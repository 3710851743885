<template>
  <div class="picker-table mt-2 pt-4">
    <span class="block title font-medium field-edit-label"
      >{{ $t('organisation.parent') }}<span class="required">*</span></span
    >
    <span class="block text-grey text-sm mb-2">{{ $t('organisation.scope') }}</span>
    <div class="container">
      <div v-if="loading" class="loader-wrapper loader-wrapper-sm">
        <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
      </div>
      <DataTable
        v-if="!loading"
        :value="organisations"
        dataKey="id"
        :scrollable="true"
        class="p-datatable-sm organisation-picker-table picker-table"
        v-model:filters="filters"
        filterDisplay="menu"
        selectionMode="single"
        v-model:selection="selectedParentOrganisation"
        :globalFilterFields="['name', 'parent_organisation_name']"
        @rowSelect="onSelectedRow"
        :metaKeySelection="false"
      >
        <template #empty>{{ $t('organisation.empty') }}</template>
        <template #loading>{{ $t('organisation.loading') }}</template>
        <template #header>
          <div class="flex justify-between align-items-center">
            <span class="p-input-icon-left search">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="
                  $t('searchBy', {
                    name: `${$t('organisation.title')}, ${$t('organisation.parent')}`,
                  })
                "
                class="p-inputtext-sm"
              />
            </span>
          </div>
        </template>
        <Column
          selectionMode="single"
          style="flex: 0 0 0; padding: 0"
          bodyStyle="padding-left: 16px"
        >
          <template #body="{ data }">
            <RadioButton
              data-cy="parent-organisation-input"
              :id="data.id"
              name="selectedParent"
              v-model="selectedParentOrganisation"
              :value="[data.id]"
              @change="onSelectedParentOrganisation(data)"
            />
          </template>
        </Column>
        <Column field="name" :header="$t('name')">
          <template #body="{ data }">
            <div class="w-full pl-1">
              <span :id="data.id" class="text-sm font-medium" data-cy="system-organisation-name">{{
                data.name
              }}</span>
            </div>
          </template>
        </Column>
        <Column field="parent_organisation_name" :header="$t('organisation.parent')">
          <template #body="{ data }">
            <div data-cy="organisation-parent" class="flex justify-start">
              <span
                v-if="data.parent_organisation_name"
                data-cy="system-parent-organisation-name"
                class="text-small"
                >{{ data.parent_organisation_name }}</span
              >
              <span v-else class="empty text-grey">--</span>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { FilterMatchMode } from 'primevue/api';
import { useOrganisationsStore } from '@/stores/admin/organizations/organisations.store';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';

export default {
  name: 'OrganisationParentPicker',
  components: {
    DataTable,
    Column,
    InputText,
    // eslint-disable-next-line vue/no-unused-components
    RadioButton,
  },

  setup(props, { emit }) {
    const store = useOrganisationsStore();
    const { organisationEditionData } = storeToRefs(store);
    const loading = ref(false);
    const selectedParentOrganisation = ref<number[]>(
      organisationEditionData.value.parent_organisation_id
        ? [organisationEditionData.value.parent_organisation_id]
        : []
    );

    const organisations = computed(() =>
      store.organisations.filter((item) => item.id !== store.organisationEditionData.id)
    );

    const onSelectedRow = (event: any) => {
      const parentData = {
        id: event.data.id,
        name: event.data.name,
      };

      selectedParentOrganisation.value = [event.data.id];

      emit('onParentSelect', parentData);
    };

    const onSelectedParentOrganisation = (data: any) => {
      const parentData = {
        id: data.id,
        name: data.name,
      };
      emit('onParentSelect', parentData);
    };

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const matchModeOptions = ref([
      { label: 'Contains', value: FilterMatchMode.CONTAINS },
      { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
      { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
    ]);

    return {
      organisations,
      filters,
      matchModeOptions,
      loading,
      onSelectedParentOrganisation,
      organisationEditionData,
      selectedParentOrganisation,
      onSelectedRow,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  border: none;
}
</style>
