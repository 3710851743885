import { defineStore } from 'pinia';
import {
  createMediaObject,
  fetchMedia,
  uploadMediaFile,
  fetchMediaFilePath,
  deleteMediaFile,
  fetchMediaStatusList
} from '@/stores/admin/media/media.api';

import type { Media, MediaStatus as MediaStatusModel } from '@/models/media.model';

interface MediaStatus {
  isMediaUploadModalOpen: boolean;
  mediaList: Media[];
  mediaDeleteModalIsOpen: boolean;
  mediaDeleteData: {
    id: number;
  };
  mediaStatusList: MediaStatusModel[]
}

export const useMediaStore = defineStore('media', {
  state: (): MediaStatus => ({
    isMediaUploadModalOpen: false,
    mediaList: [],
    mediaDeleteModalIsOpen: false,
    mediaDeleteData: {
      id: 0,
    },
    mediaStatusList: []
  }),
  getters:{
    processedMediaStatusID() : number | undefined {
      return this.mediaStatusList.find(item=>item.name == "Processed")?.id;
    }
  },
  actions: {
    getMediaStateOptions(){
      return this.mediaStatusList.map(item=>({"label": item.name, "value": item.id}))
    },
    getMediaStatusByID(id: number){
      const item = this.mediaStatusList.find(item=>item.id === id);
      return item
    },
    getMediaStatusStyle(id: number){
      const item = this.getMediaStatusByID(id);
      switch(item?.name){
        case "Processed":
          return "background-color: rgb(0, 155, 74); color: white;";
        default:
          return "background-color: lightgray;";
      }
    },
    async fetchMediaList(filtered_status_ids?: number[]) {
      return await fetchMedia(filtered_status_ids)
        .then((response) => {
          this.mediaList = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async uploadFile(file: File) {
      try {
        return await uploadMediaFile(file);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async createMedia(fileId: number) {
      try {
        return await createMediaObject(fileId);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async fetchMediaFileFullPath(url: string) {
      return await fetchMediaFilePath(url)
        .then((response: any) => {
          return response;
        })
        .catch((error: unknown) => {
          return Promise.reject(error);
        });
    },

    async deleteMediaItem(id: number) {
      return await deleteMediaFile(id)
        .then((response: any) => {
          this.fetchMediaList();
          return response;
        })
        .catch((error: unknown) => {
          return Promise.reject(error);
        });
    },
    async fetchMediaStatus() {
      return await fetchMediaStatusList()
        .then((response: any) => {
          this.mediaStatusList = response.data;
          return response;
        })
        .catch((error: unknown) => {
          return Promise.reject(error);
        });
    },
  }
});
