<template>
  <div>
    <div class="field grid grid-cols-5 content-center py-4 px-6">
      <label
        id="playlistNameLabel"
        for="playlistName"
        class="title flex self-center font-medium text-gray-600"
      >
        {{ $t('name') }}<span class="required">*</span>
      </label>
      <div class="col-span-4 flex flex-col">
        <InputText
          data-cy="playlist-configuration-name-input"
          id="playlistName"
          type="text"
          aria-describedby="playlistNameLabel"
          class="p-inputtext-sm font-bold"
          v-model="playlistModalData.name"
        />
        <div v-if="v$.name.$errors.length" class="text-right">
          <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
            <small class="p-error">{{ error.$message }}</small>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isEditMode" class="field grid grid-cols-5 content-center py-4 px-6">
      <label
        id="playlistOwnerLabel"
        for="playlistOwner"
        class="title  self-center font-medium text-gray-600"
      >
        {{ $t('createdByOrganization') }}
      </label>
      <span class="col-span-4 text-sm font-bold ml-[10.5px]">{{ playlistModalData.owner_organisation_name }}</span>
    </div>

    <div class="border border-t-gray-200">
      <PlaylistMediaPicker />
    </div>
  </div>
</template>

<script setup lang="ts">
import InputText from 'primevue/inputtext';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';

import PlaylistMediaPicker from '@/components/playlists/picker/PlaylistMediaPicker.vue';
import { usePlaylistsStore } from '@/stores/admin/playlists/playlists.store';

import type { playlistRules } from '@/utils/playlistModalRules';
import type { Validation } from '@vuelidate/core';

const playlistsStore = usePlaylistsStore();
const { playlistModalData, isEditMode } = storeToRefs(playlistsStore);

const props = defineProps<{
  v$: Validation<typeof playlistRules>;
}>();

watch(
  () => playlistModalData.value.name,
  () => {
    props.v$.name.$touch();
  },
  { deep: true }
);

watch(
  () => playlistModalData.value.media,
  () => {
    props.v$.media.$touch();
  },
  { deep: true }
);
</script>
