import { defineStore } from 'pinia';
import {
  createAlertRequest,
  editAlertRequest,
  fetchInvocations,
  fetchAlertSeverities,
  fetchFilteredAlerts,
  fetchAlerts,
  fetchAlertTemplates,
  batchEditAlertRequest,
  batchDeleteAlertRequest,
} from '@/stores/admin/alerts/alerts.api';
import { useContactsStore } from '@/stores/admin/contacts/contacts.store';

import type {
  Alert,
  AlertEditData,
  AlertTemplateGroup,
  AlertTemplateWithGroupName,
  Invocation,
  AlertParameter,
  AlertBatchEditData,
  AlertContact,
} from '@/models/alerts.model';

import type { Contact } from '@/models/contacts.model';
import type { ID, IdName } from '@/models/common.model';
import type { GenericSetting } from '@/models/datatypes.model';

interface ActiveMonitoringState {
  invocations: Invocation[];
  severities: IdName[];
  alerts: Alert[];
  alertsActiveTab: number;
  alertCreateModalIsOpen: boolean;
  alertDeleteModalIsOpen: boolean;
  alertEditId: number | null;
  alertDeleteIds: ID[];
  alertTemplateGroups: AlertTemplateGroup[];
  alertModalData: AlertEditData;
  alertModalAllParamsValid: boolean;
  selectedParameters: GenericSetting[];
}

export const useAlertsStore = defineStore('alerts', {
  state: (): ActiveMonitoringState => ({
    invocations: [],
    severities: [],
    alerts: [],
    alertsActiveTab: 0,
    alertCreateModalIsOpen: false,
    alertDeleteModalIsOpen: false,
    alertEditId: null,
    alertDeleteIds: [],
    alertTemplateGroups: [],
    alertModalData: {
      name: '',
      parameters: [],
      contacts: [],
      charge_parks: [],
      send_on_weekends: true,
      max_rate_per_day: 10,
      template_id: '',
      created_by_user_name: '',
    },
    alertModalAllParamsValid: true,
    selectedParameters: [],
  }),

  getters: {
    alertTemplateList(state): AlertTemplateWithGroupName[] {
      return state.alertTemplateGroups.reduce(
        (acc: AlertTemplateWithGroupName[], curr: AlertTemplateGroup) => {
          const { groupName, alertTemplates } = curr;
          const alertTemplatesWithGroupName = alertTemplates.map((alertTemplate) => {
            return { ...alertTemplate, groupName };
          });
          return [...acc, ...alertTemplatesWithGroupName];
        },
        []
      );
    },

    selectedTemplate(state): AlertTemplateWithGroupName | undefined {
      return this.alertTemplateList.find(
        (template) => template.id === state.alertModalData.template_id
      );
    },

    selectedContacts(state): Contact[] {
      const contactsStore = useContactsStore();

      return contactsStore.contacts.filter((contact: Contact) =>
        state.alertModalData.contacts.includes(contact.id)
      );
    },

    isEditMode(state) {
      return state.alertEditId !== null;
    },
  },

  actions: {
    async fetchInvocationsList() {
      return await fetchInvocations()
        .then((response) => {
          this.invocations = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchSeverityList() {
      return await fetchAlertSeverities()
        .then((response) => {
          this.severities = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchFilteredAlertsList(startTime: string, currentTime: string, type: number[]) {
      return await fetchFilteredAlerts(startTime, currentTime, type)
        .then((response) => {
          this.invocations = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchAlertsList() {
      return await fetchAlerts()
        .then((response) => {
          this.alerts = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchAlertTemplateGroups() {
      return await fetchAlertTemplates()
        .then((response) => {
          this.alertTemplateGroups = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async createAlert() {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { created_by_user_name, ...rest } = this.alertModalData;
        return await createAlertRequest(rest);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async editAlert() {
      try {
        if (this.alertEditId === null) {
          return Promise.reject('No alert id to edit');
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { created_by_user_name, ...rest } = this.alertModalData;

        return await editAlertRequest(this.alertEditId, rest);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async batchUpdateAlerts(items: AlertBatchEditData) {
      try {
        return await batchEditAlertRequest(items);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async batchDeleteAlerts() {
      if (!this.alertDeleteIds.length) {
        return Promise.reject('No alert ids to delete');
      }

      try {
        await batchDeleteAlertRequest(this.alertDeleteIds);
        this.alertDeleteIds = [];
      } catch (error) {
        return Promise.reject(error);
      }
    },

    fillAlertModalData() {
      const alert = this.alerts.find((alert: Alert) => alert.id === this.alertEditId);

      if (!alert) {
        throw new Error(`Alert with id ${this.alertEditId} not found`);
      }

      this.alertModalData.template_id = alert.template_id;
      this.alertModalData.created_by_user_name = alert.created_by_user_name;
      if (alert.name) this.alertModalData.name = alert.name;
      this.alertModalData.parameters = alert.parameters.map((parameter: AlertParameter) => {
        return { id: parameter.id, value: parameter.value };
      });
      this.alertModalData.charge_parks = alert.charge_parks.map(
        (chargePark: IdName) => chargePark.id
      );
      this.alertModalData.contacts = alert.contacts.map((contact: AlertContact) => contact.id);
      if (alert.max_rate_per_day) this.alertModalData.max_rate_per_day = alert.max_rate_per_day;
      this.alertModalData.send_on_weekends = alert.send_on_weekends;
    },

    resetAlertModalData() {
      this.alertModalData = {
        name: '',
        parameters: [],
        contacts: [],
        charge_parks: [],
        send_on_weekends: true,
        max_rate_per_day: 10,
        template_id: '',
        created_by_user_name: '',
      };
    },
  },
});
