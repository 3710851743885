<template>
  <div class="px-12 border-b text-sm py-2">
    <ReadOnlyItem :name="$t('downtimes.reason')" :value="previewData.reason" />
  </div>
  <div class="px-12 border-b text-sm py-2">
    <ReadOnlyItem :name="$t('downtimes.startTime')">
        <date-time-display id="downtime-start-time" :date="previewData.start_time" bold />
    </ReadOnlyItem>
    <ReadOnlyItem  :name="$t('downtimes.endTime')">
        <date-time-display id="downtime-end-time" :date="previewData.end_time" bold />
    </ReadOnlyItem>
  </div>
  <div
    :class="`flex flex-col text-sm py-2 ${
      downtimeModalData.systems.length > 6 ? 'pl-12 pr-6' : 'px-12'
    } ${downtimeModalData.systems.length > 6 ? 'h-[200px] overflow-y-scroll' : ''}`"
  >
    <ReadOnlyTitle :title="$t('hardwareSystems')" />
    <div v-for="asset in previewData.systems" :key="asset.id">
      <ReadOnlyItem :name="asset.name" connector />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useDowntimesStore } from '@/stores/admin/downtimes/downtimes.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { storeToRefs } from 'pinia';

import ReadOnlyItem from '@/components/common/readOnly/ReadOnlyItem.vue';
import ReadOnlyTitle from '@/components/common/readOnly/ReadOnlyTitle.vue';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';

const downtimesStore = useDowntimesStore();
const { downtimeModalData } = storeToRefs(downtimesStore);
const systemsStore = useHardwareSystemsStore();
const { systems } = storeToRefs(systemsStore);

const previewData = ref({
  reason: downtimeModalData.value.reason!,
  start_time: downtimeModalData.value.start_time,
  end_time: downtimeModalData.value.end_time,
  systems: systems.value.filter((system) => downtimeModalData.value.systems.includes(system.id)),
});
</script>

<style scoped lang="scss"></style>
