<template>
  <PageHeader :title="whitelistDetails.name" :subtitle="$t('whitelist.title')">
    <template #bottom>
      <WhitelistTabs class="ml-4" />
    </template>
  </PageHeader>
  <div class="container container-grey">
    <WhitelistRfidTags v-if="whitelistActiveTab === 0" />
    <WhitelistChargers v-if="whitelistActiveTab === 1" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onMounted, ref, onUnmounted } from 'vue';
import { useWhitelistsStore } from '@/stores/admin/whitelists/whitelists.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { useRoute } from 'vue-router';
import WhitelistRfidTags from '@/components/whitelists/WhitelistRfidTags.vue';
import WhitelistChargers from '@/components/whitelists/WhitelistChargers.vue';
import PageHeader from '@/components/common/layout/PageHeader.vue';
import WhitelistTabs from '@/components/whitelists/WhitelistTabs.vue';

const route = useRoute();
const userStore = useUsersStore();
const whitelistsStore = useWhitelistsStore();
const { whitelistActiveTab, whitelistDetails } = storeToRefs(whitelistsStore);
const whitelistId = ref(Number(route.params.id));

onMounted(() => whitelistsStore.fetchWhitelistDetails(whitelistId.value));
onUnmounted(async () => {
  whitelistsStore.resetWhitelistDetails();
  whitelistActiveTab.value = 0;
  whitelistsStore.setWhitelistDetailsView(whitelistId.value);
  await userStore.init();
});
</script>
