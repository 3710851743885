<template>
  <div class="w-1/2">
    <div class="my-2">
      <i :class="`pi ${icon} font-bold`"></i>
      <span class="pl-2 font-medium">{{ title }}</span>
    </div>
    <div class="flex border border-r-1 border-solid border-gray-200 rounded bg-white">
      <div :class="`flex flex-col pt-3 pb-2 px-5 min-w-fit ${leftWidth ? leftWidth : 'w-1/3'}`">
        <slot name="left"></slot>
      </div>
      <div class="features-container pt-3 pb-2 px-5">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'HeaderCard',

  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    leftWidth: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.features-container {
  border-left: 1px solid $grey-medium-light;
}
</style>
