<template>
  <HardwareSystemsPicker
    @onSystemSelect="setSelectedAssets"
    @onSystemUnselect="setUnselectedAssets"
    @onAllSystemsSelect="setSelectedAllAssets"
    @onAllSystemsUnselect="setUnselectedAllAssets"
    :value="downtimeModalData.systems"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useDowntimesStore } from '@/stores/admin/downtimes/downtimes.store';

import HardwareSystemsPicker from '@/components/hardwareSystems/HardwareSystemsPicker.vue';

import type { downtimeRules } from '@/utils/downtimeModalRules';
import type { Validation } from '@vuelidate/core';

const downtimesStore = useDowntimesStore();
const { downtimeModalData } = storeToRefs(downtimesStore);

const props = defineProps<{
  v$: Validation<typeof downtimeRules>;
}>();

const setSelectedAssets = (assetId: { id: number }) => {
  downtimeModalData.value.systems.push(assetId.id);
  props.v$.systems.$touch();
};

const setUnselectedAssets = (id: number) => {
  const assetPos = downtimeModalData.value.systems.findIndex((systemId) => systemId === id);
  downtimeModalData.value.systems.splice(assetPos, 1);
  props.v$.systems.$touch();

  if (downtimeModalData.value.systems.length === 0) {
    props.v$.systems.$reset();
  }
};

const setSelectedAllAssets = (assetIds: any) => {
  downtimeModalData.value.systems = assetIds.map((item: { id: number }) => item.id);
  props.v$.systems.$touch();
};

const setUnselectedAllAssets = () => {
  downtimeModalData.value.systems = [];
  props.v$.systems.$reset();
};
</script>

<style scoped lang="scss"></style>
