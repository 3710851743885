<template>
  <div class="picker-table mt-2 pt-4 px-12">
    <div class="mb-3">
      <slot name="header">
        <span class="block title font-medium">{{ $t('chargePark.title') }}</span>
      </slot>
      <slot name="subheader" />
    </div>

    <div class="container">
      <div v-if="loading" class="loader-wrapper loader-wrapper-sm">
        <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
      </div>
      <DataTable
        v-else
        v-model:filters="filters"
        v-model:selection="selectedChargePark"
        scrollable
        class="p-datatable-sm charge-park-picker-table picker-table"
        dataKey="id"
        filterDisplay="menu"
        scrollHeight="280px"
        :globalFilterFields="['name', 'owner_organisation_name', 'address']"
        :metaKeySelection="false"
        :value="props.sites ?? chargeParks"
        v-bind="{ loading }"
        @rowSelect="onSelectedChargePark"
        @rowUnselect="onUnselectedChargePark"
        @rowSelectAll="onSelectedAllChargeParks"
        @rowUnselectAll="onUnselectedAllChargeParks"
      >
        <template #empty>{{ $t('chargePark.empty') }}</template>
        <template #loading>{{ $t('chargePark.loading') }}</template>
        <template #header>
          <div class="flex justify-between align-items-center">
            <span class="p-input-icon-left search">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('searchBy', { name: `${$t('names')}, ${$t('owners')}` })"
                class="p-inputtext-sm"
              />
            </span>
          </div>
        </template>
        <Column
          :selectionMode="$props.selectMode"
          :style="{
            flex: 0,
            padding: 0,
            paddingLeft: $props.selectMode === 'multiple' ? '16px' : 0,
          }"
          bodyStyle="padding-left: 16px"
        />
        <Column field="name" :header="$t('name')">
          <template #body="{ data }: { data: ChargePark }">
            <div class="w-full pl-1">
              <span
                :id="data?.id ? String(data.id) : undefined"
                class="text-sm font-medium"
                data-cy="system-charge-park-name"
              >
                {{ data?.name }}
              </span>
              <template v-if="showLocation">
                <Country
                  :countryCode="data?.country_code"
                  :showIcon="false"
                  nameClass="font-medium text-grey block text-xs"
                />
              </template>
            </div>
          </template>
        </Column>
        <Column field="owner_organisation_name" :header="ownerLabelTranslation">
          <template #body="{ data }: { data: ChargePark }">
            <div data-cy="charge-park-owner" class="flex justify-start">
              <span data-cy="system-charge-park-owner" class="text-small">{{
                data?.owner_organisation_name
              }}</span>
            </div>
          </template>
        </Column>
        <Column
          v-if="$props.assetsColumn && $props.compatibleSystems?.length"
          field="compatible_systems"
          :header="$t('hardwareSystem.compatibleSystems')"
        >
          <template #body="{ data }: { data: ChargePark }">
            <div class="flex justify-start">
              <span data-cy="charge-number-of-compatible-systems" class="text-small">
                {{ getNumberOfCompatibleSystems(data) }} / {{ data?.number_of_systems }}
              </span>
            </div>
          </template>
        </Column>
        <Column
          v-if="$props.playlistColumn"
          field="playlist"
          :header="$t('hardwareSystem.currentPlaylist')"
        >
          <template #body="{ data }: { data: ChargePark }">
            <span v-if="data.playlist && data.playlist.name">{{ data.playlist?.name }}</span>
            <span v-else class="empty text-grey">--</span>
          </template>
        </Column>
        <Column v-if="$props.tarriffColumn" field="tariff" :header="$t('tariff.currentTariff')">
          <template #body="{ data }: { data: ChargePark }">
            <span v-if="data.tariff && data.tariff.name" class="text-small">{{
              data.tariff?.name
            }}</span>
            <span v-else class="empty text-grey">--</span>
          </template>
        </Column>
        <Column
          v-if="$props.assetsColumn"
          field="number_of_systems"
          :header="$t('hardwareSystem.numberOfSystems')"
        >
          <template #body="{ data }: { data: ChargePark }">
            <div class="flex justify-start">
              <span data-cy="charge-number-of-systems" class="text-small">
                {{ data?.number_of_systems }}
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { FilterMatchMode } from 'primevue/api';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Country from '../common/countries/Country.vue';

import type { ChargePark, SelectMode } from '@/models/chargeParks.model';
import { useI18n } from 'vue-i18n';
import type { ID } from '@/models/common.model';

const chargeParksStore = useChargeParksStore();
const { chargeParks } = storeToRefs(chargeParksStore);
const loading = ref(false);
const selectedChargePark = ref<ID[]>([]);

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    value?: number[];
    selectMode: SelectMode;
    assetsColumn: boolean;
    playlistColumn?: boolean;
    tarriffColumn?: boolean;
    showLocation: boolean;
    ownerLabel: 'owner' | 'organisation';
    compatibleSystems?: number[];
    sites?: ChargePark[];
  }>(),
  {
    assetsColumn: false,
    showLocation: true,
    ownerLabel: 'owner',
  }
);

const emit = defineEmits([
  'onChargeParkSelect',
  'onChargeParkUnselect',
  'onChargeParkSelectAll',
  'onChargeParkUnselectAll',
  'update:value',
]);

const getNumberOfCompatibleSystems = (chargePark: ChargePark) => {
  if (!props.compatibleSystems) return 0;

  return props.compatibleSystems.reduce((acc, productType) => {
    const numberOfSystems = chargePark?.number_of_systems_by_product_type?.[productType] ?? 0;

    return acc + numberOfSystems;
  }, 0);
};

const ownerLabelTranslation = computed(() => {
  return t(`chargePark.${props.ownerLabel}`);
});

const onSelectedChargePark = (event: any) => {
  emit('onChargeParkSelect', event.data);
  emit(
    'update:value',
    selectedChargePark.value.map((item) => item.id)
  );
};

const onUnselectedChargePark = (event: any) => {
  emit('onChargeParkUnselect', event.data);
  emit(
    'update:value',
    selectedChargePark.value.map((item) => item.id)
  );
};

const onSelectedAllChargeParks = (event: any) => {
  const ids = event.data.map((item: any) => ({ id: item.id }));
  emit('onChargeParkSelectAll', ids);
  emit(
    'update:value',
    selectedChargePark.value.map((item) => item.id)
  );
};

const onUnselectedAllChargeParks = () => {
  emit('onChargeParkUnselectAll');
  emit(
    'update:value',
    selectedChargePark.value.map((item) => item.id)
  );
};

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

onMounted(async () => {
  if (props.sites !== undefined) {
    selectedChargePark.value = props.sites.filter((site: ChargePark) =>
      props.value?.includes(site.id)
    );
    return;
  }

  loading.value = true;
  await chargeParksStore.fetchChargeParkList();
  selectedChargePark.value = chargeParksStore.chargeParks.filter((chargePark: ChargePark) =>
    props.value?.includes(chargePark.id)
  );
  loading.value = false;
});
</script>

<style lang="scss" scoped>
:deep(input.p-inputtext-sm::placeholder) {
  color: var(--grey-400);
}
</style>
