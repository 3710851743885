import type { AxiosPromise, AxiosResponse } from 'axios';
import axios from 'axios';
import type { TariffEditData } from '@/models/tariffs.model';

export async function fetchTariffs(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/tariffs/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchSingleTariffDetails(id: number): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/tariffs/${id}/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createTariff(data: TariffEditData): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/tariffs/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function updateTariff(id: number, data: TariffEditData): Promise<AxiosResponse> {
  return axios
    .patch<AxiosPromise>(`/api/tariffs/${id}/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function deleteTariffRequest(id: number): Promise<AxiosResponse> {
  return axios
    .delete<AxiosPromise>(`/api/tariffs/${id}/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function fetchCurrency(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/tariff-currencies/`);
  } catch (error) {
    return Promise.reject(error);
  }
}
