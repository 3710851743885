<template>
  <div>
    <span v-if="title" class="title">{{ title }}</span>
    <div
      :class="`flex flex-wrap ${
        layout === 'horizontal' && itemsAmount === 'double' ? 'justify-between' : ''
      }`"
    >
      <div
        v-for="(item, index) in data"
        :key="index"
        :class="`border bg-white border-solid border-slate-500 rounded p-3 mt-1 ${
          layout === 'horizontal' ? itemsAmount : 'single'
        }`"
      >
        <div data-cy="custom-radio-select" class="flex flex-nowrap items-center">
          <RadioButton
            data-cy="custom-radio-select-input"
            :id="item.id"
            name="selectedItem"
            v-model="selectedItem"
            :value="item.state"
            @change="onSelectedItem"
          />
          <div class="w-full pl-1 flex flex-col">
            <span
              data-cy="custom-radio-select-title"
              class="text-sm font-medium pl-2 mb-1 item-title"
              >{{ item.title }}</span
            >
            <span data-cy="custom-radio-select-description" class="pl-2 item-description">{{
              item.description
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import RadioButton from 'primevue/radiobutton';
import { HORIZONTAL } from '@/utils/constants';

type CustomRadioSelectData = {
  id: number | string;
  title: string;
  description: string;
  state: boolean;
};

type Layout = 'horizontal' | 'vertical';
type Items = 'single' | 'double' | 'multiple';

const props = withDefaults(
  defineProps<{
    data: CustomRadioSelectData[];
    layout?: Layout;
    title?: String;
    selectedState: Boolean;
    itemsAmount: Items;
  }>(),
  {
    data: () => [],
    layout: HORIZONTAL,
    itemsAmount: 'double',
  }
);

const emit = defineEmits(['onRadioChange']);

const selectedItem = ref(props.selectedState);

const onSelectedItem = () => {
  emit('onRadioChange', selectedItem.value);
};
</script>
<style lang="scss" scoped>
.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $text-medium;
}

.single {
  width: 100%;
}
.double {
  width: 48.5%;
}

.multiple {
  width: 230px;
  margin: 0 10px 8px 0;
}

.item-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: $text-medium;
}
.item-description {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $text-light;
}
</style>
