<template>
  <div>
    <PanelWithEdit
      v-if="!isLoading"
      :title="$t('chargePark.payment')"
      :loading="isLoading"
      :isEditing="false"
      :isNotValid="editFormIsNotValid"
      :editIsDisabled="!activeRoles"
      cancelBtn
      @onEdit="isEditing = true"
      @onClose="onClose"
      @onApply="editSite"
    >
      <div class="flex flex-col py-2">
        <div class="flex justify-between">
          <span data-cy="site-payment-field-name" class="block font-medium py-3 w-1/2">{{
            $t('chargePark.paymentMethods')
          }}</span>
          <div class="w-3/4 flex flex-col">
            <MultiSelect
              v-if="isEditing"
              v-model="chargeParkDetails.payment_methods"
              :options="paymentMethods"
              :placeholder="$t('chargePark.paymentMethods')"
              class="max-w-[100%] min-w-[354px] md:min-w-[200px] mb-2"
              :maxSelectedLabels="5"
              optionLabel="name"
              @change="onChangePaymentMethods"
              @show="onShow"
              @hide="onHide"
            >
              <template #value="slotProps">
                <div v-if="isValueVisible && slotProps.value.length > 0">
                  <span v-if="chargeParkDetails.payment_methods.length <= 3">
                    <span
                      v-for="(item, index) in slotProps.value"
                      :key="index"
                      class="inline-block ml-2"
                    >
                      {{ item.name
                      }}{{ index + 1 !== chargeParkDetails.payment_methods.length ? ',' : '' }}
                    </span>
                  </span>
                  <span v-else-if="isValueVisible && chargeParkDetails.payment_methods.length > 3">
                    {{ chargeParkDetails.payment_methods.length }} methods selected
                  </span>
                </div>
                <span v-else class="custom-value">{{ $t('chargePark.paymentMethods') }}</span>
              </template>
              <template #option="slotProps">
                <span class="pr-4">{{ slotProps.option.name }}</span>
              </template>
            </MultiSelect>
            <div
              v-if="isEditing && chargeParkDetails.payment_methods.length > 0"
              class="text-right"
            >
              <div
                v-for="(item, index) in chargeParkDetails.payment_methods"
                :key="index"
                class="inline-block ml-2"
              >
                <div
                  data-cy="payment-method"
                  class="custom-chip cursor-pointer"
                  @click="unselectedDisplayedPaymentMethod(item.id)"
                >
                  <span>{{ item.name }}</span>
                  <i class="pi pi-times text-xs font-bold"></i>
                </div>
              </div>
            </div>
            <span
              v-if="!isEditing && chargeParkDetails.payment_methods.length > 0"
              data-cy="site-payment-methods-field-value"
              class="block font-medium text-right py-3"
              >{{ selectedPaymentMethods }}</span
            >
            <span
              v-if="!isEditing && chargeParkDetails.payment_methods.length === 0"
              data-cy="site-payment-methods-field-value-empty"
              class="block empty py-2 text-right font-medium whitespace-nowrap"
              >--</span
            >
          </div>
        </div>
        <div class="flex justify-between">
          <span data-cy="site-payment-field-name" class="block font-medium py-3 w-1/2">{{
            $t('chargePark.customReceiptMessage')
          }}</span>
          <div class="w-1/2">
            <Textarea
              v-if="isEditing"
              data-cy="panel-item-textarea"
              class="border border-[var--gray-200] rounded p-2 w-full"
              v-model="chargeParkDetails.custom_receipt_message"
              :placeholder="$t('chargePark.customReceiptMessage')"
              autoResize
              row="1"
              cols="18"
              @keyup="validateInput()"
            />
            <div
              class="input-errors w-full"
              v-for="(error, index) of v$.custom_receipt_message.$errors"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small>
            </div>
          </div>
          <span
            v-if="
              !isEditing &&
              chargeParkDetails.custom_receipt_message &&
              chargeParkDetails.custom_receipt_message !== ''
            "
            data-cy="site-payment-receipt-field-value"
            class="block font-medium text-right py-3 w-1/3"
            >{{ chargeParkDetails.custom_receipt_message }}</span
          >
          <span
            v-if="
              !isEditing &&
              (chargeParkDetails.custom_receipt_message === '' ||
                !chargeParkDetails.custom_receipt_message)
            "
            data-cy="site-payment-receipt-field-value-empty"
            class="empty py-2 text-right font-medium whitespace-nowrap"
            >--</span
          >
        </div>
      </div>
    </PanelWithEdit>
    <Toast
      data-cy="charge-park-info-toast"
      position="top-center"
      errorIcon="pi pi-info-circle"
      group="chargeParkPayment"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { chargeParkPaymentRules } from '@/utils/chargeParkDetailsRules';
import { useUsersStore } from '@/stores/admin/users/users.store';
import {
  ASSET_MANAGER_ROLE,
  ADMINISTRATOR_ROLE,
  DEFAULT_TOAST_LIFE_MILLISECONDS,
} from '@/utils/constants';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import MultiSelect from 'primevue/multiselect';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import type { IdName } from '@/models/common.model';

const { t } = useI18n();
const toast = useToast();
const chargeParksStore = useChargeParksStore();
const systemsStore = useHardwareSystemsStore();
const userStore = useUsersStore();
const { chargeParkEditionData, paymentMethods } = storeToRefs(chargeParksStore);

const chargeParkDetails = ref({
  payment_methods: chargeParkEditionData.value.payment_methods,
  custom_receipt_message: chargeParkEditionData.value.custom_receipt_message,
});

const isLoading = ref(true);
const isEditing = ref(false);
const isValueVisible = ref(false);

const activeRoles = computed(
  () =>
    userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
    userStore.me.roles.map((role) => role.id).includes(ASSET_MANAGER_ROLE)
);

const onShow = () => (isValueVisible.value = true);
const onHide = () => (isValueVisible.value = false);

const selectedPaymentMethods = computed(() =>
  chargeParkDetails.value.payment_methods.map((method) => method.name).join(', ')
);

const unselectedDisplayedPaymentMethod = (id: number) => {
  const methodPos = chargeParkDetails.value.payment_methods.findIndex((item) => item.id === id);

  chargeParkDetails.value.payment_methods.splice(methodPos, 1);

  v$.value.payment_methods.$touch();
};

const onChangePaymentMethods = () => {
  v$.value.payment_methods.$touch();
};

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('chargePark.title'),
      action: t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'chargeParkPayment',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: t('toast.edition'),
      name: t('chargePark.title'),
    }),
    group: 'chargeParkPayment',
  });
};

const editSite = () => {
  const data = {
    payment_methods: chargeParkDetails.value.payment_methods.map((method: IdName) => method.id),
    custom_receipt_message: chargeParkDetails.value.custom_receipt_message,
  };
  chargeParksStore
    .editChargeParkDetails(chargeParkEditionData.value.id, data)
    .then((response: any) => {
      chargeParksStore.fetchChargePark(chargeParkEditionData.value.id).then((response) => {
        const { payment_methods, custom_receipt_message } = response.data;
        chargeParkDetails.value.payment_methods = payment_methods;
        chargeParkDetails.value.custom_receipt_message = custom_receipt_message;
      });
      if (response.status === 200) {
        systemsStore.fetchSystemsByChargeParkId(chargeParkEditionData.value.id);
        toast.removeGroup('chargeParkPayment');
        showSuccess();
        onClose();
      }
    })
    .catch((error) => {
      isEditing.value = true;
      toast.removeGroup('chargeParkPayment');
      showError();
      throw new Error(error);
    });
};

onMounted(async () => {
  await chargeParksStore.fetchPaymentMethodsList();
  isLoading.value = false;
});

const onClose = () => {
  isEditing.value = false;
  resetForm();
};

const editFormIsNotValid = computed(() => {
  return (
    !v$.value.$anyDirty ||
    !v$.value.payment_methods.$anyDirty ||
    (v$.value.custom_receipt_message.$anyDirty && v$.value.custom_receipt_message.$error)
  );
});

const resetForm = () => {
  chargeParkDetails.value.payment_methods = chargeParkEditionData.value.payment_methods;
  chargeParkDetails.value.custom_receipt_message =
    chargeParkEditionData.value.custom_receipt_message;
  v$.value.payment_methods.$reset();
  v$.value.custom_receipt_message.$reset();
  v$.value.$reset();
};

const validateInput = async () => {
  v$.value.payment_methods.$touch();
  await v$.value.custom_receipt_message.$validate();
};

const rules = computed(() => chargeParkPaymentRules);

const v$ = useVuelidate(rules, chargeParkDetails.value);
</script>
<style lang="scss" scoped>
:deep(.p-dropdown-label) {
  padding: 8px;
}

:deep(.p-multiselect) {
  border-color: $grey-medium-light;
  .p-multiselect-label.p-placeholder {
    padding: 10px 16px;
    color: $text-light;
    font-size: 14px;
  }
}

:deep(.p-inputwrapper-filled.p-multiselect .p-multiselect-label) {
  padding: 10px 16px;
  color: $text-light;
  font-size: 14px;
}

.custom-chip {
  padding: 4px 10px;
}
</style>
