import type { IdName } from '@/models/common.model';

export interface Downtime {
  id: number;
  reason: string;
  start_time: string;
  end_time: string;
  systems: IdName[];
}

export interface DowntimeEditData {
  reason: string | null;
  systems: number[];
  start_time: string | null;
  end_time: string | null;
}

export enum DowntimeStep {
  Configuration = 'configuration',
  Assets = 'assets',
  Preview = 'preview',
}
