<template>
  <PageHeader :title="t('monitoring.alerts')" :description="t('alert.description.main')" />
  <div class="container container-grey">
    <AlertsTabs />
    <AlertHistoryTable v-if="alertsActiveTab === 0" />
    <AlertTable v-if="alertsActiveTab === 1" />
  </div>

  <AlertCreateEditModal />
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useAlertsStore } from '@/stores/admin/alerts/alerts.store';

import AlertCreateEditModal from '@/components/alerts/AlertCreateEditModal.vue';
import AlertHistoryTable from '@/components/alerts/AlertHistoryTable.vue';
import AlertTable from '@/components/alerts/AlertTable.vue';
import AlertsTabs from '@/components/alerts/AlertsTabs.vue';
import PageHeader from '@/components/common/layout/PageHeader.vue';

const { t } = useI18n();
const alertsStore = useAlertsStore();
const { alertsActiveTab } = storeToRefs(alertsStore);

onMounted(() => {
  alertsActiveTab.value = 0;
});
</script>
