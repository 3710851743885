<template>
  <Dialog
    data-cy="contact-delete-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="field flex flex-col field-edit">
      <Message severity="error" icon="pi pi-exclamation-triangle" :closable="false">{{
        $t('deleteWarning')
      }}</Message>
      <p class="delete-info">
        {{ $t('contact.deleteInfo') }}
      </p>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        data-cy="delete-contact-button"
        :label="t('delete')"
        class="p-button-sm p-button-footer p-button-error mx-auto mb-2 block mt-5"
        @click="deleteContact"
        :loading="loading"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="delete-contact-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="deleteContact"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Message from 'primevue/message';
import { useContactsStore } from '@/stores/admin/contacts/contacts.store';
import { useI18n } from 'vue-i18n';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

const { t } = useI18n();
const toast = useToast();
const contactsStore = useContactsStore();
const isVisible = ref(false);
const loading = ref(false);
const { contactDeleteModalIsOpen, contactDeleteData } = storeToRefs(contactsStore);
const modalTitle = computed(() => t('delete', { itemName: t('contact.title') }));

const closeModal = () => {
  contactDeleteModalIsOpen.value = false;
  contactDeleteData.value.id = 0;
  loading.value = false;
};

const successSummary = computed(() => {
  return t('toast.success', {
    name: t('contact.title'),
    action: t('toast.deleted'),
  });
});

const errorSummary = computed(() => {
  return t('toast.error', {
    action: t('toast.deletion'),
    name: t('contact.title'),
  });
});

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: successSummary.value,
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'deleteContact',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: errorSummary.value,
    group: 'deleteContact',
  });
};

const deleteContact = async () => {
  loading.value = true;

  await contactsStore
    .deleteContactItem(contactDeleteData.value.id)
    .then((response: any) => {
      loading.value = false;
      if (response.status === 204) {
        toast.removeGroup('deleteContact');
        showSuccess();
        closeModal();
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('deleteContact');
      showError();
      throw new Error(error);
    });
};

watch(
  () => contactDeleteModalIsOpen.value,
  (value) => {
    if (value) {
      isVisible.value = true;
    } else {
      isVisible.value = false;
    }
  }
);
</script>
