<template>
  <div>
    <PanelWithEdit :title="$t('chargeParks')" :editBtn="false">
      <div class="flex flex-col py-4">
        <div class="flex justify-between" v-for="(sites, key) in tariffDetails" :key="key">
          <span
            data-cy="site-info-field-name"
            class="block font-medium text-[var(--gray-400)] py-1 w-1/3 self-center"
            >{{ $t(`tariff.tariffDetails.${key}`) }}</span
          >
          <div v-if="sites && sites.length > 0" class="text-right w-2/3">
            <span
              data-cy="tariff-info-field-site"
              class="py-1 pl-3 text-right font-medium text-[var(--gray-700)] inline-block"
              v-for="(site, index, key) in sites"
              :key="key"
              >{{ site.name + `${index !== sites.length - 1 ? ',' : ''}` }}</span
            >
          </div>
          <span v-if="!sites || sites.length === 0" class="empty py-2 pl-4 font-medium">--</span>
        </div>
      </div>
    </PanelWithEdit>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';

import type { Tariff } from '@/models/tariffs.model';

const props = defineProps<{
  tariff: Tariff;
}>();

const tariffDetails = computed(() => {
  return {
    charge_parks: props.tariff.charge_parks,
  };
});
</script>
<style scoped lang="scss"></style>
