import { required, helpers, maxLength, email } from '@vuelidate/validators';
import { asciiCharacters, asciiCharactersWithNewLine, phoneNumber } from '@/utils/validationRules';
import { MAX_LENGTH } from '@/utils/constants';
import i18n from '@/i18n';

export const chargeParkInfoRules = {
  name: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('name').toLowerCase(),
      }),
      required
    ),
    asciiCharacters: helpers.withMessage(
      i18n.global.t('rules.asciiCharacters', { field: i18n.global.t('name') }),
      asciiCharacters
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
  operator_name: {
    asciiCharacters: helpers.withMessage(
      i18n.global.t('rules.asciiCharacters', { field: i18n.global.t('chargePark.operatorName') }),
      asciiCharacters
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
  operator_address: {
    asciiCharactersWithNewLine: helpers.withMessage(
      i18n.global.t('rules.asciiCharactersWithNewLine', {
        field: i18n.global.t('chargePark.operatorAddress'),
      }),
      asciiCharactersWithNewLine
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
};

export const chargeParkContactRules = {
  contact_name: {
    asciiCharacters: helpers.withMessage(
      i18n.global.t('rules.asciiCharacters', {
        field: i18n.global.t('name'),
      }),
      asciiCharacters
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
  contact_email: {
    email: helpers.withMessage(i18n.global.t('rules.email'), email),
    maxLength: maxLength(MAX_LENGTH),
  },
  contact_phone: {
    phoneNumber: helpers.withMessage(
      i18n.global.t('rules.phoneNumber', {
        field: i18n.global.t('chargePark.chargeParkDetails.contact_phone'),
      }),
      phoneNumber
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
};

export const chargeParkLocationRules = {
  country_code: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('country.title').toLowerCase(),
        item: i18n.global.t('chargePark.title').toLowerCase(),
      }),
      required
    ),
  },
  address: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('address').toLowerCase(),
        item: i18n.global.t('chargePark.title').toLowerCase(),
      }),
      required
    ),
  },
};

export const chargeParkPaymentRules = {
  custom_receipt_message: {
    asciiCharacters: helpers.withMessage(
      i18n.global.t('rules.asciiCharacters', {
        field: i18n.global.t('chargePark.customReceiptMessage'),
      }),
      asciiCharacters
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
  payment_methods: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('chargePark.accessType').toLowerCase(),
        item: i18n.global.t('chargePark.payment').toLowerCase(),
      }),
      required
    ),
  },
};

export const chargeParkAccessRules = {
  access_type: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('chargePark.accessType').toLowerCase(),
        item: i18n.global.t('chargePark.payment').toLowerCase(),
      }),
      required
    ),
  },
  station_type: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('chargePark.stationType').toLowerCase(),
        item: i18n.global.t('chargePark.access').toLowerCase(),
      }),
      required
    ),
  },
};
