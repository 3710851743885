<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="report-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}</Message
  >
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="report-table"
    :value="reportsWithTemplateName"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    v-model:selection="selectedReports"
    filterDisplay="menu"
    :globalFilterFields="['name', 'template_name']"
    class="p-datatable-sm mb-5 mt-6 border-rounded overflow-hidden report-table"
    dataKey="id"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="$t('searchBy', { name: `${$t('name')}, ${$t('template')}` })"
            class="p-inputtext-sm"
          />
        </span>
        <div v-if="activeRoles" class="flex justify-end items-center p-4">
          <Button
            data-cy="open-batch-actions-menu"
            :label="$t('actions')"
            @click="toggleActions"
            icon="pi pi-chevron-down"
            iconPos="right"
            aria-haspopup="true"
            aria-controls="actions_menu"
            class="p-button-outlined p-button-plain p-button-sm mr-3 actions-menu-button"
          />
          <Menu
            data-cy="batch-actions-menu"
            id="actions_menu"
            ref="actionsMenu"
            :model="actionMenuItems"
            :popup="true"
            class="mt-1"
          />
          <Button
            data-cy="open-create-report-modal"
            :label="$t('report.newReport')"
            icon="pi pi-plus"
            class="p-button-sm p-button-primary"
            @click="openCreateReportModal"
          />
        </div>
      </div>
    </template>
    <template #empty>
      <span data-cy="report-table-empty" class="w-full block text-center">{{
        $t('report.empty')
      }}</span>
    </template>
    <Column class="no-top-border-row" selectionMode="multiple" style="min-width: 3rem" />
    <Column
      class="no-top-border-row"
      field="name"
      :header="$t('name')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 12rem"
    >
      <template #body="{ data }">
        <span data-cy="report-name" :id="data.id">{{ data.name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="template_name"
      :header="$t('template')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 12rem"
    >
      <template #body="{ data }">
        <span data-cy="report-template-name">{{ data.template_name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('template') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="contacts"
      :header="$t('contacts')"
      :sortable="true"
      style="min-width: 12rem"
    >
      <template #body="{ data }">
        <span v-if="data.contacts.length > 0" data-cy="report-contacts">
          <i
            class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 mr-2"
            v-tooltip.right="{
              value: `${data.contacts
                .map((contact: Contact) => `<div class='mb-3'><span class='font-medium'>Name:</span><span class='text-neutral-500 pl-1'> ${contact.name}</span>
                  <span class='font-medium'>Type:</span><span class='text-neutral-500 pl-2.5'> ${contact.type}</span>
                </div>
                `).join('')
                }`,
              escape: true,
              class: 'custom-tooltip contacts-tooltip',
            }"
          >
          </i>
          {{
            data.contacts.filter((contact: Contact) => contact.type === 'EMAIL').length === 1
              ? data.contacts.filter((contact: Contact) => contact.type === 'EMAIL').length +
                ' Email' +
                ', ' +
                data.contacts.filter((contact: Contact) => contact.type === 'SMS').length +
                ' Sms'
              : data.contacts.filter((contact: Contact) => contact.type === 'EMAIL').length +
                ' Emails' +
                ', ' +
                data.contacts.filter((contact: Contact) => contact.type === 'SMS').length +
                ' Sms'
          }}
        </span>
        <span v-else data-cy="report-no-contacts">
          <i class="pi pi-exclamation-triangle text-amber-500 text-lg ml-1 mr-2"></i>
          0 {{ $t('contacts') }}
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="charge_parks"
      :header="$t('chargePark.includedSites')"
      :sortable="true"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <span v-if="data.charge_parks.length > 0" data-cy="report-charge-park">
          <i
            class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 mr-2"
            v-tooltip.right="{
              value: `${data.charge_parks.map((chargePark: IdName) => `<div class='flex flex-nowrap w-full'><span class='pr-2'>${chargePark.name}</span></div>`).join('')}`,
              escape: true,
              class: 'custom-tooltip site-tooltip',
            }"
          >
          </i>
          {{
            data.charge_parks.length === 1
              ? data.charge_parks.length + ' Site'
              : data.charge_parks.length + ' Sites'
          }}
        </span>
        <span v-else data-cy="report-no-charge-parks">
          <i class="pi pi-exclamation-triangle text-amber-500 text-lg ml-1 mr-2"></i>
          0 {{ $t('chargeParks') }}
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="next_report_at"
      :header="$t('report.nextReport')"
      :sortable="true"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <date-time-display data-cy="report-next-report" :date="(data as Report).next_report_at" />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="active"
      :header="$t('status')"
      :sortable="true"
      :showFilterMatchModes="false"
      style="min-width: 8rem"
    >
      <template #body="{ data }">
        <Tag
          data-cy="report-enabled"
          class="mr-2"
          :severity="`${data.active ? 'success' : 'info'}`"
          :value="`${data.active ? 'Enabled' : 'Disabled'}`"
          rounded
        ></Tag>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="statuses"
          :placeholder="$t('select', { name: $t('status') })"
          class="p-column-filter mb-2"
          :showClear="true"
        >
        </Dropdown>
      </template>
    </Column>
    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }">
        <div class="flex">
          <Button
            v-if="activeRoles"
            data-cy="open-edit-report-modal"
            :label="$t('edit')"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openEditReportModal(data.id)"
          />
          <Button
            v-if="activeRoles"
            data-cy="open-delete-report-modal"
            :label="$t('delete')"
            icon="pi pi-trash"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openDeleteReportModal(data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>

  <ReportDeleteModal @delete="selectedReports = []" />

  <Toast
    data-cy="report-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="reportConfiguration"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useReportsStore } from '@/stores/admin/reports/reports.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api';
import {
  ADMINISTRATOR_ROLE,
  REPORTS_ADMIN_ROLE,
  DISABLE,
  ENABLE,
  DEFAULT_TOAST_LIFE_MILLISECONDS,
} from '@/utils/constants';

import ReportDeleteModal from '@/components/reports/ReportDeleteModal.vue';

import type { Report, ReportBatchEditData } from '@/models/reports.model';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import Menu from 'primevue/menu';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import Toast from 'primevue/toast';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Contact } from '@/models/contacts.model';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { IdName } from '@/models/common.model';

const reportsStore = useReportsStore();
const {
  reportsWithTemplateName,
  reportCreateModalIsOpen,
  reportDeleteModalIsOpen,
  reportDeleteIds,
  reportEditId,
} = storeToRefs(reportsStore);
const { t } = useI18n();
const userStore = useUsersStore();
const toast = useToast();

const loading = ref(true);
const notAuthorized = ref(false);
const selectedReports = ref<Report[]>([]);
const statuses = ref(['Enabled', 'Disabled']);
const IS_ENABLED = ref('ENABLED');

const actionsMenu = ref();

const activeRoles = computed(
  () =>
    userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
    userStore.me.roles.map((role) => role.id).includes(REPORTS_ADMIN_ROLE)
);

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  template_name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  contacts: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  charge_park_id: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  next_report_at: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  active: { value: null, matchMode: IS_ENABLED.value },
});

const matchModes = ref([
  { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
  { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
  { label: 'Contains', value: FilterMatchMode.CONTAINS },
]);

FilterService.register(IS_ENABLED.value, (value, filter): boolean => {
  const convertedValue = value ? 'Enabled' : 'Disabled';

  if (filter === undefined || filter === null) {
    return true;
  }

  return convertedValue === filter;
});

const openEditReportModal = (id: number) => {
  reportEditId.value = id;
  reportsStore.fillReportModalData();
  reportCreateModalIsOpen.value = true;
};

const openDeleteReportModal = (id: number) => {
  reportDeleteIds.value = [{ id: id }];
  reportDeleteModalIsOpen.value = true;
};

const openCreateReportModal = () => {
  reportCreateModalIsOpen.value = true;
};

const actionMenuItems = ref([
  {
    label: t('batchActions.enable'),
    icon: 'pi pi-chevron-circle-down',
    command: () => {
      changeSelectedReportsStatus(true);
    },
  },
  {
    label: t('batchActions.disable'),
    icon: 'pi pi-ban',
    command: () => {
      changeSelectedReportsStatus(false);
    },
  },
  {
    label: t('batchActions.delete'),
    icon: 'pi pi-trash',
    command: () => {
      deleteSelected();
    },
  },
]);

const setActionInfo = (actionName: string) => {
  if (actionName === ENABLE) {
    return t('toast.enabled', { name: t('reports') });
  } else if (actionName === DISABLE) {
    return t('toast.disabled', { name: t('reports') });
  } else {
    return t('toast.successBatch', {
      name: t('reports'),
      action: t('toast.deleted'),
    });
  }
};

const showSuccess = (actionName: string) => {
  toast.add({
    severity: 'success',
    summary: setActionInfo(actionName),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'reportConfiguration',
  });
};

const showError = (actionName: string) => {
  toast.add({
    severity: 'error',
    summary: t('toast.errorBatch', {
      action: actionName === 'delete' ? t('toast.removal') : t('toast.statusChange'),
      name: t('report.title').toLowerCase(),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'reportConfiguration',
  });
};

const toggleActions = (event: any) => {
  actionsMenu.value.toggle(event);
};

const changeSelectedReportsStatus = async (enabled: boolean) => {
  const data: ReportBatchEditData = {
    items: selectedReports.value.map((report) => ({
      id: report.id,
      active: enabled,
    })),
  };

  const action = enabled ? ENABLE : DISABLE;

  try {
    await reportsStore.batchUpdateReports(data);
    selectedReports.value = [];
    showSuccess(action);
    await reportsStore.fetchReportsList();
  } catch {
    showError(action);
  }
};

const deleteSelected = () => {
  selectedReports.value.map((report: Report) => reportDeleteIds.value.push({ id: report.id }));
  reportDeleteModalIsOpen.value = true;
};

onMounted(async () => {
  try {
    await reportsStore.fetchReportTemplateList();
    await reportsStore.fetchReportsList();
  } catch (error: any) {
    if (error.response.status === 403) {
      notAuthorized.value = true;
    } else {
      throw new Error('Reports failed to be fetched');
    }
  } finally {
    loading.value = false;
  }
});
</script>

<style lang="scss" scoped>
.page-title {
  color: $text-medium;
  font-weight: 600;
  font-size: 20px;
}
.page-subtitle {
  color: $text-light;
  font-weight: 400;
  font-size: 14px;
}
</style>
