import type { AxiosPromise, AxiosResponse } from 'axios';
import axios from 'axios';
import type { DowntimeEditData } from '@/models/downtimes.model';

export async function fetchDowntimes(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/downtimes/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteDowntimeRequest(id: number): Promise<AxiosResponse> {
  return axios
    .delete<AxiosPromise>(`/api/downtimes/${id}/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function createDowntime(data: DowntimeEditData): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/downtimes/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function updateDowntime(id: number, data: DowntimeEditData): Promise<AxiosResponse> {
  return axios
    .patch<AxiosPromise>(`/api/downtimes/${id}/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}
