<template>
  <label class="inline-label-dropdown flex items-center border border-gray-200 rounded-md">
    <span
      class="flex rounded-l-lg items-center h-full px-3 bg-gray-100 text-sm border-r font-medium text-gray-500"
    >
      {{ label }}<span v-if="required" class="required">*</span>
    </span>

    <Dropdown
      v-model="value"
      :options="options"
      optionLabel="name"
      optionValue="id"
      class="inline-label-dropdown__input border-none rounded-r-lg rounded-l-none text-sm"
      scrollHeight="240px"
    />
  </label>
</template>

<script setup lang="ts">
import Dropdown from 'primevue/dropdown';
import type { IdName } from '@/models/common.model';
import { useVModel } from '@vueuse/core';

const emit = defineEmits(['update:modelValue']);

const props = withDefaults(
  defineProps<{
    label: string;
    modelValue: number | null;
    options: IdName[];
    required: boolean;
  }>(),
  {
    required: false,
  }
);

const value = useVModel(props, 'modelValue', emit);
</script>

<style lang="scss">
.inline-label-dropdown {
  &__input {
    .p-dropdown-label {
      color: black !important;
      font-weight: 500;
      font-size: 14px;
      padding: 5px 10px;
    }
  }
}

.p-dropdown:not(.p-disabled) {
  &.p-focus {
    box-shadow: none;
  }
}
</style>
