<template>
  <input
    type="text"
    v-model="value"
    @input="setValue"
    :class="{ error: showValidationErrors && !!validationError }"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import {urlRegexPattern} from '@/stores/admin/branding/ui.validation'

const props = defineProps<{
  type: 'string' | 'email' | 'phone' | 'url';
  required?: boolean;
  value?: any;
  defaultValue?: string;
}>();

const emit = defineEmits<{
  (event: 'update:value', value: string, validationError?: string): void;
}>();

const showValidationErrors = ref(false);

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/g;
const phoneRegex = /[0-9]+/g;

function validate(value: string) {
  if (props.required && !value) return 'Required';
  if (props.type === 'email' && !emailRegex.test(value)) return 'Invalid Email Format';
  if (props.type === 'phone' && !phoneRegex.test(value)) return 'Invalid Phone Format';
  if (props.type === 'url' && !urlRegexPattern.test(value)) return 'Invalid Url Format';
  return undefined;
}

function setValue(event: Event) {
  const value = (event.target as HTMLInputElement).value;
  validationError.value = validate(value);
  emit('update:value', value, validationError.value);
}

const value = ref(props.value || '');
const validationError = ref(validate(value.value));
</script>

<style scoped lang="scss">
input[type='text'] {
  border-radius: var(--rounded-md);
  outline: 1px solid var(--gray-200);
  padding: 0.25rem 0.5rem;
  text-align: right;

  &.error {
    outline: 2px solid orangered;

    &:focus {
      outline: 2px solid orangered;
    }
  }
}
</style>
