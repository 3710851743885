<template>
  <Dialog
    data-cy="alert-delete-modal"
    :header="modalTitle"
    v-model:visible="alertDeleteModalIsOpen"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="field flex flex-col field-edit">
      <Message severity="error" icon=" pi pi-exclamation-triangle" :closable="false">
        {{ $t('deleteWarning') }}
      </Message>
      <p class="delete-info">
        {{ $t('alert.deleteInfo') }}
      </p>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        data-cy="alert-delete-button"
        class="p-button-sm p-button-footer p-button-error mx-auto mb-2 block mt-5"
        :label="t('delete')"
        :loading="loading"
        @click="deleteAlert"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="alert-delete-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="delete-alert"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Message from 'primevue/message';
import Toast from 'primevue/toast';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';

import { useAlertsStore } from '@/stores/admin/alerts/alerts.store';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

const { t } = useI18n();
const toast = useToast();
const store = useAlertsStore();
const { alertDeleteModalIsOpen, alertDeleteIds } = storeToRefs(store);

const emit = defineEmits(['delete']);

const loading = ref(false);
const isBatch = computed(() => alertDeleteIds.value.length > 1);
const modalTitle = computed(() =>
  t('delete', { itemName: isBatch.value ? t('alerts') : t('alert.title') })
);

const successSummary = computed(() => {
  return t('toast.success', {
    name: t('alert.title'),
    action: t('toast.deleted'),
  });
});

const errorSummary = computed(() => {
  return t('toast.error', {
    action: t('toast.deletion'),
    name: t('alert.title'),
  });
});

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: successSummary.value,
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'delete-alert',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: errorSummary.value,
    group: 'delete-alert',
  });
};

const closeModal = () => {
  alertDeleteModalIsOpen.value = false;
  alertDeleteIds.value = [];
  loading.value = false;
};

const deleteAlert = async () => {
  loading.value = true;
  toast.removeGroup('delete-alert');

  try {
    await store.batchDeleteAlerts();
    showSuccess();
    closeModal();
    emit('delete');
  } catch (error: unknown) {
    showError();
    throw new Error(error as string);
  } finally {
    await store.fetchAlertsList();
    loading.value = false;
  }
};
</script>
