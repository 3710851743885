<template>
  <fieldset class="flex flex-row items-center gap-2">
    <div class="boolean-item">
      <input
        type="radio"
        :id="props.id + '_true'"
        :name="props.id"
        :value="true"
        v-model="updatedValue"
        :aria-checked="!!updatedValue"
      />
      <label :for="props.id + '_true'">True</label>
    </div>
    <div class="boolean-item">
      <input
        type="radio"
        :id="props.id + '_false'"
        :name="props.id"
        :value="false"
        v-model="updatedValue"
        :aria-checked="!!updatedValue"
      />
      <label :for="props.id + '_false'">False</label>
    </div>
  </fieldset>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps<{
  id: string;
  value: any;
}>();

const emit = defineEmits<{
  (event: 'update:value', value: any): void;
}>();

const updatedValue = ref(props.value ?? false);

watch(updatedValue, (value) => emit('update:value', value));
</script>

<style scoped lang="scss">
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

input {
  appearance: none;
  display: inline-block;

  border-radius: 50%;
  width: 1rem;
  height: 1rem;

  border: 2px solid var(--gray-400);
  transition: 0.1s all linear;

  position: relative;

  &:checked {
    border: 6px solid var(--green-primary);
  }
}

label {
  margin-left: 0.5rem;
  line-height: 1rem;
}

.boolean-item {
  display: flex;
  align-items: center;
  padding: 0.25rem;
}
</style>
