<template>
  <span class="category block">{{ name }}</span>
</template>

<script lang="ts">
export default {
  name: 'SidebarCategory',
  props: {
    name: {
      type: String,
    },
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.category {
  color: var(--gray-300);
  font-size: 14px;
  line-height: 16px;
}
</style>
