import type { Configuration, EndSessionRequest } from '@azure/msal-browser';
import { PublicClientApplication, type RedirectRequest } from '@azure/msal-browser';
import { ROUTES } from '@/utils/routeNames';

export const signInRequest: RedirectRequest = {
  scopes: ['openid', 'profile', 'offline_access'],
  authority: import.meta.env.VITE_B2C_SIGNIN_AUTHORITY,
};

export const signUpRequest: RedirectRequest = {
  scopes: ['openid', 'profile', 'offline_access'],
  authority: import.meta.env.VITE_B2C_SIGNUP_AUTHORITY,
};

export const acquireTokenSignInRequest: RedirectRequest = {
  scopes: [import.meta.env.VITE_B2C_CLIENT_ID],
  authority: import.meta.env.VITE_B2C_SIGNIN_AUTHORITY,
};

export const signOutRequest: EndSessionRequest = {
  postLogoutRedirectUri: `${window.location.origin}/`,
  authority: import.meta.env.VITE_B2C_SIGNIN_AUTHORITY,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_B2C_CLIENT_ID,
    knownAuthorities: import.meta.env.VITE_B2C_KNOWN_AUTHORITIES?.split(',') || [],
    redirectUri: `${window.location.origin}/${ROUTES.OPENID_CALLBACK}`,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message) => {
        console.log(`MSAL Logging: ${message}`);
      },
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export async function logout() {
  await msalInstance.logoutRedirect(signOutRequest).catch((e) => console.log(e));
}

export async function isLoggedIn(): Promise<boolean> {
  await msalInstance.initialize();
  return msalInstance.getAllAccounts().length > 0;
}
