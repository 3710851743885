<template>
  <div>
    <PanelWithEdit
      :title="$t('hardwareSystem.siteContactInfo')"
      :loading="isLoading"
      :editBtn="false"
    >
      <template #header>
        <Button
          :label="$t('site')"
          icon="pi pi-external-link"
          class="p-button-outlined p-button-white p-button-xs"
          :disabled="contactDetails.name === ''"
          @click="onSiteOpen"
        />
      </template>
      <div class="flex justify-between w-full my-2">
        <div class="flex flex-col w-1/2">
          <span
            v-for="(value, key) in contactDetails"
            :key="key"
            data-cy="system-contact-field-name"
            class="block font-medium py-3"
            >{{ $t(`hardwareSystem.contact.${key}`) }}</span
          >
        </div>
        <div class="flex flex-col w-full">
          <div class="my-3 text-right" v-for="(value, key) in contactDetails" :key="key">
            <span
              data-cy="system-contact-field-value"
              :class="`font-medium ${
                value ? 'py-3 pl-4 text-[var(--gray-400)]' : 'empty py-2 px-4'
              }`"
              >{{ value ? value : '--' }}</span
            >
          </div>
        </div>
      </div>
    </PanelWithEdit>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { ref, onMounted, computed, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { CHARGE_PARKS } from '@/utils/routeNames';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import Button from 'primevue/button';

const router = useRouter();
const chargeParksStore = useChargeParksStore();
const { chargeParkEditionData } = storeToRefs(chargeParksStore);
const systemsStore = useHardwareSystemsStore();
const { singleSystem } = storeToRefs(systemsStore);

const isLoading = ref(true);

const contactDetails = computed(() => {
  return {
    name: chargeParkEditionData.value.name,
    contact_name: chargeParkEditionData.value.contact_name,
    contact_email: chargeParkEditionData.value.contact_email,
    contact_phone: chargeParkEditionData.value.contact_phone,
  };
});

const onSiteOpen = () => {
  router.push({ path: `/${CHARGE_PARKS}/${singleSystem.value.charge_park_id}` });
};

onMounted(async () => {
  if (singleSystem.value.charge_park_id) {
    await chargeParksStore.fetchChargePark(singleSystem.value.charge_park_id).then(() => {
      isLoading.value = false;
    });
  }
});

onUnmounted(async () => {
  chargeParksStore.resetChargeParkEditionData();
});
</script>

<style scoped lang="scss"></style>
