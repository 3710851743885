<template>
  <div class="px-12 py-1">
    <ReadOnlyItem :name="$t('name')" :value="tariffModalData.name" />
    <ReadOnlyItem :name="$t('tariff.tariffDetails.currency')" :value="currencyDetails" />
    <ReadOnlyItem v-if="showSpecialRates" :name="$t('tariff.specialRates')" :value="specialRates" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';
import { normalizeSelectedCurrency } from '@/utils/currency';
import { isSpecialRate } from '@/models/tariffs.model';
import { useI18n } from 'vue-i18n';

import ReadOnlyItem from '@/components/common/readOnly/ReadOnlyItem.vue';

const tariffsStore = useTariffsStore();
const { tariffModalData, currencyTypes } = storeToRefs(tariffsStore);

withDefaults(
  defineProps<{
    showSpecialRates?: boolean;
  }>(),
  {
    showSpecialRates: false,
  }
);

const { t } = useI18n();

const specialRates = computed(() => {
  return tariffModalData.value.rates.filter(isSpecialRate).length + ' ' + t('tariff.specialRates');
});

const currencyDetails = computed(() => {
  if (typeof tariffModalData.value.currency === 'number') {
    return normalizeSelectedCurrency(currencyTypes.value, tariffModalData.value.currency).name;
  } else {
    return `${tariffModalData.value.currency} (${tariffModalData.value.currency_symbol})`;
  }
});
</script>
