<template>
  <Dialog
    data-cy="contact-verify-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="flex field-no-edit">
      <i class="pi pi-info-circle text-grey mr-3 self-center" />
      <span class="text-grey transfer-info">{{
        contactVerificationData.type === 'SMS' ? $t('contact.typeSms') : $t('contact.typeEmail')
      }}</span>
    </div>
    <div class="flex field-no-edit">
      <div class="w-full info-single">
        <div class="flex justify-between py-2 info-item">
          <span class="text-grey-medium font-semibold">{{ $t('type') }}</span>
          <span class="text-grey-medium">{{
            contactVerificationData.type === 'SMS' ? $t('sms') : $t('email')
          }}</span>
        </div>
        <div class="flex justify-between py-2 info-item">
          <span class="text-grey-medium font-semibold">{{ $t('address') }}</span>
          <span class="text-grey-medium">{{
            contactVerificationData.type === 'SMS'
              ? contactVerificationData.phone_number
              : contactVerificationData.email
          }}</span>
        </div>
      </div>
    </div>
    <div class="field flex flex-col field-edit pb-0">
      <label for="code" class="mb-2 field-edit-label"
        >{{ $t('contact.code') }}<span class="required">*</span></label
      >
      <InputText
        data-cy="contact-verify-code-input"
        id="code"
        type="text"
        aria-describedby="code-help"
        class="p-inputtext-sm"
        v-model="verificationCode"
        :placeholder="$t('contact.code')"
        @keyup="validateVerificationCode"
      />
      <div class="input-errors" v-for="(error, index) of v$.verificationCode.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
      <div class="flex items-center">
        <span class="text-grey-medium text-sm resend-text">{{
          $t('contact.verificationResend')
        }}</span>
        <Button
          data-cy="contact-resend-code-button"
          :label="t('contact.resend')"
          class="p-button-sm p-button-link underline"
          @click="resendVerificationCode"
        />
      </div>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        data-cy="contact-verify-code-button"
        :label="t('apply')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        :disabled="verifyFormIsNotValid"
        :loading="loading"
        @click="verifyContactCode"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="contact-verify-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="contact-verify-code"
  >
    <template v-if="isFailed" #message="slotProps">
      <div class="flex flex-col w-full pt-1">
        <div class="w-full flex">
          <span class="p-toast-message-icon pi pi-info-circle"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
          </div>
        </div>
        <span class="my-2 ml-8 font-medium">{{ verifyErrorMessage }}</span>
      </div>
    </template>
  </Toast>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useContactsStore } from '@/stores/admin/contacts/contacts.store';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { helpers, maxLength } from '@vuelidate/validators';
import { asciiCharacters } from '@/utils/validationRules';
import { useToast } from 'primevue/usetoast';
import { MAX_LENGTH, VERIFY, RESEND, DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Toast from 'primevue/toast';

const { t } = useI18n();
const toast = useToast();
const contactsStore = useContactsStore();
const { contactVerifyModalIsOpen, contactVerificationData } = storeToRefs(contactsStore);
const isVisible = ref(false);
const loading = ref(false);
const verificationCode = ref();
const isFailed = ref(false);
const verifyErrorMessage = ref('');

const modalTitle = computed(() => t('contact.verifyContact'));

const closeModal = () => {
  contactVerifyModalIsOpen.value = false;
  loading.value = false;

  resetForm();
};

const showSuccess = (type: string) => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: type === VERIFY ? t('contact.title') : t('contact.code'),
      action: type === VERIFY ? t('toast.verified') : t('toast.resent'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'contact-verify-code',
  });
};

const showError = (type: string) => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: type === VERIFY ? t('toast.verification') : t('toast.resend'),
      name: type === VERIFY ? t('contact.title') : t('contact.code'),
    }),
    group: 'contact-verify-code',
  });
};

const resendVerificationCode = async () => {
  loading.value = true;

  await contactsStore
    .sendVerificationCode(contactVerificationData.value.id)
    .then((response: any) => {
      loading.value = false;
      if (response.status === 200) {
        toast.removeAllGroups();
        showSuccess(RESEND);
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeAllGroups();
      showError(RESEND);
      throw new Error(error);
    });
};

const verifyContactCode = async () => {
  await v$.value.verificationCode.$validate();

  if (v$.value.verificationCode.$error) return;

  loading.value = true;

  await contactsStore
    .verifyNewContactCode(contactVerificationData.value.id, verificationCode.value)
    .then((response: any) => {
      loading.value = false;
      if (response.status === 200) {
        toast.removeAllGroups();
        isFailed.value = false;
        showSuccess(VERIFY);
        closeModal();
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('contact-verify-code');
      if (error.response && error.response.status === 400 && error.response.data) {
        verifyErrorMessage.value = error.response.data.error_details.error_message;
        isFailed.value = true;
        toast.removeAllGroups();
        showError(VERIFY);
      } else {
        showError(VERIFY);
      }
      throw new Error(error);
    });
};

watch(
  () => contactVerifyModalIsOpen.value,
  (value) => {
    if (value) {
      isVisible.value = true;
    } else {
      isVisible.value = false;
    }
  }
);

const validateVerificationCode = async () => {
  await v$.value.verificationCode.$validate();
};

const resetForm = () => {
  verificationCode.value = '';
  v$.value.verificationCode.$reset();
  contactsStore.resetContactVerifyData();
  if (isFailed.value) {
    isFailed.value = false;
    verifyErrorMessage.value = '';
  }
};

const verifyFormIsNotValid = computed(() => {
  return !v$.value.$anyDirty ||
    (v$.value.verificationCode.$anyDirty && v$.value.verificationCode.$error)
    ? true
    : false;
});

const rules = computed(() => {
  return {
    verificationCode: {
      asciiCharacters: helpers.withMessage(
        t('rules.asciiCharacters', { field: t('name') }),
        asciiCharacters
      ),
      maxLength: maxLength(MAX_LENGTH),
    },
  };
});

const v$ = useVuelidate(rules, verificationCode.value);
</script>
<style lang="scss" scoped>
.resend-text {
  padding: 8px 4px 8px 0;
}
</style>
