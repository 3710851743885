<template>
  <div class="flex flex-col h-full">
    <TopBar />
    <div class="flex flex-row w-full h-full overflow-hidden">
      <Sidebar v-if="usesSidebarLayout" />
      <div class="wrapper">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import Sidebar from './sidebar/Sidebar.vue';
import TopBar from '@/components/common/layout/TopBar.vue';

const route = useRoute();

const usesSidebarLayout = computed(() => route.meta['requiresAuth'] === true);
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors';

.wrapper {
  background-color: $grey-light;
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
</style>
