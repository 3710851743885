<template>
  <div class="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 general">
    <HardwareSystemInfo v-if="!isLoading" />
    <HardwareSystemLocation class="row-span-4" v-if="!isLoading" />
    <HardwareSystemContact v-if="!isLoading" />
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { storeToRefs } from 'pinia';

import HardwareSystemInfo from './info/HardwareSystemInfo.vue';
import HardwareSystemContact from './contact/HardwareSystemContact.vue';
import HardwareSystemLocation from './location/HardwareSystemLocation.vue';

const systemsStore = useHardwareSystemsStore();
const { singleSystem } = storeToRefs(systemsStore);
const isLoading = ref(true);

watch(
  singleSystem.value,
  (value) => {
    value.id ? (isLoading.value = false) : (isLoading.value = true);
  },
  { immediate: true }
);
</script>
<style scoped lang="scss">
.general {
  background-color: $grey-light;
  padding: 20px;
}
</style>
