<template>
  <div :class="[widthClass, heightClass]">
    <img
      data-cy="media-library-thumbnail"
      :src="thumbnailUrl"
      :alt="media.file_name"
      class="object-cover rounded-md w-full h-full overflow-hidden text-xs"
    />
  </div>
</template>

<script setup lang="ts">
import type { Media } from '@/models/media.model';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    media: Media;
    widthClass: string;
    heightClass: string;
  }>(),
  {
    widthClass: 'w-24',
    heightClass: 'h-12',
  }
);

const thumbnailUrl = computed(() => {
  if (props.media.thumbnail) return props.media.thumbnail;

  if (props.media.file_type.toLowerCase() === 'image') return '/images/image-placeholder.jpg';

  return '/images/video-placeholder.jpg';
});
</script>
