<template>
  <DataTable
    data-cy="users-table"
    :value="data.users"
    v-model:filters="filters"
    filterDisplay="menu"
    class="child-table p-datatable-sm mb-5 mt-2"
  >
    <template #empty>
      <span data-cy="users-table-empty" class="w-full block text-center">{{
        $t('user.empty')
      }}</span>
    </template>
    <Column
      field="name"
      :header="$t('user.user')"
      :sortable="true"
      :showFilterMatchModes="false"
      filter-field="email"
      :style="{ 'min-width': '18rem' }"
    >
      <template #body="{ data }">
        <div class="flex flex-col justify-start">
          <span :id="data.id" data-cy="user-name" class="font-medium">{{ data.name }}</span>
          <span :id="data.id" data-cy="user-email" class="text-xs text-gray-400">{{
            data.email
          }}</span>
        </div>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter mb-2"
          :placeholder="$t('searchBy', { name: $t('email') })"
        />
      </template>
    </Column>
    <Column
      field="roles"
      :header="$t('user.role.roles')"
      :sortable="true"
      :showFilterMatchModes="false"
      :style="{ 'white-space': 'nowrap', 'min-width': '7rem' }"
    >
      <template #body="{ data }">
        <div data-cy="user-roles" v-if="data.roles.length > 0" class="flex items-center">
          <span :class="`${data.roles.length < 10 ? 'pl-2' : 'pl-0'} pr-1.5 text-sm font-medium`">{{
            data.roles.length
          }}</span>
          <i
            class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5"
            v-tooltip.right="{
              value: `
              <div>${data.roles.map((role: UserRole) => `<span class='custom-tag'>${role.name}</span>`).join('')}</div>`,
              escape: true,
              class: 'custom-tooltip',
            }"
          ></i>
        </div>
        <span v-else class="empty pl-4">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      field="status"
      :header="$t('status')"
      :sortable="true"
      :showFilterMatchModes="false"
      :style="{ 'white-space': 'nowrap', 'min-width': '6rem' }"
    >
      <template #body="{ data }">
        <Tag
          data-cy="user-status"
          class="mr-2 status-tag"
          :severity="setSeverity(data.status)"
          :value="setStatus(data.status)"
          rounded
        ></Tag>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="statuses"
          :placeholder="$t('select', { name: $t('status') })"
          class="p-column-filter mb-2"
          :showClear="true"
        >
        </Dropdown>
      </template>
    </Column>
    <Column
      field="created_by_user_name"
      :header="$t('user.createdBy')"
      :sortable="true"
      :showFilterMatchModes="false"
      :style="{ 'min-width': '145px' }"
    >
      <template #body="{ data }">
        <span data-cy="user-created-by">{{ data.created_by_user_name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('user.createdBy') })"
        />
      </template>
    </Column>
    <Column
      field="deleted_by_user_name"
      :header="$t('deletedBy')"
      :sortable="true"
      :showFilterMatchModes="false"
      :style="{ 'min-width': '145px' }"
    >
      <template #body="{ data }">
        <span
          v-if="!data.deleted_by_user_name || data.deleted_by_user_name.length === 0"
          data-cy="user-created-by"
          class="empty"
          >--</span
        >
        <span v-else>{{ data.deleted_by_user_name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('deletedBy') })"
        />
      </template>
    </Column>
    <Column
      field="deleted_at"
      :header="$t('deletedAt')"
      :sortable="true"
      :style="{ 'min-width': '145px' }"
    >
      <template #body="{ data }"> <date-time-display :date="data.deleted_at" /></template>
    </Column>
    <Column
      v-if="activeRoles"
      field="actions"
      :header="$t('actions')"
      class="no-break"
      :style="{ 'min-width': '12.5rem' }"
    >
      <template #body="slotProps">
        <Button
          :data-cy="`open-edit-user-modal-${slotProps.data.id}`"
          :label="$t('edit')"
          icon="pi pi-pencil"
          :disabled="slotProps.data.deleted_at"
          class="mr-3 p-button-outlined p-button-plain p-button-sm"
          @click="openUserModal(data, slotProps.data)"
        />
        <Button
          :data-cy="`open-delete-user-modal-${slotProps.data.id}`"
          :label="$t('delete')"
          icon="pi pi-trash"
          :disabled="slotProps.data.deleted_at"
          class="p-button-sm p-button-danger"
          @click="openDeleteUserModal(slotProps.data)"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script lang="ts">
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import dayjs from 'dayjs';

import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useOrganisationsStore } from '@/stores/admin/organizations/organisations.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { FilterMatchMode, FilterService } from 'primevue/api';
import { ADMINISTRATOR_ROLE, USER_ADMIN_ROLE } from '@/utils/constants';

import type { OrganisationWithUsers } from '@/models/organisation.model';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { UserEditData, User, UserRole } from '@/models/users.model';
import type { PropType } from 'vue';
import { UserStatus } from '@/models/users.model';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';

export default {
  name: 'UsersTable',

  components: {
    DateTimeDisplay,
    DataTable,
    Column,
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    InputText,
    Dropdown,
    Tag,
  },

  props: {
    data: {
      type: Object as PropType<OrganisationWithUsers>,
      default: () => {},
    },
  },

  setup() {
    const organisationsStore = useOrganisationsStore();
    const usersStore = useUsersStore();
    const { organisations } = storeToRefs(organisationsStore);
    const {
      me,
      userModalIsOpen,
      userOrganisation,
      userIsEditing,
      userDeleteModalIsOpen,
      userDeleteData,
    } = storeToRefs(usersStore);
    const statuses = ref(['Invited', 'Active', 'Deleted']);
    const CUSTOM_STATE = ref('CUSTOM FILTER');

    const activeRoles = computed(() => {
      return (
        me.value.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
        me.value.roles.map((role) => role.id).includes(USER_ADMIN_ROLE)
      );
    });

    const setStatus = (status: number) => {
      switch (status) {
        case 2:
          return 'Active';
        case 3:
          return 'Deleted';
        default:
          return 'Invited';
      }
    };

    const setSeverity = (status: number) => {
      switch (status) {
        case 2:
          return 'success';
        case 3:
          return 'danger';
        default:
          return 'info';
      }
    };

    const openUserModal = (organisation: any, data: any) => {
      const { id, name, email, organisation_id, roles } = data;

      const userData: UserEditData = {
        id: id,
        name: name,
        email: email,
        organisation_id: organisation_id,
        roles: roles,
      };

      usersStore.setUserEditionData(userData);
      userOrganisation.value.id = organisation.id;
      userOrganisation.value.name = organisation.name;
      userOrganisation.value.roles = organisation.available_roles;
      userIsEditing.value = true;
      userModalIsOpen.value = true;
    };

    const openDeleteUserModal = (data: User) => {
      userDeleteData.value.id = data.id;
      userDeleteData.value.organisation_id = data.organisation_id;
      userDeleteData.value.name = data.name;
      userDeleteData.value.email = data.email;
      userDeleteModalIsOpen.value = true;
    };

    FilterService.register(CUSTOM_STATE.value, (value, filter): boolean => {
      let convertedValue;

      if (value === UserStatus.INVITED) {
        convertedValue = 'Invited';
      }

      if (value === UserStatus.ACTIVE) {
        convertedValue = 'Active';
      }

      if (value === UserStatus.DELETED) {
        convertedValue = 'Deleted';
      }

      if (filter === undefined || filter === null) {
        return true;
      }

      return convertedValue === filter;
    });

    const matchModes = ref([
      { label: 'Contains', value: FilterMatchMode.CONTAINS },
      { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
      { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
    ]);

    const filters = ref({
      email: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      status: { value: null, matchMode: CUSTOM_STATE.value },
      created_by_user_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      deleted_by_user_name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
    });

    return {
      organisations,
      openUserModal,
      dayjs,
      openDeleteUserModal,
      filters,
      matchModes,
      statuses,
      activeRoles,
      setStatus,
      setSeverity,
    };
  },
};
</script>

<style lang="scss" scoped>
.status-tag {
  width: 64px;
  font-size: 14px;
  padding: 2px 8px;
}
</style>
