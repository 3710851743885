<template>
  <div class="sortable-list-container">
    <div
      class="row-divider default-height-0"
      :class="{
        'is-dragging': optionValueBeingDragged !== null,
        'is-closest-gap': 0 === closestGap,
      }"
      @dragover.prevent
      @drop.prevent
    />

    <template v-for="(option, index) of options" :key="option.value">
      <div
        class="option"
        draggable="true"
        @dragstart="(e) => dragstart(option, e)"
        @dragover.prevent="(e) => dragover(option, e)"
        @dragend.prevent="(e) => drop(option, e)"
        @drop.prevent
        :class="{ hide: optionValueBeingDragged === option.value }"
      >
        <Bars3Icon class="icon" />
        <input
          type="checkbox"
          :checked="option.selected"
          @change="() => toggleOption(option.value)"
        />
        <p>{{ option.label }}</p>
      </div>

      <div
        v-if="index < options.length"
        class="row-divider"
        :class="{
          'is-dragging': optionValueBeingDragged !== null,
          'is-closest-gap': index + 1 === closestGap,
          'default-height-0': index + 1 === options.length,
        }"
        @dragover.prevent
        @drop.prevent
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { Bars3Icon } from '@heroicons/vue/20/solid';

const props = defineProps<{
  values: string[];
  options: { value: string; label?: string | null }[];
}>();

const optionValueBeingDragged = ref<string | null>(null);
const closestGap = ref(0);

type DraggableSelectableOption = {
  value: string;
  label: string;
  selected: boolean;
};

const defaultAllOptionsInCorrectOrder: DraggableSelectableOption[] = props.values
  .map((v) => {
    const option = props.options.find((o) => o.value === v);
    return { value: v, label: option?.label ?? v, selected: true };
  })
  .concat(
    props.options
      .filter((o) => !props.values.includes(o.value))
      .map((o) => ({
        value: o.value,
        label: o.label ?? o.value,
        selected: false,
      }))
  );

const options = ref(defaultAllOptionsInCorrectOrder);

const emit = defineEmits<{
  (e: 'update:values', value: string[]): void;
}>();

function dragstart(option: DraggableSelectableOption, e: DragEvent) {
  optionValueBeingDragged.value = option.value;
}

function dragover(option: DraggableSelectableOption, e: DragEvent) {
  e.preventDefault();
  const index = options.value.findIndex((o) => o.value === option.value);
  const rect = (e.currentTarget as HTMLDivElement).getBoundingClientRect();
  const isInTopOfValue = e.clientY - rect.top < rect.height / 2;
  closestGap.value = isInTopOfValue ? index : index + 1;
}

function drop(option: DraggableSelectableOption, e: DragEvent) {
  e.preventDefault();
  reorder(option, closestGap.value);
  optionValueBeingDragged.value = null;
}

function toggleOption(value: string) {
  options.value = options.value.map((o) =>
    o.value === value ? { ...o, selected: !o.selected } : o
  );
}

function reorder(option: DraggableSelectableOption, gapIndex: number) {
  const before = options.value.slice(0, gapIndex).filter((o) => o.value !== option.value);
  const after = options.value.slice(gapIndex).filter((o) => o.value !== option.value);
  options.value = [...before, option, ...after];
}

watch(
  options,
  (updated) => {
    emit(
      'update:values',
      updated.filter((o) => o.selected).map((o) => o.value)
    );
  },
  { deep: true }
);
</script>

<style scoped lang="scss">
.sortable-list-container {
  max-height: 15rem;
  overflow-y: auto;
  min-width: 20rem;
  border: 1px solid var(--gray-200);
  border-radius: var(--rounded-md);
}

.option {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
  white-space: nowrap;
  background: white;
}

p {
  user-select: none;
}

.icon {
  width: 1rem;
  height: 1rem;
  color: var(--gray-400);
  cursor: grab;
}

.option:hover .icon {
  color: var(--gray-600);
}

div.row-divider {
  margin: 0;
  border: none;
  height: 1px;
  background-color: var(--gray-200);
  transition: height 0.2s, background-color 0.2s, margin 0.2s;

  &.default-height-0 {
    height: 0;
  }

  &.is-dragging.is-closest-gap {
    background-color: var(--blue-primary);
    height: 3px;
    margin-top: -1px;
    margin-bottom: -1px;
  }
}
</style>
