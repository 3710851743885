<template>
  <div
    :class="`flex text-sm pt-2 pb-1 ${
      reportModalData.contacts.length > 6 ? 'pl-12 pr-6' : 'px-12'
    }`"
  >
    <div class="w-full info-single">
      <div
        v-if="reportModalData.contacts.length > 0"
        :class="`${reportModalData.contacts.length > 6 ? 'h-[190px] overflow-y-scroll' : ''}`"
      >
        <ReadOnlyTitle :title="$t('contacts')" />
        <div v-for="(contact, index) in reportsStore.selectedContacts" :key="index">
          <ReadOnlyItem
            v-if="contact.email"
            :name="contact.email"
            :value="$t('email')"
            connector
            :class="`${reportModalData.contacts.length > 6 ? 'pr-[7px]' : ''}`"
          />
          <ReadOnlyItem
            v-if="contact.type === 'SMS'"
            :name="prettify(`${contact.name}`)"
            :value="$t('sms')"
            connector
            :class="`${reportModalData.contacts.length > 6 ? 'pr-[7px]' : ''}`"
          />
        </div>
      </div>
      <div v-if="reportModalData.contacts.length === 0" class="flex justify-end py-1">
        <ReadOnlyTitle :title="$t('contacts')" inline />
        <div class="flex justify-end items-center w-1/2">
          <i class="pi pi-exclamation-triangle text-amber-500 text-lg ml-1 mr-2"></i>
          <span
            v-html="$t('report.preview.noContactSelected')"
            class="font-medium text-amber-500"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { prettify } from '@/utils/textFormatting';
import ReadOnlyItem from '@/components/common/readOnly/ReadOnlyItem.vue';
import ReadOnlyTitle from '@/components/common/readOnly/ReadOnlyTitle.vue';

import { storeToRefs } from 'pinia';
import { useReportsStore } from '@/stores/admin/reports/reports.store';

const reportsStore = useReportsStore();
const { reportModalData } = storeToRefs(reportsStore);
</script>
