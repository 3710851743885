<template>
  <div v-if="isLoading" class="loading-container">Loading...</div>
  <data-table
    v-if="data"
    :value="data.rows"
    paginator
    always-show-paginator
    :rows="10"
    filterDisplay="menu"
  >
    <column
      v-for="header of data.headers"
      :key="header.key"
      :column-key="header.key"
      :header="header.label"
      :field="(item: any) => item[header.key]?.value"
      :sortable="header.dimension === undefined"
    >
      <template #body="data">
        <router-link
          v-if="header.dimension === 'chargepark.id'"
          :to="`/${CHARGE_PARKS}/${data.data[header.key]?.id}`"
          class="custom-link"
        >
          {{ data.data[header.key]?.value }}
          <arrow-top-right-on-square-icon class="h-4 w-auto" />
        </router-link>

        <router-link
          v-else-if="header.dimension === 'system.id'"
          :to="`/${HARDWARE_SYSTEMS}/${data.data[header.key]?.id}`"
          class="custom-link"
        >
          {{ data.data[header.key]?.value }}
          <arrow-top-right-on-square-icon class="h-4 w-auto" />
        </router-link>

        <span v-else :class="{ empty: data.data[header.key]?.value === null }">{{
          data.data[header.key]?.value ?? '--'
        }}</span>
      </template>
    </column>
  </data-table>
</template>

<script setup lang="ts">
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import type { TableWidget, WidgetFilter } from '@/stores/admin/dashboard/dashboard.types';
import type { TableData } from '@/stores/admin/dashboard/dashboard.logic';
import { loadTableWidgetData } from '@/stores/admin/dashboard/dashboard.logic';
import { onMounted, ref } from 'vue';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline';
import { HARDWARE_SYSTEMS, CHARGE_PARKS } from '@/utils/routeNames';
import type { WidgetContextResolver } from '@/stores/admin/dashboard/dashboard.logic.context';

const props = defineProps<{
  widget: TableWidget;
  additionalFilters: WidgetFilter[];
  contextResolver: WidgetContextResolver;
}>();

const isLoading = ref(false);
const error = ref<Error | null>(null);
const data = ref<TableData | null>(null);

const usersStore = useUsersStore();

onMounted(async () => {
  try {
    isLoading.value = true;
    await usersStore.fetchUserData();
    data.value = await loadTableWidgetData(
      props.widget,
      props.additionalFilters,
      props.contextResolver,
      usersStore.me.units
    );
  } catch (e) {
    error.value = e as Error;
  } finally {
    isLoading.value = false;
  }
});
</script>

<style scoped lang="scss">
.custom-link {
  display: flex;
  align-items: center;
  margin-right: auto;
  flex-shrink: 1;
  gap: 0.5rem;
  border-radius: var(--rounded-sm);
  border: 1px solid var(--gray-200) !important;
  padding: 0.25rem 0.5rem;
  text-decoration: none;

  &:hover {
    background-color: var(--gray-100);
  }
}

.empty {
  color: var(--gray-300);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  width: 100%;
  background-color: var(--gray-20);
  color: var(--gray-300);
}
</style>
