<template>
  <span v-if="dateString !== null" :class="{ 'font-semibold': props.bold }">
    {{ props.relative ? relativeDateString : dateString }}
  </span>
  <span v-else class="unknown">--</span>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import {
  formatDateTimeWithUsersTimezone,
  timezoneUtcOffsetHours,
  forTimezoneUtcOffsetHours,
  formatDateTimeWithTimezone,
  formatDateWithUsersTimezone,
  formatDateWithTimezone,
} from '@/utils/dateTimeFormatVue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
type Props = {
  date?: Date | string | number | null; // Number = unix epoch in seconds
  showTimezone?: boolean;
  bold?: boolean;
  overrideTimezone?: string;
  onlyDate?: boolean;
  relative?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  timestyle: 'medium',
  dateStyle: 'medium',
  showTimezone: true,
});
const relativeDateString = computed(() => {
  if (!props.date) return null;
  return dayjs().to(dayjs(props.date));
});

const dateString = computed(() => {
  if (!props.date) return null;
  let resultString = '';
  if (props.onlyDate) {
    if (props.overrideTimezone) {
      resultString += formatDateWithTimezone(props.date, props.overrideTimezone);
    } else {
      resultString += formatDateWithUsersTimezone(props.date);
    }
  } else {
    if (props.overrideTimezone) {
      resultString += formatDateTimeWithTimezone(props.date, props.overrideTimezone);
    } else {
      resultString += formatDateTimeWithUsersTimezone(props.date);
    }
  }
  if (props.showTimezone) {
    resultString += ' UTC';
    if (props.overrideTimezone) {
      resultString += forTimezoneUtcOffsetHours.value(
        props.date ? props.date : new Date(),
        props.overrideTimezone
      );
    } else {
      resultString += timezoneUtcOffsetHours.value(props.date ? props.date : new Date());
    }
  }
  return resultString;
});
</script>

<style scoped lang="scss">
span {
  font-size: 0.9rem;
  color: var(--gray-600);

  &.unknown {
    color: var(--gray-400);
  }
}
</style>
