import { defineStore } from 'pinia';

interface GeneralState {
  title: String;
}

export const useGeneralStore = defineStore('general', {
  state: (): GeneralState => ({
    title: '',
  }),
  actions: {},
});
