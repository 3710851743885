import type { AxiosPromise, AxiosResponse } from 'axios';
import axios from 'axios';
import type { PlaylistCreationData, PlaylistMediaCreationData } from '@/models/playlists.model';

export async function fetchPlaylists(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/playlists/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchPlaylistMedia(id: number): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/playlist-media/?playlist_id=${id}`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchPlaylist(id: number): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/playlists/${id}/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createNewPlaylist(data: PlaylistCreationData): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/playlists/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function createNewPlaylistMedia(
  data: PlaylistMediaCreationData
): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/playlist-media/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function updatePlaylist(
  id: number,
  data: PlaylistCreationData
): Promise<AxiosResponse> {
  return axios
    .patch<AxiosPromise>(`/api/playlists/${id}/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function updatePlaylistMedia(
  id: number,
  data: PlaylistMediaCreationData
): Promise<AxiosResponse> {
  return axios
    .patch<AxiosPromise>(`/api/playlist-media/${id}/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function deletePlaylist(id: number): Promise<AxiosResponse> {
  try {
    return await axios.delete<AxiosPromise>(`/api/playlists/${id}/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deletePlaylistMedia(id: number): Promise<AxiosResponse> {
  try {
    return await axios.delete<AxiosPromise>(`/api/playlist-media/${id}/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchMediaScaling(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/media-scaling/`);
  } catch (error) {
    return Promise.reject(error);
  }
}
