<template>
  <div class="image-widget" :style="{ backgroundColor }">
    <img :src="props.image.url" :style="{ height }" alt="Image" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ImageWidget } from '@/models/controlPanel/dashboard';

const props = defineProps<{ image: ImageWidget }>();

const height = computed(() => {
  if (props.image.maxHeightPx) return props.image.maxHeightPx + 'px';
  else return 'auto';
});

const backgroundColor = computed(() => {
  switch (props.image.background) {
    case 'white':
      return 'var(--gray-0)';
    case 'light-grey':
      return 'var(--gray-100)';
    default:
      return 'var(--gray-100)';
  }
});
</script>

<style lang="scss" scoped>
div.image-widget {
  background-color: var(--gray-100);
}

img {
  object-fit: contain;
  width: 100%;
}
</style>
