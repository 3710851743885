<template>
  <page-layout>
    <router-view />
  </page-layout>
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router';
import PageLayout from '@/components/common/layout/PageLayout.vue';
import { isLoggedIn } from '@/services/auth/config';

window.addEventListener('focus', () => {
  isLoggedIn().then((loggedIn) => {
    if (!loggedIn) {
      window.location.reload();
    }
  });
});
</script>
