<template>
  <div class="telemetry-header">
    <p class="label">{{ prettify(props.widget.label) }}</p>
    <div class="time-container" :data-cy="`${widget.path}-last-value`">
      <date-time-display :date="telemetryValue" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { type Events, data } from '@/logic/controlPanel/eventbus';
import { prettify } from '@/utils/textFormatting';
import type { DateTimeWidget } from '@/models/controlPanel/dashboard';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';

const props = defineProps<{
  systemId: HardwareSystem['id'];
  widgetId: string;
  widget: DateTimeWidget;
}>();

const telemetryValue = ref<string | number | null>(Date.now());

const onNewTelemetryData = (event: Events['telemetry']) => {
  if (event.path.endsWith(props.widget.path)) {
    if (typeof event.value === 'boolean') return;
    else if (typeof event.value === 'number') telemetryValue.value = event.value * 1000;
    else telemetryValue.value = event.value;
  }
};

onMounted(() => {
  data.on('telemetry', onNewTelemetryData);
});

onUnmounted(() => {
  data.off('telemetry', onNewTelemetryData);
});
</script>

<style scoped lang="scss">
div.telemetry-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
  box-sizing: border-box;
  border-radius: var(--rounded-sm);
}

.time-container {
  padding: 0.25rem 0.5rem;
  border-radius: var(--rounded-sm);
  background-color: #fafafa;
}

p.label {
  margin: 0;
  padding: 0;
  color: var(--gray-600);
}
</style>
