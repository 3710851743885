<template>
  <div class="telemetry-widget-container">
    <div class="telemetry-header">
      <p class="label">{{ prettify(props.widget.label) }}</p>
      <div class="telemetry-value-container">
        <pre
          class="telemetry-value"
          :class="{ stale: isStaleData }"
          :style="{ color: converted.colorForeground, backgroundColor: converted.colorBackground }"
          :data-cy="`${props.widget.path}-last-value`"
          @mouseenter="showValueDetails = true"
          @mouseleave="showValueDetails = false"
        >
          <span>{{ renderValue(converted.value) }}</span>
          <span v-if="converted.unit" class="ml-1">{{ converted.unit }}</span>
        </pre>

        <button
          v-if="showTimeseriesButton"
          class="expand"
          :class="{ expanded }"
          :data-cy="`${props.widget.path}-show-history`"
          @click="toggleTimeseries"
        >
          <i class="pi pi-chart-bar" />
        </button>
      </div>
    </div>
    <div class="telemetry-history" v-if="expanded">
      <telemetry-history
        :system-id="props.systemId"
        :path="props.widget.path"
        :label="props.widget.label"
        :node-id="props.widgetId"
        :original-unit="originalUnit"
        :desired-unit="desiredUnit"
        :enum-values="enumValues"
        @close="toggleTimeseries"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { type Events, data } from '@/logic/controlPanel/eventbus';
import { prettify } from '@/utils/textFormatting';
import type { TelemetryWidget } from '@/models/controlPanel/dashboard';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import { useControlPanelStore } from '@/stores/admin/controlPanel/controlPanel.store';
import { convertValue, getUnitForSystem } from '@/utils/units';
import {
  getTelemetryEnumValue,
  isEnumTelemetryChannel,
  isNumberTelemetryChannel,
} from '@/utils/telemetry';
import type { UnitSystem } from '@/models/datatypes.model';
import TelemetryHistory from '@/components/hardwareSystems/controlPanel/widgets/telemetry/TelemetryHistory.vue';
import { ColorGenerator } from '@/stores/admin/dashboard/dashboard.logic.colors';

const props = defineProps<{
  systemId: HardwareSystem['id'];
  widgetId: string;
  widget: TelemetryWidget;
  desiredUnitSystem?: UnitSystem;
}>();

const controlPanelStore = useControlPanelStore();

const initialValue = controlPanelStore.getTelemetryValueFromCache(props.widget.path);
const telemetryValue = ref<string | number | boolean | null>(initialValue?.value ?? null);
const telemetryTimestampMs = ref<number | undefined>(undefined);

const expanded = ref(false);
const showValueDetails = ref(false);

const toggleTimeseries = () => {
  expanded.value = !expanded.value;
};

const onNewTelemetryData = (event: Events['telemetry']) => {
  if (event.path.endsWith(props.widget.path)) {
    telemetryValue.value = event.value;
    telemetryTimestampMs.value = event.timestampMs;
  }
};

const channel = props.widget.path.split(':').pop();
const telemetryDefinition = channel ? controlPanelStore.getTelemetryByChannel(channel) : undefined;

const originalUnit: string | undefined =
  telemetryDefinition && isNumberTelemetryChannel(telemetryDefinition)
    ? telemetryDefinition?.unit
    : undefined;

const desiredUnit =
  props.widget.desiredUnit !== undefined && props.desiredUnitSystem !== undefined
    ? getUnitForSystem(props.widget.desiredUnit, props.desiredUnitSystem)
    : undefined;

const showTimeseriesButton =
  telemetryDefinition &&
  (isNumberTelemetryChannel(telemetryDefinition) || isEnumTelemetryChannel(telemetryDefinition));

const ONE_HOUR_IN_MILLISECONDS = 60 * 60 * 1000;

const isStaleData = computed(() => {
  return (
    telemetryTimestampMs.value === undefined ||
    telemetryTimestampMs.value < Date.now() - ONE_HOUR_IN_MILLISECONDS
  );
});

const enumValues: { value: number; label: string }[] | undefined =
  telemetryDefinition && isEnumTelemetryChannel(telemetryDefinition)
    ? telemetryDefinition.enumValues
    : undefined;

const colorGenerator = new ColorGenerator(props.widget.colorMap, '#fafafa');

const converted = computed(() => {
  const bgColor = colorGenerator.get(telemetryValue.value!);
  const colors = {
    colorBackground: bgColor,
    colorForeground: colorGenerator.getForegroundFor(bgColor),
  };

  if (telemetryValue.value === null) return { value: null, unit: desiredUnit, ...colors };

  if (typeof telemetryValue.value !== 'number')
    return { value: telemetryValue.value, unit: undefined, ...colors };

  if (telemetryDefinition && isEnumTelemetryChannel(telemetryDefinition)) {
    console.log('value is enum telemetry channel', telemetryDefinition);
    return {
      value: getTelemetryEnumValue(telemetryDefinition.enumValues, telemetryValue.value),
      unit: undefined,
      ...colors,
    };
  }

  const [convertedValue, convertedUnit] = convertValue(
    telemetryValue.value,
    originalUnit,
    desiredUnit
  );

  if (convertedValue % 1 !== 0)
    return { value: convertedValue.toFixed(3), unit: convertedUnit, ...colors };

  return { value: convertedValue, unit: convertedUnit, color: 'green', ...colors };
});

const renderValue = (val: any) => {
  if (val === null) return '-';
  if (typeof val === 'boolean') return val ? 'true' : 'false';
  return val;
};

// const renderedLastUpdatedDate = computed(() => {
//   if (telemetryTimestampMs.value === undefined) return '--';
//   return new Date(telemetryTimestampMs.value).toLocaleDateString();
// });
//
// const renderedLastUpdatedTime = computed(() => {
//   if (telemetryTimestampMs.value === undefined) return '--';
//   return new Date(telemetryTimestampMs.value).toLocaleTimeString();
// });

onMounted(() => {
  data.on('telemetry', onNewTelemetryData);
});

onUnmounted(() => {
  data.off('telemetry', onNewTelemetryData);
});
</script>

<style scoped lang="scss">
div.telemetry-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 0.25rem;
  padding: 0.25rem 0.5rem;
  box-sizing: border-box;
  border-radius: var(--rounded-sm);
}

div.telemetry-value-container {
  display: flex;
  flex-direction: row;
  position: relative;

  button {
    border: 1px solid var(--gray-200);
    margin: 0 0 0 4px;
    height: 2rem;
    box-sizing: border-box;
    padding: 0 0.5rem;
    border-radius: var(--rounded-sm);

    &:hover {
      cursor: pointer;
      background-color: var(--gray-50);
    }

    &.expanded {
      background-color: var(--green-primary);
      color: var(--gray-0);
    }

    i {
      font-size: 0.9rem;
    }
  }
}

pre.telemetry-value {
  line-height: calc(2rem - 2px);
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: var(--rounded-sm);

  &:hover {
    cursor: default;
  }

  &.undefined,
  &.stale {
    color: var(--gray-300);
    background-color: var(--gray-50);
  }
}

.value-details-container {
  border: 1px solid var(--gray-200);
  border-radius: var(--rounded-sm);
  background-color: var(--gray-0);
  position: absolute;
  top: calc(100%);
  right: 0.5rem;
  z-index: 1;

  padding: 0.5rem;
  max-width: 100%;

  .details-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;

    line-height: 1;

    p {
      margin: 0;
      padding: 0;
      color: var(--gray-600);
    }

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }
}

p.label {
  margin: 0;
  padding: 0;
  color: var(--gray-600);
}

.fade-down-enter-active,
.fade-down-leave-active {
  transition: all 0.1s ease;
}

.fade-down-enter-from,
.fade-down-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}
</style>
