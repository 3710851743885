<template>
  <div v-if="allSystemsAdded" class="text-center my-8">
    <span data-cy="all-systems-added">{{ t('whitelist.allSystemsAdded') }}</span>
  </div>
  <div v-else data-cy="system-picker-table" class="picker-table mt-2 pt-4 px-12">
    <span class="block title font-medium mb-3">{{ t('hardwareSystem.systems') }}</span>
    <div class="container">
      <div v-if="loading" class="loader-wrapper loader-wrapper-sm">
        <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
      </div>
      <DataTable
        v-if="!loading"
        :value="systemList"
        dataKey="id"
        :scrollable="true"
        scrollHeight="280px"
        class="p-datatable-sm picker-table"
        v-model:filters="filters"
        filterDisplay="menu"
        :globalFilterFields="['name', 'custom_id', 'charge_park_name', 'owner_organisation_name']"
        v-model:selection="selectedSystems"
        @rowSelect="onSelectedSystems"
        @rowUnselect="onUnselectedSystems"
        @rowSelectAll="onSelectedAllSystems"
        @rowUnselectAll="onUnselectedAllSystems"
        :metaKeySelection="false"
      >
        <template #empty>{{ t('hardwareSystem.empty') }}</template>
        <template #loading>{{ t('hardwareSystem.loading') }}</template>
        <template #header>
          <div class="flex justify-between align-items-center">
            <span class="p-input-icon-left search">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="
                  t('searchBy', {
                    name: `${t('name')}, ${t('hardwareSystem.customId')}, ${t(
                      'organisation.title'
                    )},  ${t('chargePark.title')}`,
                  })
                "
                class="p-inputtext-sm"
              />
            </span>
          </div>
        </template>
        <Column
          selectionMode="multiple"
          style="flex: 0 0 0; padding: 16px"
          bodyStyle="padding-left: 16px"
        ></Column>
        <Column field="name" :header="t('name')">
          <template #body="{ data }: { data: HardwareSystem }">
            <div class="w-full pl-1">
              <span
                v-if="data.name"
                :id="String(data.id)"
                class="text-sm font-medium"
                data-cy="system-name"
                >{{ data.name }}</span
              >
              <span v-else class="empty">--</span>
            </div>
          </template>
        </Column>
        <Column field="custom_id" :header="t('hardwareSystem.customId')">
          <template #body="{ data }: { data: HardwareSystem }">
            <span v-if="data.custom_id" data-cy="system-custom-id" class="text-small">{{
              data.custom_id
            }}</span>
            <span v-else class="empty">--</span>
          </template>
        </Column>
        <Column field="owner_organisation_name" :header="t('organisation.title')">
          <template #body="{ data }: { data: HardwareSystem }">
            <span
              v-if="data.owner_organisation_name"
              data-cy="system-organisation"
              class="text-small"
              >{{ data.owner_organisation_name }}</span
            >
            <span v-else class="empty">--</span>
          </template>
        </Column>
        <Column field="charge_park_name" :header="t('chargePark.title')">
          <template #body="{ data }: { data: HardwareSystem }">
            <span
              v-if="data.charge_park_name"
              data-cy="system-charge-park-name"
              class="text-small"
              >{{ data.charge_park_name }}</span
            >
            <span v-else class="empty">--</span>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, type Ref } from 'vue';
import { storeToRefs } from 'pinia';
import { FilterMatchMode } from 'primevue/api';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

import type { HardwareSystem } from '@/models/hardwareSystems.model';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  value?: number[];
  whitelistId?: number | null;
}>();

const emit = defineEmits([
  'onSystemSelect',
  'onSystemUnselect',
  'onAllSystemsSelect',
  'onAllSystemsUnselect',
]);

const { t } = useI18n();
const hardwareSystemsStore = useHardwareSystemsStore();
const { systems, filteredSystems } = storeToRefs(hardwareSystemsStore);
const loading = ref(true);
const selectedSystems = ref<HardwareSystem[]>([]);
const systemList: Ref<HardwareSystem[]> = props.whitelistId ? filteredSystems : systems;

const allSystemsAdded = computed(
  () => props.whitelistId && filteredSystems.value.length === 0 && !loading.value
);

const onSelectedSystems = (event: any) => {
  emit('onSystemSelect', { id: event.data.id });
};

const onUnselectedSystems = (event: any) => {
  emit('onSystemUnselect', event.data.id);
};

const onSelectedAllSystems = (event: any) => {
  const ids = event.data.map((item: any) => ({ id: item.id }));
  emit('onAllSystemsSelect', ids);
};

const onUnselectedAllSystems = () => {
  emit('onAllSystemsUnselect');
};

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

onMounted(async () => {
  if (props.whitelistId) {
    await hardwareSystemsStore
      .fetchSystemsForWhitelist(props.whitelistId)
      .then(() => (loading.value = false));
  } else {
    await hardwareSystemsStore.fetchSystemsList().then(() => (loading.value = false));
  }

  if (props.value?.length) {
    selectedSystems.value = systems.value.filter((asset: HardwareSystem) =>
      props.value?.includes(asset.id)
    );
  }
});
</script>

<style lang="scss" scoped></style>
