<template>
  <div class="flex bg-slate-100  rounded-md max-w-max" v-if="props.connectorStates && Object.entries(props.connectorStates).length >0 && props.numberOfConnectors"> 
          <HardwareSystemConnectorStatus
            v-if="props.connectorStates && props.numberOfConnectors"
            v-for="state, index in shownStates(props.connectorStates,props.numberOfConnectors)" 
            
            :data-cy="`connectorStatus-state-${state.state}`"
            :key="index"
            :model-value="state"
            :connector-index="Number(index)"
          >
          </HardwareSystemConnectorStatus> 
          </div>
</template>

<script setup lang="ts"> 
import type { HardwareConnectorState } from '@/models/hardwareSystems.model';  
import HardwareSystemConnectorStatus from './HardwareSystemConnectorStatus.vue';

const props = defineProps<{
  connectorStates?: Record<string,HardwareConnectorState>;
  numberOfConnectors?: number;
}>();


const shownStates = (connector_states: Record<string,HardwareConnectorState>,number_of_connectors: number) =>{ 
  const result = []; 
  for ( var i = 0; i<number_of_connectors; i++ ){
    result.push(connector_states[String(i)])
  }
  return result
}

</script>
