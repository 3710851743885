<template>
  <GeneralSummary :show-author="false" :template="selectedTemplate" />
  <div class="px-12">
    <div class="field flex flex-col pt-4 pb-6">
      <label id="reportNameLabel" for="reportName" class="title font-medium text-gray-600">
        {{ $t('name') }}<span class="required">*</span>
      </label>
      <InputText
        data-cy="report-configuration-name-input"
        id="reportName"
        type="text"
        aria-describedby="reportNameLabel"
        class="p-inputtext-sm mt-1"
        v-model="reportModalData.name"
      />
      <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>

    <ReportSchedulePicker class="mb-4" v-bind="{ v$ }" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onMounted, watch } from 'vue';
import { useReportsStore } from '@/stores/admin/reports/reports.store';
import InputText from 'primevue/inputtext';
import GeneralSummary from '@/components/alertsAndReportsShared/summary/GeneralSummary.vue';
import ReportSchedulePicker from '@/components/reports/ReportSchedulePicker.vue';

import type { reportInstantTypeRules, reportPeriodicTypeRules } from '@/utils/reportModalRules';
import type { Validation } from '@vuelidate/core';

const reportsStore = useReportsStore();
const { reportModalData, selectedTemplate } = storeToRefs(reportsStore);

const props = defineProps<{
  v$: Validation<typeof reportInstantTypeRules | typeof reportPeriodicTypeRules>;
}>();

watch(
  () => reportModalData.value.name,
  () => {
    props.v$.name.$touch();
  },
  { deep: true }
);

onMounted(async () => {
  if (!reportsStore.reportTemplates.length) {
    await reportsStore.fetchReportTemplateList();
  }
});
</script>
