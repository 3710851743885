<template>
  <p class="text" :class="{ empty: renderedValue === undefined }">
    {{ renderedValue !== undefined ? renderedValue : '--' }}
  </p>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  value?: any;
}>();

const renderedValue = computed<string | undefined>(() => {
  if (props.value === undefined) return undefined;
  return props.value ? 'True' : 'False';
});
</script>

<style scoped lang="scss">
.text {
  font-size: 0.9rem;
  color: var(--gray-600);

  &.empty {
    color: var(--gray-400);
  }
}
</style>
