<template>
  <div :class="`flex justify-between ${inline ? 'w-1/2' : 'w-full'}`">
    <span class="text-grey-medium font-semibold">{{ props.title }}</span>
    <span v-if="empty">-</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  title: string;
  empty?: boolean;
  inline?: boolean;
}>();
</script>
