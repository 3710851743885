<template>
  <GeneralSummary
    show-name
    :show-author="false"
    :name="reportModalData.name"
    :template="selectedTemplate"
  />
  <div class="px-12">
    <span class="block title font-medium field-edit-label pt-4">{{ $t('contacts') }}</span>
    <p class="block text-grey text-sm">{{ $t('report.contact.description') }}</p>
    <ContactsPicker
      selectMode="multiple"
      :value="reportsStore.reportModalData.contacts"
      @onContactSelect="onContactSelected"
      @onContactUnselect="onContactUnselected"
      @onContactSelectAll="onContactSelectedAll"
      @onContactUnselectAll="onContactUnselectedAll"
    />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';

import GeneralSummary from '@/components/alertsAndReportsShared/summary/GeneralSummary.vue';
import ContactsPicker from '@/components/contacts/ContactsPicker.vue';

import { useReportsStore } from '@/stores/admin/reports/reports.store';

import type { reportInstantTypeRules, reportPeriodicTypeRules } from '@/utils/reportModalRules';
import type { Validation } from '@vuelidate/core';

const reportsStore = useReportsStore();
const { reportModalData, selectedTemplate } = storeToRefs(reportsStore);

defineProps<{
  v$: Validation<typeof reportInstantTypeRules | typeof reportPeriodicTypeRules>;
}>();

const onContactSelected = (id: number) => {
  reportModalData.value.contacts.push(id);
};

const onContactUnselected = (id: number) => {
  const contactPos = reportModalData.value.contacts.findIndex((itemId) => itemId === id);
  reportModalData.value.contacts.splice(contactPos, 1);
};

const onContactSelectedAll = (ids: number[]) => {
  reportModalData.value.contacts = ids;
};

const onContactUnselectedAll = () => {
  reportModalData.value.contacts = [];
};
</script>
