<template>
  <div class="value">
    <div class="color-preview" :style="{ 'background-color': backgroundColor }"></div>
    <p class="text" :class="{ empty: props.value === undefined }">
      {{ props.value ? props.value.toString() : '--' }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  value?: any;
}>();

const backgroundColor = computed(() => {
  const isValidHex = /^#([0-9A-F]{3}){1,2}$/i.test(props.value);
  return isValidHex ? props.value : 'transparent';
});
</script>

<style scoped lang="scss">
.value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
}

.color-preview {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: var(--rounded-sm);
  border: 1px solid var(--gray-200);
}

.text {
  font-size: 0.9rem;
  color: var(--gray-600);

  &.empty {
    color: var(--gray-400);
  }
}
</style>
