export function prettify(text: string): string {
  return text[0].toUpperCase() + text.slice(1).toLowerCase();
}

export const fileSizeToHumanReadable = (bytes: number) => {
  return bytes >= 1048576
    ? (bytes / 1048576).toFixed(2) + ' MB'
    : (bytes / 1024).toFixed(2) + ' KB';
};

export const allLettersAreCapital = (input:string) :boolean=> {
  if(input.toUpperCase() === input) return true
  return false
};