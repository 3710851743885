import { helpers } from '@vuelidate/validators';

export const alphaNumWithSpaces = helpers.regex(/^[a-zA-Z0-9\s]+$/);
export const alphaWithSpaces = helpers.regex(/^[a-zA-Z]+( [a-zA-Z]+)*$/);
export const latitude = helpers.regex(
  /^(\+|-)?(?:90(?:(?:\.0{0,3})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,14})?))$/
);
export const longitude = helpers.regex(
  /^(\+|-)?(?:180(?:(?:\.0{0,3})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,14})?))$/
);
export const asciiCharacters = helpers.regex(/^[\x20-\x7E]*$/);
export const asciiCharactersWithNewLine = helpers.regex(/^[\x20-\x7E\n]*$/);
export const phoneNumber = helpers.regex(/^\+[1-9]\d{1,14}$/);
export const durationHours = helpers.regex(/^(\d?[1-9]|[1-9]0)$/);
export const durationMinutes = helpers.regex(/^([0-5]|[0-9])$/);
export const durationSeconds = helpers.regex(/^([0-5]|[0-9])$/);

export const minStartTimeRule = (
  startTime: Date | string | null,
  endTime: Date | string | null
) => {
  if (endTime && endTime !== '' && startTime && startTime !== '') {
    return endTime > startTime;
  } else {
    return true;
  }
};

export const minEndTimeRule = (endTime: Date | string | null, startTime: Date | string | null) => {
  if (endTime && endTime !== '' && startTime && startTime !== '') {
    return endTime > startTime;
  } else {
    return true;
  }
};

export const minStartDateRule = (
  startDate: Date | string | null,
  endDate: Date | string | null
) => {
  if (endDate && endDate !== '' && startDate && startDate !== '') {
    return startDate < endDate;
  } else {
    return true;
  }
};

export const minEndDateRule = (endDate: Date | string | null, startDate: Date | string | null) => {
  if (endDate && endDate !== '' && startDate && startDate !== '') {
    return startDate < endDate;
  } else {
    return true;
  }
};
