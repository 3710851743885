<template>
  <div class="px-12 py-4">
    <div v-for="group of props.template.settings" :key="group.id" class="group-container">
      <div class="group-header-container">
        <p class="group-title">{{ group.label }}</p>
      </div>
      <div class="settings-container">
        <div v-for="setting of group.settings" :key="setting.id" class="setting-row">
          <p>{{ setting.label }}</p>
          <div class="value-and-error">
            <color-input
              v-if="setting.type === 'color'"
              :id="`${group.id}${setting.id}`"
              :key="`${group.id}${setting.id}${updateKeys[group.id + '-' + setting.id]}`"
              :value="state[group.id]?.[setting.id]"
              :error="props.validationErrors?.[group.id]?.[setting.id]"
              :default-value="setting.defaultValue"
              @update:value="(value) => setValue(group.id, setting.id, value)"
            />
            <number-input
              v-else-if="setting.type === 'number'"
              :id="`${group.id}${setting.id}`"
              :key="`${group.id}${setting.id}${updateKeys[group.id + '-' + setting.id]}`"
              :value="state[group.id]?.[setting.id]"
              :error="props.validationErrors?.[group.id]?.[setting.id]"
              :default-value="setting.defaultValue"
              @update:value="(value) => setValue(group.id, setting.id, value)"
            />
            <string-input
              v-else-if="
                setting.type === 'string' ||
                setting.type === 'url' ||
                setting.type === 'email' ||
                setting.type === 'phone'
              "
              :id="`${group.id}${setting.id}`"
              :key="`${group.id}${setting.id}${updateKeys[group.id + '-' + setting.id]}`"
              :type="setting.type"
              :value="state[group.id]?.[setting.id]"
              :error="props.validationErrors?.[group.id]?.[setting.id]"
              :default-value="setting.defaultValue"
              @update:value="(value) => setValue(group.id, setting.id, value)"
            />
            <multi-enum-input
              v-else-if="setting.type === 'multi-enum'"
              :id="`${group.id}${setting.id}`"
              :key="`${group.id}${setting.id}${updateKeys[group.id + '-' + setting.id]}`"
              :value="state[group.id]?.[setting.id]"
              :default-value="setting.defaultValue"
              :error="props.validationErrors?.[group.id]?.[setting.id]"
              :enum-values="setting.options"
              @update:value="(value) => setValue(group.id, setting.id, value)"
            />
            <single-enum-input
              v-else-if="setting.type === 'single-enum'"
              :id="`${group.id}${setting.id}`"
              :key="`${group.id}${setting.id}${updateKeys[group.id + '-' + setting.id]}`"
              :value="state[group.id]?.[setting.id]"
              :default-value="setting.defaultValue"
              :error="props.validationErrors?.[group.id]?.[setting.id]"
              :enum-values="setting.options"
              @update:value="(value) => setValue(group.id, setting.id, value)"
            />
            <boolean-input
              v-else-if="setting.type === 'boolean'"
              :id="`${group.id}${setting.id}`"
              :key="`${group.id}${setting.id}${updateKeys[group.id + '-' + setting.id]}`"
              :value="state[group.id]?.[setting.id]"
              :error="props.validationErrors?.[group.id]?.[setting.id]"
              :default-value="setting.defaultValue"
              @update:value="(value) => setValue(group.id, setting.id, value)"
            />
            <image-input
              v-else-if="setting.type === 'image'"
              :id="`${group.id}${setting.id}`"
              :key="`${group.id}${setting.id}${updateKeys[group.id + '-' + setting.id]}`"
              :value="state[group.id]?.[setting.id]"
              :error="props.validationErrors?.[group.id]?.[setting.id]"
              @update:value="(value) => setValue(group.id, setting.id, value)"
            />
            <error-bar
              v-if="props.validationErrors?.[group.id]?.[setting.id]"
              :message="String(props.validationErrors?.[group.id]?.[setting.id])"
              size="sm"
              class="rounded-md mt-0.5"
            />
          </div>
          <div class="ml-2">
            <custom-button
              @click="(e) => toggle(group.id, setting.id, e)"
              aria-haspopup="true"
              :aria-controls="`overlay-menu-${group.id}-${setting.id}`"
            >
              <ellipsis-horizontal-icon class="icon" />
            </custom-button>
            <Menu
              :ref="(el: any) => (menuRefs[`menu-${group.id}-${setting.id}`] = el as unknown as Menu)"
              :id="`overlay-menu-${group.id}-${setting.id}`"
              :model="options(group.id, setting.id)"
              :popup="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BrandingTemplate } from '@/stores/admin/branding/branding.types';
import ColorInput from '@/components/branding/input/ColorInput.vue';
import StringInput from '@/components/branding/input/StringInput.vue';
import NumberInput from '@/components/branding/input/NumberInput.vue';
import BooleanInput from '@/components/branding/input/BooleanInput.vue';
import ImageInput from '@/components/branding/input/ImageInput.vue';
import { ref, type VNodeRef } from 'vue';
import MultiEnumInput from '@/components/branding/input/MultiEnumInput.vue';
import SingleEnumInput from '@/components/branding/input/SingleEnumInput.vue';
import type { ConfigurationValidationErrors } from '@/stores/admin/branding/ui.validation';
import ErrorBar from '@/components/common/bar/ErrorBar.vue';
import Menu, { type MenuProps } from 'primevue/menu';
import { EllipsisHorizontalIcon } from '@heroicons/vue/20/solid';
import CustomButton from '@/components/common/button/CustomButton.vue';

const options = (groupId: string, settingId: string): MenuProps['model'] => [
  { label: 'Undo', icon: 'pi pi-replay', command: () => undo(groupId, settingId) },
  { label: 'Reset To Default', icon: 'pi pi-replay', command: () => reset(groupId, settingId) },
];

const menuRefs = ref<Record<string, Menu | null>>({});
const updateKeys = ref<Record<string, number>>({});

const props = defineProps<{
  template: BrandingTemplate;
  values: { [groupId: string]: { [settingId: string]: any } };
  validationErrors?: ConfigurationValidationErrors;
}>();

const state = ref(JSON.parse(JSON.stringify(props.values)));
const originalState = JSON.parse(JSON.stringify(props.values));

const emit = defineEmits<{
  (event: 'update:values', value: Record<string, Record<string, any>>): void;
}>();

function toggle(groupId: string, settingId: string, event: MouseEvent) {
  const menu = menuRefs.value[`menu-${groupId}-${settingId}`];
  if (menu)  menu.toggle(event);
}

function undo(groupId: string, settingId: string) {
  if (!state.value[groupId]) state.value[groupId] = {};
  state.value[groupId][settingId] = originalState[groupId]?.[settingId];
  emit('update:values', state.value);
  forceUpdate(groupId, settingId);
}

function reset(groupId: string, settingId: string) {
  if (!state.value[groupId]) state.value[groupId] = {};
  const defaultValue = props.template.settings
    .find((group) => group.id === groupId)
    ?.settings.find((setting) => setting.id === settingId)?.defaultValue;
  if (defaultValue === undefined) return;
  state.value[groupId][settingId] = defaultValue;
  emit('update:values', state.value);
  forceUpdate(groupId, settingId);
}

function forceUpdate(groupId: string, settingId: string) {
  updateKeys.value[`${groupId}-${settingId}`] =
    (updateKeys.value[`${groupId}-${settingId}`] ?? 0) + 1;
}

function setValue(groupId: string, settingId: string, value: any) {
  if (!state.value[groupId]) state.value[groupId] = {};
  state.value[groupId][settingId] = value;
  emit('update:values', state.value);
}
</script>

<style scoped lang="scss">
.group-header-container {
  padding: 0 1rem;
}

.settings-container {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 0.75rem 1rem 2rem 1rem;
  width: 100%;
}

.setting-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.customize-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.group-container {
  margin: 1rem 0;
}

div.value-and-error {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: flex-end;
  margin-left: auto;
  min-width: 10rem;
}

.group-title {
  font-size: 1rem;
  color: var(--gray-400);
  font-weight: 400;
}

.icon {
  width: 1rem;
  height: 1rem;
  color: var(--gray-400);
}

:deep(.p-button .p-button-icon-only) {
  width: 1.5rem;
  height: 1.5rem;
}
</style>
