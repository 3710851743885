import { computed } from 'vue';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { TimeSystem, UnitSystem } from '@/models/datatypes.model';

import dateTimeFormat from './dateTimeFormat';

let userDataLoaded = false;

// ####################################
// Format definition code begins here
// ####################################

function loadStore() {
  const store = useUsersStore();
  if (!userDataLoaded) {
    userDataLoaded = true;
    store.fetchUserData().catch(() => (userDataLoaded = false));
  }
  return store;
}

/**
 * Returns the current time format configured for user.
 * ~ Mainly used for PrimeVue datetime component as prop.
 * @returns {String} possible values '12' | '24'
 */
export const primeVueHourFormat = computed(() => {
  const usersStore = loadStore();
  if (usersStore.me.time_format === TimeSystem.TIME_12H) return '12';
  return '24';
});

/**
 * Returns the current date format configured for user.
 * ~ Mainly used for PrimeVue datetime component as prop.
 * @returns {String}
 */
export const primeVueDateFormat = computed(() => {
  const usersStore = loadStore();
  if (usersStore.me.units === UnitSystem.IMPERIAL) return 'm/d/yy';
  return 'd.m.yy';
});

/**
 * Returns the current date format configured for user.
 * ~ Mainly used for formatting the dates/times to readable format using dayjs.
 * Using dayjs format https://day.js.org/docs/en/display/format#list-of-all-available-formats
 * @returns {String} DayJS date format
 */
export const dayJsDateFormat = computed(() => {
  const usersStore = loadStore();
  if (usersStore.me.units === UnitSystem.IMPERIAL) return 'M/D/YYYY';
  //if (usersStore.me.units === UnitSystem.METRIC)
  return 'D.M.YYYY';
});

/**
 * Returns the current date format configured for user.
 * ~ Mainly used for formatting the dates/times to readable format using dayjs.
 * Using dayjs format https://day.js.org/docs/en/display/format#list-of-all-available-formats
 * @returns {String} DayJS time format
 */
export const dayJsTimeFormat = computed(() => {
  const usersStore = loadStore();
  if (usersStore.me.time_format === TimeSystem.TIME_12H) return 'h:mm:ss.SSS A';
  //if (usersStore.me.units === UnitSystem.METRIC)
  return 'HH:mm:ss.SSS';
});

export const dayJsShortTimeFormat = computed(() => {
  const usersStore = loadStore();
  if (usersStore.me.time_format === TimeSystem.TIME_12H) return 'h:mm A';
  //if (usersStore.me.units === UnitSystem.METRIC)
  return 'HH:mm';
});

/**
 * Returns the current datetime format configured for user.
 * ~ Mainly used for formatting the dates/times to readable format using dayjs.
 * Using dayjs format https://day.js.org/docs/en/display/format#list-of-all-available-formats
 * @returns {String} DayJS Datetime format
 */
export const dayJsDateTimeFormat = computed(() => {
  return dayJsDateFormat.value + ' ' + dayJsTimeFormat.value;
});
export const dayJsShortDateTimeFormat = computed(() => {
  return dayJsDateFormat.value + ' ' + dayJsShortTimeFormat.value;
});
export const timezoneKey = computed<string>(() => {
  const usersStore = loadStore();
  return usersStore.me.local_timezone;
});

export const timezoneUtcOffsetHours = computed(() => {
  return (datetime: number | string | Date) => {
    return dateTimeFormat.getOffsetHoursForTimezone(datetime, timezoneKey.value);
  };
});

export const forTimezoneUtcOffsetHours = computed(() => {
  return (datetime: number | string | Date, timezone: string) => {
    return dateTimeFormat.getOffsetHoursForTimezone(datetime, timezone);
  };
});

// ####################################
// Formatter functions code begins here
// ####################################
/**
 * Formats a given date, time, or timestamp
 * in the timezone passed to this function
 *
 * @param {string | number | Date} value - The value representing a date, time, or timestamp.
 * @param {string} timezone - The timezone key to format to
 * @returns {string} - A readable formatted string representation of the value in the user's local timezone.
 *
 * @example
 * const formattedDate = formatDateWithTimezone(new Date(), 'Europe/Berlin'); // '2024-01-12'
 */
export function formatDateWithTimezone(value: string | number | Date, timezone: string): String {
  return dateTimeFormat.formatDateTimeWithTimezone(value, dayJsDateFormat.value, timezone);
}
/**
 * Formats a given date, time, or timestamp
 * in the timezone passed to this function
 *
 * @param {string | number | Date} value - The value representing a date, time, or timestamp.
 * @returns {string} - A readable formatted string representation of the value in the user's local timezone.
 *
 * @example
 * const formattedDate = formatDateWithUsersTimezone(new Date()); // '2024-01-12'
 */
export function formatDateWithUsersTimezone(value: string | number | Date): String {
  return dateTimeFormat.formatDateTimeWithTimezone(value, dayJsDateFormat.value, timezoneKey.value);
}
/**
 * Formats a given date, time, or timestamp as datetime
 * in the timezone passed to this function
 *
 * @param {string | number | Date} value - The value representing a date, time, or timestamp.
 * @param {string} timezone - The timezone key to format to
 * @returns {string} - A readable formatted string representation of the value in the user's local timezone.
 *
 * @example
 * const formattedDate = formatDateTimeWithTimezone(new Date(), 'Europe/Berlin'); // '2024-01-12 15:30:00'
 */
export function formatDateTimeWithTimezone(
  value: string | number | Date,
  timezone: string
): String {
  return dateTimeFormat.formatDateTimeWithTimezone(value, dayJsDateTimeFormat.value, timezone);
}
/**
 * Formats a given date, time, or timestamp
 * in the timezone and formatting pattern configured in user settings
 *
 * @param {string | number | Date} value - The value representing a date, time, or timestamp.
 * @returns {string} - A readable formatted string representation of the value in the user's local timezone.
 *
 * @example
 * const formattedDate = timezoneFormatter(new Date()); // '2024-01-12 15:30:00'
 */
export function formatDateTimeWithUsersTimezone(value: string | number | Date): String {
  return dateTimeFormat.formatDateTimeWithTimezone(
    value,
    dayJsDateTimeFormat.value,
    timezoneKey.value
  );
}
export function formatShortDateTimeWithUsersTimezone(value: string | number | Date): String {
  return dateTimeFormat.formatDateTimeWithTimezone(
    value,
    dayJsShortDateTimeFormat.value,
    timezoneKey.value
  );
}

/**
 * Formats a given date, time, or timestamp using the formatting pattern from user settings.
 *
 * @param {string | number | Date} value - The value representing a date, time, or timestamp.
 * @returns {string} - A formatted string representation of the value without considering timezone.
 *
 * @example
 * const formattedDate = regularDateFormatter(new Date()); // '11/8/2023 12:11'
 */
export function formatDateTime(value: string | number | Date): string {
  return dateTimeFormat.formatDateTime(value, dayJsDateTimeFormat.value);
}
export function formatShortDateTime(value: string | number | Date): string {
  return dateTimeFormat.formatDateTime(value, dayJsShortDateTimeFormat.value);
}

/**
 * Formats a time span in the format 'HH:mm-HH:mm' into a human-readable string.
 *
 * @param {string} timeSpan - The time span to be formatted (e.g., '09:00-17:00').
 * @returns {string} A formatted string representing the time span, or 'ERROR' in case of an error.
 *
 * @example
 * // Returns '9:00 AM - 5:00 PM'
 * formatHourMinSpan('09:00-17:00');
 *
 * @example
 * // Returns 'ERROR'
 * formatHourMinSpan('invalid-time-span');
 */
export function formatHourMinSpan(timeSpan: string) {
  return dateTimeFormat.formatHourMinSpan(timeSpan, dayJsTimeFormat.value);
}

// ##########################################
// DateTime helper functions code begins here
// ##########################################

// Note this functions may bet obsolete by using DateTimeInput component

/**
 * Converts a date or string representation of a date to a localized Date object
 * based on the user's local timezone.
 *
 * @param {string | Date} date - The date or string representation of a date to be converted.
 * @returns {Date} - A Date object representing the converted date and time in the user's local timezone.
 *
 * @throws {Error} Will throw an error if the date parameter is not a valid date string or Date object.
 *
 * @example
 * // Convert a date string to a localized date and time
 * const result = dateTimeWithTimezone('2024-01-12T12:00:00Z');
 *
 * // Convert a Date object to a localized date and time
 * const result = dateTimeWithTimezone(new Date('2024-01-12T12:00:00Z'));
 */
export const dateTimeWithTimezone = (date: string | Date) => {
  return dateTimeFormat.dateTimeWithTimezone(date, timezoneKey.value);
};

/**
 * Retrieves the current date and time in the user's local timezone.
 *
 * @returns {Date} - A Date object representing the current date and time in the user's local timezone.
 *
 * @throws {Error} Will throw an error if there is an issue retrieving the user's local timezone
 * or if the date and time conversion fails.
 *
 * @example
 * // Get the current date and time in the user's local timezone
 * const result = currentDateTimeWithTimezone();
 */

export const currentDateTimeWithTimezone = () => {
  return dateTimeFormat.currentDateTimeWithTimezone(timezoneKey.value);
};
