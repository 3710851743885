import type { ColorMap, PlotValue } from '@/stores/admin/dashboard/dashboard.types';
import { BysColor } from '@/utils/colors';
import { Color } from '@kurkle/color';



abstract class IColorGenerator {
  public abstract get(value: PlotValue | Date): string;
}

class PalletteColorGenerator implements IColorGenerator {
  private paletteIndex = 0;
  private internalMap = new Map<PlotValue, string>();

  constructor(private readonly palette: string[]) {}

  private getNextColor() {
    this.paletteIndex = (this.paletteIndex + 1) % this.palette.length;
    return this.palette[this.paletteIndex];
  }

  get(value: PlotValue | Date): string {
    if (value instanceof Date) value = value.getTime().toString();

    const existingValueInColorMap = this.internalMap.get(value);
    if (existingValueInColorMap != undefined) return existingValueInColorMap;

    const nextColor = this.getNextColor();
    this.internalMap.set(value, nextColor);
    return nextColor;
  }
}

class DiscreteBysColorGenerator implements IColorGenerator {
  private internalMap = new Map<string, string>();


  constructor(private colors: ColorMap) {
    Object.entries(colors.map).forEach(([value, color]) =>
      this.internalMap.set(String(value), color)
    );
  }
  get(value: PlotValue | Date): string {
    if (value instanceof Date) value = value.getTime().toString();
    return new BysColor(this.internalMap.get(String(value)) || this.colors.fallback || '#999').hexString() as string;
  }
}

class DiscreteColorGenerator implements IColorGenerator {
  private internalMap = new Map<string, string>();

  constructor(private colors: ColorMap) {
    Object.entries(colors.map).forEach(([value, color]) =>
      this.internalMap.set(String(value), color)
    );
  }

  get(value: PlotValue | Date): string {
    if (value instanceof Date) value = value.getTime().toString();
    return this.internalMap.get(String(value)) || this.colors.fallback || '#999';
  }
}

class BaseColorGenerator implements IColorGenerator {
  private internalMap = new Map<PlotValue, string>();
  private readonly baseColor: string;

  constructor(colors: ColorMap) {
    this.baseColor = colors.map[0];
  }

  private randomAroundBaseColor(step: number = 10): string {
    const lightenOrDarken = Math.random() > 0.5 ? 'lighten' : 'darken';
    const amount = Math.floor(Math.random() * step) / step;
    const desaturateBy = lightenOrDarken === 'lighten' ? 0.3 : 0.2;
    const color = new Color(this.baseColor)[lightenOrDarken](amount).desaturate(desaturateBy);
    return color.hexString() as string;
  }

  get(value: PlotValue | Date): string {
    if (value instanceof Date) value = value.getTime().toString();
    if (this.internalMap.has(value)) return this.internalMap.get(value)!;
    const color = this.randomAroundBaseColor();
    this.internalMap.set(value, color);
    return color;
  }
}

class InterpolationColorGenerator implements IColorGenerator {
  private internalRange: [PlotValue, string][] = [];
  private cache = new Map<PlotValue, string>();
  private readonly len: number = 0;

  constructor(colors: ColorMap) {
    Object.entries(colors.map)
      .sort(([[a], [b]]) => Number(a) - Number(b))
      .forEach(([value, color]) => this.internalRange.push([value, color]));
    console.log(this.internalRange);
    this.len = this.internalRange.length;
  }

  private formatHexRgb(hex: string) {
    if (hex.length === 4) {
      return (
        '#' +
        hex
          .split('')
          .slice(1)
          .map((char) => char + char)
          .join('')
      );
    }
    return hex;
  }

  private interpolate(value: PlotValue): string {
    if (this.cache.has(value)) return this.cache.get(value)!;

    const val = Number(value);
    let lowValue: number | null = null;
    let highValue: number | null = null;
    let lowIndex: number | null = null;
    let highIndex: number | null = null;

    for (let i = 0; i < this.len - 1; i++) {
      if (val >= this.internalRange[i][0] && val <= this.internalRange[i + 1][0]) {
        lowIndex = i;
        highIndex = i + 1;
        lowValue = Number(this.internalRange[i][0]);
        highValue = Number(this.internalRange[i + 1][0]);
        break;
      }
    }

    if ([lowValue, highValue, lowIndex, highIndex].includes(null)) return '#999';
    const color1 = this.formatHexRgb(this.internalRange[lowIndex!][1]);
    const color2 = this.formatHexRgb(this.internalRange[highIndex!][1]);
    const ratio = (val - lowValue!) / (highValue! - lowValue!);

    const interpolated = lerpColor(color1, color2, ratio);
    this.cache.set(value, interpolated);
    return interpolated;
  }

  get(value: PlotValue | Date): string {
    if (value instanceof Date) value = value.getTime().toString();
    if (Number(value) <= Number(this.internalRange[0][0]))
      return this.formatHexRgb(this.internalRange[0][1]);
    if (Number(value) >= Number(this.internalRange[this.len - 1][0]))
      return this.formatHexRgb(this.internalRange[this.len - 1][1]);
    return this.interpolate(value);
  }
}

class StaticColorGenerator implements IColorGenerator {
  constructor(private color: string) {}

  get(): string {
    return this.color;
  }
}

function lerpColor(a: string, b: string, amount: number): string {
  const ah = +a.replace('#', '0x'),
    ar = ah >> 16,
    ag = (ah >> 8) & 0xff,
    ab = ah & 0xff,
    bh = +b.replace('#', '0x'),
    br = bh >> 16,
    bg = (bh >> 8) & 0xff,
    bb = bh & 0xff,
    rr = ar + amount * (br - ar),
    rg = ag + amount * (bg - ag),
    rb = ab + amount * (bb - ab);

  return '#' + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1);
}

const defaultPalette = ['#ef476f', '#ffd166', '#06d6a0', '#118ab2', '#073b4c'];

// Calculate the relative luminance of a color
function calculateRelativeLuminance(red: number, green: number, blue: number): number {
  const rLinear = red / 255;
  const gLinear = green / 255;
  const bLinear = blue / 255;

  const r = rLinear <= 0.03928 ? rLinear / 12.92 : Math.pow((rLinear + 0.055) / 1.055, 2.4);
  const g = gLinear <= 0.03928 ? gLinear / 12.92 : Math.pow((gLinear + 0.055) / 1.055, 2.4);
  const b = bLinear <= 0.03928 ? bLinear / 12.92 : Math.pow((bLinear + 0.055) / 1.055, 2.4);

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export function contrastRatio(color1: Color, color2: Color): number {
  const luminance1 = calculateRelativeLuminance(color1.rgb.r, color1.rgb.g, color1.rgb.b);
  const luminance2 = calculateRelativeLuminance(color2.rgb.r, color2.rgb.g, color2.rgb.b);

  const lighterLuminance = Math.max(luminance1, luminance2);
  const darkerLuminance = Math.min(luminance1, luminance2);

  return (lighterLuminance + 0.05) / (darkerLuminance + 0.05);
}

export class ColorGenerator {
  private instance: IColorGenerator;

  constructor(private colormap?: ColorMap, private fallback?: string) {
    if (colormap?.type === 'discrete') this.instance = new DiscreteColorGenerator(colormap);
    else if (colormap?.type === 'discreteBys') this.instance = new DiscreteBysColorGenerator(colormap);
    else if (colormap?.type === 'base') this.instance = new BaseColorGenerator(colormap);
    else if (colormap?.type === 'continuous')
      this.instance = new InterpolationColorGenerator(colormap);
    else if (colormap?.type === 'palette')
      this.instance = new PalletteColorGenerator(Object.values(colormap.map));
    else this.instance = new StaticColorGenerator(this.default);
  }

  private get default() {
    return this.fallback || this.colormap?.fallback || '#999999';
  }

  private resolveValue(color: string): Color {
    if (color.startsWith('#')) return new Color(color);
    if (color.startsWith('var(')) color = color.slice(4, -1);
    if (color.startsWith('--')) {
      return new Color(getComputedStyle(document.documentElement).getPropertyValue(color));
    }
    return new Color(color);
  }

  get(value: string | number | boolean | Date | null): string {
    if (value === null) return this.default;
    if (typeof value === 'boolean') value = value ? 1 : 0;
    return this.instance.get(value);
  }

  public getForegroundFor(color: string): string {
    const c = this.resolveValue(color);
    const whiteContrast = contrastRatio(c, new Color('#ffffff'));
    const blackContrast = contrastRatio(c, new Color('#666666'));
    return whiteContrast > blackContrast ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)';
  }
}
