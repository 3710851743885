import type { AxiosPromise, AxiosResponse } from 'axios';
import axios from 'axios';

export async function fetchMedia(filtered_status_ids?: number[]): Promise<AxiosResponse> {
  let params = ""

  if(filtered_status_ids && filtered_status_ids.length >0){
    params = "?status="+filtered_status_ids.toString()
  }
  return axios.get<AxiosPromise>(`/api/media/${params}`).then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function createMediaObject(file_id: number): Promise<AxiosResponse> {
  try {
    return await axios.post<AxiosPromise>(`/api/media/`, { file_id });
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function uploadMediaFile(file: File): Promise<AxiosResponse> {
  return await axios
    .post<AxiosPromise>(
      `/api/media/file/`,
      { file },
      {
        headers: {
          uid: 1,
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function fetchMediaFilePath(url: string): Promise<AxiosResponse> {
  return await axios
    .get<AxiosPromise>(`/api/media/files/${url}`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function deleteMediaFile(id: number): Promise<AxiosResponse> {
  return await axios
    .delete<AxiosPromise>(`/api/media/${id}/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function fetchMediaStatusList(): Promise<AxiosResponse>{
  return await axios
  .get<AxiosPromise>(`/api/media-status/`)
  .then((response: AxiosResponse) => response)
  .catch((error) => {
    return Promise.reject(error);
  });
}