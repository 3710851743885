<template>
  <Dialog
    v-model:visible="reportCreateModalIsOpen"
    data-cy="report-create-edit-modal"
    modal
    class="report-create-edit-modal bg-gray-100"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '825px' }"
    @hide="closeModal"
  >
    <template #header>
      <div class="relative w-full text-center">
        <Button
          v-if="!isFirst"
          :label="$t('back')"
          icon="pi pi-angle-left"
          class="!absolute p-button-outlined !bg-white p-button-plain p-button-sm mr-2 left-0 top-1/2 transform -translate-y-1/2"
          @click="goToPrevious"
        />

        <p class="font-semibold">
          {{ modalTitle }}
        </p>
      </div>
    </template>

    <ModalStepper :active-step="current" v-bind="{ steps, isEditMode }" />

    <div class="border-t">
      <component :is="reportStepsComponents[current]" v-bind="{ v$ }" />
    </div>

    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        data-cy="report-create-edit-cancel-button"
        @click="closeModal"
      />
      <Button
        v-if="!isLast"
        :disabled="!isStepValid"
        :label="t('next')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        data-cy="report-create-edit-next-button"
        @click="goToNext"
      />
      <Button
        v-else
        :label="t('apply')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        data-cy="report-create-edit-submit-button"
        @click="submitForm"
      />
    </template>
  </Dialog>

  <Toast
    data-cy="report-create-edit-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="reports"
    class="report-toast"
  />
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import { useI18n } from 'vue-i18n';
import ModalStepper from '@/components/common/steppers/ModalStepper.vue';
import { useStepper } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';

import { CREATE, EDIT, INSTANT_REPORT, PERIODIC_REPORT } from '@/utils/constants';
import { reportInstantTypeRules, reportPeriodicTypeRules } from '@/utils/reportModalRules';
import { ReportStep } from '@/models/reports.model';
import { useReportsStore } from '@/stores/admin/reports/reports.store';

import ReportModalTemplateStep from '@/components/reports/steps/ReportModalTemplateStep.vue';
import ReportModalConfigurationStep from '@/components/reports/steps/ReportModalConfigurationStep.vue';
import ReportModalSitesStep from '@/components/reports/steps/ReportModalSitesStep.vue';
import ReportModalContactsStep from '@/components/reports/steps/ReportModalContactsStep.vue';
import ReportModalPreviewStep from '@/components/reports/steps/ReportModalPreviewStep.vue';

const reportsStore = useReportsStore();
const { t } = useI18n();
const toast = useToast();
const { reportModalData, reportCreateModalIsOpen, isEditMode, reportEditId } =
  storeToRefs(reportsStore);

const reportRules = computed(() =>
  reportModalData.value.report_type === INSTANT_REPORT
    ? reportInstantTypeRules
    : reportPeriodicTypeRules
);

const v$ = useVuelidate(reportRules, reportModalData);

const modalTitle = computed(() => {
  return isEditMode.value
    ? t('edit', { itemName: t('report.title') })
    : t('create', { itemName: t('report.title') });
});

const closeModal = () => {
  reportCreateModalIsOpen.value = false;
  resetForm();

  if (isEditMode.value) {
    reportEditId.value = null;
  }
};

const reportStepsComponents: Record<ReportStep, unknown> = {
  [ReportStep.Template]: ReportModalTemplateStep,
  [ReportStep.Configuration]: ReportModalConfigurationStep,
  [ReportStep.Sites]: ReportModalSitesStep,
  [ReportStep.Contacts]: ReportModalContactsStep,
  [ReportStep.Preview]: ReportModalPreviewStep,
};

const createPath = [
  ReportStep.Template,
  ReportStep.Configuration,
  ReportStep.Sites,
  ReportStep.Contacts,
  ReportStep.Preview,
];
const editPath = [
  ReportStep.Configuration,
  ReportStep.Sites,
  ReportStep.Contacts,
  ReportStep.Preview,
];
const currentPath = computed(() => (isEditMode.value ? editPath : createPath));

const { steps, current, goTo, goToNext, goToPrevious, isFirst, isLast } = useStepper(currentPath);

const resetForm = () => {
  reportsStore.resetReportModalData();
  v$.value.$reset();
  goTo(steps.value[0]);
};

const setSuccessMessage = (actionName: string) => {
  if (reportModalData.value.report_type === PERIODIC_REPORT) {
    return t('toast.success', {
      name: t('report.title'),
      action: actionName === CREATE ? t('toast.created') : t('toast.edited'),
    });
  }

  if (reportModalData.value.report_type === INSTANT_REPORT) {
    return actionName === CREATE
      ? t('toast.instantReportCreated')
      : t('toast.success', {
          name: t('report.title'),
          action: t('toast.edited'),
        });
  }
};

const showSuccess = (actionName: string) => {
  toast.removeGroup('reports');
  toast.add({
    severity: 'success',
    summary: setSuccessMessage(actionName),
    life: reportModalData.value.report_type === PERIODIC_REPORT ? 5000 : undefined,
    group: 'reports',
  });
};

const showError = (actionName: string) => {
  toast.removeGroup('reports');
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: actionName === CREATE ? t('toast.creation') : t('toast.edition'),
      name: t('report.title'),
    }),
    group: 'reports',
  });
};

const instantTypeValidation = computed(() => {
  const reportType = reportModalData.value.report_type;

  if (reportType === '' || reportType === PERIODIC_REPORT) {
    return true;
  } else {
    return (
      !v$.value.start_date.$invalid &&
      !v$.value.end_date.$invalid
    );
  }
});

const periodicTypeValidation = computed(() => {
  const reportType = reportModalData.value.report_type;

  if (reportType === '' || reportType === INSTANT_REPORT) {
    return true;
  } else {
    return v$.value.frequency.$dirty && !v$.value.frequency.$invalid;
  }
});

const timeSpanValidation = computed(() =>
  reportModalData.value.report_type === INSTANT_REPORT
    ? instantTypeValidation.value
    : periodicTypeValidation.value
);

const isStepValid = computed(() => {
  const stepValidationRules: Record<ReportStep, boolean> = { 
    [ReportStep.Template]: v$.value.template_id.$dirty && !v$.value.template_id.$invalid,
    [ReportStep.Configuration]:
      v$.value.name.$dirty && !v$.value.name.$invalid && timeSpanValidation.value,
    [ReportStep.Sites]: v$.value.charge_parks.$dirty && !v$.value.charge_parks.$invalid,
    [ReportStep.Contacts]: true,
    [ReportStep.Preview]: true,
  };

  return stepValidationRules[current.value];
});

async function submitForm() {
  const action = isEditMode.value ? EDIT : CREATE;

  try {
    isEditMode.value ? await reportsStore.editReport() : await reportsStore.createReport();
    showSuccess(action);
    closeModal();
    resetForm();
    reportsStore.resetReportModalData();
  } catch (err: unknown) {
    showError(action);
    throw new Error(err as string);
  }

  await reportsStore.fetchReportsList();
}

watch(
  () => isEditMode.value,
  (isActive) => {
    !isActive ? v$.value.$reset() : v$.value.$touch();
  }
);
</script>
