<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="data-export-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
  >
    {{ $t('notAuthorized') }}
  </Message>
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="data-export-table"
    :value="dataExports"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    :globalFilterFields="['name']"
    v-model:filters="filters"
    filterDisplay="menu"
    dataKey="id"
    class="p-datatable-sm mb-5 mt-6 border-rounded overflow-hidden data-export-table"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            data-cy="data-export-table-search"
            v-model="filters['global'].value"
            :placeholder="$t('searchBy', { name: `${$t('name')}` })"
            class="p-inputtext-sm"
          />
        </span>
        <div v-if="activeRoles" class="flex justify-end items-center p-4">
          <Button
            data-cy="open-create-data-export-modal"
            :label="$t('dataExport.newDataExport')"
            icon="pi pi-plus"
            class="p-button-sm p-button-primary"
            @click="openCreateDataExportModal"
          />
        </div>
      </div>
    </template>
    <template #empty>
      <span data-cy="data-export-table-empty" class="w-full block text-center">
        {{ $t('dataExport.empty') }}
      </span>
    </template>
    <Column
      class="no-top-border-row"
      field="name"
      :header="$t('name')"
      :sortable="true"
      :show-filter-match-modes="false"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <span data-cy="data-export-name">{{ data.name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="status"
      :header="$t('dataExport.status')"
      :sortable="true"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <Tag
          :value="data.status"
          data-cy="data-export-status"
          :severity="getDataExportStatusSeverity(data.status)"
          rounded
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="included_assets"
      :header="$t('dataExport.includedAssets')"
      :sortable="false"
      style="min-width: 7rem"
    >
      <template #body="{ data }">
        <HardwareSystemsHoverList
          data-cy="data-export-included_assets"
          :systems="data.systems"
        ></HardwareSystemsHoverList>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="start_datetime"
      :header="$t('dataExport.timeRangeStart')"
      :sortable="true"
      style="min-width: 9rem"
    >
      <template #body="{ data }">
        <date-time-display
          data-cy="data-export-start_datetime"
          :date="(data as DataExport).start_datetime"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="end_datetime"
      :header="$t('dataExport.timeRangeEnd')"
      :sortable="true"
      style="min-width: 9rem"
    >
      <template #body="{ data }">
        <date-time-display
          data-cy="data-export-end_datetime"
          :date="(data as DataExport).end_datetime"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="created_at"
      :header="$t('dataExport.createdAt')"
      :sortable="true"
      style="min-width: 9rem"
    >
      <template #body="{ data }">
        <date-time-display
          data-cy="data-export-created_at"
          :date="(data as DataExport).created_at"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      style="width: 13rem"
    >
      <template #body="{ data }">
        <Button
          v-if="activeRoles && data.status !== 'Deleted'"
          data-cy="download-data-export"
          :data-cy-entity="data.name"
          :label="$t('download')"
          icon="pi pi-pencil"
          :disabled="data.status !== 'Complete' || !data.file_url"
          class="p-button-outlined p-button-plain p-button-sm mr-2"
          @click="openFileUrl(data.file_url)"
        />
        <Button
          v-if="data.status === 'Deleted'"
          data-cy="deleted-data-export"
          outlined
          icon="pi pi-info-circle"
          class="!py-1"
          v-tooltip.left="{
            value: `${$t('dataExport.deletedExport')}`,
            escape: true,
          }"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { storeToRefs } from 'pinia';
import { useDataExportsStore } from '@/stores/admin/dataExports/dataExports.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { ADMINISTRATOR_ROLE, ASSET_VIEWER_ROLE } from '@/utils/constants';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Tag from 'primevue/tag';

import type { DataExport } from '@/models/dataExports.model';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import HardwareSystemsHoverList from '@/components/hardwareSystems/HardwareSystemsHoverList.vue';

const dataExportsStore = useDataExportsStore();
const { isDataExportModalOpen, dataExports } = storeToRefs(dataExportsStore);
const userStore = useUsersStore();
const loading = ref(true);
const notAuthorized = ref(false);

const openCreateDataExportModal = async () => {
  await dataExportsStore.fetchDataExportTypeList();
  isDataExportModalOpen.value = true;
};

const activeRoles = computed(
  () =>
    userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
    userStore.me.roles.map((role) => role.id).includes(ASSET_VIEWER_ROLE)
);

const getDataExportStatusSeverity = function (status: string) {
  if (status == 'Error') {
    return 'danger';
  } else if (status == 'Complete') {
    return 'success';
  }
  return 'info';
};

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const openFileUrl = async (url: string) => {
  await dataExportsStore.fetchDataExportFileFullPath(url).then((response) => {
    window.open(response.data.download_url, '_blank');
  });
};

onMounted(async () => {
  try {
    await dataExportsStore.fetchDataExportList();
  } catch (error: any) {
    if (error?.response?.status === 403) {
      notAuthorized.value = true;
    } else {
      throw new Error('DataExports failed to be fetched');
    }
  } finally {
    loading.value = false;
  }
});
</script>
