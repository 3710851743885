<template>
  <div class="flex items-center country-wrapper">
    <CountryIcon
      v-if="showIcon"
      data-cy="charge-park-country-icon"
      :country-iso="countryIso"
      class="icon mr-2"
    />
    <div>
      <slot name="title"></slot>
      <div class="flex">
        <slot name="address"></slot>
        <span :class="nameClass">{{ countryName }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, watch } from 'vue';
import type { PropType } from 'vue';
import { useCountriesStore } from '@/stores/admin/countries/countries.store';
import CountryIcon from '@/components/common/countries/CountryIcon.vue';

export default {
  components: {
    CountryIcon,
  },
  props: {
    countryCode: {
      type: String as PropType<string>,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    nameClass: {
      type: String,
    },
  },
  setup(props) {
    const countriesStore = useCountriesStore();
    const countryName = ref('World');
    const countryIso = ref('WORLD');

    watch(
      () => props.countryCode,
      (value) => {
        countriesStore.fetchCountry(value).then((data) => {
          if (data && data.name && data.iso_code_alpha_3) {
            countryName.value = data.name;
            countryIso.value = data.iso_code_alpha_3;
          }
        });
      }
    );

    onMounted(async () => {
      await countriesStore.fetchCountry(props.countryCode).then((data) => {
        if (data && data.name && data.iso_code_alpha_3) {
          countryName.value = data.name;
          countryIso.value = data.iso_code_alpha_3;
        }
      });
    });

    return {
      countryName,
      countryIso,
    };
  },
};
</script>

<style scoped lang="scss">
.country-wrapper {
  min-width: 120px;
}
.icon {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  padding: 1px;
  border: 1px solid $text-light;
  align-self: center;
}
</style>
