<template>
  <div class="picker-table">
    <div v-if="loading" class="loader-wrapper loader-wrapper-sm">
      <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
    </div>
    <DataTable
      v-else
      scrollable
      v-model:filters="filters"
      class="p-datatable-sm alert-template-picker-table"
      dataKey="id"
      filterDisplay="menu"
      group-rows-by="groupName"
      row-group-mode="subheader"
      scrollHeight="500px"
      selectionMode="single"
      :globalFilterFields="['name']"
      :metaKeySelection="false"
      :value="templates"
      @rowSelect="onSelectedRow"
    >
      <template #groupheader="slotProps">
        <span class="font-medium text-xs text-gray-400">{{ slotProps.data.groupName }}</span>
      </template>
      <template #empty>{{ $t('alert.template.empty') }}</template>
      <template #loading>{{ $t('alert.template.loading') }}</template>
      <template #header>
        <div class="flex justify-between align-items-center">
          <span class="p-input-icon-left search">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              :placeholder="t('search').charAt(0).toUpperCase() + t('search').slice(1)"
              class="p-inputtext-sm"
            />
          </span>
        </div>
      </template>
      <Column selectionMode="single" style="flex: 0 0 0; padding: 0" bodyStyle="padding-left: 16px">
        <template #body="{ data }">
          <RadioButton
            v-model="selectedTemplate"
            data-cy="alert-template-radio-button"
            name="selectedParent"
            :id="data?.id"
            :value="data?.id"
          />
        </template>
      </Column>
      <Column field="name" :header="t('name')">
        <template #body="{ data }">
          <div class="pl-1">
            <span :id="data?.id" class="text-sm font-medium">
              {{ data?.name }}
            </span>
          </div>
        </template>
      </Column>
      <Column field="description" :header="t('description')">
        <template #body="{ data }">
          <div class="flex justify-start">
            <span v-if="data?.description" class="text-small">
              {{ data?.description }}
            </span>
            <span v-else class="empty text-grey">-</span>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { useI18n } from 'vue-i18n';
import { useVModel } from '@vueuse/core';

import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';

import type { AlertTemplateWithGroupName } from '@/models/alerts.model';

const { t } = useI18n();

const emit = defineEmits(['update:modelValue']);

const props = defineProps<{
  modelValue: string;
  templates: AlertTemplateWithGroupName[];
  loading?: boolean;
}>();

const selectedTemplate = useVModel(props, 'modelValue', emit);

const onSelectedRow = (event: any) => {
  selectedTemplate.value = event.data.id;
};

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
</script>

<style lang="scss" scoped>
:deep(.p-datatable) {
  border: 1px solid $grey-medium-light;
  border-radius: 4px;
}

:deep(.p-rowgroup-header) {
  background: $grey-light !important;
  top: 39px !important;
}

:deep(input.p-inputtext-sm::placeholder) {
  color: $text-light;
}
</style>
