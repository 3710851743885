<template>
  <div class="flex px-12 py-2 border-b text-sm">
    <div class="w-full info-single">
      <ReadOnlyTitle :title="$t('alert.parameters')" :empty="!selectedParameters.length" />
      <div v-for="(parameter, index) in selectedParameters || []" :key="index">
        <ReadOnlyItem
          :name="$t(`alert.parameter.${parameter.id}`)"
          :value="setValue(parameter)"
          connector
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { prettify } from '@/utils/textFormatting';
import { useAlertsStore } from '@/stores/admin/alerts/alerts.store';
import { storeToRefs } from 'pinia';

import ReadOnlyItem from '@/components/common/readOnly/ReadOnlyItem.vue';
import ReadOnlyTitle from '@/components/common/readOnly/ReadOnlyTitle.vue';

import type { GenericSetting } from '@/models/datatypes.model';
import { settingIsNumberGeneric } from '@/models/productTypes.model';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { convertValue, getUnitForSystem } from '@/utils/units';

const alertStore = useAlertsStore();
const usersStore = useUsersStore();
const prefferedUnitSystem = usersStore.me.units;
const { selectedParameters, alertModalData } = storeToRefs(alertStore);

const setValue = (parameter: GenericSetting) => {
  const paramData = alertModalData.value.parameters.find((param) => param.id === parameter.id) || {
    id: parameter.id,
    value: '--',
  };

  if (settingIsNumberGeneric(parameter) && parameter.unit !== undefined) {
    if (typeof paramData.value !== 'number') return paramData.value;
    const prefferedUnit = getUnitForSystem(parameter.unit, prefferedUnitSystem);
    const [value, unit] = convertValue(paramData.value, parameter.unit, prefferedUnit);
    return `${value} ${unit}`;
  }

  if (parameter.type === 'string') {
    return prettify(String(paramData.value));
  }

  return paramData.value;
};
</script>
