<template>
  <TabMenu data-cy="reports-tabs" :model="items" v-model:activeIndex="reportsActiveTab">
    <template #item="{ item }">
      <div class="p-menuitem-link" @click="item.action">
        <span data-cy="reports-tabs-item" class="p-menuitem-text">{{ item.label }}</span>
      </div>
    </template>
  </TabMenu>
</template>

<script setup lang="ts">
import TabMenu from 'primevue/tabmenu';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useReportsStore } from '@/stores/admin/reports/reports.store';

const { t } = useI18n();
const reportsStore = useReportsStore();
const { reportsActiveTab } = storeToRefs(reportsStore);

const items = ref([{ label: t('monitoring.history') }, { label: t('monitoring.configuration') }]);
</script>
