<template>
  <EventHistoryAggregatedTable
    v-if="eventTypeAggregation"
    :system="system"
    @switchEventTypeAggregation="switchEventTypeAggregation"
    v-model:startTime="startTime"
    v-model:endTime="endTime"
    v-model:filteredEventGroupTypes="filteredEventGroupTypes"
  ></EventHistoryAggregatedTable>
  <EventHistoryTable
    v-else
    :system="system"
    @switchEventTypeAggregation="switchEventTypeAggregation"
    v-model:startTime="startTime"
    v-model:endTime="endTime"
    v-model:filteredEventGroupTypes="filteredEventGroupTypes"
  ></EventHistoryTable>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import EventHistoryTable from './EventHistoryTable.vue';
import EventHistoryAggregatedTable from './EventHistoryAggregatedTable.vue';

import type { HardwareSystem } from '@/models/hardwareSystems.model';

const props = defineProps<{
  system: HardwareSystem;
}>();

const startTime = ref<Date | null>(new Date(Date.now() - 24 * 7 * 60 * 60 * 1000));
const endTime = ref<Date | null>(new Date());

const eventTypeAggregation = ref<boolean>(true);

const filteredEventGroupTypes = ref<number[]>([]);

function switchEventTypeAggregation(newEventTypeAggregation: boolean) {
  eventTypeAggregation.value = newEventTypeAggregation;
}
</script>
