import axios from 'axios';
import type { AxiosPromise, AxiosResponse } from 'axios';
import type { UserEdit, UserMe } from '@/models/users.model';
import { UnitSystem } from '@/models/datatypes.model';

export function fetchUsers(): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/users/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function createUser(data: UserEdit): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/users/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function editUser(id: number, data: UserEdit): Promise<AxiosResponse> {
  return axios
    .patch<AxiosPromise>(`/api/users/${id}/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function deleteUser(id: number): Promise<AxiosResponse> {
  return axios.delete<AxiosPromise>(`/api/users/${id}/`).catch((error) => {
    return Promise.reject(error);
  });
}

export function fetchOrganisationUsers(id: number): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/users/?organisation_id=${id}`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function fetchSingleUser(): Promise<AxiosResponse> {
  const dummyUnitSystem = localStorage.getItem('nidec.bys.dummyUnitSystem') ?? UnitSystem.METRIC;
  return axios
    .get<AxiosPromise>(`/api/users/me/`, {
      headers: {
        uid: 1,
      },
    })
    .then((response: AxiosResponse) => {
      response.data.unitSystem = dummyUnitSystem;
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function fetchRoles(): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/roles/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function postDpaPreference(accepted: boolean) {
  return await axios.post(`/api/users/me/dpa/`, { accepted });
}

export async function updateUserSettings(settings: Partial<UserMe>) {
  return await axios.patch(`/api/users/me/`, settings);
}
