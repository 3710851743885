<template>
  <div>
    <div class="flex justify-between my-2">
      <div class="flex flex-col w-1/2">
        <span data-cy="timespan-started-label" class="block font-medium py-3 whitespace-nowrap">{{
          startText
        }}</span>

        <span data-cy="timespan-started-label" class="block font-medium py-3 whitespace-nowrap">{{
          endText
        }}</span>
      </div>
      <div class="flex flex-col w-1/2">
        <div class="my-1">
          <DateTimeInput
            :id="'timespan-input-' + props.startedValueId"
            :data-cy="'timespan-input-' + props.startedValueId"
            :noTime="props.noTime"
            v-model="startedVal"
            :min-date-time="props.minStartDateTime"
            :max-date-time="maxStartedDateTime"
            @update:model-value="doValidation"
            :displayTimezoneOverride="props.displayTimezoneOverride"
          />
          <div class="input-errors" v-for="(error, index) of v$.startedVal.$errors" :key="index">
            <small class="p-error">{{ error.$message }}</small>
          </div>
        </div>
        <div class="my-1">
          <DateTimeInput
            :id="'timespan-input-' + props.endedValueId"
            :data-cy="'timespan-input-' + props.endedValueId"
            :noTime="props.noTime"
            v-model="endedVal"
            :min-date-time="minEndedDateTime"
            :max-date-time="props.maxEndDateTime"
            @update:model-value="doValidation"
            :displayTimezoneOverride="props.displayTimezoneOverride"
          />
          <div class="input-errors" v-for="(error, index) of v$.endedVal.$errors" :key="index">
            <small class="p-error">{{ error.$message }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 Example usage:
  <DateTimeRangeInput 
    :startedValueId="'started_at'"
    :endedValueId="'ended_at'"
    :startedValueLocale="'Started at'"
    :endedValueLocale="'Ended at'"
    :startedAfterEndedErrorLocale="'StartedAt should be smaller than EndedAt'"

    v-model:startedValue="started_at" 
    v-model:endedValue="ended_at"
    allowNoStarted
    allowNoEnded
    />

 */
import { computed, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';

import DateTimeInput from './DateTimeInput.vue';

import { useI18n } from 'vue-i18n';
import { helpers } from '@vuelidate/validators';
const { t } = useI18n();

//************************
// START PROP HANDLING LOGIC
//************************
type Props = {
  startedValue: string | null | undefined;
  endedValue: string | null | undefined;

  minStartDateTime?: string | undefined;
  maxStartDateTime?: string | undefined;
  minEndDateTime?: string | undefined;
  maxEndDateTime?: string | undefined;

  // locale translation strings
  startedValueLocale: string;
  endedValueLocale: string;
  startedAfterEndedErrorLocale: string;

  startedValueId?: string;
  endedValueId?: string;

  allowNoStarted?: boolean;
  allowNoEnded?: boolean;

  noTime?: boolean;

  displayTimezoneOverride?: string;
};
const props = withDefaults(defineProps<Props>(), {
  allowNoStarted: false,
  allowNoEnded: false,
  startedValueId: 'startedValue',
  endedValueId: 'endedValue',
});
const emit = defineEmits(['update:startedValue', 'update:endedValue', 'update']);

const startText = ref(props.startedValueLocale);
const endText = ref(props.endedValueLocale);

const startedVal = computed({
  get: () => {
    return props.startedValue;
  },
  set: (val) => {
    emit('update:startedValue', val);
    emit('update');
  },
});

const endedVal = computed({
  get: () => {
    return props.endedValue;
  },
  set: (val) => {
    emit('update:endedValue', val);
    emit('update');
  },
});

const minEndedDateTime = computed<string | undefined>(() => {
  if (!startedVal.value) {
    if (props.minEndDateTime) return props.minEndDateTime;
    return undefined;
  }
  const startedDate = new Date(startedVal.value);
  if (props.minEndDateTime && startedDate > new Date(props.minEndDateTime))
    return props.minEndDateTime;
  return startedDate.toISOString();
});

const maxStartedDateTime = computed<string | undefined>(() => {
  if (!endedVal.value) {
    if (props.maxStartDateTime) return props.maxStartDateTime;
    return undefined;
  }
  const endedDate = new Date(endedVal.value);
  if (props.maxStartDateTime && endedDate < new Date(props.maxStartDateTime))
    return props.maxStartDateTime;
  return endedDate.toISOString();
});

//************************
// START VALIDATION LOGIC
//************************

const validations = {
  startedVal: {
    requiredIfNoStarted: helpers.withMessage(
      t('rules.required', { field: t('rules.dateAndTime'), item: props.startedValueLocale }),
      requiredIfNoStarted
    ),
    startedNotAfterEnded: helpers.withMessage(
      props.startedAfterEndedErrorLocale,
      startedNotAfterEnded
    ),
  },
  endedVal: {
    requiredIfNoEnded: helpers.withMessage(
      t('rules.required', { field: t('rules.dateAndTime'), item: props.endedValueLocale }),
      requiredIfNoEnded
    ),
    startedNotAfterEnded: helpers.withMessage(
      props.startedAfterEndedErrorLocale,
      startedNotAfterEnded
    ),
  },
};

const v$ = useVuelidate(validations, { startedVal, endedVal }, { $scope: true });

const doValidation = function () {
  v$.value.startedVal.$touch();
  v$.value.endedVal.$touch();
};

// Custom validation logic

function startedNotAfterEnded() {
  // Assume your component has 'startTime' and 'endTime' data properties
  const startTime = startedVal.value;
  const endTime = endedVal.value;

  // Check if both start and end times are set before validation
  if (startTime && endTime) {
    // Check if the start time is not after the end time
    if (new Date(startTime) < new Date(endTime)) {
      return true;
    } else {
      return false;
    }
  }

  // If either start or end time is not set, consider it as valid
  return true;
}

function requiredIfNoStarted(value: string) {
  return props.allowNoStarted ? true : !!value;
}

function requiredIfNoEnded(value: string) {
  return props.allowNoEnded ? true : !!value;
}
</script>
<style></style>
