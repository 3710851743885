<template>
  <div class="flex flex-row">
    <p
      class="flex items-center justify-center rounded-l-md border border-gray-200 bg-gray-50 text-gray-400 p-2"
    >
      {{ title }}
    </p>
    <fieldset
      class="flex flex-wrap items-center gap-2 px-4 py-2 rounded-r-md border border-gray-200 border-l-0"
    >
      <label v-for="item in items" :key="item.id" class="mr-2 text-gray-600">
        <input
          :id="item.name"
          type="checkbox"
          :value="item.id"
          :checked="props.selected.includes(item.id)"
          @change="toggleItem(item.id)"
          class="accent-green-primary focus:ring-accent-green-primary"
        />
        {{ item.name }}
      </label>
    </fieldset>
  </div>
</template>

<script setup lang="ts" generic="T extends number | string">
type FilterItem = {
  // eslint-disable-next-line no-undef
  id: T;
  name: string;
};

const props = withDefaults(
  defineProps<{
    items: FilterItem[];
    title: string;
    labelWidth?: number;
    selected: FilterItem['id'][];
  }>(),
  {
    labelWidth: 85,
  }
);

const emit = defineEmits<{
  (event: 'update:selected', value: FilterItem['id'][]): void;
}>();

const toggleItem = (id: FilterItem['id']) => {
  let items = [...props.selected];
  if (items.includes(id)) {
    items = items.filter((item) => item !== id);
  } else {
    items.push(id);
  }
  emit('update:selected', items);
};
</script>
