import type { AxiosPromise, AxiosResponse } from 'axios';
import axios from 'axios';
import type { DataExportCreationData } from '@/models/dataExports.model';

export async function fetchDataExports(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/jobs/data-exports/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createNewDataExport(data: DataExportCreationData): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/jobs/data-exports/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function fetchDataExportTypes(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/data-export-datatypes/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchDataExportFilePath(url: string): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/jobs/data-exports/files/${url}`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}
