<template>
  <div class="table-wrp block max-h-96 w-full overflow-scroll">
    <table class="w-full relative border-separate">
      <thead class="bg-white sticky top-0 z-10">
        <tr class="text-left">
          <th class="z-10 px-2 py-2 border-b border-neutral-200">
            {{ $t('dashboard.telemetry.table.timestamp') }}
          </th>
          <th class="z-10 px-2 py-2 border-b border-neutral-200">
            {{ $t('dashboard.telemetry.table.value') }}
          </th>
        </tr>
      </thead>

      <tbody class="max-h-96 w-full overflow-auto z-0">
        <tr
          v-for="row in tableData"
          :key="row.timestamp"
          class="even:bg-neutral-50 border-b border-neutral-200 last:border-b-0"
        >
          <td class="whitespace-nowrap px-3 py-1">
            <date-time-display :date="secondsToMilliseconds(row.timestamp)" />
          </td>
          <td class="whitespace-nowrap px-3 py-1">
            <p>{{ row.value }} {{ row.unit }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup lang="ts">
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import type { DataResponse } from '@/stores/admin/controlPanel/controlPanel.types';
import { computed } from 'vue';
import { convertValue } from '@/utils/units';

const props = defineProps<{
  originalUnit?: string | null;
  desiredUnit?: string | null;
  dataItem: DataResponse[number];
  enumValues?: { value: number; label: string }[];
}>();

function secondsToMilliseconds(timestamp: number) {
  return timestamp * 1000;
}

type TableData = {
  timestamp: number;
  value: string | number;
  unit: string | null;
}[];

function toTableData(dataItem: DataResponse[number]): TableData {
  const [yData, yUnit] = convertValue(dataItem.y.data, props.originalUnit, props.desiredUnit);

  return dataItem.x.data
    .map((timestamp, index) => ({
      timestamp,
      value:
        props.enumValues?.find((enumValue) => enumValue.value === yData[index])?.label ||
        yData[index],
      unit: yUnit,
    }))
    .sort((a, b) => b.timestamp - a.timestamp);
}

const tableData = computed(() => toTableData(props.dataItem));
</script>

<style scoped lang="scss"></style>
