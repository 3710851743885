<template>
  <Dialog
    data-cy="whitelist-add-rfid-tags-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '935px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="flex field-no-edit justify-center">
      <span class="pr-20">{{ $t('whitelist.title') }}</span>
      <span class="break-all">{{ whitelistDetails.name }}</span>
    </div>
    <RfidTagsPicker
      @onRfidTagsSelect="setSelectedRfidTags"
      @onRfidTagsUnselect="setUnselectedRfidTags"
      @onRfidTagsSelectAll="setAllSelectedRfidTags"
      @onUnselectedAllRfidTags="setAllUnselectedRfidTags"
      :whitelistId="whitelistDetails.id"
    />
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        data-cy="add-rfid-tags-to-whitelist-button"
        :label="t('add')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        :disabled="formIsNotValid"
        :loading="loading"
        @click="addRfidTags"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="add-rfid-tags-to-whitelist-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="add-rfid-tags"
  >
    <template v-if="isFailed" #message="slotProps">
      <div class="flex flex-col w-full pt-1">
        <div class="w-full flex">
          <span class="p-toast-message-icon pi pi-info-circle"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
          </div>
        </div>
        <List
          :data="addRfidTagsFailedIds"
          :title="t('whitelist.failedRfidTagsIds')"
          class="w-full my-5"
        />
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { required, helpers } from '@vuelidate/validators';
import { useWhitelistsStore } from '@/stores/admin/whitelists/whitelists.store';
import type { WhitelistId } from '@/models/whitelists.model';
import List from '@/components/common/List.vue';
import RfidTagsPicker from '@/components/rfidTags/RfidTagsPicker.vue';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

export default {
  name: 'WhitelistAddRfidTagsModal',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    Toast,
    RfidTagsPicker,
    List,
  },

  setup() {
    const { t } = useI18n();
    const whitelistsStore = useWhitelistsStore();
    const { whitelistDetails, whitelistAddRfidTagsModalOpen } = storeToRefs(whitelistsStore);
    const toast = useToast();
    const isVisible = ref(false);
    const loading = ref(false);
    const addRfidTagsFailedIds = ref<number[]>([]);
    const isFailed = ref(false);
    const rfidTags = ref({
      selected: false,
      items: [] as WhitelistId[],
    });
    const rfidTagsInWhitelist = computed(() => {
      return whitelistDetails.value.rfidtags.map((item) => item.rfid_tag);
    });

    const modalTitle = computed(() => t('whitelist.addRfidTags'));

    const resetForm = () => {
      rfidTags.value.selected = false;
      rfidTags.value.items = [];
      v$.value.$reset();
    };

    const closeModal = () => {
      whitelistAddRfidTagsModalOpen.value = false;
      loading.value = false;

      resetForm();
    };

    const formIsNotValid = computed(() => {
      return !v$.value.selected.$anyDirty ? true : false;
    });

    const setSelectedRfidTags = (id: WhitelistId) => {
      rfidTags.value.items.push(id);
      v$.value.selected.$touch();
    };

    const setUnselectedRfidTags = (id: number) => {
      const rfidTagPos = rfidTags.value.items.findIndex((item) => item.id === id);
      rfidTags.value.items.splice(rfidTagPos, 1);
      v$.value.selected.$touch();

      if (rfidTags.value.items.length === 0) {
        v$.value.selected.$reset();
      }
    };

    const setAllSelectedRfidTags = (ids: WhitelistId[]) => {
      rfidTags.value.items = ids;
      v$.value.selected.$touch();
    };

    const setAllUnselectedRfidTags = () => {
      rfidTags.value.items = [];
      v$.value.selected.$reset();
    };

    const showSuccess = () => {
      toast.add({
        severity: 'success',
        summary: t('toast.successBatch', {
          name: t('rfidTags'),
          action: t('toast.added'),
        }),
        life: DEFAULT_TOAST_LIFE_MILLISECONDS,
        group: 'add-rfid-tags',
      });
    };

    const showError = () => {
      toast.add({
        severity: 'error',
        summary: t('toast.errorBatchWhen', {
          name: t('rfidTags'),
          action: t('toast.adding'),
        }),
        group: 'add-rfid-tags',
      });
    };

    const addRfidTags = async () => {
      await v$.value.$validate();

      if (v$.value.$error) return;

      loading.value = true;

      await whitelistsStore
        .addRfidTagsToWhitelist(whitelistDetails.value.id, rfidTags.value.items)
        .then((response: any) => {
          loading.value = false;
          if (response.status === 201) {
            toast.removeGroup('add-rfid-tags');
            showSuccess();
            closeModal();
          }
        })
        .catch((error) => {
          toast.removeGroup('add-rfid-tags');
          if (error.response && error.response.status === 400 && error.response.data.failed_items) {
            addRfidTagsFailedIds.value = error.response.data.failed_items.map(
              (item: WhitelistId) => item.id
            );
            isFailed.value = true;
            showError();
            closeModal();
          } else {
            showError();
          }
          loading.value = false;
          throw new Error(error);
        });
    };

    watch(
      () => whitelistAddRfidTagsModalOpen.value,
      (value) => {
        if (value) {
          isVisible.value = true;
        } else {
          isVisible.value = false;
        }
      }
    );

    const rules = computed(() => {
      return {
        selected: {
          required: helpers.withMessage(t('rules.parentRequired'), required),
        },
      };
    });

    const v$ = useVuelidate(rules, rfidTags.value);

    return {
      toast,
      modalTitle,
      t,
      v$,
      closeModal,
      isVisible,
      showSuccess,
      showError,
      resetForm,
      loading,
      setSelectedRfidTags,
      formIsNotValid,
      whitelistDetails,
      addRfidTags,
      rfidTags,
      addRfidTagsFailedIds,
      isFailed,
      rfidTagsInWhitelist,
      setAllSelectedRfidTags,
      setAllUnselectedRfidTags,
      setUnselectedRfidTags,
    };
  },
};
</script>
<style lang="scss" scoped>
.pi-info-circle {
  font-size: 12px;
}
</style>
