<template>
  <page-header :title="t('rfidTags')" />
  <div class="container container-grey">
    <RfidTagsTable />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import RfidTagsTable from '@/components/rfidTags/RfidTagsTable.vue';
import PageHeader from '@/components/common/layout/PageHeader.vue';

const { t } = useI18n();
</script>

<style lang="scss" scoped></style>
