<template>
  <div class="bg-[var(--gray-100)] p-4 text-left">
    <SelectButton
      v-model="selectedDays"
      :options="daysOfWeek"
      @change="setSelectedDays"
      optionLabel="name"
      multiple
      aria-labelledby="multiple"
      class="mb-4 w-full"
    />
    <Calendar
      v-model="timeFrom"
      timeOnly
      :hourFormat="primeVueHourFormat"
      :stepMinute="10"
      :placeholder="$t('chargePark.timeFrom')"
    />
    <span class="mx-3">-</span>
    <Calendar
      v-model="timeTo"
      timeOnly
      :hourFormat="primeVueHourFormat"
      :stepMinute="10"
      :placeholder="$t('chargePark.timeTo')"
    />
    <div class="mt-2 text-sm text-[var(--gray-400)] text-left">
      <i class="text-xs pi pi-info-circle" />
      <span class="pl-1">{{ $t('chargePark.timeInfo') }}</span>
    </div>
    <div class="flex justify-center mt-3">
      <Button
        :label="t('cancel')"
        icon="pi pi-times"
        class="p-button-xxs p-button-white mx-1"
        @click="closeEditing"
      />
      <Button
        data-cy="panel-edit-apply"
        :label="$t('apply')"
        icon="pi pi-chevron-circle-down"
        class="p-button-xxs p-button-primary mx-1"
        @click="onApply"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import { useToast } from 'primevue/usetoast';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import SelectButton from 'primevue/selectbutton';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import { dayJsTimeFormat, primeVueHourFormat } from '@/utils/dateTimeFormatVue';

import type { AccessTimes } from '@/models/chargeParks.model';
dayjs.extend(customParseFormat);
const emit = defineEmits(['closeEditingRule', 'success', 'error', 'onError']);

const { t } = useI18n();
const toast = useToast();
const chargeParksStore = useChargeParksStore();
const systemsStore = useHardwareSystemsStore();
const { chargeParkEditionData, daysOfWeek, ruleEditingData } = storeToRefs(chargeParksStore);
const selectedDays = ref();
const timeFrom = ref<string | Date>(dayjs().format(dayJsTimeFormat.value));
const timeTo = ref<string | Date>(dayjs().add(1, 'hour').format(dayJsTimeFormat.value));
const loading = ref(false);
const accessTimes = ref<AccessTimes[] | null>(chargeParkEditionData.value.access_times);

const convertedTimeFrom = computed(() => {
  return typeof timeFrom.value === 'string' || (timeFrom.value as any) instanceof String
    ? timeFrom.value
    : dayjs(timeFrom.value).format('HH:mm');
});

const convertedTimeTo = computed(() => {
  return typeof timeTo.value === 'string' || (timeTo.value as any) instanceof String
    ? timeTo.value
    : dayjs(timeTo.value).format('HH:mm');
});

const setSelectedDays = (days: any) => {
  chargeParkEditionData.value.access_times?.map((item, index) => {
    if (index === ruleEditingData.value.index) {
      item.days = days.value.map((item: any) => item.id);
      return item;
    }
  });
};

const closeEditing = () => {
  emit('closeEditingRule');
};

const onApply = () => {
  if (!accessTimes.value) {
    return;
  }
  const convertedData = { access_times: [...accessTimes.value] };

  chargeParksStore
    .editChargeParkDetails(chargeParkEditionData.value.id, convertedData)
    .then((response: any) => {
      if (response.status === 200) {
        chargeParksStore.fetchChargePark(chargeParkEditionData.value.id);
        systemsStore.fetchSystemsByChargeParkId(chargeParkEditionData.value.id);
        toast.removeAllGroups();
        emit('success');
        closeEditing();
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 400 && error.response.data.access_times) {
        const verifyErrorMessage = error.response.data.access_times[0].error_details.error_message;
        toast.removeAllGroups();
        emit('onError', verifyErrorMessage);
        emit('error');
      } else {
        emit('error');
      }
      throw new Error(error);
    });
};

watch(
  () => chargeParkEditionData.value.access_times,
  (value) => {
    accessTimes.value = value;
  },
  { immediate: true, deep: true }
);

watch(
  () => ruleEditingData.value.days,
  (value) => {
    selectedDays.value = daysOfWeek.value.filter((item) => value.includes(item.id));
  },
  { immediate: true }
);

watch(
  () => ruleEditingData.value.time,
  (value) => {
    if (value !== '') {
      timeFrom.value = dayjs(value.split('-')[0], 'HH:mm').toDate();
    }
  },
  { immediate: true }
);

watch(
  () => ruleEditingData.value.time,
  (value) => {
    if (value !== '') {
      timeTo.value = dayjs(value.split('-')[1], 'HH:mm').toDate();
    }
  },
  { immediate: true }
);

watch(
  () => timeFrom.value,
  (value) => {
    if (value && timeTo.value) {
      chargeParkEditionData.value.access_times?.map((item, index) => {
        if (index === ruleEditingData.value.index) {
          item.time = `${convertedTimeFrom.value}-${convertedTimeTo.value}`;
          return item;
        }
      });
    }
  }
);

watch(
  () => timeTo.value,
  (value) => {
    if (value && timeFrom.value) {
      chargeParkEditionData.value.access_times?.map((item, index) => {
        if (index === ruleEditingData.value.index) {
          item.time = `${convertedTimeFrom.value}-${convertedTimeTo.value}`;
          return item;
        }
      });
    }
  }
);
</script>
<style scoped lang="scss">
:deep(.p-calendar .p-inputtext) {
  padding: 6px;
}
</style>
