import type { IdName } from '@/models/common.model';

export interface Media {
  id: number;
  created_by_name: string;
  thumbnail: string;
  file_name: string;
  file_size: number;
  metadata: {
    size: string;
    duration: number;
  };
  status: string;
  file_type: MediaType;
  file_extension: string;
  created_at: string;
  file_url: string;
  playlists: IdName[];
  playlists_count: number;
}

export enum MediaType {
  Image = 'Image',
  Video = 'Video',
}

export interface MediaStatus{
  id: number;
  name: string;
}