<template>
  <input
    :id="props.id"
    type="number"
    :min="props.min"
    :max="props.max"
    step="1"
    v-model.number="value"
    @input="update"
  />
</template>
<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
  id: string;
  min?: number;
  max?: number;
  label?: string;
  value: number | null;
  defaultValue?: number | null;
}>();

const value = ref(String(props.value ?? props.defaultValue));

const emit = defineEmits<{
  (event: 'update:value', value: number | null): void;
}>();

function update() {
  if (value.value === '') {
    emit('update:value', null);
  } else if (
    !isNaN(Number(value.value)) &&
    (props.min == undefined || Number(value.value) >= props.min) &&
    (props.max == undefined || Number(value.value) <= props.max)
  ) {
    emit('update:value', +value.value);
  }
}
</script>

<style scoped lang="scss">
input {
  border: 1px solid var(--gray-200);
  padding: 0.25rem 0.5rem;
  border-radius: var(--rounded-md);
  text-align: right;
}
</style>
