<template>
  <div class="flex px-12 border-b text-sm pt-2 pb-1">
    <div class="w-full info-single">
      <ReadOnlyItem :name="t('report.startDate')">
        <date-time-display
          :date="reportModalData.start_date"
          :overrideTimezone="'UTC'"
          bold
          onlyDate
        />
      </ReadOnlyItem>
      <ReadOnlyItem :name="t('report.endDate')">
        <date-time-display
          :date="reportModalData.end_date"
          :overrideTimezone="'UTC'"
          bold
          onlyDate
        />
      </ReadOnlyItem>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import ReadOnlyItem from '@/components/common/readOnly/ReadOnlyItem.vue';
import { useReportsStore } from '@/stores/admin/reports/reports.store';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const reportsStore = useReportsStore();
const { reportModalData } = storeToRefs(reportsStore);
</script>
