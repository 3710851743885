<template>
  <div class="mt-2 pt-4 px-12">
    <span class="block title font-medium field-edit-label">
      {{ t('template') }}
    </span>
    <p class="block text-grey text-sm mb-2">{{ t('alert.template.description') }}</p>
    <div class="mb-5">
      <AlertTemplatePicker
        v-model="alertModalData.template_id"
        :templates="alertTemplateList"
        v-bind="{ loading }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import AlertTemplatePicker from '@/components/alerts/AlertTemplatePicker.vue';
import { onMounted, ref, watch } from 'vue';
import { useAlertsStore } from '@/stores/admin/alerts/alerts.store';
import { storeToRefs } from 'pinia';

import type { alertRules } from '@/utils/alertModalRules';
import type { AlertTemplateWithGroupName } from '@/models/alerts.model';
import type { Validation } from '@vuelidate/core';

const { t } = useI18n();
const alertsStore = useAlertsStore();
const { alertTemplateList, alertModalData, selectedParameters } = storeToRefs(alertsStore);

const loading = ref(false);

const props = defineProps<{
  v$: Validation<typeof alertRules>;
}>();

onMounted(async () => {
  try {
    loading.value = true;

    if (!alertsStore.alertTemplateList.length) {
      await alertsStore.fetchAlertTemplateGroups();
    }
  } catch (e: unknown) {
    console.error(e);
  } finally {
    loading.value = false;
  }
});

watch(
  () => alertModalData.value.template_id,
  () => {
    const selectedTemplate = alertTemplateList.value.find(
      (template: AlertTemplateWithGroupName) => template.id === alertModalData.value.template_id
    );

    if (!selectedTemplate) return;

    props.v$.template_id.$touch();

    alertsStore.$patch({
      alertModalData: {
        parameters: selectedTemplate.parameters.map((parameter) => ({
          id: parameter.id,
          value: parameter.defaultValue,
        })),
      },
    });

    selectedParameters.value = selectedTemplate.parameters;
  },
  { deep: true }
);
</script>
