import type {
  Widget,
  PlotValue,
  WidgetData,
  PieChartWidget,
  BarChartWidget,
  WidgetQuery,
  WidgetFilter,
  RenderableWidget,
  MapWidget,
  TableWidget,
  Dimension,
} from '@/stores/admin/dashboard/dashboard.types';
import { allLettersAreCapital, prettify } from '@/utils/textFormatting';
import type { EChartsOption } from 'echarts';
import type { WidgetContextResolver } from '@/stores/admin/dashboard/dashboard.logic.context';
import type { Result } from '@/@types';
import { Err, Ok } from '@/@types';
import type { XAXisOption } from 'echarts/types/dist/shared';
import { convertValue, getUnitForSystem } from '@/utils/units';
import { loadWidgetData } from '@/stores/admin/dashboard/dashboard.api';
import type { UnitSystem } from '@/models/datatypes.model';
import type { ColorGenerator } from '@/stores/admin/dashboard/dashboard.logic.colors';

export type SummaryItem = { id: string; name: string; value: PlotValue; color: string };

const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export function mergeFilters(query: WidgetQuery, additionalFilters: WidgetFilter[]): WidgetQuery {
  const queryWithAllFilters = deepCopy(query);

  queryWithAllFilters.transform.filters = query.transform.filters
    .filter((f) => !additionalFilters.some((af) => af.dimension === f.dimension))
    .concat(additionalFilters);

  return queryWithAllFilters;
}

function truncateTimestampToBinSize(date: Date, binSize: string): Date {
  if (binSize.endsWith('s')) date.setMilliseconds(0);
  if (binSize.endsWith('min')) date.setSeconds(0, 0);
  if (binSize.endsWith('h')) date.setMinutes(0, 0, 0);
  if (binSize.endsWith('d')) date.setHours(0, 0, 0, 0);
  if (binSize.endsWith('w')) date.setHours(0, 0, 0, 0);
  if (binSize.endsWith('m')) date.setDate(1);
  if (binSize.endsWith('y')) date.setMonth(0, 1);
  return date;
}

function toPlotValue(type: 'time' | 'category', binSize?: string): (val: PlotValue) => PlotValue {
  return function (val: PlotValue): PlotValue {
    if (type === 'category') return val;
    if (binSize != undefined)
      return truncateTimestampToBinSize(new Date(+val * 1000), binSize).getTime();
    return new Date(+val * 1000).getTime();
  };
}

export function getGroupKey(query: WidgetQuery, defaultKey?: string): string | undefined {
  return query.transform.groupBy?.[0]?.target || defaultKey;
}

export function getBinSize(query: WidgetQuery): string | undefined {
  return query.transform.groupBy?.[0]?.binSize;
}

export function buildTimeXAxis(show = true): XAXisOption {
  return {
    type: 'time',
    show,
  };
}

function buildCategoryXAxis(show = true, resolver?: (val: any) => string): XAXisOption {
  const formatter = resolver ? (val: string) => resolver(val) : (val: any) => String(val);
  return {
    type: 'category',
    axisLabel: { formatter, rotate: 45 },
    show,
  };
}

export async function resolve<T extends string | number | string[] | number[]>(
  dimensionId: WidgetFilter['dimension'],
  dimensionVal: T,
  contextResolver: WidgetContextResolver
): Promise<T> {
  if (Array.isArray(dimensionVal))
    return (await Promise.all(
      dimensionVal.map((val) => resolve(dimensionId, val, contextResolver))
    )) as T;

  await contextResolver.init();
  const resolver = contextResolver.getResolverFor(dimensionId);
  if (resolver === undefined) return dimensionVal;
  return resolver(dimensionVal.toString()) as T;
}

function isNumberArray(val: any): val is number[] {
  return Array.isArray(val) && typeof val[0] === 'number';
}

export async function buildEchartsOption(
  widget: PieChartWidget | BarChartWidget,
  colors: ColorGenerator,
  data: WidgetData,
  contextResolver: WidgetContextResolver
): Promise<Result<EChartsOption>> {
  const type = widget.type;
  let groupKey;
  let binSize;
  if(widget.query){
    groupKey = getGroupKey(widget.query);
    binSize = getBinSize(widget.query);
  } else {
    groupKey = "value"
  }

  const xAxisType = groupKey === 'time' ? 'time' : 'category';

  const xAxisData: PlotValue[] = data.series[0].map(toPlotValue(xAxisType, binSize));
  let yAxisData = data.series[1];

  let colorList = undefined;

  if (widget.presentation?.colorMap?.colorBy === 'index')
    colorList = xAxisData.map((val) => colors.get(val));
  if (widget.presentation?.colorMap?.colorBy === 'value')
    colorList = yAxisData.map((val) => colors.get(val));

  const xAxisFormatter = contextResolver.getResolverFor(groupKey);

  const xAxis =
    xAxisType === 'time'
      ? buildTimeXAxis(type != 'pie')
      : buildCategoryXAxis(type != 'pie', xAxisFormatter);

  let currentYAxisUnit ="";
  // load units from product type channel
  if(widget.query){
    const productType = widget.query.series.productType;
    const channel = widget.query.series.path.split(':').at(-1);
    currentYAxisUnit =
    productType && channel ? contextResolver.resolveUnit(productType, +channel) : '';
  }

  
  const desiredYAxisUnit = widget.axis.y.desiredUnit;

  if (currentYAxisUnit && desiredYAxisUnit && isNumberArray(yAxisData)) {
    const [convertedYAxisData, convertedYAxisUnit] = convertValue(
      yAxisData,
      currentYAxisUnit,
      widget.axis.y.desiredUnit
    );

    yAxisData = convertedYAxisData;
    currentYAxisUnit = convertedYAxisUnit;
  }

  const echartsOption: Required<Pick<EChartsOption, 'xAxis'>> & EChartsOption = {
    tooltip: {
      trigger: 'item',
      confine: true,
    },
    dataset: {
      seriesLayoutBy: 'row',
      dimensions: [groupKey, 'value'],
      source: [xAxisData, yAxisData],
    },
    grid: { containLabel: true, left: '3%', right: '4%', bottom: '3%', top: '10%' },
    xAxis,
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (val: unknown) => formatValue(val, { unit: currentYAxisUnit, maxDecimals: 3 }),
      },
    },
    series: [
      {
        type,
        colorBy: 'data',
        color: colorList,
        tooltip: {
          show: true,
          formatter: (val) =>
            [
              val.marker,
              (xAxisFormatter?.(val.name) ?? val.name) + ':',
              formatValue(yAxisData[val.dataIndex], {
                unit: currentYAxisUnit,
                maxDecimals: 3,
              }),
            ].join(' '),
        },
      },
    ],
  };

  return Ok(echartsOption);
}



export function buildSummary(
  widget: Widget,
  data: WidgetData,
  colors: ColorGenerator
): SummaryItem[] {
  return data.series[0].map((_, i) => ({
    id: String(data.series[0][i]),
    name: allLettersAreCapital(String(data.series[0][i]))? prettify(String(data.series[0][i])): String(data.series[0][i]),
    value: data.series[1][i],
    color: colors.get(data.series[0][i]),
  }));
}

export type MapPoint = {
  lat?: number;
  lng?: number;
  value: number | string;
  color: string;
  assetId?: string;
  assetName?: string;
};

export async function buildMapPoints(
  widget: MapWidget,
  colors: ColorGenerator,
  data: WidgetData,
  contextResolver: WidgetContextResolver
): Promise<Result<MapPoint[]>> {
  
  if(!widget.query) throw Error("widget.query is not defined!" + JSON.stringify(widget) )
  const groupKey = getGroupKey(widget.query, 'system.id');

  if (groupKey === undefined || (groupKey !== 'chargepark.id' && groupKey !== 'system.id')) {
    return Err(new Error('Bad Query: No group by key'));
  }

  const assets = contextResolver.getAssets(groupKey, data.series[0]);
  const values = data.series[1];

  const points: MapPoint[] = [];

  data.series[0].forEach((id, index) => {
    const asset = assets[id];
    if (asset === undefined) return;
    points.push({
      lat: asset?.lat,
      lng: asset?.lng,
      value: values[index],
      color: colors.get(values[index]),
      assetId: asset.id,
      assetName: asset.name,
    });
  });

  return Ok(points);
}

const centerOfBerlin = [52.520008, 13.404954];

export function computeBounds(points: { lat: number; lng: number }[]) {
  if (points.length == 0) {
    return [
      [centerOfBerlin[0] - 0.1, centerOfBerlin[1] - 0.1],
      [centerOfBerlin[0] + 0.1, centerOfBerlin[1] + 0.1],
    ] as [[number, number], [number, number]];
  }

  if (points.length == 1) {
    const defaultPadding = 0.01;
    const point = points[0];
    return [
      [point.lat - defaultPadding, point.lng - defaultPadding],
      [point.lat + defaultPadding, point.lng + defaultPadding],
    ] as [[number, number], [number, number]];
  }

  return points.reduce(
    (acc, cur) => {
      if (cur.lat < acc[0][0]) acc[0][0] = cur.lat;
      if (cur.lng < acc[0][1]) acc[0][1] = cur.lng;
      if (cur.lat > acc[1][0]) acc[1][0] = cur.lat;
      if (cur.lng > acc[1][1]) acc[1][1] = cur.lng;
      return acc;
    },
    [
      [Infinity, Infinity],
      [-Infinity, -Infinity],
    ] as [[number, number], [number, number]]
  );
}

////////// TABLE WIDGET

export type TableColumnHeader = {
  key: string;
  label: string;
  unit?: string;
  dimension?: Dimension;
};

type RawTableRow = {
  [key: string]: string | number | boolean | null;
};

export type TableRow = {
  [key: string]: { value: string | number | boolean | null; id?: string };
};

export type TableData = {
  headers: TableColumnHeader[];
  rows: TableRow[];
};

type MergeInput = {
  key: string;
  x: (string | number)[];
  y: (string | number)[];
};

async function loadColumnData(
  index: string,
  query: WidgetQuery,
  additionalFilters: WidgetFilter[]
) {
  const response = await loadWidgetData(query, additionalFilters);
  if (!response.ok) return { index, data: { series: [] } as WidgetData };
  return { index, data: response.data };
}

type ColumnUnitMap = { [key: string]: { original?: string; desired?: string } };

function formatToMaxDecimals(val: number, decimals: number): string {
  const factor = Math.pow(10, decimals);
  return (Math.round(val * factor) / factor).toString();
}

function buildColumnUnitMap(
  widget: TableWidget,
  contextResolver: WidgetContextResolver,
  desiredUnitSystem?: UnitSystem
): ColumnUnitMap {
  const result: ColumnUnitMap = {};

  for (const [index, col] of widget.columns.entries()) {
    const key = String(index);
    const productType = col.query.series.productType;
    const channel = col.query.series.path.split(':').at(-1);
    const originalUnit =
      productType && channel ? contextResolver.resolveUnit(productType, +channel) : '';
    const originalUnitOverride = col.overrideSourceUnit;
    const desiredUnit = col.desiredUnit;

    const original = originalUnitOverride || originalUnit;

    if (desiredUnitSystem !== undefined && original !== undefined) {
      if (desiredUnit !== undefined) getUnitForSystem(desiredUnit, desiredUnitSystem);
      else getUnitForSystem(original, desiredUnitSystem);
    }

    result[key] = {
      original: originalUnitOverride || originalUnit,
      desired: desiredUnit,
    };
  }

  return result;
}

const unitsWithoutSpace = ['%', '°C', '°F', '°'];

function formatValue(val: unknown, opts: { maxDecimals?: number; unit?: string }): string {
  let strVal: string;
  if (typeof val === 'number') {
    strVal = formatToMaxDecimals(val, opts.maxDecimals ?? 3);
  } else {
    strVal = String(val);
  }

  if (!opts.unit) return strVal;
  const separator = unitsWithoutSpace.includes(opts.unit) ? '' : ' ';
  return strVal + separator + opts.unit;
}

export async function loadTableWidgetData(
  widget: TableWidget,
  additionalFilters: WidgetFilter[],
  contextResolver: WidgetContextResolver,
  desiredUnitSystem?: UnitSystem
): Promise<TableData> {
  let xColumnLabel = widget.axis.x.label;
  if (widget.axis.x.dimension === 'system.id') xColumnLabel = 'System';
  if (widget.axis.x.dimension === 'chargepark.id') xColumnLabel = 'Site';

  const columns: TableColumnHeader[] = [
    { key: 'x', label: xColumnLabel, dimension: widget.axis.x.dimension },
    ...widget.columns.map((col, idx) => ({
      key: String(idx),
      label: col.label,
      dimension: undefined,
    })),
  ];

  const columnUnitMap = buildColumnUnitMap(widget, contextResolver, desiredUnitSystem);

  const responses = await Promise.all(
    widget.columns.map((col, idx) => loadColumnData(String(idx), col.query, additionalFilters))
  );

  const data: MergeInput[] = responses.map(({ index, data }) => ({
    key: index,
    x: data.series[0],
    y: data.series[1],
  }));

  for (const col of data) {
    const unit = columnUnitMap[col.key];
    if (isNumberArray(col.y)) {
      const [convertedYAxisData, convertedYAxisUnit] = convertValue(
        col.y,
        unit.original,
        unit.desired
      );
      col.y = convertedYAxisData.map((val) =>
        formatValue(val, { unit: convertedYAxisUnit, maxDecimals: 3 })
      );
    }
  }

  const rawTableRows = mergeToTableRows('x', data);
  const tableRows = rawTableRows.map((rawRow) => {
    const row: TableRow = {};
    for (const [key, val] of Object.entries(rawRow)) {
      row[key] = { value: val };
    }
    return row;
  });

  if (widget.axis.x.dimension === 'system.id' || widget.axis.x.dimension === 'chargepark.id') {
    if (widget.axis.x.dimension === 'system.id')
      for (const row of tableRows) {
        const id = String(row.x.value);
        const name = contextResolver.resolveEntityNameById(widget.axis.x.dimension, id) as string;
        row.x = { value: name, id };
      }
  }

  return { headers: columns, rows: tableRows };
}

export function mergeToTableRows(xKey: string, data: MergeInput[]): RawTableRow[] {
  const result: RawTableRow[] = [];

  const keys = data.map((d) => d.key);

  const xDataMap = new Map<string | number, Record<string | number, string | number>>();

  for (const series of data) {
    for (let i = 0; i < series.x.length; i++) {
      const x = series.x[i];
      const y = series.y[i];
      const point = xDataMap.get(x) || {};
      xDataMap.set(x, { ...point, [series.key]: y });
    }
  }

  for (const [x, y] of xDataMap.entries()) {
    const row: RawTableRow = {};
    row[xKey] = x;

    for (const key of keys) {
      row[key] = y[key] ?? null;
    }

    result.push(row);
  }

  result.sort((a, b) => (a[xKey]! > b[xKey]! ? 1 : -1));

  return result;
}
