<template>
  <div class="report-schedule-picker picker-table">
    <div class="mb-3">
      <slot name="header">
        <span class="block title font-medium"
          >{{
            reportModalData.report_type === INSTANT_REPORT
              ? $t('report.coveredTimespan.title')
              : $t('report.schedule.title')
          }}
          <span class="required">*</span>
        </span>
      </slot>
      <slot name="subheader">
        <span
          v-html="
            reportModalData.report_type === INSTANT_REPORT
              ? $t('report.coveredTimespan.description')
              : $t('report.schedule.description')
          "
          class="block text-grey text-sm mb-4"
        ></span>
      </slot>
    </div>
    <div class="flex flex-col gap-2">
      <ReportPeriodicSelectors
        v-if="reportModalData.report_type === PERIODIC_REPORT"
        v-bind="{ v$ }"
      />
      <DateTimeRangeInput
        v-else-if="reportModalData.report_type === INSTANT_REPORT"
        startedValueId="instant_report_started_at"
        endedValueId="instant_report_ended_at"
        :startedValueLocale="$t('report.startDate')"
        :endedValueLocale="$t('report.endDate')"
        startedAfterEndedErrorLocale="StartedAt should be smaller than EndedAt"
        v-model:startedValue="reportModalData.start_date"
        v-model:endedValue="reportModalData.end_date"
        :maxStartDateTime="maxStartDate"
        :minEndDateTime="minEndDate"
        :maxEndDateTime="maxEndDate"
        displayTimezoneOverride="UTC"
        noTime
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useReportsStore } from '@/stores/admin/reports/reports.store';
import { INSTANT_REPORT, PERIODIC_REPORT } from '@/utils/constants';

import ReportPeriodicSelectors from '@/components/reports/ReportPeriodicSelectors.vue';
import DateTimeRangeInput from '@/components/common/time/DateTimeRangeInput.vue';
import type { Validation } from '@vuelidate/core';
import type { reportInstantTypeRules, reportPeriodicTypeRules } from '@/utils/reportModalRules';
import dayjs from 'dayjs';

const reportsStore = useReportsStore();
const { reportModalData } = storeToRefs(reportsStore);

const maxEndDate = computed(() => dayjs().tz('UTC').subtract(2, 'd').startOf('day').toISOString());
const maxStartDate = computed(() =>
  reportModalData.value.end_date
    ? dayjs(reportModalData.value.end_date).toISOString()
    : dayjs().tz('UTC').subtract(2, 'd').startOf('day').toISOString()
);
const minEndDate = computed(() =>
  reportModalData.value.start_date
    ? dayjs(reportModalData.value.start_date).toISOString()
    : undefined
);

defineProps<{
  v$: Validation<typeof reportInstantTypeRules | typeof reportPeriodicTypeRules>;
}>();
</script>
