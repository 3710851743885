<template>
  <div>
    <PanelWithEdit
      v-if="!isLoading"
      :title="$t('chargePark.schedule')"
      :loading="isLoading"
      :isEditing="false"
      :showFooter="false"
      :editIsDisabled="!activeRoles"
      cancelBtn
      @onEdit="isEditing = true"
      @onClose="isEditing = false"
    >
      <div class="flex justify-between scheduler pb-2">
        <div class="pt-2 w-1/2">
          <span class="block font-medium">{{ $t('chargePark.rules') }}</span>
          <span class="text-sm text-[var(--gray-500)]">{{ $t('chargePark.rulesInfo') }}</span>
        </div>
        <div
          :class="`flex flex-col items-center w-1/2 ${
            rules ? 'border border-t-[var--gray-200] rounded my-4' : ''
          }`"
        >
          <div v-if="chargeParkDetails.access_times" class="w-full">
            <div
              v-for="(rule, index) in chargeParkDetails.access_times"
              :key="index"
              class="w-full"
            >
              <div
                :class="`flex justify-between items-center w-full px-2 pt-3 ${
                  index < chargeParkDetails.access_times.length - 1
                    ? 'border-b border-b-[var(--gray-200)]'
                    : ''
                }`"
              >
                <div class="flex items-center mb-3">
                  <span v-for="(dayId, dayIndex) in rule.days" :key="dayIndex" class="pr-1">
                    {{ getDateName(dayId) }}{{ dayIndex < rule.days.length - 1 ? ',' : '' }}
                  </span>
                  <span class="text-grey pl-4">{{ formatHourMinSpan(rule.time) }}</span>
                </div>
                <div class="flex items-center">
                  <Tag
                    class="access-tag mb-3"
                    severity="success"
                    :value="$t('chargePark.accessible')"
                  ></Tag>
                  <span v-if="isEditing" class="p-buttonset pl-2 mb-3">
                    <Button
                      icon="pi pi-pencil"
                      outlined
                      size="small"
                      class=""
                      @click="editRule(rule, index)"
                    />
                    <Button
                      icon="pi pi-times"
                      outlined
                      size="small"
                      class=""
                      @click="removeRule(rule, index)"
                    />
                  </span>
                </div>
              </div>
              <!-- Editing view  -->
              <div v-show="isEditing" class="flex flex-col w-full">
                <div
                  v-show="isRuleEditing && ruleEditingData.index === index"
                  class="border border-l-0 border-r-0 border-[var--gray-200]text-center"
                >
                  <DayAndTimeScheduler
                    @closeEditingRule="isRuleEditing = false"
                    @success="showSuccess"
                    @error="showError"
                    @onError="setErrorMessage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="isEditing"
            :class="`w-full ${
              !rules
                ? 'border border-[var--gray-200] rounded mt-2'
                : 'border-t border-t-[var--gray-200]'
            }`"
          >
            <Button
              :label="$t('chargePark.addRule')"
              icon="pi pi-plus"
              outlined
              size="small"
              class="w-full no-border flex justify-center"
              :disabled="isRuleEditing"
              @click="addRule"
            />
          </div>
        </div>
      </div>
    </PanelWithEdit>
    <Toast
      data-cy="charge-park-days-and-times-toast"
      position="top-center"
      errorIcon="pi pi-info-circle"
      group="chargeParkDaysAndTimes"
    >
      <template v-if="isFailed" #message="slotProps">
        <div class="flex flex-col w-full pt-1">
          <div class="w-full flex">
            <span class="p-toast-message-icon pi pi-info-circle"></span>
            <div class="p-toast-message-text">
              <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
            </div>
          </div>
          <span class="my-2 ml-8 font-medium">{{ verifyErrorMessage }}</span>
        </div>
      </template>
    </Toast>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { formatHourMinSpan } from '@/utils/dateTimeFormatVue';
import {
  ASSET_MANAGER_ROLE,
  ADMINISTRATOR_ROLE,
  DEFAULT_TOAST_LIFE_MILLISECONDS,
} from '@/utils/constants';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import Tag from 'primevue/tag';
import Button from 'primevue/button';
import DayAndTimeScheduler from '@/components/common/DayAndTimeScheduler.vue';
import Toast from 'primevue/toast';

const { t } = useI18n();
const toast = useToast();
const chargeParksStore = useChargeParksStore();
const systemsStore = useHardwareSystemsStore();
const userStore = useUsersStore();
const { chargeParkEditionData, daysOfWeek, ruleEditingData } = storeToRefs(chargeParksStore);

const chargeParkDetails = computed({
  get: () => ({ access_times: chargeParkEditionData.value.access_times }),
  set: (v) => ({ access_times: v }),
});

const isLoading = ref(true);
const isEditing = ref(false);
const isRuleEditing = ref(false);
const isFailed = ref(false);
const verifyErrorMessage = ref('');

const activeRoles = computed(
  () =>
    userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
    userStore.me.roles.map((role) => role.id).includes(ASSET_MANAGER_ROLE)
);

const rules = computed(() => {
  if (!chargeParkDetails.value.access_times || chargeParkDetails.value.access_times?.length === 0) {
    return false;
  } else {
    return true;
  }
});

const getDateName = (id: number) => {
  if (isLoading.value) {
    return;
  } else {
    return daysOfWeek.value.find((day) => day.id === id)?.name;
  }
};

const addRule = () => {
  const data = {
    days: [1, 2, 3, 4, 5, 6, 7],
    time: '00:00-23:00',
  };
  isRuleEditing.value = true;
  chargeParkDetails.value.access_times
    ? chargeParkDetails.value.access_times.push(data)
    : (chargeParkDetails.value.access_times = [data]);
  ruleEditingData.value = {
    ...data,
    index: chargeParkDetails.value.access_times!.length - 1,
  };
};

const editRule = (rule: any, index: number) => {
  ruleEditingData.value = {
    days: rule.days,
    time: rule.time,
    index: index,
  };
  isRuleEditing.value = true;
};

const removeRule = (rule: any, index: number) => {
  if (!chargeParkEditionData.value.access_times || !chargeParkDetails.value.access_times) {
    return;
  }

  chargeParkDetails.value.access_times?.splice(index, 1);

  const convertedData = { access_times: [...chargeParkDetails.value.access_times] };

  chargeParksStore
    .editChargeParkDetails(chargeParkEditionData.value.id, convertedData)
    .then((response: any) => {
      if (response.status === 200) {
        chargeParksStore.fetchChargePark(chargeParkEditionData.value.id);
        systemsStore.fetchSystemsByChargeParkId(chargeParkEditionData.value.id);
        toast.removeAllGroups();
        showSuccess();
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 400 && error.response.data.access_times) {
        verifyErrorMessage.value = error.response.data.access_times[0].error_details.error_message;
        toast.removeAllGroups();
        showError();
      } else {
        showError();
      }
      throw new Error(error);
    });
};

const setErrorMessage = (message: string) => {
  verifyErrorMessage.value = message;
  isFailed.value = true;
};

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('chargePark.title'),
      action: t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'chargeParkDaysAndTimes',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: t('toast.edition'),
      name: t('chargePark.title'),
    }),
    group: 'chargeParkDaysAndTimes',
  });
};

watch(
  () => chargeParkDetails.value.access_times,
  (value) => {
    chargeParkEditionData.value.access_times = value;
  },
  { deep: true }
);

onMounted(async () => {
  await chargeParksStore.fetchDaysList().then(() => (isLoading.value = false));
});
</script>
<style scoped lang="scss">
.scheduler {
  min-width: 573px;
}
</style>
