<template>
  <sortable-list
    :values="currentValue"
    :options="props.enumValues || []"
    @update:values="(val) => emit('update:value', val)"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import SortableList from '@/components/common/inputs/SortableList.vue';

const props = defineProps<{
  id: string;
  value: unknown;
  defaultValue: string[];
  enumValues?: { value: string; label: string }[];
}>();

function isValidPropValue(value: unknown): value is string[] {
  return Array.isArray(value) && value.every((v) => typeof v === 'string');
}

const currentValue = ref(isValidPropValue(props.value) ? props.value : props.defaultValue);

const emit = defineEmits<{
  (event: 'update:value', value: string[]): void;
}>();
</script>
