<template>
  <PanelWithEdit
    :title="$t('info')"
    :loading="isLoading"
    :isEditing="isEditing"
    :isNotValid="v$.$invalid"
    @onEdit="isEditing = true"
    @onClose="closeEditing"
    @onApply="apply"
  >
    <div class="flex justify-between">
      <div class="flex flex-col w-1/2">
        <div v-for="(_, key) in editableInfo" :key="key">
          <span class="block font-medium py-3">{{ $t(`user.preferences.${key}`) }}</span>
        </div>
      </div>

      <div v-if="isEditing" class="flex flex-col w-1/2 gap-1">
        <InputText
          type="text"
          :class="{ 'p-invalid': v$.name.$invalid }"
          v-model="editableInfo.name"
          class="p-column-filter p-2 w-full"
        />
        <InputText
          type="text"
          v-model="editableInfo.email"
          disabled
          class="p-column-filter p-2 w-full input-disabled"
        />
        <InputText
          type="text"
          v-model="editableInfo.organisation"
          disabled
          class="p-column-filter p-2 w-full input-disabled"
        />
      </div>
      <div v-else>
        <div class="flex flex-col w-full text-right">
          <div v-for="(value, key) in editableInfo" :key="key">
            <span
              class="block font-medium py-3"
              :class="[isEditable(key) ? 'text-gray-600' : 'text-gray-400']"
            >
              {{ value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </PanelWithEdit>
</template>

<script setup lang="ts">
import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';

import { onMounted, reactive, ref, watch } from 'vue';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { storeToRefs } from 'pinia';
import InputText from 'primevue/inputtext';
import { useOrganisationsStore } from '@/stores/admin/organizations/organisations.store';
import { useVuelidate } from '@vuelidate/core';
import { infoRules } from '@/utils/userPreferencesRules';
import { UserPreferencesEditableFields } from '@/models/userPreferences.model';

import type { UserPreferencesInfoFields } from '@/models/userPreferences.model';
import { updateUserSettings } from '@/stores/admin/users/users.api';

const usersStore = useUsersStore();
const organisationStore = useOrganisationsStore();
const { me } = storeToRefs(usersStore);

const editableInfo: Record<UserPreferencesInfoFields, string | null> = reactive({
  name: me.value.name,
  email: me.value.email,
  organisation: null,
});

const v$ = useVuelidate(infoRules, editableInfo);

const isLoading = ref(false);
const isEditing = ref(false);

const isEditable = (field: UserPreferencesInfoFields) => {
  return UserPreferencesEditableFields.includes(field);
};

const closeEditing = () => {
  v$.value.$reset();
  editableInfo.name = me.value.name;
  isEditing.value = false;
};

async function apply() {
  isLoading.value = true;
  if (editableInfo.name === null) return;
  try {
    await updateUserSettings({ name: editableInfo.name });
    await usersStore.fetchUserData(true);
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
    isEditing.value = false;
  }
}
watch(
  () => me.value.organisation_id,
  async (id) => {
    const organisation = await organisationStore.fetchOrganisationById(id);

    if (organisation?.data?.name) {
      editableInfo.organisation = organisation.data.name;
    }
  },
  { immediate: true }
);

watch(
  () => editableInfo.name,
  () => {
    v$.value.name.$touch();
  }
);

onMounted(async () => {
  await usersStore.fetchUserData();
  editableInfo.name = usersStore.me.name;
});
</script>
