<template>
  <PanelWithEdit
    :title="$t('preferences')"
    :loading="isLoading"
    :isEditing="isEditing"
    :isNotValid="v$.$invalid"
    @onEdit="isEditing = true"
    @onClose="closeEditing"
    @onApply="apply"
  >
    <div class="flex justify-between">
      <div class="flex flex-col w-1/2">
        <div v-for="(_, key) in editableInfo" :key="key">
          <span class="block font-medium py-3">{{ $t(`user.preferences.${key}`) }}</span>
        </div>
      </div>

      <div v-if="isEditing" class="flex flex-col w-1/2 gap-1">
        <Dropdown
          v-model="editableInfo.timezone"
          :class="{ 'p-invalid': v$.timezone.$invalid && v$.timezone.$dirty }"
          :options="timezoneDropdownOptions"
          :placeholder="$t('select')"
          optionLabel="name"
          optionValue="value"
          scrollHeight="240px"
          filter
        />
        <Dropdown
          v-model="editableInfo.unit"
          :class="{ 'p-invalid': v$.unit.$invalid && v$.unit.$dirty }"
          :options="unitDropdownOptions"
          :placeholder="$t('select')"
          optionLabel="name"
          optionValue="value"
          scrollHeight="240px"
        />
        <Dropdown
          v-model="editableInfo.timeFormat"
          :class="{ 'p-invalid': v$.timeFormat.$invalid && v$.timeFormat.$dirty }"
          :options="timeFormatDropdownOptions"
          :placeholder="$t('select')"
          optionLabel="name"
          optionValue="value"
          scrollHeight="240px"
        />
      </div>
      <div v-else>
        <div class="flex flex-col w-full text-right">
          <span class="block font-medium py-3 text-gray-600">
            {{ formattedTimezone }}
          </span>
          <span class="block font-medium py-3 text-gray-600">
            {{
              unitDropdownOptions.find((opt) => opt.value === editableInfo.unit)?.name ||
              editableInfo.unit
            }}
          </span>
          <span class="block font-medium py-3 text-gray-600">
            {{
              timeFormatDropdownOptions.find((opt) => opt.value === editableInfo.timeFormat)
                ?.name || editableInfo.timeFormat
            }}
          </span>
        </div>
      </div>
    </div>
  </PanelWithEdit>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import Dropdown from 'primevue/dropdown';
import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import { useVuelidate } from '@vuelidate/core';
import { preferencesRules } from '@/utils/userPreferencesRules';
import { timezoneDropdownOptions } from '@/utils/timezones';
import { UnitSystem, TimeSystem } from '@/models/datatypes.model';

import { updateUserSettings } from '@/stores/admin/users/users.api';
import { useUsersStore } from '@/stores/admin/users/users.store';

const usersStore = useUsersStore();

const editableInfo = reactive({
  timezone: null as string | null,
  unit: null as UnitSystem | null,
  timeFormat: null as TimeSystem | null,
});

const v$ = useVuelidate(preferencesRules, editableInfo);

const isLoading = ref(false);
const isEditing = ref(false);

const formattedTimezone = computed(() => {
  if (editableInfo.timezone === null) return '--';
  return editableInfo.timezone.replace('Etc/UTC', 'UTC/GMT+0').replace(/_/g, ' ');
});

const closeEditing = () => {
  v$.value.$reset();
  isEditing.value = false;
};

async function apply() {
  isLoading.value = true;
  if (editableInfo.timezone === null || editableInfo.unit === null) return;
  try {
    await updateUserSettings({
      local_timezone: editableInfo.timezone,
      units: editableInfo.unit,
      // shomehow I get type error here although it is simlar to units - thus the hotfix
      time_format: editableInfo.timeFormat ? editableInfo.timeFormat : undefined,
    });
    await usersStore.fetchUserData(true);
  } catch (e) {
    console.error(e);
  } finally {
    isLoading.value = false;
    isEditing.value = false;
  }
}

const unitDropdownOptions = [
  { name: 'Metric', value: UnitSystem.METRIC },
  { name: 'Imperial', value: UnitSystem.IMPERIAL },
];

const timeFormatDropdownOptions = [
  { name: '12h (am, pm)', value: TimeSystem.TIME_12H },
  { name: '24h', value: TimeSystem.TIME_24H },
];

watch(
  () => editableInfo.timezone,
  () => {
    v$.value.timezone.$touch();
  }
);

watch(
  () => editableInfo.unit,
  () => {
    v$.value.unit.$touch();
  }
);

watch(
  () => editableInfo.timeFormat,
  () => {
    v$.value.unit.$touch();
  }
);

onMounted(async () => {
  await usersStore.fetchUserData();

  editableInfo.timezone = usersStore.me.local_timezone;
  editableInfo.unit = usersStore.me.units;
  editableInfo.timeFormat = usersStore.me.time_format;
});
</script>
