import axios from 'axios';
import type { AxiosPromise, AxiosResponse } from 'axios';

export function fetchGeoLocation(address: string): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/geo-location/`, {
      address: address,
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}
