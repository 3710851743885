<template>
  <GeneralSummary
    show-name
    :show-author="false"
    :name="reportModalData.name"
    :template="selectedTemplate"
  />

  <ChargeParkPicker
    assets-column
    class="pt-4"
    owner-label="organisation"
    select-mode="multiple"
    :value="reportsStore.reportModalData.charge_parks"
    :compatible-systems="selectedTemplate?.supportedProductTypes"
    :show-location="false"
    @on-charge-park-select="setSelectedChargePark"
    @on-charge-park-unselect="unselectChargePark"
    @on-charge-park-select-all="selectAllChargeParks"
    @on-charge-park-unselect-all="unselectAllChargeParks"
  >
    <template #header>
      <p class="block title font-medium field-edit-label">
        {{ $t('report.site.title') }}<span class="required">*</span>
      </p>
    </template>
    <template #subheader>
      <p class="block text-grey text-sm">{{ $t('report.site.description') }}</p>
    </template>
  </ChargeParkPicker>

  <div v-if="v$.charge_parks.$errors.length" class="px-12 -mt-4 mb-4">
    <span
      v-for="(error, index) in v$.charge_parks.$errors"
      :key="index"
      class="text-red-500 text-sm"
    >
      {{ error?.$message }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import GeneralSummary from '@/components/alertsAndReportsShared/summary/GeneralSummary.vue';
import ChargeParkPicker from '@/components/chargeParks/ChargeParkPicker.vue';
import { useReportsStore } from '@/stores/admin/reports/reports.store';

import type { reportInstantTypeRules, reportPeriodicTypeRules } from '@/utils/reportModalRules';
import type { ChargePark } from '@/models/chargeParks.model';
import type { Validation } from '@vuelidate/core';

const reportsStore = useReportsStore();
const { selectedTemplate, reportModalData } = storeToRefs(reportsStore);

const props = defineProps<{
  v$: Validation<typeof reportInstantTypeRules | typeof reportPeriodicTypeRules>;
}>();

const setSelectedChargePark = (chargePark: ChargePark) => {
  reportModalData.value.charge_parks.push(chargePark.id);
};

const unselectChargePark = (chargePark: ChargePark) => {
  reportModalData.value.charge_parks = reportModalData.value.charge_parks.filter(
    (id: number) => id !== chargePark.id
  );
};

const selectAllChargeParks = (chargeParks: { id: number }[]) => {
  reportModalData.value.charge_parks = chargeParks.map((chargePark) => chargePark.id);
};

const unselectAllChargeParks = () => {
  reportModalData.value.charge_parks = [];
};

watch(
  () => reportModalData.value.charge_parks,
  () => {
    props.v$.charge_parks.$touch();
  },
  { deep: true }
);
</script>
