import { required, helpers, maxLength } from '@vuelidate/validators';
import { asciiCharacters } from '@/utils/validationRules';
import { MAX_LENGTH } from '@/utils/constants';
import i18n from '@/i18n';

export const hardwareSystemInfoRules = {
  name: {
    asciiCharacters: helpers.withMessage(
      i18n.global.t('rules.asciiCharacters', {
        field: i18n.global.t('name'),
      }),
      asciiCharacters
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
  custom_id: {
    asciiCharacters: helpers.withMessage(
      i18n.global.t('rules.asciiCharacters', {
        field: i18n.global.t('chargePark.chargeParkDetails.custom_id'),
      }),
      asciiCharacters
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
  /* service_started_at and service_ended_at are validated inside DateTimeRangeInput */
};

export const hardwareSystemLocationRules = {
  country_code: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('country.title').toLowerCase(),
        item: i18n.global.t('hardwareSystem.title').toLowerCase(),
      }),
      required
    ),
  },
  address: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('address').toLowerCase(),
        item: i18n.global.t('hardwareSystem.title').toLowerCase(),
      }),
      required
    ),
  },
};
