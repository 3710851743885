<template>
  <div>
    <div class="border-b py-2 px-12 text-sm">
      <ReadOnlyItem :name="$t('name')" :value="playlistModalData.name" />
    </div>

    <ChargeParkPicker
      class="pt-4"
      owner-label="organisation"
      select-mode="multiple"
      :value="playlistsStore.playlistModalData.charge_parks"
      :show-location="false"
      playlistColumn
      @on-charge-park-select="setSelectedChargePark"
      @on-charge-park-unselect="unselectChargePark"
      @on-charge-park-select-all="selectAllChargeParks"
      @on-charge-park-unselect-all="unselectAllChargeParks"
    >
      <template #header>
        <p class="block title font-medium field-edit-label">
          {{ $t('playlists.sites') }}<span class="required">*</span>
        </p>
      </template>
      <template #subheader>
        <p class="block text-grey text-sm">{{ $t('playlists.siteDescription') }}</p>
      </template>
    </ChargeParkPicker>

    <div v-if="v$.charge_parks.$errors.length" class="px-12 -mt-4 mb-4">
      <span
        v-for="(error, index) in v$.charge_parks.$errors"
        :key="index"
        class="text-red-500 text-sm"
      >
        {{ error?.$message }}
      </span>
    </div>

    <div class="px-12 mt-3 mb-6">
      <warning-bar
        v-if="chargeParksWithPlaylist.length > 0"
        title="Playlist Conflict"
        :message="
          t('warnings.sites.resourceConflict', {
            sites: chargeParksWithPlaylist.join(', '),
            resource: 'playlist',
            count: chargeParksWithPlaylist.length,
          })
        "
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { usePlaylistsStore } from '@/stores/admin/playlists/playlists.store';
import { storeToRefs } from 'pinia';

import ChargeParkPicker from '@/components/chargeParks/ChargeParkPicker.vue';
import type { ChargePark } from '@/models/chargeParks.model';
import WarningBar from '@/components/common/bar/WarningBar.vue';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import ReadOnlyItem from '@/components/common/readOnly/ReadOnlyItem.vue';

import type { playlistRules } from '@/utils/playlistModalRules';
import type { Validation } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';

const playlistsStore = usePlaylistsStore();
const { playlistModalData, playlistId } = storeToRefs(playlistsStore);
const chargeParksStore = useChargeParksStore();
const { chargeParks } = storeToRefs(chargeParksStore);
const { t } = useI18n();

const props = defineProps<{
  v$: Validation<typeof playlistRules>;
}>();

const chargeParksWithPlaylist = computed(() => {
  return chargeParks.value
    .filter((chargePark) => chargePark.playlist)
    .filter(
      (item) =>
        playlistModalData.value.charge_parks.includes(item.id) &&
        item.playlist?.id !== playlistId.value
    )
    .map((chargePark) => chargePark.name);
});

const setSelectedChargePark = (chargePark: ChargePark) => {
  playlistModalData.value.charge_parks.push(chargePark.id);
};

const unselectChargePark = (chargePark: ChargePark) => {
  playlistModalData.value.charge_parks = playlistModalData.value.charge_parks.filter(
    (id: number) => id !== chargePark.id
  );
};

const selectAllChargeParks = (chargeParks: { id: number }[]) => {
  playlistModalData.value.charge_parks = chargeParks.map((chargePark) => chargePark.id);
};

const unselectAllChargeParks = () => {
  playlistModalData.value.charge_parks = [];
};

watch(
  () => playlistModalData.value.charge_parks,
  () => {
    props.v$.charge_parks.$touch();
  },
  { deep: true }
);
</script>
