<template>
  <div class="picker-table mt-2 pt-4 px-12">
    <span class="block title font-medium mb-3">{{ $t('organisation.title') }}</span>
    <div class="container">
      <DataTable
        :value="sortedOrganisations"
        dataKey="id"
        :scrollable="true"
        scrollHeight="280px"
        class="p-datatable-sm organisation-picker-table picker-table"
        v-model:filters="filters"
        filterDisplay="menu"
        :loading="loading"
        :globalFilterFields="['name', 'parent_organisation_name']"
        v-model:selection="selectedOrganisation"
        @rowSelect="onSelectedOrganisation"
      >
        <template #empty>{{ $t('organisation.empty') }}</template>
        <template #loading>{{ $t('organisation.loading') }}</template>
        <template #header>
          <div class="flex justify-between align-items-center">
            <span class="p-input-icon-left search">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="
                  $t('searchBy', {
                    name: `${$t('organisation.title')}, ${$t('organisation.parent')}`,
                  })
                "
                class="p-inputtext-sm"
              />
            </span>
          </div>
        </template>
        <Column
          selectionMode="single"
          style="flex: 0 0 0; padding: 0"
          bodyStyle="padding-left: 16px"
        ></Column>
        <Column field="name" :header="$t('name')">
          <template #body="{ data }">
            <div class="w-full pl-1">
              <span :id="data.id" class="text-sm font-medium" data-cy="system-organisation-name">{{
                data.name
              }}</span>
              <span
                v-if="data.id === systemEditData.owner_organisation_id"
                class="font-medium text-grey block text-xs"
                data-cy="system-organisation-owner"
                >{{ $t('organisation.currentOwner') }}</span
              >
            </div>
          </template>
        </Column>
        <Column field="parent_organisation_name" :header="$t('organisation.parent')">
          <template #body="{ data }">
            <div data-cy="organisation-parent" class="flex justify-start">
              <span
                v-if="data.parent_organisation_name"
                data-cy="system-parent-organisation-name"
                class="text-small"
                >{{ data.parent_organisation_name }}</span
              >
              <span v-else class="empty text-grey">--</span>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { FilterMatchMode } from 'primevue/api';
import { useOrganisationsStore } from '@/stores/admin/organizations/organisations.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import type { Organisation } from '@/models/organisation.model';

export default {
  name: 'OrganisationPicker',
  components: {
    DataTable,
    Column,
    InputText,
    // eslint-disable-next-line vue/no-unused-components
    RadioButton,
  },

  setup(props, { emit }) {
    const organisationsStore = useOrganisationsStore();
    const { organisations } = storeToRefs(organisationsStore);
    const hardwareSystemsStore = useHardwareSystemsStore();
    const { systemEditData, hardwareSystemOrganisation } = storeToRefs(hardwareSystemsStore);
    const loading = ref(false);
    const systemOrganisation = computed(() => hardwareSystemsStore.hardwareSystemOrganisation);
    const selectedOrganisation = ref<Organisation | null>(null);
    const sortedOrganisations = computed(() => {
      const orgList = [...organisations.value];
      const currentOwnerIndex = organisations.value.findIndex(
        (item) => item.id === systemEditData.value.owner_organisation_id
      );
      const removedCurrentOwner = orgList.splice(currentOwnerIndex, 1);
      orgList.unshift(...removedCurrentOwner);

      return orgList;
    });

    const onSelectedOrganisation = (event: any) => {
      const organisationData = {
        id: event.data.id,
        name: event.data.name,
      };
      emit('onOrganisationChange', organisationData);
    };

    const matchModeOptions = ref([{ label: 'Starts With', value: FilterMatchMode.STARTS_WITH }]);

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    watch(
      () => hardwareSystemOrganisation.value,
      (value) => {
        if (value) {
          selectedOrganisation.value = value;
        }
      }
    );

    onMounted(async () => {
      await organisationsStore.fetchOrganisationsList();
    });

    return {
      organisations,
      filters,
      matchModeOptions,
      loading,
      selectedOrganisation,
      onSelectedOrganisation,
      systemEditData,
      systemOrganisation,
      sortedOrganisations,
    };
  },
};
</script>

<style lang="scss" scoped></style>
