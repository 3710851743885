<template>
  <Dialog
    v-model:visible="downtimeCreateModalIsOpen"
    data-cy="downtime-create-edit-modal"
    modal
    class="downtime-create-edit-modal bg-gray-100"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '820px' }"
    @hide="closeModal"
  >
    <template #header>
      <div class="relative w-full text-center">
        <Button
          v-if="!isFirst"
          :label="$t('back')"
          icon="pi pi-angle-left"
          class="!absolute p-button-outlined !bg-white p-button-plain p-button-sm mr-2 left-0 top-1/2 transform -translate-y-1/2"
          @click="goToPrevious"
        />

        <p class="font-semibold">
          {{ modalTitle }}
        </p>
      </div>
    </template>

    <ModalStepper :active-step="current" v-bind="{ steps, isEditMode }" />

    <div class="border-t">
      <component :is="downtimeStepsComponents[current]" v-bind="{ v$ }" />
    </div>

    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        data-cy="downtime-create-edit-cancel-button"
        @click="closeModal"
      />
      <Button
        v-if="!isLast"
        :disabled="!isStepValid"
        :label="t('next')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        data-cy="downtime-create-edit-next-button"
        @click="goToNext"
      />
      <Button
        v-else
        :label="t('apply')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        data-cy="downtime-create-edit-submit-button"
        :loading="loading"
        @click="submitForm"
      />
    </template>
  </Dialog>

  <Toast
    data-cy="downtime-create-edit-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="downtimes"
    class="downtime-toast"
  />
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { useDowntimesStore } from '@/stores/admin/downtimes/downtimes.store';
import { DowntimeStep } from '@/models/downtimes.model';
import { useStepper } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';
import { CREATE, DEFAULT_TOAST_LIFE_MILLISECONDS, EDIT } from '@/utils/constants';
import { downtimeRules } from '@/utils/downtimeModalRules';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ModalStepper from '@/components/common/steppers/ModalStepper.vue';
import DowntimeModalConfigurationStep from '@/components/downtimes/steps/DowntimeModalConfigurationStep.vue';
import DowntimeModalAssetsStep from '@/components/downtimes/steps/DowntimeModalAssetsStep.vue';
import DowntimeModalPreviewStep from '@/components/downtimes/steps/DowntimeModalPreviewStep.vue';

const downtimesStore = useDowntimesStore();
const { t } = useI18n();
const toast = useToast();
const { downtimeCreateModalIsOpen, isEditMode, downtimeEditId, downtimeModalData } =
  storeToRefs(downtimesStore);

const v$ = useVuelidate(downtimeRules, downtimeModalData);
const loading = ref(false);

const modalTitle = computed(() => {
  return isEditMode.value
    ? t('edit', { itemName: t('downtimes.downtime') })
    : t('create', { itemName: t('downtimes.downtime') });
});

const closeModal = () => {
  downtimeCreateModalIsOpen.value = false;
  resetForm();

  if (isEditMode.value) {
    downtimeEditId.value = null;
  }
};

const downtimeStepsComponents: Record<DowntimeStep, unknown> = {
  [DowntimeStep.Configuration]: DowntimeModalConfigurationStep,
  [DowntimeStep.Assets]: DowntimeModalAssetsStep,
  [DowntimeStep.Preview]: DowntimeModalPreviewStep,
};

const createPath = [DowntimeStep.Configuration, DowntimeStep.Assets, DowntimeStep.Preview];
const editPath = [DowntimeStep.Configuration, DowntimeStep.Assets, DowntimeStep.Preview];
const currentPath = computed(() => (isEditMode.value ? editPath : createPath));

const { steps, current, goTo, goToNext, goToPrevious, isFirst, isLast } = useStepper(currentPath);

const resetForm = () => {
  downtimesStore.resetDowntimeModalData();
  v$.value.$reset();
  goTo(steps.value[0]);
};

const showSuccess = (actionName: string) => {
  toast.removeGroup('downtimes');
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('downtimes.downtime'),
      action: actionName === CREATE ? t('toast.created') : t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'downtimes',
  });
};

const showError = (actionName: string) => {
  toast.removeGroup('downtimes');
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: actionName === CREATE ? t('toast.creation') : t('toast.edition'),
      name: t('downtimes.downtime'),
    }),
    group: 'downtimes',
  });
};

const isStepValid = computed(() => {
  const stepValidationRules: Record<DowntimeStep, boolean> = {
    [DowntimeStep.Configuration]:
      v$.value.reason.$dirty &&
      !v$.value.reason.$invalid &&
      !v$.value.start_time.$invalid &&
      !v$.value.end_time.$invalid,
    [DowntimeStep.Assets]: v$.value.systems.$dirty && !v$.value.systems.$invalid,
    [DowntimeStep.Preview]: true,
  };

  return stepValidationRules[current.value];
});

async function submitForm() {
  const action = isEditMode.value ? EDIT : CREATE;
  loading.value = true;

  try {
    isEditMode.value
      ? await downtimesStore.editDowntime()
      : await downtimesStore.createNewDowntime();
    showSuccess(action);
    loading.value = false;
    closeModal();
    resetForm();
    downtimesStore.resetDowntimeModalData();
  } catch (err: unknown) {
    loading.value = false;
    showError(action);
    throw new Error(err as string);
  }

  await downtimesStore.fetchDowntimeList();
}

watch(
  () => isEditMode.value,
  (isActive) => {
    !isActive ? v$.value.$reset() : v$.value.$touch();
  }
);
</script>
