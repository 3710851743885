import { defineStore } from 'pinia';
import {
  fetchPlaylists,
  deletePlaylist,
  createNewPlaylist,
  createNewPlaylistMedia,
  updatePlaylist,
  updatePlaylistMedia,
  fetchPlaylistMedia,
  fetchPlaylist,
  deletePlaylistMedia,
} from '@/stores/admin/playlists/playlists.api';
import { fetchMediaScaling } from '@/stores/admin/playlists/playlists.api';

import type {
  PlaylistEditData,
  PlaylistEditMediaData,
  Playlist,
  MediaItem,
  PlaylistMediaCreationData,
} from '@/models/playlists.model';
import type { IdName } from '@/models/common.model';

interface MediaState {
  playlists: Playlist[];
  isPlaylistModalOpen: boolean;
  playlistDeleteModalIsOpen: boolean;
  playlistDeleteData: {
    id: number;
  };
  isEditMode: boolean;
  playlistId: number | null;
  playlistModalData: PlaylistEditData;
  scalingOptions: IdName[];
  durationIsValid: boolean;
  playlistEditMediaData: PlaylistEditMediaData[];
  mediaData: MediaItem;
  mediaDeleteIds: number[];
  mediaToEditIds: number[];
}

export const usePlaylistsStore = defineStore('playlists', {
  state: (): MediaState => ({
    playlists: [],
    isPlaylistModalOpen: false,
    playlistDeleteModalIsOpen: false,
    playlistDeleteData: { id: 0 },
    isEditMode: false,
    playlistId: null,
    playlistModalData: {
      name: '',
      charge_parks: [],
      media: [],
      owner_organisation_name: '',
    },
    scalingOptions: [],
    durationIsValid: false,
    playlistEditMediaData: [],
    mediaData: {
      id: 0,
      name: '',
      type: '',
      status: ''
    },
    mediaDeleteIds: [],
    mediaToEditIds: [],
  }),

  getters: {
    repeatOptions(): IdName[] {
      return Array.from(Array(10).keys()).map((i) => ({
        id: i + 1,
        name: `${i + 1}x`,
      }));
    },
  },

  actions: {
    async fetchMediaScalingOptions() {
      try {
        await fetchMediaScaling().then((response) => {
          this.scalingOptions = response.data;
          return response;
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async fetchAllPlaylists() {
      return await fetchPlaylists()
        .then((response) => {
          this.playlists = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchSinglePlaylist(id: number) {
      return await await fetchPlaylist(id)
        .then((response) => {
          this.playlistModalData.charge_parks = response.data.charge_parks.map(
            (item: IdName) => item.id
          );
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchSinglePlaylistMedia(id: number) {
      return await fetchPlaylistMedia(id)
        .then((response) => {
          this.playlistEditMediaData = response.data;
          response.data.forEach((item: any) => {
            const {
              id: id,
              media: { id: media },
              playlist: { id: playlist },
              ...rest
            } = item;
            this.playlistModalData.media.push({ id, media, playlist, ...rest });
          });
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async createPlaylist() {
      const { name, charge_parks } = this.playlistModalData;

      try {
        return await createNewPlaylist({ name, charge_parks }).then((response) => {
          return this.createPlaylistMedia(response.data.id);
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async createPlaylistMedia(mediaId: number) {
      for (const mediaItem of this.playlistModalData.media) {
        mediaItem.playlist = mediaId;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id, ...rest } = mediaItem;
        await createNewPlaylistMedia(rest).then((response) => response);
      }
    },

    async addNewMedia(data: PlaylistMediaCreationData) {
      try {
        return await createNewPlaylistMedia(data).then((response) => {
          this.playlistModalData.media.find((mediaItem) => {
            mediaItem.id = response.data.id;
            mediaItem.playlist = this.playlistId;
            return response;
          });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async updateMedia() {
      if (this.mediaToEditIds.length === 0) {
        return;
      }
      await Promise.all(
        this.playlistModalData.media.map(async (mediaItem) => {
          if (this.mediaToEditIds.includes(mediaItem.id)) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...rest } = mediaItem;
            return await updatePlaylistMedia(mediaItem.id, rest).then((response) => response);
          }
        })
      );
    },

    async deleteMedia() {
      if (this.mediaDeleteIds.length === 0) {
        return;
      }
      await Promise.all(
        this.mediaDeleteIds.map(
          async (id) => await deletePlaylistMedia(id).then((response) => response)
        )
      );
    },

    async editPlaylist() {
      const { name, charge_parks } = this.playlistModalData;

      try {
        if (this.playlistId === null) {
          return Promise.reject('No playlist id to edit');
        }

        this.playlistModalData.media.map(async (mediaItem) => {
          const newMediaExists = this.playlistEditMediaData.find(
            (item) => item.id === mediaItem.id
          );

          if (!newMediaExists) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, ...rest } = mediaItem;
            rest.playlist = this.playlistId;
            await this.addNewMedia(rest);
          }
        });

        await this.updateMedia();
        await this.deleteMedia();

        await updatePlaylist(this.playlistId, { name, charge_parks });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async editPlaylistMedia() {
      try {
        this.playlistModalData.media.forEach((mediaItem) => {
          const { id, ...rest } = mediaItem;

          return updatePlaylistMedia(id, rest).then((response) => response);
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async removePlaylist(id: number) {
      return await deletePlaylist(id)
        .then((response: any) => {
          this.fetchAllPlaylists();
          return response;
        })
        .catch((error: unknown) => {
          return Promise.reject(error);
        });
    },

    resetPlaylistModalData() {
      this.playlistModalData.name = '';
      this.playlistModalData.media = [];
      this.playlistModalData.charge_parks = [];
      this.playlistModalData.owner_organisation_name = '';
    },
  },
});
