import i18n from '@/i18n';
import dayjs from 'dayjs';

export const timeRange = [
  {
    id: 1,
    name: i18n.global.t('last24h'),
    range: {
      startTime: dayjs().subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
      currentTime: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
    },
  },
  {
    id: 2,
    name: i18n.global.t('lastWeek'),
    range: {
      startTime: dayjs().subtract(1, 'week').format('YYYY-MM-DDTHH:mm:ss[Z]'),
      currentTime: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
    },
  },
  {
    id: 3,
    name: i18n.global.t('lastMonth'),
    range: {
      startTime: dayjs().subtract(1, 'month').format('YYYY-MM-DDTHH:mm:ss[Z]'),
      currentTime: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
    },
  },
  {
    id: 4,
    name: i18n.global.t('last3months'),
    range: {
      startTime: dayjs().subtract(3, 'month').format('YYYY-MM-DDTHH:mm:ss[Z]'),
      currentTime: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
    },
  },
  {
    id: 5,
    name: i18n.global.t('lastYear'),
    range: {
      startTime: dayjs().subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ss[Z]'),
      currentTime: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
    },
  },
];
