<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="rfid-tags-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}</Message
  >
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="rfid-tags-table"
    :value="tags"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    filterDisplay="menu"
    v-model:selection="selectedTags"
    :globalFilterFields="['rfid_tag', 'username']"
    dataKey="id"
    class="p-datatable-sm mb-5 mt-2 border-rounded overflow-hidden"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="$t('searchBy', { name: `${$t('name')}, ${$t('rfidTag.id')}` })"
            class="p-inputtext-sm"
          />
        </span>
        <div v-if="activeRoles" class="flex justify-end items-center">
          <Button
            data-cy="open-batch-actions-menu"
            :label="$t('actions')"
            @click="toggleActions"
            icon="pi pi-chevron-down"
            iconPos="right"
            aria-haspopup="true"
            aria-controls="actions_menu"
            class="p-button-outlined p-button-plain p-button-sm mr-3 actions-menu-button"
          />
          <Menu
            data-cy="batch-actions-menu"
            id="actions_menu"
            ref="actionsMenu"
            :model="actionMenuItems"
            :popup="true"
            class="mt-1"
          />
          <Button
            data-cy="open-create-rfid-tag-modal"
            :label="$t('create', { itemName: $t('rfidTag.title') })"
            icon="pi pi-plus"
            class="p-button-sm p-button-primary"
            @click="openCreateRfidTagModal"
          />
        </div>
      </div>
    </template>
    <template #empty>
      <span data-cy="rfid-tags-table-empty" class="w-full block text-center">{{
        $t('rfidTag.empty')
      }}</span>
    </template>
    <Column class="no-top-border-row" selectionMode="multiple" headerStyle="width: 3rem"></Column>
    <Column
      class="no-top-border-row"
      field="rfid_tag"
      :header="$t('rfidTag.title')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 12rem"
    >
      <template #body="{ data }">
        <span :id="data.id" data-cy="rfid-tag-tag">{{ data.rfid_tag }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('rfidTag.title') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="username"
      :header="$t('name')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 15rem"
    >
      <template #body="{ data }">
        <span v-if="data.username" data-cy="rfid-tag-user-name">{{ data.username }}</span>
        <span v-else class="empty">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="state"
      :header="$t('status')"
      :sortable="true"
      :showFilterMatchModes="false"
      style="min-width: 8rem"
    >
      <template #body="{ data }">
        <Tag
          data-cy="rfid-tag-status"
          class="mr-2"
          :severity="`${data.state ? 'success' : 'info'}`"
          :value="`${data.state ? 'Enabled' : 'Disabled'}`"
          rounded
        ></Tag>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="statuses"
          :placeholder="$t('select', { name: $t('status') })"
          class="p-column-filter mb-2"
          :showClear="true"
        >
        </Dropdown>
      </template>
    </Column>
    <Column
      v-if="activeRoles"
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }">
        <div class="flex">
          <Button
            data-cy="open-edit-rfid-tag-modal"
            :label="$t('edit')"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openEditRfidTagModal(data)"
          />
          <Button
            data-cy="open-delete-rfid-tag-modal"
            :label="$t('delete')"
            icon="pi pi-trash"
            class="p-button-outlined p-button-plain p-button-sm"
            @click="openDeleteRfidTagModal(data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <RfidTagEditModal />
  <RfidTagDeleteModal />
  <Toast
    data-cy="rfid-tag-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="tagsActions"
  />
</template>

<script lang="ts">
import { onMounted, ref, watch, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useRfidTagsStore } from '@/stores/admin/rfidTags/rfidTags.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import {
  ENABLE,
  DISABLE,
  WHITELIST_ADMIN_ROLE,
  ADMINISTRATOR_ROLE,
  DEFAULT_TOAST_LIFE_MILLISECONDS,
} from '@/utils/constants';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import RfidTagEditModal from './RfidTagEditModal.vue';
import RfidTagDeleteModal from './RfidTagDeleteModal.vue';
import Toast from 'primevue/toast';

import type { RfidTag } from '@/models/rfidTags.model';

export default {
  name: 'RfidTagsTable',

  components: {
    DataTable,
    Column,
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    InputText,
    Dropdown,
    Tag,
    // eslint-disable-next-line vue/no-reserved-component-names
    Menu,
    RfidTagEditModal,
    RfidTagDeleteModal,
    Toast,
    Message,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const rfidTagsStore = useRfidTagsStore();
    const {
      tags,
      rfidTagModalIsOpen,
      rfidTagEditionData,
      rfidTagIsEditing,
      rfidTagDeleteModalIsOpen,
      rfidTagDeletionIds,
    } = storeToRefs(rfidTagsStore);
    const userStore = useUsersStore();
    const loading = ref(true);
    const IS_ENABLED = ref('YOUR FILTER');
    const statuses = ref(['Enabled', 'Disabled']);
    const actionsMenu = ref();
    const selectedTags = ref([]);
    const notAuthorized = ref(false);

    const activeRoles = computed(
      () =>
        userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
        userStore.me.roles.map((role) => role.id).includes(WHITELIST_ADMIN_ROLE)
    );

    const actionMenuItems = ref([
      {
        label: t('batchActions.enable'),
        icon: 'pi pi-chevron-circle-down',
        command: () => {
          enableSelected();
        },
      },
      {
        label: t('batchActions.disable'),
        icon: 'pi pi-ban',
        command: () => {
          disableSelected();
        },
      },
      {
        label: t('batchActions.delete'),
        icon: 'pi pi-trash',
        command: () => {
          deleteSelected();
        },
      },
    ]);

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      rfid_tag: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      username: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      state: { value: null, matchMode: IS_ENABLED.value },
    });

    const matchModes = ref([
      { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
      { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
      { label: 'Contains', value: FilterMatchMode.CONTAINS },
    ]);

    FilterService.register(IS_ENABLED.value, (value, filter): boolean => {
      const convertedValue = value ? 'Enabled' : 'Disabled';

      if (filter === undefined || filter === null) {
        return true;
      }

      return convertedValue === filter;
    });

    const toggleActions = (event: any) => {
      actionsMenu.value.toggle(event);
    };

    const setActionInfo = (actionName: string) => {
      if (actionName === ENABLE) {
        return t('toast.enabled', { name: t('rfidTags') });
      } else if (actionName === DISABLE) {
        return t('toast.disabled', { name: t('rfidTags') });
      } else {
        return t('toast.deletedBatch');
      }
    };

    const showSuccess = (actionName: string) => {
      toast.add({
        severity: 'success',
        summary: setActionInfo(actionName),
        life: DEFAULT_TOAST_LIFE_MILLISECONDS,
        group: 'tagsActions',
      });
    };

    const showError = (actionName: string) => {
      toast.add({
        severity: 'error',
        summary: setActionInfo(actionName),
        group: 'tagsActions',
      });
    };

    const enableSelected = () => {
      const enabledTags: RfidTag[] = [];
      selectedTags.value.forEach((tag: RfidTag) => {
        tag.state = true;
        enabledTags.push(tag);
      });

      rfidTagsStore.updateRfidTag(enabledTags).then((response: any) => {
        if (response.status === 200) {
          showSuccess(ENABLE);
          selectedTags.value = [];
        } else {
          showError(ENABLE);
        }
      });
    };

    const disableSelected = () => {
      const enabledTags: RfidTag[] = [];
      selectedTags.value.forEach((tag: RfidTag) => {
        tag.state = false;
        enabledTags.push(tag);
      });

      rfidTagsStore.updateRfidTag(enabledTags).then((response: any) => {
        if (response.status === 200) {
          showSuccess(DISABLE);
          selectedTags.value = [];
        } else {
          showError(DISABLE);
        }
      });
    };

    const deleteSelected = () => {
      selectedTags.value.map((tag: RfidTag) => rfidTagDeletionIds.value.push({ id: tag.id }));
      rfidTagDeleteModalIsOpen.value = true;
    };

    const openCreateRfidTagModal = () => {
      rfidTagModalIsOpen.value = true;
    };

    const openEditRfidTagModal = (data: RfidTag) => {
      rfidTagEditionData.value = data;
      rfidTagIsEditing.value = true;
      rfidTagModalIsOpen.value = true;
    };

    const openDeleteRfidTagModal = (id: number) => {
      rfidTagDeletionIds.value.push({ id: id });
      rfidTagDeleteModalIsOpen.value = true;
    };

    watch(
      () => rfidTagsStore.rfidTagDeletionIds,
      (value) => {
        if (value.length === 0) {
          selectedTags.value = [];
        }
      }
    );

    onMounted(async () => {
      await rfidTagsStore
        .fetchRfidTagsList()
        .catch((error) => {
          if (error.response.status === 403) {
            notAuthorized.value = true;
          } else {
            throw new Error('RFID Tags failed to be fetched');
          }
        })
        .finally(() => (loading.value = false));
    });

    return {
      tags,
      filters,
      loading,
      matchModes,
      statuses,
      IS_ENABLED,
      toggleActions,
      actionsMenu,
      actionMenuItems,
      enableSelected,
      disableSelected,
      deleteSelected,
      selectedTags,
      openCreateRfidTagModal,
      openEditRfidTagModal,
      openDeleteRfidTagModal,
      setActionInfo,
      notAuthorized,
      activeRoles,
    };
  },
};
</script>

<style lang="scss" scoped></style>
