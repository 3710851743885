<template>
  <div class="flex flex-col pb-2">
    <div class="flex justify-between text-right">
      <span
        data-cy="tariff-info-field-name"
        class="block font-medium text-[var(--gray-400)] py-1"
        >{{ $t('tariff.tariffDetails.sessionFeeAndTaxRate') }}</span
      >
      <div
        v-if="rate?.session_fee_cent !== undefined && rate?.session_fee_tax_percent !== undefined"
      >
        <span
          data-cy="tariff-info-field-session_fee"
          class="pt-1 px-4 w-1/2 text-right font-medium text-[var(--gray-700)] whitespace-nowrap"
        >
          {{ displayValueWithCurrency(rate?.session_fee_cent, TWO_DIGITS, 'kWh') }}</span
        >
        <span class="value-separator">|</span>
        <span
          data-cy="tariff-info-field-session_fee_tax_percent"
          class="py-1 pl-4 w-1/2 text-right font-medium text-[var(--gray-700)]"
          >{{ displayValueWithPercent(rate?.session_fee_tax_percent) }}</span
        >
      </div>
      <span
        v-if="rate?.session_fee_cent === undefined && rate?.session_fee_tax_percent === undefined"
        class="empty py-2 pl-4 font-medium"
        >--</span
      >
    </div>
    <div class="flex justify-between text-right">
      <span
        data-cy="tariff-info-field-name"
        class="block font-medium text-[var(--gray-400)] py-1"
        >{{ $t('tariff.tariffDetails.energyCostAndTaxRate') }}</span
      >
      <div
        v-if="
          rate?.price_per_kwh_tenth_cent !== undefined &&
          rate?.price_per_kwh_tax_percent !== undefined
        "
      >
        <span
          data-cy="tariff-info-field-price_per_kwh"
          class="pt-1 px-4 w-1/2 text-right font-medium text-[var(--gray-700)]"
          >{{ displayValueWithCurrency(rate?.price_per_kwh_tenth_cent, THREE_DIGITS, 'kWh') }}</span
        >
        <span class="value-separator">|</span>
        <span
          data-cy="tariff-info-field-price_per_kwh_tax_percent"
          class="py-1 pl-4 w-1/2 text-right font-medium text-[var(--gray-700)]"
          >{{ displayValueWithPercent(rate?.price_per_kwh_tax_percent) }}</span
        >
      </div>
      <span
        v-if="
          rate?.price_per_kwh_tenth_cent === undefined &&
          rate?.price_per_kwh_tax_percent === undefined
        "
        class="empty py-2 pl-4 font-medium"
        >--</span
      >
    </div>
    <div class="flex justify-between text-right">
      <span
        data-cy="tariff-info-field-name"
        class="block font-medium text-[var(--gray-400)] py-1 text-left"
        >{{ $t('tariff.tariffDetails.chargingDurationCostAndTaxRate') }}</span
      >
      <div
        v-if="
          rate?.charging_duration_fee_tenth_cent !== undefined &&
          rate?.charging_duration_fee_tax_percent !== undefined
        "
      >
        <span
          data-cy="tariff-info-field-charging_duration_fee_tenth_cent"
          class="pt-1 px-4 w-1/2 text-right font-medium text-[var(--gray-700)] whitespace-nowrap"
          >{{
            displayValueWithCurrency(rate?.charging_duration_fee_tenth_cent, THREE_DIGITS, 'kWh')
          }}</span
        >
        <span class="value-separator">|</span>
        <span
          data-cy="tariff-info-field-charging_duration_fee_tax_percent"
          class="py-1 pl-4 w-1/2 text-right font-medium text-[var(--gray-700)]"
          >{{ displayValueWithPercent(rate?.charging_duration_fee_tax_percent) }}</span
        >
      </div>
      <span
        v-if="
          rate?.charging_duration_fee_tenth_cent === undefined &&
          rate?.charging_duration_fee_tax_percent === undefined
        "
        class="empty py-2 pl-4 font-medium"
        >--</span
      >
    </div>
    <div class="flex justify-between text-right">
      <span
        data-cy="tariff-info-field-name"
        class="block font-medium text-[var(--gray-400)] py-1"
        >{{ $t('tariff.tariffDetails.idleGracePeriod') }}</span
      >
      <div v-if="rate?.idle_fee_grace_period_min !== undefined">
        <span
          data-cy="tariff-info-field-idle_fee_grace_period_min"
          class="py-1 pl-4 w-1/2 text-right font-medium text-[var(--gray-700)]"
          >{{ rate?.idle_fee_grace_period_min }} min</span
        >
      </div>
      <span v-if="rate?.idle_fee_grace_period_min === undefined" class="empty py-2 pl-4 font-medium"
        >--</span
      >
    </div>
    <div class="flex justify-between text-right">
      <span
        data-cy="tariff-info-field-name"
        class="block font-medium text-[var(--gray-400)] py-1 text-left"
        >{{ $t('tariff.tariffDetails.idleFeeAndTaxRate') }}</span
      >
      <div
        v-if="
          rate?.idle_fee_tenth_cent_per_min !== undefined &&
          rate?.idle_fee_tax_percent !== undefined
        "
      >
        <span
          data-cy="tariff-info-field-idle_fee_per_min"
          class="py-1 px-4 w-1/2 text-right font-medium text-[var(--gray-700)] whitespace-nowrap"
          >{{
            displayValueWithCurrency(rate?.idle_fee_tenth_cent_per_min, THREE_DIGITS, 'kWh')
          }}</span
        >
        <span class="value-separator">|</span>
        <span
          data-cy="tariff-info-field-idle_fee_tax_percent"
          class="py-1 pl-4 w-1/2 text-right font-medium text-[var(--gray-700)]"
          >{{ displayValueWithPercent(rate?.idle_fee_tax_percent) }}</span
        >
      </div>
      <span
        v-if="
          rate?.idle_fee_tenth_cent_per_min === undefined &&
          rate?.idle_fee_tax_percent === undefined
        "
        class="empty py-2 pl-4 font-medium"
        >--</span
      >
    </div>
    <div class="flex justify-between text-right">
      <span
        data-cy="tariff-info-field-name"
        class="block font-medium text-[var(--gray-400)] py-1"
        >{{ $t('tariff.tariffDetails.fixedTaxAmountPerSessionEnergy') }}</span
      >
      <div v-if="rate?.fixed_tax_session_cent !== undefined">
        <span
          data-cy="tariff-info-field-fixed_tax_session"
          class="py-1 pl-4 w-1/2 text-right font-medium text-[var(--gray-700)]"
          >{{ displayValueWithCurrency(rate?.fixed_tax_session_cent, TWO_DIGITS) }}</span
        >
      </div>
      <span v-if="rate?.fixed_tax_session_cent === undefined" class="empty py-2 pl-4 font-medium"
        >--</span
      >
    </div>
    <div class="flex justify-between text-right">
      <span
        data-cy="tariff-info-field-name"
        class="block font-medium text-[var(--gray-400)] py-1"
        >{{ $t('tariff.tariffDetails.fixedTaxAmountPerSessionParking') }}</span
      >
      <div v-if="rate?.fixed_tax_parking_cent !== undefined">
        <span
          data-cy="tariff-info-field-fixed_tax_session"
          class="py-1 pl-4 w-1/2 text-right font-medium text-[var(--gray-700)]"
          >{{ displayValueWithCurrency(rate?.fixed_tax_parking_cent, TWO_DIGITS) }}</span
        >
      </div>
      <span v-if="rate?.fixed_tax_parking_cent === undefined" class="empty py-2 pl-4 font-medium"
        >--</span
      >
    </div>
    <div class="flex justify-between text-right">
      <span
        data-cy="tariff-info-field-name"
        class="block font-medium text-[var(--gray-400)] py-1"
        >{{ $t('tariff.tariffDetails.preAuthorizationAmount') }}</span
      >
      <div v-if="rate?.pre_authorization_amount_cent !== undefined">
        <span
          data-cy="tariff-info-field-pre_authorization_amount"
          class="py-1 pl-4 w-1/2 text-right font-medium text-[var(--gray-700)]"
          >{{ displayValueWithCurrency(rate?.pre_authorization_amount_cent, TWO_DIGITS) }}</span
        >
      </div>
      <span
        v-if="rate?.pre_authorization_amount_cent === undefined"
        class="empty py-2 pl-4 font-medium"
        >--</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  LEFT,
  TWO_DIGITS,
  THREE_DIGITS,
  TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER,
  THREE_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER,
} from '@/utils/constants';

import type { Rate, SpecialRate } from '@/models/tariffs.model';

const props = defineProps<{
  rate: Rate | SpecialRate | undefined;
  currencySymbol: string | undefined;
  currencySymbolPosition: string;
}>();

const displayValueWithCurrency = (value: number, decimalPlaces: number, energyUnit?: string) => {
  const convertedValue =
    decimalPlaces === THREE_DIGITS
      ? value / THREE_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : value / TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER;

  if (props.currencySymbolPosition === LEFT) {
    return energyUnit
      ? `${props.currencySymbol} ${convertedValue} / ${energyUnit}`
      : `${props.currencySymbol} ${convertedValue}`;
  } else {
    return energyUnit
      ? `${convertedValue} ${props.currencySymbol} / ${energyUnit}`
      : `${convertedValue} ${props.currencySymbol}`;
  }
};

const displayValueWithPercent = (value: number) =>
  `${value / TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER} %`;
</script>
<style scoped lang="scss"></style>
