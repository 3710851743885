<template>
  <div class="loading-widget-container">
    <i class="pi pi-spin pi-spinner" />
  </div>
</template>

<style scoped lang="scss">
.loading-widget-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--gray-50);
}

i {
  font-size: 1.25rem;
  color: var(--gray-300);
}
</style>
