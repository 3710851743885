<template>
  <Dialog
    v-model:visible="alertCreateModalIsOpen"
    data-cy="alert-create-edit-modal"
    modal
    class="alert-create-edit-modal bg-gray-100"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '820px' }"
    @hide="closeModal"
  >
    <template #header>
      <div class="relative w-full text-center">
        <Button
          v-if="!isFirst"
          :label="$t('back')"
          icon="pi pi-angle-left"
          class="!absolute p-button-outlined !bg-white p-button-plain p-button-sm mr-2 left-0 top-1/2 transform -translate-y-1/2"
          @click="goToPrevious"
        />

        <p class="font-semibold">
          {{ modalTitle }}
        </p>
      </div>
    </template>

    <ModalStepper :active-step="current" v-bind="{ steps, isEditMode }" />

    <div class="border-t">
      <component :is="alertStepsComponents[current]" v-bind="{ v$ }" />
    </div>

    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        data-cy="alert-create-edit-cancel-button"
        @click="closeModal"
      />
      <Button
        v-if="!isLast"
        :disabled="!isStepValid"
        :label="t('next')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        data-cy="alert-create-edit-next-button"
        @click="goToNext"
      />
      <Button
        v-else
        :label="t('apply')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        data-cy="alert-create-edit-submit-button"
        @click="submitForm"
      />
    </template>
  </Dialog>

  <Toast
    data-cy="alert-create-edit-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="alerts"
    class="alert-toast"
  />
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import { useI18n } from 'vue-i18n';
import { useAlertsStore } from '@/stores/admin/alerts/alerts.store';
import ModalStepper from '@/components/common/steppers/ModalStepper.vue';
import AlertModalTemplateStep from '@/components/alerts/steps/AlertModalTemplateStep.vue';
import { AlertStep } from '@/models/alerts.model';
import AlertModalConfigurationStep from '@/components/alerts/steps/AlertModalConfigurationStep.vue';
import AlertModalContactsStep from '@/components/alerts/steps/AlertModalContactsStep.vue';
import AlertModalPreviewStep from '@/components/alerts/steps/AlertModalPreviewStep.vue';
import AlertModalSitesStep from '@/components/alerts/steps/AlertModalSitesStep.vue';
import { useStepper } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';
import { CREATE, DEFAULT_TOAST_LIFE_MILLISECONDS, EDIT } from '@/utils/constants';
import { useVuelidate } from '@vuelidate/core';
import { alertRules } from '@/utils/alertModalRules';

const alertsStore = useAlertsStore();
const { t } = useI18n();
const toast = useToast();
const {
  alertModalData,
  alertCreateModalIsOpen,
  isEditMode,
  alertEditId,
  alertModalAllParamsValid,
} = storeToRefs(alertsStore);

const v$ = useVuelidate(alertRules, alertModalData);

const modalTitle = computed(() => {
  return isEditMode.value
    ? t('edit', { itemName: t('alert.title') })
    : t('create', { itemName: t('alert.title') });
});

const closeModal = () => {
  alertCreateModalIsOpen.value = false;
  resetForm();

  if (isEditMode.value) {
    alertEditId.value = null;
  }
};

const alertStepsComponents: Record<AlertStep, unknown> = {
  [AlertStep.Template]: AlertModalTemplateStep,
  [AlertStep.Configuration]: AlertModalConfigurationStep,
  [AlertStep.Sites]: AlertModalSitesStep,
  [AlertStep.Contacts]: AlertModalContactsStep,
  [AlertStep.Preview]: AlertModalPreviewStep,
};

const createPath = [
  AlertStep.Template,
  AlertStep.Configuration,
  AlertStep.Sites,
  AlertStep.Contacts,
  AlertStep.Preview,
];
const editPath = [AlertStep.Configuration, AlertStep.Sites, AlertStep.Contacts, AlertStep.Preview];
const currentPath = computed(() => (isEditMode.value ? editPath : createPath));

const { steps, current, goTo, goToNext, goToPrevious, isFirst, isLast } = useStepper(currentPath);

const resetForm = () => {
  alertsStore.resetAlertModalData();
  v$.value.$reset();
  goTo(steps.value[0]);
  alertsStore.alertModalAllParamsValid = true;
};

const showSuccess = (actionName: string) => {
  toast.removeGroup('alerts');
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('alert.title'),
      action: actionName === CREATE ? t('toast.created') : t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'alerts',
  });
};

const showError = (actionName: string) => {
  toast.removeGroup('alerts');
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: actionName === CREATE ? t('toast.creation') : t('toast.edition'),
      name: t('alert.title'),
    }),
    group: 'alerts',
  });
};

const isStepValid = computed(() => {
  const stepValidationRules: Record<AlertStep, boolean> = {
    [AlertStep.Template]: v$.value.template_id.$dirty && !v$.value.template_id.$invalid,
    [AlertStep.Configuration]:
      v$.value.name.$dirty && !v$.value.name.$invalid && alertModalAllParamsValid.value,
    [AlertStep.Sites]: v$.value.charge_parks.$dirty && !v$.value.charge_parks.$invalid,
    [AlertStep.Contacts]: true,
    [AlertStep.Preview]: true,
  };

  return stepValidationRules[current.value];
});

async function submitForm() {
  const action = isEditMode.value ? EDIT : CREATE;

  try {
    isEditMode.value ? await alertsStore.editAlert() : await alertsStore.createAlert();
    showSuccess(action);
    closeModal();
    resetForm();
    alertsStore.resetAlertModalData();
  } catch (err: unknown) {
    showError(action);
    throw new Error(err as string);
  }

  await alertsStore.fetchAlertsList();
}

watch(
  () => isEditMode.value,
  (isActive) => {
    !isActive ? v$.value.$reset() : v$.value.$touch();
  }
);
</script>
