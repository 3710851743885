import type {
  FullBrandingConfiguration,
  BrandingTemplate,
  Option,
} from '@/stores/admin/branding/branding.types';

export function fillMissingValuesInBrandingConfiguration(
  template: BrandingTemplate,
  configuration: FullBrandingConfiguration['settings']
): FullBrandingConfiguration['settings'] {
  template.settings.forEach((group) => {
    group.settings.forEach((setting) => {
      configuration[group.id] = configuration[group.id] || {};
      if (configuration[group.id][setting.id] === undefined) {
        configuration[group.id][setting.id] = setting.defaultValue ?? null;
      }
    });
  });
  return configuration;
}

type Validator<T extends Option> = (option: T, value: unknown) => { error?: string };

type ValidatorMap = {
  [T in Option['type']]: Validator<Extract<Option, { type: T }>>;
};

const validatorMap: ValidatorMap = {
  color: (_option, value) => {
    if (typeof value !== 'string') return { error: 'Color must be a string' };
    if (!/^#[0-9a-f]{6}$/i.test(value)) return { error: 'Color must be a valid hex color' };
    return {};
  },
  number: (option, value) => {
    if (typeof value !== 'number') return { error: 'Number must be a number' };
    if (option.min !== undefined && value < option.min)
      return { error: 'Number must be at least ' + option.min };
    if (option.max !== undefined && value > option.max)
      return { error: 'Number must be at most ' + option.max };
    return {};
  },
  image: (_option, value) => {
    if (typeof value !== 'string') return { error: 'Image must have valid id' };
    return {};
  },
  boolean: (_option, value) => {
    if (typeof value !== 'boolean') return { error: 'Boolean must be a boolean' };
    return {};
  },
  string: (_option, value) => {
    if (typeof value !== 'string') return { error: 'String must be a string' };
    return {};
  },
  'single-enum': (option, value) => {
    if (typeof value !== 'string') return { error: 'Enum must be a string' };
    if (!option.options.some((option) => option.value === value))
      return { error: 'Enum must be one of the options' };
    return {};
  },
  'multi-enum': (option, value) => {
    if (!Array.isArray(value)) return { error: 'Enum must be an array' };
    if (!value.every((value) => typeof value === 'string'))
      return { error: 'Enum must be an array of strings' };
    if (!value.every((value) => option.options.some((option) => option.value === value)))
      return { error: 'Enum must be an array of options' };
    return {};
  },
  url: (_option, value) => {
    if (typeof value !== 'string') return { error: 'URL must be a string' };
    if (!/^https?:\/\/.+$/.test(value)) return { error: 'URL must be a valid URL' };
    return {};
  },
  email: (_option, value) => {
    if (typeof value !== 'string') return { error: 'Email must be a string' };
    if (!/^.+@.+$/.test(value)) return { error: 'Email must be a valid email' };
    return {};
  },
  phone: (_option, value) => {
    if (typeof value !== 'string') return { error: 'Phone must be a string' };
    if (!/^\+?[0-9]+$/.test(value)) return { error: 'Phone must be a valid phone number' };
    return {};
  },
};

export type BrandingValidationErrorMap = {
  [groupId: string]: {
    [settingId: string]: string | undefined;
  };
};

export function brandingErrors(
  template: BrandingTemplate,
  values: FullBrandingConfiguration['settings']
): BrandingValidationErrorMap {
  const map = {} as BrandingValidationErrorMap;

  for (const group of template.settings) {
    map[group.id] = {};

    for (const setting of group.settings) {
      const validator = validatorMap[setting.type];
      const value = values[group.id]?.[setting.id];
      map[group.id][setting.id] = (validator as Validator<Option>)(setting, value).error;
    }
  }

  return map;
}
