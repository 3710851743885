<template>
  <div class="flex items-center time-range-wrapper">
    <span class="text-sm font-medium whitespace-nowrap pr-4 time-range-title">{{ title }}</span>
    <Dropdown
      v-model="selectedTime"
      :options="options"
      optionLabel="name"
      :placeholder="$t('allTime')"
      class="time-range-selector"
      showClear
      scrollHeight="240px"
      @update:modelValue="filterByTime"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import Dropdown from 'primevue/dropdown';

defineProps<{
  options: any[];
  title: string;
}>();

const emit = defineEmits(['filterByTime']);

const selectedTime = ref();

const filterByTime = (data: any) => {
  emit('filterByTime', data);
};
</script>

<style lang="scss" scoped>
.time-range {
  &-wrapper {
    background-color: #fff;
    border: 1px solid $grey-medium-light;
    border-radius: 4px;
  }
  &-title {
    font-weight: 500;
    font-size: 14px;
    color: $text-light;
    padding: 8px 16px;
    margin-right: 10px;
    background-color: $grey-light;
    border-right: 1px solid $grey-medium-light;
  }
}

:deep(.p-dropdown) {
  border: none;
  min-width: 175px;

  &:not(.p-disabled) {
    &.p-focus {
      box-shadow: none;
    }
  }
}

:deep(.p-dropdown .p-dropdown-label.p-placeholder) {
  font-weight: 500;
  color: $text-dark;
}

:deep(.p-inputtext) {
  font-size: 14px;
  padding: 6px 16px;
}
</style>
