import { defineStore } from 'pinia';
import {
  createReportRequest,
  editReportRequest,
  fetchReports,
  fetchReportTemplates,
  batchUpdateReportRequest,
  batchDeleteReportRequest,
  fetchReportFrequencies,
  fetchGeneratedReports,
  fetchReportFilePath,
} from '@/stores/admin/reports/reports.api';

import { useContactsStore } from '@/stores/admin/contacts/contacts.store';

import type {
  Report,
  ReportEditData,
  ReportTemplate,
  ReportBatchEditData,
  GeneratedReport,
  ReportContact,
} from '@/models/reports.model';
import type { IdName, ID } from '@/models/common.model';
import type { Contact } from '@/models/contacts.model';

interface ReportsState {
  reports: Report[];
  reportsActiveTab: number;
  reportCreateModalIsOpen: boolean;
  reportDeleteModalIsOpen: boolean;
  reportEditId: number | null;
  reportDeleteIds: ID[];
  reportTemplates: ReportTemplate[];
  reportModalData: ReportEditData;
  frequencies: IdName[];
  generatedReports: GeneratedReport[];
}

export const useReportsStore = defineStore('reports', {
  state: (): ReportsState => ({
    reports: [],
    reportsActiveTab: 0,
    reportCreateModalIsOpen: false,
    reportDeleteModalIsOpen: false,
    reportEditId: null,
    reportDeleteIds: [],
    reportTemplates: [],
    reportModalData: {
      name: '',
      frequency: null,
      charge_parks: [],
      contacts: [],
      template_id: '',
      next_report_at: null,
      start_date: null,
      end_date: null,
      report_type: null,
    },
    frequencies: [],
    generatedReports: [],
  }),

  getters: {
    isEditMode(state): boolean {
      return state.reportEditId !== null;
    },

    selectedTemplate(state): ReportTemplate | undefined {
      return state.reportTemplates.find(
        (template) => template.id === state.reportModalData.template_id
      );
    },

    selectedFrequency(state): IdName | undefined {
      return state.frequencies.find(
        (frequency) => frequency.id === state.reportModalData.frequency
      );
    },

    selectedContacts(state): Contact[] {
      const contactsStore = useContactsStore();

      return contactsStore.contacts.filter((contact) =>
        state.reportModalData.contacts.includes(contact.id)
      );
    },

    reportsWithTemplateName(state) {
      return state.reports
        .filter((reportItem) => reportItem.report_type === 'periodic')
        .map((report) => {
          const template = state.reportTemplates.find(
            (template) => template.id === report.template_id
          );
          return {
            ...report,
            template_name: template?.name,
          };
        });
    },
  },

  actions: {
    async fetchReportsList() {
      return await fetchReports()
        .then((response) => {
          this.reports = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchGeneratedReportsList(startTime?: string, endTime?: string) {
      return await fetchGeneratedReports(startTime, endTime)
        .then((response) => {
          this.generatedReports = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchReportTemplateList() {
      return await fetchReportTemplates()
        .then((response) => {
          this.reportTemplates = response.data.reportTemplates;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchFrequencies() {
      return await fetchReportFrequencies()
        .then((response) => {
          this.frequencies = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async batchUpdateReports(items: ReportBatchEditData) {
      try {
        return await batchUpdateReportRequest(items);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async createReport() {
      try {
        return await createReportRequest(this.reportModalData);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async batchDeleteReports(ids: ID[]) {
      try {
        return await batchDeleteReportRequest(ids);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async editReport() {
      try {
        if (this.reportEditId === null) {
          return Promise.reject('No report id to edit');
        }

        return await editReportRequest(this.reportEditId, this.reportModalData);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async fetchReportFileFullPath(url: string) {
      try {
        return await fetchReportFilePath(url);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },

    fillReportModalData() {
      const report = this.reports.find((report: Report) => report.id === this.reportEditId);

      if (!report) {
        throw new Error(`Report with id ${this.reportEditId} not found`);
      }

      this.reportModalData.template_id = report.template_id;
      if (report.name) this.reportModalData.name = report.name;
      this.reportModalData.frequency = report.frequency;
      this.reportModalData.charge_parks = report.charge_parks.map(
        (chargePark: IdName) => chargePark.id
      );
      this.reportModalData.contacts = report.contacts.map((contact: ReportContact) => contact.id);
      this.reportModalData.next_report_at = report.next_report_at ? report.next_report_at : null;
      this.reportModalData.report_type = report.report_type;
    },

    resetReportModalData() {
      this.reportModalData = {
        name: '',
        frequency: null,
        charge_parks: [],
        contacts: [],
        template_id: '',
        next_report_at: null,
        start_date: null,
        end_date: null,
        report_type: null,
      };
    },
  },
});
