<template>
  <div
    :class="`flex text-sm pt-2 pb-1 ${alertModalData.contacts.length > 6 ? 'pl-12 pr-6' : 'px-12'}`"
  >
    <div class="w-full info-single">
      <div
        v-if="alertModalData.contacts.length > 0"
        :class="`${alertModalData.contacts.length > 6 ? 'h-[190px] overflow-y-scroll' : ''}`"
      >
        <ReadOnlyTitle :title="$t('contacts')" />
        <div v-for="(contact, index) in alertStore.selectedContacts" :key="index">
          <ReadOnlyItem
            v-if="contact.email"
            :name="contact.email"
            :value="$t('email')"
            connector
            :class="`${alertModalData.contacts.length > 6 ? 'pr-[7px]' : ''}`"
          />
          <ReadOnlyItem
            v-if="contact.type === 'SMS'"
            :name="prettify(`${contact.name}`)"
            :value="$t('sms')"
            connector
            :class="`${alertModalData.contacts.length > 6 ? 'pr-[7px]' : ''}`"
          />
        </div>
      </div>
      <ReadOnlyItem
        :name="$t('alert.deliveryRateLimit')"
        :value="`${alertModalData.max_rate_per_day ? alertModalData.max_rate_per_day : $t('none')}`"
        :class="`${alertModalData.contacts.length > 6 ? 'pr-[27px]' : ''}`"
      />
      <ReadOnlyItem
        :name="$t('alert.sendOnWeekends')"
        :value="prettify(`${alertModalData.send_on_weekends}`)"
        :class="`${alertModalData.contacts.length > 6 ? 'pr-[27px]' : ''}`"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { prettify } from '@/utils/textFormatting';
import ReadOnlyItem from '@/components/common/readOnly/ReadOnlyItem.vue';
import ReadOnlyTitle from '@/components/common/readOnly/ReadOnlyTitle.vue';

import { useAlertsStore } from '@/stores/admin/alerts/alerts.store';
import { storeToRefs } from 'pinia';

const alertStore = useAlertsStore();
const { alertModalData } = storeToRefs(alertStore);
</script>
