<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="whitelists-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}</Message
  >
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="whitelists-table"
    :value="whitelists"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    filterDisplay="menu"
    :globalFilterFields="['name']"
    dataKey="id"
    class="p-datatable-sm mb-5 mt-2 border-rounded overflow-hidden"
    v-model:selection="selectedWhitelist"
    selectionMode="single"
    :metaKeySelection="false"
    @row-click="onRowSelect"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="$t('searchBy', { name: $t('name') })"
            class="p-inputtext-sm"
          />
        </span>
        <Button
          v-if="activeRoles"
          data-cy="open-create-whitelist-modal"
          :label="$t('create', { itemName: $t('whitelist.title') })"
          icon="pi pi-plus"
          class="p-button-sm p-button-primary"
          @click="openCreateWhitelistModal"
        />
      </div>
    </template>
    <template #empty>
      <span data-cy="whitelists-table-empty" class="w-full block text-center">{{
        $t('whitelist.empty')
      }}</span>
    </template>
    <Column
      class="no-top-border-row"
      field="name"
      :header="$t('whitelist.whitelistName')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 13rem"
    >
      <template #body="{ data }">
        <span data-cy="whitelist-name">{{ data.name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="number_of_rfidtags"
      :header="$t('rfidTags')"
      :sortable="true"
      style="min-width: 8rem"
    >
      <template #body="{ data }">
        <span v-if="data.number_of_rfidtags > 0" data-cy="whitelist-rfid-tags">{{
          `${data.number_of_rfidtags} ${$t('rfidTags')}`
        }}</span>
        <span v-else class="empty">--</span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="number_of_systems"
      :header="$t('whitelist.assignedChargers')"
      :sortable="true"
      style="min-width: 12rem"
    >
      <template #body="{ data }">
        <span v-if="data.number_of_systems > 0" data-cy="whitelist-assigned-chargers">{{
          `${data.number_of_systems} ${$t('chargers')}`
        }}</span>
        <span v-else class="empty">--</span>
      </template>
    </Column>
    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }">
        <div class="flex">
          <Button
            v-if="activeRoles"
            data-cy="open-edit-whitelist-modal"
            :label="$t('edit')"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openEditWhitelistModal(data)"
          />
          <Button
            v-if="activeRoles"
            data-cy="open-delete-whitelist-modal"
            :label="$t('delete')"
            icon="pi pi-trash"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openDeleteWhitelistModal(data.id)"
          />
          <Button
            data-cy="open-whitelist-details-table"
            :label="$t('details')"
            icon="pi pi-arrow-right"
            iconPos="right"
            class="p-button-outlined p-button-plain p-button-sm"
            @click="openWhitelistDetails(data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <WhitelistEditModal />
  <WhitelistDeleteModal />
</template>

<script lang="ts">
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useWhitelistsStore } from '@/stores/admin/whitelists/whitelists.store';
import { useGeneralStore } from '@/stores/general/general.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { WHITELISTS } from '@/utils/routeNames';
import { WHITELIST_ADMIN_ROLE, ADMINISTRATOR_ROLE } from '@/utils/constants';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import WhitelistEditModal from '@/components/whitelists/WhitelistEditModal.vue';
import WhitelistDeleteModal from './WhitelistDeleteModal.vue';

import type { Whitelist } from '@/models/whitelists.model';

export default {
  name: 'WhitelistTable',

  components: {
    DataTable,
    Column,
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    InputText,
    WhitelistEditModal,
    Message,
    WhitelistDeleteModal,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const whitelistsStore = useWhitelistsStore();
    const {
      whitelists,
      whitelistEditModalIsOpen,
      whitelistIsEditing,
      whitelistEditionData,
      whitelistDeleteId,
      whitelistDeleteModalIsOpen,
    } = storeToRefs(whitelistsStore);
    const generalStore = useGeneralStore();
    const userStore = useUsersStore();
    const { title } = storeToRefs(generalStore);
    const loading = ref(true);
    const notAuthorized = ref(false);
    const selectedWhitelist = ref();

    const activeRoles = computed(
      () =>
        userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
        userStore.me.roles.map((role) => role.id).includes(WHITELIST_ADMIN_ROLE)
    );

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      users: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });

    const matchModes = ref([
      { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
      { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
      { label: 'Contains', value: FilterMatchMode.CONTAINS },
    ]);

    const openEditWhitelistModal = (data: Whitelist) => {
      whitelistEditionData.value = data;
      whitelistIsEditing.value = true;
      whitelistEditModalIsOpen.value = true;
    };

    const openDeleteWhitelistModal = (id: number) => {
      whitelistDeleteId.value = id;
      whitelistDeleteModalIsOpen.value = true;
    };

    const openWhitelistDetails = (id: number) => {
      whitelistsStore.fetchWhitelistDetails(id).then(() => {
        router.push(`/${WHITELISTS}/${id}`);
        title.value = t('whitelists');
      });
    };

    const openCreateWhitelistModal = () => {
      whitelistEditModalIsOpen.value = true;
    };

    const onRowSelect = (event: any) => {
      openWhitelistDetails(event.data.id);
    };

    onMounted(
      async () =>
        await whitelistsStore
          .fetchAllWhitelists()
          .catch((error) => {
            if (error.response.status === 403) {
              notAuthorized.value = true;
            } else {
              throw new Error('Whitelists failed to be fetched');
            }
          })
          .finally(() => (loading.value = false))
    );

    return {
      whitelists,
      filters,
      loading,
      matchModes,
      openDeleteWhitelistModal,
      openWhitelistDetails,
      openCreateWhitelistModal,
      notAuthorized,
      openEditWhitelistModal,
      onRowSelect,
      selectedWhitelist,
      activeRoles,
    };
  },
};
</script>

<style lang="scss" scoped></style>
