import { required, helpers, maxLength } from '@vuelidate/validators';
import { asciiCharacters } from '@/utils/validationRules';
import { MAX_LENGTH } from '@/utils/constants';
import i18n from '@/i18n';

export const dataExportRules = {
  name: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('name').toLowerCase(),
        item: i18n.global.t('dataExport.title').toLowerCase(),
      }),
      required
    ),
    asciiCharacters: helpers.withMessage(
      i18n.global.t('rules.asciiCharacters', { field: i18n.global.t('name') }),
      asciiCharacters
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
  systems: {
    required: helpers.withMessage(i18n.global.t('rules.chargeParkRequired'), required),
  },
  datatypes: {
    required: helpers.withMessage(i18n.global.t('rules.chargeParkRequired'), required),
  },
  start_datetime: {
    required: helpers.withMessage(i18n.global.t('rules.chargeParkRequired'), required),
  },
  end_datetime: {
    required: helpers.withMessage(i18n.global.t('rules.chargeParkRequired'), required),
  },
};
