import { required, helpers, maxLength } from '@vuelidate/validators';
import { asciiCharacters } from '@/utils/validationRules';
import { MAX_LENGTH } from '@/utils/constants';
import i18n from '@/i18n';

export const tariffRules = {
  name: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('name').toLowerCase(),
        item: i18n.global.t('tariff.title').toLowerCase(),
      }),
      required
    ),
    asciiCharacters: helpers.withMessage(
      i18n.global.t('rules.asciiCharacters', { field: i18n.global.t('tariff.title') }),
      asciiCharacters
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
  currency: {
    required: helpers.withMessage(i18n.global.t('rules.required'), required),
  },
  charge_parks: {
    required: helpers.withMessage(i18n.global.t('rules.chargeParkRequired'), required),
  },
};

export const rateRules = {
  name: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('name').toLowerCase(),
        item: i18n.global.t('tariff.title').toLowerCase(),
      }),
      required
    ),
    asciiCharacters: helpers.withMessage(
      i18n.global.t('rules.asciiCharacters', { field: i18n.global.t('name') }),
      asciiCharacters
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
  days: {
    required: helpers.withMessage(i18n.global.t('rules.weekdayRequired'), required),
  },
  session_fee_cent: {
    required: helpers.withMessage(i18n.global.t('rules.required'), required),
  },
  session_fee_tax_percent: {
    required: helpers.withMessage(i18n.global.t('rules.required'), required),
  },
  price_per_kwh_tenth_cent: {
    required: helpers.withMessage(i18n.global.t('rules.required'), required),
  },
  price_per_kwh_tax_percent: {
    required: helpers.withMessage(i18n.global.t('rules.required'), required),
  },
  idle_fee_tenth_cent_per_min: {
    required: helpers.withMessage(i18n.global.t('rules.required'), required),
  },
  idle_fee_tax_percent: {
    required: helpers.withMessage(i18n.global.t('rules.required'), required),
  },
  idle_fee_grace_period_min: {
    required: helpers.withMessage(i18n.global.t('rules.required'), required),
  },
  fixed_tax_session_cent: {
    required: helpers.withMessage(i18n.global.t('rules.required'), required),
  },
  fixed_tax_parking_cent: {
    required: helpers.withMessage(i18n.global.t('rules.required'), required),
  },
  pre_authorization_amount_cent: {
    required: helpers.withMessage(i18n.global.t('rules.required'), required),
  },
};
