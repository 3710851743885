<template>
  <div class="section">
    <boolean-input
      v-if="type === 'bool'"
      :id="props.id"
      :value="defaultValue"
      @update:value="updateValue"
    />
    <string-input
      v-if="type === 'string'"
      :id="props.id"
      :default-value="defaultValue"
      @update:value="updateValue"
      :valid="inputValid"
    />
    <enum-input
      v-if="type === 'enum'"
      :id="props.id"
      :default-value="defaultValue"
      :enum-values="props.enumValues"
      @update:value="updateValue"
      :valid="inputValid"
    />
    <numeric-input
      v-if="isNumericalType(type)"
      :id="props.id"
      :type="type"
      :default-value="defaultValue"
      :min="convertedMin"
      :max="convertedMax"
      :unit="desiredUnit"
      @update:value="updateValue"
      :valid="inputValid"
    />
    <div v-if="validators.length > 0" class="validator-container">
      <p
        class="hint"
        v-for="validation in validationState"
        :key="validation.label"
        :class="{ invalid: !validation.isValid }"
      >
        {{ validation.label }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import type { GenericSetting, UnitSystem } from '@/models/datatypes.model';
import type { ProductTypeSetting } from '@/models/productTypes.model';

export type ValueType = number | string | boolean | undefined;

export type GenericInputProps = {
  id: string;
  type: GenericSetting['type'] | ProductTypeSetting['type'];
  defaultValue: ValueType;
  min?: number;
  max?: number;
  enumValues?: { value: string | number; label: string; selectable?: boolean }[];
  originalUnit?: string;
  desiredUnitSystem?: UnitSystem;
};
</script>

<script setup lang="ts">
import { ref } from 'vue';
import { buildValidatorsForInput } from '@/logic/controlPanel/productType';
import { isNumericalType } from '@/models/productTypes.model';
import { convertValue, getUnitForSystem } from '@/utils/units';

import BooleanInput from '@/components/common/inputs/BooleanInput.vue';
import StringInput from '@/components/common/inputs/StringInput.vue';
import EnumInput from '@/components/common/inputs/EnumInput.vue';
import NumericInput from '@/components/common/inputs/NumericInput.vue';

const props = defineProps<GenericInputProps>();

const emit = defineEmits<{
  (event: 'update:value', value: ValueType, valid: boolean): void;
}>();

function tryConvert<T extends number | string | boolean | undefined>(
  val: T,
  fromUnit: string | undefined,
  toUnit: string | undefined
): T {
  if (typeof val === 'number' && fromUnit !== undefined && toUnit !== undefined) {
    const [convertedValue] = convertValue(val, fromUnit, toUnit);
    return convertedValue;
  }
  return val;
}

const isNumberInput = isNumericalType(props.type);

const desiredUnit =
  props.originalUnit && props.desiredUnitSystem
    ? getUnitForSystem(props.originalUnit, props.desiredUnitSystem)
    : undefined;

const defaultValue: ValueType =
  isNumberInput && desiredUnit
    ? tryConvert(props.defaultValue, props.originalUnit, desiredUnit)
    : props.defaultValue;

const convertedMin =
  isNumberInput && props.min !== undefined && desiredUnit
    ? tryConvert(props.min, props.originalUnit, desiredUnit)
    : props.min;

const convertedMax =
  isNumberInput && props.max !== undefined && desiredUnit
    ? tryConvert(props.max, props.originalUnit, desiredUnit)
    : props.max;

const validators = buildValidatorsForInput(props.type, convertedMin, convertedMax);

function getValidationState(value: ValueType): { label: string; isValid: boolean }[] {
  return validators.map((validator) => ({
    label: validator.label,
    isValid: validator.isValid(value),
  }));
}

const validationState = ref(getValidationState(defaultValue));
const inputValid = ref(validators.every((validator) => validator.isValid(defaultValue)));

const updateValue = (value: ValueType) => {
  inputValid.value = validators.every((validator) => validator.isValid(value));
  validationState.value = getValidationState(value);
  const unconverted =
    isNumberInput && props.originalUnit && desiredUnit
      ? tryConvert(value, desiredUnit, props.originalUnit)
      : value;
  emit('update:value', unconverted, inputValid.value);
};
</script>

<style scoped lang="scss">
div.validator-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 0.25rem;
  gap: 0.25rem;
}

p.hint {
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  background-color: var(--gray-50);
  color: var(--gray-500);

  border-radius: var(--rounded-sm);
  border: 1px solid var(--gray-200);

  &.invalid {
    border-color: var(--red-primary-dark);
    background-color: var(--red-500);
    color: var(--gray-0);
  }
}
</style>
