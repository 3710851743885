export interface Log {
  file_name: string;
  created_at: string;
  file_size: number;
  download_url: string;
}

export interface LogFileDownloadUrl {
  download_url: string;
}

export enum LogType {
  SYSTEM = 'SYSTEMLOG',
  OCPP = 'OCPPLOG',
  TRACE = 'TRACELOG',
}

export type LogFile = {
  id: number;
  iot_hub_device_id: string;
  filename: string;
  extracted_filename: string;
  start_time: string;
  end_time: string;
  logtext: string;
  type: LogType;
  port: string | null;

  created_at: string;
  modified_at: string;
};
