<template>
  <div class="system-location">
    <PanelWithEdit
      :title="$t('location')"
      :loading="isLoading"
      :isEditing="isEditing"
      :isNotValid="editFormIsNotValid"
      @onEdit="isEditing = true"
      @onClose="closeEditing"
      @onApply="editSite"
    >
      <div v-if="!isEditing" class="map-container">
        <l-map
          data-cy="system-map"
          ref="map"
          :use-global-leaflet="false"
          :zoom="9"
          :center="[Number(systemDetails.latitude), Number(systemDetails.longitude)]"
          :options="options"
        >
          <l-control-attribution position="bottomright" prefix="Leaflet | OpenStreetMap" />
          <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            layer-type="base"
            name="OpenStreetMap"
          />
          <l-marker
            :visible="!!systemDetails.latitude && !!systemDetails.longitude"
            :lat-lng="[Number(systemDetails.latitude), Number(systemDetails.longitude)]"
          />
        </l-map>
      </div>
      <AssetLocation
        v-if="isEditing"
        @coordinates="setCoordinates"
        @onValidateAddress="validateAddress"
        @onValidateLatitude="validateLatitude"
        @onValidateLongitude="validateLongitude"
        :resetValidation="resetLocationValidation"
        :address="systemDetails.address"
      />
      <CountriesSelect
        v-if="isEditing"
        data-cy="country-select"
        class="w-full my-3"
        :country="selectedChargeParkCountry"
        vertical
        @onCountrySelect="setSelectedCountry"
        aria-describedby="countrySelect-help"
      />
      <HardwareSystemLocationPreview v-if="!isEditing" :hardwareSystem="singleSystem" />
    </PanelWithEdit>
    <Toast
      data-cy="system-location-toast"
      position="top-center"
      errorIcon="pi pi-info-circle"
      group="hardwareSystemLocation"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { hardwareSystemLocationRules } from '@/utils/hardwareSystemDetailsRules';
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_TOAST_LIFE_MILLISECONDS,
} from '@/utils/constants';
import { LMap, LTileLayer, LControlAttribution, LMarker } from '@vue-leaflet/vue-leaflet';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import HardwareSystemLocationPreview from './HardwareSystemLocationPreview.vue';
import Toast from 'primevue/toast';
import AssetLocation from '@/components/common/AssetLocation.vue';
import CountriesSelect from '@/components/common/countries/CountriesSelect.vue';

import type { MapOptions } from 'leaflet';

const { t } = useI18n();
const toast = useToast();
const systemsStore = useHardwareSystemsStore();
const { singleSystem } = storeToRefs(systemsStore);
const systemDetails = ref({
  latitude: singleSystem.value.latitude,
  longitude: singleSystem.value.longitude,
  country_code: singleSystem.value.country_code,
  address: singleSystem.value.address,
});

const validationData = ref();
const address = ref('');
const resetLocationValidation = ref(false);

const latitude = ref<number>(DEFAULT_LATITUDE);
const longitude = ref<number>(DEFAULT_LONGITUDE);

const selectedChargeParkCountry = computed(() => systemsStore.hardwareSystemCountry);

const isLoading = ref(false);
const isEditing = ref(false);

const options: MapOptions = {
  attributionControl: false,
};

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('hardwareSystem.title'),
      action: t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'hardwareSystemLocation',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: t('toast.edition'),
      name: t('hardwareSystem.title'),
    }),
    group: 'hardwareSystemLocation',
  });
};

const editFormIsNotValid = computed(() => {
  return (
    !v$.value.$anyDirty ||
    systemDetails.value.country_code === null ||
    systemDetails.value.country_code === '' ||
    systemDetails.value.address === '' ||
    (validationData.value?.address.$anyDirty && validationData.value?.address.$error) ||
    (validationData.value?.latitude.$anyDirty && validationData.value?.latitude.$error) ||
    (validationData.value?.longitude.$anyDirty && validationData.value?.longitude.$error)
  );
});

const resetForm = () => {
  systemDetails.value.latitude = singleSystem.value.latitude;
  systemDetails.value.longitude = singleSystem.value.longitude;
  systemDetails.value.country_code = singleSystem.value.country_code;
  systemDetails.value.address = singleSystem.value.address;
  v$.value.latitude.$reset();
  v$.value.longitude.$reset();
  v$.value.country_code.$reset();
  v$.value.address.$reset();
  v$.value.$reset();
};

const closeEditing = () => {
  isEditing.value = false;
  resetForm();
};

const setSelectedCountry = (countryCode: string) => {
  systemDetails.value.country_code = countryCode;

  if (systemDetails.value.country_code === null) {
    v$.value.country_code.$validate();
  }
};

const validateAddress = async (geoAddress: string, validation: any) => {
  systemDetails.value.address = geoAddress;
  address.value = geoAddress;
  validationData.value = validation;
};

const validateLatitude = async (latitude: string, validation: any) => {
  systemDetails.value.latitude = latitude;
  validationData.value = validation;
};

const validateLongitude = async (longitude: string, validation: any) => {
  systemDetails.value.longitude = longitude;
  validationData.value = validation;
};

const setCoordinates = (lat: number, lng: number, validation: any) => {
  systemDetails.value.latitude = lat.toString();
  systemDetails.value.longitude = lng.toString();
  latitude.value = lat;
  longitude.value = lng;
  validationData.value = validation;
};

const editSite = async () => {
  const data = {
    ...systemDetails.value,
    latitude: systemDetails.value.latitude !== '' ? systemDetails.value.latitude : null,
    longitude: systemDetails.value.longitude ? systemDetails.value.longitude : null,
  };

  try {
    await systemsStore.updateHardwareSystem(singleSystem.value.id, data);
    await systemsStore.fetchSingleSystem(singleSystem.value.id).then((response) => {
      const { latitude, longitude, country_code, address } = response.data;
      systemDetails.value.latitude = latitude;
      systemDetails.value.longitude = longitude;
      systemDetails.value.country_code = country_code;
      systemDetails.value.address = address;
    });
    await systemsStore.fetchSystemsByChargeParkId(singleSystem.value.id);
    toast.removeGroup('hardwareSystemLocation');
    showSuccess();
    isEditing.value = false;
  } catch (error) {
    isEditing.value = true;
    toast.removeGroup('hardwareSystemLocation');
    showError();
    throw new Error(error as string);
  }
};

watch(
  () => systemDetails.value.country_code,
  (oldVal: string, newVal: string) => {
    newVal ? v$.value.country_code.$touch() : v$.value.country_code.$reset();
  }
);

const rules = computed(() => hardwareSystemLocationRules);

const v$ = useVuelidate(rules, systemDetails.value);
</script>
<style scoped lang="scss">
.map-container {
  height: 192px;
  width: auto;
  margin-top: 15px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 4px;
}
</style>
