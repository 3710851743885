<template>
  <div
    class="interactive-badge"
    @mouseenter="showInteractiveBadgeText = true"
    @mouseleave="showInteractiveBadgeText = false"
  >
    <sparkles-icon class="icon" />
    <transition name="fadeup">
      <div v-if="showInteractiveBadgeText" class="badge-text">
        <sparkles-icon class="icon" />
        {{ t('dashboards.widgets.info.interactive') }}
      </div>
    </transition>
  </div>
</template>
<script setup lang="ts">
import { SparklesIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const showInteractiveBadgeText = ref(false);
</script>

<style scoped lang="scss">
.interactive-badge {
  position: relative;
  margin-left: auto;
  margin-right: 0.75rem;
  border-radius: var(--rounded-md);
  padding: 0.5rem;
}

.badge-text {
  position: absolute;
  bottom: calc(100% - 0.125rem);
  right: 0;
  padding: 0.5rem 1rem;
  background-color: var(--gray-0);
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
  white-space: nowrap;
}

.icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  display: inline-block;
}

.fadeup-enter-active,
.fadeup-leave-active {
  transition: all 0.1s ease;
}

.fadeup-enter-from,
.fadeup-leave-to {
  opacity: 0;
  transform: translateY(0.125rem);
}
</style>
