<template>
  <div class="flex flex-col">
    <span v-if="name" data-cy="special-rate-preview-name" class="font-medium pt-3 pb-2">{{
      rate?.name
    }}</span>
    <div class="flex justify-between items-center text-right">
      <span data-cy="site-info-field-name" class="block font-medium text-[var(--gray-400)] py-1">{{
        $t('tariff.weekdays')
      }}</span>
      <div v-if="rate?.days !== undefined && rate?.days.length !== 0">
        <span
          data-cy="tariff-info-field-value"
          class="py-1 pl-2 w-1/2 text-right font-medium text-[var(--gray-700)]"
          v-for="(day, dayIndex) in rate?.days"
          :key="dayIndex"
          >{{ getDateName(day) }}{{ dayIndex < rate?.days.length - 1 ? ',' : '' }}
        </span>
      </div>
      <span v-if="!rate?.days" class="empty py-2 pl-4 font-medium">--</span>
    </div>
    <div class="flex justify-between text-right items-center">
      <span data-cy="site-info-field-name" class="block font-medium text-[var(--gray-400)] py-1">{{
        $t('tariff.times')
      }}</span>
      <div>
        <span
          v-if="rate?.start_time !== undefined && rate?.start_time !== null"
          data-cy="tariff-info-field-value"
          class="py-1 w-1/2 text-right font-medium text-[var(--gray-700)]"
          >{{ dayjs(startTime, 'HH:mm').format(dayJsTimeFormat) }}
        </span>
        <span
          v-if="!rate?.start_time || rate?.start_time === ''"
          class="empty py-2 pl-4 font-medium"
          >--</span
        >
        <i class="pi pi-arrow-right value-separator arrow-separator px-3"></i>
        <span
          v-if="rate?.end_time !== undefined && rate?.end_time !== null"
          data-cy="tariff-info-field-value"
          class="py-1 w-1/2 text-right font-medium text-[var(--gray-700)]"
          >{{ dayjs(endTime, 'HH:mm').format(dayJsTimeFormat) }}
        </span>
        <span v-if="!rate?.end_time || rate?.end_time === ''" class="empty py-2 font-medium"
          >--</span
        >
      </div>
    </div>
    <div class="flex justify-between text-right items-center">
      <span data-cy="site-info-field-name" class="block font-medium text-[var(--gray-400)] py-1">{{
        $t('tariff.startAndEndDate')
      }}</span>
      <div>
        <span
          data-cy="tariff-info-field-value"
          :class="`py-1 w-1/2 text-right font-medium text-[var(--gray-700)] ${
            rate?.start_date === null || rate?.start_date === '' ? 'empty' : ''
          }`"
          >{{
            rate?.start_date === null || rate?.start_date === ''
              ? '--'
              : dayjs(rate?.start_date).format(dayJsDateFormat)
          }}
        </span>
        <i class="pi pi-arrow-right value-separator arrow-separator px-3"></i>
        <span
          data-cy="tariff-info-field-value"
          :class="`py-1 w-1/2 text-right font-medium text-[var(--gray-700)] ${
            rate?.end_date === null || rate?.end_date === '' ? 'empty' : ''
          }`"
          >{{
            rate?.end_date === null || rate?.end_date === ''
              ? '--'
              : dayjs(rate?.end_date).format(dayJsDateFormat)
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import dayjs from 'dayjs';
import { isNumber, isDate } from 'lodash';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import type { SpecialRate } from '@/models/tariffs.model';
import { dayJsDateFormat, dayJsTimeFormat } from '@/utils/dateTimeFormatVue';

dayjs.extend(customParseFormat);
const chargeParksStore = useChargeParksStore();
const { daysOfWeek } = storeToRefs(chargeParksStore);

const props = withDefaults(
  defineProps<{
    rate: SpecialRate | undefined;
    name?: boolean;
  }>(),
  {
    name: true,
  }
);

const startTime = computed(() => {
  if (props.rate && props.rate.start_time && props.rate.start_time.length === 8) {
    return props.rate.start_time.slice(0, -3);
  } else if (props.rate && props.rate.start_time && isDate(props.rate.start_time)) {
    return dayjs(props.rate.start_time).format(dayJsTimeFormat.value);
  } else {
    return props.rate?.start_time;
  }
});

const endTime = computed(() => {
  if (props.rate && props.rate.end_time && props.rate.end_time.length === 8) {
    return props.rate.end_time.slice(0, -3);
  } else if (props.rate && props.rate.end_time && isDate(props.rate.end_time)) {
    return dayjs(props.rate.end_time).format(dayJsTimeFormat.value);
  } else {
    return props.rate?.end_time;
  }
});

const getDateName = (data: number | string) => {
  if (isNumber(data)) {
    return daysOfWeek.value.find((day) => day.id === data)?.name;
  } else {
    return daysOfWeek.value.find((day) => day.name.toLocaleLowerCase() === data.toLocaleLowerCase())
      ?.name;
  }
};
</script>
<style scoped lang="scss"></style>
