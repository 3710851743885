import mitt from 'mitt';

export type Events = {
  telemetry: {
    path: string;
    value: string | number | boolean;
    timestampMs?: number;
  };
  setting: {
    path: string;
    value: string | number | boolean;
    pending?: string | number | boolean;
  };
  command: {
    path: string;
    state: 'pending' | 'success' | 'error' | 'idle';
    executionId?: string;
  };
  status: {
    state: 'loading' | 'success' | 'error';
    label?: string;
    userDismissable?: boolean; // Default false
    autoDismissAfterSeconds?: number;
    details?: string;
  };
};

export const data = mitt<Events>();
