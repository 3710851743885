<template>
  <div class="px-12 border-b text-sm py-2">
    <ReadOnlyItem :name="$t('name')" :value="previewData.name" />
    <ReadOnlyItem :name="$t('dataExport.timeRangeStart')">
        <date-time-display :date="previewData.start_datetime" bold />
    </ReadOnlyItem>
    <ReadOnlyItem :name="$t('dataExport.timeRangeEnd')">
        <date-time-display :date="previewData.end_datetime" bold />
    </ReadOnlyItem>
    <ReadOnlyItem :name="$t('dataExport.includedData')" :value="previewData.datatypes" />
  </div>
  <div
    :class="`flex flex-col text-sm py-2 ${
      dataExportModalData.systems.length > 6 ? 'pl-12 pr-6' : 'px-12'
    } ${dataExportModalData.systems.length > 6 ? 'h-[200px] overflow-y-scroll' : ''}`"
  >
    <ReadOnlyTitle :title="$t('hardwareSystems')" />
    <div v-for="asset in previewData.systems" :key="asset.id">
      <ReadOnlyItem
        :name="asset.name"
        :value="asset.charge_park_name ? asset.charge_park_name : '--'"
        connector
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useDataExportsStore } from '@/stores/admin/dataExports/dataExports.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { storeToRefs } from 'pinia';

import ReadOnlyItem from '@/components/common/readOnly/ReadOnlyItem.vue';
import ReadOnlyTitle from '@/components/common/readOnly/ReadOnlyTitle.vue';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';

const dataExportsStore = useDataExportsStore();
const { dataExportModalData, dataTypes } = storeToRefs(dataExportsStore);
const systemsStore = useHardwareSystemsStore();
const { systems } = storeToRefs(systemsStore);

const previewData = ref({
  name: dataExportModalData.value.name,
  start_datetime: dataExportModalData.value.start_datetime,
  end_datetime: dataExportModalData.value.end_datetime,
  datatypes: dataTypes.value
    .filter((type) => dataExportModalData.value.datatypes.includes(type.id))
    .map((item) => item.name)
    .join(', '),
  systems: systems.value.filter((system) => dataExportModalData.value.systems.includes(system.id)),
});
</script>

<style scoped lang="scss"></style>
