<template>
  <Dialog
    data-cy="system-update-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '870px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="flex field-no-edit">
      <div class="w-full info-double">
        <div class="flex justify-between py-2 info-item">
          <span class="text-grey-medium font-semibold">{{ $t('name') }}</span>
          <span v-if="system.name" class="text-grey-medium">{{ system.name }}</span>
          <span v-else class="text-grey">{{ t('none') }}</span>
        </div>
        <div class="flex justify-between py-2 info-item">
          <span class="text-grey-medium font-semibold">{{ $t('hardwareSystem.customId') }}</span>
          <span v-if="system.custom_id" class="text-grey-medium">{{ system.custom_id }}</span>
          <span v-else class="text-grey">{{ t('none') }}</span>
        </div>
        <div class="flex justify-between py-2 info-item">
          <span class="text-grey-medium font-semibold">{{ $t('hardwareSystem.nidecId') }}</span>
          <span v-if="system.nidec_id" class="text-grey-medium">{{ system.nidec_id }}</span>
          <span v-else class="text-grey">{{ t('none') }}</span>
        </div>
        <div class="flex justify-between py-2 info-item">
          <span class="text-grey-medium font-semibold">{{ $t('hardwareSystem.version') }}</span>
          <span v-if="system.version" class="text-grey-medium">{{ system.version }}</span>
          <span v-else class="text-grey">{{ t('none') }}</span>
        </div>
      </div>
    </div>
    <UpdatesPicker
      v-if="system"
      :productTypeId="system.product_type_id"
      @onSelectedSystemUpdate="setSelectedUpdate"
    />
    <div class="flex justify-between field-edit">
      <div class="field flex flex-col w-1/2">
        <label for="retries" class="mb-2 field-edit-label"
          >{{ $t('update.retries') }}<span class="required">*</span></label
        >
        <InputNumber
          data-cy="system-install-update-retries-input"
          id="retries"
          aria-describedby="retries-help"
          class="p-inputtext-sm"
          :min="1"
          :useGrouping="false"
          v-model="systemUpdate.retries"
          :placeholder="$t('update.retries')"
          @keyup="validateRetries"
        />
        <div class="input-errors" v-for="(error, index) of v$.retries.$errors" :key="index">
          <small class="p-error">{{ error.$message }}</small>
        </div>
      </div>
      <div class="field flex flex-col w-1/2 pl-2">
        <label for="retry_interval" class="mb-2 field-edit-label">{{
          $t('update.retryIntervalInSec')
        }}</label>
        <InputNumber
          data-cy="system-install-update-retry-interval-input"
          id="retry_interval"
          aria-describedby="retries-help"
          class="p-inputtext-sm"
          :useGrouping="false"
          v-model="systemUpdate.retry_interval"
          :min="0"
          :placeholder="$t('update.retryInterval')"
          @keyup="validateInterval"
        />
        <div v-if="v$.retry_interval.$errors.length > 0">
          <div
            class="input-errors"
            v-for="(error, index) of v$.retry_interval.$errors"
            :key="index"
          >
            <small class="p-error">{{ error.$message }}</small>
          </div>
        </div>
        <span v-else class="text-xs pt-2">{{ $t('update.retryIntervalInfo') }}</span>
      </div>
    </div>
    <div class="flex justify-between field-edit mb-6">
      <div class="field flex flex-col w-1/2">
        <label for="retrieve_date" class="mb-1 field-edit-label"
          >{{ $t('update.scheduledAt') + ' (' + $t('update.retrieveDate') + ')'
          }}<span class="required">*</span></label
        >
        <Calendar
          data-cy="system-install-update-date"
          id="retrieve_date"
          class="w-1/2"
          v-model="systemUpdate.retrieve_date"
          :hideOnDateTimeSelect="true"
          :minDate="minDate"
          :manualInput="false"
          :hourFormat="primeVueHourFormat"
          :dateFormat="primeVueDateFormat"
          :placeholder="primeVueDateFormat"
          showTime
        >
        </Calendar>
        <div v-if="v$.retrieve_date.$errors.length > 0">
          <div class="input-errors" v-for="(error, index) of v$.retrieve_date.$errors" :key="index">
            <small class="p-error">{{ error.$message }}</small>
          </div>
        </div>
        <span v-else class="text-xs pt-2">{{ $t('update.scheduleInfo') }}</span>
      </div>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        data-cy="system-install-update-button"
        :label="t('update.install')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        @click="installUpdate"
        :loading="loading"
        :disabled="updateFormIsNotValid"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="system-update-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="system-update"
  >
    <template v-if="isFailed" #message="slotProps">
      <div class="flex flex-col w-full pt-1">
        <div class="w-full flex">
          <span class="p-toast-message-icon pi pi-info-circle"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
          </div>
        </div>
        <span class="my-2 ml-8 font-medium">{{ updateErrorMessage }}</span>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import UpdatesPicker from '../updates/UpdatesPicker.vue';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';

import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { helpers, integer, required, minValue, maxValue } from '@vuelidate/validators';
import dayjs from 'dayjs';

import type { PropType } from 'vue';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';
import { primeVueDateFormat, primeVueHourFormat } from '@/utils/dateTimeFormatVue';

export default {
  name: 'HardwareSystemUpdateModal',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    Toast,
    UpdatesPicker,
    InputNumber,
    Calendar,
  },

  props: {
    system: {
      type: Object as PropType<HardwareSystem>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const toast = useToast();
    const store = useHardwareSystemsStore();
    const isVisible = ref(false);
    const { systemUpdateModalIsOpen } = storeToRefs(store);
    const modalTitle = computed(() => t('hardwareSystem.updateSystem'));
    const loading = ref(false);
    const isFailed = ref(false);
    const updateErrorMessage = ref();
    const minDate = ref(new Date(dayjs().format()));
    const systemUpdate = ref({
      retries: 3,
      retry_interval: null,
      retrieve_date: new Date(dayjs.utc().format()),
      update_package_id: 0,
    });

    const closeModal = () => {
      systemUpdateModalIsOpen.value = false;
      loading.value = false;

      resetForm();
    };

    const resetForm = () => {
      systemUpdate.value.retries = 1;
      systemUpdate.value.retrieve_date = new Date(dayjs().format());
      systemUpdate.value.retry_interval = null;
      systemUpdate.value.update_package_id = 0;
      v$.value.retries.$reset();
      v$.value.retrieve_date.$reset();
      v$.value.retry_interval.$reset();
      v$.value.$reset();
    };

    const updateFormIsNotValid = computed(() => {
      return (
        !v$.value.$anyDirty ||
        (v$.value.retries.$anyDirty && v$.value.retries.$error) ||
        (v$.value.retry_interval.$anyDirty && v$.value.retry_interval.$error) ||
        (v$.value.retrieve_date.$anyDirty && v$.value.retrieve_date.$error) ||
        systemUpdate.value.update_package_id === 0
      );
    });

    const successSummary = computed(() => {
      return t('toast.success', {
        name: t('update.title'),
        action: t('toast.scheduled'),
      });
    });

    const errorSummary = computed(() => {
      return t('toast.error', {
        action: t('toast.schedule'),
        name: t('update.title'),
      });
    });

    const showSuccess = () => {
      toast.add({
        severity: 'success',
        summary: successSummary.value,
        life: DEFAULT_TOAST_LIFE_MILLISECONDS,
        group: 'system-update',
      });
    };

    const showError = () => {
      toast.add({
        severity: 'error',
        summary: errorSummary.value,
        group: 'system-update',
      });
    };

    const setSelectedUpdate = (id: number) => {
      systemUpdate.value.update_package_id = id;
      v$.value.$touch();
    };

    const installUpdate = async () => {
      await v$.value.$validate();

      if (v$.value.$error) return;

      loading.value = true;

      await store
        .updateSystemFirmware(props.system.id, systemUpdate.value)
        .then((response: any) => {
          loading.value = false;
          if (response.status === 201) {
            toast.removeGroup('system-update');
            showSuccess();
            closeModal();
          }
        })
        .catch((error) => {
          loading.value = false;
          toast.removeGroup('system-update');
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data.update_package_id
          ) {
            updateErrorMessage.value =
              error.response.data.update_package_id.error_details.error_message;
            isFailed.value = true;
            showError();
          } else {
            showError();
          }
          throw new Error(error);
        });
    };

    watch(
      () => systemUpdateModalIsOpen.value,
      (value) => {
        isVisible.value = value;
      }
    );

    const validateRetries = async () => {
      await v$.value.retries.$validate();
    };

    const validateDate = async () => {
      await v$.value.retrieve_date.$validate();
    };

    const validateInterval = async () => {
      await v$.value.retry_interval.$validate();
    };

    const rules = computed(() => {
      return {
        retries: {
          required: helpers.withMessage(
            t('rules.required', { field: t('update.retriesAmount'), item: t('update.title') }),
            required
          ),
          integer: helpers.withMessage(t('rules.integer', { field: t('update.retries') }), integer),
          minValue: helpers.withMessage(t('rules.minValue', { amount: 1 }), minValue(1)),
          maxValue: helpers.withMessage(t('rules.maxValue', { amount: 100 }), maxValue(100)),
        },
        retry_interval: {
          minValue: helpers.withMessage(t('rules.minValue', { amount: 0 }), minValue(0)),
          maxValue: helpers.withMessage(
            t('rules.maxValue', { amount: 2000000000 }),
            maxValue(2000000000)
          ),
        },
        retrieve_date: {
          required: helpers.withMessage(
            t('rules.required', { field: t('update.retrieveDate'), item: t('update.title') }),
            required
          ),
        },
      };
    });

    const v$ = useVuelidate(rules, systemUpdate.value);

    return {
      t,
      v$,
      toast,
      isVisible,
      closeModal,
      modalTitle,
      systemUpdateModalIsOpen,
      loading,
      installUpdate,
      setSelectedUpdate,
      isFailed,
      updateErrorMessage,
      systemUpdate,
      validateRetries,
      validateDate,
      validateInterval,
      minDate,
      updateFormIsNotValid,
      primeVueDateFormat,
      primeVueHourFormat,
    };
  },
};
</script>
<style lang="scss" scoped>
.info-double {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .info-item {
    width: 47%;

    &:nth-child(odd) {
      width: 50%;
      border-right: 1px solid $grey-medium-light;
      padding-right: 16px;
    }
  }
}
.field-edit {
  padding-bottom: 0;
}
:deep(.p-calendar) {
  width: 100%;
  min-width: 100%;
}

:deep(.p-paginator .p-paginator-pages .p-paginator-page) {
  min-width: 1.5rem;
  height: unset;
}

:deep(.p-paginator) {
  .p-paginator-next,
  .p-paginator-last,
  .p-paginator-prev,
  .p-paginator-first {
    min-width: 1.5rem;
    height: 1.5rem;
  }
}

:deep(.p-calendar .p-inputtext) {
  width: 100%;
  flex: none;
  padding: 0.6rem 0.6rem;
}
</style>
