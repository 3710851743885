<template>
  <div class="widget-stack-container">
    <div class="header-container">
      <back-button v-if="activeWidgetIndex > 0" @click="onBack" />
      <widget-title :title="activeWidget.title" />
      <interactive-badge v-if="allWidgets.length > 1" />
    </div>
    <widget-details v-if="appliedFilters.length > 0" :items="appliedFilters" />
    <map-widget
      v-if="allWidgets[activeWidgetIndex].type === 'map'"
      :key="activeWidgetIndex"
      :widget="allWidgets[activeWidgetIndex] as any"
      :additional-filters="additionalFilters"
      :is-interactive="transitionAvailable"
      :context-resolver="props.contextResolver"
    />
    <bar-chart-widget
      v-if="allWidgets[activeWidgetIndex].type === 'bar'"
      :key="activeWidgetIndex"
      :widget="allWidgets[activeWidgetIndex] as any"
      :additional-filters="additionalFilters"
      :is-interactive="transitionAvailable"
      :context-resolver="props.contextResolver"
      @click="onClick"
    />
    <pie-chart-widget
      v-if="allWidgets[activeWidgetIndex].type === 'pie'"
      :key="activeWidgetIndex"
      :widget="allWidgets[activeWidgetIndex] as any"
      :additional-filters="additionalFilters"
      :is-interactive="transitionAvailable"
      :context-resolver="props.contextResolver"
    />
    <table-widget
      v-if="allWidgets[activeWidgetIndex].type === 'table'"
      :key="activeWidgetIndex"
      :widget="allWidgets[activeWidgetIndex] as any"
      :additional-filters="additionalFilters"
      :context-resolver="props.contextResolver"
    />
  </div>
</template>

<script setup lang="ts">
import {
  type ChartEvent,
  type Widget,
  type WidgetFilter,
  isRenderableWidget,
  isWidgetStack,
  isTransitionLayer,
} from '@/stores/admin/dashboard/dashboard.types';

import { computed, ref, watch } from 'vue';
import { prettify } from '@/utils/textFormatting';
import { resolve } from '@/stores/admin/dashboard/dashboard.logic';

import BarChartWidget from '@/components/dashboard/widget/BarChartWidget.vue';
import PieChartWidget from '@/components/dashboard/widget/PieChartWidget.vue';
import WidgetTitle from '@/components/dashboard/misc/WidgetTitle.vue';
import WidgetDetails from '@/components/dashboard/misc/WidgetDetails.vue';
import InteractiveBadge from '@/components/dashboard/misc/InteractiveBadge.vue';
import BackButton from '@/components/common/micro/BackButton.vue';
import MapWidget from '@/components/dashboard/widget/MapWidget.vue';
import TableWidget from '@/components/dashboard/widget/TableWidget.vue';
import type { WidgetContextResolver } from '@/stores/admin/dashboard/dashboard.logic.context';

const props = defineProps<{
  widget: Widget;
  additionalFilters: WidgetFilter[];
  contextResolver: WidgetContextResolver;
}>();

const allWidgets = isWidgetStack(props.widget)
  ? props.widget.stack.filter(isRenderableWidget)
  : [props.widget];

const allTransitions = isWidgetStack(props.widget)
  ? props.widget.stack.filter(isTransitionLayer)
  : [];

const activeWidgetIndex = ref(0);
const activeWidget = computed(() => allWidgets[activeWidgetIndex.value]);

const nextTransitionIndex = ref(0);
const transitionAvailable = computed(() => nextTransitionIndex.value < allTransitions.length);

const transitionFilters = ref<Record<number, WidgetFilter[]>>({});
const additionalFilters = computed(() => {
  return props.additionalFilters.concat(Object.values(transitionFilters.value).flat());
});

const transitionDown = (event: ChartEvent) => {
  transitionFilters.value[nextTransitionIndex.value] = allTransitions[
    nextTransitionIndex.value
  ].eventFilters.map((filter) => evaluateFilter(event, filter));

  activeWidgetIndex.value++;
  nextTransitionIndex.value++;
};

const transitionBack = () => {
  activeWidgetIndex.value--;
  nextTransitionIndex.value--;

  transitionFilters.value = {
    ...transitionFilters.value,
    [nextTransitionIndex.value]: [],
  };
};

function evaluateFilter(event: ChartEvent, filter: WidgetFilter) {
  return {
    dimension: filter.dimension,
    condition: filter.condition,
    value: new Function('$event', 'return ' + filter.value)(event.data),
  };
}

const appliedFilters = ref<{ name: string; value: string }[]>([]);

watch(additionalFilters, async (filters) => {
  appliedFilters.value = await Promise.all(
    Object.values(filters).map(async (filter) => ({
      name: prettify(filter.dimension.split('.')[0]),
      value: (await resolve(filter.dimension, filter.value, props.contextResolver)).toString(),
    }))
  );
});

const onClick = (payload: { dimensionId: string; value: string | number }) => {
  if (!transitionAvailable.value) return;

  const event: ChartEvent = { type: 'click', data: {} };

  if (payload.dimensionId === 'chargepark.id') event.data.chargeParkId = +payload.value;
  if (payload.dimensionId === 'system.id') event.data.systemId = +payload.value;
  if (payload.dimensionId === 'time') event.data.timestamp = +payload.value;

  transitionDown(event);
};

const onBack = () => transitionBack();
</script>

<style scoped lang="scss">
.widget-stack-container {
  width: 100%;
  margin-bottom: auto;
  border-radius: var(--rounded-md);
  background-color: var(--gray-0);
  border: 1px solid var(--gray-200);
}

.header-container {
  display: flex;
  align-items: center;
  margin: 0;
  border-bottom: 1px solid var(--gray-200);
}

h5.title {
  padding: 1rem 1rem;
  margin: 0;
}
</style>
