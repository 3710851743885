import { defineStore } from 'pinia';
import type {
  ChargePark,
  ChargeParkEdit,
  ChargeParkDetailsEdition,
} from '@/models/chargeParks.model';
import {
  fetchChargeParks,
  fetchChargeParksForOrganisation,
  createChargePark,
  editChargePark,
  editSingleChargePark,
  fetchSingleChargePark,
  fetchDays,
  fetchAccessTypes,
  fetchPaymentMethods,
  fetchStationTypes,
} from '@/stores/admin/chargeParks/chargeParks.api';
import { useOrganisationsStore } from '@/stores/admin/organizations/organisations.store';
import { useCountriesStore } from '../countries/countries.store';
import { fetchSystemsByChargePark } from '../hardwareSystems/hardwareSystems.api';
import type { Country } from '@/models/countries.model';
import type { IdName } from '@/models/common.model';
import type { WeekdayNumber } from '@/models/tariffs.model';

interface ChargeParksState {
  chargeParks: ChargePark[];
  chargeParkModalIsOpen: Boolean;
  chargeParkIsEditing: Boolean;
  chargeParkEditionData: ChargePark;
  chargeParkDetailsId: number;
  chargeParkDetailsEditionData: ChargeParkDetailsEdition;
  chargeParkOrganisation: {
    id: number;
    name: string;
  };
  daysOfWeek: IdName<WeekdayNumber>[];
  ruleEditingData: {
    index: number;
    days: number[];
    time: string;
  };
  accessTypes: {
    id: number;
    name: string;
  }[];
  paymentMethods: {
    id: number;
    name: string;
  }[];
  stationTypes: {
    id: number;
    name: string;
  }[];
}

export const useChargeParksStore = defineStore('chargeParks', {
  state: (): ChargeParksState => ({
    chargeParks: [],
    chargeParkModalIsOpen: false,
    chargeParkIsEditing: false,
    chargeParkEditionData: {
      id: 0,
      name: '',
      country_code: '',
      latitude: '',
      longitude: '',
      owner_organisation_id: 0,
      owner_organisation_name: '',
      settings_via_cloud_allowed: 0,
      updates_via_cloud_allowed: 0,
      commands_via_cloud_allowed: 0,
      address: '',
      contact_phone: '',
      contact_name: '',
      contact_email: '',
      access_type: 0,
      access_times: [],
      payment_methods: [],
      number_of_systems: 0,
      number_of_systems_by_product_type: {},
      operator_name: '',
      operator_address: '',
      custom_receipt_message: '',
      playlist: {
        id: 0,
        name: '',
      },
      tariff: {
        id: 0,
        name: '',
      },
      branding: null,
      station_type: null,
    },
    chargeParkDetailsId: 0,
    chargeParkDetailsEditionData: {
      name: '',
      country_code: '',
      latitude: '',
      longitude: '',
      owner_organisation_id: 0,
      settings_via_cloud_allowed: 0,
      updates_via_cloud_allowed: 0,
      commands_via_cloud_allowed: 0,
      address: '',
      contact_phone: '',
      contact_name: '',
      contact_email: '',
      access_times: [],
      operator_name: '',
      custom_receipt_message: '',
      station_type: null,
    },
    chargeParkOrganisation: {
      id: 0,
      name: '',
    },
    daysOfWeek: [],
    ruleEditingData: {
      index: 0,
      days: [],
      time: '',
    },
    accessTypes: [],
    paymentMethods: [],
    stationTypes: [],
  }),
  getters: {
    chargeParkCountry(): Country | undefined {
      const countriesStore = useCountriesStore();
      return countriesStore.countries.find(
        (country: Country) => country.country_code === this.chargeParkEditionData.country_code
      );
    },
  },
  actions: {
    async fetchChargeParkList() {
      return await fetchChargeParks()
        .then((response) => {
          this.chargeParks = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async fetchChargeParkListForOrganisation(organisationId: number) {
      return await fetchChargeParksForOrganisation(organisationId)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async createNewChargePark(data: ChargeParkEdit) {
      return await createChargePark(data)
        .then((response) => {
          const organisationsStore = useOrganisationsStore();
          organisationsStore.fetchChargeParksForSingleOrganisation(data.owner_organisation_id);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async updateChargePark(data: ChargeParkEdit) {
      return await editChargePark(this.chargeParkEditionData.id, data)
        .then((response) => {
          const organisationsStore = useOrganisationsStore();
          organisationsStore.fetchChargeParksForSingleOrganisation(data.owner_organisation_id);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async editChargeParkDetails(id: number, data: ChargeParkDetailsEdition) {
      return await editSingleChargePark(id, data)
        .then((response) => {
          fetchSystemsByChargePark(id);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async fetchChargePark(id: number) {
      return await fetchSingleChargePark(id)
        .then((response) => {
          this.chargeParkEditionData = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async fetchDaysList() {
      return await fetchDays()
        .then((response) => {
          this.daysOfWeek = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async fetchAccessTypeList() {
      return await fetchAccessTypes()
        .then((response) => {
          this.accessTypes = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async fetchPaymentMethodsList() {
      return await fetchPaymentMethods()
        .then((response) => {
          this.paymentMethods = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async fetchStationTypesList() {
      return await fetchStationTypes()
        .then((response) => {
          this.stationTypes = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    resetChargeParkEditionData() {
      this.chargeParkEditionData = {
        id: 0,
        name: '',
        country_code: '',
        latitude: '',
        longitude: '',
        owner_organisation_id: 0,
        owner_organisation_name: '',
        settings_via_cloud_allowed: 0,
        updates_via_cloud_allowed: 0,
        commands_via_cloud_allowed: 0,
        address: '',
        contact_phone: '',
        contact_name: '',
        contact_email: '',
        access_type: 0,
        access_times: [],
        payment_methods: [],
        number_of_systems: 0,
        number_of_systems_by_product_type: {},
        operator_name: '',
        operator_address: '',
        custom_receipt_message: '',
        station_type: null,
        playlist: {
          id: 0,
          name: '',
        },
        tariff: {
          id: 0,
          name: '',
        },
        branding: null,
      };
    },
  },
});
