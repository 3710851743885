<template>
  <Message
    v-if="notAuthorized"
    data-cy="whitelist-chargers-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}</Message
  >
  <DataTable
    v-else
    data-cy="whitelist-chargers-table"
    :value="whitelistDetails.systems"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    filterDisplay="menu"
    v-model:selection="selectedChargers"
    :globalFilterFields="['name', 'custom_id', 'charge_park_name', 'charge_park_city', 'nidec_id']"
    dataKey="id"
    class="p-datatable-sm mb-5 mt-2 border-rounded overflow-hidden"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="
              $t('searchBy', {
                name: `${$t('name')}, ${$t('hardwareSystem.customId')}, ${$t('chargePark.title')}`,
              })
            "
            class="p-inputtext-sm"
          />
        </span>
        <div v-if="activeRoles" class="flex justify-end">
          <Button
            data-cy="whitelist-remove-selected-chargers-button"
            :label="$t('removeSelected')"
            icon="pi pi-times"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            :disabled="selectedChargers.length === 0"
            @click="removeSelectedChargers"
            :loading="loading"
          />
          <Button
            data-cy="open-add-chargers-modal"
            :label="$t('chargePark.addChargers')"
            icon="pi pi-plus"
            class="p-button-primary p-button-sm mr-2"
            @click="openAddChargersModal"
          />
        </div>
      </div>
    </template>
    <template #empty>
      <span data-cy="whitelist-chargers-table-empty" class="w-full block text-center">{{
        $t('whitelist.tableEmpty')
      }}</span>
    </template>
    <Column class="no-top-border-row" selectionMode="multiple" headerStyle="width: 3rem"></Column>
    <Column
      class="no-top-border-row"
      field="name"
      :header="$t('name')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 15rem"
    >
      <template #body="{ data }">
        <span :id="data.id" data-cy="whitelist-charger-name">{{ data.name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="charge_park_name"
      :header="$t('site')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 12rem"
    >
      <template #body="{ data }">
        <div v-if="data.charge_park_country_code" class="flex">
          <Country :country-code="data.charge_park_country_code" nameClass="country">
            <template #title>
              <span
                :id="data.id"
                data-cy="whitelist-charge-park-name"
                class="block charge-park-name"
                >{{ data.charge_park_name }}</span
              >
            </template>
            <template #city>
              <span data-cy="whitelist-charger-city" class="pr-1 city"
                >{{ data.charge_park_city }},
              </span>
            </template>
          </Country>
          <!-- <Button
            data-cy="whitelist-open-charger-view-button"
            class="p-button-rounded p-button-secondary p-button-text ml-2"
            icon="pi pi-external-link"
          /> -->
        </div>
        <span v-else class="empty">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('chargePark.title') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="nidec_id"
      :header="$t('hardwareSystem.model')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 12rem"
    >
      <template #body="{ data }">
        <span :id="data.id" data-cy="whitelist-nidec-id">{{ data.nidec_id }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('hardwareSystem.nidecId') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="custom_id"
      :header="$t('hardwareSystem.customId')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 16rem"
    >
      <template #body="{ data }">
        <span :id="data.id" data-cy="whitelist-custom-id">{{ data.custom_id }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('hardwareSystem.customId') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="added_at"
      :header="$t('whitelist.addedToList')"
      :sortable="true"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <span v-if="data.added_at" data-cy="whitelist-added-to-list">{{
          dayjs(data.added_at).format('ll')
        }}</span>
        <span v-else class="empty">--</span>
      </template>
    </Column>
    <Column
      v-if="activeRoles"
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }">
        <div class="flex">
          <Button
            data-cy="remove-charger-from-whitelist"
            :label="$t('remove')"
            icon="pi pi-times"
            class="p-button-outlined p-button-plain p-button-sm"
            @click="removeFromWhitelist(data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <WhitelistEditModal />
  <WhitelistAddChargersModal />
  <Toast
    data-cy="remove-chargers-from-whitelist-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="remove-chargers"
  >
    <template v-if="isFailed" #message="slotProps">
      <div class="flex flex-col w-full pt-1">
        <div class="w-full flex">
          <span class="p-toast-message-icon pi pi-info-circle"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
          </div>
        </div>
        <List
          :data="removeFailedIds"
          :title="$t('whitelist.failedRemovalChargersIds')"
          class="w-full my-5"
        />
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useWhitelistsStore } from '@/stores/admin/whitelists/whitelists.store';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useUsersStore } from '@/stores/admin/users/users.store';
import {
  WHITELIST_ADMIN_ROLE,
  ADMINISTRATOR_ROLE,
  DEFAULT_TOAST_LIFE_MILLISECONDS,
} from '@/utils/constants';

import dayjs from 'dayjs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import WhitelistEditModal from '@/components/whitelists/WhitelistEditModal.vue';
import Country from '@/components/common/countries/Country.vue';
import WhitelistAddChargersModal from './WhitelistAddChargersModal.vue';
import List from '@/components/common/List.vue';

import type { WhitelistId } from '@/models/whitelists.model';
import type { HardwareSystem } from '@/models/hardwareSystems.model';

export default {
  name: 'WhitelistChargers',

  components: {
    DataTable,
    Column,
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    InputText,
    WhitelistEditModal,
    Message,
    Country,
    WhitelistAddChargersModal,
    Toast,
    List,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const whitelistsStore = useWhitelistsStore();
    const { whitelistDetails, whitelistAddChargersModalIsOpen } = storeToRefs(whitelistsStore);
    const loading = ref(false);
    const notAuthorized = ref(false);
    const selectedChargers = ref<HardwareSystem[]>([]);
    const isBatch = computed(() => (selectedChargers.value.length > 1 ? true : false));
    const isFailed = ref(false);
    const removeFailedIds = ref<number[]>([]);
    const userStore = useUsersStore();

    const activeRoles = computed(
      () =>
        userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
        userStore.me.roles.map((role) => role.id).includes(WHITELIST_ADMIN_ROLE)
    );

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      charge_park_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      charge_park_city: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      custom_id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      nidec_id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });

    const matchModes = ref([
      { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
      { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
      { label: 'Contains', value: FilterMatchMode.CONTAINS },
    ]);

    const openAddChargersModal = () => {
      whitelistAddChargersModalIsOpen.value = true;
    };

    const successSummary = computed(() => {
      return isBatch.value
        ? t('toast.successBatch', {
            name: t('chargers'),
            action: t('toast.removed'),
          })
        : t('toast.success', {
            name: t('hardwareSystem.charger'),
            action: t('toast.removed'),
          });
    });

    const errorSummary = computed(() => {
      return isBatch.value
        ? t('toast.errorBatch', {
            action: t('toast.removal'),
            name: t('chargers'),
          })
        : t('toast.error', {
            action: t('toast.removal'),
            name: t('hardwareSystem.charger'),
          });
    });

    const showSuccess = () => {
      toast.add({
        severity: 'success',
        summary: successSummary.value,
        life: DEFAULT_TOAST_LIFE_MILLISECONDS,
        group: 'remove-chargers',
      });
    };

    const showError = () => {
      toast.add({
        severity: 'error',
        summary: errorSummary.value,
        group: 'remove-chargers',
      });
    };

    const removeFromWhitelist = async (id: number) => {
      const chargerId = {
        id: id,
      };

      await whitelistsStore
        .removeChargersFromWhitelist([chargerId])
        .then((response: any) => {
          if (response.status === 204) {
            showSuccess();
            selectedChargers.value = [];
          }
        })
        .catch((error) => {
          showError();
          throw new Error(error);
        });
    };

    const removeSelectedChargers = async () => {
      const ids = selectedChargers.value.map((item) => ({ id: item.id }));

      loading.value = true;

      await whitelistsStore
        .removeChargersFromWhitelist(ids)
        .then((response: any) => {
          loading.value = false;
          if (response.status === 204) {
            showSuccess();
            selectedChargers.value = [];
          }
        })
        .catch((error) => {
          whitelistsStore.fetchWhitelistDetails(whitelistDetails.value.id);
          if (error.response.status === 400 && error.response.data.failed_items) {
            removeFailedIds.value = error.response.data.failed_items.map(
              (item: WhitelistId) => item.id
            );
            isFailed.value = true;
            showError();
          } else {
            showError();
          }
          loading.value = false;
          throw new Error(error);
        });
    };

    onMounted(
      async () =>
        await whitelistsStore.fetchAllWhitelists().catch((error) => {
          if (error.response.status === 403) {
            notAuthorized.value = true;
          } else {
            throw new Error('Whitelist Chargers failed to be fetched');
          }
        })
    );

    return {
      whitelistDetails,
      filters,
      loading,
      matchModes,
      removeSelectedChargers,
      openAddChargersModal,
      removeFromWhitelist,
      notAuthorized,
      selectedChargers,
      dayjs,
      Toast,
      isFailed,
      removeFailedIds,
      activeRoles,
    };
  },
};
</script>

<style lang="scss" scoped>
.charge-park-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $text-dark;
}
:deep(.country),
.city {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $grey-dark;
}
</style>
