import { defineStore } from 'pinia';
import * as api from './dashboard.api';
import type { Panel } from '@/stores/admin/dashboard/dashboard.types';

export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    panel: null as Panel | null,
    isInitialized: false,
  }),
  actions: {
    async init() {
      this.panel = await api.loadPanel();
      this.isInitialized = true;
    },
  },
});
