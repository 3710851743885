<template>
  <div class="site-location">
    <PanelWithEdit
      :title="$t('location')"
      :loading="isLoading"
      :isEditing="isEditing"
      :isNotValid="editFormIsNotValid"
      @onEdit="isEditing = true"
      @onClose="closeEditing"
      @onApply="editSite"
    >
      <div v-if="!isEditing" class="map-container">
        <l-map
          data-cy="charge-park-map"
          ref="map"
          :use-global-leaflet="false"
          :zoom="9"
          :center="[
            Number(chargeParkEditionData.latitude),
            Number(chargeParkEditionData.longitude),
          ]"
          :options="options"
        >
          <l-control-attribution position="bottomright" prefix="Leaflet | OpenStreetMap" />
          <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            layer-type="base"
            name="OpenStreetMap"
          />
          <l-marker
            :visible="!!chargeParkDetails.latitude && !!chargeParkDetails.longitude"
            :lat-lng="[Number(chargeParkDetails.latitude), Number(chargeParkDetails.longitude)]"
          ></l-marker>
        </l-map>
      </div>
      <Location
        v-if="isEditing"
        @coordinates="setCoordinates"
        @onValidateAddress="validateAddress"
        @onValidateLatitude="validateLatitude"
        @onValidateLongitude="validateLongitude"
        :resetValidation="resetLocationValidation"
        :address="chargeParkDetails.address"
      />
      <CountriesSelect
        v-if="isEditing"
        data-cy="charge-park-country-select"
        class="w-full my-3"
        :country="selectedChargeParkCountry"
        vertical
        @onCountrySelect="setSelectedCountry"
        aria-describedby="countrySelect-help"
      />
      <ChargeParkLocationPreview v-if="!isEditing" :chargePark="chargeParkEditionData" />
    </PanelWithEdit>
    <Toast
      data-cy="charge-park-location-toast"
      position="top-center"
      errorIcon="pi pi-info-circle"
      group="chargeParkLocation"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { chargeParkLocationRules } from '@/utils/chargeParkDetailsRules';
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_TOAST_LIFE_MILLISECONDS,
} from '@/utils/constants';
import { LMap, LTileLayer, LControlAttribution, LMarker } from '@vue-leaflet/vue-leaflet';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import ChargeParkLocationPreview from './ChargeParkLocationPreview.vue';
import Toast from 'primevue/toast';
import Location from '@/components/common/Location.vue';
import CountriesSelect from '@/components/common/countries/CountriesSelect.vue';

import type { MapOptions } from 'leaflet';

const { t } = useI18n();
const toast = useToast();
const chargeParksStore = useChargeParksStore();
const { chargeParkEditionData, chargeParkIsEditing } = storeToRefs(chargeParksStore);
const systemsStore = useHardwareSystemsStore();
const chargeParkDetails = ref({
  latitude: chargeParkEditionData.value.latitude,
  longitude: chargeParkEditionData.value.longitude,
  country_code: chargeParkEditionData.value.country_code,
  address: chargeParkEditionData.value.address,
});

const validationData = ref();
const address = ref('');
const resetLocationValidation = ref(false);

const latitude = ref<number>(DEFAULT_LATITUDE);
const longitude = ref<number>(DEFAULT_LONGITUDE);

const selectedChargeParkCountry = computed(() => chargeParksStore.chargeParkCountry);

const isLoading = ref(false);
const isEditing = ref(false);

const options: MapOptions = {
  attributionControl: false,
};

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('chargePark.title'),
      action: t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'chargeParkLocation',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: t('toast.edition'),
      name: t('chargePark.title'),
    }),
    group: 'chargeParkLocation',
  });
};

const editFormIsNotValid = computed(() => {
  return (
    !v$.value.$anyDirty ||
    chargeParkDetails.value.country_code === null ||
    chargeParkDetails.value.country_code === '' ||
    chargeParkDetails.value.address === '' ||
    (validationData.value?.address.$anyDirty && validationData.value?.address.$error) ||
    (validationData.value?.latitude.$anyDirty && validationData.value?.latitude.$error) ||
    (validationData.value?.longitude.$anyDirty && validationData.value?.longitude.$error)
  );
});

const resetForm = () => {
  chargeParkDetails.value.latitude = chargeParkEditionData.value.latitude;
  chargeParkDetails.value.longitude = chargeParkEditionData.value.longitude;
  chargeParkDetails.value.country_code = chargeParkEditionData.value.country_code;
  chargeParkDetails.value.address = chargeParkEditionData.value.address;
  v$.value.latitude.$reset();
  v$.value.longitude.$reset();
  v$.value.country_code.$reset();
  v$.value.address.$reset();
  v$.value.$reset();
};

const closeEditing = () => {
  isEditing.value = false;
  resetForm();
};

const setSelectedCountry = (countryCode: string) => {
  chargeParkDetails.value.country_code = countryCode;

  if (chargeParkDetails.value.country_code === null) {
    v$.value.country_code.$validate();
  }
};

const validateAddress = async (geoAddress: string, validation: any) => {
  chargeParkDetails.value.address = geoAddress;
  address.value = geoAddress;
  validationData.value = validation;
  console.log('validationData.value', validationData.value);
};

const validateLatitude = async (latitude: string, validation: any) => {
  chargeParkDetails.value.latitude = latitude;
  validationData.value = validation;
};

const validateLongitude = async (longitude: string, validation: any) => {
  chargeParkDetails.value.longitude = longitude;
  validationData.value = validation;
};

const setCoordinates = (lat: number, lng: number, validation: any) => {
  chargeParkDetails.value.latitude = lat.toString();
  chargeParkDetails.value.longitude = lng.toString();
  latitude.value = lat;
  longitude.value = lng;
  validationData.value = validation;
};

const editSite = () => {
  chargeParksStore
    .editChargeParkDetails(chargeParkEditionData.value.id, chargeParkDetails.value)
    .then((response: any) => {
      chargeParksStore.fetchChargePark(chargeParkEditionData.value.id).then((response) => {
        const { latitude, longitude, country_code, address } = response.data;
        chargeParkDetails.value.latitude = latitude;
        chargeParkDetails.value.longitude = longitude;
        chargeParkDetails.value.country_code = country_code;
        chargeParkDetails.value.address = address;
      });
      if (response.status === 200) {
        systemsStore.fetchSystemsByChargeParkId(chargeParkEditionData.value.id);
        toast.removeGroup('chargeParkLocation');
        showSuccess();
        isEditing.value = false;
      }
    })
    .catch((error) => {
      toast.removeGroup('chargeParkLocation');
      showError();
      throw new Error(error);
    });
};

watch(
  () => chargeParkDetails.value.country_code,
  (oldVal: string, newVal: string) => {
    if (newVal === '') {
      v$.value.country_code.$reset();
    }
    if (newVal !== '') {
      v$.value.country_code.$touch();
    }
  }
);

watch(
  () => isEditing.value,
  (value) => {
    chargeParkIsEditing.value = value;
  },
  { immediate: true }
);

const rules = computed(() => chargeParkLocationRules);

const v$ = useVuelidate(rules, chargeParkDetails.value);
</script>
<style scoped lang="scss">
.map-container {
  height: 192px;
  width: auto;
  margin-top: 15px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 4px;
}
</style>
