<template>
  <div class="px-12">
    <div class="field flex justify-between pt-4 pb-4">
      <label id="tariffNameLabel" for="tariffName" class="title font-medium text-gray-600">
        {{ $t('name') }}<span class="required">*</span>
      </label>
      <div>
        <InputText
          data-cy="tariff-configuration-name-input"
          id="tariffName"
          type="text"
          aria-describedby="tariffNameLabel"
          class="p-inputtext-sm w-[300px] text-right"
          v-model="tariffModalData.name"
        />
        <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
          <small class="p-error">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="field flex justify-between">
      <label for="contactType" class="mb-2 field-edit-label"
        >{{ $t('tariff.tariffDetails.currency') }}<span class="required">*</span></label
      >
      <Dropdown
        data-cy="tariff-currency-select"
        id="currency"
        v-model="selectedCurrency"
        :options="currencyOptions"
        :placeholder="$t('select', { name: $t('tariff.tariffDetails.currency') })"
        class="p-column-filter mb-2 w-[300px] text-right"
        optionLabel="name"
        @change="setSelectedCurrency"
      >
      </Dropdown>
    </div>
    <div class="field flex justify-between">
      <label for="contactType" class="mb-2 field-edit-label">{{
        $t('tariff.tariffDetails.currencySymbolPosition')
      }}</label>
      <Dropdown
        data-cy="tariff-currency-symbol-position-select"
        id="symbol-position"
        v-model="selectedCurrencySymbolPosition"
        :options="positionOptions"
        :placeholder="$t('select', { name: $t('tariff.position') })"
        class="p-column-filter mb-2 w-[300px] text-right"
        optionLabel="name"
        @change="setSelectedCurrencySymbolPosition"
      >
      </Dropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';
import { storeToRefs } from 'pinia';
import { onMounted, watch, ref, computed } from 'vue';
import { normalizeCurrency, normalizeSelectedCurrency } from '@/utils/currency';
import { LEFT, RIGHT } from '@/utils/constants';

import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';

import type { Validation } from '@vuelidate/core';
import type { tariffRules } from '@/utils/tariffModalRules';

const tariffsStore = useTariffsStore();
const { tariffModalData, currencyTypes } = storeToRefs(tariffsStore);

const props = defineProps<{
  v$: Validation<typeof tariffRules>;
}>();

const selectedCurrency = ref();
const selectedCurrencySymbolPosition = ref();
const positionOptions = [
  {
    id: 0,
    name: 'Left',
    value: LEFT,
  },
  {
    id: 1,
    name: 'Right',
    value: RIGHT,
  },
];

const currencyOptions = computed(() => {
  return currencyTypes.value.map((item) => normalizeCurrency(item));
});

const setSelectedCurrency = (data: any) => {
  tariffModalData.value.currency = data.value.id;
  tariffModalData.value.currency_symbol = data.value.symbol;
  props.v$.currency.$touch();
};

const setSelectedCurrencySymbolPosition = (data: any) => {
  tariffModalData.value.currency_symbol_position = data.value.value;
};

const setDefaultCurrencySymbolPosition = () => {
  if (tariffModalData.value.currency_symbol_position === '') {
    selectedCurrencySymbolPosition.value = positionOptions[1];
    tariffModalData.value.currency_symbol_position = positionOptions[1].value;
  } else {
    selectedCurrencySymbolPosition.value = positionOptions.find(
      (position) => position.value === tariffModalData.value.currency_symbol_position
    );
  }
};

watch(
  () => tariffModalData.value.name,
  () => {
    props.v$.name.$touch();
  },
  { deep: true }
);

onMounted(() => {
  tariffsStore.fetchCurrencyList().then(() => {
    if (tariffModalData.value.currency) {
      selectedCurrency.value = normalizeSelectedCurrency(
        currencyTypes.value,
        tariffModalData.value.currency
      );
    }
  });
  setDefaultCurrencySymbolPosition();
});
</script>
<style scoped lang="scss">
:deep(.p-dropdown-label) {
  padding: 9px 0 9px 9px;
}
</style>
