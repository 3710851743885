<template>
  <div :class="`flex ${vertical ? 'flex-wrap justify-between' : 'flex-col'}`">
    <label for="countries" class="text-sm font-medium mb-2"
      >{{ $t('country.title') }}<span class="required">*</span></label
    >
    <Dropdown
      v-model="selectedCountry"
      :options="countries"
      optionLabel="name"
      :filter="true"
      :placeholder="$t('select', { name: $t('country.title') })"
      :showClear="true"
      id="countries"
      aria-describedby="chargeParkName-help"
      :class="`${vertical ? 'w-1/2' : 'w-full'}`"
    >
      <template #value="slotProps">
        <div class="flex" v-if="slotProps.value">
          <CountryIcon :country-iso="slotProps.value.iso_code_alpha_3" class="icon mr-2" />
          <div class="truncate ...">{{ slotProps.value.name }}</div>
        </div>
        <span v-else>
          {{ slotProps.placeholder }}
        </span>
      </template>
      <template #option="slotProps">
        <div data-cy="charge-park-country-select-option" class="flex">
          <CountryIcon :country-iso="slotProps.option.iso_code_alpha_3" class="icon mr-2" />
          <div>{{ slotProps.option.name }}</div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, watch } from 'vue';
import type { PropType } from 'vue';
import Dropdown from 'primevue/dropdown';
import CountryIcon from '@/components/common/countries/CountryIcon.vue';
import { useCountriesStore } from '@/stores/admin/countries/countries.store';
import { storeToRefs } from 'pinia';
import type { Country } from '@/models/countries.model';

export default {
  components: {
    Dropdown,
    CountryIcon,
  },
  props: {
    country: {
      type: Object as PropType<Country | null>,
      default: () => {},
    },
    vertical: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const countriesStore = useCountriesStore();
    const { countries } = storeToRefs(countriesStore);
    const selectedCountry = ref<Country | null>(null);

    watch(
      () => selectedCountry.value,
      (value) => {
        if (value && value.country_code) {
          emit('onCountrySelect', value.country_code);
        } else {
          emit('onCountrySelect', null);
        }
      }
    );

    onMounted(async () => {
      await countriesStore.fetchCountriesList();

      if (props.country) {
        selectedCountry.value = props.country;
      }
    });

    return {
      selectedCountry,
      countries: countries,
    };
  },
};
</script>

<style scoped lang="scss">
:deep(.p-dropdown-label) {
  padding: 10px;
}
:deep(.p-placeholder) {
  > span {
    font-size: 14px;
    color: #9ca3af;
  }
}
.icon {
  width: 17px;
}
</style>
