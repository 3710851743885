import type { Feature } from './organisation.model';
import type { TimeSystem, UnitSystem } from '@/models/datatypes.model';

export enum UserStatus {
  INVITED = 1,
  ACTIVE = 2,
  DELETED = 3,
}

export interface User {
  id: number;
  created_at: string;
  name: string;
  email: string;
  organisation_id: number;
  created_by_user_id: number;
  roles: UserRole[];
  status: UserStatus;
  deleted_at?: string;
}

export interface UserEdit {
  name: string;
  email: string;
  organisation_id: number;
  roles?: number[];
}

export interface UserEditData {
  id: number;
  name: string;
  email: string;
  organisation_id: number;
  roles: UserRole[];
}

export interface UserDelete {
  id: number;
  organisation_id: number;
}

export interface UserMe {
  id: number;
  name: string;
  email: string;
  organisation_id: number;
  active_features: Feature[];
  roles: UserRole[];
  status: UserStatus;
  units: UnitSystem;
  local_timezone: string;
  time_format: TimeSystem;
}

export interface UserRole {
  id: number;
  name: string;
  description: string;
}

export interface UserRoleId {
  id: number;
}
