import axios from 'axios';
import type { AxiosPromise, AxiosResponse } from 'axios';
import type { RfidTagEdit, RfidTagDelete, RfidTagEditData } from '@/models/rfidTags.model';

export function fetchRfidTags(): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/rfidtags/`, {
      headers: { uid: 1 },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function createRfidTag(data: RfidTagEdit): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/rfidtags/`, data, {
      headers: { uid: 1 },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function editRfidTag(data: RfidTagEditData[]): Promise<AxiosResponse> {
  return axios
    .put<AxiosPromise>(
      `/api/rfidtags/batch/`,
      { items: data },
      {
        headers: {
          uid: 1,
        },
      }
    )
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function removeRfidTag(ids: RfidTagDelete[]): Promise<AxiosResponse> {
  return axios
    .delete<AxiosPromise>(`/api/rfidtags/batch/`, {
      headers: { uid: 1 },
      data: { items: ids },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function fetchFilteredRfidTags(id: number): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/rfidtags/?exclude_whitelist=${id}`, {
      headers: { uid: 1 },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}
