export const USERS = 'users';
export const CHARGE_PARKS = 'chargeParks';
export const CREATE = 'create';
export const EDIT = 'edit';
export const ASSIGN = 'assign';
export const UNASSIGN = 'unassign';
export const DELEGATE = 'delegate';
export const HORIZONTAL = 'horizontal';
export const VERTICAL = 'vertical';
export const ENABLE = 'enable';
export const DISABLE = 'disable';
export const FILE = 'file';
export const UPDATE = 'update';
export const VERIFY = 'verify';
export const RESEND = 'resend';

export enum Feature {
  WHITELIST = 1,
  SITES = 2,
  ADMINISTRATION = 3,
  SUBORGANISATIONS = 4,
  DASHBOARD = 5,
  CONTENT_MANAGEMENT = 6,
  ACTIVE_MONITORING = 7,
  REPORTS = 8,
  TARIFFS = 9,
  DATA_EXPORTS = 10,
  CUSTOM_UI = 11,
}

export enum Role {
  ADMINISTRATOR = 1,
  ASSET_VIEWER = 2,
  ASSET_OPERATOR = 3,
  ASSET_MANAGER = 4,
  WHITELIST_VIEWER = 5,
  UPDATE_ADMIN = 6,
  WHITELIST_ADMIN = 7,
  DASHBOARD_ADMIN = 8,
  USER_ADMIN = 9,
  ORGANISATION_ADMIN = 10,
  CONTENT_ADMIN = 11,
  ACTIVE_MONITORING_ADMIN = 12,
  REPORT_ADMIN = 13,
  TARIFF_ADMIN = 14,
}

export const roleSuperSet: Partial<Record<Role, Role[]>> = {
  [Role.WHITELIST_VIEWER]: [Role.WHITELIST_ADMIN],
};

export const ADMINISTRATOR_ROLE = 1;
export const ASSET_VIEWER_ROLE = 2;
export const ASSET_OPERATOR_ROLE = 3;
export const ASSET_MANAGER_ROLE = 4;
export const WHITELIST_VIEWER_ROLE = 5;
export const UPDATE_ADMIN_ROLE = 6;
export const WHITELIST_ADMIN_ROLE = 7;
export const DASHBOARD_ADMIN_ROLE = 8;
export const USER_ADMIN_ROLE = 9;
export const ORGANISATION_ADMIN_ROLE = 10;
export const CONTENT_ADMIN_ROLE = 11;
export const ACTIVE_MONITORING_ADMIN_ROLE = 12;
export const REPORTS_ADMIN_ROLE = 13;
export const TARIFFS_ADMIN_ROLE = 14;

export const MAX_LENGTH = 100;
export const DEFAULT_EVENT_HISTORY_PAGE_SIZE = 50;
export const DEFAULT_HARDWARE_SYSTEMS_PAGE_SIZE = 50;

export const DEFAULT_LATITUDE = 52.520008;
export const DEFAULT_LONGITUDE = 13.404954;

export const INSTANT_REPORT = 'instant';
export const PERIODIC_REPORT = 'periodic';

export const DEFAULT_TOAST_LIFE_MILLISECONDS = 5000;

export const LEFT = 'LEFT';
export const RIGHT = 'RIGHT';
export const TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER = 100;
export const THREE_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER = 1000;
export const TWO_DIGITS = 2;
export const THREE_DIGITS = 3;
