<template>
  <div class="system-info">
    <PanelWithEdit
      :title="$t('info')"
      :loading="isLoading"
      :isEditing="isEditing"
      :isNotValid="editFormIsNotValid"
      :showFooter="showFooter"
      :isError="isError"
      :errorMessage="errorMessage"
      @onEdit="isEditing = true"
      @onClose="closeEditing"
      @onApply="editSite"
    >
      <div class="flex justify-between my-2">
        <div v-if="isEditing" class="flex flex-col w-1/2">
          <div v-for="(value, key) in hardwareSystemDetails" :key="key">
            <span
              data-cy="system-info-field-name"
              class="block font-medium py-3 whitespace-nowrap"
              v-if="key != 'service_started_at' && key != 'service_ended_at'"
              >{{
                key === 'name' ? $t('name') : $t(`hardwareSystem.hardwareSystemDetails.${key}`)
              }}</span
            >
            <div
              v-if="key === 'nidec_id'"
              class="w-full border-t border-t-[var--gray-200] my-3 whitespace-nowrap"
            ></div>
          </div>
        </div>
        <div v-if="isEditing" class="flex flex-col w-1/2">
          <div class="my-1">
            <InputText
              data-cy="panel-item-input"
              type="text"
              v-model="singleSystem.manufacturer"
              class="p-column-filter p-2 w-full input-disabled"
              disabled
            />
          </div>
          <div class="my-1">
            <InputText
              data-cy="panel-item-input"
              type="text"
              v-model="singleSystem.model"
              class="p-column-filter p-2 w-full input-disabled"
              disabled
            />
          </div>
          <div class="mt-[3px]">
            <InputText
              data-cy="panel-item-input"
              type="text"
              v-model="singleSystem.nidec_id"
              class="p-column-filter p-2 w-full input-disabled"
              disabled
            />
          </div>
          <div class="w-full border-t border-t-[var--gray-200] mt-3 mb-2"></div>
          <div class="my-1">
            <InputText
              data-cy="panel-item-input"
              type="text"
              v-model="singleSystem.owner_organisation_name"
              class="p-column-filter p-2 w-full input-disabled"
              disabled
            />
          </div>
          <div class="my-1">
            <InputText
              data-cy="system-name-input"
              type="text"
              v-model="hardwareSystemDetails.name"
              class="p-column-filter p-2 w-full"
              :placeholder="$t('name')"
              @keyup="validateInput('name')"
            />
            <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
              <small class="p-error">{{ error.$message }}</small>
            </div>
          </div>
          <div class="my-1">
            <InputText
              data-cy="system-custom-id-input"
              type="text"
              v-model="hardwareSystemDetails.custom_id"
              class="p-column-filter p-2 w-full"
              :placeholder="$t('chargePark.chargeParkDetails.custom_id')"
              @keyup="validateInput('custom_id')"
            />
            <div class="input-errors" v-for="(error, index) of v$.custom_id.$errors" :key="index">
              <small class="p-error">{{ error.$message }}</small>
            </div>
          </div>
        </div>

        <HardwareSystemInfoPreview v-if="!isEditing" :system="singleSystem" />
      </div>
      <DateTimeRangeInput
        v-if="isEditing"
        ref="dateTimeRangeInput"
        :startedValueId="'service_started_at'"
        :endedValueId="'service_ended_at'"
        :startedValueLocale="$t(`hardwareSystem.hardwareSystemDetails.service_started_at`)"
        :endedValueLocale="$t(`hardwareSystem.hardwareSystemDetails.service_ended_at`)"
        :startedAfterEndedErrorLocale="$t('hardwareSystem.errors.startedAt')"
        v-model:startedValue="hardwareSystemDetails.service_started_at"
        v-model:endedValue="hardwareSystemDetails.service_ended_at"
        allowNoStarted
        allowNoEnded
      />
    </PanelWithEdit>
    <Toast
      data-cy="charge-park-info-toast"
      position="top-center"
      errorIcon="pi pi-info-circle"
      group="hardwareSystemInfo"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';

import { hardwareSystemInfoRules } from '@/utils/hardwareSystemDetailsRules';

import DateTimeRangeInput from '@/components/common/time/DateTimeRangeInput.vue';
import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import HardwareSystemInfoPreview from './HardwareSystemInfoPreview.vue';

import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

const { t } = useI18n();
const toast = useToast();
const route = useRoute();
const systemsStore = useHardwareSystemsStore();
const { singleSystem } = storeToRefs(systemsStore);
const isError = ref(false);
const errorMessage = ref<string | null>(null);
const hardwareSystemDetails = ref({
  manufacturer: '',
  model: '',
  nidec_id: '',
  owner_organisation_name: '',
  name: '',
  custom_id: '',
  service_started_at: '' as string | null,
  service_ended_at: '' as string | null,
});

const isLoading = ref(false);
const isEditing = ref(false);

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('hardwareSystem.title'),
      action: t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'hardwareSystemInfo',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: t('toast.edition'),
      name: t('hardwareSystem.title'),
    }),
    group: 'hardwareSystemInfo',
  });
};

const editFormIsNotValid = computed(() => {
  return (
    !v$.value.$anyDirty ||
    v$.value.$invalid ||
    (v$.value.custom_id.$anyDirty && v$.value.custom_id.$error) ||
    isError.value
  );
});

const resetForm = () => {
  hardwareSystemDetails.value.custom_id = singleSystem.value.custom_id;
  hardwareSystemDetails.value.service_started_at = singleSystem.value.service_started_at;
  hardwareSystemDetails.value.service_ended_at = singleSystem.value.service_ended_at;

  v$.value.custom_id.$reset();
  v$.value.$reset();
};

const closeEditing = () => {
  isEditing.value = false;
  resetForm();
};

const showFooter = ref(true);

const editSite = () => {
  showFooter.value = true;
  isLoading.value = true;
  systemsStore
    .updateHardwareSystem(Number(route.params.id), hardwareSystemDetails.value)
    .then((response: any) => {
      systemsStore.fetchSingleSystem(Number(route.params.id));

      if (response.status === 200) {
        isLoading.value = false;
        toast.removeGroup('hardwareSystemInfo');
        showSuccess();
        closeEditing();
      }
    })
    .catch((error) => {
      toast.removeGroup('hardwareSystemInfo');
      showError();
      isEditing.value = false;
      isLoading.value = false;
      throw new Error(error);
    });
};

watch(
  () => singleSystem.value,
  (value) => {
    hardwareSystemDetails.value.manufacturer = singleSystem.value.manufacturer;
    hardwareSystemDetails.value.model = singleSystem.value.name;
    hardwareSystemDetails.value.nidec_id = singleSystem.value.nidec_id;
    hardwareSystemDetails.value.owner_organisation_name =
      singleSystem.value.owner_organisation_name;
    hardwareSystemDetails.value.name = singleSystem.value.name;
    hardwareSystemDetails.value.custom_id = singleSystem.value.custom_id;
    hardwareSystemDetails.value.service_started_at = singleSystem.value.service_started_at;
    hardwareSystemDetails.value.service_ended_at = singleSystem.value.service_ended_at;
  },
  { immediate: true }
);

const validateInput = async (item: string) => {
  await v$.value[item].$validate();
};

const rules = computed(() => hardwareSystemInfoRules);

const v$ = useVuelidate(rules, hardwareSystemDetails.value, { $scope: true });
</script>
<style scoped lang="scss">
:deep(.p-calendar .p-inputtext) {
  padding: 8px;
}
:deep(.p-button.p-button-icon-only) {
  padding: 8px;
}
</style>
