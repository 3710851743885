<template>
  <div data-cy="session-free-and-tax-rate" class="flex justify-between items-center w-full my-1">
    <span class="font-medium text-gray-600 w-1/2"
      >{{ $t('tariff.tariffDetails.sessionFeeAndTaxRate')
      }}<span class="required font-medium">*</span></span
    >
    <div class="flex items-center w-1/2">
      <InlineLabelNumericInput
        class="mr-3"
        name="session_fee_cent"
        :type="currencySymbol"
        :value="singleRateData.session_fee_cent"
        @update="updateRate"
      />
      <InlineLabelNumericInput
        name="session_fee_tax_percent"
        type="%"
        :value="singleRateData.session_fee_tax_percent"
        @update="updateRate"
      />
    </div>
  </div>
  <div data-cy="energy-cost-and-tax-rate" class="flex justify-between w-full my-1">
    <span class="font-medium text-gray-600"
      >{{ $t('tariff.tariffDetails.energyCostAndTaxRate')
      }}<span class="required font-medium">*</span></span
    >
    <div class="flex w-1/2">
      <InlineLabelNumericInput
        class="mr-3"
        name="price_per_kwh_tenth_cent"
        :type="currencySymbol + ' / kWh'"
        :value="singleRateData.price_per_kwh_tenth_cent"
        :maxFractionDigits="3"
        :minFractionDigits="3"
        @update="updateRate"
      />
      <InlineLabelNumericInput
        name="price_per_kwh_tax_percent"
        type="%"
        :value="singleRateData.price_per_kwh_tax_percent"
        @update="updateRate"
      />
    </div>
  </div>
  <div data-cy="charging-duration-cost-and-tax-rate" class="flex justify-between w-full my-1">
    <span class="font-medium text-gray-600"
      >{{ $t('tariff.tariffDetails.chargingDurationCostAndTaxRate')
      }}<span class="required font-medium">*</span></span
    >
    <div class="flex w-1/2">
      <InlineLabelNumericInput
        class="mr-3"
        name="charging_duration_fee_tenth_cent"
        :type="currencySymbol + ` / ${$t('tariff.tariffDetails.min')}`"
        :value="singleRateData.charging_duration_fee_tenth_cent"
        :maxFractionDigits="3"
        :minFractionDigits="3"
        @update="updateRate"
      />
      <InlineLabelNumericInput
        name="charging_duration_fee_tax_percent"
        type="%"
        :value="singleRateData.charging_duration_fee_tax_percent"
        @update="updateRate"
      />
    </div>
  </div>
  <div data-cy="idle-grace-period" class="flex justify-between w-full my-1">
    <span class="font-medium text-gray-600"
      >{{ $t('tariff.tariffDetails.idleGracePeriod')
      }}<span class="required font-medium">*</span></span
    >
    <div class="flex w-1/2">
      <InlineLabelNumericInput
        name="idle_fee_grace_period_min"
        :type="$t('tariff.minutes')"
        :value="singleRateData.idle_fee_grace_period_min"
        :minFractionDigits="0"
        @update="updateRate"
      />
    </div>
  </div>
  <div data-cy="idle-free-and-tax-rate" class="flex justify-between w-full my-1">
    <span class="font-medium text-gray-600"
      >{{ $t('tariff.tariffDetails.idleFeeAndTaxRate')
      }}<span class="required font-medium">*</span></span
    >
    <div class="flex w-1/2">
      <InlineLabelNumericInput
        class="mr-3"
        name="idle_fee_tenth_cent_per_min"
        :type="currencySymbol + ` / ${$t('tariff.tariffDetails.min')}`"
        :value="singleRateData.idle_fee_tenth_cent_per_min"
        :maxFractionDigits="3"
        :minFractionDigits="3"
        @update="updateRate"
      />
      <InlineLabelNumericInput
        name="idle_fee_tax_percent"
        type="%"
        :value="singleRateData.idle_fee_tax_percent"
        @update="updateRate"
      />
    </div>
  </div>
  <div data-cy="fixed-tax-amount-per-session-energy" class="flex justify-between w-full my-1">
    <span class="font-medium text-gray-600"
      >{{ $t('tariff.tariffDetails.fixedTaxAmountPerSessionEnergy')
      }}<span class="required font-medium">*</span></span
    >
    <div class="flex w-1/2">
      <InlineLabelNumericInput
        name="fixed_tax_session_cent"
        :type="currencySymbol"
        :value="singleRateData.fixed_tax_session_cent"
        @update="updateRate"
      />
    </div>
  </div>
  <div data-cy="fixed-tax-amount-per-session-parking" class="flex justify-between w-full my-1">
    <span class="font-medium text-gray-600"
      >{{ $t('tariff.tariffDetails.fixedTaxAmountPerSessionParking')
      }}<span class="required font-medium">*</span></span
    >
    <div class="flex w-1/2">
      <InlineLabelNumericInput
        name="fixed_tax_parking_cent"
        :type="currencySymbol"
        :value="singleRateData.fixed_tax_parking_cent"
        @update="updateRate"
      />
    </div>
  </div>
  <div data-cy="pre-authorization-amount" class="flex justify-between w-full my-1">
    <span class="font-medium text-gray-600"
      >{{ $t('tariff.tariffDetails.preAuthorizationAmount')
      }}<span class="required font-medium">*</span></span
    >
    <div class="flex w-1/2">
      <InlineLabelNumericInput
        name="pre_authorization_amount_cent"
        :type="currencySymbol"
        :value="singleRateData.pre_authorization_amount_cent"
        @update="updateRate"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';
import { normalizeSelectedCurrency } from '@/utils/currency';
import {
  TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER,
  THREE_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER,
} from '@/utils/constants';

import InlineLabelNumericInput from '@/components/common/inputs/InlineLabelNumericInput.vue';

import type { Rate, SpecialRate } from '@/models/tariffs.model';
import type { rateRules } from '@/utils/tariffModalRules';
import type { Validation } from '@vuelidate/core';

const tariffsStore = useTariffsStore();
const { tariffModalData, currencyTypes } = storeToRefs(tariffsStore);

const emit = defineEmits(['updateRateInputs']);

const props = defineProps<{
  rate: Rate | SpecialRate | undefined;
  type: number | string;
  v$?: Validation<typeof rateRules>;
}>();

const singleRateData = ref({
  type: props.type,
  session_fee_cent:
    props.rate && props.rate.session_fee_cent
      ? props.rate.session_fee_cent / TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : 0,
  session_fee_tax_percent:
    props.rate && props.rate.session_fee_tax_percent
      ? props.rate.session_fee_tax_percent / TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : 0,
  price_per_kwh_tenth_cent:
    props.rate && props.rate.price_per_kwh_tenth_cent
      ? props.rate.price_per_kwh_tenth_cent / THREE_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : 0,
  price_per_kwh_tax_percent:
    props.rate && props.rate.price_per_kwh_tax_percent
      ? props.rate.price_per_kwh_tax_percent / TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : 0,
  charging_duration_fee_tenth_cent:
    props.rate && props.rate.charging_duration_fee_tenth_cent
      ? props.rate.charging_duration_fee_tenth_cent / THREE_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : 0,
  charging_duration_fee_tax_percent:
    props.rate && props.rate.charging_duration_fee_tax_percent
      ? props.rate.charging_duration_fee_tax_percent / TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : 0,
  idle_fee_grace_period_min:
    props.rate && props.rate.idle_fee_grace_period_min ? props.rate.idle_fee_grace_period_min : 0,
  idle_fee_tenth_cent_per_min:
    props.rate && props.rate.idle_fee_tenth_cent_per_min
      ? props.rate.idle_fee_tenth_cent_per_min / THREE_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : 0,
  idle_fee_tax_percent:
    props.rate && props.rate.idle_fee_tax_percent
      ? props.rate.idle_fee_tax_percent / TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : 0,
  fixed_tax_session_cent:
    props.rate && props.rate.fixed_tax_session_cent
      ? props.rate.fixed_tax_session_cent / TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : 0,
  fixed_tax_parking_cent:
    props.rate && props.rate.fixed_tax_parking_cent
      ? props.rate.fixed_tax_parking_cent / TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : 0,
  pre_authorization_amount_cent:
    props.rate && props.rate.pre_authorization_amount_cent
      ? props.rate.pre_authorization_amount_cent / TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : 0,
});

const updateRate = (name: string, value: number) => {
  singleRateData.value = {
    ...singleRateData.value,
    [name]: Number(value),
  };

  emit('updateRateInputs', singleRateData.value);

  if (props.v$) {
    props.v$[name].$touch();
  }
};

const currencySymbol = computed(() => {
  if (typeof tariffModalData.value.currency === 'number') {
    return normalizeSelectedCurrency(currencyTypes.value, tariffModalData.value.currency).symbol;
  } else {
    return tariffModalData.value.currency_symbol;
  }
});
</script>
