<template>
  <div
    v-if="connectorIndex !== undefined && connectorIndex !== null"
    v-tooltip.left="connectorTooltip"
    :class="connectorClasses"
    :data-cy="connectorState"
    :data-cy-connector-id="connectorIndex"
    :style="`background-color: ${connectorStateColor}`"
  ></div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { HardwareConnectorState } from '@/models/hardwareSystems.model';
import { computed } from 'vue';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store'; 
import { formatDateTimeWithUsersTimezone } from '@/utils/dateTimeFormatVue'; 
import { BysColor } from '@/utils/colors';
const hardwareSystemsStore = useHardwareSystemsStore();
const connectorState = defineModel<HardwareConnectorState>();
const connectorIndex = defineModel<number>('connectorIndex'); 

const connectorName = computed(() => {
  switch (connectorIndex.value) {
    case 0:
      return 'LEFT';
    case 1:
      return 'RIGHT';
    case 2:
      return 'AC';
    default:
      return 'UNKNOWN';
  }
});

const connectorTooltip = computed(() => {
  let result = connectorName.value + ' \n' + connectorStateType.value?.name + '\n';
  if (connectorState.value?.updated_at)
    result += formatDateTimeWithUsersTimezone(connectorState.value?.updated_at);
  return result;
});
const connectorStateType = computed(() => {
  return hardwareSystemsStore.connectorStateTypes.find(
    (item) => item.id == connectorState.value?.state
  );
});
const connectorStateColor = computed(() => {
  if(!connectorStateType.value?.color) return new BysColor("black").hexString()
  return new BysColor(connectorStateType.value.color).hexString()
});
 
const connectorClasses = computed(() => {
  return 'm-1 w-6 h-10  rounded-md'; 
});
</script>
