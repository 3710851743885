<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="alert-history-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}</Message
  >
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="alert-history-table"
    :value="invocations"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    filterDisplay="menu"
    dataKey="id"
    class="p-datatable-sm mb-5 mt-6 border-rounded overflow-hidden alert-history-table"
  >
    <template #header>
      <div class="flex justify-end items-center p-4">
        <TimeRangeSelect
          :options="timeRange"
          :title="$t('timeFilter')"
          @filterByTime="filterAlertsByTime"
          class="mr-4"
        />
        <CheckboxMultiFilterVue
          :items="severities"
          :title="$t('monitoring.severity')"
          :selected="selectedSeverity"
          @update:selected="setSelectedSeverity"
        />
      </div>
    </template>
    <template #empty>
      <span data-cy="alert-history-table-empty" class="w-full block text-center">{{
        $t('monitoring.historyEmpty')
      }}</span>
    </template>
    <Column
      class="no-top-border-row"
      field="created_at"
      :header="$t('time')"
      :sortable="true"
      style="min-width: 9rem"
    >
      <template #body="{ data }">
        <date-time-display data-cy="alert-history-time" :date="(data as Invocation).created_at" />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="name"
      :header="$t('name')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <span data-cy="alert-history-name">{{ data.name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="details"
      :header="$t('details')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <span v-if="data.details" data-cy="alert-history-details">{{ data.details }}</span>
        <span v-else data-cy="alert-history-details" class="empty pl-4">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('details') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="charge_park_name"
      :header="$t('site')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <div v-if="data.charge_park_name" class="flex items-center">
          <span :id="data.charge_park_id" data-cy="alert-history-site">{{
            data.charge_park_name
          }}</span>
          <i
            data-cy="alert-history-site-link"
            class="pi pi-external-link text-gray-400 text-sm pl-3 cursor-pointer"
            @click="openAlertSite(data.charge_park_id)"
          />
        </div>
        <span v-else data-cy="alert-history-site" class="empty pl-4">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('site') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="system_name"
      :header="$t('hardwareSystem.title')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 12rem"
    >
      <template #body="{ data }">
        <div v-if="data.system_name" class="flex items-center">
          <span :id="data.system_id" data-cy="alert-history-system">{{ data.system_name }}</span>
          <i
            data-cy="alert-history-system-link"
            class="pi pi-external-link text-gray-400 text-sm pl-3 cursor-pointer"
            @click="openAlertAsset(data.system_id)"
          />
        </div>
        <span v-else data-cy="alert-history-system" class="empty pl-4">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('hardwareSystem.title') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="severity"
      :header="$t('monitoring.severity')"
      :sortable="true"
      :showFilterMatchModes="false"
      style="min-width: 6rem"
    >
      <template #body="{ data }">
        <Tag
          data-cy="alert-history-severity"
          class="mr-2"
          :severity="setSeverityColor(data.severity)"
          :value="setSeverityName(data.severity)"
          rounded
        ></Tag>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="severity"
          :placeholder="$t('select', { name: $t('monitoring.severity') })"
          class="p-column-filter mb-2"
          :showClear="true"
        >
        </Dropdown>
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAlertsStore } from '@/stores/admin/alerts/alerts.store';
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api';
import { useRouter } from 'vue-router';
import { HARDWARE_SYSTEMS, CHARGE_PARKS } from '@/utils/routeNames';
import { timeRange } from '@/utils/timeRange';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import CheckboxMultiFilterVue from '../common/CheckboxMultiFilter.vue';
import TimeRangeSelect from '../common/TimeRangeSelect.vue';
import type { Invocation } from '@/models/alerts.model';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';

const alertsStore = useAlertsStore();
const { invocations, severities } = storeToRefs(alertsStore);
const router = useRouter();
const loading = ref(true);
const notAuthorized = ref(false);
const severity = ref(['Low', 'Medium', 'High']);
const HAS_SEVERITY = ref('ENABLED');
const selectedSeverity = ref<number[]>([]);
const startDate = ref();
const endDate = ref();

const setSelectedSeverity = (data: number[]) => {
  selectedSeverity.value = data;
};

const openAlertSite = (id: number) => {
  router.push(`/${CHARGE_PARKS}/${id}`);
};

const openAlertAsset = (id: number) => {
  router.push(`/${HARDWARE_SYSTEMS}/${id}`);
};

const filterAlertsByTime = (data: any) => {
  if (!data && selectedSeverity.value.length === 0) {
    alertsStore.fetchInvocationsList();
  }

  if (
    (data && selectedSeverity.value.length === 0) ||
    (!data && selectedSeverity.value.length > 0) ||
    (data && selectedSeverity.value.length > 0)
  ) {
    startDate.value = data ? data.range.startTime : undefined;
    endDate.value = data ? data.range.currentTime : undefined;

    alertsStore.fetchFilteredAlertsList(
      data ? data.range.startTime : undefined,
      data ? data.range.currentTime : undefined,
      selectedSeverity.value
    );
  }
};

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  charge_park_name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  system_name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  severity: { value: null, matchMode: HAS_SEVERITY.value },
});

const matchModes = ref([
  { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
  { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
  { label: 'Contains', value: FilterMatchMode.CONTAINS },
]);

FilterService.register(HAS_SEVERITY.value, (value, filter): boolean => {
  let convertedValue;

  if (value === 1) {
    convertedValue = 'Low';
  }

  if (value === 2) {
    convertedValue = 'Medium';
  }

  if (value === 3) {
    convertedValue = 'High';
  }

  if (filter === undefined || filter === null) {
    return true;
  }

  return convertedValue === filter;
});

const setSeverityColor = (severity: number) => {
  switch (severity) {
    case 1:
      return 'success';
    case 2:
      return 'warning';
    default:
      return 'danger';
  }
};

const setSeverityName = (severity: number) => {
  switch (severity) {
    case 1:
      return 'Low';
    case 2:
      return 'Medium';
    default:
      return 'High';
  }
};

watch(
  () => selectedSeverity.value,
  (value: any) => {
    if (value.length === 0 && startDate.value && endDate.value) {
      alertsStore.fetchFilteredAlertsList(startDate.value, endDate.value, value);
    } else if (
      (value.length > 0 && !startDate.value && !endDate.value) ||
      (value.length > 0 && startDate.value && endDate.value)
    ) {
      alertsStore.fetchFilteredAlertsList(startDate.value, endDate.value, value);
    } else {
      alertsStore.fetchInvocationsList();
    }
  }
);

onMounted(async () => {
  await alertsStore
    .fetchInvocationsList()
    .catch((error) => {
      if (error.response.status === 403) {
        notAuthorized.value = true;
      } else {
        throw new Error('Alert history failed to be fetched');
      }
    })
    .finally(() => (loading.value = false));

  await alertsStore.fetchSeverityList().catch((error) => {
    if (error.response.status === 403) {
      notAuthorized.value = true;
    } else {
      throw new Error('Alert severities failed to be fetched');
    }
  });
});
</script>

<style lang="scss" scoped>
.page-title {
  color: $text-medium;
  font-weight: 600;
  font-size: 20px;
}
.page-subtitle {
  color: $text-light;
  font-weight: 400;
  font-size: 14px;
}
:deep(.p-tag) {
  &.p-tag-warning {
    background-color: $accent;
    color: #fff;
  }
}
</style>
