<template>
  <div>
    <PanelWithEdit
      v-if="!isLoading"
      :title="$t('chargePark.access')"
      :loading="isLoading"
      :isEditing="false"
      :isNotValid="editFormIsNotValid"
      :editIsDisabled="!activeRoles"
      cancelBtn
      @onEdit="isEditing = true"
      @onClose="onClose"
      @onApply="editSite"
    >
      <div class="flex flex-col py-2">
        <div class="flex justify-between">
          <span data-cy="site-access-field-name" class="block font-medium py-3 w-1/3">{{
            $t('chargePark.stationType')
          }}</span>
          <Dropdown
            v-if="isEditing"
            v-model="selectedStationType"
            :options="stationTypes"
            :placeholder="$t('select', { name: $t('chargePark.stationType') })"
            optionLabel="name"
            showClear
            class="p-column-filter mb-2 w-2/3"
            @change="setSelectedStationType"
          >
          </Dropdown>
          <span
            v-if="!isEditing && chargeParkDetails.station_type"
            data-cy="site-access-station-field-value"
            class="block font-medium text-right py-3 w-2/3"
            >{{
              stationTypes.find((type) => type.id === chargeParkDetails.station_type)?.name
            }}</span
          >
          <span
            data-cy="site-access-station-field-value-empty"
            v-if="!isEditing && !chargeParkDetails.station_type"
            class="empty text-right py-2 font-medium whitespace-nowrap"
            >--</span
          >
        </div>
        <div class="flex justify-between">
          <span data-cy="site-access-field-name" class="block font-medium py-3 w-1/3">{{
            $t('chargePark.accessType')
          }}</span>
          <Dropdown
            v-if="isEditing"
            v-model="selectedAccessType"
            :options="accessTypes"
            :placeholder="$t('select', { name: $t('chargePark.accessType') })"
            optionLabel="name"
            showClear
            class="p-column-filter mb-2 w-2/3"
            @change="setSelectedAccessType"
          >
          </Dropdown>
          <span
            v-if="!isEditing && chargeParkDetails.access_type"
            data-cy="site-access-type-field-value"
            class="block font-medium text-right py-3 w-2/3"
            >{{ accessTypes.find((type) => type.id === chargeParkDetails.access_type)?.name }}</span
          >
          <span
            v-if="!isEditing && !chargeParkDetails.access_type"
            data-cy="site-access-type-field-value-empty"
            class="empty text-right py-2 font-medium whitespace-nowrap"
            >--</span
          >
        </div>
      </div>
    </PanelWithEdit>
    <Toast
      data-cy="charge-park-info-toast"
      position="top-center"
      errorIcon="pi pi-info-circle"
      group="chargeParkAccess"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { chargeParkAccessRules } from '@/utils/chargeParkDetailsRules';
import { useUsersStore } from '@/stores/admin/users/users.store';
import {
  ASSET_MANAGER_ROLE,
  ADMINISTRATOR_ROLE,
  DEFAULT_TOAST_LIFE_MILLISECONDS,
} from '@/utils/constants';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import Dropdown from 'primevue/dropdown';
import Toast from 'primevue/toast';

const { t } = useI18n();
const toast = useToast();
const chargeParksStore = useChargeParksStore();
const systemsStore = useHardwareSystemsStore();
const userStore = useUsersStore();
const { chargeParkEditionData, accessTypes, stationTypes } = storeToRefs(chargeParksStore);

const chargeParkDetails = ref({
  access_type: chargeParkEditionData.value.access_type,
  station_type: chargeParkEditionData.value.station_type,
});

const selectedAccessType = ref();
const selectedStationType = ref();
const isLoading = ref(true);
const isEditing = ref(false);

const activeRoles = computed(
  () =>
    userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
    userStore.me.roles.map((role) => role.id).includes(ASSET_MANAGER_ROLE)
);

const setSelectedAccessType = (data: any) => {
  if (data.value) {
    chargeParkEditionData.value.access_type = data.value.id;
    chargeParkDetails.value.access_type = data.value.id;
  } else {
    chargeParkEditionData.value.access_type = data.value;
    chargeParkDetails.value.access_type = data.value;
  }
  v$.value.access_type.$touch();
};

const setSelectedStationType = (data: any) => {
  if (data.value) {
    chargeParkEditionData.value.station_type = data.value.id;
    chargeParkDetails.value.station_type = data.value.id;
  } else {
    chargeParkEditionData.value.station_type = data.value;
    chargeParkDetails.value.station_type = data.value;
  }
  v$.value.station_type.$touch();
};

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('chargePark.title'),
      action: t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'chargeParkAccess',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: t('toast.edition'),
      name: t('chargePark.title'),
    }),
    group: 'chargeParkAccess',
  });
};

const editSite = () => {
  const data = {
    access_type: chargeParkDetails.value.access_type,
    station_type: chargeParkDetails.value.station_type,
  };
  chargeParksStore
    .editChargeParkDetails(chargeParkEditionData.value.id, data)
    .then((response: any) => {
      chargeParksStore.fetchChargePark(chargeParkEditionData.value.id).then((response) => {
        const { access_type, station_type } = response.data;
        chargeParkDetails.value.access_type = access_type;
        chargeParkDetails.value.station_type = station_type;
      });
      if (response.status === 200) {
        systemsStore.fetchSystemsByChargeParkId(chargeParkEditionData.value.id);
        toast.removeGroup('chargeParkAccess');
        showSuccess();
        onClose();
      }
    })
    .catch((error) => {
      isEditing.value = true;
      toast.removeGroup('chargeParkAccess');
      showError();
      throw new Error(error);
    });
};

onMounted(async () => {
  await chargeParksStore.fetchAccessTypeList().then(() => {
    selectedAccessType.value = accessTypes.value.find(
      (type) => type.id === chargeParkEditionData.value.access_type
    );
  });
  await chargeParksStore.fetchStationTypesList().then(() => {
    selectedStationType.value = stationTypes.value.find(
      (station) => station.id === chargeParkEditionData.value.station_type
    );
  });
  isLoading.value = false;
});

const onClose = () => {
  isEditing.value = false;
  resetForm();
};

const editFormIsNotValid = computed(() => {
  return !v$.value.$anyDirty;
});

const resetForm = () => {
  chargeParkDetails.value.access_type = chargeParkEditionData.value.access_type;
  chargeParkDetails.value.station_type = chargeParkEditionData.value.station_type;
  v$.value.access_type.$reset();
  v$.value.station_type.$reset();
  v$.value.$reset();
};

const rules = computed(() => chargeParkAccessRules);

const v$ = useVuelidate(rules, chargeParkDetails.value);
</script>
<style lang="scss" scoped>
:deep(.p-dropdown-label) {
  padding: 8px;
}
</style>
