<template>
  <HardwareSystemsPicker
    @onSystemSelect="setSelectedAssets"
    @onSystemUnselect="setUnselectedAssets"
    @onAllSystemsSelect="setSelectedAllAssets"
    @onAllSystemsUnselect="setUnselectedAllAssets"
    :value="dataExportModalData.systems"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useDataExportsStore } from '@/stores/admin/dataExports/dataExports.store';

import HardwareSystemsPicker from '@/components/hardwareSystems/HardwareSystemsPicker.vue';

import type { dataExportRules } from '@/utils/dataExportModalRules';
import type { Validation } from '@vuelidate/core';

const dataExportsStore = useDataExportsStore();
const { dataExportModalData } = storeToRefs(dataExportsStore);

const props = defineProps<{
  v$: Validation<typeof dataExportRules>;
}>();

const setSelectedAssets = (assetId: { id: number }) => {
  dataExportModalData.value.systems.push(assetId.id);
  props.v$.systems.$touch();
};

const setUnselectedAssets = (id: number) => {
  const assetPos = dataExportModalData.value.systems.findIndex((systemId) => systemId === id);
  dataExportModalData.value.systems.splice(assetPos, 1);
  props.v$.systems.$touch();

  if (dataExportModalData.value.systems.length === 0) {
    props.v$.systems.$reset();
  }
};

const setSelectedAllAssets = (assetIds: any) => {
  dataExportModalData.value.systems = assetIds.map((item: { id: number }) => item.id);
  props.v$.systems.$touch();
};

const setUnselectedAllAssets = () => {
  dataExportModalData.value.systems = [];
  props.v$.systems.$reset();
};
</script>

<style scoped lang="scss"></style>
