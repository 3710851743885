<template>
  <GeneralSummary
    show-name
    :show-author="false"
    :name="reportModalData.name"
    :template="selectedTemplate"
    :type="reportModalData.report_type"
  />
  <ScheduleSummary v-if="periodicReport" />
  <ReportInstantDatesSummary v-if="instantReport" />
  <ReportSitesSummary />
  <ReportContactsSummary />
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useReportsStore } from '@/stores/admin/reports/reports.store';

import GeneralSummary from '@/components/alertsAndReportsShared/summary/GeneralSummary.vue';
import ScheduleSummary from '@/components/alertsAndReportsShared/summary/ScheduleSummary.vue';
import ReportSitesSummary from '@/components/alertsAndReportsShared/summary/ReportSitesSummary.vue';
import ReportContactsSummary from '@/components/alertsAndReportsShared/summary/ReportContactsSummary.vue';
import ReportInstantDatesSummary from '@/components/alertsAndReportsShared/summary/ReportInstantDatesSummary.vue';

import type { reportInstantTypeRules, reportPeriodicTypeRules } from '@/utils/reportModalRules';
import type { Validation } from '@vuelidate/core';
import { ReportType } from '@/models/reports.model';

const reportsStore = useReportsStore();
const { reportModalData, selectedTemplate } = storeToRefs(reportsStore);

defineProps<{
  v$: Validation<typeof reportInstantTypeRules | typeof reportPeriodicTypeRules>;
}>();

const instantReport = computed(() => reportModalData.value.report_type === ReportType.Instant);
const periodicReport = computed(() => reportModalData.value.report_type === ReportType.Periodic);
</script>
