import axios from 'axios';
import type { Country } from '@/models/countries.model';

export function fetchCountries(): Promise<Country[]> {
  return axios
    .get<Country[]>(`/api/countries/`, {
      headers: {
        uid: 1,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}

export function fetchSingleCountry(code: string): Promise<Country> {
  return axios
    .get<Country>(`/api/countries/${code}/`, {
      headers: {
        uid: 1,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
}
