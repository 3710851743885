<template>
  <Dialog
    v-model:visible="tariffCreateModalIsOpen"
    data-cy="tariff-create-edit-modal"
    modal
    class="tariff-create-edit-modal bg-gray-100"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '1030px' }"
    @hide="closeModal"
  >
    <template #header>
      <div class="relative w-full text-center">
        <Button
          v-if="!isFirst"
          :label="$t('back')"
          icon="pi pi-angle-left"
          class="!absolute p-button-outlined !bg-white p-button-plain p-button-sm mr-2 left-0 top-1/2 transform -translate-y-1/2"
          @click="goBack"
        />

        <p class="font-semibold">
          {{ modalTitle }}
        </p>
      </div>
    </template>

    <ModalStepper :active-step="current" v-bind="{ steps, isEditMode }" />

    <div class="border-t">
      <component :is="tariffStepsComponents[current]" v-bind="{ v$ }" />
    </div>

    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        data-cy="tariff-create-edit-cancel-button"
        @click="closeModal"
      />
      <Button
        v-if="!isLast"
        :disabled="!isStepValid || tariffCreateSpecialRateIsOpen"
        :label="t('next')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        data-cy="tariff-create-edit-next-button"
        @click="goToNext"
      />
      <Button
        v-else
        :disabled="!isStepValid"
        :label="t('apply')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        data-cy="tariff-create-edit-submit-button"
        :loading="loading"
        @click="submitForm"
      />
    </template>
  </Dialog>

  <Toast
    data-cy="tariff-create-edit-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="tariffs"
    class="tariff-toast"
  />
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';
import { TariffStep } from '@/models/tariffs.model';
import { useStepper } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';
import { CREATE, DEFAULT_TOAST_LIFE_MILLISECONDS, EDIT } from '@/utils/constants';
import { tariffRules } from '@/utils/tariffModalRules';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ModalStepper from '@/components/common/steppers/ModalStepper.vue';
import TariffModalInformationStep from '@/components/tariffs/steps/TariffModalInformationStep.vue';
import TariffModalDefaultRateStep from '@/components/tariffs/steps/TariffModalDefaultRateStep.vue';
import TariffModalSpecialRatesStep from '@/components/tariffs/steps/TariffModalSpecialRatesStep.vue';
import TariffModalSitesStep from '@/components/tariffs/steps/TariffModalSitesStep.vue';

const tariffsStore = useTariffsStore();
const { t } = useI18n();
const toast = useToast();
const {
  tariffCreateModalIsOpen,
  isEditMode,
  tariffEditId,
  tariffModalData,
  tariffCreateSpecialRateIsOpen,
} = storeToRefs(tariffsStore);

const chargeParksStore = useChargeParksStore();

const v$ = useVuelidate(tariffRules, tariffModalData);
const loading = ref(false);

const modalTitle = computed(() => {
  return isEditMode.value
    ? t('edit', { itemName: t('tariff.title') })
    : t('create', { itemName: t('tariff.title') });
});

const closeModal = () => {
  tariffCreateModalIsOpen.value = false;
  tariffCreateSpecialRateIsOpen.value = false;

  if (isEditMode.value) {
    tariffEditId.value = null;
  }
  tariffsStore.resetDefaultRate();
  resetForm();
};

const goBack = () => {
  goToPrevious();
  if (tariffCreateSpecialRateIsOpen.value) {
    tariffCreateSpecialRateIsOpen.value = false;
  }
};

const tariffStepsComponents: Record<TariffStep, unknown> = {
  [TariffStep.Information]: TariffModalInformationStep,
  [TariffStep.DefaultRate]: TariffModalDefaultRateStep,
  [TariffStep.SpecialRates]: TariffModalSpecialRatesStep,
  [TariffStep.Sites]: TariffModalSitesStep,
};

const createPath = [
  TariffStep.Information,
  TariffStep.DefaultRate,
  TariffStep.SpecialRates,
  TariffStep.Sites,
];
const editPath = [
  TariffStep.Information,
  TariffStep.DefaultRate,
  TariffStep.SpecialRates,
  TariffStep.Sites,
];
const currentPath = computed(() => (isEditMode.value ? editPath : createPath));

const { steps, current, goTo, goToNext, goToPrevious, isFirst, isLast } = useStepper(currentPath);

const resetForm = () => {
  tariffsStore.resetTariffModalData();
  v$.value.$reset();
  goTo(steps.value[0]);
};

const showSuccess = (actionName: string) => {
  toast.removeGroup('tariffs');
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('tariff.title'),
      action: actionName === CREATE ? t('toast.created') : t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'tariffs',
  });
};

const showError = (actionName: string) => {
  toast.removeGroup('tariffs');
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: actionName === CREATE ? t('toast.creation') : t('toast.edition'),
      name: t('tariff.title'),
    }),
    group: 'tariffs',
  });
};

const isStepValid = computed(() => {
  const stepValidationRules: Record<TariffStep, boolean> = {
    [TariffStep.Information]:
      v$.value.name.$dirty && !v$.value.name.$invalid && v$.value.currency.$dirty,
    [TariffStep.DefaultRate]: true,
    [TariffStep.SpecialRates]: true,
    [TariffStep.Sites]: v$.value.charge_parks.$dirty && !v$.value.charge_parks.$invalid,
  };

  return stepValidationRules[current.value];
});

async function submitForm() {
  const action = isEditMode.value ? EDIT : CREATE;
  loading.value = true;

  try {
    isEditMode.value ? await tariffsStore.editTariff() : await tariffsStore.createNewTariff();

    if (isEditMode.value && tariffEditId.value) {
      await tariffsStore.fetchTariffDetails(tariffEditId.value);
    } else {
      await tariffsStore.fetchTariffList();
    }

    showSuccess(action);
    loading.value = false;
    closeModal();

    await chargeParksStore.fetchChargeParkList();

    resetForm();

    tariffsStore.resetTariffModalData();
  } catch (err: unknown) {
    loading.value = false;
    showError(action);
    throw new Error(err as string);
  }
}

watch(
  () => isEditMode.value,
  (isActive) => {
    !isActive ? v$.value.$reset() : v$.value.$touch();
  }
);
</script>
