<template>
  <page-header :title="$t('chargeParks')" />
  <div class="container container-grey">
    <div class="flex flex-wrap justify-end">
      <OrganisationTable
        class="overflow-x-auto w-full"
        :child-table="organisationChargeParksTable"
        :child-table-name="CHARGE_PARKS"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ChargeParkTable from '@/components/chargeParks/ChargeParkTable.vue';
import OrganisationTable from '@/components/organisations/OrganisationTable.vue';
import { CHARGE_PARKS } from '@/utils/constants';
import PageHeader from '@/components/common/layout/PageHeader.vue';
import { shallowRef } from 'vue';

const organisationChargeParksTable = shallowRef(ChargeParkTable);
</script>
