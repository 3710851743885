<template>
  <div class="field flex flex-col px-12 py-4">
    <label id="alertNameLabel" for="alertName" class="title font-medium text-gray-600">
      Name<span class="required">*</span>
    </label>

    <InputText
      data-cy="alert-configuration-name-input"
      id="alertName"
      type="text"
      aria-describedby="alertNameLabel"
      class="p-inputtext-sm"
      :model-value="name"
      @update:model-value="emits('update:name', $event)"
      @input="showNameValidation = true"
    />

    <div
      v-if="showNameValidation && props.validationErrors?.name"
      class="text-red-500 text-sm mt-1"
    >
      {{ props.validationErrors.name }}
    </div>

    <div class="mt-6">
      <span class="block title font-medium field-edit-label"> Product Type </span>
      <p class="block text-grey text-sm mb-2">Select the product type you would like to brand</p>
      <div class="mb-5">
        <DataTable
          v-model:selection="selected"
          :selection-mode="props.templateSelectionDisabled ? undefined : 'single'"
          dataKey="id"
          filter-display="menu"
          scroll-height="280px"
          :value="props.productTypes"
          :row-class="rowClass"
        >
          <Column
            selection-mode="single"
            style="flex: 0; padding: 0"
            bodyStyle="padding-left: 16px"
          />
          <Column field="name" header="Name" style="flex: 1" />
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import InputText from 'primevue/inputtext';
import { ref, watch } from 'vue';
import type { ProductType } from '@/models/productTypes.model';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import type { InformationValidationErrors } from '@/stores/admin/branding/ui.validation';

const props = defineProps<{
  name?: string;
  productTypeId?: ProductType['id'];
  productTypes: ProductType[];
  templateSelectionDisabled?: boolean;
  validationErrors?: InformationValidationErrors;
}>();

const emits = defineEmits<{
  (event: 'update:name', name: string): void;
  (event: 'update:product-type-id', templateId: ProductType['id']): void;
  (event: 'update:is-valid', isValid: boolean): void;
}>();

const name = ref(props.name || '');
const showNameValidation = ref(false);
const selected = ref<ProductType | null>(
  props.productTypeId ? props.productTypes.find((p) => p.id === props.productTypeId) || null : null
);

watch(name, (newValue) => {
  emits('update:name', newValue);
});

watch(selected, (newValue: ProductType | null) => {
  if (newValue === null) return;
  if (newValue.properties.branding_template === undefined) return;
  emits('update:product-type-id', newValue.id);
});

const rowClass = (row: ProductType) => {
  if (props.templateSelectionDisabled || row.properties.branding_template === undefined)
    return 'p-disabled';
  return '';
};
</script>
