import axios from 'axios';
import type { AxiosPromise, AxiosResponse } from 'axios';
import type { WhitelistCreate, WhitelistId, WhitelistDetailsView } from '@/models/whitelists.model';

export function fetchWhitelists(): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/whitelists/`, {
      headers: {
        uid: 1,
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function createWhitelist(data: WhitelistCreate): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/whitelists/`, data, {
      headers: {
        uid: 1,
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function fetchSingleWhitelistDetails(id: number): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/whitelists/${id}/`, {
      headers: {
        uid: 1,
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function addChargers(whitelistId: number, data: WhitelistId[]): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(
      `/api/whitelists/${whitelistId}/batch/systems/`,
      { items: data },
      {
        headers: {
          uid: 1,
        },
      }
    )
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function addRfidTags(whitelistId: number, data: WhitelistId[]): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(
      `/api/whitelists/${whitelistId}/batch/rfidtags/`,
      { items: data },
      {
        headers: {
          uid: 1,
        },
      }
    )
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function removeRfidTags(whitelistId: number, data: WhitelistId[]): Promise<AxiosResponse> {
  return axios
    .delete<AxiosPromise>(`/api/whitelists/${whitelistId}/batch/rfidtags/`, {
      headers: {
        uid: 1,
      },
      data: { items: data },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function removeChargers(whitelistId: number, data: WhitelistId[]): Promise<AxiosResponse> {
  return axios
    .delete<AxiosPromise>(`/api/whitelists/${whitelistId}/batch/systems/`, {
      headers: {
        uid: 1,
      },
      data: { items: data },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function editWhitelist(data: WhitelistDetailsView): Promise<AxiosResponse> {
  return axios
    .put<AxiosPromise>(
      `/api/whitelists/${data.id}/`,
      { name: data.name },
      {
        headers: {
          uid: 1,
        },
      }
    )
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function removeWhitelist(id: number): Promise<AxiosResponse> {
  return axios
    .delete<AxiosPromise>(`/api/whitelists/${id}/`, {
      headers: {
        uid: 1,
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}
