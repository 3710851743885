<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="updates-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}</Message
  >
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="updates-table"
    :value="updates"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    filterDisplay="menu"
    :globalFilterFields="['description', 'version', 'product_type_id', 'product_type_name']"
    dataKey="id"
    class="p-datatable-sm mb-5 mt-2 border-rounded overflow-hidden"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="
              $t('searchBy', {
                name: `${$t('description')}, ${$t('version')}, ${$t('productType.title')}...`,
              })
            "
            class="p-inputtext-sm"
          />
        </span>
        <Button
          v-if="activeRoles"
          data-cy="open-updates-upload-file-modal"
          :label="$t('update.uploadNewVersion')"
          icon="pi pi-plus"
          class="p-button-sm p-button-primary"
          @click="openUploadUpdateModal"
        />
      </div>
    </template>
    <template #empty>
      <span data-cy="updates-table-empty" class="w-full block text-center">{{
        $t('update.empty')
      }}</span>
    </template>
    <Column
      class="no-top-border-row"
      field="product_type_name"
      :header="$t('update.productTypeName')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 13rem"
    >
      <template #body="{ data }">
        <span v-if="data.product_type_name" data-cy="update-product-type-name">{{
          data.product_type_name
        }}</span>
        <span v-else class="empty">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('update.productTypeName') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="product_type_id"
      :header="$t('update.productTypeId')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 5rem"
    >
      <template #body="{ data }">
        <span v-if="data.product_type_id" data-cy="update-product-type-id">{{
          data.product_type_id
        }}</span>
        <span v-else class="empty">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('update.productTypeId') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="version"
      :header="$t('update.packageVersion')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 8rem"
    >
      <template #body="{ data }">
        <span v-if="data.version" data-cy="update-package-version">{{ data.version }}</span>
        <span v-else class="empty">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('update.packageVersion') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="description"
      :header="$t('update.packageDescription')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 15rem"
    >
      <template #body="{ data }">
        <span v-if="data.description" data-cy="update-package-description">{{
          data.description
        }}</span>
        <span v-else class="empty">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('update.packageDescription') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="created_at"
      :header="$t('createdAt')"
      :sortable="true"
      style="min-width: 8rem"
    >
      <template #body="{ data }">
        <date-time-display :date="(data as Update).created_at" data-cy="update-created-at" />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="file"
      :header="$t('update.linkToFile')"
      style="min-width: 9.5rem"
    >
      <template #body="{ data }">
        <Button
          v-if="data.file_url"
          data-cy="update-link-to-file"
          :label="$t('download')"
          :disabled="!downloadActiveRoles"
          icon="pi pi-download"
          class="p-button-outlined p-button-plain p-button-sm mr-2"
          @click="openFileUrl(data.file_url)"
        />
        <span v-else class="empty">--</span>
      </template>
    </Column>
    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 12.5rem"
    >
      <template #body="{ data }">
        <div class="flex">
          <Button
            v-if="activeRoles"
            data-cy="open-edit-update-modal"
            :label="$t('edit')"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openEditUpdateModal(data)"
          />
          <Button
            v-if="activeRoles"
            data-cy="open-delete-update-modal"
            :label="$t('delete')"
            icon="pi pi-trash"
            class="p-button-outlined p-button-plain p-button-sm"
            @click="openDeleteUpdateModal(data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <UpdatesUploadModal />
  <UpdateDeleteModal />
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useUpdatesStore } from '@/stores/admin/updates/updates.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { ADMINISTRATOR_ROLE, UPDATE_ADMIN_ROLE, ASSET_OPERATOR_ROLE } from '@/utils/constants';

import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Message from 'primevue/message';
import UpdatesUploadModal from './UpdatesUploadModal.vue';
import UpdateDeleteModal from './UpdateDeleteModal.vue';

import type { Update, UpdateEdit } from '@/models/updates.model';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';

const updatesStore = useUpdatesStore();
const {
  updates,
  uploadUpdatesModalIsOpen,
  uploadIsEditing,
  uploadEditionData,
  updateDeleteModalIsOpen,
  updateDeleteData,
} = storeToRefs(updatesStore);
const loading = ref(true);
const notAuthorized = ref(false);
const userStore = useUsersStore();

const openFileUrl = async (url: string) => {
  await updatesStore.fetchUpdateFileFullPath(url).then((response) => {
    window.open(response.data.download_url, '_parent');
  });
};

const activeRoles = computed(
  () =>
    userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
    userStore.me.roles.map((role) => role.id).includes(UPDATE_ADMIN_ROLE)
);

const downloadActiveRoles = computed(
  () => activeRoles.value || userStore.me.roles.map((role) => role.id).includes(ASSET_OPERATOR_ROLE)
);

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  description: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  version: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  product_type_id: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  product_type_name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
});

const matchModes = ref([
  { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
  { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
  { label: 'Contains', value: FilterMatchMode.CONTAINS },
]);

const openUploadUpdateModal = () => {
  uploadUpdatesModalIsOpen.value = true;
};

const openEditUpdateModal = (data: UpdateEdit) => {
  openUploadUpdateModal();

  uploadIsEditing.value = true;

  uploadEditionData.value = {
    id: data.id,
    file_name: data.file_name,
    description: data.description,
    version: data.version,
    product_type_id: data.product_type_id,
    product_type_name: data.product_type_name,
  };
};

const openDeleteUpdateModal = (id: number) => {
  updateDeleteData.value.id = id;
  updateDeleteModalIsOpen.value = true;
};

onMounted(
  async () =>
    await updatesStore
      .fetchUpdatesList()
      .catch((error) => {
        if (error.response.status === 403) {
          notAuthorized.value = true;
        } else {
          throw new Error('Updates failed to be fetched');
        }
      })
      .finally(() => (loading.value = false))
);
</script>

<style lang="scss" scoped></style>
