<template>
  <div class="widget-layout-container">
    <widget-container
      :style="{ zIndex: index + 1 }"
      class="widget-container"
      :class="`size-${widget.presentation?.size || 'md'}`"
      v-for="(widget, index) in props.panel.widgets"
      :widget="widget"
      :additional-filters="props.additionalFilters"
      :context-resolver="props.contextResolver"
      :key="widget.id"
    />
  </div>
</template>

<script setup lang="ts">
import type { Panel, WidgetFilter } from '@/stores/admin/dashboard/dashboard.types';
import WidgetContainer from '@/components/dashboard/container/WidgetContainer.vue';
import type { WidgetContextResolver } from '@/stores/admin/dashboard/dashboard.logic.context';

const props = defineProps<{
  panel: Panel;
  additionalFilters: WidgetFilter[];
  contextResolver: WidgetContextResolver;
}>();
</script>

<style scoped lang="scss">
.widget-layout-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(300px, 50%));
  grid-auto-rows: min-content;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  padding: 1rem;
  overflow: scroll;
  height: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, minmax(300px, 100%));
  }
}

.widget-container {
  position: relative;

  &.size-sm {
    grid-column: span 1;
  }

  &.size-md {
    grid-column: span 1;
  }

  &.size-lg {
    grid-column: span 2;
  }
}
</style>
