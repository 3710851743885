<template>
  <PageHeader :title="t('downtimes.title')" :description="t('downtimes.description')" />
  <div class="container container-grey">
    <DowntimesTable />
  </div>

  <DowntimesCreateEditModal />
  <DowntimeDeleteModal />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import DowntimesCreateEditModal from '@/components/downtimes/DowntimesCreateEditModal.vue';

import PageHeader from '@/components/common/layout/PageHeader.vue';
import DowntimesTable from '@/components/downtimes/DowntimesTable.vue';
import DowntimeDeleteModal from '@/components/downtimes/DowntimeDeleteModal.vue';

const { t } = useI18n();
</script>
