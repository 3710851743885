<template>
  <div class="w-full flex flex-col md:grid grid-cols-12 divide-x">
    <!--  Media browser -->
    <div class="col-span-4">
      <MediaBrowser @add-media="addMediaToPlaylist" />
    </div>

    <!--  Playlist -->
    <div class="p-4 col-span-8">
      <div v-if="playlistModalData.media.length">
        <div class="flex space-between mt-1">
          <h2 class="flex-1 text-gray-700 font-medium">
            {{ $t('playlist') }}
          </h2>

          <p class="text-sm text-gray-500">{{ mediaCountLabel }}</p>
        </div>
        <span class="text-sm text-gray-400 pt-4 pb-2">{{ $t('playlists.singleImageInfo') }}</span>
      </div>
      <div
        v-if="playlistModalData.media.length"
        class="bg-white mt-4 flex flex-col divide-y rounded-md border border-gray-200 max-h-[300px] overflow-y-scroll"
      >
        <Draggable v-model="playlistModalData.media" item-key="id" @change="onOrderChange">
          <template #item="{ element }">
            <PlaylistItem
              :item="element"
              class="[&:not(:first-child)]:border-t playlist-item"
              @remove="removeItemFromPlaylist"
            />
          </template>
        </Draggable>
      </div>
      <p class="text-center text-sm text-gray-400 py-4">
        {{ $t('playlists.addMoreDescription') }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { usePlaylistsStore } from '@/stores/admin/playlists/playlists.store';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import Draggable from 'vuedraggable';

import MediaBrowser from '@/components/playlists/picker/MediaBrowser.vue';
import PlaylistItem from '@/components/playlists/picker/PlaylistItem.vue';

import type { Media } from '@/models/media.model';
import type { PlaylistItemModel } from '@/models/playlists.model';

const playlistsStore = usePlaylistsStore();
const { playlistModalData, playlistEditMediaData, mediaDeleteIds } = storeToRefs(playlistsStore);
const { t } = useI18n();

const addMediaToPlaylist = (item: Media) => {
  const itemOrder = playlistModalData.value.media.length;

  const playlistItem: PlaylistItemModel = {
    id: Math.floor(100000 + Math.random() * 900000),
    playlist: 0,
    media: item.id,
    repeat: item.file_type === 'Video' ? 1 : null,
    duration: item.file_type === 'Image' ? 60 : null,
    scaling: 1,
    order: itemOrder + 1,
  };

  playlistModalData.value.media.push(playlistItem);
};

const onOrderChange = () =>
  playlistModalData.value.media.forEach((mediaItem, index) => (mediaItem.order = index + 1));

const mediaCountLabel = computed(() => {
  return `${playlistModalData.value.media.length} ${
    playlistModalData.value.media.length === 1 ? t('item') : t('items')
  }`;
});

const removeItemFromPlaylist = (item: PlaylistItemModel) => {
  const index = playlistModalData.value.media.findIndex((media) => media.id === item.id);
  const editMediaIndex = playlistEditMediaData.value.findIndex((media) => media.id === item.id);

  if (editMediaIndex !== -1) {
    mediaDeleteIds.value.push(item.id);
    playlistEditMediaData.value.splice(editMediaIndex, 1);
  }

  playlistModalData.value.media.splice(index, 1);
};

onMounted(async () => {
  if (!playlistsStore.playlistModalData.media.length) {
    await playlistsStore.fetchMediaScalingOptions();
  }
});
</script>
