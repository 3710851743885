<template>
  <select v-model="updatedValue" @change="emitValue">
    <option v-for="enumValue in props.enumValues" :value="enumValue.value" :key="enumValue.value">
      {{ enumValue.label }}
    </option>
  </select>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
  id?: string;
  value?: any;
  defaultValue?: string | number;
  enumValues?: { value: string | number; label: string }[];
}>();

const updatedValue = ref(props.value || props.defaultValue || props.enumValues?.[0].value);

const emit = defineEmits<{
  (event: 'update:value', value: string | number): void;
}>();

function emitValue(newValue: Event) {
  const value = (newValue.target as HTMLInputElement).value;
  emit('update:value', value);
}
</script>

<style scoped lang="scss">
select {
  padding: 0.25rem 0.5rem;
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
  background-color: var(--gray-0);
  outline: none;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
</style>
