import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);

/**
 * Get the UTC offset for a given timezone.
 *
 * @param {string} timezone - The timezone for which to get the offset. (example: 'Europe/Warsaw')
 * @returns {string} The UTC offset in the format '+HH:mm' or '-HH:mm'.
 */
export const getOffsetForTimezone = (datetime: number | string | Date, timezone: string) => {
  return dayjs(datetime).tz(timezone).format('Z');
};

/**
 * Get the UTC offset hours for a given timezone.
 *
 * @param {string} timezone - The timezone for which to get the offset hours. (example: 'Europe/Warsaw')
 * @returns {string} The UTC offset hours in the format '+HH' or '-HH'.
 */
export const getOffsetHoursForTimezone = (datetime: number | string | Date, timezone: string) => {
  return getOffsetForTimezone(datetime, timezone).replace(':00', '');
};

/**
 * Format a date and time with a specific timezone.
 *
 * @param {string | number | Date} datetime - The date and time to format.
 * @param {string} format - The format string for the output.
 * @param {string} timezone - The timezone to use for formatting. (example: 'Europe/Warsaw')
 * @returns {string} The formatted date and time string.
 */
export const formatDateTimeWithTimezone = (
  datetime: string | number | Date,
  format: string,
  timezone: string
) => {
  return dayjs(new Date(datetime)).tz(timezone).format(format);
};

/**
 * Format a date and time in the local timezone.
 *
 * @param {string | number | Date} datetime - The date and time to format.
 * @param {string} format - The format string for the output.
 * @returns {string} The formatted date and time string.
 */
export const formatDateTime = (datetime: string | number | Date, format: string) => {
  return dayjs(new Date(datetime)).format(format);
};

export const formatHourMinSpan = (timeSpan: string, timeFormat: string) => {
  try {
    // Split the time span into start and end times
    const splitVal = timeSpan.split('-');

    // Parse start and end times using dayjs
    const fromTime = dayjs(splitVal[0], 'HH:mm');
    const toTime = dayjs(splitVal[1], 'HH:mm');

    // Format and return the result
    return `${fromTime.format(timeFormat)} - ${toTime.format(timeFormat)}`;
  } catch (error) {
    console.error(error);
    // Handle errors during parsing or formatting
    return timeSpan;
  }
};

/**
 * Format a time span string into a specified time format.
 *
 * @param {string} timeSpan - The time span string in the format 'HH:mm-HH:mm'.
 * @param {string} timeFormat - The format string for the output time.
 * @returns {string} The formatted time span string.
 */
export const dateTimeWithTimezone = (date: string | Date, timezone: string) => {
  return dayjs(date).tz(timezone).$d;
};

/**
 * Get the current date and time in the specified timezone.
 *
 * @param {string} timezone - The timezone to use for obtaining the current date and time.
 * @returns {string} The formatted current date and time string.
 */
export const currentDateTimeWithTimezone = (timezone: string) => {
  return dateTimeWithTimezone(new Date(), timezone);
};

const DayjsUtils = {
  getOffsetForTimezone,
  getOffsetHoursForTimezone,
  formatDateTimeWithTimezone,
  formatDateTime,
  formatHourMinSpan,
  dateTimeWithTimezone,
  currentDateTimeWithTimezone,
};

export default DayjsUtils;
