<template>
  <Dialog
    data-cy="rfid-tag-delete-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="field flex flex-col field-edit">
      <Message severity="error" icon="pi pi-exclamation-triangle" :closable="false">{{
        $t('deleteWarning')
      }}</Message>
      <p class="delete-info">
        {{ `${isBatch ? $t('rfidTag.deleteBatchInfo') : $t('rfidTag.deleteInfo')}` }}
      </p>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        data-cy="delete-rfid-tag-button"
        :label="t('delete')"
        class="p-button-sm p-button-footer p-button-error mx-auto mb-2 block mt-5"
        @click="deleteSelectedRfidTag"
        :loading="loading"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="rfid-tag-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="deleteTag"
  >
    <template v-if="isFailed" #message="slotProps">
      <div class="flex flex-col w-full pt-1">
        <div class="w-full flex">
          <span class="p-toast-message-icon pi pi-info-circle"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
          </div>
        </div>
        <List :data="deleteFailedIds" :title="t('rfidTag.failedIds')" class="w-full my-5" />
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Message from 'primevue/message';
import { useRfidTagsStore } from '@/stores/admin/rfidTags/rfidTags.store';
import { useI18n } from 'vue-i18n';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import List from '@/components/common/List.vue';
import type { RfidTagFailedId } from '@/models/rfidTags.model';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

export default {
  name: 'RfidTagDeleteModal',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    Message,
    Toast,
    List,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const store = useRfidTagsStore();
    const isVisible = ref(false);
    const { rfidTagDeleteModalIsOpen, rfidTagDeletionIds } = storeToRefs(store);
    const isBatch = computed(() => (rfidTagDeletionIds.value.length > 1 ? true : false));
    const isFailed = ref(false);
    const loading = ref(false);
    const modalTitle = computed(() =>
      t('delete', { itemName: isBatch.value ? t('rfidTags') : t('rfidTag.title') })
    );
    const deleteFailedIds = ref<number[]>([]);

    const closeModal = () => {
      rfidTagDeleteModalIsOpen.value = false;
      store.rfidTagDeletionIds = [];
      loading.value = false;
    };

    const successSummary = computed(() => {
      return isBatch.value
        ? t('toast.successBatch', {
            name: t('rfidTags'),
            action: t('toast.deleted'),
          })
        : t('toast.success', {
            name: t('rfidTag.title'),
            action: t('toast.deleted'),
          });
    });

    const errorSummary = computed(() => {
      return isBatch.value
        ? t('toast.errorBatch', {
            action: t('toast.deletion'),
            name: t('rfidTags'),
          })
        : t('toast.error', {
            action: t('toast.deletion'),
            name: t('rfidTag.title'),
          });
    });

    const showSuccess = () => {
      toast.add({
        severity: 'success',
        summary: successSummary.value,
        life: DEFAULT_TOAST_LIFE_MILLISECONDS,
        group: 'deleteTag',
      });
    };

    const showError = () => {
      toast.add({
        severity: 'error',
        summary: errorSummary.value,
        group: 'deleteTag',
      });
    };

    const deleteSelectedRfidTag = async () => {
      loading.value = true;

      await store
        .deleteRfidTag(rfidTagDeletionIds.value)
        .then((response: any) => {
          loading.value = false;
          if (response.status === 204) {
            toast.removeGroup('deleteTag');
            showSuccess();
            closeModal();
          }
        })
        .catch((error) => {
          store.fetchRfidTagsList();
          loading.value = false;
          toast.removeGroup('deleteTag');
          if (error.response && error.response.status === 400 && error.response.data.failed_items) {
            deleteFailedIds.value = error.response.data.failed_items.map(
              (item: RfidTagFailedId) => item.id
            );
            isFailed.value = true;
            showError();
            closeModal();
          } else {
            showError();
          }
          throw new Error(error);
        });
    };

    watch(
      () => rfidTagDeleteModalIsOpen.value,
      (value) => {
        if (value) {
          isVisible.value = true;
        } else {
          isVisible.value = false;
        }
      }
    );

    return {
      t,
      toast,
      isVisible,
      closeModal,
      modalTitle,
      deleteSelectedRfidTag,
      isBatch,
      rfidTagDeletionIds,
      deleteFailedIds,
      isFailed,
      loading,
    };
  },
};
</script>
