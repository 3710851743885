<template>
  <div class="flex flex-col w-full">
    <div v-for="(value, key) in hardwareSystemDetails" :key="key">
      <div class="flex justify-between">
        <p data-cy="system-info-field-name" class="font-medium py-3 whitespace-nowrap">
          {{ key === 'name' ? $t('name') : $t(`hardwareSystem.hardwareSystemDetails.${key}`) }}
        </p>
        <div
          v-if="value"
          data-cy="system-info-field-value"
          :class="`py-3 pl-4 w-2/3 text-right font-medium ${
            key === 'manufacturer' ||
            key === 'model' ||
            key === 'nidec_id' ||
            key === 'owner_organisation_name'
              ? 'text-[var(--gray-400)]'
              : 'text-[var(--gray-700)]'
          }`"
        >
          <date-time-display
            v-if="key === 'service_started_at' || key === 'service_ended_at'"
            :date="value"
          />
          <span v-else>{{ value }}</span>
        </div>
        <span v-if="!value" class="empty py-2 px-4 font-medium">--</span>
      </div>
      <div
        v-if="key === 'nidec_id'"
        class="w-full border-t border-t-[var--gray-200] my-3 whitespace-nowrap"
      ></div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import dayjs from 'dayjs';

import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import {formatDateTime} from '@/utils/dateTimeFormatVue';

import type { HardwareSystem } from '@/models/hardwareSystems.model';

const props = defineProps<{
  system: HardwareSystem;
}>();

const hardwareSystemDetails = computed(() => {
  return {
    manufacturer: props.system.manufacturer,
    model: props.system.model,
    nidec_id: props.system.nidec_id,
    owner_organisation_name: props.system.owner_organisation_name,
    name: props.system.name,
    custom_id: props.system.custom_id,
    service_started_at: props.system.service_started_at,
    service_ended_at: props.system.service_ended_at,
  
  };
});
</script>
<style scoped lang="scss"></style>
