import { defineStore } from 'pinia';
import type { Country } from '@/models/countries.model';
import { fetchSingleCountry, fetchCountries } from '@/stores/admin/countries/countries.api';

interface CountriesState {
  countries: Country[];
  countryByCode: Record<Country['country_code'], Country>;
}

export const useCountriesStore = defineStore('countries', {
  state: (): CountriesState => ({
    countries: [],
    countryByCode: {},
  }),
  actions: {
    async fetchCountriesList() {
      try {
        const countryData = await fetchCountries();
        this.countries = countryData;
        countryData.forEach((country) => {
          this.countryByCode[country.country_code] = country;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCountry(code: string) {
      if (this.countryByCode[code] == undefined) {
        await fetchSingleCountry(code)
          .then((res) => (this.countryByCode[code] = res))
          .catch(console.error);
      }

      return this.countryByCode[code];
    },
  },
});
