<template>
  <div>
    <PanelWithEdit :editBtn="false">
      <template #customHeader>
        <div class="flex flex-wrap">
          <span class="p-panel-title w-full">{{ $t('tariff.defaultRate') }}</span>
          <span class="p-panel-subtitle w-full pt-1">{{
            $t('tariff.defaultRateDescription')
          }}</span>
        </div>
      </template>
      <TariffRate
        :rate="defaultRate"
        :currencySymbol="props.tariff.currency_symbol"
        :currencySymbolPosition="props.tariff.currency_symbol_position"
        class="mt-2"
      />
    </PanelWithEdit>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import TariffRate from '@/components/tariffs/rate/TariffRate.vue';

import type { Tariff } from '@/models/tariffs.model';

const props = defineProps<{
  tariff: Tariff;
}>();

const defaultRate = computed(() => {
  return props.tariff.rates.find((rate) => rate.type === 1 || rate.type === 'Default');
});
</script>
<style scoped lang="scss"></style>
