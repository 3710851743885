<template>
  <Dialog
    data-cy="rate-delete-modal"
    :header="modalTitle"
    v-model:visible="rateDeleteModalIsOpen"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="field flex flex-col field-edit">
      <Message severity="error" icon="pi pi-exclamation-triangle" :closable="false">
        {{ $t('deleteWarning') }}
      </Message>
      <p class="delete-info my-2">
        {{ $t('tariff.deleteRateInfo', { rateName: `"${rateDeleteData.name}"` }) }}
      </p>
      <ul v-for="site in tariffDetails.charge_parks" :key="site.id">
        <li class="list-item">
          <span class="delete-info">{{ site.name }}</span>
        </li>
      </ul>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        data-cy="special-rate-delete-button"
        class="p-button-sm p-button-footer p-button-error mx-auto mb-2 block mt-5"
        :label="t('delete')"
        :loading="loading"
        @click="deleteRate"
      />
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Message from 'primevue/message';
import { isDefaultRate, isSpecialRate } from '@/models/tariffs.model';

const { t } = useI18n();
const store = useTariffsStore();
const { rateDeleteModalIsOpen, tariffDetails, rateDeleteData, tariffModalData } =
  storeToRefs(store);

const loading = ref(false);

const modalTitle = computed(() => t('delete', { itemName: t('tariff.specialRate') }));

const closeModal = () => {
  rateDeleteModalIsOpen.value = false;
};

const deleteRate = async () => {
  const defaultRate = tariffModalData.value.rates.find(isDefaultRate);

  tariffModalData.value.rates = tariffModalData.value.rates
    .filter(isSpecialRate)
    .filter((rateToDelete, index) => rateDeleteData.value.id !== index);

  if (defaultRate !== undefined) tariffModalData.value.rates.unshift(defaultRate);

  closeModal();
  store.resetTariffDetails();
};
</script>
<style scoped lang="scss">
.list-item {
  list-style: disc;
  margin-left: 22px;
}
</style>
