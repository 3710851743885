<template>
  <div class="syslog-container" :class="{ fullscreen: fullscreen }">
    <div class="w-full border-b border-[#333333] p-2">
      <div class="flex items-center justify-between">
        <h4>SysLog</h4>
        <div class="syslog-actions">
          <debounced-search @update:query="setSearchQuery" />

          <div class="search-line-container" v-if="lineMatches.length > 0">
            <button @click="goToPreviousMatch">
              <chevron-up-icon class="w-5 h-5" />
            </button>
            <p>{{ highlightedMatchIdx }} / {{ lineMatches.length }}</p>
            <button @click="goToNextMatch">
              <chevron-down-icon class="w-5 h-5" />
            </button>
          </div>

          <button @click="copyToClipboard">
            <ClipboardIcon class="icon" />
            Copy All
          </button>
          <button @click="toggleFullscreen">
            <template v-if="fullscreen">
              <ArrowsPointingInIcon class="icon" />
              Minimize
              <kbd>ESC</kbd>
            </template>
            <template v-else>
              <ArrowsPointingOutIcon class="icon" />
              Fullscreen
            </template>
          </button>
        </div>
      </div>
      <div class="w-full items-center justify-center opacity-50">
        <information-circle-icon class="h-5 w-5 inline-block mr-1 ml-1" />
        <span class="text-sm">Times are UTC+0</span>
      </div>
    </div>

    <div class="log-stream-body" ref="logStreamContainer">
      <div class="p-8 flex items-center justify-center gap-4" v-if="loading">
        <loading-spinner color="light" :opacity="0.5" with-text />
      </div>
      <div
        v-else-if="error !== null"
        class="px-8 py-2 flex items-center justify-center gap-4 bg-red-500 border-t border-b border-red-700"
      >
        <exclamation-triangle-icon class="h-5 w-5" />
        <pre>Error Loading Logs: {{ error.message }}</pre>
      </div>
      <div
        v-else-if="processedChunks.length === 0"
        class="p-8 flex items-center justify-center gap-4 opacity-40 w-full"
      >
        <pre>No logs found for the given timerange</pre>
      </div>
      <template v-else>
        <!-- Vue starts the iteration at 1, so we need to decrement the index -->
        <pre
          v-for="chunkIdx in visibleUpToChunk"
          v-html="processedChunks[chunkIdx - 1]"
          :key="`${chunkIdx}-${searchQuery}`"
        />
      </template>
    </div>
  </div>
  <Toast :group="TOAST_GROUP" position="top-center" />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import type { LogType } from '@/models/logs.model';
import {
  ClipboardIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from '@heroicons/vue/24/outline';
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/vue/20/solid';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import DebouncedSearch from '@/components/logs/DebouncedSearch.vue';

import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';
import { useI18n } from 'vue-i18n';
import { fetchLogRaw } from '@/stores/admin/hardwareSystems/hardwareSystems.api';
import type { HardwareSystem } from '@/models/hardwareSystems.model';

const TOAST_GROUP = 'LOGSTREAM';
const toast = useToast();

const i18n = useI18n();

const props = defineProps<{
  start: string;
  end: string;
  systemId?: HardwareSystem['id'];
  type: LogType;
}>();

const fullscreen = ref(false);

const searchQuery = ref('');
const lineMatches = ref<{ chunk: number; line: number }[]>([]);
const highlightedMatchIdx = ref<number>(0);

const chunkSizeLines = 1000;
const rawChunks = ref<string[]>([]);
const visibleUpToChunk = ref<number>(1);

function setSearchQuery(newQuery: string) {
  searchQuery.value = newQuery;
  lineMatches.value = [];
  highlightedMatchIdx.value = 0;

  if (!newQuery) return;

  lineMatches.value = rawChunks.value.flatMap((chunk, idx) =>
    findMatchingLines(chunk, newQuery).map((line) => ({ chunk: idx, line }))
  );
  goToNextMatch();
}

function findMatchingLines(inputText: string, query: string): number[] {
  const matches: number[] = [];
  let lineNumber = 0;
  let lastFoundInLineNumber = 0;

  for (let i = 0; i < inputText.length; i++) {
    if (inputText[i] === '\n') lineNumber++;
    if (lastFoundInLineNumber === lineNumber) continue;

    if (inputText.slice(i).startsWith(query)) matches.push(lineNumber);
  }

  return matches;
}

function goToNextMatch() {
  if (lineMatches.value.length === 0) return;
  if (highlightedMatchIdx.value >= lineMatches.value.length - 1) return;

  highlightedMatchIdx.value++;

  const scrollTo = lineMatches.value[highlightedMatchIdx.value];

  if (visibleUpToChunk.value < scrollTo.chunk) {
    visibleUpToChunk.value = scrollTo.chunk + 1;
  }

  //   On next render, scroll to the highlighted match
  setTimeout(() => {
    const element = document.getElementById(`match-${scrollTo.chunk}-${scrollTo.line}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, 0);
}

function goToPreviousMatch() {
  if (lineMatches.value.length === 0) return;
  if (highlightedMatchIdx.value === null || highlightedMatchIdx.value <= 0) return;

  highlightedMatchIdx.value--;

  const scrollTo = lineMatches.value[highlightedMatchIdx.value];

  const element = document.getElementById(`match-${scrollTo.chunk}-${scrollTo.line}`);
  element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

function chunkLogs(raw: string): string[] {
  const startTime = Date.now();
  const chunked: string[] = [];
  let startingIndex = 0;
  let numLinesPassed = 0;

  for (let i = 0; i < raw.length; i++) {
    if (raw[i] === '\n') {
      numLinesPassed++;
    }

    if (numLinesPassed >= chunkSizeLines) {
      chunked.push(raw.slice(startingIndex, i));
      startingIndex = i;
      numLinesPassed = 0;
    }
  }

  if (startingIndex < raw.length) {
    chunked.push(raw.slice(startingIndex));
  }

  console.log('Chunking took', Date.now() - startTime, 'ms');

  return chunked;
}

const processedChunks = computed(() => {
  if (!searchQuery.value) return rawChunks.value;

  return rawChunks.value.map((chunk, chunkIdx) => {
    const matchingLines = lineMatches.value.filter((match) => match.chunk === chunkIdx);
    if (matchingLines.length === 0) return chunk;

    return chunk
      .split('\n')
      .map((line, lineIdx) => {
        if (matchingLines.some((match) => match.line === lineIdx)) {
          return `<span class="match" id="match-${chunkIdx}-${lineIdx}">${line}</span>`;
        }
        return line;
      })
      .join('\n');
  });
});

function handleEscapeKey(event: KeyboardEvent) {
  if (event.key === 'Escape') toggleFullscreen();
}

function toggleFullscreen() {
  fullscreen.value = !fullscreen.value;

  if (fullscreen.value) document.addEventListener('keydown', handleEscapeKey);
  else document.removeEventListener('keydown', handleEscapeKey);
}

const copyToClipboard = () => {
  navigator.clipboard
    .writeText(rawChunks.value.join(''))
    .then(() =>
      toast.add({
        group: TOAST_GROUP,
        severity: 'success',
        summary: i18n.t('interactions.copyToClipboard.success'),
        life: DEFAULT_TOAST_LIFE_MILLISECONDS,
        closable: true,
      })
    )
    .catch(() =>
      toast.add({
        group: TOAST_GROUP,
        severity: 'error',
        summary: i18n.t('interactions.copyToClipboard.error'),
        life: DEFAULT_TOAST_LIFE_MILLISECONDS,
        closable: true,
      })
    );
};

const loading = ref<boolean>(false);
const error = ref<Error | null>(null);

async function fetchRawLogStream() {
  if (!props.systemId) return;
  loading.value = true;
  error.value = null;
  await fetchLogRaw(props.systemId, props.start, props.end)
    .then((res) => (rawChunks.value = chunkLogs(res)))
    .catch((err) => (error.value = err))
    .finally(() => (loading.value = false));
}

const logStreamContainer = ref<HTMLElement | null>(null);

function addInfiniteScrollListener() {
  if (!logStreamContainer.value) return;

  logStreamContainer.value!.addEventListener('scroll', () => {
    const { scrollTop, scrollHeight, clientHeight } = logStreamContainer.value!;
    if (scrollTop + clientHeight >= scrollHeight) {
      visibleUpToChunk.value = Math.min(visibleUpToChunk.value + 1, rawChunks.value.length);
    }
  });
}

onMounted(() => {
  fetchRawLogStream();
  addInfiniteScrollListener();
});
watch(
  () => [props.systemId, props.start, props.end],
  () => fetchRawLogStream()
);
</script>

<style scoped lang="scss">
.syslog-container {
  background-color: #111111;
  border-radius: var(--rounded-md);
  border: 1px solid #333333;
  color: #ffffff;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  flex-direction: column;

  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;
    border-radius: 0;
  }
}

.syslog-actions {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

button {
  background-color: #222222;
  border: 1px solid #333333;
  border-radius: var(--rounded-md);
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0.375rem 1rem;

  &:hover {
    background-color: #333333;
  }

  .icon {
    height: 1rem;
    width: 1rem;
    margin-right: 0.25rem;
  }
}

h4 {
  margin: 0;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #ffffff;
}

:deep(span.match) {
  background-color: var(--blue-primary);
}

.log-stream-body {
  height: 100%;
  width: 100%;
  overflow: scroll;
}

:deep(.filename-header) {
  padding: 0.5rem;
  background-color: #222222;
  position: sticky;
  top: 0;
  display: block;

  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
}

kbd {
  background-color: #333333;
  border-radius: var(--rounded-md);
  color: #ffffff;
  font-size: 0.75rem;
  padding: 0.125rem 0.25rem;
  margin-left: 0.75rem;
}

.search-line-container {
  display: flex;
  align-items: center;
  border: 1px solid #333333;
  border-radius: var(--rounded-md);
  padding: 0;
  gap: 0.5rem;

  button {
    border: none;
    background-color: #222222;
    border-radius: 0;
    margin: 0;
    height: 3rem;

    &:hover {
      background-color: #333333;
    }

    &:not(:last-child) {
      border-right: 1px solid #333333;
    }

    &:not(:first-child) {
      border-left: 1px solid #333333;
    }
  }
}
</style>
