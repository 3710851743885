import type { IdName } from '@/models/common.model';
export interface Tariff {
  id?: number;
  name: string;
  modified_at: string;
  currency: string | number;
  currency_symbol?: string;
  currency_symbol_position: string;
  writable?: boolean;
  owner_organisation_name?: string;
  charge_parks: IdName[];
  rates: (Rate | SpecialRate)[];
}

export interface TariffDetails {
  name: string;
  modified_at: string;
  currency: string | number;
  currency_symbol?: string;
  currency_symbol_position: string;
  owner_organisation_name?: string;
  writable?: boolean;
  charge_parks: IdName[];
  rates: (Rate | SpecialRate)[];
}

export interface TariffEditData {
  name: string;
  currency: string | number;
  currency_symbol?: string;
  currency_symbol_position: string;
  owner_organisation_name?: string;
  writable?: boolean;
  charge_parks: number[];
  rates: (Rate | SpecialRate)[];
}

export interface RateInputs {
  session_fee_cent: number;
  session_fee_tax_percent: number;
  price_per_kwh_tenth_cent: number;
  price_per_kwh_tax_percent: number;
  idle_fee_tenth_cent_per_min: number;
  idle_fee_tax_percent: number;
  charging_duration_fee_tenth_cent: number;
  charging_duration_fee_tax_percent: number;
  idle_fee_grace_period_min: number;
  fixed_tax_session_cent: number;
  fixed_tax_parking_cent: number;
  pre_authorization_amount_cent: number;
}

export type WeekdayNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type WeekdayString = 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN';

export function isDefaultRate(rate: Rate | SpecialRate): rate is Rate {
  return rate.type === 1 || rate.type === 'Default';
}

export function isSpecialRate(rate: Rate | SpecialRate): rate is SpecialRate {
  return rate.type === 2 || rate.type === 'Special';
}

export interface Rate extends RateInputs {
  name: string;
  type: 1 | 'Default';
  days: WeekdayNumber[] | WeekdayString[];
  start_time?: string | null; // Check this?
  end_time?: string | null; // Check this?
  start_date?: Date | string | null; // Check this?
  end_date?: Date | string | null; // Check this?
}

export interface SpecialRate extends RateInputs {
  name: string;
  type: 2 | 'Special';
  days: WeekdayNumber[] | WeekdayString[];
  start_time: string | null; // hh:mm:ss
  end_time: string | null; // hh:mm:ss
  start_date: Date | string | null; // YYYY-MM-DD
  end_date: Date | string | null; // YYYY-MM-DD
}

export enum TariffStep {
  Information = 'information',
  DefaultRate = 'defaultRate',
  SpecialRates = 'specialRates',
  Sites = 'sites',
}

export enum Currency {
  Dollar = 'USD',
  Euro = 'EUR',
}

export interface Currencies extends IdName {
  symbol: string;
}
