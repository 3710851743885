import type { Events } from './eventbus';
import axios from 'axios';
import { data } from './eventbus';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { Dashboard, Panel } from '@/models/controlPanel/dashboard';
import { flatten } from '@/utils/objects';

import * as mockSystemData from '@/data/systemData.dummy.json';

export type LastDataEvents = {
  telemetry: Events['telemetry'][];
  settings: Events['setting'][];
  commands: Events['command'][];
};

function isTelemetry(path: string): boolean {
  return path.includes(':');
}

function transformSystemLastData(data: any): LastDataEvents {
  return Object.entries(flatten(data, 1)).reduce(
    (acc, [key, value]) => {
      if (isTelemetry(key))
        acc.telemetry.push({
          path: key,
          value: (value as any).value,
          timestampMs: (value as any).timestamp ? (value as any).timestamp * 1000 : Date.now(),
        });
      else
        acc.settings.push({
          path: key,
          value: (value as any).value,
          pending: (value as any).pending,
        });
      return acc;
    },
    { telemetry: [], settings: [], commands: [] } as LastDataEvents
  );
}

export async function fetchSystemLastData(
  systemId: HardwareSystem['id'],
  dashboardId?: Dashboard['id'],
  panelId?: Panel['id']
): Promise<LastDataEvents> {
  let url = `/api/systems/${systemId}/data/`;
  if (dashboardId && panelId) url += `?dashboard=${dashboardId}&panel=${panelId}`;

  return await axios.get(url).then(({ data }) => transformSystemLastData(data));
}

export class DataClient {
  private interval: NodeJS.Timer | undefined = undefined;

  private systemId: HardwareSystem['id'] | null = null;
  private dashboardId?: Dashboard['id'];
  private panelId?: Panel['id'];

  private cache: LastDataEvents | null = null;

  constructor(private readonly pollIntervalMs: number = 2000) {}

  public async startPolling(
    systemId: HardwareSystem['id'],
    dashboardId?: Dashboard['id'],
    panelId?: Panel['id']
  ): Promise<void> {
    console.log('Starting polling: ', systemId, dashboardId, panelId);
    if (this.interval) clearInterval(this.interval);

    this.systemId = systemId;
    this.dashboardId = dashboardId;
    this.panelId = panelId;

    await this.fetchAndPublish();
    this.interval = setInterval(() => this.fetchAndPublish(), this.pollIntervalMs);
  }

  public async sendSingleRequest(options?: { useCacheIfAvailable?: boolean }): Promise<void> {
    if (!this.systemId || !this.dashboardId || !this.panelId) return;
    await this.fetchAndPublish(options);
  }

  private emitData(): void {
    if (!this.cache) return;
    this.cache.telemetry.forEach((e) => data.emit('telemetry', e));
    this.cache.settings.forEach((e) => data.emit('setting', e));
    this.cache.commands.forEach((e) => data.emit('command', e));
  }

  private async fetchAndPublish(options?: { useCacheIfAvailable?: boolean }): Promise<void> {
    if (!this.systemId) return;
    data.emit('status', { state: 'loading' });

    try {
      if (!this.cache || !options?.useCacheIfAvailable) {
        this.cache = await fetchSystemLastData(this.systemId, this.dashboardId, this.panelId);
      }

      this.emitData();
      data.emit('status', { state: 'success' });
    } catch (e) {
      console.error(e);
      data.emit('status', { state: 'error' });
    }
  }

  public getFromCache(
    type: 'setting',
    path: string
  ): LastDataEvents['settings'][number] | undefined;
  public getFromCache(
    type: 'telemetry',
    path: string
  ): LastDataEvents['telemetry'][number] | undefined;
  public getFromCache(type: string, path: string): any | undefined {
    if (!this.cache) return undefined;

    switch (type) {
      case 'setting':
        return this.cache.settings.find((s) => s.path === path);
      case 'telemetry':
        return this.cache.telemetry.find((t) => t.path === path);
      default:
        return undefined;
    }
  }

  public stopPolling(): void {
    if (this.interval) clearInterval(this.interval);

    this.systemId = null;
    this.dashboardId = undefined;
    this.panelId = undefined;
    this.cache = null;
  }
}
