<template>
  <div class="mt-2 pt-4 px-12">
    <span class="block title font-medium field-edit-label mb-2">
      {{ t('report.reportType.title') }}
    </span>
    <Dropdown
      v-model="reportType"
      optionLabel="name"
      class="mb-2"
      :options="reportTypeOptions"
      :placeholder="$t('select', { name: $t('report.reportType.title') })"
    />
    <span class="block text-grey text-sm mb-6">
      {{
        reportType && reportType.id === 1
          ? t('report.reportType.instantDescription')
          : t('report.reportType.periodicDescription')
      }}
    </span>
    <span class="block title font-medium field-edit-label">
      {{ t('template') }}
    </span>
    <p class="block text-grey text-sm mb-2">{{ t('report.template.description') }}</p>
    <div class="mb-5">
      <ReportTemplatePicker
        v-model="reportModalData.template_id"
        :templates="reportsStore.reportTemplates"
        v-bind="{ loading }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useReportsStore } from '@/stores/admin/reports/reports.store';
import { INSTANT_REPORT } from '@/utils/constants';

import ReportTemplatePicker from '@/components/reports/ReportTemplatePicker.vue';
import Dropdown from 'primevue/dropdown';

import type { reportInstantTypeRules, reportPeriodicTypeRules } from '@/utils/reportModalRules';
import type { Validation } from '@vuelidate/core';
import type { ReportTemplate } from '@/models/reports.model';
import type { IdNameValue } from '@/models/common.model';

const { t } = useI18n();
const reportsStore = useReportsStore();
const { reportModalData } = storeToRefs(reportsStore);

const props = defineProps<{
  v$: Validation<typeof reportInstantTypeRules | typeof reportPeriodicTypeRules>;
}>();

const loading = ref(false);
const reportType = ref<IdNameValue | null | undefined>(null);
const reportTypeOptions = [
  {
    id: 1,
    name: 'Instant report',
    value: 'instant',
  },
  {
    id: 2,
    name: 'Periodic report',
    value: 'periodic',
  },
];

const setDefaultReportType = () => {
  reportType.value = !reportModalData.value.report_type
    ? reportTypeOptions.find((item) => item.value === INSTANT_REPORT)
    : reportTypeOptions.find((item) => item.value === reportModalData.value.report_type);
};

onMounted(async () => {
  try {
    loading.value = true;

    if (!reportsStore.reportTemplates.length) {
      await reportsStore.fetchReportTemplateList();
    }
  } catch (e: unknown) {
    console.error(e);
  } finally {
    setDefaultReportType();
    loading.value = false;
  }
});

watch(
  () => reportType.value,
  (reportTypeData) => {
    reportModalData.value.report_type = reportTypeData?.value;
  }
);

watch(
  () => reportModalData.value.template_id,
  () => {
    const selectedTemplate = reportsStore.reportTemplates.find(
      (template: ReportTemplate) => template.id === reportModalData.value.template_id
    );

    if (!selectedTemplate) return;

    props.v$.template_id.$touch();
  },
  { deep: true }
);
</script>

<style lang="scss" scoped>
:deep(.p-dropdown .p-inputtext) {
  padding: 8px 12px;
  font-size: 14px;
  min-width: 160px;
}
</style>
