<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="report-history-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
  >
    {{ $t('notAuthorized') }}
  </Message>
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="report-history-table"
    :value="reportsStore.generatedReports"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    filterDisplay="menu"
    dataKey="id"
    class="p-datatable-sm mb-5 mt-6 border-rounded overflow-hidden report-history-table"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="$t('searchBy', { name: `${$t('name')}, ${$t('template')}` })"
            class="p-inputtext-sm"
          />
        </span>
        <TimeRangeSelect
          data-cy="report-history-time-range"
          :options="timeRange"
          :title="$t('timeFilter')"
          @filterByTime="filterReportsByTime"
          class="mr-4"
        />
      </div>
    </template>
    <template #empty>
      <span data-cy="report-history-table-empty" class="w-full block text-center">
        {{ $t('report.historyEmpty') }}
      </span>
    </template>
    <Column
      class="no-top-border-row"
      field="report_name"
      :header="$t('name')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <span data-cy="report-history-name">{{ data.report_name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="report_template_name"
      :header="$t('template')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <span data-cy="report-history-template-name">{{ data.report_template_name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="report_type"
      :header="$t('report.reportType.title')"
      :sortable="true"
      :showFilterMatchModes="false"
      :filterMenuStyle="{ width: '15rem' }"
      style="min-width: 8rem"
    >
      <template #body="{ data }">
        <span data-cy="report-history-type">{{ data.report_type }}</span>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          data-cy="report-type-filter"
          v-model="filterModel.value"
          class="p-column-filter"
          optionLabel="name"
          :placeholder="$t('select', { name: $t('report.reportType.title') })"
          :options="reportTypes"
          :showClear="true"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="charge_parks"
      :header="$t('chargeParks')"
      :sortable="false"
      style="min-width: 7rem"
    >
      <template #body="{ data }">
        <span v-if="data.charge_parks.length > 0" data-cy="report-charge-parks">
          <i
            class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 mr-2"
            v-tooltip.right="{
              value: `${data.charge_parks.map((chargePark: ChargeParkAlert) => `<div class='flex flex-nowrap w-full'><span class='pr-2'>${chargePark.name}</span></div>`).join('')}`,
              escape: true,
              class: 'custom-tooltip site-tooltip',
            }"
          >
          </i>
          {{
            data.charge_parks.length === 1
              ? data.charge_parks.length + ' Site'
              : data.charge_parks.length + ' Sites'
          }}
        </span>
        <span v-else data-cy="report-no-charge-parks">
          <i class="pi pi-exclamation-triangle text-amber-500 text-lg ml-1 mr-2"></i>
          0 {{ $t('chargeParks') }}
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="contacts"
      :header="$t('contacts')"
      :sortable="false"
      style="min-width: 12rem"
    >
      <template #body="{ data }">
        <span v-if="data.contacts.length > 0" data-cy="report-contacts">
          <i
            class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 mr-2"
            v-tooltip.right="{
              value: `${data.contacts
                .map((contact: Contact) => `<div class='mb-3'><span class='font-medium'>Name:</span><span class='text-neutral-500 pl-1'> ${contact.name}</span>
                  <span class='font-medium'>Type:</span><span class='text-neutral-500 pl-2.5'> ${contact.type}</span>
                </div>
                `).join('')
                }`,
              escape: true,
              class: 'custom-tooltip contacts-tooltip',
            }"
          >
          </i>
          {{
            data.contacts.filter((contact: Contact) => contact.type === 'EMAIL').length === 1
              ? data.contacts.filter((contact: Contact) => contact.type === 'EMAIL').length +
                ' Email' +
                ', ' +
                data.contacts.filter((contact: Contact) => contact.type === 'SMS').length +
                ' Sms'
              : data.contacts.filter((contact: Contact) => contact.type === 'EMAIL').length +
                ' Emails' +
                ', ' +
                data.contacts.filter((contact: Contact) => contact.type === 'SMS').length +
                ' Sms'
          }}
        </span>
        <span v-else data-cy="report-no-contacts">
          <i class="pi pi-exclamation-triangle text-amber-500 text-lg ml-1 mr-2"></i>
          0 {{ $t('contacts') }}
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="created_at"
      :header="$t('generated')"
      :sortable="true"
      style="min-width: 9rem"
    >
      <template #body="{ data }">
        <date-time-display
          :date="(data as GeneratedReport).created_at"
          data-cy="report-history-time"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }">
        <Button
          v-if="data.file_url"
          data-cy="report-link-to-file"
          :label="$t('download')"
          icon="pi pi-download"
          class="p-button-outlined p-button-plain p-button-sm mr-2"
          @click="openFileUrl(data.file_url)"
        />
        <span v-else class="empty">--</span>
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { timeRange } from '@/utils/timeRange';
import { useReportsStore } from '@/stores/admin/reports/reports.store';
import { FilterService } from 'primevue/api';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import TimeRangeSelect from '../common/TimeRangeSelect.vue';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import Dropdown from 'primevue/dropdown';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { ChargeParkAlert } from '@/models/chargeParks.model';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Contact } from '@/models/contacts.model';
import type { GeneratedReport } from '@/models/reports.model';

const reportsStore = useReportsStore();
const loading = ref(true);
const notAuthorized = ref(false);
const startDate = ref();
const endDate = ref();
const TYPES = ref('TYPES');

const filterReportsByTime = async (data: any) => {
  if (!data) {
    return await reportsStore.fetchGeneratedReportsList();
  }

  startDate.value = data?.range?.startTime;
  endDate.value = data?.range?.currentTime;
  await reportsStore.fetchGeneratedReportsList(data?.range?.startTime, data?.range?.currentTime);
};

const openFileUrl = async (url: string) => {
  await reportsStore.fetchReportFileFullPath(url).then((response) => {
    window.open(response.data.download_url, '_parent');
  });
};

const reportTypes = [
  {
    id: 1,
    name: 'Instant',
  },
  {
    id: 2,
    name: 'Periodic',
  },
];

FilterService.register(TYPES.value, (value, filter): boolean => {
  return filter === undefined || filter === null ? true : value === filter.name.toLowerCase();
});

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  report_template_name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  report_type: { value: null, matchMode: TYPES },
});

const matchModes = ref([
  { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
  { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
  { label: 'Contains', value: FilterMatchMode.CONTAINS },
]);

onMounted(async () => {
  try {
    await reportsStore.fetchGeneratedReportsList();
  } catch (error: any) {
    if (error?.response?.status === 403) {
      notAuthorized.value = true;
    } else {
      throw new Error('Report history failed to be fetched');
    }
  } finally {
    loading.value = false;
  }
});
</script>

<style lang="scss" scoped>
.page-title {
  color: $text-medium;
  font-weight: 600;
  font-size: 20px;
}
.page-subtitle {
  color: $text-light;
  font-weight: 400;
  font-size: 14px;
}
:deep(.p-tag) {
  &.p-tag-warning {
    background-color: $accent;
    color: #fff;
  }
}
</style>
