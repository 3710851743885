<template>
  <p class="text">{{ renderedValue }}</p>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  value?: any;
  options?: { label: string; value: string }[];
}>();

function displayValue(value: string, options: { label: string; value: string }[]) {
  const option = options.find((option) => option.value === value);
  return option ? option.label : value;
}

const renderedValue = computed(() => {
  if (props.value === undefined) return '--';
  const options = props.options;
  if (options == undefined) return props.value.toString();

  return Array.isArray(props.value)
    ? props.value.map((value) => displayValue(value, options)).join(', ')
    : displayValue(props.value, options);
});
</script>

<style scoped lang="scss">
.text {
  font-size: 0.9rem;
  color: var(--gray-600);
  text-align: right;

  &.empty {
    color: var(--gray-400);
  }
}
</style>
