<template>
  <page-header :title="t('hardwareSystems')" />
  <div class="container container-grey w-full">
    <HardwareSystemTable
      :systems="systemsStore.systems"
      :loading="loading"
      :not-authorized="notAuthorized"
      use-unnasigned-filter
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import HardwareSystemTable from '@/components/hardwareSystems/HardwareSystemTable.vue';
import PageHeader from '@/components/common/layout/PageHeader.vue';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { onMounted, ref } from 'vue';
import { isAxiosError } from 'axios';

const { t } = useI18n();

const systemsStore = useHardwareSystemsStore();
const loading = ref(true);
const notAuthorized = ref(false);

onMounted(async () => {
  await systemsStore
    .fetchSystemsList()
    .catch((res) => {
      if (isAxiosError(res) && res.response?.status === 403) {
        notAuthorized.value = true;
      }
    })
    .finally(() => (loading.value = false));
});
</script>

<style lang="scss" scoped></style>
