import type { Currencies } from '@/models/tariffs.model';

export const normalizeSelectedCurrency = (
  currencyTypes: Currencies[],
  currency: number | string
) => {
  const selectedCurrency = currencyTypes.find((item: Currencies) => {
    if (typeof currency === 'string') {
      return item.name === currency;
    } else {
      return item.id === currency;
    }
  });

  return normalizeCurrency(selectedCurrency);
};

export const normalizeCurrency = (currency: Currencies | undefined) => {
  return {
    id: currency?.id,
    name: `${currency?.name} (${currency?.symbol})`,
    symbol: currency?.symbol,
  };
};
