<template>
  <Dialog
    data-cy="charge-park-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '655px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="field-edit">
      <div class="field flex flex-col">
        <label for="chargeParkName" class="text-sm font-medium mb-2"
          >{{ $t('name') }}<span class="required">*</span></label
        >
        <InputText
          data-cy="charge-park-name-input"
          id="chargeParkName"
          type="text"
          aria-describedby="chargeParkName-help"
          class="p-inputtext-sm"
          v-model="chargePark.name"
          :placeholder="$t('name')"
          @keyup="validateName"
        />
        <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
          <small class="p-error">{{ error.$message }}</small>
        </div>
      </div>
      <CountriesSelect
        data-cy="charge-park-country-select"
        class="w-full mt-2"
        :country="selectedChargeParkCountry"
        @onCountrySelect="setSelectedCountry"
        aria-describedby="countrySelect-help"
      />
      <div class="input-errors" v-for="(error, index) of v$.country_code.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
      <Location
        @coordinates="setCoordinates"
        @onValidateAddress="validateAddress"
        @onValidateLatitude="validateLatitude"
        @onValidateLongitude="validateLongitude"
        :resetValidation="resetLocationValidation"
        :address="address"
      />
    </div>
    <template #footer>
      <Button
        v-if="chargeParkIsEditing"
        data-cy="edit-charge-park-button"
        :label="editLabel"
        class="p-button-sm p-button-primary mx-auto mb-2 block"
        :disabled="editFormIsNotValid"
        @click="editChargePark"
        :loading="loading"
      />
      <Button
        v-else
        data-cy="create-charge-park-button"
        :label="createLabel"
        class="p-button-sm p-button-primary mx-auto mb-2 block"
        :disabled="formIsNotValid"
        @click="createChargePark"
        :loading="loading"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="charge-park-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="chargePark"
  />
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { chargeParkRules } from '@/utils/chargeParkModalRules';
import { useToast } from 'primevue/usetoast';
import {
  CREATE,
  EDIT,
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_TOAST_LIFE_MILLISECONDS,
} from '@/utils/constants';

import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import CountriesSelect from '@/components/common/countries/CountriesSelect.vue';
import Toast from 'primevue/toast';
import Location from '@/components/common/Location.vue';

const { t } = useI18n();
const toast = useToast();
const store = useChargeParksStore();
const isVisible = ref(false);
const loading = ref(false);
const {
  chargeParkModalIsOpen,
  chargeParkEditionData,
  chargeParkIsEditing,
  chargeParkOrganisation,
} = storeToRefs(store);
const chargePark = ref({
  id: 0,
  name: '',
  country_code: '',
  address: '',
  latitude: '',
  longitude: '',
  owner_organisation_id: 0,
  settings_via_cloud_allowed: 0,
  updates_via_cloud_allowed: 0,
  commands_via_cloud_allowed: 0,
});

const validationData = ref();

const address = ref('');
const resetLocationValidation = ref(false);

const latitude = ref<number>(DEFAULT_LATITUDE);
const longitude = ref<number>(DEFAULT_LONGITUDE);

const selectedChargeParkCountry = computed(() => store.chargeParkCountry);

const modalTitle = computed(() => {
  const title = chargeParkIsEditing.value
    ? t('edit', { itemName: t('chargePark.title') })
    : t('create', { itemName: t('chargePark.title') });
  return `${title} in ${chargeParkOrganisation.value.name}`;
});

const createLabel = computed(() => {
  return v$.value.$error ? t('error') : t('create', { itemName: t('chargePark.title') });
});

const editLabel = computed(() => {
  return v$.value.$error ? t('error') : t('apply');
});

const closeModal = () => {
  chargeParkModalIsOpen.value = false;
  loading.value = false;

  resetForm();

  if (chargeParkIsEditing) {
    chargeParkIsEditing.value = false;
  }
};

const setSelectedCountry = (countryCode: string) => {
  chargePark.value.country_code = countryCode;

  if (chargePark.value.country_code === null) {
    v$.value.country_code.$validate();
  }
};

const showSuccess = (actionName: string) => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('chargePark.title'),
      action: actionName === CREATE ? t('toast.created') : t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'chargePark',
  });
};

const showError = (actionName: string) => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: actionName === CREATE ? t('toast.creation') : t('toast.edition'),
      name: t('chargePark.title'),
    }),
    group: 'chargePark',
  });
};

const createChargePark = async () => {
  await v$.value.$validate();

  if (v$.value.$error) return;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...data } = chargePark.value;

  data.owner_organisation_id = chargeParkOrganisation.value.id;

  loading.value = true;

  await store
    .createNewChargePark(data)
    .then((response: any) => {
      loading.value = false;
      if (response.status === 201) {
        toast.removeGroup('chargePark');
        showSuccess(CREATE);
        closeModal();
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('chargePark');
      showError(CREATE);
      throw new Error(error);
    });
};

const editChargePark = async () => {
  await v$.value.$validate();
  if (v$.value.$error) return;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...data } = chargePark.value;

  data.owner_organisation_id = chargeParkEditionData.value.owner_organisation_id;

  loading.value = true;

  await store
    .updateChargePark(data)
    .then((response: any) => {
      loading.value = false;
      if (response.status === 200) {
        toast.removeGroup('chargePark');
        showSuccess(EDIT);
        closeModal();
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('chargePark');
      showError(EDIT);
      throw new Error(error);
    });
};

const setCoordinates = (lat: number, lng: number, validation: any) => {
  chargePark.value.latitude = lat.toString();
  chargePark.value.longitude = lng.toString();
  latitude.value = lat;
  longitude.value = lng;
  validationData.value = validation;
};

watch(
  () => store.chargeParkEditionData,
  (value) => {
    if (value.name !== '') {
      chargePark.value.name = value.name;
      chargePark.value.address = value.address;
      chargePark.value.country_code = value.country_code;
      chargePark.value.latitude = value.latitude ? value.latitude : '';
      chargePark.value.longitude = value.longitude ? value.longitude : '';
      address.value = value.address;

      if (value.latitude && value.longitude) {
        latitude.value = Number(value.latitude);
        longitude.value = Number(value.longitude);
      }
    }
  }
);

watch(
  () => chargePark.value.country_code,
  (oldVal, newVal) => {
    if (newVal === '') {
      v$.value.country_code.$reset();
    }
    if (newVal !== '') {
      v$.value.country_code.$touch();
    }
  }
);

watch(
  () => chargeParkModalIsOpen.value,
  (value) => {
    if (value) {
      isVisible.value = true;
    } else {
      isVisible.value = false;
      v$.value.country_code.$reset();
    }
  }
);

const validateName = async () => {
  await v$.value.name.$validate();
};

const validateAddress = async (geoAddress: string, validation: any) => {
  chargePark.value.address = geoAddress;
  address.value = geoAddress;
  validationData.value = validation;
};

const validateLatitude = async (latitude: string, validation: any) => {
  chargePark.value.latitude = latitude;
  validationData.value = validation;
};

const validateLongitude = async (longitude: string, validation: any) => {
  chargePark.value.longitude = longitude;
  validationData.value = validation;
};

const resetForm = () => {
  chargePark.value.name = '';
  chargePark.value.country_code = '';
  chargePark.value.address = '';
  chargePark.value.latitude = '';
  chargePark.value.longitude = '';
  address.value = '';
  latitude.value = DEFAULT_LATITUDE;
  longitude.value = DEFAULT_LONGITUDE;
  v$.value.name.$reset();
  v$.value.country_code.$reset();
  v$.value.$reset();
  resetLocationValidation.value = true;
  store.resetChargeParkEditionData();
};

const formIsNotValid = computed(() => {
  return (
    chargePark.value.name === '' ||
    chargePark.value.country_code === null ||
    chargePark.value.country_code === '' ||
    (v$.value.name.$anyDirty && v$.value.name.$error) ||
    (validationData.value?.address.$anyDirty && validationData.value?.address.$error) ||
    (validationData.value?.latitude.$anyDirty && validationData.value?.latitude.$error) ||
    (validationData.value?.longitude.$anyDirty && validationData.value?.longitude.$error)
  );
});

const editFormIsNotValid = computed(() => {
  return (
    !v$.value.$anyDirty ||
    (v$.value.name.$anyDirty && v$.value.name.$error) ||
    (v$.value.country_code.$anyDirty && v$.value.country_code.$error) ||
    (validationData.value?.address.$anyDirty && validationData?.value.address.$error) ||
    (validationData.value?.latitude.$anyDirty && validationData?.value.latitude.$error) ||
    (validationData.value?.longitude.$anyDirty && validationData?.value.longitude.$error)
  );
});

const rules = computed(() => chargeParkRules);
const v$ = useVuelidate(rules, chargePark.value);
</script>

<style scoped lang="scss">
.input-errors {
  line-height: 16px;
}
</style>
