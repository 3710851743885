import { required, helpers, maxLength } from '@vuelidate/validators';
import { asciiCharacters } from '@/utils/validationRules';
import { MAX_LENGTH } from '@/utils/constants';
import i18n from '@/i18n';

export const downtimeRules = {
  reason: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('downtimes.reason').toLowerCase(),
        item: i18n.global.t('downtimes.downtime').toLowerCase(),
      }),
      required
    ),
    asciiCharacters: helpers.withMessage(
      i18n.global.t('rules.asciiCharacters', { field: i18n.global.t('downtimes.reason') }),
      asciiCharacters
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
  systems: {
    required: helpers.withMessage(i18n.global.t('rules.systemRequired'), required),
  },
  start_time: {
    required: helpers.withMessage(i18n.global.t('rules.startTimeRequired'), required),
  },
  end_time: {
    required: helpers.withMessage(i18n.global.t('rules.endTimeRequired'), required),
  },
};
