import type { ProductTypeSetting } from '@/models/productTypes.model';
import {
  settingIsNumber,
  settingIsNumberGeneric,
  isNumericalType,
} from '@/models/productTypes.model';
import type { GenericSetting } from '@/models/datatypes.model';

export const pathMatcherWithWildcards = (toMatch: string, originalString: string) => {
  const originalStringSections = originalString.split('.');
  const toMatchSections = toMatch.split('.');
  if (originalStringSections.length !== toMatchSections.length) return false;
  for (let i = 0; i < originalStringSections.length; i++) {
    if (originalStringSections[i] !== '*' && toMatchSections[i] !== originalStringSections[i])
      return false;
  }
  return true;
};

//-------------------- Validator map
type Validator = {
  label: string;
  isValid: (value: unknown) => boolean;
};

export const isValidFloat = (value: unknown) => {
  return typeof value === 'number' || !Number.isNaN(parseFloat(value as string));
};

export const isValidInt = (size: number, signed: boolean) => (value: unknown) => {
  if (Number.isNaN(value)) return false;
  const isInt = typeof value === 'string' ? !value.includes('.') : Number.isInteger(value);
  const int = parseInt(value as string);
  const min = signed ? (-2) ** (size - 1) : 0;
  const max = signed ? 2 ** (size - 1) - 1 : 2 ** size - 1;
  return isInt && int >= min && int <= max;
};

export const typeValidatorMap: Record<ProductTypeSetting['type'], Validator[]> = {
  bool: [],
  double: [{ label: 'double', isValid: isValidFloat }],
  float: [{ label: 'float', isValid: isValidFloat }],
  int16: [{ label: 'int16', isValid: isValidInt(16, true) }],
  int32: [{ label: 'int32', isValid: isValidInt(32, true) }],
  int8: [{ label: 'int8', isValid: isValidInt(8, true) }],
  string: [],
  enum: [],
  uint16: [{ label: 'uint16', isValid: isValidInt(16, false) }],
  uint32: [{ label: 'uint32', isValid: isValidInt(32, false) }],
  uint8: [{ label: 'uint8', isValid: isValidInt(8, false) }],
};

export const buildValidatorsForSetting = (setting: ProductTypeSetting): Validator[] => {
  const validators = [...typeValidatorMap[setting.type]];

  if (settingIsNumber(setting)) {
    const { minValue, maxValue } = setting;

    if (minValue !== undefined)
      validators.push({
        label: `Min: ${minValue}`,
        isValid: (value) => !Number.isNaN(value) && Number(value) >= minValue,
      });

    if (maxValue !== undefined)
      validators.push({
        label: `Max: ${maxValue}`,
        isValid: (value) => !Number.isNaN(value) && Number(value) <= maxValue,
      });
  }

  return validators;
};

export const buildValidatorsForSettingGeneric = (setting: GenericSetting): Validator[] => {
  const validators = [...typeValidatorMap[setting.type]];

  if (settingIsNumberGeneric(setting)) {
    const { minValue, maxValue } = setting;

    if (minValue !== undefined)
      validators.push({
        label: `Min: ${minValue}`,
        isValid: (value) => !Number.isNaN(value) && Number(value) >= minValue,
      });

    if (maxValue !== undefined)
      validators.push({
        label: `Max: ${maxValue}`,
        isValid: (value) => !Number.isNaN(value) && Number(value) <= maxValue,
      });
  }

  return validators;
};

export const buildValidatorsForInput = (
  type: GenericSetting['type'],
  min?: number,
  max?: number
): Validator[] => {
  const validators = [...typeValidatorMap[type]];

  if (isNumericalType(type) && min !== undefined) {
    validators.push({
      label: `Min: ${min}`,
      isValid: (value) => !Number.isNaN(value) && Number(value) >= min,
    });
  }

  if (isNumericalType(type) && max !== undefined) {
    validators.push({
      label: `Max: ${max}`,
      isValid: (value) => !Number.isNaN(value) && Number(value) <= max,
    });
  }

  return validators;
};
