<template>
  <page-header :title="t('dashboard.page.title')" />
  <div v-if="panel === null" class="loader-wrapper flex">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <widget-panel
    v-else
    :panel="panel"
    :additional-filters="[]"
    :context-resolver="contextResolver"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import PageHeader from '@/components/common/layout/PageHeader.vue';
import WidgetPanel from '@/components/dashboard/layout/WidgetPanel.vue';
import type { Panel } from '@/stores/admin/dashboard/dashboard.types';
import { loadPanel } from '@/stores/admin/dashboard/dashboard.api';
import { WidgetContextResolver } from '@/stores/admin/dashboard/dashboard.logic.context';

const { t } = useI18n();
const panel = ref<Panel | null>(null);

const contextResolver = new WidgetContextResolver();

onMounted(async () => {
  await contextResolver.init({ force: true });
  panel.value = await loadPanel();
});
</script>

<style lang="scss"></style>
