<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="tariff-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
  >
    {{ $t('notAuthorized') }}
  </Message>
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="tariff-table"
    :value="tariffs"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    filterDisplay="menu"
    dataKey="id"
    class="tariff-table p-datatable-sm mb-5 mt-6 border-rounded overflow-hidden"
    v-model:selection="selectedTariff"
    selectionMode="single"
    @row-click="onRowSelect"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            data-cy="tariff-table-search"
            v-model="filters['global'].value"
            :placeholder="$t('searchBy', { name: `${$t('name')}, ${$t('organisation.title')}` })"
            class="p-inputtext-sm"
          />
        </span>
        <div class="flex justify-end items-center">
          <Button
            data-cy="add-tariff-button"
            class="p-button-primary p-button-sm"
            icon="pi pi-plus"
            :label="$t('tariff.newTariff')"
            @click="openCreateTariffModal"
          />
        </div>
      </div>
    </template>
    <template #empty>
      <span data-cy="tariff-table-empty" class="w-full block text-center">
        {{ $t('tariff.empty') }}
      </span>
    </template>
    <Column
      class="no-top-border-row"
      field="name"
      :header="$t('name')"
      :sortable="true"
      :show-filter-match-modes="false"
      style="min-width: 9rem"
    >
      <template #body="{ data }: { data: Tariff }">
        <span data-cy="tariff-name" class="text-base">
          {{ data.name }}
        </span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name').toLowerCase() })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="charge_parks"
      :header="$t('chargePark.includedSites')"
      :sortable="false"
      style="min-width: 8rem"
    >
      <template #body="{ data }: { data: Tariff }">
        <span v-if="data?.charge_parks?.length > 0" data-cy="tariff-sites" class="text-base">
          <i
            class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 mr-2"
            v-tooltip.right="{
                value: `${data?.charge_parks.map((chargePark: IdName) => `<div class='flex flex-nowrap w-full'><span class='pr-2'>${chargePark.name}</span></div>`).join('')}`,
                escape: true,
                class: 'custom-tooltip site-tooltip',
              }"
          >
          </i>
          <span data-cy="tariff-site-count">{{ showChargePark(data.charge_parks) }}</span>
        </span>
        <span v-else data-cy="tariff-site-count">
          <i class="pi pi-exclamation-triangle text-amber-500 text-lg ml-1 mr-2"></i>0
          {{ $t('chargeParks') }}
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="owner_organisation_name"
      :header="$t('createdByOrganization')"
      :sortable="true"
      :show-filter-match-modes="false"
    >
      <template #body="{ data }: { data: Tariff }">
        <span data-cy="tariff-owner-organisation-name">{{ data.owner_organisation_name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('organisation.title') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="modified_at"
      :header="$t('updatedAt')"
      :sortable="true"
      style="min-width: 9rem"
    >
      <template #body="{ data }: { data: Tariff }">
        <span data-cy="playlist-time" class="time">
          <date-time-display :date="(data as Tariff).modified_at" />
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }">
        <div class="flex">
          <Button
            data-cy="open-edit-tariff-modal"
            :label="$t('details')"
            icon="pi pi-arrow-right"
            iconPos="right"
            class="p-button-sm p-button-outlined p-button-plain p-button-sm"
            @click="navigateToSystemsDetailPage(data)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <TariffCreateEditModal />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { TARIFFS } from '@/utils/routeNames';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import TariffCreateEditModal from './TariffCreateEditModal.vue';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';

import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';

import type { Tariff } from '@/models/tariffs.model';
import type { IdName } from '@/models/common.model';

const { t } = useI18n();
const router = useRouter();
const tariffsStore = useTariffsStore();
const { tariffs, tariffCreateModalIsOpen, tariffDetails } = storeToRefs(tariffsStore);
const chargeParksStore = useChargeParksStore();

const selectedTariff = ref();
const loading = ref(true);
const notAuthorized = ref(false);

const navigateToSystemsDetailPage = (data: Tariff) => {
  tariffDetails.value = data;
  chargeParksStore.fetchDaysList();
  router.push(`/${TARIFFS}/${data.id}`);
};

const onRowSelect = (event: any) => {
  tariffDetails.value = event.data;
  chargeParksStore.fetchDaysList();
  router.push(`/${TARIFFS}/${event.data.id}`);
};

const showChargePark = (chargeParks: IdName[]) => {
  return chargeParks.length === 1
    ? chargeParks.length + ` ${t('chargePark.title')}`
    : chargeParks.length + ` ${t('chargeParks')}`;
};

const openCreateTariffModal = () => {
  chargeParksStore.fetchDaysList();
  tariffCreateModalIsOpen.value = true;
};

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  owner_organisation_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

onMounted(async () => {
  try {
    await tariffsStore.fetchTariffList();
  } catch (error: any) {
    if (error?.response?.status === 403) {
      notAuthorized.value = true;
    } else {
      throw new Error('Tariff list failed to be fetched');
    }
  } finally {
    loading.value = false;
  }
});
</script>
