<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="playlist-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
  >
    {{ t('notAuthorized') }}
  </Message>
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="playlist-table"
    :value="playlists"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    filterDisplay="menu"
    dataKey="id"
    class="p-datatable-sm mb-5 mt-6 border-rounded overflow-hidden playlist-table"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            data-cy="playlist-table-search"
            v-model="filters['global'].value"
            :placeholder="t('searchBy', { name: `${t('name')}, ${t('organisation.title')}` })"
            class="p-inputtext-sm"
          />
        </span>
        <div v-if="activeRoles" class="flex justify-end items-center p-4">
          <Button
            data-cy="open-create-playlist-modal"
            :label="t('playlists.newPlaylist')"
            icon="pi pi-plus"
            class="p-button-sm p-button-primary"
            @click="openCreatePlaylistModal"
          />
        </div>
      </div>
    </template>
    <template #empty>
      <span data-cy="playlist-table-empty" class="w-full block text-center">
        {{ t('playlists.empty') }}
      </span>
    </template>
    <Column
      class="no-top-border-row"
      field="name"
      :header="t('name')"
      :sortable="true"
      :show-filter-match-modes="false"
      style="min-width: 10rem"
    >
      <template #body="{ data }: { data: Playlist }">
        <span data-cy="playlist-name">{{ data.name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="t('searchBy', { name: t('name') })"
        />
      </template>
    </Column>
    <!-- <Column
      class="no-top-border-row"
      field="total_duration"
      :header="t('playlists.totalDuration')"
      :sortable="true"
      style="min-width: 10rem"
    >
      <template #body="{ data }: { data: Playlist }">
        <span data-cy="playlist-total-duration">{{ data.total_duration }}</span>
      </template>
    </Column> -->
    <Column
      class="no-top-border-row"
      field="media"
      :header="t('playlists.mediaItems')"
      :sortable="false"
      style="min-width: 12rem"
    >
      <template #body="{ data }: { data: Playlist }">
        <span v-if="data.image_count > 0 || data.video_count > 0" data-cy="playlist-media-items">
          <i
            :class="`${
              playlistHasUnprocessedMedia(data.media)
                ? 'pi-exclamation-triangle text-orange-primary'
                : 'pi-eye'
            } pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 mr-2`"
            v-tooltip.right="{
              value: mediaItemsTooltip(data.media),
              escape: true,
              class: 'custom-tooltip contacts-tooltip',
            }"
          >
          </i>
          <span data-cy="playlist-image-count"> {{ showImageCount(data.image_count) }} </span>,
          <span data-cy="playlist-video-count" class="pl-1">{{
            showVideoCount(data.video_count)
          }}</span>
        </span>
        <span v-else data-cy="playlist-no-media">
          <i class="pi pi-exclamation-triangle text-amber-500 text-lg ml-1 mr-2"></i>
          <span data-cy="playlist-image-count">0 {{ t('playlists.images') }} </span>,
          <span data-cy="playlist-video-count" class="pl-1">0 {{ t('playlists.videos') }}</span>
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="charge_parks"
      :header="t('chargeParks')"
      :sortable="false"
      style="min-width: 7rem"
    >
      <template #body="{ data }: { data: Playlist }">
        <span v-if="data.charge_parks.length > 0" data-cy="playlist-sites">
          <i
            class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 mr-2"
            v-tooltip.right="{
              value: `${data.charge_parks.map((chargePark: ChargeParkAlert) => `<div class='flex flex-nowrap w-full'><span class='pr-2'>${chargePark.name}</span></div>`).join('')}`,
              escape: true,
              class: 'custom-tooltip site-tooltip',
            }"
          >
          </i>
          <span data-cy="playlist-site-count">{{
            data.charge_parks.length === 1
              ? data.charge_parks.length + ' Site'
              : data.charge_parks.length + ' Sites'
          }}</span>
        </span>
        <span v-else data-cy="playlist-no-charge-parks">
          <i class="pi pi-exclamation-triangle text-amber-500 text-lg ml-1 mr-2"></i>
          <span data-cy="playlist-site-count">0 {{ t('chargeParks') }}</span>
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="owner_organisation_name"
      :header="t('createdByOrganization')"
      :sortable="true"
      :show-filter-match-modes="false"
    >
      <template #body="{ data }: { data: Playlist }">
        <span data-cy="playlist-owner-organisation-name">{{ data.owner_organisation_name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="t('searchBy', { name: t('organisation.title') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="modified_at"
      :header="t('updatedAt')"
      :sortable="true"
      style="min-width: 9rem"
    >
      <template #body="{ data }: { data: Playlist }">
        <date-time-display data-cy="playlist-updated" :date="(data as Playlist).modified_at" />
      </template>
    </Column>
    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="t('actions')"
      style="width: 13rem"
    >
      <template #body="{ data }: { data: Playlist }">
        <div>
          <custom-button
            v-if="activeRoles"
            data-cy="open-edit-playlist-modal"
            :disabled="!data.writable"
            :disabled-tooltip="
              t('permissions.info.parentOrgOwnsResource.editNotAllowed', {
                resource: t('resources.playlist', { count: 1 }),
              })
            "
            class="mr-2"
            @click="openEditPlaylistModal(data)"
          >
            <pencil-icon class="w-5 h-5" />
            <span>{{ t('edit') }}</span>
          </custom-button>
          <custom-button
            v-if="activeRoles"
            data-cy="open-delete-playlist-modal"
            :disabled="!data.writable"
            :disabled-tooltip="
              t('permissions.info.parentOrgOwnsResource.editNotAllowed', {
                resource: t('resources.playlist', { count: 1 }),
              })
            "
            @click="openDeletePlaylistModal(data.id)"
          >
            <trash-icon class="w-5 h-5" />
            <span>{{ t('delete') }}</span>
          </custom-button>
        </div>
      </template>
    </Column>
  </DataTable>
  <PlaylistDeleteModal />
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useMediaStore } from '@/stores/admin/media/media.store';
import { usePlaylistsStore } from '@/stores/admin/playlists/playlists.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { CONTENT_ADMIN_ROLE, ADMINISTRATOR_ROLE } from '@/utils/constants';

import Chip from 'primevue/chip';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import PlaylistDeleteModal from './PlaylistDeleteModal.vue';

import type { Playlist } from '@/models/playlists.model';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { ChargeParkAlert } from '@/models/chargeParks.model';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { MediaItem } from '@/models/playlists.model';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';

import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
import CustomButton from '@/components/common/button/CustomButton.vue';

const { t } = useI18n();
const playlistsStore = usePlaylistsStore();
const mediaStore = useMediaStore();
const {
  isPlaylistModalOpen,
  playlistModalData,
  playlistDeleteModalIsOpen,
  playlistDeleteData,
  playlists,
  playlistId,
  isEditMode,
} = storeToRefs(playlistsStore);
const userStore = useUsersStore();
const loading = ref(true);
const notAuthorized = ref(false);

const playlistHasUnprocessedMedia = (mediaItems: MediaItem[]) => {
  const processedState = mediaStore.processedMediaStatusID;
  return !!mediaItems.find((item) => Number(item.status) !== processedState);
};

const mediaItemsTooltipItem = (mediaItem: MediaItem) => {
  const processedState = mediaStore.processedMediaStatusID;

  const isNotProcessed = processedState !== Number(mediaItem.status);
  let result = `<div class='${isNotProcessed?'text-orange-primary':''}'>
                  <span class='font-medium'>Name:</span><span class='${isNotProcessed?' ':'text-neutral-500'} pl-1'> ${mediaItem.name}</span>
                  <div class='flex '>
                    <span class='font-medium'>Type:</span><span class='${isNotProcessed?' ':'text-neutral-500'} pl-2.5'> ${mediaItem.type}</span></div>`;

  if(isNotProcessed) result += `<span class='text-xs rounded p-1 bg-orange-primary text-black' >${t('playlists.mediaItemNotProcessed')}</span>`;

  result += `</div>`;
  return result;
};

const mediaItemsTooltip = (media: MediaItem[]) => {
  const hasUnprocessed = playlistHasUnprocessedMedia(media);
  let result = `<div class="mb-${hasUnprocessed?'2':'8'}"  >`;

  if (hasUnprocessed) {
    result += `<p class="text-orange-primary"><i class='pi pi-exclamation-triangle'></i>${t(
      'playlists.warnNotProcessedMedia'
    )}</p>`;
  }

  return result + media.map(mediaItemsTooltipItem).join('') + "</div>";
};

const showImageCount = (count: number) => {
  if (count === 1) {
    return `${count} ${t('playlists.image')}`;
  } else {
    return `${count} ${t('playlists.images')}`;
  }
};

const showVideoCount = (count: number) => {
  if (count === 1) {
    return `${count} ${t('playlists.video')}`;
  } else {
    return `${count} ${t('playlists.videos')}`;
  }
};

const openCreatePlaylistModal = () => {
  isPlaylistModalOpen.value = true;
};

const activeRoles = computed(
  () =>
    userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
    userStore.me.roles.map((role) => role.id).includes(CONTENT_ADMIN_ROLE)
);

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  owner_organisation_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const openEditPlaylistModal = (data: any) => {
  playlistsStore.fetchSinglePlaylist(data.id);
  playlistsStore.fetchSinglePlaylistMedia(data.id);
  playlistModalData.value.name = data.name;
  playlistModalData.value.owner_organisation_name = data.owner_organisation_name;
  playlistId.value = data.id;
  isEditMode.value = true;
  isPlaylistModalOpen.value = true;
};

const openDeletePlaylistModal = (id: number) => {
  playlistDeleteModalIsOpen.value = true;
  playlistDeleteData.value.id = id;
};

onMounted(async () => {
  try {
    await Promise.all([playlistsStore.fetchAllPlaylists(), mediaStore.fetchMediaStatus()]);
  } catch (error: any) {
    if (error?.response?.status === 403) {
      notAuthorized.value = true;
    } else {
      throw new Error('Playlists failed to be fetched');
    }
  } finally {
    loading.value = false;
  }
});
</script>
