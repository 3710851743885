import { defineStore } from 'pinia';
import { fetchGeoLocation } from '@/stores/admin/location/location.api';
import type { Coordinates } from '@/models/location.model';

interface LocationState {
  geoLocation: Coordinates;
}

export const useLocationStore = defineStore('geoLocation', {
  state: (): LocationState => ({
    geoLocation: {
      latitude: '',
      longitude: '',
    },
  }),
  actions: {
    async fetchLocation(address: string) {
      return await fetchGeoLocation(address)
        .then((response) => {
          this.geoLocation = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
});
