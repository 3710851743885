export enum UnitSystem {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export enum TimeSystem {
  TIME_12H = '12h (am/pm)',
  TIME_24H = '24h',
}

export interface EnumValue {
  value: string;
  label: string;
  selectable?: boolean;
}

export interface GenericSettingCommon {
  id: string;
  name: string;
  description: string;
}

export interface GenericSettingBoolean extends GenericSettingCommon {
  type: 'bool';
  defaultValue: boolean;
}

export interface GenericSettingNumber extends GenericSettingCommon {
  type: 'int8' | 'uint8' | 'int16' | 'uint16' | 'int32' | 'uint32' | 'float' | 'double';
  defaultValue: number;
  minValue?: number;
  maxValue?: number;
  unit?: string;
}

export interface GenericSettingString extends GenericSettingCommon {
  type: 'string';
  defaultValue: string;
}

export interface GenericSettingEnum extends GenericSettingCommon {
  type: 'enum';
  enumValues: EnumValue[];
  defaultValue: number;
}

export type GenericSetting =
  | GenericSettingBoolean
  | GenericSettingNumber
  | GenericSettingString
  | GenericSettingEnum;
