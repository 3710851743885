import { defineStore } from 'pinia';
import {
  deleteDowntimeRequest,
  fetchDowntimes,
  createDowntime,
  updateDowntime,
} from '@/stores/admin/downtimes/downtimes.api';

import type { Downtime, DowntimeEditData } from '@/models/downtimes.model';

interface DowntimesState {
  downtimes: Downtime[];
  downtimesActiveTab: number;
  downtimeCreateModalIsOpen: boolean;
  downtimeDeleteModalIsOpen: boolean;
  downtimeModalData: DowntimeEditData;
  downtimeEditId: number | null;
  downtimeDeleteId: number | null;
}

export const useDowntimesStore = defineStore('downtimes', {
  state: (): DowntimesState => ({
    downtimes: [],
    downtimesActiveTab: 1,
    downtimeCreateModalIsOpen: false,
    downtimeDeleteModalIsOpen: false,
    downtimeModalData: {
      reason: null,
      systems: [],
      start_time: null,
      end_time: null,
    },
    downtimeEditId: null,
    downtimeDeleteId: null,
  }),

  getters: {
    isEditMode(state): boolean {
      return state.downtimeEditId !== null;
    },
  },

  actions: {
    async fetchDowntimeList() {
      try {
        const res = await fetchDowntimes();
        this.downtimes = res.data;

        return res;
      } catch (error: unknown) {
        return Promise.reject(error);
      }
    },

    async deleteDowntime() {
      try {
        if (!this.downtimeDeleteId) throw new Error('No downtime id provided');

        await deleteDowntimeRequest(this.downtimeDeleteId);
        this.downtimeDeleteId = null;
        this.downtimeDeleteModalIsOpen = false;
      } catch (error: unknown) {
        return Promise.reject(error);
      }
    },

    async createNewDowntime() {
      try {
        return await createDowntime(this.downtimeModalData);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async editDowntime() {
      try {
        if (this.downtimeEditId === null) {
          return Promise.reject('No downtime id to edit');
        }

        return await updateDowntime(this.downtimeEditId, this.downtimeModalData);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    setDowntimeToEdit(id: number) {
      const downtime = this.downtimes.find((item: Downtime) => item.id === id);
      this.downtimeModalData = {
        reason: downtime?.reason || null,
        systems: downtime?.systems.map((item) => item.id) || [],
        start_time: downtime?.start_time || null,
        end_time: downtime?.end_time || null,
      };
    },

    resetDowntimeModalData() {
      this.downtimeModalData = {
        reason: null,
        systems: [],
        start_time: null,
        end_time: null,
      };
    },
  },
});
