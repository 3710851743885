<template>
  <div v-if="props?.enumValues !== undefined" class="enum-input-container">
    <select v-model="updatedValue" :data-cy="`${props.id}-value-input`">
      <option
        v-for="enumValue in props.enumValues"
        :value="enumValue.value"
        :key="enumValue.value"
        :disabled="enumValue.selectable === undefined ? false : !enumValue.selectable"
      >
        {{ enumValue.label }}
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps<{
  id: string;
  enumValues?: { value: string | number; label: string; selectable?: boolean }[];
  defaultValue: number | string | boolean | undefined;
  valid: boolean;
}>();

const emit = defineEmits<{
  (event: 'update:value', value: number | string | boolean | undefined): void;
}>();

const updatedValue = ref(props.defaultValue || 0);

watch(updatedValue, (value) => emit('update:value', value));
</script>

<style scoped lang="scss">
.enum-input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

select {
  width: 100%;
  padding: 0.25rem 0.5rem;
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
  background-color: var(--gray-0);
  outline: none;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
</style>
