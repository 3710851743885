<template>
  <div class="picker-table pt-4">
    <div class="container">
      <div v-if="loading" class="loader-wrapper h-[200px]">
        <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
      </div>
      <DataTable
        v-if="!loading"
        :value="contacts"
        dataKey="id"
        :scrollable="true"
        scrollHeight="195px"
        class="p-datatable-sm contacts-picker-table picker-table"
        v-model:filters="filters"
        filterDisplay="menu"
        :globalFilterFields="['name', 'type', 'enabled']"
        :metaKeySelection="false"
        v-model:selection="selectedContact"
        @rowSelect="onSelectedContact"
        @rowUnselect="onUnselectedContact"
        @rowSelectAll="onSelectedAllContacts"
        @rowUnselectAll="onUnselectedAllContacts"
      >
        <template #empty>{{ $t('contact.empty') }}</template>
        <template #loading>{{ $t('loading') }}</template>
        <template #header>
          <div class="flex justify-between align-items-center">
            <span class="p-input-icon-left search">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('searchBy', { name: `${$t('name')}, ${$t('type')}` })"
                class="p-inputtext-sm"
              />
            </span>
          </div>
        </template>
        <Column
          :selectionMode="$props.selectMode"
          :style="{
            flex: 0,
            padding: 0,
            paddingLeft: $props.selectMode === 'multiple' ? '16px' : 0,
          }"
          bodyStyle="padding-left: 16px"
        ></Column>
        <Column field="name" :header="$t('name')">
          <template #body="{ data }">
            <div class="w-full pl-1">
              <span :id="data.id" class="text-sm font-medium" data-cy="contact-name">{{
                data.name
              }}</span>
            </div>
          </template>
        </Column>
        <Column class="no-top-border-row" field="address" :header="$t('address')">
          <template #body="{ data }">
            <span v-if="data.type === 'EMAIL' && data.email" data-cy="contact-address">{{
              data.email
            }}</span>
            <span v-if="data.type === 'SMS' && data.phone_number" data-cy="contact-address">{{
              data.phone_number
            }}</span>
          </template>
        </Column>
        <Column field="type" :header="$t('type')" style="flex: 0 0 0; min-width: 90px">
          <template #body="{ data }">
            <div class="flex justify-start">
              <span data-cy="contact-type" class="text-small">{{ data.type }}</span>
            </div>
          </template>
        </Column>
        <Column field="enabled" :header="$t('enabled')" style="flex: 0 0 0; min-width: 120px">
          <template #body="{ data }">
            <div class="flex justify-start">
              <Tag
                data-cy="contact-enabled"
                class="mr-2"
                :severity="`${data.enabled ? 'success' : 'info'}`"
                :value="`${data.enabled ? 'Enabled' : 'Disabled'}`"
                rounded
              ></Tag>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { FilterMatchMode } from 'primevue/api';
import { useContactsStore } from '@/stores/admin/contacts/contacts.store';

import Tag from 'primevue/tag';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

import type { SelectMode } from '@/models/chargeParks.model';
import type { Contact } from '@/models/contacts.model';

const contactsStore = useContactsStore();
const { contacts } = storeToRefs(contactsStore);
const loading = ref(true);
const selectedContact = ref<Contact[]>([]);

const props = defineProps<{
  selectMode: SelectMode;
  value?: number[];
}>();

const emit = defineEmits([
  'onContactSelect',
  'onContactUnselect',
  'onContactSelectAll',
  'onContactUnselectAll',
]);

const onSelectedContact = (event: any) => {
  emit('onContactSelect', event.data.id);
};

const onUnselectedContact = (event: any) => {
  emit('onContactUnselect', event.data.id);
};

const onSelectedAllContacts = (event: any) => {
  const ids = event.data.map((item: any) => item.id);
  emit('onContactSelectAll', ids);
};

const onUnselectedAllContacts = () => {
  emit('onContactUnselectAll');
};

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

onMounted(async () => {
  if (!contacts.value.length) {
    await contactsStore.fetchContactsList();
  }

  if (props.value?.length) {
    selectedContact.value = contacts.value.filter((contact: Contact) =>
      props.value?.includes(contact.id)
    );
  }

  loading.value = false;
});
</script>

<style lang="scss" scoped>
:deep(input.p-inputtext-sm::placeholder) {
  color: $text-light;
}
</style>
