<template>
  <div class="flex field-no-edit">
    <div :class="`w-full ${systemModalIsOpen ? 'info-double' : 'info-single'}`">
      <div
        class="flex justify-between py-2 info-item"
        v-for="(value, key) in transformedData"
        :key="key"
      >
        <span class="text-grey-medium font-semibold">{{
          key === 'name' ? t('name') : t(`hardwareSystem.${key}`)
        }}</span>
        <span v-if="value" class="text-grey-medium" :data-cy="'hardware_system_'+key+'_value'">{{ value }}</span>
        <span v-else class="text-grey">{{ t('none') }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { HardwareSystemReadOnly } from '@/models/hardwareSystems.model';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';

const { t } = useI18n();
const hardwareSystemsStore = useHardwareSystemsStore();
const { systemModalIsOpen } = storeToRefs(hardwareSystemsStore);

const props = defineProps<{
  data: HardwareSystemReadOnly;
}>();

const transformedData = computed(() => {
  const { name, nidec_id, custom_id, owner_organisation_name, charge_park_name } = props.data;

  const filtered = systemModalIsOpen.value
    ? {
        name,
        charge_park_name,
        nidec_id,
        owner_organisation_name,
        custom_id,
      }
    : {
        custom_id,
        charge_park_name,
        owner_organisation_name,
      };

  return Object.fromEntries(
    Object.entries(filtered).map(([k, v]) => [
      k.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m: any, chr: any) => chr.toUpperCase()),
      v,
    ])
  );
});
</script>

<style lang="scss" scoped></style>
