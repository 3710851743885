import { defineStore } from 'pinia';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import type {
  Organisation,
  OrganisationEdit,
  Feature,
  OrganisationWithUsers,
} from '@/models/organisation.model';
import {
  fetchOrganizations,
  createOrganisation,
  editOrganisation,
  fetchFeatures,
  fetchSingleOrganisation,
} from '@/stores/admin/organizations/organisations.api';

interface OrganisationsState {
  organisations: Organisation[];
  organisationModalIsOpen: Boolean;
  organisationEditionData: Organisation;
  organisationIsEditing: Boolean;
  features: Feature[];
  userOrganisationData: OrganisationWithUsers | undefined;
}

export const useOrganisationsStore = defineStore('organisations', {
  state: (): OrganisationsState => ({
    organisations: [],
    organisationModalIsOpen: false,
    organisationIsEditing: false,
    organisationEditionData: {
      id: 0,
      name: '',
      parent_organisation_id: 0,
      parent_organisation_name: '',
      available_features: [],
      available_roles: [],
    },
    features: [],
    userOrganisationData: {
      id: 0,
      name: '',
      parent_organisation_id: 0,
      parent_organisation_name: '',
      available_features: [],
      available_roles: [],
      users: [],
    },
  }),

  actions: {
    async fetchOrganisationsList() {
      return await fetchOrganizations()
        .then((response) => {
          this.organisations = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async fetchOrganisationById(id: number) {
      return await fetchSingleOrganisation(id)
        .then((response) => {
          const item = this.organisations.find((organisation: any) => organisation.id === id);
          if (item) {
            item.name = response.data.name;
            item.parent_organisation_id = response.data.parent_organisation_id;
            item.parent_organisation_name = response.data.parent_organisation_name;
            item.available_features = response.data.available_features;
            item.available_roles = response.data.available_roles;
          } else {
            this.organisations.push(response.data);
          }

          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async createNewOrganisation(data: OrganisationEdit) {
      return await createOrganisation(data)
        .then((response) => {
          this.fetchOrganisationsList().then(() => {
            this.fetchUsersForOrganisations(this.organisations);
          });
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async updateOrganisation(data: OrganisationEdit) {
      return await editOrganisation(this.organisationEditionData.id, data)
        .then((response) => {
          this.fetchOrganisationsList().then(() => {
            this.fetchUsersForOrganisations(this.organisations);
          });
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    setOrganisationEditionData(data: Organisation) {
      this.organisationEditionData = data;
    },
    resetOrganisationEditionData() {
      this.organisationEditionData = {
        id: 0,
        name: '',
        parent_organisation_id: 0,
        parent_organisation_name: '',
        available_features: [],
        available_roles: [],
      };
    },
    async fetchUsersForOrganisations(organisations: Organisation[]) {
      const usersStore = useUsersStore();

      return await organisations.forEach((organisation: any) => {
        usersStore
          .fetchOrganisationUsersList(organisation.id)
          .then((response) => {
            organisation.users = response.data;
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      });
    },
    fetchUsersForSingleOrganisation(organisationId: number) {
      const usersStore = useUsersStore();

      return usersStore
        .fetchOrganisationUsersList(organisationId)
        .then((response) => {
          if (response.data) {
            this.organisations.forEach((organisation: any) => {
              if (organisation.id === organisationId) {
                organisation.users = response.data;
              }
            });
          }
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async fetchChargeParksForOrganisations(organisations: Organisation[]) {
      const chargeParksStore = useChargeParksStore();

      return organisations.forEach((organisation: any) => {
        return chargeParksStore
          .fetchChargeParkListForOrganisation(organisation.id)
          .then((response) => {
            if (response.data) {
              organisation.chargeParks = response.data;
            }
            return response;
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      });
    },
    fetchChargeParksForSingleOrganisation(organisationId: number) {
      const chargeParksStore = useChargeParksStore();

      return chargeParksStore
        .fetchChargeParkListForOrganisation(organisationId)
        .then((response) => {
          if (response.data) {
            this.organisations.forEach((organisation: any) => {
              if (organisation.id === organisationId) {
                organisation.chargeParks = response.data;
              }
            });
          }
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async fetchFeaturesList() {
      return await fetchFeatures()
        .then((response) => {
          this.features = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
});
