<template>
  <div>
    <DataTable id="eventHistoryAggDetailsTable" :value="modelValue">
      <Column field="first_occurrence":header="$t('history.first_occurrence')">
        <template #body="{ data }: { data: HistoryAggregationMessageDetails }">
          <span data-cy="event-history-time" class="time whitespace-nowrap">
            <date-time-display
              :date="(data as HistoryAggregationMessageDetails).first_occurrence"
            />
          </span>
        </template>
      </Column>

      <Column field="last_occurrence" :header="$t('history.last_occurrence')">
        <template #body="{ data }: { data: HistoryAggregationMessageDetails }">
          <span data-cy="event-history-time" class="time whitespace-nowrap">
            <date-time-display
              :date="(data as HistoryAggregationMessageDetails).last_occurrence"
            />
          </span>
        </template>
      </Column>
      <Column field="content" :header="$t('details')"></Column>
      <Column field="count" :header="$t('history.totalCount')"></Column>

      <template #empty>
        <div class="grid justify-items-center">
        {{ $t("history.noData") }}
    </div>
      </template>
    </DataTable>
  </div>
</template>

<script setup lang="ts">
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import type { HistoryAggregationMessageDetails } from '@/models/history.model';

const modelValue = defineModel<HistoryAggregationMessageDetails[]>();
</script>

<style>
#eventHistoryAggDetailsTable.p-datatable .p-datatable-thead > tr > th {
  background-color: #F9F9F9 !important; 
}
#eventHistoryAggDetailsTable.p-datatable .p-datatable-tbody > tr {
  background-color: #F9F9F9 !important;
}
</style>
