<template>
  <div class="image-upload-container">
    <image-element :id="props.value" class="image-preview" />
    <label :for="`${props.id}-file-upload`" :class="{ disabled: uploading }">
      <cloud-arrow-up-icon class="w-6 h-6" />
      <span>Upload</span>
    </label>
    <input
      :id="`${props.id}-file-upload`"
      type="file"
      accept="image/png,image/jpg,image/jpeg"
      @change="uploadAndFetch"
      :disabled="uploading"
      hidden
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { uploadBrandingMedia } from '@/stores/admin/branding/ui.api';
import { CloudArrowUpIcon } from '@heroicons/vue/24/outline';
import ImageElement from '@/components/branding/common/ImageElement.vue';

const props = defineProps<{
  id: string;
  value?: number | string;
}>();

const emit = defineEmits<{
  (event: 'update:value', value: number): void;
}>();

const uploading = ref(false);

const uploadError = ref<string | undefined>(undefined);

async function uploadAndFetch(event: Event) {
  const files = (event.target as HTMLInputElement).files;
  const fileToUpload = files && files[0];

  if (!fileToUpload) return;

  uploading.value = true;
  uploadError.value = undefined;

  await uploadBrandingMedia(fileToUpload)
    .then(({ id }) => emit('update:value', id))
    .catch((err) => (uploadError.value = err.message));

  uploading.value = false;
}
</script>

<style scoped lang="scss">
.image-upload-container {
  display: flex;
  flex-direction: row;
}

.image-preview {
  position: relative;
  height: 6rem;
  width: 10rem;
  border: 1px solid var(--gray-200);
  border-radius: var(--rounded-md) 0 0 var(--rounded-md);
  background-color: var(--gray-50);
}

label {
  background-color: var(--gray-0);
  border: 1px solid var(--gray-200);
  border-left: none;
  border-radius: 0 var(--rounded-md) var(--rounded-md) 0;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 6rem;

  &:not(.disabled):hover {
    background-color: var(--gray-50);
  }

  &.disabled {
    cursor: not-allowed;
    background-color: var(--gray-100);
  }
}

.spinner {
  border: 3px solid var(--green-primary);
  border-top: 3px solid transparent;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
</style>
