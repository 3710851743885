<template>
  <div class="px-12">
    <div class="field flex flex-col pt-4">
      <label id="dataExportNameLabel" for="dataExportName" class="title font-medium text-gray-600">
        {{ $t('name') }}<span class="required">*</span>
      </label>
      <InputText
        data-cy="data-export-name-input"
        id="dataExportName"
        type="text"
        aria-describedby="dataExportNameLabel"
        class="p-inputtext-sm"
        v-model="dataExportModalData.name"
      />
      <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>
    <div class="field flex flex-col pt-4 pb-6">
      <label id="dataExportNameLabel" for="dataExportName" class="title font-medium text-gray-600">
        {{ $t('dataExport.dataTypes') }}<span class="required">*</span>
        <span class="block text-grey text-sm">{{ $t('dataExport.dataTypesDescription') }}</span>
      </label>
      <CustomCheckboxSelect
        :data="dataTypes"
        :selectedItemsIds="selectedDataTypes"
        itemsAmount="multiple"
        class="mt-2"
        @selectedCheckboxItems="setSelectedDataTypes"
      />
    </div>
    <div class="field flex flex-col pb-4">
      <label
        id="dataExportTimeRangeLabel"
        for="dataExportTimeRange"
        class="title font-medium text-gray-600"
      >
        {{ $t('dataExport.timeRange') }}<span class="required">*</span>
      </label>
      <span class="block text-grey text-sm">{{
        $t('dataExport.timeRangeInfo', { userTimezone: prettifyTimezone(me.local_timezone) })
      }}</span>
    </div>
    <DateTimeRangeInput
      :startedValueId="'dataexport-calendar-start-time'"
      :endedValueId="'dataexport-calendar-end-time'"
      :startedValueLocale="$t('dataExport.timeRangeStart')"
      :endedValueLocale="$t('dataExport.timeRangeEnd')"
      :startedAfterEndedErrorLocale="$t('errors.startDatetimeLessThanEndDatetime')"
      v-model:startedValue="dataExportModalData.start_datetime"
      v-model:endedValue="dataExportModalData.end_datetime"
      :maxEndDateTime="new Date().toISOString()"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue';
import { useDataExportsStore } from '@/stores/admin/dataExports/dataExports.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { storeToRefs } from 'pinia';
import { prettifyTimezone } from '@/utils/timezones';

import InputText from 'primevue/inputtext';
import DateTimeRangeInput from '@/components/common/time/DateTimeRangeInput.vue';
import CustomCheckboxSelect from '@/components/common/CustomCheckboxSelect.vue';

import type { dataExportRules } from '@/utils/dataExportModalRules';
import type { Validation } from '@vuelidate/core';

declare module 'dayjs' {
  interface Dayjs {
    $d: Date;
  }
}

const dataExportStore = useDataExportsStore();
const { dataExportModalData, dataTypes } = storeToRefs(dataExportStore);
const userStore = useUsersStore();
const { me } = storeToRefs(userStore);

const preselectedDataTypes = ref(
  dataExportModalData.value.datatypes.length === 0
    ? dataTypes.value.filter((item) => item.id === 1 || item.id === 2).map((item) => item.id)
    : dataExportModalData.value.datatypes
);

const selectedDataTypes = computed(() => dataExportModalData.value.datatypes);

const setSelectedDataTypes = (selectedDataType: number[]) => {
  dataExportModalData.value.datatypes = selectedDataType;
  props.v$.datatypes.$touch();
};

const props = defineProps<{
  v$: Validation<typeof dataExportRules>;
}>();

watch(
  () => dataExportModalData.value.name,
  () => {
    props.v$.name.$touch();
  },
  { deep: true }
);

watch(
  () => preselectedDataTypes.value,
  (value) => {
    dataExportModalData.value.datatypes = value;
  },
  { immediate: true }
);

onMounted(() => (dataExportModalData.value.end_datetime = new Date().toISOString()));
</script>

<style scoped lang="scss">
:deep(.p-calendar .p-inputtext) {
  width: 100%;
  flex: none;
  padding: 0.3rem 0.5rem;
}

:deep(input::placeholder) {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
</style>
