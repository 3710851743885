<template>
  <div class="flex flex-wrap text-xs">
     
    <div class="info-item">
      <span data-cy="system-details-nidec-id-title" class="label">{{ $t('type') }}</span>
      <span data-cy="system-details-nidec-id-value" class="value" :class="{ none: !props.productType?.name }">
        {{ props.productType?.name ?? '--' }}
      </span>
    </div>
    <div class="info-item">
      <span data-cy="system-details-nidec-id-title" class="label">{{ $t('hardwareSystem.nidecId') }}</span>
      <span data-cy="system-details-nidec-id-value" class="value" :class="{ none: !props.nidecId }">
        {{ props.nidecId ?? '--' }}
      </span>
    </div>
    <div class="info-item" @click="emits('go-to-firmware')">
      <span data-cy="system-details-version-title" class="label">{{ $t('update.firmwareVersion') }}</span>
      <span
        data-cy="system-details-version-value"
        
        class="value"
        :class="{ none: !props.firmwareVersion }"
      >
        {{ props.firmwareVersion || '--' }}
      </span>
    </div>
    <div class="info-item">
      <span data-cy="system-details-custom-id-title" class="label">
        {{ $t('hardwareSystem.inventoryNo') }}
      </span>
      <span data-cy="system-details-custom-id-value" class="value" :class="{ none: !props.customId }">
        {{ props.customId ?? '--' }}
      </span>
    </div> 
  </div>
</template>

<script setup lang="ts">
import type { ProductType } from '@/models/productTypes.model';

const props = defineProps<{
  lastOnline?: Date | string;
  nidecId?: string;
  firmwareVersion?: string;
  customId?: string;
  
  productType: ProductType | null;
}>();

const emits = defineEmits<{
  (event: 'go-to-firmware'): void;
}>();
</script>

<style scoped lang="scss">
.info-item {
  background-color: var(--gray-200);
  border-radius: var(--rounded-md);
  padding:4px;
  padding-left:6px;
  padding-right:6px;
  margin: 4px;
}
.info-grid {
  display: grid;
  grid-template-columns: repeat(4, auto);
  margin: 1rem;
  grid-column-gap: 1rem;
  grid-row-gap: 0.25rem;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid var(--gray-200);
  background-color: var(--gray-25);

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, auto);
  }
}

span {
  margin: 0;
  font-size: 0.875rem;

  &.label {
    color: var(--gray-400);
    margin-right:8px;
  }
  &.value {
    color: var(--gray-800); 
  }
  &.none {
    color: var(--gray-400);
  }
}
</style>
