<template>
  <TabMenu data-cy="whitelist-tabs" :model="items" v-model:activeIndex="active">
    <template #item="{ item }">
      <div class="p-menuitem-link" @click="item.action">
        <span data-cy="whitelist-tabs-item" class="p-menuitem-text pr-2">{{ item.label }}</span>
        <span data-cy="whitelist-tabs-item-amount" class="pl-1 tab-item-amount">{{
          `${
            item.label === $t('rfidTags')
              ? whitelistDetails.number_of_rfidtags
              : whitelistDetails.number_of_systems
          }`
        }}</span>
      </div>
    </template>
  </TabMenu>
</template>

<script lang="ts">
import TabMenu from 'primevue/tabmenu';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useWhitelistsStore } from '@/stores/admin/whitelists/whitelists.store';

export default {
  name: 'WhitelistTabs',
  components: {
    TabMenu,
  },
  setup() {
    const { t } = useI18n();
    const whitelistsStore = useWhitelistsStore();
    const { whitelistDetails, whitelistActiveTab } = storeToRefs(whitelistsStore);
    const active = ref(0);
    const items = ref([
      {
        label: t('rfidTags'),
        action: () => {
          active.value = 0;
          whitelistActiveTab.value = 0;
        },
      },
      {
        label: t('chargers'),
        action: () => {
          active.value = 1;
          whitelistActiveTab.value = 1;
        },
      },
    ]);

    return {
      items,
      active,
      whitelistDetails,
    };
  },
};
</script>
<style lang="scss" scoped></style>
