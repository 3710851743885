<template>
  <Dialog
    data-cy="contact-edit-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '600px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="field flex flex-col field-edit">
      <label for="contact" class="mb-2 field-edit-label"
        >{{ $t('name') }}<span class="required">*</span></label
      >
      <InputText
        data-cy="contact-name-input"
        id="contact"
        type="text"
        aria-describedby="contact-help"
        class="p-inputtext-sm"
        v-model="contact.name"
        :placeholder="$t('name')"
        @keyup="validateName"
      />
      <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>
    <div class="flex justify-between w-full field-edit">
      <div class="field flex flex-col w-1/3 mr-4">
        <label for="contactType" class="mb-2 field-edit-label"
          >{{ $t('type') }}<span class="required">*</span></label
        >
        <Dropdown
          data-cy="contact-type-select"
          id="contactType"
          v-model="selectedTargetType"
          :options="targetTypes"
          :placeholder="$t('select', { name: $t('type') })"
          class="p-column-filter mb-2"
          optionLabel="name"
        >
        </Dropdown>
      </div>
      <div v-if="contact.type === 'Sms'" class="field flex flex-col w-2/3">
        <label for="phoneNumber" class="mb-2 field-edit-label"
          >{{ $t('phoneNumber') }}<span class="required">*</span></label
        >
        <InputText
          data-cy="contact-phone-input"
          id="phoneNumber"
          type="text"
          aria-describedby="phoneNumber-help"
          class="p-inputtext-sm"
          v-model="contact.phone_number"
          :placeholder="$t('phoneNumber')"
          @keyup="validatePhoneNumber"
        />
        <div class="input-errors" v-for="(error, index) of v$.phone_number.$errors" :key="index">
          <small class="p-error">{{ error.$message }}</small>
        </div>
      </div>
      <div v-else class="field flex flex-col w-2/3">
        <label for="email" class="mb-2 field-edit-label"
          >{{ $t('email') }}<span class="required">*</span></label
        >
        <InputText
          data-cy="contact-email-input"
          id="email"
          type="email"
          aria-describedby="email-help"
          class="p-inputtext-sm"
          v-model="contact.email"
          :placeholder="$t('email')"
          @keyup="validateEmail"
        />
        <div class="input-errors" v-for="(error, index) of v$.email.$errors" :key="index">
          <small class="p-error">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <CustomRadioSelect
      :data="stateData"
      :title="t('status')"
      :selectedState="contactEditionData.enabled"
      class="field-edit"
      @onRadioChange="setState"
    />
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        v-if="!contactIsEditing"
        data-cy="create-contact-button"
        :label="createLabel"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        :disabled="formIsNotValid"
        @click="createContact"
        :loading="loading"
      />
      <Button
        v-else
        data-cy="edit-contact-button"
        :label="editLabel"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        :disabled="editFormIsNotValid"
        @click="editContact"
        :loading="loading"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="contact-edit-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="contacts"
    class="contact-toast"
  />
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useContactsStore } from '@/stores/admin/contacts/contacts.store';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf, helpers, maxLength, email } from '@vuelidate/validators';
import { asciiCharacters, phoneNumber } from '@/utils/validationRules';
import { useToast } from 'primevue/usetoast';
import { CREATE, DEFAULT_TOAST_LIFE_MILLISECONDS, EDIT, MAX_LENGTH } from '@/utils/constants';

import Toast from 'primevue/toast';
import CustomRadioSelect from '../common/CustomRadioSelect.vue';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import type { ContactEditSendData } from '@/models/contacts.model';

const { t } = useI18n();
const toast = useToast();
const contactsStore = useContactsStore();
const isVisible = ref(false);
const loading = ref(false);
const {
  contactEditionData,
  contactIsEditing,
  contactsModalIsOpen,
  contactVerifyModalIsOpen,
  contactVerificationData,
} = storeToRefs(contactsStore);
const contact = ref<ContactEditSendData>({
  id: 0,
  name: '',
  email: null,
  phone_number: null,
  type: 'E-mail',
  enabled: true,
});
const selectedTargetType = ref({
  id: 2,
  name: t('email'),
});

const stateData = ref([
  {
    id: 1,
    title: t('enabled'),
    description: t('contact.enabledInfo'),
    state: true,
  },
  {
    id: 2,
    title: t('disabled'),
    description: t('contact.disabledInfo'),
    state: false,
  },
]);

const targetTypes = ref([
  // {
  //   id: 1,
  //   name: t('sms'),
  // },
  {
    id: 2,
    name: t('email'),
  },
]);

const modalTitle = computed(() => {
  const title = contactIsEditing.value
    ? t('edit', { itemName: t('contact.title') })
    : t('create', { itemName: t('contact.title') });
  return title;
});

const createLabel = computed(() => {
  return v$.value.$error ? t('error') : t('create');
});

const editLabel = computed(() => {
  return v$.value.$error ? t('error') : t('apply');
});

const closeModal = () => {
  contactsModalIsOpen.value = false;
  loading.value = false;

  resetForm();

  if (contactIsEditing.value) {
    contactIsEditing.value = false;
  }
};

const setState = (selectedState: boolean) => {
  contact.value.enabled = selectedState;
  v$.value.enabled.$touch();
};

const showSuccess = (actionName: string) => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('contact.title'),
      action: actionName === CREATE ? t('toast.created') : t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'contacts',
  });
};

const showError = (actionName: string) => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: actionName === CREATE ? t('toast.creation') : t('toast.edition'),
      name: t('contact.title'),
    }),
    group: 'contacts',
  });
};

const createContact = async () => {
  await v$.value.$validate();

  if (v$.value.$error) return;

  loading.value = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, ...targetData } = contact.value;

  const data = {
    ...targetData,
    type: selectedTargetType.value.name === 'E-mail' ? 'EMAIL' : 'SMS',
  };

  await contactsStore
    .createNewContact(data)
    .then((response: any) => {
      loading.value = false;
      if (response.status === 201) {
        toast.removeGroup('contacts');
        showSuccess(CREATE);
        closeModal();
        contactVerificationData.value = response.data;
        contactVerifyModalIsOpen.value = true;
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('contacts');
      showError(CREATE);
      throw new Error(error);
    });
};

const editContact = async () => {
  await v$.value.$validate();

  if (v$.value.$error) return;

  loading.value = true;

  const data = {
    ...contact.value,
    type: selectedTargetType.value.name === 'E-mail' ? 'EMAIL' : 'SMS',
  };

  await contactsStore
    .editContact(data)
    .then((response: any) => {
      loading.value = false;
      if (response.status === 200) {
        toast.removeGroup('contacts');
        showSuccess(EDIT);
        closeModal();
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('contacts');
      showError(EDIT);
      throw new Error(error);
    });
};

watch(
  () => contactsModalIsOpen.value,
  (value) => {
    if (value) {
      isVisible.value = true;
    } else {
      isVisible.value = false;
    }
  }
);

watch(
  () => selectedTargetType.value.id,
  (id) => {
    if (id !== 0) {
      contact.value.type = selectedTargetType.value.name;
    }

    if (selectedTargetType.value.name === 'Sms') {
      contact.value.email = null;
    }

    if (selectedTargetType.value.name === 'E-mail') {
      contact.value.phone_number = null;
    }
  },
  { immediate: true }
);

watch(
  () => contactEditionData.value.id,
  (value) => {
    if (value !== 0) {
      contact.value.id = contactEditionData.value.id;
      contact.value.name = contactEditionData.value.name;
      contact.value.type = contactEditionData.value.type;
      contact.value.phone_number = contactEditionData.value.phone_number;
      contact.value.email = contactEditionData.value.email;
      contact.value.enabled = contactEditionData.value.enabled;
      selectedTargetType.value = targetTypes.value[0]; // remove this line and uncomment the next one if sms contact type is connected
      // selectedTargetType.value = contactEditionData.value.type === 'SMS' ? targetTypes.value[0] : targetTypes.value[1];
    }
  },
  { immediate: true }
);

const validateName = async () => {
  await v$.value.name.$validate();
};

const validateEmail = async () => {
  await v$.value.email.$validate();
};

const validatePhoneNumber = async () => {
  await v$.value.phone_number.$validate();
};

const resetForm = () => {
  contact.value.name = '';
  contact.value.email = null;
  contact.value.phone_number = null;
  contact.value.type = '';
  contact.value.enabled = true;
  selectedTargetType.value = {
    id: 2,
    name: t('email'),
  };

  v$.value.$reset();
  contactsStore.resetContactEditionData();
};

const formIsNotValid = computed(() => {
  return (
    v$.value.$error ||
    contact.value.name === '' ||
    (contact.value.type === 'E-mail' && contact.value.email === null) ||
    (contact.value.type === 'Sms' && contact.value.phone_number === null)
  );
});

const editFormIsNotValid = computed(() => {
  return (
    !v$.value.$anyDirty ||
    (v$.value.name.$anyDirty && v$.value.name.$error) ||
    (v$.value.email.$anyDirty && v$.value.email.$error) ||
    (v$.value.phone_number.$anyDirty && v$.value.phone_number.$error)
  );
});

const rules = computed(() => {
  return {
    name: {
      required: helpers.withMessage(
        t('rules.required', { field: t('name'), item: t('contact.title') }),
        required
      ),
      asciiCharacters: helpers.withMessage(
        t('rules.asciiCharacters', { field: t('name') }),
        asciiCharacters
      ),
      maxLength: maxLength(MAX_LENGTH),
    },
    email: {
      required: helpers.withMessage(
        t('rules.required', {
          field: t('email').toLowerCase(),
          item: t('contact.title').toLowerCase(),
        }),
        requiredIf(() => contact.value.type === 'EMAIL')
      ),
      email: helpers.withMessage(t('rules.email'), email),
      maxLength: maxLength(MAX_LENGTH),
    },
    phone_number: {
      required: helpers.withMessage(
        t('rules.required', {
          field: t('phoneNumber').toLowerCase(),
          item: t('contact.title').toLowerCase(),
        }),
        requiredIf(() => contact.value.type === 'SMS')
      ),
      phoneNumber: helpers.withMessage(
        t('rules.phoneNumber', { field: t('contact.title') }),
        phoneNumber
      ),
      maxLength: maxLength(MAX_LENGTH),
    },
    enabled: {
      required: helpers.withMessage(t('rules.required', { field: t('enabled') }), required),
    },
  };
});

const v$ = useVuelidate(rules, contact.value);
</script>
<style lang="scss" scoped>
:deep(.p-dropdown-label) {
  padding: 9px;
}
</style>
