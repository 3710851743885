export type Result<T> =
  | {
      ok: true;
      data: T;
    }
  | {
      ok: false;
      error: Error;
    };

export function Ok<T>(data: T): Result<T> {
  return { ok: true, data };
}

export function Err<T>(error: Error): Result<T> {
  return { ok: false, error };
}

 
export interface TabItem {
  id: string;
  label: string;
}

export type TabGroups = Array<Array<TabItem>>;