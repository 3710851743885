<template>
  <div class="input-container">
    <input
      type="text"
      v-model="value"
      placeholder="#123456"
      @input="setValue"
      :class="{ error: !!validationError }"
    />
    <input type="color" v-model="value" @input="setValue" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
  value?: any;
  defaultValue?: string;
}>();

const value = ref(props.value || props.defaultValue || '#000000');
const validationError = ref(validate(value.value));

function validate(value: string) {
  if (!/#[0-9A-Fa-f]{6}$/i.test(value)) return 'Invalid Color Format';
  return undefined;
}

function setValue(event: Event) {
  const value = (event.target as HTMLInputElement).value;
  validationError.value = validate(value);
  emit('update:value', value, validationError.value);
}

const emit = defineEmits<{
  (event: 'update:value', value: string, validationError?: string): void;
}>();
</script>

<style scoped lang="scss">
.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

input[type='color'] {
  width: 2rem;
  height: 2rem;
  border-radius: var(--rounded-md);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

input[type='color']::-webkit-color-swatch {
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
}
input[type='color']::-moz-color-swatch {
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
}

input[type='text'] {
  border-radius: var(--rounded-md);
  outline: 1px solid var(--gray-200);
  padding: 0.25rem 0.5rem;
  text-align: end;

  &.error {
    outline: 2px solid orangered;

    &:focus {
      outline: 2px solid orangered;
    }
  }
}
</style>
