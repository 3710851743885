<template>
  <div class="header">
    <div class="row row-top pt-6">
      <h1 data-cy="charge-park-detail-name">
        {{ chargePark.name ? prettify(chargePark.name) : '--' }}
      </h1>
      <div v-if="country !== undefined" class="location">
        <country-icon :country-iso="country.iso_code_alpha_3" class="flag" />
        <p class="address">{{ chargePark.address || '--' }}</p>
      </div>
    </div>

    <div>
      <tab-menu :model="tabs" :active-index="activeTabIndex">
        <template #item="{ item }">
          <div class="p-menuitem-link" @click="() => emit('update:activeTabIndex', item.id)">
            <span data-cy="charge-park-detail-tab" class="p-menuitem-text pr-2">{{
              item.label
            }}</span>
            <span
              v-if="item.label === $t('hardwareSystem.systems')"
              data-cy="charge-park-detail-systems"
              class="tab-item-amount"
              >{{ chargePark.number_of_systems }}</span
            >
          </div>
        </template>
      </tab-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import { prettify } from '@/utils/textFormatting';
import TabMenu from 'primevue/tabmenu';
import CountryIcon from '@/components/common/countries/CountryIcon.vue';

import type { ChargePark } from '@/models/chargeParks.model';
import type { Country } from '@/models/countries.model';

defineProps<{
  chargePark: ChargePark;
  country?: Country;
  tabs: { key: string; label: string }[];
  activeTabIndex: number;
}>();

const emit = defineEmits(['update:activeTabIndex']);
</script>

<style scoped lang="scss">
.header {
  background-color: var(--gray-0);
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
}

.row {
  margin: 1rem;

  &.row-top {
    margin-top: 0;
  }
}

.location {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  gap: 0.5rem;
  color: var(--gray-500);
}

.flag {
  width: 1.25rem;
  height: 1.25rem;
}

.address {
  margin: 0;
  color: var(--gray-500);
  font-size: 0.825rem;
}

h1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--gray-700);
}
</style>
