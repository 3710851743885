import { isAxiosError } from 'axios';

type ApiError = {
  error_details?: {
    error_code?: string;
    error_message?: string;
  };
};

function isApiError(error: unknown): error is ApiError {
  return (
    (error as ApiError).error_details?.error_message !== undefined &&
    (error as ApiError).error_details?.error_code !== undefined
  );
}

export function extractApiError(error: Error) {
  if (isAxiosError(error)) {
    if (isApiError(error.response?.data)) {
      return error.response?.data.error_details?.error_message;
    }

    if (error.response?.status === 404) return 'Not found';
    else if (error.response?.status === 500) return 'Internal server error';
    else if (error.response?.status === 401) return 'Unauthorized';
    else if (error.response?.status === 403) return 'Forbidden';
    else if (error.response?.status === 400) return 'Bad request';
    else if (error.response?.status === 503) return 'Service unavailable';
    else if (error.response?.status === 504) return 'Gateway timeout';
    else if (error.response?.status === 429) return 'Too many requests';
    else return error.message;
  }

  return error.message;
}
