<template>
  <div class="widget-card">
    <div v-if="props.card.title" class="card-header">
      <h3>{{ props.card.title }}</h3>
    </div>

    <div v-if="!!props.card.parameters?.length" class="param-container">
      <div v-for="param in card.parameters" :key="param.placeholder" class="param-row">
        <label :for="`param-${param.placeholder}`">{{ param.label }}</label>
        <select
          :id="`param-${param.placeholder}`"
          :data-cy="`${param.placeholder}-select`"
          :name="param.label"
          @change="updateParam(param.placeholder, ($event.target as HTMLSelectElement).value)"
        >
          <option
            v-for="option in param.values"
            :key="option.value"
            :value="option.value"
            :selected="option.value === parameterState[param.placeholder]"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>

    <div
      class="widget-container"
      v-if="cardWithPopulatedParameters.widgets && cardWithPopulatedParameters.widgets?.length > 0"
    >
      <template v-for="(widget, index) in cardWithPopulatedParameters.widgets">
        <image-widget
          v-if="widget.type === 'image'"
          :image="widget"
          :data-cy="`widget-image-${widget.url}`"
          :key="`${widget.url}-${index}`"
        />
        <telemetry-widget
          v-else-if="widget.type === 'telemetry'"
          :system-id="props.systemId"
          :widget="widget"
          :data-cy="`widget-telemetry-${widget.path}`"
          :widget-id="`${widget.path}-${index}`"
          :key="`telemetry-${widget.path}-${index}`"
          :desired-unit-system="props.unitSystem"
        />
        <date-time-widget
          v-else-if="widget.type === 'datetime'"
          :system-id="props.systemId"
          :widget="widget"
          :data-cy="`widget-telemetry-${widget.path}`"
          :widget-id="`${widget.path}-${index}`"
          :key="`datetime-${widget.path}-${index}`"
        />
        <setting-widget
          v-else-if="widget.type === 'setting'"
          :setting="widget"
          :data-cy="`widget-setting-${widget.path}`"
          :key="`setting-${widget.path}-${index}`"
          :desired-unit-system="props.unitSystem"
        />
        <divider-widget
          v-else-if="widget.type === 'divider'"
          :divider="widget"
          :data-cy="`widget-divider`"
          :key="`divider-${index}`"
        />
        <command-widget
          v-else-if="widget.type === 'command'"
          :command="widget"
          :data-cy="`widget-command-${widget.commandId}`"
          :key="`${widget.commandId}-${index}`"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue';

import type { Card } from '@/models/controlPanel/dashboard';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { UnitSystem } from '@/models/datatypes.model';
import ImageWidget from '@/components/hardwareSystems/controlPanel/widgets/generic/ImageWidget.vue';
import TelemetryWidget from '@/components/hardwareSystems/controlPanel/widgets/telemetry/TelemetryWidget.vue';
import SettingWidget from '@/components/hardwareSystems/controlPanel/widgets/settings/SettingWidget.vue';
import DividerWidget from '@/components/hardwareSystems/controlPanel/widgets/generic/DividerWidget.vue';
import CommandWidget from '@/components/hardwareSystems/controlPanel/widgets/commands/CommandWidget.vue';
import DateTimeWidget from '@/components/hardwareSystems/controlPanel/widgets/telemetry/DateTimeWidget.vue';
import { useControlPanelStore } from '@/stores/admin/controlPanel/controlPanel.store';

const props = defineProps<{
  systemId: HardwareSystem['id'];
  card: Card;
  unitSystem: UnitSystem;
}>();

const controlPanelStore = useControlPanelStore();
const parameterState = reactive<Record<string, string | number | undefined>>({});

props.card.parameters?.forEach((param) => {
  parameterState[param.placeholder] = param.values[0]?.value;
});

function updateParam(placeholder: string, value: string | null) {
  if (value === null) return;
  parameterState[placeholder] = value;
  controlPanelStore.refreshData();
}

const cardWithPopulatedParameters = computed(() => {
  const populatedCard = JSON.parse(JSON.stringify(props.card)) as Card;
  populatedCard.widgets?.forEach((widget) => {
    if (widget.type === 'telemetry' || widget.type === 'setting') {
      for (const param of props.card.parameters || []) {
        widget.path = widget.path.replace(
          param.placeholder,
          String(parameterState[param.placeholder])
        );
      }
    }
  });
  return populatedCard;
});
</script>

<style lang="scss" scoped>
.widget-card {
  & > :first-child {
    border-radius: var(--rounded-sm) var(--rounded-sm) 0 0;
  }

  & > :not(:last-child) {
    border: 1px solid var(--gray-200);
  }

  & > * {
    border: 1px solid var(--gray-200);
  }

  & > :only-child {
    border-radius: var(--rounded-sm) !important;
    border: 1px solid var(--gray-200) !important;
  }

  & > :last-child {
    border-radius: 0 0 var(--rounded-sm) var(--rounded-sm);
    border-top: none;
  }
}

.widget-container {
  background-color: var(--gray-0);
  padding: 0.125rem;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: var(--gray-100);
  border: 1px solid var(--gray-200);
  padding: 0.5rem 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.param-container {
  border-style: solid;
  border-color: var(--gray-200);
  border-width: 1px 1px 0 1px;
  background-color: var(--gray-50);
  padding: 0.5rem 0.5rem 0.5rem 1rem;

  .param-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  select {
    border: 1px solid var(--gray-200);
    background-color: var(--gray-0);
    border-radius: var(--rounded-sm);
    padding: 0.25rem 0.5rem;
  }
}
</style>
