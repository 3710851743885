import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { ChargePark } from '@/models/chargeParks.model';

export type PlotValue = string | number;

export enum WidgetSize {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
}

export type ColorMap = {
  type: 'discrete' | 'continuous' | 'base' | 'palette'| 'discreteBys';
  colorBy: 'value' | 'index';
  map: Record<PlotValue, string>;
  fallback: string;
};

type WidgetGroupBy = { target: string; binSize?: string };

export type Dimension = 'chargepark.id' | 'system.id' | 'time';

export function isDimension(dimension: string): dimension is Dimension {
  return ['chargepark.id', 'system.id', 'time'].includes(dimension);
}

export type WidgetFilter = {
  dimension: Dimension;
  condition: '=' | '!=' | '>' | '<' | '>=' | '<=' | 'in';
  value: string | number | string[] | number[];
};

export type WidgetOrderBy = {
  target: 'time' | 'value';
  direction: 'asc' | 'desc';
};

export type WidgetQuery = {
  series: { productType: number; path: string };
  transform: {
    aggregation?: string;
    last: boolean;
    returnMissingAs?: null | string;
    groupBy?: WidgetGroupBy[];
    orderBy?: WidgetOrderBy[];
    filters: WidgetFilter[];
  };
};

export type ChartEvent = {
  type: 'click' | 'hover';
  data: {
    systemId?: HardwareSystem['id'];
    systemIds?: HardwareSystem['id'][];
    chargeParkId?: ChargePark['id'];
    chargeParkIds?: ChargePark['id'][];
    timestamp?: number;
    timeRange?: [number, number];
  };
};

export type TransitionLayer = {
  id: number;
  type: 'transition';
  eventFilters: WidgetFilter[];
};

export type WidgetStack = {
  id: number;
  type: 'stack';
  presentation: {
    size?: WidgetSize;
  };
  stack: (Widget | TransitionLayer)[];
};

interface BaseWidget {
  id: number;
  title: string;
  presentation?: {
    colorMap?: ColorMap;
    size?: WidgetSize;
  };
  axis: {
    x: { label: string };
    y: { label: string; desiredUnit?: string };
  };
  query?: WidgetQuery;
  api?: string;
}

export interface PieChartWidget extends BaseWidget {
  type: 'pie';
}

export interface BarChartWidget extends BaseWidget {
  type: 'bar';
}

export interface MapWidget extends BaseWidget {
  type: 'map';
}

export interface TableWidget {
  id: string;
  title: string;
  type: 'table';
  presentation: {
    size?: WidgetSize;
  };
  axis: {
    x: { label: string; dimension: Dimension };
  };
  columns: {
    label: string;
    overrideSourceUnit?: string;
    desiredUnit?: string;
    query: WidgetQuery;
  }[];
}

export type RenderableWidget = PieChartWidget | BarChartWidget | MapWidget;
export type Widget = RenderableWidget | WidgetStack | TableWidget;

export type Panel = {
  id: string;
  title: string;
  widgets: Widget[];
};

export type WidgetData = {
  query?: string;
  params?: number[];
  series: PlotValue[][];
};

export function isWidgetStack(widget: Widget): widget is WidgetStack {
  return widget.type === 'stack';
}

export function isRenderableWidget(widget: Widget | TransitionLayer): widget is RenderableWidget {
  return widget.type === 'pie' || widget.type === 'bar' || widget.type === 'map';
}

export function isTransitionLayer(widget: Widget | TransitionLayer): widget is TransitionLayer {
  return widget.type === 'transition';
}
