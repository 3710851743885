<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="downtimes-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
  >
    {{ $t('notAuthorized') }}
  </Message>
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="downtimes-table"
    :value="downtimesStore.downtimes"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    filterDisplay="menu"
    dataKey="id"
    class="downtime-table p-datatable-sm mb-5 mt-6 border-rounded overflow-hidden"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="$t('searchBy', { name: `${$t('downtimes.reason').toLowerCase()}` })"
            class="p-inputtext-sm"
          />
        </span>
        <div class="flex justify-end items-center p-4">
          <Button
            data-cy="add-downtime-button"
            class="p-button-primary p-button-sm"
            icon="pi pi-plus"
            :label="$t('downtimes.newDowntime')"
            @click="downtimesStore.downtimeCreateModalIsOpen = true"
          />
        </div>
      </div>
    </template>
    <template #empty>
      <span data-cy="downtimes-table-empty" class="w-full block text-center">
        {{ $t('downtimes.empty') }}
      </span>
    </template>
    <Column
      class="no-top-border-row"
      field="start_time"
      :header="$t('downtimes.startTime')"
      :sortable="true"
      style="min-width: 11rem"
    >
      <template #body="{ data }">
        <span data-cy="downtime-start-time" class="text-sm flex items-center">
          <date-time-display :date="(data as Downtime).start_time" />
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="end_time"
      :header="$t('downtimes.endTime')"
      :sortable="true"
      style="min-width: 11rem"
    >
      <template #body="{ data }">
        <span data-cy="downtime-end-time" class="text-sm flex items-center">
          <date-time-display :date="(data as Downtime).end_time" />
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="reason"
      :header="$t('downtimes.reason')"
      :filterMatchModeOptions="matchModes"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <span data-cy="downtime-reason" class="text-sm">{{ data.reason }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('downtimes.reason').toLowerCase() })"
        />
      </template>
    </Column>

    <Column
      class="no-top-border-row"
      field="systems"
      :header="$t('downtimes.affectedAssets')"
      :sortable="false"
      style="min-width: 8rem"
    >
      <template #body="{ data }">
        <HardwareSystemsHoverList :systems="data.systems"></HardwareSystemsHoverList>
      </template>
    </Column>
    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }">
        <div class="flex">
          <Button
            data-cy="open-edit-downtime-modal"
            :label="$t('edit')"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="editDowntime(data?.id)"
          />
          <Button
            data-cy="open-delete-downtime-modal"
            :label="$t('delete')"
            icon="pi pi-trash"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="deleteDowntime(data?.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import { useDowntimesStore } from '@/stores/admin/downtimes/downtimes.store';

import HardwareSystemsHoverList from '@/components/hardwareSystems/HardwareSystemsHoverList.vue';

import type { Downtime } from '@/models/downtimes.model';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';

const downtimesStore = useDowntimesStore();
const loading = ref(true);
const notAuthorized = ref(false);

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  timezone: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  reason: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
});

const matchModes = ref([
  { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
  { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
  { label: 'Contains', value: FilterMatchMode.CONTAINS },
]);

const deleteDowntime = (id?: number) => {
  if (!id) return;

  downtimesStore.downtimeDeleteModalIsOpen = true;
  downtimesStore.downtimeDeleteId = id;
};

const editDowntime = (id?: number) => {
  if (!id) return;

  downtimesStore.downtimeCreateModalIsOpen = true;
  downtimesStore.downtimeEditId = id;
  downtimesStore.setDowntimeToEdit(id);
};

onMounted(async () => {
  try {
    await downtimesStore.fetchDowntimeList();
  } catch (error: any) {
    if (error?.response?.status === 403) {
      notAuthorized.value = true;
    } else {
      throw new Error('Unplanned Outage list failed to be fetched');
    }
  } finally {
    loading.value = false;
  }
});
</script>
