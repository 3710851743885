<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="alert-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}
  </Message>
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="alert-table"
    v-model:filters="filters"
    v-model:selection="selectedAlerts"
    class="p-datatable-sm mb-5 mt-6 border-rounded overflow-hidden alert-table"
    dataKey="id"
    filterDisplay="menu"
    :alwaysShowPaginator="false"
    :paginator="true"
    :rows="10"
    :value="convertedAlerts"
    :globalFilterFields="['name']"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="$t('searchBy', { name: `${$t('name')}` })"
            class="p-inputtext-sm"
          />
        </span>
        <div v-if="activeRoles" class="flex justify-end items-center p-4">
          <Button
            data-cy="open-batch-actions-menu"
            :label="$t('actions')"
            @click="toggleActions"
            icon="pi pi-chevron-down"
            iconPos="right"
            aria-haspopup="true"
            aria-controls="actions_menu"
            class="p-button-outlined p-button-plain p-button-sm mr-3 actions-menu-button"
          />
          <Menu
            data-cy="batch-actions-menu"
            id="actions_menu"
            ref="actionsMenu"
            :model="actionMenuItems"
            :popup="true"
            class="mt-1"
          />
          <Button
            data-cy="open-create-alert-modal"
            :label="$t('alert.newAlert')"
            icon="pi pi-plus"
            class="p-button-sm p-button-primary"
            @click="openCreateAlertModal"
          />
        </div>
      </div>
    </template>
    <template #empty>
      <span data-cy="alert-table-empty" class="w-full block text-center">{{
        $t('alert.empty')
      }}</span>
    </template>
    <Column class="no-top-border-row" selectionMode="multiple" headerStyle="width: 3rem" />
    <Column
      class="no-top-border-row"
      field="name"
      :header="$t('name')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <span data-cy="alert-name">{{ (data as Alert).name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="parameter"
      :header="$t('alert.parameters')"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <div v-if="(data as Alert).parameters.length > 0" data-cy="alert-parameters">
          <i
            class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 mr-2"
            v-tooltip.right="{
              value: `${(data as Alert).parameters
                .map((p) => `<div class='flex flex-nowrap w-full'>
                  <span class='whitespace-nowrap font-medium'>${p?.name ?? p.id}:</span>
                  <span class='text-neutral-500 pl-1 pr-2'> ${p.value}${p.unit || ''}</span>
                </div>`)
                .join('')}`,
              escape: true,
              class: 'custom-tooltip parameters-tooltip',
            }"
          >
          </i>
          {{
            (data as Alert).parameters.length +
            ((data as Alert).parameters.length === 1 ? ' Parameter' : ' Parameters')
          }}
        </div>
        <div v-else>
          <span>--</span>
        </div>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="contacts"
      :header="$t('contact.activeContacts')"
      style="min-width: 13rem"
    >
      <template #body="{ data }">
        <span v-if="(data as Alert).contacts.length > 0" data-cy="alert-contacts">
          <i
            class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 mr-2"
            v-tooltip.right="{
              value: `${(data as Alert).contacts
                .map((contact) => `<div class='mb-3'>
                  <span class='font-medium'>Name:</span><span class='text-neutral-500 pl-1'> ${contact.name}</span>
                  <span class='font-medium'>Type:</span><span class='text-neutral-500 pl-2.5'> ${contact.type}</span>
                </div>
                `).join('')
                }`,
              escape: true,
              class: 'custom-tooltip contacts-tooltip',
            }"
          >
          </i>
          {{ renderActiveContacts((data as Alert).contacts) }}
        </span>
        <span v-else data-cy="alert-no-contacts">
          <i class="pi pi-exclamation-triangle text-amber-500 text-lg ml-1 mr-2"></i>
          0 {{ $t('contacts') }}
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="charge_parks"
      :header="$t('chargeParks')"
      style="min-width: 7rem"
    >
      <template #body="{ data }">
        <span v-if="(data as Alert).charge_parks.length > 0" data-cy="alert-charge-park">
          <i
            class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 mr-2"
            v-tooltip.right="{
              value: `${(data as Alert).charge_parks.map((chargePark: ChargeParkAlert) => `<div class='flex flex-nowrap w-full'><span class='pr-2'>${chargePark.name}</span></div>`).join('')}`,
              escape: true,
              class: 'custom-tooltip site-tooltip',
            }"
          >
          </i>
          {{
            (data as Alert).charge_parks.length === 1
              ? (data as Alert).charge_parks.length + ' Site'
              : (data as Alert).charge_parks.length + ' Sites'
          }}
        </span>
        <span v-else data-cy="alert-no-charge-parks">
          <i class="pi pi-exclamation-triangle text-amber-500 text-lg ml-1 mr-2"></i>
          0 {{ $t('chargeParks') }}
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="triggered_at"
      :header="$t('alert.lastTriggered')"
      :sortable="true"
      style="min-width: 9rem"
    >
      <template #body="{ data }">
        <date-time-display
          data-cy="alert-triggered"
          v-if="(data as Alert).triggered_at"
          :date="(data as Alert).triggered_at"
        />
        <span v-else>{{ $t('alert.never') }}</span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="active"
      :header="$t('status')"
      :sortable="true"
      :showFilterMatchModes="false"
      style="min-width: 6rem"
    >
      <template #body="{ data }">
        <Tag
          data-cy="alert-enabled"
          class="mr-2"
          :severity="`${(data as Alert).active ? 'success' : 'info'}`"
          :value="`${(data as Alert).active ? 'Enabled' : 'Disabled'}`"
          rounded
        ></Tag>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="statuses"
          :placeholder="$t('select', { name: $t('status') })"
          class="p-column-filter mb-2"
          :showClear="true"
        >
        </Dropdown>
      </template>
    </Column>
    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }">
        <div class="flex">
          <Button
            v-if="activeRoles"
            data-cy="open-edit-alert-modal"
            :label="$t('edit')"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openEditAlertModal((data as Alert).id)"
          />
          <Button
            v-if="activeRoles"
            data-cy="open-delete-alert-modal"
            :label="$t('delete')"
            icon="pi pi-trash"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openDeleteAlertModal((data as Alert).id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>

  <AlertDeleteModal @delete="selectedAlerts = []" />

  <Toast
    data-cy="alert-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="alertsActions"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAlertsStore } from '@/stores/admin/alerts/alerts.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api';
import {
  ACTIVE_MONITORING_ADMIN_ROLE,
  ADMINISTRATOR_ROLE,
  DEFAULT_TOAST_LIFE_MILLISECONDS,
  DISABLE,
  ENABLE,
} from '@/utils/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { ChargeParkAlert } from '@/models/chargeParks.model';
import type { Alert, AlertBatchEditData, AlertParameter } from '@/models/alerts.model';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import Menu from 'primevue/menu';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import Toast from 'primevue/toast';
import AlertDeleteModal from '@/components/alerts/AlertDeleteModal.vue';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import { convertValue, getUnitForSystem } from '@/utils/units';

const alertsStore = useAlertsStore();
const { alerts, alertCreateModalIsOpen, alertDeleteModalIsOpen, alertDeleteIds, alertEditId } =
  storeToRefs(alertsStore);
const { t } = useI18n();
const userStore = useUsersStore();
const toast = useToast();

const loading = ref(true);
const notAuthorized = ref(false);
const selectedAlerts = ref<Alert[]>([]);
const statuses = ref(['Enabled', 'Disabled']);
const IS_ENABLED = ref('ENABLED');

const actionsMenu = ref();

const activeRoles = computed(
  () =>
    userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
    userStore.me.roles.map((role) => role.id).includes(ACTIVE_MONITORING_ADMIN_ROLE)
);

function convertParameter(param: AlertParameter): AlertParameter {
  if (!param.unit || !(typeof param.value === 'number')) return param;
  const desiredUnitSystem = userStore.me.units;
  const desiredUnit = getUnitForSystem(param.unit, desiredUnitSystem);
  const [convertedValue, convertedUnit] = convertValue(param.value, param.unit, desiredUnit);
  return { ...param, value: convertedValue, unit: convertedUnit };
}

function renderActiveContacts(contacts: Alert['contacts']): string {
  const numberOfEmails = contacts.filter((contact) => contact.type === 'EMAIL').length;
  const numberOfSms = contacts.filter((contact) => contact.type === 'SMS').length;
  return `${numberOfEmails} Email${numberOfEmails === 1 ? '' : 's'}, ${numberOfSms} Sms`;
}

const convertedAlerts = computed<Alert[]>(() => {
  return alerts.value.map((alert) => {
    return { ...alert, parameters: alert.parameters.map(convertParameter) };
  });
});

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  parameters: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  contacts: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  charge_park_id: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  triggered_at: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  active: { value: null, matchMode: IS_ENABLED.value },
});

const matchModes = ref([
  { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
  { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
  { label: 'Contains', value: FilterMatchMode.CONTAINS },
]);

FilterService.register(IS_ENABLED.value, (value, filter): boolean => {
  const convertedValue = value ? 'Enabled' : 'Disabled';

  if (filter === undefined || filter === null) {
    return true;
  }

  return convertedValue === filter;
});

const openEditAlertModal = (id: number) => {
  alertEditId.value = id;
  alertsStore.fillAlertModalData();
  alertCreateModalIsOpen.value = true;
};

const openDeleteAlertModal = (id: number) => {
  alertDeleteIds.value = [{ id: id }];
  alertDeleteModalIsOpen.value = true;
};

const openCreateAlertModal = () => {
  alertCreateModalIsOpen.value = true;
};

const actionMenuItems = ref([
  {
    label: t('batchActions.enable'),
    icon: 'pi pi-chevron-circle-down',
    command: () => {
      changeSelectedAlertsStatus(true);
    },
  },
  {
    label: t('batchActions.disable'),
    icon: 'pi pi-ban',
    command: () => {
      changeSelectedAlertsStatus(false);
    },
  },
  {
    label: t('batchActions.delete'),
    icon: 'pi pi-trash',
    command: () => {
      deleteSelected();
    },
  },
]);

const setActionInfo = (actionName: string) => {
  if (actionName === ENABLE) {
    return t('alert.enabled');
  } else if (actionName === DISABLE) {
    return t('alert.disabled');
  } else {
    return t('alert.deleted');
  }
};

const showSuccess = (actionName: string) => {
  toast.add({
    severity: 'success',
    summary: setActionInfo(actionName),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'alertsActions',
  });
};

const showError = (actionName: string) => {
  toast.add({
    severity: 'error',
    summary: t('toast.errorBatch', {
      action: actionName === 'delete' ? t('toast.removal') : t('toast.statusChange'),
      name: t('alert.title').toLowerCase(),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'alertsActions',
  });
};

const toggleActions = (event: any) => {
  actionsMenu.value.toggle(event);
};

const changeSelectedAlertsStatus = async (enabled: boolean) => {
  const data: AlertBatchEditData = {
    items: selectedAlerts.value.map((alert) => ({
      id: alert.id,
      active: enabled,
    })),
  };

  const action = enabled ? ENABLE : DISABLE;

  try {
    await alertsStore.batchUpdateAlerts(data);
    selectedAlerts.value = [];
    showSuccess(action);
    await alertsStore.fetchAlertsList();
  } catch {
    showError(action);
  }
};

const deleteSelected = async () => {
  selectedAlerts.value.forEach((alert) => alertDeleteIds.value.push({ id: alert.id }));
  alertDeleteModalIsOpen.value = true;
};

onMounted(
  async () =>
    await alertsStore
      .fetchAlertsList()
      .catch((error) => {
        if (error.response.status === 403) {
          notAuthorized.value = true;
        } else {
          throw new Error('Alerts failed to be fetched');
        }
      })
      .finally(() => (loading.value = false))
);
</script>
