<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    class="m-5"
    data-cy="updates-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}</Message
  >
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="active-error-table"
    filterDisplay="menu"
    v-model:filters="filters"
    @update:filters="onFilterStateChange"
    lazy
    :value="sortedActiveErrors"
    :loading="isFetchingData"
    @sort="onSort($event)"
  >
    <template #empty>
      <span data-cy="active-errors-empty" class="w-full block text-center">{{
        $t('hardwareSystem.emptyActiveErrors')
      }}</span>
    </template>

    <Column
      class="no-top-border-row"
      field="Timestamp"
      :header="$t('time')"
      :sortable="true"
      dataType="Timestamp"
    >
      <template #body="{ data }: { data: ActiveError }">
        <span data-cy="active-error-time" class="time whitespace-nowrap">
          <date-time-display :date="(data as ActiveError).Timestamp * 1000" />
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="Count"
      :header="$t('history.count')"
      :sortable="true"
      style="min-width: 5rem"
    >
      <template #body="{ data }: { data: ActiveError }">
        <span data-cy="active-error-count">{{ data.Count }}</span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      :sortable="true"
      field="Severity"
      :header="$t('history.severity')"
      style="min-width: 5rem"
    >
      <template #body="{ data }: { data: ActiveError }">
        <span data-cy="active-error-severity">{{ data.Severity }}</span>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="severityOptions"
          :placeholder="$t('select', { name: $t('monitoring.severity') })"
          class="p-column-filter mb-2"
          :showClear="true"
        >
        </Dropdown>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      :sortable="true"
      field="ErrorCode"
      :header="$t('history.errorCode')"
      
      style="min-width: 8rem"
    >
      <template #body="{ data }: { data: ActiveError }">
        <span data-cy="active-error-errorcode">{{ data.ErrorCode }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { ErrorCode: $t('history.errorCode') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      :sortable="true"
      field="Connector"
      :header="$t('hardwareSystem.connector')"
    >
      <template #body="{ data }: { data: ActiveError }">
        <span data-cy="active-error-connector">{{ data.Connector }}</span>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="connectorOptions"
          :placeholder="$t('select', { name: $t('hardwareSystem.connector') })"
          class="p-column-filter mb-2"
          :showClear="true"
        >
        </Dropdown>
      </template>
    </Column>
    <Column class="no-top-border-row w-[80%]" field="Description" :header="$t('description')">
      <template #body="{ data }: { data: ActiveError }">
        <span data-cy="active-error-description">{{ data.Description }}</span>
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import DataTable, {
  type DataTableFilterMeta,
  type DataTableFilterMetaData,
} from 'primevue/datatable';

import InputText from 'primevue/inputtext';

import Dropdown from 'primevue/dropdown';
import Column from 'primevue/column';
import Message from 'primevue/message';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';

import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';  
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api';

import type { HardwareSystem, EventType } from '@/models/hardwareSystems.model';
import type { ActiveError } from '@/models/history.model'; 
import type { DataTableSortEvent } from 'primevue/datatable';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';

const props = defineProps<{
  system: HardwareSystem;
}>();

const hardwareSystemsStore = useHardwareSystemsStore();
const { activeErrors, eventTypes, isFetchingData } = storeToRefs(hardwareSystemsStore);
const loading = ref(true);
const notAuthorized = ref(false); 

const sortField = ref<string | null>(null);
const sortOrder = ref<number>(1); // 1 for ascending, -1 for descending

// Filters  

const emit = defineEmits(['switchEventTypeAggregation']);
 
const filterState = ref<DataTableFilterMeta>()
 
function onFilterStateChange(newfilterState: DataTableFilterMeta) {
  filterState.value = newfilterState
}


const eventTypesOptions = ref<EventType[]>(eventTypes.value);
 
  
// Computed property to sort the data locally
const sortedActiveErrors = computed(() => {
  let filteredErrors = activeErrors.value;

  const filterS = filterState.value as any;
  if (filterS) {
    filteredErrors = filteredErrors.filter((error: any) => {
      return Object.keys(filterS).every((key) => {
        const filter = filterS[key];
        if (!filter.value) return true; // Skip if no filter value 

        const val = String(error[key])
        if(!val) return false;
        

        switch (filter.matchMode) {
          case FilterMatchMode.CONTAINS:
            return val.includes(filter.value);
          case FilterMatchMode.STARTS_WITH:
            return val.startsWith(filter.value);
          case FilterMatchMode.ENDS_WITH:
            return val.endsWith(filter.value);
          case FilterMatchMode.EQUALS:
            return val === filter.value;
          case FilterMatchMode.NOT_EQUALS:
            return val !== filter.value;
          case FilterMatchMode.NOT_CONTAINS:
            return !val.includes(filter.value);
          default:
            return true;
        }
      });
    });
  }

  if (!sortField.value) return filteredErrors; // No sorting applied
  return [...filteredErrors].sort((a, b) => {
    const valueA = a[sortField.value as keyof ActiveError];
    const valueB = b[sortField.value as keyof ActiveError];

    if (valueA < valueB) return -1 * sortOrder.value;
    if (valueA > valueB) return 1 * sortOrder.value;
    return 0;
  });
});


function onSort(event: DataTableSortEvent) {
  sortField.value = String(event.sortField);
  sortOrder.value = Number(event.sortOrder);
}

async function fetchFilteredActiveErrors() {
  await hardwareSystemsStore.fetchActiveErrors(props.system.id);
}

const connectorOptions = computed(() => {
  const uniqueConnectors = new Set(activeErrors.value.map(error => error.Connector));
  return Array.from(uniqueConnectors);
});
 

const filters = ref({
  Severity: { value: null, matchMode: FilterMatchMode.CONTAINS },
  Connector: { value: null, matchMode: FilterMatchMode.CONTAINS },
  ErrorCode:  { value: null, matchMode: FilterMatchMode.CONTAINS }
});

const severityOptions = computed(() => {
  const uniqueSeverities = new Set(activeErrors.value.map(error => error.Severity));
  return Array.from(uniqueSeverities);
});
onMounted(async () => {
  await Promise.all([fetchFilteredActiveErrors(), hardwareSystemsStore.fetchEventTypes()])
    .catch((error) => {
      if (error.response?.status === 403) {
        notAuthorized.value = true;
      } else {
        throw new Error('Events failed to be fetched');
      }
    })
    .finally(() => {
      eventTypesOptions.value = eventTypes.value.map((item) => ({
        //HACK
        ...item,
        events: item.events.filter(
          (event) => !event.name.includes('(on)') && !event.name.includes('(off)')
        ),
      }));
      loading.value = false;
    });
});
 
</script>

<style lang="scss" scoped>
:deep(.p-badge) {
  background-color: var(--gray-200);
  color: var(--gray-500);
  border-radius: 3px;
}

.p-datatable {
  margin: 5px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
}

:deep(.p-datatable-header) {
  padding: 30px 20px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
</style>
