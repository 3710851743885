<template>
  <Dialog
    modal
    data-cy="media-upload-modal"
    v-model:visible="isMediaUploadModalOpen"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :header="modalTitle"
    :style="{ width: '710px' }"
    @hide="closeModal"
  >
    <FileUpload
      chooseIcon="pi pi-file"
      name="update"
      accept=".jpg,.jpeg,.png,.mp4,.avi,.webm,.mov,.qt,.wmv,.flv,.f4v,.f4p"
      :chooseLabel="t('selectFile')"
      :class="`${mediaData.file_id && fileIsUploaded ? 'disabled' : ''}`"
      :showCancelButton="false"
      :showUploadButton="false"
      @select="uploadMediaFile"
    >
      <template #content>
        <div v-if="mediaFile && !isFailed" class="flex flex-wrap">
          <div class="flex items-center w-full">
            <i class="pi pi-file icon mr-2" />
            <span class="font-semibold">{{ mediaFile.name }}</span>
            <i v-if="fileIsLoading" class="pi pi-spin pi-spinner icon icon-loading ml-4" />
            <i
              v-if="fileIsUploaded && uploadSuccess"
              class="pi pi-check-circle icon icon-success ml-4"
            />
          </div>
        </div>
      </template>
      <template #empty>
        <div class="flex justify-center items-center flex-wrap drag-and-drop-wrapper">
          <i class="pi pi-cloud-upload" />
          <p class="mt-4 info w-full text-center">{{ $t('dragAndDrop') }}</p>
        </div>
      </template>
    </FileUpload>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        data-cy="media-add-button"
        class="p-button-sm p-button-primary"
        :disabled="formIsNotValid"
        :label="t('add')"
        v-bind="{ loading }"
        @click="createMedia"
      />
    </template>
  </Dialog>

  <Toast
    data-cy="media-upload-toast"
    errorIcon="pi pi-info-circle"
    group="media-upload"
    position="top-center"
  >
    <template v-if="!fileTypeIsValid" #message="slotProps">
      <div class="flex flex-col w-full pt-1">
        <div class="w-full flex items-bottom">
          <span class="p-toast-message-icon pi pi-info-circle"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
          </div>
        </div>
        <div>
          <span data-cy="media-upload-toast-error" class="block my-2 ml-8">
            {{ fileErrorDetails }}
          </span>
        </div>
      </div>
    </template>
  </Toast>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import FileUpload from 'primevue/fileupload';
import { useVuelidate } from '@vuelidate/core';
import { DEFAULT_TOAST_LIFE_MILLISECONDS, FILE, UPDATE } from '@/utils/constants';
import { useMediaStore } from '@/stores/admin/media/media.store';

const { t } = useI18n();
const toast = useToast();
const store = useMediaStore();
const { isMediaUploadModalOpen } = storeToRefs(store);

const loading = ref(false);
const isFailed = ref(false);
const fileErrorDetails = ref('');
const uploadSuccess = ref(false);
const fileIsUploaded = ref(false);
const fileIsLoading = ref(false);
const fileTypeIsValid = ref(true);
const mediaFile = ref<File | null>(null);
const mediaData = ref({
  file_id: null,
});

const modalTitle = computed(() => t('mediaLibrary.uploadNewMedia'));

const closeModal = () => {
  isMediaUploadModalOpen.value = false;
  loading.value = false;

  resetForm();
};

const resetForm = () => {
  uploadSuccess.value = false;
  fileIsUploaded.value = false;
  mediaFile.value = null;

  mediaData.value = {
    file_id: null,
  };

  v$.value.$reset();
};

const formIsNotValid = computed(() => {
  return v$.value.$error || mediaData.value.file_id === null;
});

const showSuccess = (actionName: string) => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: actionName === FILE ? t('file') : t('media'),
      action: actionName === FILE ? t('toast.uploaded') : t('toast.added'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'media-upload',
  });
};

const showError = (actionName: string) => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action:
        actionName === FILE
          ? t('toast.upload').toLocaleLowerCase()
          : t('toast.adding').toLocaleLowerCase(),
      name: actionName === FILE ? t('file') : t('media').toLocaleLowerCase(),
    }),
    group: 'media-upload',
  });
};

const uploadMediaFile = async (event: any) => {
  if (event.files.length === 0) return;

  mediaFile.value = event.files.length > 1 ? event.files.slice(-1)[0] : event.files[0];
  fileIsLoading.value = true;
  loading.value = true;
  toast.removeGroup('media-upload');

  try {
    const response = await store.uploadFile(
      event.files.length > 1 ? event.files.slice(-1)[0] : event.files[0]
    );

    isFailed.value = false;
    uploadSuccess.value = true;
    mediaData.value.file_id = response.data.id;
    showSuccess(FILE);
  } catch (error: any) {
    isFailed.value = true;
    fileIsUploaded.value = true;
    uploadSuccess.value = false;
    fileIsLoading.value = false;
    loading.value = false;

    if (error.response && error.response.status === 400 && error.response.data.file) {
      fileErrorDetails.value = error.response.data.file.error_details.error_message;
      fileTypeIsValid.value = false;
      showError(FILE);
    } else {
      showError(FILE);
    }
    mediaData.value.file_id = null;

    throw new Error(error);
  } finally {
    fileIsUploaded.value = true;
    fileIsLoading.value = false;
    loading.value = false;
  }
};

const createMedia = async () => {
  if (!mediaData.value.file_id) return;

  try {
    loading.value = true;
    await store.createMedia(mediaData.value.file_id);
    toast.removeGroup('media-upload');
    showSuccess(UPDATE);
    closeModal();
    await store.fetchMediaList();
  } catch (error: any) {
    toast.removeGroup('media-upload');
    showError(UPDATE);
    throw new Error(error);
  } finally {
    loading.value = false;
  }
};

const rules = computed(() => {
  return {};
});

const v$ = useVuelidate(rules, mediaData.value);
</script>

<style scoped lang="scss">
.pi-cloud-upload {
  color: $grey-medium;
  border: 3px solid $grey-medium;
  border-radius: 100px;
  font-size: 2.5rem;
  padding: 15px;
}

.info {
  color: $text-medium;
}
.drag-and-drop-wrapper {
  border: 2px solid $grey-medium-light;
  border-radius: 5px;
  padding: 30px;
}
.icon {
  font-size: 1.2rem;
  color: $text-medium;

  &-success {
    color: $primary;
    font-size: 1.5rem;
  }

  &-error {
    color: $error-toast;
    font-size: 1.5rem;
  }
  &-loading {
    color: $text-light;
    font-size: 1.5rem;
  }
}
</style>
