<template>
  <div>
    <PanelWithEdit :title="$t('info')" :editBtn="false">
      <div class="flex flex-col py-2">
        <div class="flex justify-between" v-for="(value, key) in tariffDetails" :key="key">
          <span
            data-cy="site-info-field-name"
            class="block font-medium text-[var(--gray-400)] py-1"
            >{{ $t(`tariff.tariffDetails.${key}`) }}</span
          >
          <span
            v-if="value"
            data-cy="tariff-info-field-value"
            class="py-1 pl-4 w-1/2 text-right font-medium text-[var(--gray-700)]"
            >{{ key === 'currency' ? currencyDetails : value }}</span
          >
          <span v-if="!value" class="empty py-2 pl-4 font-medium">--</span>
        </div>
      </div>
    </PanelWithEdit>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { prettify } from '@/utils/textFormatting';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';

import type { Tariff } from '@/models/tariffs.model';

const props = defineProps<{
  tariff: Tariff;
}>();

const tariffDetails = computed(() => {
  return {
    name: props.tariff.name,
    currency: props.tariff.currency,
    currencySymbolPosition: props.tariff.currency_symbol_position
      ? prettify(props.tariff.currency_symbol_position)
      : props.tariff.currency_symbol_position,
  };
});

const currencyDetails = computed(
  () => `${props.tariff.currency} (${props.tariff.currency_symbol})`
);
</script>
<style scoped lang="scss"></style>
