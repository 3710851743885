<template>
  <div v-if="convertedSettingDefinition !== undefined" class="setting-widget">
    <div class="setting-header">
      <widget-label :label="label" :description="description" class="label-container" />
      <div class="value-container">
        <pre :data-cy="`${props.setting.path}-last-value`">{{ display(value) }}</pre>
        <button
          class="edit"
          @click="toggleEditSection"
          :disabled="!writable"
          :data-cy="`${props.setting.path}-expand-edit-section`"
        >
          <i class="pi pi-pencil"></i>
        </button>
      </div>
    </div>

    <div class="card" v-if="expanded">
      <div v-if="description" class="section flex flex-row items-center gap-2 text-gray-500">
        <i class="pi pi-info-circle"></i>
        <!-- TODO: move style to class and/or do not use width  -->
        <div class="wrap-description-text">{{ description }}</div>
      </div>

      <generic-input class="section" v-bind="inputParams" @update:value="onUpdate" />

      <div v-if="settingApplyError">
        <div class="apply-error-container" data-cy="settings-update-error">
          <i class="pi pi-exclamation-triangle"></i>
          <span>{{ t('dashboard.controlPanel.setting.error.updateFailed') }}</span>
        </div>
      </div>

      <div class="flex items-center justify-center p-1 gap-1">
        <Button
          :label="t('cancel')"
          class="p-button-sm p-button-footer p-button-white block"
          @click="cancel"
        />
        <Button
          :label="t('apply')"
          class="p-button-sm p-button-footer p-button-primary block"
          :disabled="!canApplyChanges"
          :loading="loading"
          @click="apply"
          :data-cy="`${props.setting.path}-apply-change`"
        />
      </div>
    </div>

    <div v-if="pendingValue !== undefined" class="card pending-value">
      <div class="section flex flex-row items-center">
        <div class="flex flex-grow gap-2 text-gray-500 items-center">
          <i class="pi pi-exclamation-triangle"></i>
          <span>{{ t('dashboard.controlPanel.setting.status.pendingChange') }}</span>
        </div>

        <button
          :disabled="!writable || cancelPendingChangeLoading"
          @click="cancelPendingChange"
          :data-cy="`${props.setting.path}-cancel-pending-change`"
        >
          <div class="w-4 h-4" v-if="cancelPendingChangeLoading">
            <i class="pi pi-spin pi-spinner" />
          </div>
          <span>{{ t('dashboard.controlPanel.setting.actions.cancel') }}</span>
        </button>
      </div>

      <div class="section flex flex-row items-center gap-2">
        <p class="pending-value">{{ display(value) }}</p>
        <i class="pi pi-arrow-right text-xs"></i>
        <p class="pending-value">{{ display(pendingValue) }}</p>
      </div>
    </div>
  </div>
  <div v-else />
</template>

<script setup lang="ts">
import type { SettingWidget } from '@/models/controlPanel/dashboard';
import { prettify } from '@/utils/textFormatting';
import { useControlPanelStore } from '@/stores/admin/controlPanel/controlPanel.store';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import { type Events, data } from '@/logic/controlPanel/eventbus';
import { settingIsEnum, settingIsNumber } from '@/models/productTypes.model';
import type { UnitSystem } from '@/models/datatypes.model';
import { convertSetting, convertValue } from '@/utils/units';
import GenericInput, { type GenericInputProps } from '@/components/common/inputs/GenericInput.vue';
import WidgetLabel from '@/components/hardwareSystems/controlPanel/widgets/common/WidgetLabel.vue';

const { t } = useI18n();
const controlPanelStore = useControlPanelStore();
const props = defineProps<{ setting: SettingWidget; desiredUnitSystem?: UnitSystem }>();

const expanded = ref(false);
const loading = ref(false);
const cancelPendingChangeLoading = ref(false);
const settingApplyError = ref<string | undefined>(undefined);

const settingDefinition = controlPanelStore.getSettingByPath(props.setting.path);

const convertedSettingDefinition =
  settingIsNumber(settingDefinition) && props.desiredUnitSystem !== undefined
    ? convertSetting(settingDefinition, props.desiredUnitSystem)
    : settingDefinition;

function convert<T extends ValueType>(
  value: T,
  direction: 'ORIG_TO_DESIRED' | 'DESIRED_TO_ORIG'
): T {
  if (
    settingIsNumber(settingDefinition) &&
    settingIsNumber(convertedSettingDefinition) &&
    typeof value === 'number'
  ) {
    return direction === 'ORIG_TO_DESIRED'
      ? convertValue(value, settingDefinition.unit, convertedSettingDefinition.unit)[0]
      : convertValue(value, convertedSettingDefinition.unit, settingDefinition.unit)[0];
  }

  return value;
}
function capitalizeFirstLetter(str: string) {
    if (str.length === 0) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}
const label =
  props.setting.label || capitalizeFirstLetter(convertedSettingDefinition?.path.split('.').pop() || 'Setting');
const description = props.setting.description || settingDefinition?.description;

type ValueType = number | string | boolean | undefined;

const cachedValue = controlPanelStore.getSettingValueFromCache(props.setting.path);

const value = ref<ValueType>(cachedValue?.value);
const updatedValue = ref<ValueType>(undefined);
const pendingValue = ref<ValueType>(undefined);

const inputValid = ref(true);
const inputParams: GenericInputProps = {
  id: props.setting.path,
  type: convertedSettingDefinition?.type || 'string',
  defaultValue: value.value,
  min: settingIsNumber(convertedSettingDefinition)
    ? convertedSettingDefinition.minValue
    : undefined,
  max: settingIsNumber(convertedSettingDefinition)
    ? convertedSettingDefinition.maxValue
    : undefined,
  enumValues: settingIsEnum(convertedSettingDefinition)
    ? convertedSettingDefinition?.enumValues
    : undefined,
  originalUnit: settingIsNumber(convertedSettingDefinition)
    ? convertedSettingDefinition.unit
    : undefined,
  desiredUnitSystem: props.desiredUnitSystem,
};

function onUpdate(value: number | string | boolean | undefined, valid: boolean) {
  updatedValue.value = convert(value, 'DESIRED_TO_ORIG');
  inputValid.value = valid;
}

const writable = !!props.setting.writable;

const toggleEditSection = () => {
  if (!expanded.value) updatedValue.value = value.value;
  inputParams.defaultValue = updatedValue.value;
  expanded.value = !expanded.value;
};

const cancelPendingChange = async () => {
  if (!writable || cancelPendingChangeLoading.value) return;
  cancelPendingChangeLoading.value = true;
  await controlPanelStore
    .applyChange(props.setting.path, value.value)
    .then(() => {
      controlPanelStore.refreshData();
      expanded.value = false;
    })
    .catch((e) => {
      console.error(e);
      settingApplyError.value = e.message || e;
    })
    .finally(() => {
      cancelPendingChangeLoading.value = false;
    });
};

const apply = async () => {
  if (!writable || !inputValid.value) return;
  loading.value = true;
  await controlPanelStore
    .applyChange(props.setting.path, updatedValue.value)
    .then(() => {
      controlPanelStore.refreshData();
      expanded.value = false;
    })
    .catch((e) => {
      console.error(e);
      settingApplyError.value = e.message || e;
    })
    .finally(() => {
      loading.value = false;
    });
};

const cancel = () => {
  expanded.value = false;
};

const onNewSettingData = (event: Events['setting']) => {
  if (event.path.endsWith(props.setting.path)) {
    value.value = convert(event.value, 'ORIG_TO_DESIRED');
    pendingValue.value = convert(event.pending, 'ORIG_TO_DESIRED');
  }
};

const display = (value: ValueType) => {
  let displayValue = value;

  switch (convertedSettingDefinition?.type) {
    case 'float':
    case 'double':
      displayValue = displayValue ? Number(value).toFixed(2) : '--';
      break;
    case 'bool':
      displayValue = value ? 'True' : 'False';
      break;
    case 'enum':
      displayValue =
        convertedSettingDefinition.enumValues.find((enumValue) => enumValue.value == value)
          ?.label || value;
      break;
  }

  if (settingIsNumber(convertedSettingDefinition) && convertedSettingDefinition.unit) {
    displayValue += ' ' + convertedSettingDefinition.unit;
  }

  return displayValue;
};

const canApplyChanges = computed(
  () => writable && inputValid.value && updatedValue.value !== value.value
);

onMounted(() => data.on('setting', onNewSettingData));
onBeforeUnmount(() => data.off('setting', onNewSettingData));

watch(updatedValue, () => {
  settingApplyError.value = undefined;
});
</script>

<style scoped lang="scss">
.wrap-description-text {
  word-wrap: break-word;
  display: inline-block;
  width: 90%;
}

div.setting-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  margin: 0.5rem;
  box-sizing: border-box;
}

div.value-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.5rem;
  background-color: var(--gray-50);
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
  overflow: hidden;

  pre {
    padding: 0 0.75rem;
    box-sizing: border-box;
    background-color: var(--gray-50);
    font-size: 0.9rem;
    overflow-x: auto;
  }

  button {
    height: 100%;
    border-left: 1px solid var(--gray-200);
    padding: 0 0.5rem;
    background-color: var(--gray-0);

    &:hover {
      background-color: var(--gray-50);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: var(--gray-50);
      color: var(--gray-400);
    }
  }
}

div.setting-expanded {
  background-color: var(--gray-50);
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
  margin: 0.5rem;
}

div.description-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  box-sizing: border-box;

  font-family: Roboto, sans-serif;
  font-size: 0.9rem;
  color: var(--gray-400);
  border-bottom: 1px solid var(--gray-200);
}

div.setting-container {
  padding: 0.5rem;
  box-sizing: border-box;
  border-bottom: 1px solid var(--gray-200);
}

div.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.5rem;

  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);

  input {
    height: 100%;
    text-align: right;
    padding: 0 0.5rem;
  }

  &.invalid {
    border: 1px solid var(--red-primary);
  }

  .unit {
    padding: 0.5rem;
    font-size: 0.9rem;
    color: var(--gray-400);
    border-left: 1px solid var(--gray-200);
  }
}

div.validator-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 0.25rem;
  gap: 0.25rem;
}

p.hint {
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  background-color: var(--gray-50);
  color: var(--gray-500);

  border-radius: var(--rounded-sm);
  border: 1px solid var(--gray-200);

  &.invalid {
    border-color: var(--red-primary-dark);
    background-color: var(--red-500);
    color: var(--gray-0);
  }
}

div.confirmation-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  box-sizing: border-box;
}

div.pending-value {
  button {
    display: flex;
    gap: 0.5rem;
    padding: 0.125rem 0.5rem;
    border-radius: var(--rounded-md);
    border: 1px solid var(--gray-200);
    cursor: pointer;
    background-color: var(--gray-0);
    white-space: nowrap;

    &:hover {
      background-color: var(--gray-50);
    }

    &.loading {
      cursor: default;
      background-color: var(--gray-50);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: var(--gray-50);
      color: var(--gray-400);
    }
  }
}

p.pending-value {
  font-family: monospace;
  border: 1px solid var(--gray-200);
  border-radius: var(--rounded-sm);
  padding: 0.25rem 0.5rem;

  overflow-x: auto;
  white-space: normal;
}

div.not-found-container {
  background-color: var(--gray-50);
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
  margin: 0.5rem;
  padding: 0.5rem;
  color: var(--gray-400);

  p {
    font-size: 0.9rem;

    .path {
      font-family: monospace;
      background-color: var(--gray-200);
      border-radius: var(--rounded-sm);
      padding: 0.25rem;
    }
  }
}

.apply-error-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  color: var(--gray-0);
  background-color: var(--red-primary);
  border-radius: var(--rounded-md);

  padding: 0.5rem 1rem;
  margin: 0.5rem;
}

.card {
  background-color: var(--gray-50);
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
  margin: 0.5rem;
}

.section {
  padding: 0.5rem;
  box-sizing: border-box;

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-200);
  }
}
</style>
