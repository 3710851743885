<template>
  <div class="flex flex-col">
    <div class="my-3 text-right" v-for="(value, key) in chargeParkDetails" :key="key">
      <!-- Workaround to show newlines with proper padding on right aligned text (Issue here was only last line had padding applied if using newlines)  -->
      <div
        v-if="value && key === 'operator_address'"
        data-cy="site-info-field-value"
        class="py-[9px] px-4 w-1/2 text-right font-medium show-new-lines show-new-lines-div"
      >
        {{ value }}
      </div>
      <span
        v-else-if="value"
        data-cy="site-info-field-value"
        :class="`py-[9px] px-4 w-1/2 text-right font-medium ${
          key === 'owner_organisation_name' ? 'text-[var(--gray-400)]' : 'text-[var(--gray-700)]'
        } `"
        >{{ value }}</span
      >

      <span v-if="!value" class="empty py-2 px-4 font-medium">--</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import type { ChargePark } from '@/models/chargeParks.model';

const props = defineProps<{
  chargePark: ChargePark;
}>();

const chargeParkDetails = computed(() => {
  return {
    owner_organisation_name: props.chargePark.owner_organisation_name,
    name: props.chargePark.name,
    operator_name: props.chargePark.operator_name,
    operator_address: props.chargePark.operator_address,
  };
});
</script>
<style scoped lang="scss">
.show-new-lines-div {
  width: 100%;
}
</style>
