import { defineStore } from 'pinia';
import type {
  Contact,
  ContactEditData,
  ContactEditSendData,
  ContactVerifyData,
} from '@/models/contacts.model';
import {
  createContact,
  fetchContacts,
  updateContact,
  verifyContactCode,
  sendContactVerificationCode,
  deleteContact,
} from '@/stores/admin/contacts/contacts.api';

interface ContactsState {
  contacts: Contact[];
  contactsModalIsOpen: Boolean;
  contactEditionData: ContactEditSendData;
  contactIsEditing: Boolean;
  contactVerifyModalIsOpen: Boolean;
  contactDeleteModalIsOpen: Boolean;
  contactDeleteData: {
    id: number;
  };
  contactVerificationData: ContactVerifyData;
}

export const useContactsStore = defineStore('contacts', {
  state: (): ContactsState => ({
    contacts: [],
    contactsModalIsOpen: false,
    contactEditionData: {
      id: 0,
      name: '',
      email: null,
      phone_number: null,
      type: '',
      enabled: true,
    },
    contactIsEditing: false,
    contactDeleteModalIsOpen: false,
    contactVerifyModalIsOpen: false,
    contactDeleteData: {
      id: 0,
    },
    contactVerificationData: {
      id: 0,
      type: '',
      phone_number: null,
      email: null,
    },
  }),
  actions: {
    async fetchContactsList() {
      return await fetchContacts()
        .then((response) => {
          this.contacts = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async createNewContact(data: ContactEditData) {
      return await createContact(data)
        .then((response) => {
          this.fetchContactsList();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async editContact(data: ContactEditSendData) {
      const { id, ...targetData } = data;
      return await updateContact(id, targetData)
        .then((response) => {
          this.fetchContactsList();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async verifyNewContactCode(id: number, code: string) {
      return await verifyContactCode(id, code)
        .then((response) => {
          this.fetchContactsList();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async sendVerificationCode(id: number) {
      return await sendContactVerificationCode(id)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async deleteContactItem(id: number) {
      return await deleteContact(id)
        .then((response) => {
          this.fetchContactsList();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    resetContactEditionData() {
      this.contactEditionData = {
        id: 0,
        name: '',
        email: null,
        phone_number: null,
        type: '',
        enabled: true,
      };
    },

    resetContactVerifyData() {
      this.contactVerificationData = {
        id: 0,
        type: '',
        phone_number: null,
        email: null,
      };
    },
  },
});
