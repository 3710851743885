<template>
  <loading-widget v-if="loading" class="chart" />

  <div v-else>
    <!--<widget-details v-if="showSummary" :items="summaryItems" />-->
    <v-chart
      ref="chart"
      v-if="options != null"
      :option="options"
      class="chart"
      @zr:mousemove="onMouseMove"
      autoresize
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { DatasetComponent, GridComponent, TooltipComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import VChart from 'vue-echarts';
import type { EChartsOption } from 'echarts';
import type {
  PieChartWidget,
  WidgetData,
  WidgetFilter,
} from '@/stores/admin/dashboard/dashboard.types';
import { fetchConnectorsStates, loadWidgetData } from '@/stores/admin/dashboard/dashboard.api';
import {
  buildEchartsOption,
  buildSummary,
  type SummaryItem,
} from '@/stores/admin/dashboard/dashboard.logic';
import LoadingWidget from '@/components/dashboard/widget/LoadingWidget.vue';
import WidgetDetails from '@/components/dashboard/misc/WidgetDetails.vue';
import type { WidgetContextResolver } from '@/stores/admin/dashboard/dashboard.logic.context';
import { ColorGenerator } from '@/stores/admin/dashboard/dashboard.logic.colors';
import { fetchSystemStates } from '@/stores/admin/dashboard/dashboard.api';
import { fetchConnectorStateTypes } from '@/stores/admin/hardwareSystems/hardwareSystems.api';
import type { ConnectorStateType } from '@/models/hardwareSystems.model';

const props = defineProps<{
  widget: PieChartWidget;
  additionalFilters: WidgetFilter[];
  isInteractive?: boolean;
  contextResolver: WidgetContextResolver;
}>();

use([TooltipComponent, PieChart, CanvasRenderer, DatasetComponent, GridComponent]);

const loading = ref(true);
const options = ref<EChartsOption | null>(null);
const error = ref<Error | null>(null);

const summaryItems = ref<SummaryItem[]>([]);
const showSummary = props.widget.type === 'pie';
let colorGen = new ColorGenerator(props.widget?.presentation?.colorMap);
const chart = ref<typeof VChart | null>(null);

const onMouseMove = () => {
  if (!props.isInteractive) chart.value?.chart?.getZr().setCursorStyle('default');
};

onMounted(async () => {
  let dataResult;
  console.warn("-----------NMOUNTED PUE CHART")
  if (props.widget.api == 'fetchSystemStates') {
    
    const charge_park_id =  props.additionalFilters && props.additionalFilters.length == 1 &&  props.additionalFilters[0].dimension ==="chargepark.id" && props.additionalFilters[0].condition ==="=" ? Number(props.additionalFilters[0].value) :undefined;
    dataResult = await fetchSystemStates(charge_park_id);
  } else if (props.widget.api == 'fetchConnectorsStates') {
    function mapConnectorTypesAsColorMap(data: ConnectorStateType[]): Record<string, string> {
      const result: Record<string, string> = {};

      for (let item of data) {
        result[item.name] = item.color;
      }

      return result;
    }
    const charge_park_id = props.additionalFilters && props.additionalFilters.length == 1 &&  props.additionalFilters[0].dimension ==="chargepark.id" && props.additionalFilters[0].condition ==="=" ? Number(props.additionalFilters[0].value) :undefined;
    
    dataResult = await fetchConnectorsStates(charge_park_id);
    const connectorTypes = await fetchConnectorStateTypes();

    props.widget.presentation = {
      colorMap: {
        type: 'discreteBys',
        colorBy: 'index',
        map: mapConnectorTypesAsColorMap(connectorTypes.data as ConnectorStateType[]),
        fallback: '#000000',
      },
    };

    colorGen = new ColorGenerator(props.widget.presentation.colorMap);
  } else if (!props.widget.query) {
    throw Error('query is not defined for widget. You need to use api or query props');
  } else {
    dataResult = await loadWidgetData(props.widget.query, props.additionalFilters);
  }

  if (!dataResult.ok) {
    error.value = dataResult.error;
    return;
  }

  const optionResult = await buildEchartsOption(
    props.widget,
    colorGen,
    dataResult.data,
    props.contextResolver
  );
  if (!optionResult.ok) {
    error.value = optionResult.error;
  } else options.value = optionResult.data;

  summaryItems.value = buildSummary(props.widget, dataResult.data, colorGen);
  loading.value = false;
});
</script>

<style scoped lang="scss">
.chart {
  height: 400px;
}
</style>
