export const convertTime = (h: number | null, m: number | null, s: number | null) => {
  if (h && m && s) {
    return h * 3600 + m * 60 + s;
  } else if (h && !m && s) {
    return h * 3600 + 0 + s;
  } else if (h && m && !s) {
    return h * 3600 + m * 60 + 0;
  } else if (!h && m && s) {
    return 0 + m * 60 + s;
  } else if (!h && !m && s) {
    return 0 + 0 + s;
  } else if (!h && m && !s) {
    return 0 + m * 60 + 0;
  } else if (h && !m && !s) {
    return h * 3600 + 0 + 0;
  } else {
    return null;
  }
};
