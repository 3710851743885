import type { AxiosPromise, AxiosResponse } from 'axios';
import axios from 'axios';
import type { ReportBatchEditData, ReportEditData } from '@/models/reports.model';
import type { ID } from '@/models/common.model';

export async function fetchReports(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/reports/`);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export async function fetchGeneratedReports(
  startTime?: string,
  currentTime?: string
): Promise<AxiosResponse> {
  try {
    if (!startTime && !currentTime) {
      return await axios.get<AxiosPromise>(`/api/generated-reports/`);
    }

    return await axios.get<AxiosPromise>(
      `/api/generated-reports/?start_time=${startTime}&end_time=${currentTime}`
    );
  } catch (error) {
    return await Promise.reject(error);
  }
}

export async function fetchReportTemplates(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/report-templates/`);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export async function fetchReportFrequencies(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/report-frequencies/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function batchUpdateReportRequest(data: ReportBatchEditData): Promise<AxiosResponse> {
  return axios
    .patch<AxiosPromise>(`/api/reports/batch/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function batchDeleteReportRequest(ids: ID[]): Promise<AxiosResponse> {
  return axios
    .delete<AxiosPromise>(`/api/reports/batch/`, {
      data: { items: ids },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function createReportRequest(data: ReportEditData): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/reports/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function editReportRequest(id: number, data: ReportEditData): Promise<AxiosResponse> {
  return axios
    .patch<AxiosPromise>(`/api/reports/${id}/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function fetchReportFilePath(url: string): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/generated-reports/files/${url}`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}
