<template>
  <Panel :header="title" :class="!editBtn ? 'no-buttons' : ''">
    <template #header>
      <slot name="customHeader"></slot>
    </template>
    <template #icons>
      <slot name="header"></slot>
      <Button
        v-if="cancelBtn && isEditing"
        :label="t('cancel')"
        icon="pi pi-times"
        class="p-button-outlined p-button-white p-button-xs mr-2"
        @click="onClose"
      />
      <Button
        v-if="editBtn"
        data-cy="panel-open-edit"
        :label="$t('edit')"
        :disabled="editIsDisabled"
        icon="pi pi-pencil"
        class="p-button-outlined p-button-white p-button-xs"
        @click="onEdit"
      />
    </template>
    <div :class="`px-4 ${errorMessage ? 'pt-1 pb-0' : 'py-1'}`">
      <slot></slot>
    </div>
    <div class="flex justify-end">
      <span v-if="errorMessage" class="pb-3 pr-6 p-error text-xs">{{ errorMessage }}</span>
    </div>
    <div
      v-if="isEditing && showFooter"
      class="flex justify-center border-t border-t-[var--gray-200]"
    >
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white my-3 mx-1"
        @click="onClose"
      />
      <Button
        data-cy="panel-edit-apply"
        :label="applyLabel"
        class="p-button-sm p-button-footer p-button-primary my-3 mx-1"
        @click="onApply"
        :loading="loading"
        :disabled="isNotValid"
      />
    </div>
  </Panel>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';

import Panel from 'primevue/panel';
import Button from 'primevue/button';

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    title?: string;
    loading?: boolean;
    isError?: boolean;
    errorMessage?: string | null;
    isEditing?: boolean;
    isNotValid?: boolean;
    showFooter?: boolean;
    cancelBtn?: boolean;
    editBtn?: boolean;
    editIsDisabled?: boolean;
  }>(),
  {
    loading: false,
    isError: false,
    isEditing: false,
    isNotValid: false,
    showFooter: true,
    cancelBtn: false,
    editBtn: true,
    editIsDisabled: false,
  }
);

const applyLabel = computed(() => (props.isError ? t('error') : t('apply')));
const isEditing = ref(props.isEditing);

const emit = defineEmits(['onClose', 'onEdit', 'onApply']);

const onClose = () => {
  isEditing.value = false;
  emit('onClose');
};

const onApply = async () => {
  await v$.value.$validate();

  if (v$.value.$error) return;

  isEditing.value = false;
  emit('onApply');
};

const onEdit = () => {
  isEditing.value = true;
  emit('onEdit');
};

const v$ = useVuelidate();
</script>

<style scoped lang="scss">
.p-panel {
  box-shadow: none;
  border: 1px solid $grey-medium-light;

  &.no-buttons {
    :deep(.p-panel-header) {
      padding: 18px 16px;
    }
  }
}
:deep(.p-panel-header) {
  padding: 10px 16px;
  background-color: $grey-light;
  border-bottom: 1px solid $grey-medium-light;
}

:deep(.p-panel-content) {
  padding: 0;
}

:deep(.p-panel-title) {
  font-size: 16px;
  color: $text-dark;
}
</style>
