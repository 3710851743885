<template>
  <Dialog
    data-cy="user-delete-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="flex field-no-edit">
      <div class="w-full info-single">
        <div class="flex justify-between py-1 info-item">
          <span class="text-grey-medium font-semibold">{{ t('name') }}</span>
          <span class="text-grey-medium">{{ userDeleteData.name }}</span>
        </div>
        <div class="flex justify-between py-2 info-item">
          <span class="text-grey-medium font-semibold">{{ t('email') }}</span>
          <span class="text-grey-medium">{{ userDeleteData.email }}</span>
        </div>
      </div>
    </div>
    <div class="field flex flex-col field-edit mt-1">
      <Message severity="error" icon="pi pi-exclamation-triangle" :closable="false">{{
        $t('deleteWarning')
      }}</Message>
      <p class="delete-info mt-2 mb-3">
        {{ $t('user.deleteInfo') }}
      </p>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        data-cy="delete-user-button"
        :label="t('delete')"
        class="p-button-sm p-button-footer p-button-error mx-auto mb-2 block mt-5"
        @click="deleteUser"
        :loading="loading"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="user-delete-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="delete-user"
  />
</template>

<script lang="ts">
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Message from 'primevue/message';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { useI18n } from 'vue-i18n';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

export default {
  name: 'UserDeleteModal',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    Message,
    Toast,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const store = useUsersStore();
    const isVisible = ref(false);
    const { userDeleteModalIsOpen, userDeleteData } = storeToRefs(store);
    const modalTitle = computed(() => t('delete', { itemName: t('user.title') }));
    const loading = ref(false);

    const closeModal = () => {
      userDeleteModalIsOpen.value = false;
      store.resetUserDeleteData();
      loading.value = false;
    };

    const successSummary = computed(() => {
      return t('toast.success', {
        name: t('user.title'),
        action: t('toast.deleted'),
      });
    });

    const errorSummary = computed(() => {
      return t('toast.error', {
        action: t('toast.deletion'),
        name: t('user.title'),
      });
    });

    const showSuccess = () => {
      toast.add({
        severity: 'success',
        summary: successSummary.value,
        life: DEFAULT_TOAST_LIFE_MILLISECONDS,
        group: 'delete-user',
      });
    };

    const showError = () => {
      toast.add({
        severity: 'error',
        summary: errorSummary.value,
        group: 'delete-user',
      });
    };

    const deleteUser = async () => {
      loading.value = true;

      await store
        .removeUser()
        .then((response: any) => {
          loading.value = false;
          if (response.status === 204) {
            toast.removeGroup('delete-user');
            showSuccess();
            closeModal();
          }
        })
        .catch((error) => {
          loading.value = false;
          toast.removeGroup('delete-user');
          showError();
          throw new Error(error);
        });
    };

    watch(
      () => userDeleteModalIsOpen.value,
      (value) => {
        if (value) {
          isVisible.value = true;
        } else {
          isVisible.value = false;
        }
      }
    );

    return {
      t,
      toast,
      isVisible,
      closeModal,
      modalTitle,
      deleteUser,
      userDeleteModalIsOpen,
      loading,
      userDeleteData,
    };
  },
};
</script>
