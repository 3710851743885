<template>
  <Dialog
    data-cy="user-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '660px' }"
    contentClass="custom-content"
    :modal="true"
    @hide="closeModal"
  >
    <div v-if="userIsEditing" class="flex justify-between field-no-edit font-semibold">
      <span>{{ $t('email') }}</span>
      <span data-cy="user-edit-email">{{ userEditionData.email }}</span>
    </div>
    <div class="field flex flex-col field-edit">
      <label for="userName" class="mb-2 field-edit-label"
        >{{ $t('name') }}<span class="required">*</span></label
      >
      <InputText
        data-cy="user-name-input"
        id="userName"
        type="text"
        aria-describedby="userName-help"
        class="p-inputtext-sm"
        v-model="user.name"
        :placeholder="$t('name')"
        @keyup="validateName"
      />
      <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>
    <div v-if="!userIsEditing" class="field flex flex-col px-12 pb-4">
      <label for="userEmail" class="mb-2 field-edit-label"
        >{{ $t('email') }}<span class="required">*</span></label
      >
      <InputText
        data-cy="user-email-input"
        id="userEmail"
        type="email"
        aria-describedby="userEmail-help"
        class="p-inputtext-sm"
        v-model="user.email"
        :placeholder="$t('email')"
        @keyup="validateEmail"
      />
      <div class="input-errors" v-for="(error, index) of v$.email.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>
    <UserRolesPicker
      v-if="userEditionData.email !== userEmail"
      custom-class="user-modal-role-picker"
      :organisationRoles="userOrganisation.roles"
      :id="user.id"
      :title="$t('user.role.roles')"
      :value="user.roles"
      @onRolesSelect="setSelectedRoles"
      @onRolesUnselect="setUnselectedRoles"
      @onRolesSelectAll="setAllSelectedRoles"
      @onUnselectedAllRoles="setAllUnselectedRoles"
    />
    <div v-if="userEditionData.email === userEmail" data-cy="roles-edit-info" class="p-2 px-12">
      <span class="block title font-medium field-edit-label">{{ $t('user.role.roles') }}</span>
      <span class="block text-grey text-sm pt-2 pb-4">{{ $t('user.role.infoEdit') }}</span>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        v-if="!userIsEditing"
        data-cy="create-user-button"
        :label="createLabel"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        :disabled="formIsNotValid"
        @click="createUser"
        :loading="loading"
      />
      <Button
        v-else
        data-cy="edit-user-button"
        :label="editLabel"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        :disabled="editFormIsNotValid"
        @click="editUser"
        :loading="loading"
      />
    </template>
  </Dialog>
  <Toast data-cy="users-toast" position="top-center" errorIcon="pi pi-info-circle" group="users">
    <template v-if="isFailed" #message="slotProps">
      <div class="flex flex-col w-full pt-1">
        <div class="w-full flex">
          <span class="p-toast-message-icon pi pi-info-circle"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
          </div>
        </div>
        <span
          class="my-2 ml-8 font-medium"
          v-for="(value, key, index) in errorMessages"
          :key="index"
        >
          <span class="pr-1" v-for="(valueItem, index) in value" :key="index">{{
            valueItem === 'This field must be unique.' ? $t('toast.existingEmail') : valueItem
          }}</span>
        </span>
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, email, maxLength } from '@vuelidate/validators';
import { asciiCharacters } from '@/utils/validationRules';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { CREATE, DEFAULT_TOAST_LIFE_MILLISECONDS, EDIT, MAX_LENGTH } from '@/utils/constants';
import UserRolesPicker from './UserRolesPicker.vue';
import type { UserRole, UserRoleId } from '@/models/users.model';

export default {
  name: 'UserModal',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    InputText,
    Toast,
    UserRolesPicker,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const store = useUsersStore();
    const isVisible = ref(false);
    const loading = ref(false);
    const errorMessages = ref();
    const isFailed = ref(false);
    const { userModalIsOpen, userEditionData, userIsEditing, userOrganisation } =
      storeToRefs(store);
    const user = ref({
      id: null,
      created_at: '',
      name: '',
      email: '',
      organisation_id: null,
      created_by_user_id: null,
      roles: [] as UserRoleId[],
    });
    const userEmail = ref(store.me.email);

    const modalTitle = computed(() => {
      const title = userIsEditing.value
        ? t('edit', { itemName: t('user.title') })
        : t('create', { itemName: t('user.title') });
      return `${title} in ${userOrganisation.value.name}`;
    });

    const createLabel = computed(() => {
      return v$.value.$error ? t('error') : t('apply');
    });

    const editLabel = computed(() => {
      return v$.value.$error ? t('error') : t('apply');
    });

    const closeModal = () => {
      userModalIsOpen.value = false;
      loading.value = false;

      resetForm();

      if (userIsEditing) {
        userIsEditing.value = false;
      }
    };

    const setSelectedRoles = (id: UserRoleId) => {
      user.value.roles.push(id);
      v$.value.roles.$touch();
    };

    const setUnselectedRoles = (role: UserRoleId) => {
      const rolePos = user.value.roles.findIndex((item) => item.id === role.id);
      user.value.roles.splice(rolePos, 1);
      if (user.value.roles.length === 0) {
        v$.value.roles.$reset();
      } else {
        v$.value.roles.$touch();
      }
    };

    const setAllSelectedRoles = (ids: UserRoleId[]) => {
      user.value.roles = ids;
      v$.value.roles.$touch();
    };

    const setAllUnselectedRoles = () => {
      user.value.roles = [];
      v$.value.roles.$reset();
    };

    const showSuccess = (actionName: string) => {
      toast.add({
        severity: 'success',
        summary: t('toast.success', {
          name: t('user.title'),
          action: actionName === CREATE ? t('toast.created') : t('toast.edited'),
        }),
        life: DEFAULT_TOAST_LIFE_MILLISECONDS,
        group: 'users',
      });
    };

    const showError = (actionName: string) => {
      toast.add({
        severity: 'error',
        summary: t('toast.error', {
          action: actionName === CREATE ? t('toast.creation') : t('toast.edition'),
          name: t('user.title'),
        }),
        group: 'users',
      });
    };

    const createUser = async () => {
      await v$.value.$validate();

      if (v$.value.$error) return;

      const data = {
        name: user.value.name,
        email: user.value.email,
        organisation_id: userOrganisation.value.id,
        roles: user.value.roles.map((role) => role.id),
      };

      loading.value = true;

      await store
        .createNewUser(data)
        .then((response) => {
          loading.value = false;
          if (response.status === 201) {
            toast.removeGroup('users');
            showSuccess(CREATE);
            closeModal();
          }
        })
        .catch((error) => {
          loading.value = false;
          toast.removeGroup('users');
          if (error.response && error.response.status === 400 && error.response.data.email) {
            errorMessages.value = error.response.data;
            isFailed.value = true;
            showError(CREATE);
          } else {
            showError(CREATE);
          }
          throw new Error(CREATE);
        });
    };

    const editUser = async () => {
      await v$.value.name.$validate();

      if (v$.value.$error) return;

      const data = {
        name: user.value.name,
        email: userEditionData.value.email,
        organisation_id: userOrganisation.value.id,
        roles: user.value.roles.map((role) => role.id),
      };

      const ownUserData = {
        name: user.value.name,
        email: userEditionData.value.email,
        organisation_id: userOrganisation.value.id,
      };

      const editData = userEditionData.value.email === userEmail.value ? ownUserData : data;

      loading.value = true;

      await store
        .updateUser(editData)
        .then((response: any) => {
          loading.value = false;
          if (response.status === 200) {
            showSuccess(EDIT);
            closeModal();
          }
        })
        .catch((error) => {
          loading.value = false;
          showError(EDIT);
          throw new Error(error);
        });
    };

    watch(
      () => userModalIsOpen.value,
      (value) => {
        if (value) {
          user.value.roles = user.value.roles.filter((role) =>
            userOrganisation.value.roles.some((orgRole: UserRole) => role.id === orgRole.id)
          );
          isVisible.value = true;
        } else {
          isVisible.value = false;
        }
      }
    );

    watch(
      () => store.userEditionData.id,
      (id) => {
        if (id !== 0) {
          user.value.name = store.userEditionData.name;
          user.value.roles = store.userEditionData.roles;
        }
      }
    );

    const validateName = async () => {
      await v$.value.name.$validate();
    };

    const validateEmail = async () => {
      await v$.value.email.$validate();
    };

    const resetForm = () => {
      user.value.name = '';
      user.value.email = '';
      user.value.roles = [];
      v$.value.name.$reset();
      v$.value.email.$reset();
      v$.value.roles.$reset();
      v$.value.$reset();
      store.resetUserEditionData();
    };

    const formIsNotValid = computed(() => {
      return (
        v$.value.$error ||
        user.value.name === '' ||
        user.value.email === '' ||
        user.value.roles.length === 0
      );
    });

    const editFormIsNotValid = computed(() => {
      return !v$.value.$anyDirty || v$.value.name.$error || user.value.roles.length === 0
        ? true
        : false;
    });

    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage(
            t('rules.required', { field: t('name'), item: t('user.title').toLowerCase() }),
            required
          ),
          asciiCharacters: helpers.withMessage(
            t('rules.asciiCharacters', { field: t('user.title') }),
            asciiCharacters
          ),
          maxLength: maxLength(MAX_LENGTH),
        },
        email: {
          required: helpers.withMessage(
            t('rules.required', {
              field: t('email').toLowerCase(),
              item: t('user.title').toLowerCase(),
            }),
            required
          ),
          email: helpers.withMessage(t('rules.email'), email),
          maxLength: maxLength(MAX_LENGTH),
        },
        roles: {
          required: helpers.withMessage(
            t('rules.required', {
              field: t('user.role.title').toLowerCase(),
              item: t('user.title').toLowerCase(),
            }),
            required
          ),
        },
      };
    });

    const v$ = useVuelidate(rules, user.value);

    return {
      closeModal,
      user,
      createUser,
      editUser,
      resetForm,
      userModalIsOpen,
      userEditionData,
      userIsEditing,
      modalTitle,
      t,
      v$,
      formIsNotValid,
      createLabel,
      editLabel,
      validateName,
      validateEmail,
      isVisible,
      showSuccess,
      showError,
      editFormIsNotValid,
      loading,
      errorMessages,
      isFailed,
      setSelectedRoles,
      setUnselectedRoles,
      setAllSelectedRoles,
      setAllUnselectedRoles,
      userOrganisation,
      userEmail,
    };
  },
};
</script>
<style lang="scss" scoped></style>
