<template>
  <div class="sidebar" data-cy="sidebar-navigation">
    <div v-if="!userDataIsLoaded" class="loader-wrapper">
      <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
    </div>
    <div v-else>
      <div v-for="(group, index) in visibleNavigation" :key="index" class="navigation-group">
        <sidebar-category :name="group.name" />
        <sidebar-item
          v-for="(item, index) in group.items"
          :key="index"
          :name="item.name"
          :path="item.path"
          class="my-2"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SidebarCategory from '@/components/common/layout/sidebar/SidebarCategory.vue';
import SidebarItem from '@/components/common/layout/sidebar/SidebarItem.vue';

import { computed, onMounted } from 'vue';
import { categories, type NavigationGroup } from '@/utils/menuCategories';
import { storeToRefs } from 'pinia';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { accessPermitted } from '@/utils/permissions';

const usersStore = useUsersStore();
const { me, userDataIsLoaded } = storeToRefs(usersStore);

const userFeatures = computed(() =>
  me.value.active_features.map((activeFeature) => activeFeature.id)
);
const userRoles = computed(() => me.value.roles.map((activeRole) => activeRole.id));

const visibleNavigation = computed<NavigationGroup[]>(() => {
  const groups: NavigationGroup[] = [];

  categories.forEach((category) => {
    const visibleItems = category.items.filter((item) =>
      accessPermitted(item.featureName, item.allowedRoles, userFeatures.value, userRoles.value)
    );

    if (visibleItems.length > 0) {
      groups.push({
        name: category.name,
        items: visibleItems,
      });
    }
  });

  return groups;
});

onMounted(async () => {
  await usersStore.fetchUserData();
});
</script>

<style lang="scss" scoped>
.sidebar {
  height: 100%;
  min-width: 230px;
  background-color: var(--gray-0);
  border-right: 1px solid var(--gray-200);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.navigation-group {
  &:not(:first-child) {
    margin-top: 32px;
  }
}
</style>
