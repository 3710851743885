<template>
  <div v-if="allTagsAdded" class="text-center my-8">
    <span data-cy="all-rfid-tags-added">{{ $t('whitelist.allRfidTagsAdded') }}</span>
  </div>
  <div v-else data-cy="rfid-tags-picker-table" class="picker-table mt-2 pt-4 px-12">
    <span class="block title font-medium mb-3">{{ $t('rfidTags') }}</span>
    <div class="container">
      <div v-if="loading" class="loader-wrapper loader-wrapper-sm">
        <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
      </div>
      <DataTable
        v-if="!loading"
        :value="tagList"
        dataKey="id"
        :scrollable="true"
        scrollHeight="280px"
        class="p-datatable-sm"
        v-model:filters="filters"
        filterDisplay="menu"
        :globalFilterFields="['rfid_tag', 'username']"
        v-model:selection="selectedRfidTags"
        @rowSelect="onSelectedRfidTags"
        @rowUnselect="onUnselectedRfidTags"
        @rowSelectAll="onSelectedAllRfidTags"
        @rowUnselectAll="onUnselectedAllRfidTags"
        :metaKeySelection="false"
      >
        <template #empty>{{ $t('rfidTag.empty') }}</template>
        <template #loading>{{ $t('rfidTag.loading') }}</template>
        <template #header>
          <div class="flex justify-between align-items-center">
            <span class="p-input-icon-left search">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('searchBy', { name: `${$t('rfidTags')}, ${$t('names')}` })"
                class="p-inputtext-sm"
              />
            </span>
          </div>
        </template>
        <Column
          selectionMode="multiple"
          style="flex: 0 0 0; padding: 16px"
          bodyStyle="padding-left: 16px"
        ></Column>
        <Column field="rfid_tag" :header="$t('rfidTag.title')">
          <template #body="{ data }">
            <div class="w-full pl-1">
              <span :id="data.id" class="text-sm font-medium" data-cy="rfid-tag">{{
                data.rfid_tag
              }}</span>
            </div>
          </template>
        </Column>
        <Column field="username" :header="$t('name')">
          <template #body="{ data }">
            <span v-if="data.username" data-cy="user-name" class="text-small">{{
              data.username
            }}</span>
            <span v-else class="empty">--</span>
          </template>
        </Column>
        <Column field="state" :header="$t('status')">
          <template #body="{ data }">
            <Tag
              data-cy="rfid-tag-status"
              class="mr-2"
              :severity="`${data.state ? 'success' : 'info'}`"
              :value="`${data.state ? 'Enabled' : 'Disabled'}`"
              rounded
            ></Tag>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, computed } from 'vue';
import type { PropType } from 'vue';
import { storeToRefs } from 'pinia';
import { FilterMatchMode } from 'primevue/api';
import { useRfidTagsStore } from '@/stores/admin/rfidTags/rfidTags.store';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import type { RfidTag } from '@/models/rfidTags.model';
import Tag from 'primevue/tag';

export default {
  name: 'RfidTagsPicker',
  components: {
    DataTable,
    Column,
    InputText,
    Tag,
  },
  props: {
    whitelistId: {
      type: Number as PropType<number | null>,
      required: false,
    },
  },

  setup(props, { emit }) {
    const rfidTagsStore = useRfidTagsStore();
    const { tags, filteredTags } = storeToRefs(rfidTagsStore);
    const loading = ref(true);
    const selectedRfidTags = ref<RfidTag[]>([]);
    const tagList = ref(props.whitelistId ? filteredTags : tags);
    const allTagsAdded = computed(
      () => props.whitelistId && filteredTags.value.length === 0 && !loading.value
    );

    const onSelectedRfidTags = (event: any) => {
      emit('onRfidTagsSelect', { id: event.data.id });
    };

    const onUnselectedRfidTags = (event: any) => {
      emit('onRfidTagsUnselect', event.data.id);
    };

    const onSelectedAllRfidTags = (event: any) => {
      const ids = event.data.map((item: any) => ({ id: item.id }));
      emit('onRfidTagsSelectAll', ids);
    };

    const onUnselectedAllRfidTags = () => {
      emit('onUnselectedAllRfidTags');
    };

    const matchModeOptions = ref([{ label: 'Starts With', value: FilterMatchMode.STARTS_WITH }]);

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    onMounted(async () => {
      if (props.whitelistId) {
        await rfidTagsStore
          .fetchRfidTagsForWhitelist(props.whitelistId)
          .then(() => (loading.value = false));
      } else {
        await rfidTagsStore.fetchRfidTagsList().then(() => (loading.value = false));
      }
    });

    return {
      tagList,
      filters,
      matchModeOptions,
      loading,
      selectedRfidTags,
      onSelectedRfidTags,
      onSelectedAllRfidTags,
      allTagsAdded,
      onUnselectedAllRfidTags,
      onUnselectedRfidTags,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.p-tag) {
  font-size: 12px;
  padding: 4px 10px;
}
</style>
