<template>
  <div class="input-wrapper">
    <InputNumber
      data-cy="number-input"
      class="p-inputtext-sm inline-input"
      :useGrouping="false"
      :min="0"
      :minFractionDigits="minFractionDigits"
      :maxFractionDigits="maxFractionDigits"
      :allowEmpty="false"
      locale="en-US"
      mode="decimal"
      v-model="inputValue"
      @keyup="updateValue"
      @input="restrictInput"
    />
    <span :class="`value-type ${!type ? 'epmty' : ''}`">{{ type ? type : '--' }}</span>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import InputNumber from 'primevue/inputnumber';

const emit = defineEmits(['update']);

const props = withDefaults(
  defineProps<{
    name: string;
    value: number;
    type: string | undefined;
    minFractionDigits?: number;
    maxFractionDigits?: number;
  }>(),
  {
    minFractionDigits: 2,
    maxFractionDigits: 2,
  }
);

const inputValue = ref(props.value);

const restrictInput = (event: any) => {
  if (event.value && event.value.toString().length > 15) {
    const restrictedValue = event.value.toString().slice(0, 15);
    inputValue.value = Number(restrictedValue);
  }
};

const updateValue = (event: any) => {
  inputValue.value = Number(event.target.value);
  emit('update', props.name, event.target.value);
};
</script>

<style scoped lang="scss">
.input-wrapper {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  border: 1px solid $grey-medium-light;
  border-radius: 4px;
  justify-content: space-between;
}
.inline-input {
  :deep(.p-inputtext) {
    padding: 6px 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    text-align: right;
    width: 100%;
  }
}

:deep(.p-inputnumber) {
  flex-grow: 1;
}
.value-type {
  padding: 5px 12px;
  background-color: $grey-light;
  border-left: 1px solid $grey-medium-light;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  white-space: nowrap;
  text-align: right;
}
</style>
