<template>
  <GeneralSummary
    :showName="false"
    :template="selectedTemplate"
    :createdBy="alertModalData.created_by_user_name"
  />
  <div class="px-12">
    <div class="field flex flex-col pt-4 pb-6">
      <label id="alertNameLabel" for="alertName" class="title font-medium text-gray-600">
        {{ $t('name') }}<span class="required">*</span>
      </label>
      <InputText
        data-cy="alert-configuration-name-input"
        id="alertName"
        type="text"
        aria-describedby="alertNameLabel"
        class="p-inputtext-sm"
        v-model="alertModalData.name"
      />
      <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>

    <div v-if="alertModalData.parameters.length">
      <span class="block title font-medium field-edit-label">
        {{ $t('alert.parameters') }}<span class="required">*</span>
      </span>
      <p class="block text-grey text-sm">{{ $t('alert.parametersDescription') }}</p>

      <div v-for="param in alertParameters" :key="param.id" class="py-2.5">
        <div class="text-sm flex justify-between">
          <label :for="param.id" class="title font-medium text-gray-500">
            {{ param.name }}
          </label>

          <generic-input
            :id="param.id"
            :type="param.type"
            :default-value="defaultValue(param.id)"
            :min="param.min"
            :max="param.max"
            :original-unit="param.originalUnit"
            :enum-values="param.enumValues"
            @update:value="(value, valid) => setValue(param.id, value, valid)"
            :desired-unit-system="param.desiredUnitSystem"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAlertsStore } from '@/stores/admin/alerts/alerts.store';
import { storeToRefs } from 'pinia';
import { onMounted, watch, reactive, ref } from 'vue';
import InputText from 'primevue/inputtext';
import GeneralSummary from '@/components/alertsAndReportsShared/summary/GeneralSummary.vue';
import GenericInput from '@/components/common/inputs/GenericInput.vue';

import type { Validation } from '@vuelidate/core';
import type { alertRules } from '@/utils/alertModalRules';
import {
  type ProductTypeSetting,
  settingIsEnumGeneric,
  settingIsNumberGeneric,
} from '@/models/productTypes.model';
import { useUsersStore } from '@/stores/admin/users/users.store';
import type { GenericSetting, UnitSystem } from '@/models/datatypes.model';

const alertsStore = useAlertsStore();
const { alertModalData, selectedTemplate } = storeToRefs(alertsStore);

const { me } = useUsersStore();

const props = defineProps<{
  v$: Validation<typeof alertRules>;
}>();

type ParameterType = {
  id: string;
  name: string;
  type: GenericSetting['type'] | ProductTypeSetting['type'];
  defaultValue: string | number | boolean | undefined;
  min?: number;
  max?: number;
  enumValues?: { value: string | number; label: string; selectable?: boolean }[];
  originalUnit?: string;
  desiredUnitSystem?: UnitSystem;
};

const alertParameters = ref<ParameterType[]>([]);

const parameterState = reactive(
  alertsStore.selectedTemplate?.parameters.reduce((acc, param) => {
    acc[param.id] = { value: param.defaultValue, valid: true };
    return acc;
  }, {} as Record<string, { value: number | string | boolean | undefined; valid: boolean }>) || {}
);

const defaultValue = (id: string) => {
  return alertModalData.value.parameters.find((item) => item.id == id)?.value;
};

function setValue(id: string, value: number | string | boolean | undefined, valid: boolean) {
  parameterState[id] = { value, valid };
  if (
    valid &&
    value !== undefined &&
    !!alertModalData.value.parameters.find((item) => item.id == id)
  ) {
    alertModalData.value.parameters.find((item) => item.id == id)!.value = value;
  }
  alertsStore.alertModalAllParamsValid = Object.values(parameterState).every(({ valid }) => valid);
}

watch(
  () => alertModalData.value.name,
  () => {
    props.v$.name.$touch();
  },
  { deep: true }
);

watch(
  () => alertsStore.selectedTemplate?.parameters,
  (parameters) => {
    if (parameters) {
      parameters.map((p) => {
        alertParameters.value.push({
          id: p.id,
          type: p.type,
          name: p.name,
          defaultValue: p.defaultValue,
          min: settingIsNumberGeneric(p) ? p.minValue : undefined,
          max: settingIsNumberGeneric(p) ? p.maxValue : undefined,
          enumValues: settingIsEnumGeneric(p) ? p.enumValues : undefined,
          originalUnit: settingIsNumberGeneric(p) ? p.unit : undefined,
          desiredUnitSystem: me.units,
        });
      });
    }
  },
  { immediate: true, deep: true }
);

onMounted(async () => {
  if (!alertsStore.alertTemplateList.length) {
    await alertsStore.fetchAlertTemplateGroups();
  }
});
</script>
