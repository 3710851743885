<template>
  <Dialog
    data-cy="whitelist-edit-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="field flex flex-col field-edit">
      <label for="name" class="mb-2 field-edit-label">{{ $t('name') }}</label>
      <InputText
        data-cy="whitelist-name-input"
        id="name"
        type="text"
        aria-describedby="name-help"
        class="p-inputtext-sm"
        v-model="whitelist.name"
        :placeholder="$t('name')"
        @keyup="validateName"
      />
      <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        v-if="!whitelistIsEditing"
        data-cy="create-whitelist-button"
        :label="createLabel"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        :disabled="formIsNotValid"
        @click="createWhitelist"
        :loading="loading"
      />
      <Button
        v-else
        data-cy="edit-whitelist-button"
        :label="editLabel"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        :disabled="editFormIsNotValid"
        @click="editWhitelist"
        :loading="loading"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="whitelist-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="whitelists"
  />
</template>

<script lang="ts">
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useWhitelistsStore } from '@/stores/admin/whitelists/whitelists.store';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, maxLength } from '@vuelidate/validators';
import { asciiCharacters } from '@/utils/validationRules';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { CREATE, DEFAULT_TOAST_LIFE_MILLISECONDS, EDIT, MAX_LENGTH } from '@/utils/constants';

export default {
  name: 'RfidTagEditModal',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    InputText,
    Toast,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const store = useWhitelistsStore();
    const isVisible = ref(false);
    const loading = ref(false);
    const { whitelistEditModalIsOpen, whitelistIsEditing, whitelistEditionData } =
      storeToRefs(store);
    const whitelist = ref({
      id: 0,
      name: '',
      number_of_rfidtags: 0,
      number_of_systems: 0,
    });

    const modalTitle = computed(() => {
      const title = whitelistIsEditing.value
        ? t('edit', { itemName: t('whitelist.title') })
        : t('create', { itemName: t('whitelist.title') });
      return title;
    });

    const createLabel = computed(() => {
      return v$.value.$error ? t('error') : t('create');
    });

    const editLabel = computed(() => {
      return v$.value.$error ? t('error') : t('apply');
    });

    const closeModal = () => {
      whitelistEditModalIsOpen.value = false;
      loading.value = false;

      resetForm();

      if (whitelistIsEditing.value) {
        whitelistIsEditing.value = false;
      }
    };

    const showSuccess = (actionName: string) => {
      toast.add({
        severity: 'success',
        summary: t('toast.success', {
          name: t('whitelist.title'),
          action: actionName === CREATE ? t('toast.created') : t('toast.edited'),
        }),
        life: DEFAULT_TOAST_LIFE_MILLISECONDS,
        group: 'whitelists',
      });
    };

    const showError = (actionName: string) => {
      toast.add({
        severity: 'error',
        summary: t('toast.error', {
          action: actionName === CREATE ? t('toast.creation') : t('toast.edition'),
          name: t('whitelist.title'),
        }),
        group: 'whitelists',
      });
    };

    const createWhitelist = async () => {
      await v$.value.$validate();

      if (v$.value.$error) return;

      const data = {
        name: whitelist.value.name,
      };

      loading.value = true;

      await store
        .createNewWhitelist(data)
        .then((response: any) => {
          loading.value = false;
          if (response.status === 201) {
            toast.removeGroup('whitelists');
            showSuccess(CREATE);
            closeModal();
          }
        })
        .catch((error) => {
          loading.value = false;
          toast.removeGroup('whitelists');
          showError(CREATE);
          throw new Error(error);
        });
    };

    const editWhitelist = async () => {
      await v$.value.$validate();

      if (v$.value.$error) return;

      const data = {
        id: whitelist.value.id,
        name: whitelist.value.name,
      };

      loading.value = true;

      await store
        .updateWhitelist(data)
        .then((response: any) => {
          loading.value = false;
          if (response.status === 200) {
            toast.removeGroup('whitelists');
            showSuccess(EDIT);
            closeModal();
          }
        })
        .catch((error) => {
          loading.value = false;
          toast.removeGroup('whitelists');
          showError(EDIT);
          throw new Error(error);
        });
    };

    watch(
      () => whitelistEditModalIsOpen.value,
      (value) => {
        if (value) {
          isVisible.value = true;
        } else {
          isVisible.value = false;
        }
      }
    );

    watch(
      () => store.whitelistEditionData,
      (value) => {
        if (value.name !== '') {
          whitelist.value.id = store.whitelistEditionData.id;
          whitelist.value.name = store.whitelistEditionData.name;
        }
      }
    );

    const validateName = async () => {
      await v$.value.name.$validate();
    };

    const resetForm = () => {
      whitelist.value.name = '';
      v$.value.$reset();
      store.resetWhitelistEditionData();
    };

    const formIsNotValid = computed(() => {
      return v$.value.$error || whitelist.value.name === '';
    });

    const editFormIsNotValid = computed(() => {
      return !v$.value.$anyDirty || (v$.value.name.$anyDirty && v$.value.name.$error)
        ? true
        : false;
    });

    const rules = computed(() => {
      return {
        name: {
          required: helpers.withMessage(
            t('rules.required', { field: t('name'), item: t('whitelist.title') }),
            required
          ),
          asciiCharacters: helpers.withMessage(
            t('rules.asciiCharacters', { field: t('whitelist.title') }),
            asciiCharacters
          ),
          maxLength: maxLength(MAX_LENGTH),
        },
      };
    });

    const v$ = useVuelidate(rules, whitelist.value);

    return {
      closeModal,
      whitelist,
      createWhitelist,
      editWhitelist,
      resetForm,
      whitelistEditModalIsOpen,
      whitelistEditionData,
      whitelistIsEditing,
      modalTitle,
      t,
      v$,
      formIsNotValid,
      createLabel,
      editLabel,
      validateName,
      isVisible,
      showSuccess,
      showError,
      editFormIsNotValid,
      loading,
    };
  },
};
</script>
<style lang="scss" scoped></style>
