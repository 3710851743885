<template>
  <div data-cy="create-edit-specila-rate-panel">
    <PanelWithEdit :editBtn="false">
      <template #customHeader>
        <div class="flex justify-between w-full items-center">
          <div class="field flex justify-between items-center">
            <label id="tariffNameLabel" for="tariffName" class="title font-medium text-gray-600">
              {{ $t('name') }}<span class="required">*</span>
            </label>
            <div class="ml-4">
              <InputText
                data-cy="tariff-special-rate-name-input"
                id="tariffName"
                type="text"
                aria-describedby="tariffNameLabel"
                class="p-inputtext-sm w-[300px] text-right tariff-name-input"
                v-model="rateName"
                @keyup="updateRateName"
              />
              <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </div>
            </div>
          </div>
          <div class="flex">
            <Button
              data-cy="tariff-special-rate-edit-cancel-button"
              class="p-button-xs p-button-footer p-button-white mx-auto block mr-3"
              :label="$t('cancel')"
              @click="closeCreateEditSpecialRate"
            />
            <Button
              data-cy="tariff-special-rate-apply-button"
              class="p-button-xs p-button-footer p-button-primary mx-auto block"
              :label="$t('apply')"
              :loading="loading"
              :disabled="!formIsValid"
              @click="onApply"
            />
          </div>
        </div>
      </template>
      <WarningBar
        v-if="isWarningVisible"
        :message="$t('tariff.rateConflictWarning', { name: conflictingRates })"
      />
      <div class="py-2">
        <div>
          <div class="field flex justify-between items-center">
            <label
              id="rateSelectedDays"
              for="rateSelectedDays"
              class="title font-medium text-gray-600"
            >
              {{ $t('tariff.weekdays') }}<span class="required">*</span>
            </label>

            <SelectButton
              class="w-1/2 my-2"
              v-model="selectedDays"
              :options="daysOfWeek"
              optionLabel="name"
              multiple
              aria-labelledby="multiple"
              @change="updateSelectedDays"
            />
          </div>
          <div class="flex">
            <div class="w-1/2"></div>
            <div class="input-errors w-1/2" v-for="(error, index) of v$.days.$errors" :key="index">
              <small class="p-error">{{ error.$message }}</small>
            </div>
          </div>
        </div>
        <div class="field flex justify-between items-center mt-1 mb-2">
          <label
            id="rateSelectedTimes"
            for="rateSelectedTimes"
            class="title font-medium text-gray-600"
          >
            {{ $t('tariff.specialRateTimes') }}
            <span class="ml-2 text-gray-300 pi pi-info-circle" v-tooltip.bottom="timesTooltip" />
          </label>
          <div class="flex items-start w-1/2">
            <div>
              <Calendar
                v-model="timeFrom"
                timeOnly
                :hourFormat="primeVueHourFormat"
                :stepMinute="5"
                :placeholder="$t('chargePark.timeFrom')"
                :manual-input="true"
                @update:modelValue="updateStartTime"
              />
              <div
                class="input-errors"
                v-for="(error, index) of v$.start_time.$errors"
                :key="index"
              >
                <small class="p-error">{{ error.$message }}</small>
              </div>
            </div>
            <i class="pi pi-arrow-right value-separator arrow-separator px-3 mt-3 self-start"></i>
            <div>
              <Calendar
                v-model="timeTo"
                timeOnly
                :hourFormat="primeVueHourFormat"
                :stepMinute="5"
                :placeholder="$t('chargePark.timeTo')"
                :manual-input="true"
                @update:modelValue="updateEndTime"
              />
              <div class="input-errors" v-for="(error, index) of v$.end_time.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="field flex justify-between items-center my-2">
          <label
            id="rateSelectedTimes"
            for="rateSelectedTimes"
            class="title font-medium text-gray-600"
          >
            {{ $t('tariff.startAndEndDate') }}
            <span class="ml-2 text-gray-300 pi pi-info-circle" v-tooltip.bottom="datesTooltip" />
          </label>
          <div class="flex items-center w-1/2">
            <Calendar
              data-cy="rate-start-date"
              v-model="currentRate.start_date"
              showIcon
              :class="[{ 'p-invalid': hasStartDateError }, 'w-1/2']"
              :dateFormat="primeVueDateFormat"
              :stepMinute="5"
              :manualInput="false"
              showButtonBar
              @update:modelValue="updateStartDate"
              @clearClick="clearStartDate"
            >
              <template #footer>
                <span v-if="hasStartDateError" class="pt-2 p-error text-xs block text-center">
                  {{ $t('tariff.errors.startDate') }}
                </span>
              </template>
            </Calendar>

            <i class="pi pi-arrow-right value-separator arrow-separator px-3"></i>

            <Calendar
              data-cy="rate-end-date"
              v-model="currentRate.end_date"
              showIcon
              :class="[{ 'p-invalid': hasEndDateError }, 'w-1/2']"
              :dateFormat="primeVueDateFormat"
              :stepMinute="5"
              :manualInput="false"
              :minDate="minEndDate"
              showButtonBar
              @update:modelValue="updateEndDate"
              @clearClick="clearEndDate"
            >
              <template #footer>
                <span v-if="hasEndDateError" class="pt-2 p-error text-xs block text-center">
                  {{ $t('tariff.errors.endDate') }}
                </span>
              </template>
            </Calendar>
          </div>
        </div>
        <TariffRateInputs
          :rate="rate"
          :type="2"
          @updateRateInputs="updateSpecialRateInputs"
          v-bind="{ v$ }"
        />
      </div>
    </PanelWithEdit>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onUnmounted, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { checkConflicts } from '@/utils/rates';
import { helpers } from '@vuelidate/validators';
import {
  minEndTimeRule,
  minStartTimeRule,
  minStartDateRule,
  minEndDateRule,
} from '@/utils/validationRules';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { isDate } from 'lodash';
import { TWO_DIGITS, THREE_DIGITS } from '@/utils/constants';
import { normalizeData } from '@/utils/rates';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import TariffRateInputs from './TariffRateInputs.vue';
import SelectButton from 'primevue/selectbutton';
import Calendar from 'primevue/calendar';
import WarningBar from '@/components/common/bar/WarningBar.vue';

import { rateRules } from '@/utils/tariffModalRules';
import type { IdName } from '@/models/common.model';
import type { SpecialRate, WeekdayString } from '@/models/tariffs.model';
import { isSpecialRate } from '@/models/tariffs.model';

import { primeVueDateFormat, primeVueHourFormat, dayJsTimeFormat } from '@/utils/dateTimeFormatVue';

const props = defineProps<{
  rate?: SpecialRate;
  currency?: string | number;
}>();

const { t } = useI18n();
const tariffsStore = useTariffsStore();
const {
  tariffSpecialRateIsEditing,
  tariffCreateSpecialRateIsOpen,
  tariffModalData,
  rateData,
  rateEditId,
} = storeToRefs(tariffsStore);
const chargeParksStore = useChargeParksStore();
const { daysOfWeek } = storeToRefs(chargeParksStore);
const loading = ref(false);
const rateName = ref<string | undefined>(props.rate?.name);
const selectedDays = ref();
const timeFrom = ref();
const timeTo = ref();
const hasStartDateError = ref(false);
const hasEndDateError = ref(false);

const currentRate = ref<SpecialRate>({
  name: '',
  type: 2,
  days: [],
  start_time: null,
  end_time: null,
  start_date:
    props.rate?.start_date && props.rate?.start_date !== ''
      ? dayjs(props.rate?.start_date).toDate()
      : null,
  end_date:
    props.rate?.end_date && props.rate?.end_date !== ''
      ? dayjs(props.rate?.end_date).toDate()
      : null,
  session_fee_cent: props.rate?.session_fee_cent ? props.rate.session_fee_cent : 0,
  session_fee_tax_percent: props.rate?.session_fee_tax_percent
    ? props.rate.session_fee_tax_percent
    : 0,
  price_per_kwh_tenth_cent: props.rate?.price_per_kwh_tenth_cent
    ? props.rate.price_per_kwh_tenth_cent
    : 0,
  price_per_kwh_tax_percent: props.rate?.price_per_kwh_tax_percent
    ? props.rate.price_per_kwh_tax_percent
    : 0,
  idle_fee_tenth_cent_per_min: props.rate?.idle_fee_tenth_cent_per_min
    ? props.rate.idle_fee_tenth_cent_per_min
    : 0,
  idle_fee_tax_percent: props.rate?.idle_fee_tax_percent ? props.rate.idle_fee_tax_percent : 0,
  idle_fee_grace_period_min: props.rate?.idle_fee_grace_period_min
    ? props.rate.idle_fee_grace_period_min
    : 0,
  charging_duration_fee_tenth_cent: props.rate?.charging_duration_fee_tenth_cent
    ? props.rate.charging_duration_fee_tenth_cent
    : 0,
  charging_duration_fee_tax_percent: props.rate?.charging_duration_fee_tax_percent
    ? props.rate.charging_duration_fee_tax_percent
    : 0,
  fixed_tax_session_cent: props.rate?.fixed_tax_session_cent
    ? props.rate.fixed_tax_session_cent
    : 0,
  fixed_tax_parking_cent: props.rate?.fixed_tax_parking_cent
    ? props.rate.fixed_tax_parking_cent
    : 0,
  pre_authorization_amount_cent: props.rate?.pre_authorization_amount_cent
    ? props.rate.pre_authorization_amount_cent
    : 0,
});

const minEndDate = ref();
const isWarningVisible = ref(false);
const conflictingRates = ref();

const updateRateName = (event: any) => {
  currentRate.value.name = event.target.value;
  v$.value.name.$touch();
};

const updateSelectedDays = (data: any) => {
  currentRate.value.days = data.value.map((day: IdName) => day.name.toUpperCase());
  v$.value.days.$touch();
};

const updateStartTime = (date: string | string[] | Date | Date[] | undefined) => {
  if (Array.isArray(date)) return;
  currentRate.value.start_time = date ? dayjs(date).format('HH:mm') : date ?? null;
  v$.value.start_time.$touch();
};

const updateEndTime = (date: string | string[] | Date | Date[] | undefined) => {
  if (Array.isArray(date)) return;
  currentRate.value.end_time = date ? dayjs(date).format('HH:mm') : date ?? null;
  v$.value.end_time.$touch();
};

const updateStartDate = (date: string | string[] | Date | Date[] | undefined) => {
  if (Array.isArray(date)) return;
  currentRate.value.start_date = date ? dayjs(date).toDate() : null;
  v$.value.start_date.$touch();
};

const updateEndDate = (date: string | string[] | Date | Date[] | undefined) => {
  if (Array.isArray(date)) return;
  currentRate.value.end_date = date ? dayjs(date).toDate() : null;
  v$.value.end_date.$touch();
};

const clearStartDate = () => {
  currentRate.value.start_date = null;

  hasStartDateError.value = false;
  hasEndDateError.value = false;

  v$.value.start_date.$touch();
};

const clearEndDate = () => {
  currentRate.value.end_date = null;

  hasStartDateError.value = false;
  hasEndDateError.value = false;

  v$.value.end_date.$touch();
};

const updateSpecialRateInputs = (data: SpecialRate) => {
  currentRate.value.session_fee_cent = normalizeData(data.session_fee_cent, TWO_DIGITS);
  currentRate.value.session_fee_tax_percent = normalizeData(
    data.session_fee_tax_percent,
    TWO_DIGITS
  );
  currentRate.value.price_per_kwh_tenth_cent = normalizeData(
    data.price_per_kwh_tenth_cent,
    THREE_DIGITS
  );
  currentRate.value.price_per_kwh_tax_percent = normalizeData(
    data.price_per_kwh_tax_percent,
    TWO_DIGITS
  );
  currentRate.value.idle_fee_tenth_cent_per_min = normalizeData(
    data.idle_fee_tenth_cent_per_min,
    THREE_DIGITS
  );
  currentRate.value.idle_fee_tax_percent = normalizeData(data.idle_fee_tax_percent, TWO_DIGITS);
  currentRate.value.idle_fee_grace_period_min = data.idle_fee_grace_period_min;
  currentRate.value.charging_duration_fee_tenth_cent = normalizeData(
    data.charging_duration_fee_tenth_cent,
    THREE_DIGITS
  );
  currentRate.value.charging_duration_fee_tax_percent = normalizeData(
    data.charging_duration_fee_tax_percent,
    TWO_DIGITS
  );
  currentRate.value.fixed_tax_session_cent = normalizeData(data.fixed_tax_session_cent, TWO_DIGITS);
  currentRate.value.fixed_tax_parking_cent = normalizeData(data.fixed_tax_parking_cent, TWO_DIGITS);
  currentRate.value.pre_authorization_amount_cent = normalizeData(
    data.pre_authorization_amount_cent,
    TWO_DIGITS
  );
};

const closeCreateEditSpecialRate = () => {
  tariffCreateSpecialRateIsOpen.value = false;

  if (tariffSpecialRateIsEditing.value) {
    tariffSpecialRateIsEditing.value = false;
  }
  rateEditId.value = null;
  tariffsStore.resetRateDate();
  v$.value.start_time.$reset();
  v$.value.end_time.$reset();
  v$.value.start_date.$reset();
  v$.value.end_date.$reset();
  v$.value.$reset();
};

const timesTooltip = computed(() => t('tariff.specialRateTimesDescription'));
const datesTooltip = computed(() => t('tariff.startAndEndDateDescription'));

const formIsValid = computed(() => {
  if (tariffSpecialRateIsEditing.value) {
    return (
      v$.value.$anyDirty &&
      (!v$.value.days.$dirty || (v$.value.days.$dirty && !v$.value.days.$invalid)) &&
      (!v$.value.name.$dirty || (v$.value.name.$dirty && !v$.value.name.$invalid)) &&
      (!v$.value.start_time.$dirty ||
        (v$.value.start_time.$dirty && !v$.value.start_time.$invalid)) &&
      (!v$.value.end_time.$dirty || (v$.value.end_time.$dirty && !v$.value.end_time.$invalid)) &&
      !hasStartDateError.value &&
      !hasEndDateError.value
    );
  } else {
    return (
      v$.value.name.$dirty &&
      !v$.value.name.$invalid &&
      v$.value.days.$dirty &&
      (!v$.value.start_time.$dirty ||
        (v$.value.start_time.$dirty && !v$.value.start_time.$invalid)) &&
      (!v$.value.end_time.$dirty || (v$.value.end_time.$dirty && !v$.value.end_time.$invalid)) &&
      !hasStartDateError.value &&
      !hasEndDateError.value
    );
  }
});

const createSpecialRate = () => {
  const { start_date, end_date, ...rest } = currentRate.value;
  tariffModalData.value.rates.push({
    start_date: start_date ? dayjs(start_date).format('YYYY-MM-DD') : null,
    end_date: end_date ? dayjs(end_date).format('YYYY-MM-DD') : null,
    ...rest,
  });
};

const editSpecialRate = () => {
  const specialRates = tariffModalData.value.rates.filter(isSpecialRate);

  if (rateEditId.value !== null) {
    const rateToUpdate = specialRates[rateEditId.value];

    rateToUpdate.name = currentRate.value.name;
    rateToUpdate.type = currentRate.value.type;
    rateToUpdate.days = currentRate.value.days;
    rateToUpdate.start_time = currentRate.value.start_time;
    rateToUpdate.end_time = currentRate.value.end_time;
    rateToUpdate.start_date = currentRate.value.start_date
      ? dayjs(currentRate.value.start_date).format('YYYY-MM-DD')
      : null;
    rateToUpdate.end_date = currentRate.value.end_date
      ? dayjs(currentRate.value.end_date).format('YYYY-MM-DD')
      : null;
    rateToUpdate.session_fee_cent = currentRate.value.session_fee_cent;
    rateToUpdate.session_fee_tax_percent = currentRate.value.session_fee_tax_percent;
    rateToUpdate.price_per_kwh_tenth_cent = currentRate.value.price_per_kwh_tenth_cent;
    rateToUpdate.price_per_kwh_tax_percent = currentRate.value.price_per_kwh_tax_percent;
    rateToUpdate.idle_fee_tenth_cent_per_min = currentRate.value.idle_fee_tenth_cent_per_min;
    rateToUpdate.idle_fee_tax_percent = currentRate.value.idle_fee_tax_percent;
    rateToUpdate.idle_fee_grace_period_min = currentRate.value.idle_fee_grace_period_min;
    rateToUpdate.charging_duration_fee_tenth_cent =
      currentRate.value.charging_duration_fee_tenth_cent;
    rateToUpdate.charging_duration_fee_tax_percent =
      currentRate.value.charging_duration_fee_tax_percent;
    rateToUpdate.fixed_tax_session_cent = currentRate.value.fixed_tax_session_cent;
    rateToUpdate.fixed_tax_parking_cent = currentRate.value.fixed_tax_parking_cent;
    rateToUpdate.pre_authorization_amount_cent = currentRate.value.pre_authorization_amount_cent;
  }
};

const onApply = async () => {
  await v$.value.$validate();

  if (v$.value.$error) return;

  let allSpecialTariffs = tariffModalData.value.rates.filter(isSpecialRate);
  if (tariffSpecialRateIsEditing.value) {
    allSpecialTariffs = allSpecialTariffs.filter((rate, index) => index !== rateEditId.value);
  }

  allSpecialTariffs.unshift(currentRate.value);

  const conflicts = checkConflicts(allSpecialTariffs);

  if (conflicts.length > 0) {
    isWarningVisible.value = true;
    conflictingRates.value = conflicts.map(([rate1, rate2]) => `${rate1} & ${rate2}`).join(', ');
    return;
  } else {
    isWarningVisible.value = false;
    conflictingRates.value = null;

    if (!tariffSpecialRateIsEditing.value && !rateEditId.value) {
      createSpecialRate();
    }

    if (tariffSpecialRateIsEditing.value) {
      editSpecialRate();
    }

    closeCreateEditSpecialRate();
  }
};

watch(
  [() => currentRate.value.start_date, () => currentRate.value.end_date],
  ([newStartDate, newEndDate]) => {
    hasStartDateError.value = newStartDate && newEndDate ? newStartDate > newEndDate : false;
    hasEndDateError.value = newStartDate && newEndDate ? newEndDate < newStartDate : false;

    v$.value.start_date.$touch();
    v$.value.end_date.$touch();

    if (newStartDate || newStartDate !== '') {
      minEndDate.value = dayjs(newStartDate).toDate();
    }
  }
);

watch(
  () => rateData.value.days,
  (rateDays) => {
    if (rateDays) {
      selectedDays.value = daysOfWeek.value.filter((item) => {
        const day = item.name.toUpperCase();
        return rateDays.includes(day as never);
      });
    }
  }
);

const convertTimeFormatToDate = (time: string | null | Date) => {
  if (!time) return;

  const normalisedTime = isDate(time) ? dayjs(time).format(dayJsTimeFormat.value) : time;

  let hour: number = Number(normalisedTime.slice(0, 2));
  let minutes: number = 0;
  let seconds: number = Number('00');

  const timeHasSeconds = normalisedTime.length === 8;
  const timeHasNoSeconds = normalisedTime.length === 5;

  if (timeHasSeconds) {
    minutes = Number(normalisedTime.slice(3, -3));
  }

  if (timeHasNoSeconds) {
    minutes = Number(normalisedTime.slice(3));
  }

  return dayjs().set('hour', hour).set('minute', minutes).set('second', seconds).toDate();
};

watch(
  () => props.rate,
  (rate) => {
    if (rate) {
      currentRate.value.name = rate.name ? rate.name : '';
      const days = daysOfWeek.value.filter((item) =>
        rate.days.includes(item.name.toUpperCase() as never)
      );

      selectedDays.value = days;
      currentRate.value.days = days.map((day) => day.name.toUpperCase() as WeekdayString);

      timeFrom.value = convertTimeFormatToDate(rate.start_time);
      timeTo.value = convertTimeFormatToDate(rate.end_time);

      currentRate.value.start_time = timeFrom.value;
      currentRate.value.end_time = timeTo.value;

      rateData.value = rate;
    }
  },
  { immediate: true }
);

const specialRateData = computed(() => {
  return {
    name: currentRate.value.name,
    days: currentRate.value.days,
    start_time: currentRate.value.start_time,
    end_time: currentRate.value.end_time,
    start_date: currentRate.value.start_date,
    end_date: currentRate.value.end_date,
    session_fee_cent: currentRate.value.session_fee_cent,
    session_fee_tax_percent: currentRate.value.session_fee_tax_percent,
    price_per_kwh_tenth_cent: currentRate.value.price_per_kwh_tenth_cent,
    price_per_kwh_tax_percent: currentRate.value.price_per_kwh_tax_percent,
    idle_fee_tenth_cent_per_min: currentRate.value.idle_fee_tenth_cent_per_min,
    idle_fee_tax_percent: currentRate.value.idle_fee_tax_percent,
    idle_fee_grace_period_min: currentRate.value.idle_fee_grace_period_min,
    charging_duration_fee_tenth_cent: currentRate.value.charging_duration_fee_tenth_cent,
    charging_duration_fee_tax_percent: currentRate.value.charging_duration_fee_tax_percent,
    fixed_tax_session_cent: currentRate.value.fixed_tax_session_cent,
    fixed_tax_parking_cent: currentRate.value.fixed_tax_parking_cent,
    pre_authorization_amount_cent: currentRate.value.pre_authorization_amount_cent,
  };
});

const specialRateRules = computed(() => {
  return {
    start_time: {
      minStartTimeRule: helpers.withMessage(t('rules.startTime'), (value: Date | string) =>
        minStartTimeRule(
          value && isDate(value) ? dayjs(value).format('HH:mm') : value,
          timeTo.value && isDate(timeTo.value) ? dayjs(timeTo.value).format('HH:mm') : timeTo.value
        )
      ),
    },
    end_time: {
      minEndTimeRule: helpers.withMessage(t('rules.endTime'), (value: Date | string) =>
        minEndTimeRule(
          value && isDate(value) ? dayjs(value).format('HH:mm') : value,
          timeFrom.value && isDate(timeFrom.value)
            ? dayjs(timeFrom.value).format('HH:mm')
            : timeFrom.value
        )
      ),
    },
    start_date: {
      minStartDateRule: minStartDateRule(currentRate.value.start_date, currentRate.value.end_date),
    },
    end_date: {
      minEndDateRule: minEndDateRule(currentRate.value.start_date, currentRate.value.end_date),
    },
    ...rateRules,
  };
});

const v$ = useVuelidate(specialRateRules, specialRateData);

onMounted(() => v$.value.$reset());

onUnmounted(() => {
  tariffSpecialRateIsEditing.value = false;
  rateEditId.value = null;
  v$.value.$reset();
});
</script>
<style scoped lang="scss">
:deep(.p-panel-header) {
  background-color: #fff;
}

:deep(.p-panel) {
  &.no-buttons {
    .p-panel-header {
      padding: 10px 16px;
    }
  }
}

:deep(.p-button) {
  &.p-button-sm {
    padding: 6px 14px;
  }

  &.p-button-icon-only {
    width: auto;
  }
}

:deep(.p-inputtext) {
  &.tariff-name-input {
    padding: 8px 12px;
  }
}

:deep(.p-selectbutton .p-button) {
  padding: 8px;
  min-width: 63px !important;
}

:deep(.p-calendar) {
  display: flex;
  flex-grow: 1;

  .p-inputtext {
    padding: 6px;
  }
}

:deep(.p-button) {
  &.p-button-icon-only {
    padding: 4px 8px;
  }
}

:deep(.p-calendar-w-btn) {
  border-color: $grey-medium-light;
}
</style>
