<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="media-library-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}</Message
  >
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="media-library-table"
    :value="mediaList"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    filterDisplay="menu"
    :globalFilterFields="['file_name', 'file_type', 'file_extension']"
    class="p-datatable-sm mb-5 mt-6 border-rounded overflow-hidden media-library-table"
    dataKey="id"
    @update:filters="fetchMediaWithCurrentFilters"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="
              $t('searchBy', {
                name: `${$t('name')}, ${$t('type')}, ${$t('mediaLibrary.fileExtension')}`,
              })
            "
            class="p-inputtext-sm"
          />
        </span>
        <div v-if="activeRoles" class="flex justify-end items-center p-4">
          <Button
            data-cy="open-create-media-library-modal"
            :label="$t('mediaLibrary.uploadFile')"
            icon="pi pi-cloud-upload"
            class="p-button-sm p-button-primary"
            @click="openUploadMediaFileModal"
          />
        </div>
      </div>
    </template>
    <template #empty>
      <span data-cy="media-library-table-empty" class="w-full block text-center">{{
        $t('mediaLibrary.empty')
      }}</span>
    </template>
    <Column
      class="no-top-border-row"
      field="thumbnail"
      :header="$t('mediaLibrary.thumbnail')"
      style="min-width: 4rem"
    >
      <template #body="{ data }">
        <MediaThumbnail :media="data" width-class="w-24" height-class="h-16" />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="file_name"
      :header="$t('name')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 12rem"
    >
      <template #body="{ data }">
        <span data-cy="media-library-file-name" :id="data.id">{{ data.file_name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="file_type"
      :header="$t('type')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 2rem"
    >
      <template #body="{ data }">
        <Tag
          data-cy="media-library-file-type"
          class="mr-2"
          severity="info"
          :value="data.file_type"
          rounded
        ></Tag>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('type') })"
        />
      </template>
    </Column>
    <!-- <Column
      class="no-top-border-row"
      field="metadata"
      :header="$t('mediaLibrary.metadata')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 9rem"
    >
      <template #body="{ data }">
        <div v-for="(value, key) in data.metadata" :key="key">
          <span data-cy="media-library-metadata">{{ value }}</span>
        </div>
      </template>
    </Column> -->
    <Column
      class="no-top-border-row"
      field="file_size"
      :header="$t('mediaLibrary.fileSize')"
      :sortable="true"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <span data-cy="media-library-file-size">{{ fileSizeToHumanReadable(data.file_size) }}</span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="status"
      :header="$t('mediaLibrary.status')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 6rem"
    >
      <template #body="{ data }">
        <Tag
          data-cy="media-library-status"
          class="mr-2"
          :severity="mediaStatusSeverity(data.status)"
          :value="data.status"
          rounded
          >{{ mediaStore.getMediaStatusByID(Number(data.status))?.name }}</Tag
        >
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          class="p-column-filter"
          :options="mediaStore.getMediaStateOptions()"
          :placeholder="$t('filterBy', { name: $t('mediaLibrary.fileExtension') })"
          option-label="label"
          option-value="value"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="playlists"
      :header="$t('mediaLibrary.usage')"
      :sortable="false"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <span v-if="data.playlists_count > 0" data-cy="media-library-playlists">
          <i
            class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 mr-2"
            v-tooltip.right="{
              value: `${data.playlists.map((playlist: IdName) => `<div class='flex flex-nowrap w-full'><span class='pr-2'>${playlist.name}</span></div>`).join('')}`,
              escape: true,
              class: 'custom-tooltip media-tooltip',
            }"
          >
          </i>
          {{
            data.playlists_count === 1
              ? `${data.playlists_count} ${$t('mediaLibrary.playlist')}`
              : `${data.playlists_count} ${$t('mediaLibrary.playlists')}`
          }}
        </span>
        <span v-else data-cy="media-library-playlists-zero">
          <i class="pi pi-exclamation-triangle text-amber-500 text-lg ml-1 mr-2"></i>
          <span class="text-amber-500">0 {{ $t('mediaLibrary.playlists') }}</span>
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="created_at"
      :header="$t('createdAt')"
      :sortable="true"
      style="min-width: 8rem"
    >
      <template #body="{ data }">
        <date-time-display :date="(data as Media).created_at" data-cy="media-library-created-at" />
      </template>
    </Column>
    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }">
        <div class="flex">
          <Button
            v-if="data.file_url"
            data-cy="update-link-to-file"
            :label="$t('download')"
            icon="pi pi-download"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openFileUrl(data.file_url)"
          />
          <Button
            v-if="activeRoles"
            data-cy="open-delete-media-library-modal"
            :label="$t('delete')"
            icon="pi pi-trash"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openDeleteMediaModal(data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>

  <Toast
    data-cy="media-library-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="mediaLibrary"
  />
  <MediaLibraryDeleteModal />
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useMediaStore } from '@/stores/admin/media/media.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { ADMINISTRATOR_ROLE, CONTENT_ADMIN_ROLE } from '@/utils/constants';
import { fileSizeToHumanReadable } from '@/utils/textFormatting';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Tag from 'primevue/tag';
import Toast from 'primevue/toast';

import MediaLibraryDeleteModal from '@/components/mediaLibrary/MediaLibraryDeleteModal.vue';
import MediaThumbnail from '@/components/common/thumbnail/MediaThumbnail.vue';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { IdName } from '@/models/common.model';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import type { Media } from '@/models/media.model';
import Dropdown from 'primevue/dropdown';

const mediaStore = useMediaStore();
const { mediaList, isMediaUploadModalOpen, mediaDeleteModalIsOpen, mediaDeleteData } =
  storeToRefs(mediaStore);
const userStore = useUsersStore();
const loading = ref(true);
const notAuthorized = ref(false);

const activeRoles = computed(
  () =>
    userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
    userStore.me.roles.map((role) => role.id).includes(CONTENT_ADMIN_ROLE)
);
 

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  file_name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  file_type: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  status: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  created_at: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
});

const matchModes = ref([
  { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
  { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
  { label: 'Contains', value: FilterMatchMode.CONTAINS },
]);

const openUploadMediaFileModal = () => {
  isMediaUploadModalOpen.value = true;
};

const openDeleteMediaModal = (id: number) => {
  mediaDeleteModalIsOpen.value = true;
  mediaDeleteData.value.id = id;
};

const openFileUrl = async (url: string) => {
  await mediaStore.fetchMediaFileFullPath(url).then((response) => {
    window.open(response.data.download_url, '_blank');
  });
};
const mediaStatusSeverity = (id: number) => {
  const ensuredNumber = Number(id);
  const mediaStatus = mediaStore.getMediaStatusByID(ensuredNumber);
  switch (mediaStatus?.name) {
    case 'Processed':
      return 'success';
    case 'Uploaded':
      return 'info';
    case 'Error':
      return 'danger';
    default:
      return 'warning';
  }
};

const fetchMediaWithCurrentFilters = () => {
  const statusConstrains = filters.value.status.constraints;
  const filteredStatuses = statusConstrains
    .map((item) => (item.matchMode === FilterMatchMode.CONTAINS ? item.value : null))
    .filter((item) => !!item);
  console.log('fetchMediaWithCurrentFilters', filteredStatuses);

  return mediaStore.fetchMediaList(filteredStatuses as unknown as number[]).then();
};

const handleErrors = (error: any) => {
  loading.value = false;
  if (error.response.status === 403) {
    notAuthorized.value = true;
  } else {
    throw new Error('Media library list failed to be fetched');
  }
};

onMounted(async () => {
  Promise.all([fetchMediaWithCurrentFilters(), mediaStore.fetchMediaStatus()])
    .then(() => (loading.value = false))
    .catch(handleErrors);
});
</script>

<style lang="scss" scoped>
.thumbnail {
  width: 98px;
  height: 74px;
  border-radius: 6px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
