<template>
  <GeneralSummary
    show-name
    :name="alertModalData.name"
    :template="selectedTemplate"
    :createdBy="alertModalData.created_by_user_name"
  />
  <div class="px-12">
    <span class="block title font-medium field-edit-label pt-4">{{ $t('contacts') }}</span>
    <p class="block text-grey text-sm">{{ $t('contact.pickerInfo') }}</p>
    <ContactsPicker
      selectMode="multiple"
      :value="alertsStore.alertModalData.contacts"
      @onContactSelect="onContactSelected"
      @onContactUnselect="onContactUnselected"
      @onContactSelectAll="onContactSelectedAll"
      @onContactUnselectAll="onContactUnselectedAll"
    />
    <span class="block title font-medium field-edit-label pt-3">
      {{ $t('alert.delivery') }}<span class="required">*</span>
    </span>
    <p class="block text-grey text-sm">{{ $t('alert.deliveryInfo') }}</p>
    <div class="flex justify-between">
      <span class="block title font-medium text-sm text-gray-500 pt-3">{{
        $t('alert.maxRate')
      }}</span>
      <Dropdown
        v-model="selectedMaxRate"
        :options="maxRate"
        optionLabel="name"
        :placeholder="$t('select', { name: $t('alert.maxRate') })"
        class="max-rate mb-2"
        :showClear="false"
      >
      </Dropdown>
    </div>
    <div class="flex justify-between items-center mt-2 mb-4">
      <span class="block title font-medium text-sm text-gray-500">{{
        $t('alert.sendOnWeekends')
      }}</span>
      <Checkbox v-model="alertModalData.send_on_weekends" :binary="true" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import { maxRate } from '@/utils/maxRate';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useAlertsStore } from '@/stores/admin/alerts/alerts.store';

import GeneralSummary from '@/components/alertsAndReportsShared/summary/GeneralSummary.vue';
import ContactsPicker from '@/components/contacts/ContactsPicker.vue';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';

import type { MaxRate } from '@/utils/maxRate';

const { t } = useI18n();
const alertsStore = useAlertsStore();
const { alertModalData, selectedTemplate } = storeToRefs(alertsStore);

const selectedMaxRate = ref<MaxRate>({
  name: `${alertModalData.value.max_rate_per_day} ${t('alert.perDay')}`,
  value: alertModalData.value.max_rate_per_day,
});

const onContactSelected = (id: number) => {
  alertModalData.value.contacts.push(id);
};

const onContactUnselected = (id: number) => {
  const contactPos = alertModalData.value.contacts.findIndex((itemId) => itemId === id);
  alertModalData.value.contacts.splice(contactPos, 1);
};

const onContactSelectedAll = (ids: number[]) => {
  alertModalData.value.contacts = ids;
};

const onContactUnselectedAll = () => {
  alertModalData.value.contacts = [];
};

watch(
  () => selectedMaxRate.value,
  (maxRate) => {
    alertModalData.value.max_rate_per_day = maxRate.value;
  },
  { deep: true }
);
</script>

<style scoped lang="scss">
:deep(.max-rate .p-inputtext) {
  font-size: 14px;
  padding: 6px 16px;
}
</style>
