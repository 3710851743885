import type { BrandingTemplate } from './branding.types';

export const directPowerBrandingTemplate: BrandingTemplate = {
  template_version: 1,
  settings: [
    {
      id: 'contactinfo',
      label: 'Displayed Contact Information',
      settings: [
        { id: 'operatorName', type: 'string', label: 'Operator Name', defaultValue: '' },
        { id: 'phoneNumber', type: 'string', label: 'Phone Number', defaultValue: '' },
        { id: 'emailAddress', type: 'email', label: 'Email Address', defaultValue: '' },
        { id: 'website', type: 'url', label: 'Website', defaultValue: '' },
        { id: 'address', type: 'string', label: 'Address', defaultValue: '' },
        {
          id: 'cpoQRCodeDownloadImageFileId',
          type: 'image',
          label: 'CPO QR Code Download Image',
          defaultValue: 23,
        },
      ],
    },
    {
      id: 'operation',
      label: 'Operation',
      settings: [
        {
          id: 'screenSaverTimoutSeconds',
          type: 'number',
          label: 'Screen Saver Timeout (Seconds)',
          defaultValue: 60,
          min: 0,
        },
        {
          id: 'screenSaverFileId',
          type: 'image',
          label: 'Screen Saver Image',
          defaultValue: 12,
        },
        {
          id: 'publicStopChargeButtonsDisplayed',
          type: 'boolean',
          label: 'Public Stop Charge Buttons Displayed',
          defaultValue: true,
        },
      ],
    },
    {
      id: 'colorsAndBackgrounds',
      label: 'Colors & Backgrounds',
      settings: [
        {
          id: 'background',
          type: 'color',
          label: 'Background',
          defaultValue: '#01BA46',
        },
        {
          id: 'colorPrimary',
          type: 'color',
          label: 'Primary Color',
          defaultValue: '#000000',
        },
        {
          id: 'colorSecondary',
          type: 'color',
          label: 'Secondary Color',
          defaultValue: '#FFFFFF',
        },
        {
          id: 'colorAccent',
          type: 'color',
          label: 'Accent Color',
          defaultValue: '#009B4A',
        },
        {
          id: 'buttonGradientStart',
          type: 'color',
          label: 'Button Gradient Start',
          defaultValue: '#000000',
        },
        {
          id: 'buttonGradientEnd',
          type: 'color',
          label: 'Button Gradient End',
          defaultValue: '#000000',
        },
        {
          id: 'readyGradientStart',
          type: 'color',
          label: 'Ready Gradient Start',
          defaultValue: '#009B4A',
        },
        {
          id: 'readyGradientEnd',
          type: 'color',
          label: 'Ready Gradient End',
          defaultValue: '#00CB81',
        },
        {
          id: 'chargingGradientStart',
          type: 'color',
          label: 'Charging Gradient Start',
          defaultValue: '#00A5A6',
        },
        {
          id: 'chargingGradientEnd',
          type: 'color',
          label: 'Charging Gradient End',
          defaultValue: '#14D5C7',
        },
        {
          id: 'graceFinishedGradientStart',
          type: 'color',
          label: 'Grace Finished Gradient Start',
          defaultValue: '#EAA51B',
        },
        {
          id: 'graceFinishedGradientEnd',
          type: 'color',
          label: 'Grace Finished Gradient End',
          defaultValue: '#D08E14',
        },
        {
          id: 'idleTimeGradientStart',
          type: 'color',
          label: 'Idle Time Gradient Start',
          defaultValue: '#E16049',
        },
        {
          id: 'idleTimeGradientEnd',
          type: 'color',
          label: 'Idle Time Gradient End',
          defaultValue: '#B6321A',
        },
        {
          id: 'reservedGradientStart',
          type: 'color',
          label: 'Reserved Gradient Start',
          defaultValue: '#EAA51B',
        },
        {
          id: 'reservedGradientEnd',
          type: 'color',
          label: 'Reserved Gradient End',
          defaultValue: '#EAA51B',
        },
        {
          id: 'outOfServiceGradientStart',
          type: 'color',
          label: 'Out of Service Gradient Start',
          defaultValue: '#A82E18',
        },
        {
          id: 'outOfServiceGradientEnd',
          type: 'color',
          label: 'Out of Service Gradient End',
          defaultValue: '#B6321A',
        },
        {
          id: 'line',
          type: 'color',
          label: 'Border Color',
          defaultValue: '#CFD9D3',
        },
      ],
    },
    {
      id: 'logo',
      label: 'Logo',
      settings: [
        {
          id: 'logoType',
          type: 'single-enum',
          label: 'Logo Type',
          defaultValue: 'NONE',
          options: [
            { label: 'None', value: 'NONE' },
            { label: 'Custom', value: 'CUSTOM' },
            { label: 'Nidec', value: 'NIDEC' },
          ],
        },
        { id: 'customLogoImageFileId', type: 'image', label: 'Logo Image', defaultValue: 18 },
      ],
    },
    {
      id: 'internationalization',
      label: 'Internationalization',
      settings: [
        {
          id: 'defaultLanguage',
          type: 'single-enum',
          label: 'Default Language',
          defaultValue: 'EN_UK',
          options: [
            { label: 'English (US)', value: 'EN_US' },
            { label: 'English (UK)', value: 'EN_UK' },
            { label: 'German', value: 'DE_DE' },
            { label: 'French', value: 'FR_FR' },
            { label: 'Italian', value: 'IT_IT' },
            { label: 'Spanish (ES)', value: 'ES_ES' },
            { label: 'Spanish (MX)', value: 'ES_MX' },
            { label: 'Romanian', value: 'RO' },
          ],
        },
        {
          id: 'availableLanguages',
          type: 'multi-enum',
          label: 'Available Languages',
          defaultValue: ['EN_US', 'EN_UK', 'DE_DE', 'FR_FR', 'IT_IT', 'ES_ES', 'ES_MX', 'RO'],
          options: [
            { label: 'English (US)', value: 'EN_US' },
            { label: 'English (UK)', value: 'EN_UK' },
            { label: 'German', value: 'DE_DE' },
            { label: 'French', value: 'FR_FR' },
            { label: 'Italian', value: 'IT_IT' },
            { label: 'Spanish (ES)', value: 'ES_ES' },
            { label: 'Spanish (MX)', value: 'ES_MX' },
            { label: 'Romanian', value: 'RO' },
          ],
        },
        {
          id: 'dateTimeFormat',
          type: 'single-enum',
          label: 'Date & Time Format',
          defaultValue: 'US',
          options: [
            { label: 'US American (MM/DD/YYYY 12:00PM)', value: 'US' },
            { label: 'European (DD.MM.YYYY 24:00)', value: 'EU' },
          ],
        },
      ],
    },
  ],
};
