<template>
  <Message
    v-if="notAuthorized"
    data-cy="whitelist-rfid-tags-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}</Message
  >
  <DataTable
    v-else
    data-cy="whitelist-rfid-tags-table"
    :value="whitelistDetails.rfidtags"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    filterDisplay="menu"
    v-model:selection="selectedTags"
    :globalFilterFields="['username', 'rfid_tag']"
    dataKey="id"
    class="p-datatable-sm mb-5 mt-2 border-rounded overflow-hidden"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="$t('searchBy', { name: `${$t('rfidTag.title')}, ${$t('name')}` })"
            class="p-inputtext-sm"
          />
        </span>
        <div v-if="activeRoles" class="flex justify-end">
          <Button
            data-cy="whitelist-remove-selected-rfid-tags-button"
            :label="$t('removeSelected')"
            icon="pi pi-times"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            :disabled="selectedTags.length === 0"
            @click="removeSelectedRfidTags"
            :loading="loading"
          />
          <Button
            data-cy="open-add-rfid-tags-modal"
            :label="$t('rfidTag.addRfidTag')"
            icon="pi pi-plus"
            class="p-button-primary p-button-sm mr-2"
            @click="openAddRfidTagsModal"
          />
        </div>
      </div>
    </template>
    <template #empty>
      <span data-cy="whitelist-rfid-tags-table-empty" class="w-full block text-center">{{
        $t('rfidTag.tableEmpty')
      }}</span>
    </template>
    <Column class="no-top-border-row" selectionMode="multiple" headerStyle="width: 3rem"></Column>
    <Column
      class="no-top-border-row"
      field="rfid_tag"
      :header="$t('rfidTag.title')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 12rem"
    >
      <template #body="{ data }">
        <span :id="data.id" data-cy="whitelist-rfid-tag">{{ data.rfid_tag }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('rfidTag.title') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="username"
      :header="$t('name')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 15rem"
    >
      <template #body="{ data }">
        <span v-if="data.username" data-cy="whitelist-user-name">{{ data.username }}</span>
        <span v-else class="empty">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="state"
      :header="$t('status')"
      :sortable="true"
      :showFilterMatchModes="false"
      style="min-width: 8rem"
    >
      <template #body="{ data }">
        <Tag
          data-cy="rfid-tag-status"
          class="mr-2"
          :severity="`${data.state ? 'success' : 'info'}`"
          :value="`${data.state ? 'Enabled' : 'Disabled'}`"
          rounded
        ></Tag>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="statuses"
          :placeholder="$t('select', { name: $t('status') })"
          class="p-column-filter mb-2"
          :showClear="true"
        >
        </Dropdown>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="added_at"
      :header="$t('whitelist.addedToList')"
      :sortable="true"
      style="min-width: 10rem"
    >
      <template #body="{ data }">
        <span v-if="data.added_at" data-cy="whitelist-added-to-list">{{
          dayjs(data.added_at).format('ll')
        }}</span>
        <span v-else class="empty">--</span>
      </template>
    </Column>
    <Column
      v-if="activeRoles"
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }">
        <div class="flex">
          <Button
            data-cy="remove-rfid-tag-from-whitelist"
            :label="$t('remove')"
            icon="pi pi-times"
            class="p-button-outlined p-button-plain p-button-sm"
            @click="removeFromWhitelist(data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <WhitelistEditModal />
  <WhitelistAddRfidTagsModal />
  <Toast
    data-cy="remove-rfid-tags-from-whitelist-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="remove-rfid-tags"
  >
    <template v-if="isFailed" #message="slotProps">
      <div class="flex flex-col w-full pt-1">
        <div class="w-full flex">
          <span class="p-toast-message-icon pi pi-info-circle"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
          </div>
        </div>
        <List
          :data="removeFailedIds"
          :title="$t('whitelist.failedRemovalRfidTagsIds')"
          class="w-full my-5"
        />
      </div>
    </template>
  </Toast>
</template>

<script lang="ts">
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useWhitelistsStore } from '@/stores/admin/whitelists/whitelists.store';
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useUsersStore } from '@/stores/admin/users/users.store';
import {
  WHITELIST_ADMIN_ROLE,
  ADMINISTRATOR_ROLE,
  DEFAULT_TOAST_LIFE_MILLISECONDS,
} from '@/utils/constants';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import WhitelistEditModal from '@/components/whitelists/WhitelistEditModal.vue';
import WhitelistAddRfidTagsModal from '@/components/whitelists/WhitelistAddRfidTagsModal.vue';
import Toast from 'primevue/toast';
import List from '@/components/common/List.vue';
import Tag from 'primevue/tag';
import Dropdown from 'primevue/dropdown';

import type { RfidTagFailedId, RfidTag } from '@/models/rfidTags.model';

export default {
  name: 'WhitelistRfidTags',

  components: {
    DataTable,
    Column,
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    InputText,
    WhitelistEditModal,
    Message,
    WhitelistAddRfidTagsModal,
    Toast,
    List,
    Tag,
    Dropdown,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const whitelistsStore = useWhitelistsStore();
    const { whitelistDetails, whitelistAddRfidTagsModalOpen } = storeToRefs(whitelistsStore);
    const loading = ref(false);
    const notAuthorized = ref(false);
    const selectedTags = ref<RfidTag[]>([]);
    const isFailed = ref(false);
    const removeFailedIds = ref<number[]>([]);
    const isBatch = computed(() => (selectedTags.value.length > 1 ? true : false));
    const IS_ENABLED = ref('Status filter');
    const statuses = ref(['Enabled', 'Disabled']);
    const userStore = useUsersStore();

    const activeRoles = computed(
      () =>
        userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
        userStore.me.roles.map((role) => role.id).includes(WHITELIST_ADMIN_ROLE)
    );

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      rfid_tag: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      username: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      state: { value: null, matchMode: IS_ENABLED.value },
    });

    const matchModes = ref([
      { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
      { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
      { label: 'Contains', value: FilterMatchMode.CONTAINS },
    ]);

    FilterService.register(IS_ENABLED.value, (value, filter): boolean => {
      const convertedValue = value ? 'Enabled' : 'Disabled';

      if (filter === undefined || filter === null) {
        return true;
      }

      return convertedValue === filter;
    });

    const successSummary = computed(() => {
      return isBatch.value
        ? t('toast.successBatch', {
            name: t('rfidTags'),
            action: t('toast.removed'),
          })
        : t('toast.success', {
            name: t('rfidTag.title'),
            action: t('toast.removed'),
          });
    });

    const errorSummary = computed(() => {
      return isBatch.value
        ? t('toast.errorBatch', {
            action: t('toast.removal'),
            name: t('rfidTags'),
          })
        : t('toast.error', {
            action: t('toast.removal'),
            name: t('rfidTag.title'),
          });
    });

    const showSuccess = () => {
      toast.add({
        severity: 'success',
        summary: successSummary.value,
        life: DEFAULT_TOAST_LIFE_MILLISECONDS,
        group: 'remove-rfid-tags',
      });
    };

    const showError = () => {
      toast.add({
        severity: 'error',
        summary: errorSummary.value,
        group: 'remove-rfid-tags',
      });
    };

    const removeSelectedRfidTags = async () => {
      const ids = selectedTags.value.map((item) => ({ id: item.id }));

      loading.value = true;

      await whitelistsStore
        .removeRfidTagsFromWhitelist(ids)
        .then((response: any) => {
          loading.value = false;
          if (response.status === 204) {
            toast.removeGroup('remove-rfid-tags');
            showSuccess();
            selectedTags.value = [];
          }
        })
        .catch((error) => {
          whitelistsStore.fetchWhitelistDetails(whitelistDetails.value.id);
          toast.removeGroup('remove-rfid-tags');
          if (error.response && error.response.status === 400 && error.response.data.failed_items) {
            removeFailedIds.value = error.response.data.failed_items.map(
              (item: RfidTagFailedId) => item.id
            );
            isFailed.value = true;
            showError();
          } else {
            showError();
          }
          loading.value = false;
          throw new Error(error);
        });
    };

    const openAddRfidTagsModal = () => {
      whitelistAddRfidTagsModalOpen.value = true;
    };

    const removeFromWhitelist = async (id: number) => {
      const rfidTagId = {
        id: id,
      };
      await whitelistsStore
        .removeRfidTagsFromWhitelist([rfidTagId])
        .then((response: any) => {
          if (response.status === 204) {
            toast.removeGroup('remove-rfid-tags');
            showSuccess();
            selectedTags.value = [];
          }
        })
        .catch((error) => {
          toast.removeGroup('remove-rfid-tags');
          showError();
          throw new Error(error);
        });
    };

    return {
      whitelistDetails,
      filters,
      loading,
      matchModes,
      removeSelectedRfidTags,
      openAddRfidTagsModal,
      removeFromWhitelist,
      notAuthorized,
      selectedTags,
      dayjs,
      successSummary,
      errorSummary,
      isFailed,
      removeFailedIds,
      statuses,
      activeRoles,
    };
  },
};
</script>
