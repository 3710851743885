<template>
  <div class="map-container">
    <l-map ref="map" :use-global-leaflet="false" :options="options">
      <l-control-attribution position="bottomright" prefix="Leaflet | OpenStreetMap" />
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      />
      <l-circle-marker
        v-for="(point, index) in pointsWithLatLng"
        :key="index"
        :lat-lng="[point.lat, point.lng]"
        :color="'white'"
        :weight="1"
        :fill-color="point.color"
        :radius="6"
        :fill-opacity="1"
      >
        <l-tooltip class="map-tooltip">
          <div class="circle" :style="{ backgroundColor: point.color }" />
          <span v-if="point.assetName">{{ point.assetName }}:</span>
          {{ ' ' }}
          <span v-if="point.value">{{ point.value }}</span>
        </l-tooltip>
      </l-circle-marker>
    </l-map>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

import 'leaflet/dist/leaflet.css';
import type { Map, MapOptions } from 'leaflet';
import {
  LCircleMarker,
  LControlAttribution,
  LMap,
  LTileLayer,
  LTooltip,
} from '@vue-leaflet/vue-leaflet';
import type { MapWidget, WidgetFilter } from '@/stores/admin/dashboard/dashboard.types';
import { loadWidgetData } from '@/stores/admin/dashboard/dashboard.api';
import {
  buildMapPoints,
  computeBounds,
  type MapPoint,
} from '@/stores/admin/dashboard/dashboard.logic';
import type { WidgetContextResolver } from '@/stores/admin/dashboard/dashboard.logic.context';
import { ColorGenerator } from '@/stores/admin/dashboard/dashboard.logic.colors';

const props = defineProps<{
  widget: MapWidget;
  additionalFilters: WidgetFilter[];
  isInteractive?: boolean;
  contextResolver: WidgetContextResolver;
}>();

const error = ref<Error | null>(null);
const colorGen = new ColorGenerator(props.widget.presentation?.colorMap);
const map = ref<typeof LMap>();

const options: MapOptions = {
  attributionControl: false,
};

const points = ref<MapPoint[]>([]);
const pointsWithLatLng = ref<PointWithLatLng[]>([]);

type PointWithLatLng = MapPoint & { lat: number; lng: number };

onMounted(async () => {
  if(!props.widget.query) throw Error("widget.query is not defined!" + JSON.stringify(props.widget) )
  const loadData = await loadWidgetData(props.widget.query, props.additionalFilters);

  if (!loadData.ok) {
    error.value = loadData.error;
    return;
  }

  const mapPoints = await buildMapPoints(
    props.widget,
    colorGen,
    loadData.data,
    props.contextResolver
  );

  if (!mapPoints.ok) {
    error.value = mapPoints.error;
    return;
  }

  points.value = mapPoints.data;
  pointsWithLatLng.value = points.value.filter((pt) => pt.lat && pt.lng) as PointWithLatLng[];
  (map.value?.leafletObject as Map).fitBounds(computeBounds(pointsWithLatLng.value));
});
</script>

<style scoped lang="scss">
.map-container {
  background-color: var(--gray-0);
  height: 400px;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
</style>
