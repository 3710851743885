<template>
  <page-header :title="t('organisationsAndUsers')" />
  <div class="container container-grey">
    <div class="flex flex-wrap justify-end">
      <OrganisationTable
        class="overflow-x-auto w-full"
        :child-table="organisationUsersTable"
        :child-table-name="USERS"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import OrganisationTable from '@/components/organisations/OrganisationTable.vue';
import PageHeader from '@/components/common/layout/PageHeader.vue';
import { shallowRef } from 'vue';
import UsersTable from '@/components/users/UsersTable.vue';
import { USERS } from '@/utils/constants';

const { t } = useI18n();
const organisationUsersTable = shallowRef(UsersTable);
</script>

<style lang="scss" scoped></style>
