<template>
  <Dialog
    data-cy="report-delete-modal"
    :header="modalTitle"
    v-model:visible="reportDeleteModalIsOpen"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="field flex flex-col field-edit">
      <Message severity="error" icon="pi pi-exclamation-triangle" :closable="false">
        {{ $t('deleteWarning') }}
      </Message>
      <p class="delete-info">
        {{ $t('report.deleteInfo') }}
      </p>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        data-cy="report-delete-button"
        class="p-button-sm p-button-footer p-button-error mx-auto mb-2 block mt-5"
        :label="t('delete')"
        :loading="loading"
        @click="deleteReport"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="report-delete-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="delete-report"
  >
    <template v-if="isFailed" #message="slotProps">
      <div class="flex flex-col w-full pt-1">
        <div class="w-full flex">
          <span class="p-toast-message-icon pi pi-info-circle"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
          </div>
        </div>
        <List :data="deleteFailedIds" :title="t('report.failedIds')" class="w-full my-5" />
      </div>
    </template>
  </Toast>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useReportsStore } from '@/stores/admin/reports/reports.store';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Message from 'primevue/message';
import Toast from 'primevue/toast';
import List from '@/components/common/List.vue';

import type { ReportFailedId } from '@/models/reports.model';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

const { t } = useI18n();
const toast = useToast();
const store = useReportsStore();
const { reportDeleteModalIsOpen, reportDeleteIds } = storeToRefs(store);

const emit = defineEmits(['delete']);

const isFailed = ref(false);
const loading = ref(false);
const deleteFailedIds = ref<number[]>([]);

const isBatch = computed(() => reportDeleteIds.value.length > 1);
const modalTitle = computed(() =>
  t('delete', { itemName: isBatch.value ? t('reports') : t('report.title') })
);

const successSummary = computed(() => {
  return isBatch.value
    ? t('toast.successBatch', {
        name: t('reports'),
        action: t('toast.deleted'),
      })
    : t('toast.success', {
        name: t('report.title'),
        action: t('toast.deleted'),
      });
});

const errorSummary = computed(() => {
  return isBatch.value
    ? t('toast.errorBatch', {
        action: t('toast.removal'),
        name: t('reports'),
      })
    : t('toast.error', {
        action: t('toast.removal'),
        name: t('report.title'),
      });
});

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: successSummary.value,
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'delete-report',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: errorSummary.value,
    group: 'delete-report',
  });
};

const closeModal = () => {
  reportDeleteModalIsOpen.value = false;
  reportDeleteIds.value = [];
  loading.value = false;
};

const deleteReport = async () => {
  loading.value = true;
  toast.removeGroup('delete-report');

  try {
    await store.batchDeleteReports(reportDeleteIds.value);
    toast.removeGroup('delete-report');
    showSuccess();
    closeModal();
    emit('delete');
  } catch (error: any) {
    loading.value = false;
    toast.removeGroup('delete-report');
    if (error.response && error.response.status === 400 && error.response.data.failed_items) {
      deleteFailedIds.value = error.response.data.failed_items.map(
        (item: ReportFailedId) => item.id
      );
      isFailed.value = true;
      showError();
      closeModal();
    } else {
      showError();
    }
    throw new Error(error);
  } finally {
    await store.fetchReportsList();
    loading.value = false;
  }
};
</script>
