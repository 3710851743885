<template>
  <Button
    outlined
    class="font-medium p-2 pr-3"
    data-cy="back-button"
    icon="pi pi-angle-left"
    :label="$t('back')"
    @click="goBack"
  />
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import { useRouter } from 'vue-router';

const router = useRouter();

const goBack = () => router.back();
</script>
