<template>
  <div class="group relative inline-block">
    <button @click="onClick" :disabled="props.disabled" :data-cy="props.dataCy">
      <slot />
    </button>

    <span v-if="props.disabled" class="hidden group-hover:block tooltip">
      {{ props.disabledTooltip }}
    </span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  label?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  dataCy?: string;
}>();

const emit = defineEmits<{
  (event: 'click', e: MouseEvent): void;
}>();

function onClick(e: MouseEvent) {
  if (!props.disabled) emit('click', e);
}
</script>

<style scoped lang="scss">
.button-container {
  position: relative;
  display: inline-block;
}

button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding: 0.325rem 0.625rem;
  border-radius: var(--rounded-sm);
  background-color: var(--gray-0);
  border: 1px solid var(--gray-200);
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--gray-600);
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not(:disabled):hover {
    background-color: var(--gray-100);
  }
}

.tooltip {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  padding: 0.25rem 0.5rem;
  border-radius: var(--rounded-sm);
  border: 1px solid var(--gray-200);
  background-color: var(--gray-100);
  box-shadow: 0 5px 0.5rem rgba(0, 0, 0, 0.05);
  color: var(--gray-400);
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 1;
}
</style>
