<template>
  <label
    :class="[
      'inline-label-duration flex items-center border rounded-md',
      `${durationIsInvalid ? 'border-red-700' : 'border-gray-200'}`,
    ]"
  >
    <span
      class="flex rounded-l-lg items-center h-full px-3 bg-gray-100 text-sm border-r font-medium text-gray-500"
      >{{ type }}<span v-if="required" class="required">*</span></span
    >
    <InputNumber
      data-cy="duration-hours-input"
      aria-describedby="durationInput-help"
      class="p-inputtext-sm inline-input hours-input"
      placeholder="hh"
      mode="decimal"
      :min="0"
      :max="99"
      :useGrouping="false"
      v-model="hours"
      @keyup="updateHours"
      @input="restrictHoursInput"
    />
    <span class="px-0.5">:</span>
    <InputNumber
      data-cy="duration-minutes-input"
      aria-describedby="durationInput-help"
      class="p-inputtext-sm inline-input minutes-input"
      placeholder="mm"
      mode="decimal"
      :min="0"
      :max="59"
      :useGrouping="false"
      v-model="minutes"
      @keyup="updateMinutes"
      @input="restrictMinutesInput"
    />
    <span class="px-0.5">:</span>
    <InputNumber
      data-cy="duration-seconds-input"
      aria-describedby="durationInput-help"
      class="p-inputtext-sm inline-input seconds-input"
      placeholder="ss"
      mode="decimal"
      :min="0"
      :max="59"
      :useGrouping="false"
      v-model="seconds"
      @keyup="updateSeconds"
      @input="restrictSecondsInput"
    />
  </label>
</template>
<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { convertTime } from '@/utils/timeConvertion';

import InputNumber from 'primevue/inputnumber';

const emit = defineEmits(['update']);

const props = withDefaults(
  defineProps<{
    modelValue: number | null;
    type: string;
    required: boolean;
  }>(),
  {
    required: false,
  }
);

const hours = ref();
const minutes = ref();
const seconds = ref();

const durationIsInvalid = computed(() => {
  return (
    (!hours.value || hours.value === 0) &&
    (!minutes.value || minutes.value === 0) &&
    (!seconds.value || seconds.value === 0)
  );
});

const restrictHoursInput = (event: any) => {
  if (event.value && event.value.toString().length > 2) {
    const restrictedValue = event.value.toString().slice(0, 2);
    hours.value = Number(restrictedValue);
  }
};

const restrictMinutesInput = (event: any) => {
  if (event.value && event.value.toString().length > 2) {
    const restrictedValue = event.value.toString().slice(0, 2);
    minutes.value = Number(restrictedValue);
  }
};

const restrictSecondsInput = (event: any) => {
  if (event.value && event.value.toString().length > 2) {
    const restrictedValue = event.value.toString().slice(0, 2);
    seconds.value = Number(restrictedValue);
  }
};

const updateHours = async (event: any) => {
  hours.value = event.target.value;
  emit('update', convertTime(Number(event.target.value), minutes.value, seconds.value));
};

const updateMinutes = async (event: any) => {
  minutes.value = event.target.value;
  emit('update', convertTime(hours.value, Number(event.target.value), seconds.value));
};

const updateSeconds = async (event: any) => {
  seconds.value = event.target.value;
  emit('update', convertTime(hours.value, minutes.value, Number(event.target.value)));
};

onMounted(() => {
  hours.value = props.modelValue ? Math.floor(props.modelValue / 3600) : null;
  minutes.value = props.modelValue ? Math.floor((props.modelValue % 3600) / 60) : null;
  seconds.value = props.modelValue ? Math.floor((props.modelValue % 3600) % 60) : null;
});
</script>

<style scoped lang="scss">
.inline-label-duration {
  overflow: hidden;
  &__input {
    .p-dropdown-label {
      color: black !important;
      font-weight: 500;
      font-size: 14px;
      padding: 5px 10px;
    }
  }
}

.p-inputnumber-input {
  border: 0;
  padding: 0;
  flex: none;

  &:enabled {
    &:focus {
      box-shadow: none;
    }
  }
}

:deep(.p-inputtext-sm .p-inputtext) {
  width: 24px;
  border: none;
  padding: 6px 0px;
  text-align: center;

  &:enabled {
    &:focus {
      box-shadow: none;
    }
  }
}

.hours-input {
  padding-left: 4px;
}

.seconds-input {
  padding-right: 4px;
}
</style>
