<template>
  <div class="numeric-input-container" :class="{ invalid: !valid }">
    <input
      type="number"
      v-model="updatedValue"
      :min="props.min"
      :max="props.max"
      :step="step"
      :data-cy="`${props.id}-value-input`"
    />
    <span v-if="props.unit" class="unit">{{ props.unit }}</span>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import type { GenericSettingNumber } from '@/models/datatypes.model';
import type { ProductTypeSetting } from '@/models/productTypes.model';

const props = defineProps<{
  id: string;
  type: GenericSettingNumber['type'] | ProductTypeSetting['type'];
  defaultValue: number | string | boolean | undefined;
  min?: number;
  max?: number;
  unit?: string;
  valid?: boolean;
}>();

const emit = defineEmits<{
  (event: 'update:value', value: number | string | boolean | undefined): void;
}>();

const updatedValue = ref(props.defaultValue);

const step = ['double', 'float'].includes(props.type || '') ? 0.1 : 1;

watch(updatedValue, (value) => emit('update:value', value));
</script>

<style scoped lang="scss">
input {
  width: 100%;
  padding: 0.25rem;
  border-radius: var(--rounded-md);
  border: none;
  outline: none;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.numeric-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);

  input {
    width: 100%;
    padding: 0.25rem 0.5rem;
    text-align: right;
  }

  span {
    padding: 0 0.5rem;
    border-left: 1px solid var(--gray-200);
  }

  &.invalid {
    border-color: var(--red-primary);
  }
}
</style>
