<template>
  <PageHeader
    :title="t('reports')"
    :description="t('report.description.main')"
    :additionalDescription="t('report.description.additional')"
  />
  <div class="container container-grey">
    <ReportsTabs />
    <ReportHistoryTable v-if="reportsActiveTab === 0" />
    <ReportConfigurationTable v-if="reportsActiveTab === 1" />
  </div>

  <ReportCreateEditModal />
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useReportsStore } from '@/stores/admin/reports/reports.store';
import { storeToRefs } from 'pinia';

import PageHeader from '@/components/common/layout/PageHeader.vue';
import ReportsTabs from '@/components/reports/ReportsTabs.vue';
import ReportConfigurationTable from '@/components/reports/ReportConfigurationTable.vue';
import ReportCreateEditModal from '@/components/reports/ReportCreateEditModal.vue';
import ReportHistoryTable from '@/components/reports/ReportHistoryTable.vue';

const { t } = useI18n();
const reportsStore = useReportsStore();
const { reportsActiveTab } = storeToRefs(reportsStore);

onMounted(() => {
  reportsActiveTab.value = 0;
});
</script>
