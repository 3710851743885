<template>
  <button @click="emit('click')">
    <arrow-left-icon class="icon" />
    <span>{{ props.text || t('back') }}</span>
  </button>
</template>

<script setup lang="ts">
import { ArrowLeftIcon } from '@heroicons/vue/24/outline';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits(['click']);
const props = defineProps<{ text?: string }>();
</script>

<style scoped lang="scss">
button {
  border: 1px solid var(--gray-200);
  border-radius: var(--rounded-md);
  padding: 0.25rem 0.75rem;
  margin: 0 0 0 0.75rem;
  background-color: var(--gray-0);
  cursor: pointer;

  &:hover {
    background-color: var(--gray-100);
  }
}

.icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  display: inline-block;
}
</style>
