<template>
  <Dialog
    v-model:visible="isDataExportModalOpen"
    data-cy="data-export-create-edit-modal"
    modal
    class="data-export-create-edit-modal bg-gray-100"
    :breakpoints="{ '960px': '90vw' }"
    :style="{ width: '816px' }"
    @hide="closeModal"
  >
    <template #header>
      <div class="relative w-full text-center">
        <Button
          v-if="!isFirst"
          :label="$t('back')"
          icon="pi pi-angle-left"
          class="!absolute p-button-outlined !bg-white p-button-plain p-button-sm mr-2 left-0 top-1/2 transform -translate-y-1/2"
          @click="goToPrevious"
        />

        <p class="font-semibold">
          {{ modalTitle }}
        </p>
      </div>
    </template>

    <ModalStepper :active-step="current" v-bind="{ steps }" />

    <div class="border-t">
      <component :is="dataExportStepsComponents[current]" v-bind="{ v$ }" />
    </div>

    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        data-cy="data-export-create-edit-cancel-button"
        @click="closeModal"
      />
      <Button
        v-if="!isLast"
        :disabled="!isStepValid"
        :label="t('next')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        data-cy="data-export-create-edit-next-button"
        @click="goToNext"
      />
      <Button
        v-else
        :disabled="!isStepValid"
        :label="t('apply')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        data-cy="data-export-create-edit-submit-button"
        :loading="isLoading"
        @click="submitForm"
      />
    </template>
  </Dialog>

  <Toast
    data-cy="data-export-create-edit-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="data-export"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useStepper } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { DataExportStep } from '@/models/dataExports.model';
import { DataExportStatus } from '@/models/dataExports.model';
import { useDataExportsStore } from '@/stores/admin/dataExports/dataExports.store';
import { dataExportRules } from '@/utils/dataExportModalRules';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ModalStepper from '@/components/common/steppers/ModalStepper.vue';
import DataExportConfigurationStep from '@/components/dataExports/steps/DataExportConfigurationStep.vue';
import DataExportAssetsStep from '@/components/dataExports/steps/DataExportAssetsStep.vue';
import DataExportPreviewStep from '@/components/dataExports/steps/DataExportPreviewStep.vue';

import type { DataExport } from '@/models/dataExports.model';

const { t } = useI18n();
const toast = useToast();
const dataExportsStore = useDataExportsStore();
const { isDataExportModalOpen, dataExportModalData } = storeToRefs(dataExportsStore);
const isLoading = ref(false);

const v$ = useVuelidate(dataExportRules, dataExportModalData);

const modalTitle = computed(() => t('dataExport.newDataExport'));

const closeModal = () => {
  isDataExportModalOpen.value = false;
  resetForm();
};

const dataExportStepsComponents: Record<DataExportStep, unknown> = {
  [DataExportStep.Configuration]: DataExportConfigurationStep,
  [DataExportStep.Assets]: DataExportAssetsStep,
  [DataExportStep.Preview]: DataExportPreviewStep,
};

const modalPath = [DataExportStep.Configuration, DataExportStep.Assets, DataExportStep.Preview];

const { steps, current, goTo, goToNext, goToPrevious, isFirst, isLast } = useStepper(modalPath);

const resetForm = () => {
  dataExportsStore.resetDataExportModalData();
  v$.value.$reset();
  goTo(steps.value[0]);
};

const showSuccess = () => {
  toast.removeGroup('data-export');
  toast.add({
    severity: 'success',
    summary: t('toast.dataExportCreated'),
    life: 10000,
    group: 'data-export',
  });
};

const showError = () => {
  toast.removeGroup('data-export');
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: t('toast.creation'),
      name: t('dataExport.dataExportJob'),
    }),
    group: 'data-export',
  });
};

const isStepValid = computed(() => {
  const stepValidationRules: Record<DataExportStep, boolean> = {
    [DataExportStep.Configuration]:
      v$.value.name.$dirty &&
      !v$.value.name.$invalid &&
      dataExportModalData.value.datatypes.length !== 0 &&
      !v$.value.start_datetime.$invalid &&
      !v$.value.end_datetime.$invalid,
    [DataExportStep.Assets]: v$.value.systems.$dirty && !v$.value.systems.$invalid,
    [DataExportStep.Preview]: true,
  };

  return stepValidationRules[current.value];
});

async function submitForm() {
  try {
    isLoading.value = true;

    await dataExportsStore.createDataExport();

    isLoading.value = false;
    showSuccess();
    closeModal();
    resetForm();
    dataExportsStore.resetDataExportModalData();
  } catch (err: unknown) {
    isLoading.value = false;
    showError();
    throw new Error(err as string);
  } finally {
    checkStatus();
  }
}

async function checkStatus() {
  await dataExportsStore
    .fetchDataExportList()
    .then((response) => {
      const isProcessing = response.data.some(
        (item: DataExport) => item.status === DataExportStatus.Processing
      );

      if (isProcessing) {
        setTimeout(checkStatus, 60000);
      }
    })
    .catch((error: unknown) => {
      throw new Error(error as string);
    });
}
</script>

<style lang="scss">
.data-export-create-edit-modal {
  transition: width 0.2s ease;
  max-width: 100%;
}
</style>
