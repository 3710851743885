<template>
  <div class="flex flex-wrap justify-between items-center gap-4">
    <span class="p-input-icon-left search event-history-search">
      <i class="pi pi-search pt-0.5" />
      <InputText
        data-cy="event-history-global-search"
        v-model="eventFilters.details"
        :placeholder="$t('searchBy', { name: `${$t('details')}` })"
        class="p-inputtext-sm"
        @keyup="onDetailsSearch"
      />
    </span>
    <div class="flex flex-wrap justify-right gap-4 items-center">
      <DateTimeInput
        data-cy="event-history-time-from"
        ref="startDateTimeCalendar"
        class="w-[210px]"
        v-model="eventFilters.startTime"
        :maxDateTime="maxTimeFrom"
        :stepMinute="10"
        :placeholder="$t('chargePark.timeFrom')"
        @update:modelValue="applyStartDateAndTimeFilter"
      />
      <DateTimeInput
        data-cy="event-history-time-to"
        ref="endDateTimeCalendar"
        class="w-[210px]"
        v-model="eventFilters.endTime"
        :minDateTime="minTimeTo"
        :maxDateTime="maxTimeTo"
        :stepMinute="10"
        :placeholder="$t('chargePark.timeTo')"
        @update:modelValue="applyEndDateAndTimeFilter"
      />

      <CheckboxMultiFilterVue
        v-if="eventTypes.length > 0"
        :items="filteredEventTypes"
        :title="$t('type')"
        :labelWidth="65"
        :selected="props.types"
        @update:selected="setSelectedEventTypes"
      />
      <div class="flex align-items-center">
        <Checkbox v-model="aggregateErrorTypes" id="aggregateErrorTypesCheckbox" name="aggregateErrorTypesCheckbox" binary />
        <label for="aggregateErrorTypesCheckbox" class="ml-2"> Aggregate errors </label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import Checkbox from 'primevue/checkbox';

import InputText from 'primevue/inputtext';
import CheckboxMultiFilterVue from '../common/CheckboxMultiFilter.vue';

import { computed, ref, watch } from 'vue';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';

import type { EventQueryParams } from '@/models/history.model';
import type { CalendarState } from 'primevue/calendar';

import DateTimeInput from '@/components/common/time/DateTimeInput.vue';

const hardwareSystemsStore = useHardwareSystemsStore();
const { eventTypes } = storeToRefs(hardwareSystemsStore);

const filteredEventTypes = eventTypes.value.filter(item=>!["Receipts", "Outages", "Other"].includes(item.name))

const startDateTimeCalendar = ref<CalendarState>({} as CalendarState);
const endDateTimeCalendar = ref<CalendarState>({} as CalendarState);
const startDateError = ref(false);
const endDateError = ref(false);
const aggregateErrorTypes =defineModel<boolean>("aggregateErrorTypes")

const props = defineProps<{
  types: number[];
  defaultStartDateTime: Date;
  defaultEndDateTime: Date;
}>();

const eventFilters = ref<EventQueryParams>({
  pageNumber: 0,
  startTime: props.defaultStartDateTime.toUTCString(),
  endTime: props.defaultEndDateTime.toUTCString(),
  type: [],
  event: undefined,
  details: undefined,
  ordering: undefined,
});

const emit = defineEmits<{
  (event: 'update:start-date-time', date: Date | null): void;
  (event: 'update:end-date-time', date: Date | null): void;
  (event: 'update:query', query: string): void;
  (event: 'update:types', types: number[]): void;
}>();

const maxTimeFrom = computed(() => {
  if (!eventFilters.value.endTime) return new Date().toISOString();
  return eventFilters.value.endTime;
});
const maxTimeTo = computed(() => {
  return new Date().toISOString();
});
const minTimeTo = computed(() => {
  if (!eventFilters.value.startTime) return undefined;
  return eventFilters.value.startTime;
});

const applyStartDateAndTimeFilter = () => {
  const date = eventFilters.value.startTime ? new Date(eventFilters.value.startTime) : null;
  emit('update:start-date-time', date);
  closeCalendar('start');
};

const applyEndDateAndTimeFilter = () => {
  const date = eventFilters.value.endTime ? new Date(eventFilters.value.endTime) : null;
  emit('update:end-date-time', date);
  closeCalendar('end');
};

const onDetailsSearch = debounce(() => {
  emit('update:query', eventFilters.value.details ?? '');
}, 500);

const setSelectedEventTypes = (data: number[]) => {
  eventFilters.value.type = data;
  emit('update:types', data);
};

const closeCalendar = (calendar: 'start' | 'end') => {
  if (calendar === 'start') {
    startDateTimeCalendar.value.overlayVisible = false;
  }

  if (calendar === 'end') {
    endDateTimeCalendar.value.overlayVisible = false;
  }
};

watch(
  [() => eventFilters.value.startTime, () => eventFilters.value.endTime],
  ([newStartTime, newEndTime]) => {
    if (!newStartTime || !newEndTime) return;

    startDateError.value = newStartTime >= newEndTime;
    endDateError.value = newEndTime <= newStartTime;
  }
);


</script>

<style lang="scss" scoped>
:deep(.p-calendar .p-inputtext) {
  padding: 7px 10px;
  color: $text-medium;
  font-size: 14px;
}

:deep(input) {
  &:placeholder {
    color: $text-medium;
  }
}

.event-history-search {
  max-width: 330px;

  .p-inputtext {
    padding: 7px 10px 8px 2.5rem;
  }
}

:deep(.p-calendar) {
  .p-inputtext {
    width: 160px;
  }
}
</style>
