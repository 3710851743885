<template>
  <page-header :title="t('update.firmware')" />
  <div class="container container-grey">
    <UpdateTable />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import UpdateTable from '@/components/updates/UpdateTable.vue';
import PageHeader from '@/components/common/layout/PageHeader.vue';

const { t } = useI18n();
</script>
