import { defineStore } from 'pinia';
import {
  fetchWhitelists,
  createWhitelist,
  fetchSingleWhitelistDetails,
  addChargers,
  addRfidTags,
  removeRfidTags,
  removeChargers,
  editWhitelist,
  removeWhitelist,
} from '@/stores/admin/whitelists/whitelists.api';
import type {
  Whitelist,
  WhitelistCreate,
  WhitelistDetailsView,
  WhitelistSingleDetails,
  WhitelistId,
} from '@/models/whitelists.model';

interface WhitelistsState {
  whitelists: Whitelist[];
  whitelistEditModalIsOpen: boolean;
  whitelistIsEditing: boolean;
  whitelistEditionData: WhitelistDetailsView;
  whitelistDetailsView: WhitelistDetailsView;
  whitelistDetails: WhitelistSingleDetails;
  whitelistActiveTab: number;
  whitelistAddChargersModalIsOpen: boolean;
  whitelistAddRfidTagsModalOpen: boolean;
  whitelistDeleteModalIsOpen: boolean;
  whitelistDeleteId: number;
}

export const useWhitelistsStore = defineStore('whitelists', {
  state: (): WhitelistsState => ({
    whitelists: [],
    whitelistEditModalIsOpen: false,
    whitelistIsEditing: false,
    whitelistEditionData: {
      id: 0,
      name: '',
    },
    whitelistDetailsView: {
      id: 0,
      name: '',
    },
    whitelistDetails: {
      id: 0,
      name: '',
      number_of_rfidtags: 0,
      number_of_systems: 0,
      rfidtags: [],
      systems: [],
    },
    whitelistActiveTab: 0,
    whitelistAddChargersModalIsOpen: false,
    whitelistAddRfidTagsModalOpen: false,
    whitelistDeleteModalIsOpen: false,
    whitelistDeleteId: 0,
  }),
  actions: {
    async fetchAllWhitelists() {
      return await fetchWhitelists()
        .then((response) => {
          this.whitelists = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async createNewWhitelist(data: WhitelistCreate) {
      return await createWhitelist(data)
        .then((response) => {
          this.fetchAllWhitelists();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchWhitelistDetails(id: number) {
      return await fetchSingleWhitelistDetails(id)
        .then((response) => {
          this.whitelistDetails = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async addChargersToWhitelist(whitelistId: number, data: WhitelistId[]) {
      return await addChargers(whitelistId, data)
        .then((response) => {
          this.fetchWhitelistDetails(whitelistId);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async addRfidTagsToWhitelist(whitelistId: number, data: WhitelistId[]) {
      return await addRfidTags(whitelistId, data)
        .then((response) => {
          this.fetchWhitelistDetails(whitelistId);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async removeRfidTagsFromWhitelist(data: WhitelistId[]) {
      return await removeRfidTags(this.whitelistDetails.id, data)
        .then((response) => {
          this.fetchWhitelistDetails(this.whitelistDetails.id);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async removeChargersFromWhitelist(data: WhitelistId[]) {
      return await removeChargers(this.whitelistDetails.id, data)
        .then((response) => {
          this.fetchWhitelistDetails(this.whitelistDetails.id);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async deleteWhitelist(id: number) {
      return await removeWhitelist(id)
        .then((response) => {
          this.fetchAllWhitelists();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async updateWhitelist(data: WhitelistDetailsView) {
      return await editWhitelist(data)
        .then((response) => {
          this.fetchAllWhitelists();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    resetWhitelistEditionData() {
      this.whitelistEditionData = {
        id: 0,
        name: '',
      };
    },
    setWhitelistDetailsView(id: number) {
      this.whitelistDetailsView.id = id;

      const whitelist = this.whitelists.find((item: Whitelist) => item.id === id);
      if (whitelist) {
        this.whitelistDetailsView.name = whitelist.name;
      }
    },
    resetWhitelistDetails() {
      this.whitelistDetails = {
        id: 0,
        name: '',
        number_of_rfidtags: 0,
        number_of_systems: 0,
        rfidtags: [],
        systems: [],
      };
    },
  },
});
