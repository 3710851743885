import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { Widget } from '@/models/controlPanel/dashboard';

type Context = {
  FIRMWARE: string;
};

export function createControlPanelContext(system: HardwareSystem | null): Context {
  return {
    FIRMWARE: system?.version || 'v0.0.0',
  };
}

export function filterWidgets(context: Context, widgets: Widget[]): Widget[] {
  return widgets.filter((widget) => {
    if (!widget.condition) return true;

    let conditionWithValues = widget.condition;

    for (const name of Object.keys(context)) {
      const value = context[name as keyof Context];
      conditionWithValues = conditionWithValues.replace(`$${name}`, `'${value}'`);
    }

    return eval(conditionWithValues);
  });
}
