

import { Color, type RGBA } from '@kurkle/color';

export class BysColor extends Color {
    constructor(input: Color|RGBA|string|number[])
    {  
        if (input.toString() === "blue") super("#75bef8")
        else super(new Color(input).alpha(0.45))
    }
}