<template>
  <div class="search-container">
    <div>
      <magnifying-glass-icon class="h-5 w-5 text-gray-400" />
    </div>
    <input type="text" @input="updateSearchQuery" placeholder="Search" />
    <div class="w-5 h-5">
      <loading-spinner v-if="isLoading" color="light" :opacity="0.5" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';

const emit = defineEmits<{
  (event: 'update:query', value: string): void;
}>();

// eslint-disable-next-line no-undef
const timer = ref<NodeJS.Timeout | null>(null);
const isLoading = ref(false);

function updateSearchQuery(event: Event) {
  const target = event.target as HTMLInputElement;
  isLoading.value = true;

  if (timer.value) clearTimeout(timer.value);

  timer.value = setTimeout(() => {
    timer.value = null;
    emit('update:query', target.value);
    isLoading.value = false;
  }, 750);
}
</script>

<style scoped lang="scss">
div.search-container {
  border: 1px solid #333333;
  border-radius: var(--rounded-md);
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background-color: #222222;
}

input[type='text'] {
  background-color: #222222;
  margin-right: 0.5rem;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 0.375rem 0.5rem;

  &::placeholder {
    color: var(--gray-400);
  }
}
</style>
