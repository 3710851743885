import { defineStore } from 'pinia';
import {
  fetchDataExports,
  createNewDataExport,
  fetchDataExportTypes,
  fetchDataExportFilePath,
} from '@/stores/admin/dataExports/dataExports.api';
import type { DataExportEditData, DataExport, DataTypes } from '@/models/dataExports.model';
import dayjs from 'dayjs';

interface MediaState {
  dataExports: DataExport[];
  isDataExportModalOpen: boolean;
  dataExportModalData: DataExportEditData;
  dataTypes: DataTypes[];
}

export const useDataExportsStore = defineStore('dataExports', {
  state: (): MediaState => ({
    dataExports: [],
    isDataExportModalOpen: false,
    dataExportModalData: {
      name: '',
      systems: [],
      datatypes: [],
      start_datetime: null,
      end_datetime: null,
    },
    dataTypes: [],
  }),

  actions: {
    async fetchDataExportList() {
      try {
        const res = await fetchDataExports();
        this.dataExports = res.data;

        return res;
      } catch (error: unknown) {
        return Promise.reject(error);
      }
    },

    async fetchDataExportTypeList() {
      try {
        const res = await fetchDataExportTypes();
        this.dataTypes = res.data;

        return res;
      } catch (error: unknown) {
        return Promise.reject(error);
      }
    },

    async createDataExport() {
      try {
        return await createNewDataExport(this.dataExportModalData);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    resetDataExportModalData() {
      (this.dataExportModalData.name = ''),
        (this.dataExportModalData.systems = []),
        (this.dataExportModalData.datatypes = []),
        (this.dataExportModalData.start_datetime = null),
        (this.dataExportModalData.end_datetime = dayjs(new Date()).format());
    },
    async fetchDataExportFileFullPath(url: string) {
      try {
        return await fetchDataExportFilePath(url);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  },
});
