<template>
  <PageHeader :title="t('whitelists')" />
  <div class="container container-grey">
    <WhitelistTable />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useGeneralStore } from '@/stores/general/general.store';
import { useI18n } from 'vue-i18n';
import WhitelistTable from '@/components/whitelists/WhitelistTable.vue';
import PageHeader from '@/components/common/layout/PageHeader.vue';

const { t } = useI18n();
const generalStore = useGeneralStore();
const { title } = storeToRefs(generalStore);

const setPageTitle = (name: string) => {
  title.value = name;
};

onMounted(() => setPageTitle(t('whitelists')));
</script>
