<template>
  <div>
    <PanelWithEdit
      :title="$t('info')"
      :loading="isLoading"
      :isEditing="isEditing"
      :isNotValid="editFormIsNotValid"
      @onEdit="isEditing = true"
      @onClose="closeEditing"
      @onApply="editSite"
    >
      <div class="flex justify-between my-2">
        <div class="flex flex-col">
          <div v-for="(value, key) in chargeParkDetails" :key="key">
            <span data-cy="site-info-field-name" class="block font-medium py-3"
              >{{ $t(`chargePark.chargeParkDetails.${key}`)
              }}<span v-if="key === 'name'" class="required font-medium">*</span></span
            >
          </div>
        </div>
        <div v-if="isEditing" class="flex flex-col w-1/2">
          <div class="my-1">
            <InputText
              data-cy="panel-item-input"
              type="text"
              v-model="chargeParkEditionData.owner_organisation_name"
              class="p-column-filter p-2 w-full input-disabled"
              :placeholder="$t('organisation.title')"
              disabled
              @keyup="validateInput('owner_organisation_name')"
            />
          </div>
          <div class="my-1">
            <InputText
              data-cy="panel-item-input"
              type="text"
              v-model="chargeParkDetails.name"
              class="p-column-filter p-2 w-full"
              :placeholder="$t('chargePark.siteName')"
              @keyup="validateInput('name')"
            />
            <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
              <small class="p-error">{{ error.$message }}</small>
            </div>
          </div>
          <div class="my-1">
            <InputText
              data-cy="panel-item-input"
              type="text"
              v-model="chargeParkDetails.operator_name"
              class="p-column-filter p-2 w-full"
              :placeholder="$t('chargePark.operatorName')"
              @keyup="validateInput('operator_name')"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.operator_name.$errors"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small>
            </div>
          </div>
          <div class="my-1">
            <Textarea
              data-cy="panel-item-textarea"
              class="border border-[var--gray-200] rounded w-full p-2"
              v-model="chargeParkDetails.operator_address"
              :placeholder="$t('chargePark.operatorAddress')"
              autoResize
              row="1"
              cols="18"
              @keyup="validateInput('operator_address')"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.operator_address.$errors"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small>
            </div>
          </div>
        </div>
        <ChargeParkInfoPreview v-if="!isEditing" :chargePark="chargeParkEditionData" />
      </div>
    </PanelWithEdit>
    <Toast
      data-cy="charge-park-info-toast"
      position="top-center"
      errorIcon="pi pi-info-circle"
      group="chargeParkInfo"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { chargeParkInfoRules } from '@/utils/chargeParkDetailsRules';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import ChargeParkInfoPreview from './ChargeParkInfoPreview.vue';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

const { t } = useI18n();
const toast = useToast();
const chargeParksStore = useChargeParksStore();
const { chargeParkEditionData } = storeToRefs(chargeParksStore);
const systemsStore = useHardwareSystemsStore();
const chargeParkDetails = ref({
  owner_organisation_name: chargeParkEditionData.value.owner_organisation_name,
  name: chargeParkEditionData.value.name,
  operator_name: chargeParkEditionData.value.operator_name,
  operator_address: chargeParkEditionData.value.operator_address,
});

const isLoading = ref(false);
const isEditing = ref(false);

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('chargePark.title'),
      action: t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'chargeParkInfo',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: t('toast.edition'),
      name: t('chargePark.title'),
    }),
    group: 'chargeParkInfo',
  });
};

const editFormIsNotValid = computed(() => {
  return (
    !v$.value.$anyDirty ||
    (v$.value.name.$anyDirty && v$.value.name.$error) ||
    (v$.value.operator_name.$anyDirty && v$.value.operator_name.$error) ||
    (v$.value.operator_address.$anyDirty && v$.value.operator_address.$error)
  );
});

const resetForm = () => {
  chargeParkDetails.value.name = chargeParkEditionData.value.name;
  chargeParkDetails.value.operator_name = chargeParkEditionData.value.operator_name;
  chargeParkDetails.value.operator_address = chargeParkEditionData.value.operator_address;
  v$.value.name.$reset();
  v$.value.operator_name.$reset();
  v$.value.operator_address.$reset();
  v$.value.$reset();
};

const closeEditing = () => {
  isEditing.value = false;
  resetForm();
};

const editSite = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { owner_organisation_name, ...data } = chargeParkDetails.value;
  chargeParksStore
    .editChargeParkDetails(chargeParkEditionData.value.id, data)
    .then((response: any) => {
      chargeParksStore.fetchChargePark(chargeParkEditionData.value.id).then((response) => {
        const { name, operator_name, operator_address } = response.data;
        chargeParkDetails.value.name = name;
        chargeParkDetails.value.operator_name = operator_name;
        chargeParkDetails.value.operator_address = operator_address;
      });
      if (response.status === 200) {
        systemsStore.fetchSystemsByChargeParkId(chargeParkEditionData.value.id);
        toast.removeGroup('chargeParkInfo');
        showSuccess();
        closeEditing();
      }
    })
    .catch((error) => {
      toast.removeGroup('chargeParkInfo');
      showError();
      throw new Error(error);
    });
};

const validateInput = async (item: string) => {
  await v$.value[item].$validate();
};

const rules = computed(() => chargeParkInfoRules);

const v$ = useVuelidate(rules, chargeParkDetails.value);
</script>
<style scoped lang="scss"></style>
