<template>
  <div class="string-input-container">
    <input
      type="text"
      v-model="updatedValue"
      :placeholder="defaultValue"
      :data-cy="`${id}-value-input`"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps<{
  id: string;
  defaultValue: number | string | boolean | undefined;
  valid: boolean;
}>();

const emit = defineEmits<{
  (event: 'update:value', value: number | string | boolean | undefined): void;
}>();

const defaultValue = props.defaultValue !== undefined ? props.defaultValue.toString() : '';
const updatedValue = ref(props.defaultValue);

watch(updatedValue, (value) => emit('update:value', value));
</script>

<style scoped lang="scss">
div.string-input-container {
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
}

input {
  width: 100%;
  padding: 0.25rem 0.5rem;
  border-radius: var(--rounded-md);
  text-align: right;
}
</style>
