<template>
  <Dialog
    :id="organisation.id"
    data-cy="organisation-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '665px' }"
    :modal="true"
    @hide="closeModal"
  >
    <template #header>
      <Button
        v-if="step === 2"
        data-cy="create-organisation-back-button"
        :label="t('back')"
        icon="pi pi-angle-left"
        class="p-button-sm p-button-white header-back-button"
        @click="step = 1"
      />
    </template>
    <div v-if="step === 1" data-cy="create-organisation-step-first" class="field-edit">
      <span v-if="!organisationIsEditing" class="text-sm text-zinc-600 hierarchy block mb-4">{{
        $t('organisation.hierarchy')
      }}</span>
      <div class="field flex flex-col">
        <label for="organisationName" class="text-sm font-medium mb-2 field-edit-label"
          >{{ $t('name') }}<span class="required">*</span></label
        >
        <InputText
          data-cy="organisation-name-input"
          id="organisationName"
          type="organisationName"
          aria-describedby="organisationName-help"
          class="p-inputtext-sm"
          v-model="organisation.name"
          :placeholder="$t('organisation.name')"
          @keyup="validateName"
        />
        <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
          <small class="p-error">{{ error.$message }}</small>
        </div>
      </div>
      <OrganisationParentPicker
        v-if="
          (organisationIsEditing &&
            organisationEditionData.parent_organisation_id !== null &&
            organisationIsEditing &&
            organisationEditionData.id !== me.organisation_id) ||
          !organisationIsEditing
        "
        @onParentSelect="changeParentOrganisation"
        aria-describedby="organisationParent-help"
      />
      <div
        class="input-errors"
        v-for="(error, index) of v$.parent_organisation_id.$errors"
        :key="index"
      >
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>
    <div v-if="step === 2" data-cy="create-organisation-step-second" class="flex flex-col">
      <div class="flex flex-col font-semibold field-no-edit">
        <div class="flex justify-between mb-3 text-grey-medium">
          <span>
            {{ $t('name') }}
          </span>
          <span>
            {{ organisation.name }}
          </span>
        </div>
        <div class="flex justify-between">
          <span>
            {{ $t('organisation.parent') }}
          </span>
          <span>
            {{ organisation.parent_organisation_name }}
          </span>
        </div>
      </div>
      <div class="field-edit">
        <span class="block title font-medium field-edit-label"
          >{{ $t('organisation.availableFeatures') }}<span class="required">*</span></span
        >
        <span class="block text-grey text-sm mb-2">{{
          $t('organisation.availableFeaturesInfo')
        }}</span>
        <MultiSelect
          v-model="organisation.available_features"
          :options="orgAvailableFeatures"
          :placeholder="$t('organisation.availableFeatures')"
          class="w-full md:w-20rem mb-2"
          :maxSelectedLabels="5"
          filter
          optionLabel="name"
          @filter="onFilterChange"
          @change="onSelectAllChange"
          @show="onShow"
          @hide="onHide"
        >
          <template #value="slotProps">
            <div v-if="isValueVisible && slotProps.value.length > 0">
              <span v-if="organisation.available_features.length <= 5">
                <span
                  v-for="(item, index) in slotProps.value"
                  :key="index"
                  class="inline-block mr-2"
                >
                  {{ item.name
                  }}{{ index + 1 !== organisation.available_features.length ? ',' : '' }}
                </span>
              </span>
              <span v-else-if="isValueVisible && organisation.available_features.length > 5">
                {{ organisation.available_features.length }} features selected
              </span>
            </div>
            <span v-else class="custom-value">{{ $t('organisation.availableFeatures') }}</span>
          </template>
          <template #option="slotProps">
            <span class="pr-4">{{ slotProps.option.name }}</span>
            <i
              class="pi pi-info-circle text-neutral-500 cursor-default"
              v-tooltip.right="{
                value: `
                  <span class='p-2'>${$t(
                    `organisation.featureDescription.${slotProps.option.id}`
                  )}</span>`,
                escape: true,
                class: 'custom-tooltip',
              }"
            ></i>
          </template>
        </MultiSelect>
        <div v-if="organisation.available_features.length > 0">
          <div
            v-for="(item, index) in organisation.available_features"
            :key="index"
            class="inline-block mr-2"
          >
            <div
              data-cy="organisation-feature"
              class="custom-chip cursor-pointer"
              @click="unselectedDisplayedFeature(item.id)"
            >
              <span>{{ item.name }}</span>
              <i class="pi pi-times text-xs font-bold"></i>
            </div>
          </div>
        </div>
      </div>
      <UserRolesPicker
        :organisationRoles="orgAvailableRoles"
        :id="organisation.id"
        :value="organisation.available_roles"
        :title="$t('user.role.availableRoles')"
        @onRolesSelect="setSelectedRoles"
        @onRolesUnselect="setUnselectedRoles"
        @onRolesSelectAll="setAllSelectedRoles"
        @onUnselectedAllRoles="setAllUnselectedRoles"
      />
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        v-if="
          organisationIsEditing &&
          organisationEditionData.parent_organisation_id &&
          step === 1 &&
          organisationEditionData.id !== me.organisation_id
        "
        data-cy="create-organisation-next-button"
        :label="$t('next')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 mt-5 block"
        :disabled="editFormIsNotValid"
        @click="onNextStep"
      />
      <Button
        v-else-if="
          (step === 1 &&
            organisationIsEditing &&
            !organisationEditionData.parent_organisation_id) ||
          organisationEditionData.id === me.organisation_id ||
          (step === 2 && organisationIsEditing)
        "
        data-cy="edit-organisation-button"
        :label="editLabel"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 mt-5 block"
        :disabled="editFormIsNotValid"
        @click="editOrganisation"
        type="submit"
        :loading="loading"
      />
      <Button
        v-else-if="!organisationIsEditing && step === 1"
        data-cy="create-organisation-next-button"
        :label="$t('next')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 mt-5 block"
        :disabled="formIsNotValid"
        @click="onNextStep"
      />
      <Button
        v-else-if="step === 2 && !organisationIsEditing"
        data-cy="create-organisation-button"
        :label="createLabel"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 mt-5 block"
        :disabled="formIsNotValid"
        @click="createOrganisation"
        type="submit"
        :loading="loading"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="organisation-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="organisation"
  />
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useOrganisationsStore } from '@/stores/admin/organizations/organisations.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, maxLength } from '@vuelidate/validators';
import { asciiCharacters } from '@/utils/validationRules';
import { CREATE, DEFAULT_TOAST_LIFE_MILLISECONDS, EDIT, MAX_LENGTH } from '@/utils/constants';

import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import OrganisationParentPicker from '@/components/organisations/OrganisationParentPicker.vue';
import Button from 'primevue/button';
import MultiSelect from 'primevue/multiselect';
import UserRolesPicker from '@/components/users/UserRolesPicker.vue';
import Toast from 'primevue/toast';

import type { ParentOrganisation } from '@/models/organisation.model';
import type { Feature } from '@/models/organisation.model';
import type { UserRole, UserRoleId } from '@/models/users.model';

const { t } = useI18n();
const store = useOrganisationsStore();
const {
  organisations,
  organisationModalIsOpen,
  organisationEditionData,
  organisationIsEditing,
  features,
} = storeToRefs(store);
const usersStore = useUsersStore();
const { me } = storeToRefs(usersStore);
const toast = useToast();
const loading = ref(false);
const isVisible = ref(false);
const step = ref(1);
const filteredFeatures = ref<Feature[]>([]);
const isValueVisible = ref(false);
const organisation = ref({
  id: 0,
  name: '',
  parent_organisation_id: null as null | number,
  parent_organisation_name: '',
  available_features: [] as Feature[],
  available_roles: [] as UserRoleId[],
});
const orgAvailableFeatures = ref();
const orgAvailableRoles = ref();
const orgSelectedFeatures = computed(() =>
  organisation.value.available_features.map((feature) => feature.id)
);
const orgSelectedRoles = computed(() => organisation.value.available_roles.map((role) => role.id));

const unselectedDisplayedFeature = (id: number) => {
  const rolePos = organisation.value.available_features.findIndex((item) => item.id === id);

  organisation.value.available_features.splice(rolePos, 1);

  if (organisation.value.available_features.length === 0) {
    v$.value.available_features.$reset();
  } else {
    v$.value.available_features.$touch();
  }
};

const onFilterChange = (data: any) => {
  if (data.value !== '') {
    const text = data.value.toLowerCase();

    filteredFeatures.value = organisation.value.available_features.filter(
      (feature) => feature.name.toLocaleLowerCase().indexOf(text) !== -1
    );
  } else {
    filteredFeatures.value = [];
  }
};

const onSelectAllChange = (event: any) => {
  if (filteredFeatures.value.length > 0 && event.value.length === 0) {
    const filterFeat = filteredFeatures.value.map((item) => item.id);
    const normalizedFeatures = features.value.filter(
      (orgFeature) => !filterFeat.includes(orgFeature.id)
    );
    organisation.value.available_features = normalizedFeatures;
  }
};

const setSelectedRoles = (role: UserRoleId) => {
  organisation.value.available_roles.push(role);
  v$.value.available_roles.$touch();
};

const setUnselectedRoles = (role: UserRoleId) => {
  const rolePos = organisation.value.available_roles.findIndex((item) => item.id === role.id);
  organisation.value.available_roles.splice(rolePos, 1);
  if (organisation.value.available_roles.length === 0) {
    v$.value.available_roles.$reset();
  } else {
    v$.value.available_roles.$touch();
  }
};

const setAllSelectedRoles = (roles: UserRoleId[]) => {
  organisation.value.available_roles = roles;
  v$.value.available_roles.$touch();
};

const setAllUnselectedRoles = () => {
  organisation.value.available_roles = [];
  v$.value.available_roles.$reset();
};

const onShow = () => (isValueVisible.value = true);
const onHide = () => (isValueVisible.value = false);
const onNextStep = () => {
  organisation.value.available_features = organisation.value.available_features.filter((feature) =>
    orgAvailableFeatures.value.some((orgFeature: Feature) => feature.id === orgFeature.id)
  );
  organisation.value.available_roles = organisation.value.available_roles.filter((role) =>
    orgAvailableRoles.value.some((orgRole: UserRole) => role.id === orgRole.id)
  );
  step.value = 2;
};

const formIsNotValid = computed(() => {
  return (
    v$.value.$error ||
    organisation.value.name === '' ||
    organisation.value.parent_organisation_id === null ||
    (step.value === 2 && organisation.value.available_roles.length === 0) ||
    (step.value === 2 && organisation.value.available_features.length === 0)
  );
});

const editFormIsNotValid = computed(() => {
  if (
    step.value === 1 &&
    organisationIsEditing.value &&
    organisationEditionData.value.parent_organisation_id &&
    organisationEditionData.value.id !== me.value.organisation_id
  ) {
    return (
      (v$.value.$anyDirty && v$.value.name.$error) ||
      (organisation.value.id !== 1 && organisation.value.parent_organisation_id === null)
    );
  } else if (
    step.value === 1 &&
    organisationIsEditing.value &&
    (organisationEditionData.value.parent_organisation_id === null ||
      organisationEditionData.value.id === me.value.organisation_id)
  ) {
    return !v$.value.$anyDirty || v$.value.name.$error;
  } else {
    return (
      !v$.value.$anyDirty ||
      organisation.value.available_roles.length === 0 ||
      organisation.value.available_features.length === 0
    );
  }
});

const changeParentOrganisation = (parentData: ParentOrganisation) => {
  v$.value.parent_organisation_id.$touch();
  organisation.value.parent_organisation_id = parentData.id;
  organisation.value.parent_organisation_name = parentData.name;
  organisationEditionData.value.parent_organisation_id = parentData.id;

  const parentOrgFeatures = organisations.value.find(
    (org) => org.id === parentData.id && org.name === parentData.name
  )?.available_features;

  const parentOrgRoles = organisations.value.find(
    (org) => org.id === parentData.id && org.name === parentData.name
  )?.available_roles;

  orgAvailableFeatures.value = parentOrgFeatures;
  orgAvailableRoles.value = parentOrgRoles;
};

const modalTitle = computed(() =>
  organisationIsEditing.value
    ? t('edit', { itemName: t('organisation.title') })
    : t('create', { itemName: t('organisation.title') })
);

const createLabel = computed(() => (v$.value.$error ? t('error') : t('apply')));

const editLabel = computed(() => {
  return v$.value.$error && editFormIsNotValid.value ? t('error') : t('apply');
});

const closeModal = () => {
  organisationModalIsOpen.value = false;
  loading.value = false;
  step.value = 1;
  resetForm();

  if (organisationIsEditing.value) {
    organisationIsEditing.value = false;
  }
};

const showSuccess = (actionName: string) => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('organisation.title'),
      action: actionName === CREATE ? t('toast.created') : t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'organisation',
  });
};

const showError = (actionName: string) => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: actionName === CREATE ? t('toast.creation') : t('toast.edition'),
      name: t('organisation.title'),
    }),
    group: 'organisation',
  });
};

const showCustomError = (errorCode: string) => {
  toast.add({
    severity: 'error',
    summary: t(`toast.${errorCode}`),
    group: 'organisation',
  });
};

const createOrganisation = async () => {
  await v$.value.$validate();

  if (v$.value.$error) return;

  const data = {
    name: organisation.value.name,
    parent_organisation_id: organisation.value.parent_organisation_id,
    available_features: orgSelectedFeatures.value,
    available_roles: orgSelectedRoles.value,
  };

  loading.value = true;

  await store
    .createNewOrganisation(data)
    .then((response: any) => {
      loading.value = false;
      if (response.status === 201) {
        toast.removeGroup('organisation');
        showSuccess(CREATE);
        closeModal();
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('organisation');
      showError(CREATE);
      throw new Error(error);
    });
};

const editOrganisation = async () => {
  await v$.value.name.$validate();

  if (v$.value.name.$error) return;

  const data = {
    name: organisation.value.name,
    parent_organisation_id: organisation.value.parent_organisation_id,
    available_features: orgSelectedFeatures.value,
    available_roles: orgSelectedRoles.value,
  };

  const ownOrgData = {
    name: organisation.value.name,
    parent_organisation_id: organisation.value.parent_organisation_id,
  };

  const editData = organisation.value.id === me.value.organisation_id ? ownOrgData : data;

  loading.value = true;

  await store
    .updateOrganisation(editData)
    .then((response: any) => {
      loading.value = false;
      if (response.status === 200) {
        toast.removeGroup('organisation');
        usersStore.fetchUserData();
        showSuccess(EDIT);
        closeModal();
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('organisation');
      if (error.response && error.response.status === 400 && error.response.data.error_details) {
        showCustomError(error.response.data.error_details.error_code);
      } else {
        showError(EDIT);
      }
      throw new Error(error);
    });
};

const resetForm = () => {
  store.resetOrganisationEditionData();
  organisation.value.name = '';
  organisation.value.parent_organisation_id = null;
  organisation.value.available_features = [];
  organisation.value.available_roles = [];
  v$.value.name.$reset();
  v$.value.parent_organisation_id.$reset();
  v$.value.$reset();
};

watch(
  () => store.organisationEditionData.id,
  (id) => {
    if (id !== 0) {
      organisation.value.id = store.organisationEditionData.id;
      organisation.value.parent_organisation_id =
        store.organisationEditionData.parent_organisation_id;
    }

    if (store.organisationEditionData.available_features.length > 0) {
      organisation.value.available_features = store.organisationEditionData.available_features;
    }

    if (store.organisationEditionData.available_roles.length > 0) {
      organisation.value.available_roles = store.organisationEditionData.available_roles;
    }
  }
);

watch(
  () => store.organisationEditionData.name,
  (name) => {
    if (name !== '') {
      organisation.value.name = store.organisationEditionData.name;
      organisation.value.parent_organisation_name =
        store.organisationEditionData.parent_organisation_name;
    }
  }
);

watch(
  () => store.organisationIsEditing,
  (value) => {
    if (value) {
      const parentOrgFeatures = organisations.value.find(
        (org) =>
          org.id === store.organisationEditionData.parent_organisation_id &&
          org.name === store.organisationEditionData.parent_organisation_name
      )?.available_features;

      orgAvailableFeatures.value = parentOrgFeatures;

      const parentOrgRoles = organisations.value.find(
        (org) =>
          org.id === store.organisationEditionData.parent_organisation_id &&
          org.name === store.organisationEditionData.parent_organisation_name
      )?.available_roles;

      orgAvailableRoles.value = parentOrgRoles;
    }
  }
);

watch(
  () => organisationModalIsOpen.value,
  (value) => {
    if (value) {
      isVisible.value = true;
    } else {
      isVisible.value = false;
    }
  }
);

watch(
  () => organisation.value.available_features,
  (oldFeatures, newFeatures) => {
    if (step.value === 2 && oldFeatures.length !== newFeatures.length) {
      v$.value.available_features.$touch();
    }
  }
);

const rules = computed(() => {
  return {
    name: {
      required: helpers.withMessage(
        t('rules.required', {
          field: t('name').toLocaleLowerCase(),
          item: t('organisation.title').toLocaleLowerCase(),
        }),
        required
      ),
      asciiCharacters: helpers.withMessage(
        t('rules.asciiCharacters', { field: t('organisation.title') }),
        asciiCharacters
      ),
      maxLength: maxLength(MAX_LENGTH),
    },
    parent_organisation_id: {
      required: helpers.withMessage(t('rules.parentRequired'), required),
    },
    available_features: {
      required: helpers.withMessage(
        t('rules.required', {
          field: t('user.role.availableRoles').toLocaleLowerCase(),
          item: t('organisation.title').toLocaleLowerCase(),
        }),
        required
      ),
    },
    available_roles: {
      required: helpers.withMessage(
        t('rules.required', {
          field: t('organisation.availableFeatures').toLocaleLowerCase(),
          item: t('organisation.title').toLocaleLowerCase(),
        }),
        required
      ),
    },
  };
});

const v$ = useVuelidate(rules, organisation.value);

const validateName = async () => {
  await v$.value.name.$validate();
};

onMounted(async () => {
  await store.fetchFeaturesList().then(() => (loading.value = false));
});
</script>
<style lang="scss" scoped>
.header-back-button {
  padding-left: 6px !important;
  border-radius: 5px !important;
}

:deep(.p-multiselect .p-multiselect-label.p-placeholder) {
  padding: 10px 16px;
  color: $text-light;
  font-size: 14px;
}

:deep(.p-inputwrapper-filled.p-multiselect .p-multiselect-label) {
  padding: 10px 16px;
  color: $text-light;
  font-size: 14px;
}
</style>
