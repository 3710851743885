<template>
  <Dialog
    data-cy="transfer-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '670px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="flex field-no-edit" :style="transferInfoStyle">
      <i :class="`pi ${messageIcon} mr-3 self-center`" />
      <span class="transfer-info" >{{ transferInfo }}</span>
    </div>
    <HardwareSystemReadOnly :data="systemTransferData" />
    <OrganisationPicker
      v-if="actionType === DELEGATE"
      @onOrganisationChange="setSelectedOrganisation"
    />
    <ChargeParkPicker
      v-else-if="actionType == ASSIGN"
      @onChargeParkSelect="setSelectedChargePark"
      selectMode="single"
    />
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        v-if="actionType === DELEGATE"
        data-cy="delegate-transfer-button"
        :label="t('apply')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        :disabled="delegateFormIsNotValid"
        :loading="loading"
        @click="delegateCharger"
      />
      <Button
        v-else-if="actionType === UNASSIGN"
        data-cy="unassign-transfer-button"
        :label="t('hardwareSystem.unassign')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5 bg-red-700"
        :loading="loading"
        @click="unassignChargePark"
      />
      <Button
        v-else
        data-cy="assign-transfer-button"
        :label="t('apply')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        :disabled="assignFormIsNotValid"
        :loading="loading"
        @click="assignChargePark"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="hardware-system-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="hardware-systems"
  />
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { ASSIGN,UNASSIGN, DEFAULT_TOAST_LIFE_MILLISECONDS, DELEGATE } from '@/utils/constants';
import { required, helpers } from '@vuelidate/validators';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import OrganisationPicker from '../organisations/OrganisationPicker.vue';
import HardwareSystemReadOnly from './HardwareSystemReadOnly.vue';
import ChargeParkPicker from '../chargeParks/ChargeParkPicker.vue';

const { t } = useI18n();
const hardwareSystemsStore = useHardwareSystemsStore();
const { systemModalIsOpen, systemTransferData, actionType } = storeToRefs(hardwareSystemsStore);
const toast = useToast();
const isVisible = ref(false);
const loading = ref(false);
const hardwareSystem = ref({
  id: 0,
  nidec_id: '',
  name: '',
  custom_id: '',
  owner_organisation_id: 0,
  owner_organisation_name: '',
  charge_park_id: 0,
  charge_park_name: '',
});
const transferInfoStyle = computed(() => {
  switch(actionType.value){
    case DELEGATE:
      return 'color: gray;'
    case UNASSIGN:
      return 'color: orange;'
    default:
      return 'color: gray;'
  }
})
const messageIcon  = computed(() => {
  switch(actionType.value){
    case UNASSIGN:
      return "pi-exclamation-triangle";
    default:
      return "pi-info-circle";
  }
});

const modalTitle = computed(() => {
  switch(actionType.value){
    case DELEGATE:
      return t('hardwareSystem.delegateTo');
    case UNASSIGN:
      return t('hardwareSystem.unassignFromSite');
    default:
      return t('hardwareSystem.assignTo');
  }
});
const transferInfo = computed(() => {
  switch(actionType.value){
    case DELEGATE:
      return t('hardwareSystem.delegateInfo');
    case UNASSIGN:
      return t('hardwareSystem.unassignWarning');
    default:
      return t('hardwareSystem.assignInfo');
  }
});

const resetForm = () => {
  hardwareSystemsStore.resetHardwareSystemEditData();
  hardwareSystem.value.owner_organisation_id = 0;
  hardwareSystem.value.charge_park_id = 0;
  v$.value.owner_organisation_id.$reset();
  v$.value.charge_park_id.$reset();
};

const closeModal = () => {
  systemModalIsOpen.value = false;
  loading.value = false;

  resetForm();
};

const delegateFormIsNotValid = computed(() => !v$.value.owner_organisation_id.$anyDirty);


const assignFormIsNotValid = computed(() => !v$.value.charge_park_id.$anyDirty);

const setSelectedOrganisation = (organisation: any) => {
  hardwareSystem.value.owner_organisation_id = organisation.id;
  hardwareSystem.value.owner_organisation_name = organisation.name;
  v$.value.owner_organisation_id.$touch();
};

const setSelectedChargePark = (chargePark: any) => {
  hardwareSystem.value.charge_park_id = chargePark.id;
  hardwareSystem.value.charge_park_name = chargePark.name;
  v$.value.charge_park_id.$touch();
};

const showSuccess = (actionName: string) => {
  let actionMsg = `${t('toast.delegated')} to ${hardwareSystem.value.owner_organisation_name}`;

  switch(actionName){
    case ASSIGN:
      actionMsg = `${t('toast.assigned')} to ${hardwareSystem.value.charge_park_name}`;
      break;
    case UNASSIGN:
      actionMsg = `${t('toast.unassigned')} from ${hardwareSystem.value.charge_park_name}`;
      break;
  }
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('hardwareSystem.charger'),
      action:actionMsg
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'hardware-systems',
  });
};

const showError = (actionName: string) => {
  let actionMsg = t('toast.delegation');

  switch(actionName){
    case ASSIGN:
    actionMsg = t('toast.assignment');
      break;
    case UNASSIGN:
    actionMsg = t('toast.unassignment');
      break;
  }
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: actionMsg,
      name: t('hardwareSystem.charger'),
    }),
    group: 'hardware-systems',
  });
};

const delegateCharger = async () => {
  await v$.value.owner_organisation_id.$validate();

  if (v$.value.owner_organisation_id.$error) return;

  loading.value = true;

  await hardwareSystemsStore
    .delegateSystemToOrganisation(
      hardwareSystem.value.id,
      hardwareSystem.value.owner_organisation_id
    )
    .then((response: any) => {
      loading.value = false;

      if (response.status === 200) {
        toast.removeGroup('hardware-systems');
        showSuccess(DELEGATE);
        closeModal();
      }
    })
    .catch((error) => {
      toast.removeGroup('hardware-systems');
      showError(DELEGATE);
      throw new Error(error);
    });
};

const assignChargePark = async () => {
  await v$.value.charge_park_id.$validate();

  if (v$.value.charge_park_id.$error) return;

  loading.value = true;

  await hardwareSystemsStore
    .assignChargeParkToSystem(hardwareSystem.value.id, hardwareSystem.value.charge_park_id)
    .then((response: any) => {
      loading.value = false;

      if (response.status === 200) {
        toast.removeGroup('hardware-systems');
        showSuccess(ASSIGN);
        closeModal();
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('hardware-systems');
      showError(ASSIGN);
      throw new Error(error);
    });
};
const unassignChargePark = async () => {
  loading.value = true;

  await hardwareSystemsStore
    .unassignChargeParkFromSystem(hardwareSystem.value.id)
    .then((response: any) => {
      loading.value = false;

      if (response.status === 200) {
        toast.removeGroup('hardware-systems');
        showSuccess(UNASSIGN);
        closeModal();
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('hardware-systems');
      showError(UNASSIGN);
      throw new Error(error);
    });
};
watch(
  () => systemModalIsOpen.value,
  (value) => {
    isVisible.value = value;
  }
);

watch(
  () => hardwareSystemsStore.systemTransferData,
  (value) => {
    if (value.id) {
      hardwareSystem.value.id = value.id;
      hardwareSystem.value.nidec_id = value.nidec_id;
      hardwareSystem.value.name = value.name;
      hardwareSystem.value.custom_id = value.custom_id;
      hardwareSystem.value.owner_organisation_id = value.owner_organisation_id;
      hardwareSystem.value.owner_organisation_name = value.owner_organisation_name;
      hardwareSystem.value.charge_park_id = value.charge_park_id;
      hardwareSystem.value.charge_park_name = value.charge_park_name;
    }
  }
);

const rules = computed(() => {
  return {
    owner_organisation_id: {
      required: helpers.withMessage(t('rules.parentRequired'), required),
    },
    charge_park_id: {
      required: helpers.withMessage(t('rules.chargeParkRequired'), required),
    },
  };
});

const v$ = useVuelidate(rules, hardwareSystem.value);
</script>
<style lang="scss" scoped>
.pi-info-circle {
  font-size: 12px;
}
.transfer-info {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
</style>
