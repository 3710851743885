<template>
  <Dialog
    data-cy="downtime-delete-modal"
    :header="modalTitle"
    v-model:visible="downtimeDeleteModalIsOpen"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="field flex flex-col field-edit">
      <Message severity="error" icon="pi pi-exclamation-triangle" :closable="false">
        {{ $t('deleteWarning') }}
      </Message>
      <p class="delete-info">
        {{ $t('downtimes.deleteInfo') }}
      </p>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        data-cy="downtime-delete-button"
        class="p-button-sm p-button-footer p-button-error mx-auto mb-2 block mt-5"
        :label="t('delete')"
        :loading="loading"
        @click="deleteDowntime"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="downtime-delete-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="delete-downtime"
  >
    <template v-if="isFailed" #message="slotProps">
      <div class="flex flex-col w-full pt-1">
        <div class="w-full flex">
          <span class="p-toast-message-icon pi pi-info-circle"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
          </div>
        </div>
      </div>
    </template>
  </Toast>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Message from 'primevue/message';
import Toast from 'primevue/toast';

import { useDowntimesStore } from '@/stores/admin/downtimes/downtimes.store';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

const { t } = useI18n();
const toast = useToast();
const store = useDowntimesStore();
const { downtimeDeleteModalIsOpen, downtimeDeleteId } = storeToRefs(store);

const isFailed = ref(false);
const loading = ref(false);

const modalTitle = computed(() => t('delete', { itemName: t('downtimes.downtime') }));

const successSummary = computed(() => {
  return t('toast.success', {
    name: t('downtimes.downtime'),
    action: t('toast.deleted'),
  });
});

const errorSummary = computed(() => {
  return t('toast.error', {
    action: t('toast.removal'),
    name: t('downtimes.downtime'),
  });
});

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: successSummary.value,
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'delete-downtime',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: errorSummary.value,
    group: 'delete-downtime',
  });
};

const closeModal = () => {
  downtimeDeleteModalIsOpen.value = false;
  downtimeDeleteId.value = null;
  loading.value = false;
};

const deleteDowntime = async () => {
  loading.value = true;
  toast.removeGroup('delete-downtime');

  try {
    await store.deleteDowntime();
    toast.removeGroup('delete-downtime');
    showSuccess();
    closeModal();
    await store.fetchDowntimeList();
  } catch (error: any) {
    loading.value = false;
    toast.removeGroup('delete-downtime');
    if (error.response && error.response.status === 400 && error.response.data.failed_items) {
      isFailed.value = true;
      showError();
      closeModal();
    } else {
      showError();
    }
    throw new Error(error);
  } finally {
    loading.value = false;
  }
};
</script>
