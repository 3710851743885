import type { ChargePark } from '@/models/chargeParks.model';
import type { HardwareSystemFull } from '@/models/hardwareSystems.model';
import type { ProductType } from '@/models/productTypes.model';

import { fetchChargeParks } from '@/stores/admin/chargeParks/chargeParks.api';
import { fetchHardwareSystems } from '@/stores/admin/hardwareSystems/hardwareSystems.api';
import { fetchProductTypes } from '@/stores/admin/productTypes/productTypes.api';
import { isNumberTelemetryChannel } from '@/utils/telemetry';

function nameOf<T extends { name: string | undefined }>(entity: T | undefined): string {
  if (!entity) return 'Unknown';
  return entity.name ?? 'Unnamed';
}

export class WidgetContextResolver {
  private chargeParks: ChargePark[] = [];
  private systems: HardwareSystemFull[] = [];
  private productTypes: ProductType[] = [];

  private isInitialized = false;
  private isCurrentlyInitializing = false;
  public initError: Error | null = null;

  public static readonly resolvableEntities = ['producttype', 'system', 'chargepark'];

  constructor() {}

  public async init(opts?: { force?: boolean }): Promise<void> {
    while (this.isCurrentlyInitializing) await new Promise((resolve) => setTimeout(resolve, 100));

    if (!this.isInitialized || opts?.force) {
      this.isCurrentlyInitializing = true;
      await Promise.all([fetchChargeParks(), fetchHardwareSystems(), fetchProductTypes()])
        .then(([chargeParks, systems, productTypes]) => {
          this.chargeParks = chargeParks.data;
          this.systems = systems.data;
          this.productTypes = productTypes.data;
        })
        .catch((e) => {
          this.initError = e;
        })
        .finally(() => {
          this.isInitialized = true;
          this.isCurrentlyInitializing = false;
        });
    }
  }

  public resolveEntityNameById(path: string, id: string): string {
    if (path.startsWith('chargepark')) {
      return nameOf(this.chargeParks.find((chargePark) => String(chargePark.id) === id));
    }
    if (path.startsWith('system')) {
      return nameOf(this.systems.find((system) => String(system.id) === id));
    }
    if (path.startsWith('producttype')) {
      return nameOf(this.productTypes.find((productType) => String(productType.id) === id));
    }
    return 'Unknown';
  }

  public getResolverFor(path?: string): ((id: string) => string) | undefined {
    if (!path) return undefined;
    if (!WidgetContextResolver.resolvableEntities.some((entity) => path.startsWith(entity)))
      return undefined;
    return (id: string) => this.resolveEntityNameById(path, id);
  }

  public getAssets(path?: string, ids: (string | number)[] = []) {
    const locations: Record<string, { lat?: number; lng?: number; id: string; name?: string }> = {};

    if (path?.startsWith('chargepark')) {
      ids.forEach((id) => {
        const chargePark = this.chargeParks.find((park) => String(park.id) === String(id));
        if (chargePark) {
          locations[id] = {
            id: String(chargePark.id),
            name: chargePark.name,
            lat: chargePark.latitude ? +chargePark.latitude : undefined,
            lng: chargePark.longitude ? +chargePark.longitude : undefined,
          };
        }
      });
    }

    if (path?.startsWith('system')) {
      ids.forEach((id) => {
        const system = this.systems.find((system) => String(system.id) === String(id));
        if (system) {
          locations[id] = {
            id: String(system.id),
            name: system.name,
            lat: system.latitude ? +system.latitude : undefined,
            lng: system.longitude ? +system.longitude : undefined,
          };
        }
      });
    }

    return locations;
  }

  public resolveUnit(productTypeId: ProductType['id'], channel: number): string {
    if (isNaN(channel)) return '';
    const productType = this.productTypes.find((productType) => productType.id === productTypeId);
    if (!productType) return '';
    const channelConfig = productType.properties.channels?.[channel];
    if (channelConfig && isNumberTelemetryChannel(channelConfig)) return channelConfig?.unit || '';
    return '';
  }
}
