import { defineStore } from 'pinia';
import type { RfidTag, RfidTagEdit, RfidTagEditData, RfidTagDelete } from '@/models/rfidTags.model';
import {
  fetchRfidTags,
  createRfidTag,
  editRfidTag,
  removeRfidTag,
  fetchFilteredRfidTags,
} from '@/stores/admin/rfidTags/rfidTags.api';

interface RfidTagsState {
  tags: RfidTag[];
  rfidTagModalIsOpen: Boolean;
  rfidTagEditionData: RfidTagEditData;
  rfidTagIsEditing: Boolean;
  rfidTagDeleteModalIsOpen: Boolean;
  rfidTagDeletionIds: RfidTagDelete[];
  filteredTags: RfidTag[];
}

export const useRfidTagsStore = defineStore('tags', {
  state: (): RfidTagsState => ({
    tags: [],
    rfidTagModalIsOpen: false,
    rfidTagEditionData: {
      id: 0,
      rfid_tag: '',
      username: '',
      state: true,
    },
    rfidTagIsEditing: false,
    rfidTagDeleteModalIsOpen: false,
    rfidTagDeletionIds: [],
    filteredTags: [],
  }),
  actions: {
    async fetchRfidTagsList() {
      return await fetchRfidTags()
        .then((response) => {
          this.tags = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async createNewRfidTag(data: RfidTagEdit) {
      return await createRfidTag(data)
        .then((response) => {
          this.fetchRfidTagsList();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async updateRfidTag(data: RfidTagEditData[]) {
      return await editRfidTag(data)
        .then((response) => {
          this.fetchRfidTagsList();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async deleteRfidTag(id: RfidTagDelete[]) {
      return await removeRfidTag(id)
        .then((response) => {
          this.fetchRfidTagsList();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchRfidTagsForWhitelist(id: number) {
      return await fetchFilteredRfidTags(id)
        .then((response) => {
          this.filteredTags = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    resetRfidTagEditionData() {
      this.rfidTagEditionData = {
        id: 0,
        rfid_tag: '',
        username: '',
        state: true,
      };
    },
  },
});
