<template>
  <div class="summary-container">
    <div v-for="item in props.items" :key="item.name" class="summary-item">
      <div v-if="item.color" class="circle" :style="{ backgroundColor: item.color }" />
      <p class="summary-item-name">{{ item.name }}</p>
      <p class="summary-item-value">{{ item.value }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  items: { name: string; value: string | number | Date; color?: string }[];
}>();
</script>

<style scoped lang="scss">
.summary-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-content: space-around;
  border-bottom: 1px solid var(--gray-200);
  overflow: hidden;
}

.summary-item {
  display: flex;
  padding: 0.75rem 1rem;
  margin: 0 -1px -1px 0;
  border-right: 1px solid var(--gray-200);
  border-bottom: 1px solid var(--gray-200);
  align-items: center;
  overflow: hidden;
}

div.circle {
  min-width: 1rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  border: 1px solid var(--gray-200);
}

p.summary-item-name {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--gray-700);
}
</style>
