import { Feature, Role, roleSuperSet } from '@/utils/constants';

function roleIsAllowed(role: Role, userRoles: Role[]): boolean {
  const userHasExactRole = userRoles.includes(role);
  const otherRolesThatContainRequiredRole = roleSuperSet[role] ?? [];
  const userHasSuperRole = otherRolesThatContainRequiredRole.some((superRole) =>
    userRoles.includes(superRole)
  );

  return userHasExactRole || userHasSuperRole;
}

export function accessPermitted(
  requiredFeature: Feature | null,
  allowedRoles: Role[],
  userFeatures: Feature[],
  userRoles: Role[]
): boolean {
  const userHasFeatureAccess =
    requiredFeature !== null ? userFeatures.includes(requiredFeature) : true;

  const userIsAdministrator = userRoles.includes(Role.ADMINISTRATOR);
  const userHasSpecificRoles =
    allowedRoles.length == 0 || allowedRoles.some((role) => roleIsAllowed(role, userRoles));
  const userHasRoleAccess = userIsAdministrator || userHasSpecificRoles;

  return userHasFeatureAccess && userHasRoleAccess;
}
