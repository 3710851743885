import type { AxiosPromise, AxiosResponse } from 'axios';
import axios from 'axios';
import type { AlertBatchEditData, AlertEditDataSend } from '@/models/alerts.model';
import type { ID } from '@/models/common.model';

export async function fetchInvocations(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/alert-invocations/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchAlertSeverities(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/alert-severities/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export function fetchFilteredAlerts(
  startTime: string | undefined,
  currentTime: string | undefined,
  severity: number[]
) {
  if (severity.length > 0 && !startTime && !currentTime) {
    return axios.get(`/api/alert-invocations/?severity=${severity}`);
  }
  if (severity.length === 0 && startTime && currentTime) {
    return axios.get(`/api/alert-invocations/?start_time=${startTime}&end_time=${currentTime}`);
  }
  return axios.get(
    `/api/alert-invocations/?start_time=${startTime}&end_time=${currentTime}&severity=${severity}`
  );
}

export async function fetchAlerts(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/alerts/`);
  } catch (error) {
    return await Promise.reject(error);
  }
}

export async function fetchAlertTemplates(): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/alert-templates/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createAlertRequest(data: AlertEditDataSend): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/alerts/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function editAlertRequest(
  id: number,
  data: AlertEditDataSend
): Promise<AxiosResponse> {
  return axios
    .patch<AxiosPromise>(`/api/alerts/${id}/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function batchEditAlertRequest(data: AlertBatchEditData): Promise<AxiosResponse> {
  return axios
    .patch<AxiosPromise>(`/api/alerts/batch/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function deleteAlertRequest(id: number): Promise<AxiosResponse> {
  return axios
    .delete<AxiosPromise>(`/api/alerts/${id}/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function batchDeleteAlertRequest(ids: ID[]): Promise<AxiosResponse> {
  return axios
    .delete<AxiosPromise>(`/api/alerts/batch/`, { data: { items: ids } })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}
