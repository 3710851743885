<template>
  <img :src="iconPath" :alt="countryIso || ''" />
</template>

<script lang="ts">
import { computed } from 'vue';
import type { PropType } from 'vue';
import { countryIconsAvailable } from '@/utils/countriesIso';

export default {
  props: {
    countryIso: {
      type: String as PropType<string | null>,
      required: false,
      default: 'WORLD',
    },
  },
  setup(props) {
    const iconPath = computed(() => {
      if (props.countryIso && countryIconsAvailable.includes(props.countryIso)) {
        return `../assets/icons/countries/${props.countryIso}.svg`;
      } else {
        return `../assets/icons/countries/WORLD.svg`;
      }
    });

    return {
      iconPath,
      countryIconsAvailable: countryIconsAvailable,
    };
  },
};
</script>

<style scoped lang="scss">
.svg-icon-container :deep(svg) {
  display: block;
}
</style>
