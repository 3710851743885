<template>
  <div class="px-12">
    <div class="field flex flex-col pt-4 pb-6">
      <label id="downtimeReasonLabel" for="downtimeReason" class="title font-medium text-gray-600">
        {{ $t('downtimes.reason') }}<span class="required">*</span>
      </label>
      <InputText
        data-cy="downtime-reason-input"
        id="downtimeReason"
        type="text"
        aria-describedby="downtimeReasonLabel"
        class="p-inputtext-sm"
        v-model="downtimeModalData.reason"
      />
      <div class="input-errors" v-for="(error, index) of v$.reason.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>
    <div class="field flex flex-col pb-4">
      <label id="downtimeReasonLabel" for="downtimeReason" class="title font-medium text-gray-600">
        {{ $t('downtimes.downtime') }}<span class="required">*</span>
      </label>
      <span class="block text-grey text-sm">{{
        $t('downtimes.downtimeInfo', { userTimezone: prettifyTimezone(me.local_timezone) })
      }}</span>
    </div>
    <DateTimeRangeInput
      :startedValueId="'downtime-calendar-start-time'"
      :endedValueId="'downtime-calendar-end-time'"
      :startedValueLocale="$t('downtimes.startTime')"
      :endedValueLocale="$t('downtimes.endTime')"
      :startedAfterEndedErrorLocale="$t('errors.startDatetimeLessThanEndDatetime')"
      v-model:startedValue="downtimeModalData.start_time"
      v-model:endedValue="downtimeModalData.end_time"
    />
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { useDowntimesStore } from '@/stores/admin/downtimes/downtimes.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { storeToRefs } from 'pinia';
import { prettifyTimezone } from '@/utils/timezones';

import InputText from 'primevue/inputtext';
import DateTimeRangeInput from '@/components/common/time/DateTimeRangeInput.vue';

import type { downtimeRules } from '@/utils/downtimeModalRules';
import type { Validation } from '@vuelidate/core';

const downtimesStore = useDowntimesStore();
const { downtimeModalData } = storeToRefs(downtimesStore);
const userStore = useUsersStore();
const { me } = storeToRefs(userStore);

const props = defineProps<{
  v$: Validation<typeof downtimeRules>;
}>();

watch(
  () => downtimeModalData.value.reason,
  () => {
    props.v$.reason.$touch();
  },
  { deep: true }
);
</script>

<style scoped lang="scss">
:deep(.p-calendar .p-inputtext) {
  width: 100%;
  flex: none;
  padding: 0.3rem 0.5rem;
}

:deep(input::placeholder) {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}
</style>
