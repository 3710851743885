<template>
  <Dialog
    data-cy="alert-delete-modal"
    header="Are You Sure"
    :visible="props.open"
    @update:visible="() => emit('canceled')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
  >
    <div class="field flex flex-col field-edit">
      <Message severity="error" icon=" pi pi-exclamation-triangle" :closable="false">
        {{ $t('deleteWarning') }}
      </Message>
      <p class="delete-info">
        {{ $t('alert.deleteInfo') }}
      </p>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="() => emit('canceled')"
      />
      <Button
        data-cy="alert-delete-button"
        class="p-button-sm p-button-footer p-button-error mx-auto mb-2 block mt-5"
        :label="t('delete')"
        :loading="isDeleting"
        @click="deleteBranding"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="alert-delete-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="delete-alert"
  />
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Message from 'primevue/message';
import Toast from 'primevue/toast';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { deleteBrandingConfiguration } from '@/stores/admin/branding/ui.api';
import type { FullBrandingConfiguration } from '@/stores/admin/branding/branding.types';

const { t } = useI18n();

const isDeleting = ref(false);
const deletionError = ref<string | null>(null);

async function deleteBranding() {
  isDeleting.value = true;
  await deleteBrandingConfiguration(props.branding.id)
    .then(() => emit('deleted'))
    .catch((e) => (deletionError.value = e.message));
}

const props = defineProps<{
  open: boolean;
  branding: FullBrandingConfiguration;
}>();

const emit = defineEmits<{
  (event: 'canceled'): void;
  (event: 'deleted'): void;
}>();
</script>

<style scoped lang="scss"></style>
