<template>
  <data-table
    :value="props.logFiles"
    v-model:filters="filters"
    data-key="filename"
    class="log-table"
    scrollable
    scroll-height="flex"
    :loading="props.loading"
    filterDisplay="menu"
  >
    <column field="filename" header="Filename" :sortable="true">
      <template #body="{ data }: { data: LogFile }">
        <span v-if="data.filename" data-cy="logs-file-name">{{ data.filename }}</span>
        <span v-else class="empty">--</span>
      </template>
    </column>
    <column field="type" filter-field="type" header="Type" :sortable="true">
      <template #body="{ data }: { data: LogFile }">
        <span v-if="data.type" data-cy="logs-file-type">{{ typeMap[data.type] }}</span>
        <span v-else class="empty">--</span>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :option-label="(data: LogType | null) => data ? typeMap[data] : ''"
          :options="logTypes"
          placeholder="Log Type"
          class="p-column-filter mb-2"
          :showClear="true"
        />
      </template>
    </column>
    <column field="port" header="Port" :sortable="true">
      <template #body="{ data }: { data: LogFile }">
        <span v-if="data.port" data-cy="logs-file-type">{{ data.port }}</span>
        <span v-else class="empty">--</span>
      </template>
    </column>
    <column field="start_time" header="Time" :sortable="true">
      <template #body="{ data }: { data: LogFile }">
        <date-time-display :date="data.start_time" />
      </template>
    </column>
    <column header="Actions">
      <template #body="{ data }: { data: LogFile }">
        <Button @click="() => downloadFile(data)">
          <span class="flex items-center gap-2">
            <arrow-down-on-square-icon class="h-5 w-5" />
            Download
          </span>
        </Button>
      </template>
    </column>
  </data-table>
</template>

<script setup lang="ts">
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import type { LogFile } from '@/models/logs.model';
import { LogType } from '@/models/logs.model';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import { ArrowDownOnSquareIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { fetchLogFileDownloadUrl } from '@/stores/admin/hardwareSystems/hardwareSystems.api';
import { FilterMatchMode } from 'primevue/api';
import Dropdown from 'primevue/dropdown';

const typeMap: Record<LogType, string> = {
  [LogType.SYSTEM]: 'System',
  [LogType.OCPP]: 'OCPP',
  [LogType.TRACE]: 'Trace',
};

const props = defineProps<{
  logFiles: LogFile[];
  loading: boolean;
}>();

const filters = ref({
  type: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const logTypes = Object.values(LogType);

const downloadLoading = ref(false);

function download(url: string, name: string) {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function showErrorPopup(error: Error) {
  console.error(error);
  alert(error.message);
}

function downloadFile(file: LogFile) {
  downloadLoading.value = true;
  fetchLogFileDownloadUrl(file.logtext)
    .then((info) => download(info.download_url, file.filename))
    .catch((error) => showErrorPopup(error))
    .finally(() => (downloadLoading.value = false));
}
</script>

<style scoped lang="scss">
.p-datatable {
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
}
</style>
