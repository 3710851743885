<template>
  <TabMenu data-cy="monitoring-tabs" :model="items" v-model:activeIndex="active">
    <template #item="{ item }">
      <div class="p-menuitem-link" @click="item.action">
        <span data-cy="monitoring-tabs-item" class="p-menuitem-text">{{ item.label }}</span>
      </div>
    </template>
  </TabMenu>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAlertsStore } from '@/stores/admin/alerts/alerts.store';

import TabMenu from 'primevue/tabmenu';

const { t } = useI18n();
const alertsStore = useAlertsStore();
const { alertsActiveTab } = storeToRefs(alertsStore);
const active = ref(0);
const items = ref([
  {
    label: t('monitoring.history'),
    action: () => {
      active.value = 0;
    },
  },
  {
    label: t('monitoring.configuration'),
    action: () => {
      active.value = 1;
    },
  },
]);

watch(
  () => active.value,
  (value) => {
    alertsActiveTab.value = value;
  }
);
</script>
<style lang="scss" scoped></style>
