<template>
  <DataTable
    v-if="!loading"
    data-cy="updates-table"
    :value="props.brandings"
    v-model:filters="filters"
    filterDisplay="menu"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    dataKey="id"
    class="p-datatable-sm border-rounded overflow-hidden"
    selectionMode="single"
    @row-click="onRowSelect"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            data-cy="branding-search-input"
            v-model="filters['global'].value"
            :placeholder="$t('searchBy', { name: `${$t('name')}, ${$t('organisation.title')}` })"
            class="p-inputtext-sm"
          />
        </span>
        <div class="flex justify-end items-center">
          <Button
            data-cy="add-branding-button"
            class="p-button-primary p-button-sm"
            icon="pi pi-plus"
            :label="$t('branding.actions.create')"
            @click="openCreate"
          />
        </div>
      </div>
    </template>
    <template #empty>
      <span data-cy="branding-table-empty" class="w-full block text-center">{{
        $t('branding.list.empty')
      }}</span>
    </template>
    <Column class="no-top-border-row" header="Name" :sortable="true" field="name">
      <template v-slot:body="{ data }: { data: FullBrandingConfiguration }">
        <span>{{ data.name }}</span>
      </template>
    </Column>

    <Column
      class="no-top-border-row"
      field="charge_parks"
      :header="$t('chargeParks')"
      :sortable="true"
      style="min-width: 7rem"
    >
      <template #body="{ data }: { data: FullBrandingConfiguration }">
        <span v-if="data.charge_parks.length > 0" data-cy="alert-charge-park">
          <i
            class="pi pi-eye text-neutral-500 border border-l border-gray-300 rounded p-0.5 mr-2"
            v-tooltip.right="{
              value: `${data.charge_parks
                .map(
                  (chargePark) =>
                    `<div class='flex flex-nowrap w-full'><span class='pr-2'>${chargePark.name}</span></div>`
                )
                .join('')}
                `,
              escape: true,
              class: 'custom-tooltip site-tooltip',
            }"
          >
          </i>
          {{
            data.charge_parks.length === 1
              ? data.charge_parks.length + ' Site'
              : data.charge_parks.length + ' Sites'
          }}
        </span>
        <span v-else data-cy="alert-no-charge-parks">
          <i class="pi pi-exclamation-triangle text-amber-500 text-lg ml-1 mr-2"></i>
          0 {{ $t('chargeParks') }}
        </span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      header="Created By Organization"
      :sortable="true"
      :filter-match-mode-options="[{ label: 'Contains', value: FilterMatchMode.CONTAINS }]"
      :show-filter-match-modes="false"
      field="owner_organisation_name"
      style="min-width: 7rem"
    >
      <template v-slot:body="{ data }: { data: FullBrandingConfiguration }">
        <span>{{ data.owner_organisation_name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name').toLowerCase() })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="created_at"
      :header="$t('createdAt')"
      :sortable="true"
      style="min-width: 8rem"
    >
      <template #body="{ data }: { data: FullBrandingConfiguration }">
        <span v-if="data.created_at" data-cy="update-created-at">{{
          dayjs(data.created_at).format('ll')
        }}</span>
        <span v-else class="empty">--</span>
      </template>
    </Column>
    <Column class="no-top-border-row no-break" field="actions" header="">
      <template #body="{ data }: { data: FullBrandingConfiguration }">
        <div class="flex">
          <custom-button
            class="ml-auto"
            data-cy="open-edit-update-modal"
            @click="openDetails(data.id)"
          >
            <span>Details</span>
            <arrow-right-icon class="w-4 h-4" />
          </custom-button>
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import { ref } from 'vue';
import type { FullBrandingConfiguration } from '@/stores/admin/branding/branding.types';
import CustomButton from '@/components/common/button/CustomButton.vue';
import type { ChargePark } from '@/models/chargeParks.model';
import { ArrowRightIcon } from '@heroicons/vue/24/outline';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { FilterMatchMode } from 'primevue/api';
import { useRouter } from 'vue-router';
import { BRANDING } from '@/utils/routeNames';

const loading = ref(false);
const router = useRouter();

const props = defineProps<{
  brandings: FullBrandingConfiguration[];
  chargeParks: ChargePark[];
}>();

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  owner_organisation_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const emit = defineEmits<{
  (event: 'details', id: FullBrandingConfiguration['id']): void;
  (event: 'create'): void;
}>();

function openCreate() {
  emit('create');
}

function openDetails(id: FullBrandingConfiguration['id']) {
  emit('details', id);
}

const onRowSelect = (event: any) => {
  emit('details', event.data.id);
  router.push(`/${BRANDING}/${event.data.id}`);
};
</script>

<style scoped lang="scss"></style>
