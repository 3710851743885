<template>
  <div class="logs-container">
    <log-header
      :start="start"
      :end="end"
      v-model:view-mode="viewMode"
      @update:range="updateRange"
    />
    <div class="content">
      <div
        v-show="viewMode === 'SPLIT' || viewMode === 'FILES'"
        :class="{ fullWidth: viewMode === 'FILES', halfWidth: viewMode === 'SPLIT' }"
      >
        <log-file-list :loading="logFilesLoading" :log-files="logFiles" />
      </div>
      <div
        v-if="viewMode === 'SPLIT' || viewMode === 'SYSLOG'"
        :class="{ fullWidth: viewMode === 'SYSLOG', halfWidth: viewMode === 'SPLIT' }"
      >
        <log-stream :start="start" :end="end" :system-id="props.systemId" :type="LogType.SYSTEM" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import LogStream from '@/components/logs/LogStream.vue';
import { onMounted, ref } from 'vue';
import LogFileList from '@/components/logs/LogFileList.vue';
import LogHeader from '@/components/logs/LogHeader.vue';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import { type LogFile, LogType } from '@/models/logs.model';
import { fetchLogFiles } from '@/stores/admin/hardwareSystems/hardwareSystems.api';

const props = defineProps<{
  systemId?: HardwareSystem['id'];
}>();

type ViewMode = 'SPLIT' | 'FILES' | 'SYSLOG';
const ONE_DAY_MILLISECONDS = 1000 * 60 * 60 * 24;
const yesterday = new Date(new Date().valueOf() - ONE_DAY_MILLISECONDS);
const today = new Date();

const start = ref<string>(new Date(yesterday.setHours(0, 0, 0, 0)).toISOString());
const end = ref<string>(new Date(today.setHours(23, 59, 59, 0)).toISOString());
const viewMode = ref<ViewMode>('SPLIT');

const logFilesLoading = ref<boolean>(false);
const logFilesError = ref<Error | null>(null);
const logFiles = ref<LogFile[]>([]);

async function fetchLogList() {
  if (!props.systemId) return;
  logFilesLoading.value = true;
  logFilesError.value = null;
  await fetchLogFiles(props.systemId, start.value, end.value)
    .then((res) => (logFiles.value = res))
    .catch((err) => (logFilesError.value = err))
    .finally(() => (logFilesLoading.value = false));
}

function updateRange(newStart: string, newEnd: string) {
  console.log('updating range', newStart, newEnd);
  start.value = newStart;
  end.value = newEnd;
  fetchLogList();
}

onMounted(() => fetchLogList());
</script>

<style scoped lang="scss">
.logs-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.content {
  display: flex;
  height: 100%;
  flex-direction: row;
  overflow: hidden;
  margin: 1rem;
  gap: 1rem;
}

.fullWidth {
  width: 100%;
}

.halfWidth {
  width: 50%;
  overflow: auto;
}
</style>
