import type { IdName } from './common.model';

export enum PlaylistStep {
  Playlist = 'playlist',
  Sites = 'sites',
}

export interface PlaylistItemModel {
  id: number;
  playlist: IdName | number | null;
  media: MediaItem | number | null;
  repeat: number | null;
  duration: number | null;
  scaling: number;
  order: number;
}

export interface PlaylistEditItemModel {
  id: number;
  playlist: IdName;
  media: MediaItem;
  repeat: number | null;
  duration: number | null;
  scaling: number;
  order: number;
}

export interface Playlist {
  id: number;
  name: string;
  charge_parks: IdName[];
  video_count: number;
  image_count: number;
  charge_park_count: number;
  modified_at: string | Date | null;
  media: MediaItem[];
  writable: boolean;
  owner_organisation_name: string;
}

export interface PlaylistEditData {
  name: string;
  charge_parks: number[];
  media: PlaylistItemModel[];
  owner_organisation_name: string;
}

export interface PlaylistCreationData {
  name: string;
  charge_parks: number[];
}

export interface PlaylistMediaCreationData {
  playlist: IdName | number | null;
  media: MediaItem | number | null;
  repeat: number | null;
  duration: number | null;
  scaling: number;
  order: number;
}

export interface MediaItem {
  id: number;
  name: string;
  type: string;
  status: string;
}

export interface PlaylistEditMediaData {
  id: number;
  playlist: IdName;
  media: MediaItem;
  repeat: number;
  duration: number;
  scaling: number;
  order: number;
}
