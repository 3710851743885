<template>
  <p class="value" :class="{ empty: props.value === undefined }">
    {{ props.value ? props.value.toString() : '--' }}
  </p>
</template>

<script setup lang="ts">
const props = defineProps<{
  value?: any;
}>();
</script>

<style scoped lang="scss">
.value {
  font-size: 0.9rem;
  color: var(--gray-600);
  text-align: right;

  &.empty {
    color: var(--gray-400);
  }
}
</style>
