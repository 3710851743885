import type { Router } from 'vue-router';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { ORGANISATIONS_AND_USERS, ROUTES } from '@/utils/routeNames';

export function registerFeatureAndRoleGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const usersStore = useUsersStore();
    await usersStore.init();

    const featureDisabled =
      to.meta.requiredFeatures !== undefined &&
      to.meta.requiredFeatures.length > 0 &&
      !usersStore.featureIsActive(to.meta.requiredFeatures);

    const roleDisabled =
      to.meta.requiredRoles !== undefined &&
      to.meta.requiredRoles.length > 0 &&
      !usersStore.hasAtLeastOneRoleOf(...to.meta.requiredRoles);

    if ((featureDisabled || roleDisabled) && to.path !== `/${ORGANISATIONS_AND_USERS}`) {
      next({ path: `/${ORGANISATIONS_AND_USERS}` });
    } else {
      next();
    }
  });
}

export function registerDpaGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const usersStore = useUsersStore();
    await usersStore.init();

    if (usersStore.userNeedsToAcceptDpa && to.path !== `/${ROUTES.DPA}`) {
      next({ path: `/${ROUTES.DPA}` });
      return;
    }

    next();
  });
}
