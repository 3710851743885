<template>
  <span v-if="title" class="text-grey-medium font-semibold">{{ title }}</span>
  <div
    :class="`flex justify-between py-1 info-item ${connector ? 'pl-3' : ''} ${
      $props.class ? $props.class : ''
    }`"
  >
    <div class="flex">
      <div v-if="connector" class="connector"></div>
      <div v-if="nested" class="nested-connector"></div>
      <span class="text-grey-medium font-semibold read-only-name">{{ name }}</span>
    </div>
    <span
      v-if="value"
      :data-cy="props.name"
      :id="props.valueID"
      class="font-semibold flex items-center read-only-value"
      :class="`${alert ? 'text-orange-primary' : 'text-dark'} ${value === '--' ? 'empty' : ''}`"
    >
      <i v-if="alert" class="pi pi-exclamation-triangle mr-2"></i>
      {{ value }}
      <span
        v-if="tooltip"
        class="ml-2 text-gray-300 pi pi-info-circle"
        v-tooltip.bottom="tooltip"
      />
    </span>
    <slot />
  </div>
</template>

<script setup lang="ts">
export interface Props {
  title?: string;
  name?: string | number;
  value?: string | number | boolean;
  connector?: boolean;
  class?: string;
  alert?: boolean;
  tooltip?: string;
  nested?: boolean;
  valueID?: string;
}
const props = withDefaults(defineProps<Props>(), {
  connector: false,
});
</script>
