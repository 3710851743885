<template>
  <div v-if="loading" class="loading-container">
    <i class="pi pi-spin pi-spinner" />
  </div>
  <error-bar
    v-else-if="chargeParkEditionData.id === 0"
    title="404: Site not found"
    message="The site you are looking for does not exist."
  />
  <div v-else>
    <charge-park-detail-header
      v-if="chargeParkEditionData"
      :charge-park="chargeParkEditionData"
      :country="country"
      :tabs="tabs"
      v-model:active-tab-index="activeTabIndex"
    />
    <div class="container container-grey">
      <div v-if="activeTabIndex === 0">
        <charge-park-general />
      </div>
      <div v-else-if="activeTabIndex === 1">
        <hardware-system-table :systems="systemsByChargePark" :charge_park_id="chargeParkFilter[0].dimension ==='chargepark.id' && chargeParkFilter[0].condition ==='=' ? Number(chargeParkFilter[0].value) :undefined"/>
      </div>
      <div v-if="activeTabIndex === 2">
        <widget-panel
          v-if="panel"
          :panel="panel"
          :additional-filters="chargeParkFilter"
          :context-resolver="contextResolver"
        />
      </div>
      <div v-if="activeTabIndex === 3">
        <charge-park-access-and-payments />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { storeToRefs } from 'pinia';
import { onMounted, onUnmounted, ref } from 'vue';
import { useDashboardStore } from '@/stores/admin/dashboard/dashboard.store';
import { useCountriesStore } from '@/stores/admin/countries/countries.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { loadPanel } from '@/stores/admin/dashboard/dashboard.api';
import { useI18n } from 'vue-i18n';

import ChargeParkDetailHeader from '@/components/chargeParks/ChargeParkDetailHeader.vue';
import WidgetPanel from '@/components/dashboard/layout/WidgetPanel.vue';
import ErrorBar from '@/components/common/bar/ErrorBar.vue';
import HardwareSystemTable from '@/components/hardwareSystems/HardwareSystemTable.vue';
import ChargeParkGeneral from '@/components/chargeParks/general/ChargeParkGeneral.vue';
import ChargeParkAccessAndPayments from '@/components/chargeParks/accessAndPayment/ChargeParkAccessAndPayment.vue';

import type { Panel, WidgetFilter } from '@/stores/admin/dashboard/dashboard.types';
import type { ChargePark } from '@/models/chargeParks.model';
import type { Country } from '@/models/countries.model';
import { WidgetContextResolver } from '@/stores/admin/dashboard/dashboard.logic.context';

const { t } = useI18n();
const route = useRoute();
const chargeParkStore = useChargeParksStore();
const countriesStore = useCountriesStore();
const dashboardStore = useDashboardStore();
const systemsStore = useHardwareSystemsStore();
const { chargeParkDetailsId, chargeParkEditionData } = storeToRefs(chargeParkStore);
const { systemsByChargePark } = storeToRefs(systemsStore);

const loading = ref(true);
const chargeParkId = Number(route.params.id);
const chargePark = ref<ChargePark | undefined>(undefined);
const country = ref<Country | undefined>(undefined);
const panel = ref<Panel | undefined>(undefined);

const contextResolver = new WidgetContextResolver();

const tabs = [
  { id: 0, key: 'general', label: `${t('general')}` },
  { id: 1, key: 'chargers', label: `${t('hardwareSystem.systems')}` },
  { id: 2, key: 'performanceAndMetrics', label: `${t('chargePark.performanceAndMetrics')}` },
  { id: 3, key: 'accessAndPayments', label: `${t('chargePark.accessAndPayments')}` },
];

const chargeParkFilter: WidgetFilter[] = [
  { dimension: 'chargepark.id', condition: '=', value: chargeParkId },
];

const activeTabIndex = ref(0);

onMounted(async () => {
  try {
    if (chargeParkDetailsId.value === 0) {
      await chargeParkStore.fetchChargePark(chargeParkId);
    }

    const countryCode = chargePark.value?.country_code;
    if (countryCode) country.value = await countriesStore.fetchCountry(countryCode);

    await contextResolver.init();
    panel.value = await loadPanel();

    await systemsStore.fetchSystemsByChargeParkId(chargeParkId);
  } finally {
    loading.value = false;
  }

  if (dashboardStore.panel !== null) panel.value = dashboardStore.panel;
});

onUnmounted(() => {
  chargeParkDetailsId.value = 0;
  chargeParkStore.resetChargeParkEditionData();
});
</script>

<style scoped lang="scss">
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  i {
    font-size: 1.5rem;
    opacity: 0.5;
  }
}
</style>
