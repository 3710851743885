<template>
  <Calendar
    v-model="formattedValue"
    showIcon
    :showTime="!noTime"
    class="w-full"
    :placeholder="props.placeholder"
    :dateFormat="primeVueDateFormat"
    :hourFormat="primeVueHourFormat"
    :minDate="minDate"
    :maxDate="maxDate"
    showButtonBar
    @today-click="todayClicked"
    :manualInput="false"
    :step-minute="props.stepMinute"
    :overlayVisible="props.overlayVisible"
    :selectionMode="props.selectionMode"
    hideOnDateTimeSelect
  >
    <template #footer>
      <slot name="footer"></slot>
      <span class="m-3 text-grey font-normal font-sans justify-end flex"> {{ timezoneNote }}</span>
    </template>
  </Calendar>
</template>

<script setup lang="ts">
import Calendar from 'primevue/calendar';
import {
  primeVueHourFormat,
  primeVueDateFormat,
  timezoneKey,
  timezoneUtcOffsetHours,
  forTimezoneUtcOffsetHours,
} from '@/utils/dateTimeFormatVue';
import { computed } from 'vue';

import dayjs from 'dayjs';

const emit = defineEmits(['update:modelValue']);
const props = withDefaults(
  defineProps<{
    // string for mode=['single']
    // string[] for mode=['multiple', 'range']
    modelValue: string | string[] | null | undefined;
    minDateTime?: string | undefined;
    maxDateTime?: string | undefined;
    placeholder?: string;
    noTime?: boolean;
    stepMinute?: number;
    overlayVisible?: boolean;
    displayTimezoneOverride?: string;
    selectionMode?: 'range' | 'multiple' | 'single';
  }>(),
  {
    selectionMode: 'single',
  }
);

/**
 * Removes locale timezone offset from Date object
 * (This is needed since Date object is always using browser timezone in Date class)
 * @param val Date The Date object to use
 */
const dateWithRemovedBrowserLocale = function (val: Date): Date {
  // First remove the offset that is added by Date object locale
  const currOffsetMin = val.getTimezoneOffset();
  return dayjs(val.toISOString()).subtract(currOffsetMin, 'minute').$d;
};
/**
 * Removes given timezone offset on a Date object
 * @param val Date where the timezone will be applied to
 * @param timezone Timezone key to apply
 * @returns Date object with timezone offset removed
 */
const datejsObjWithTimezone = function (val: Date, timezone: string): dayjs.Dayjs {
  // Now remove manually added userTimezone offset
  const isoStringWithoutZ = val.toISOString().replace('Z', '');
  return dayjs(isoStringWithoutZ).tz(timezone, true);
};

/**
 * Applies currently used timezone to given DateTime val
 * @param val Value to apply timezone to
 * @returns Date object with the timezone applied
 */
const datetimeAsTimezonedDate = function (val: string | Date): Date {
  // This date Object needs to be converted to use required timezone
  return dayjs(val).tz(props.displayTimezoneOverride || timezoneKey.value).$d;
};
/**
 * Removes timezone offset from Date object
 * @param val Date object with timezone offset
 * @returns string
 */
const dateTimeStringFromCustomTimezoneDate = function (val: Date): string {
  const dateVal = datejsObjWithTimezone(val, props.displayTimezoneOverride || timezoneKey.value);
  let result = dateVal.toISOString();

  if (props.noTime) {
    result = result.split('T')[0];
  }
  return result;
};

const timezoneNote = computed(() => {
  let result = `Timezone:  ${props.displayTimezoneOverride || timezoneKey.value} (UTC`;

  let valUsedForTimezoneOffset = new Date().toISOString();
  if (props.selectionMode === 'single') {
    valUsedForTimezoneOffset = props.modelValue as string;
  } else if (props.selectionMode === 'range' || props.selectionMode === 'multiple') {
    const arrayVal = props.modelValue as string[];
    valUsedForTimezoneOffset = arrayVal[0];
  }


  if (props.displayTimezoneOverride) {
    result += forTimezoneUtcOffsetHours.value(
      new Date(valUsedForTimezoneOffset),
      props.displayTimezoneOverride
    );
  } else {
    result += timezoneUtcOffsetHours.value(new Date(valUsedForTimezoneOffset));
  }
  result += ')';
  return result;
});

const minDate = computed(() => {
  if (!props.minDateTime) return undefined;
  return datetimeAsTimezonedDate(new Date(props.minDateTime));
});

const maxDate = computed(() => {
  if (!props.maxDateTime) return undefined;
  return datetimeAsTimezonedDate(new Date(props.maxDateTime));
});

const formattedValue = computed({
  /**
   * Since primeVue calendar is displaying time in Browser locale time,
   * we need to convert the datetimes to use our users settings timezone
   */
  get: () => {
    // Here we need to return a Date object for primeVue Calendar
    if (!props || !props.modelValue) return null;
    if (props.selectionMode === 'single') {
      let usedModelValue = props.modelValue;
      if (props.noTime && !usedModelValue.includes('T')) {
        // if model value only contains date, append T00:00:00Z
        usedModelValue += 'T00:00:00Z';
      }
      return datetimeAsTimezonedDate(usedModelValue as string);
    } else if (props.selectionMode === 'range' || props.selectionMode === 'multiple') {
      const modelValArray = props.modelValue as string[];
      return modelValArray.map((value) => {
        if (!value) return null;
        if (props.noTime && !value.includes('T')) {
          // if model value only contains date, append T00:00:00Z
          value += 'T00:00:00Z';
        }
        return datetimeAsTimezonedDate(value);
      }) as Date[];
    } else {
      console.error('Not supported DateTimeInput mode!', props.selectionMode);
      return null;
    }
  },

  set: (val) => {
    // TODO handle any conversion errors
    if (!val) {
      if (props.selectionMode === 'single') {
        emit('update:modelValue', null);
      } else if (props.selectionMode === 'range' || props.selectionMode === 'multiple') {
        emit('update:modelValue', []);
      }
      return;
    }

    if (props.selectionMode === 'single') {
      const datetime = dateWithRemovedBrowserLocale(val as Date);
      const result = dateTimeStringFromCustomTimezoneDate(datetime);
      // emits string
      emit('update:modelValue', result);
    } else if (props.selectionMode === 'range' || props.selectionMode === 'multiple') {
      const valArr = val as Date[];
      const datetime = valArr.map((item) => item && dateWithRemovedBrowserLocale(item as Date));
      const result = datetime.map((item) => item && dateTimeStringFromCustomTimezoneDate(item));
      // emits string[]
      emit('update:modelValue', result);
    }
  },
});

function todayClicked() {
  let result;

  if (props.selectionMode === 'range' || props.selectionMode === 'multiple') {
    // Set result as an array containing the current date
    result = [dayjs().toISOString()];
  } else {
    // Set result as a single date value
    result = dayjs().toISOString();
  }

  // Used to fix Calendar not setting time to now on click at today
  if (props.maxDateTime && new Date() > new Date(props.maxDateTime)) {
    result = dayjs(props.maxDateTime).toISOString();
  } else if (props.minDateTime && new Date() < new Date(props.minDateTime)) {
    result = dayjs(props.minDateTime).toISOString();
  }

  if (props.noTime) {
    // If noTime is true, remove the time part
    if (Array.isArray(result)) {
      result = result.map(date => date.split('T')[0]);
    } else {
      result = result.split('T')[0];
    }
  }

  emit('update:modelValue', result);
}
</script>
<style></style>
