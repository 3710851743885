<template>
  <div class="w-full px-3 py-2 flex items-center">
    <button class="flex mr-4 text-gray-300">
      <i class="pi pi-ellipsis-v w-2" />
      <i class="pi pi-ellipsis-v w-2" />
    </button>

    <div>
      <MediaThumbnail
        v-if="mediaItem"
        :media="mediaItem"
        width-class="w-32"
        height-class="h-16"
        class="hidden lg:block"
      />
    </div>

    <div class="mx-4">
      <p class="font-semibold w-[150px] truncate ...">{{ mediaItem?.file_name }}</p>
      <div class="flex items-baseline text-sm text-gray-400">
        <p>{{ mediaItem?.file_type }}</p>
        <span class="mx-1"> &#8226; </span>
        <p v-if="mediaItem?.file_size">{{ fileSizeToHumanReadable(mediaItem.file_size) }}</p>
      </div>
      <Chip class="text-xs" :style="mediaStore.getMediaStatusStyle(Number(mediaItem?.status))" :label="mediaStore.getMediaStatusByID(Number(mediaItem?.status))?.name"></Chip>

    </div>

    <div class="ml-auto flex justify-end gap-2">
      <InlineLabelDropdown
        v-if="editableItem && showRepeat"
        v-model="editableItem.repeat"
        :options="repeatOptions"
        :label="$t('playlists.repeat')"
      />

      <InlineLabelDurationInput
        v-if="editableItem && showDuration"
        :type="$t('playlists.duration')"
        v-model="editableItem.duration"
        @update="updateDuration"
        :required="true"
      />

      <InlineLabelDropdown
        v-if="editableItem && mediaItem?.file_type !== 'Video'"
        v-model="editableItem.scaling"
        :options="scalingOptions"
        :label="$t('playlists.scaling')"
        :required="true"
      />

      <Button
        outlined
        icon="pi pi-info-circle"
        class="!p-0"
        v-tooltip.left="{
          value: `<span class='mb-1 inline-block'>${$t(
            'playlists.scalingDescription.general'
          )}</span>
          <span class='my-1 inline-block'>${$t('playlists.scalingDescription.stretch')}</span>
          <span class='my-1 inline-block'>${$t('playlists.scalingDescription.fill')}</span>
          <span class='my-1 inline-block'>${$t('playlists.scalingDescription.fit')}</span>`,
          escape: true,
        }"
      />

      <Button
        data-cy="delete-playlist-media-button"
        icon="pi pi-trash"
        class="p-button-outlined p-button-white p-button-sm"
        @click="removeItem"
      />
    </div>
  </div>
  <div>
  </div>
  <div v-if="Number(mediaItem?.status) !== Number(mediaStore.processedMediaStatusID)" class="flex field-no-edit">
      <i class="pi pi-exclamation-triangle text-orange-primary	 mr-3 self-center" />
      <span class="text-orange-primary	 transfer-info" v-html="$t('playlists.warnNotProcessedMedia')" />
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { fileSizeToHumanReadable } from '@/utils/textFormatting';
import { useMediaStore } from '@/stores/admin/media/media.store';
import { usePlaylistsStore } from '@/stores/admin/playlists/playlists.store';

import Chip from 'primevue/chip';
import Button from 'primevue/button';
import MediaThumbnail from '@/components/common/thumbnail/MediaThumbnail.vue';
import InlineLabelDropdown from '@/components/common/InlineLabelDropdown.vue';
import InlineLabelDurationInput from '@/components/common/inputs/InlineLabelDurationInput.vue';

import type { Media } from '@/models/media.model';
import type { PlaylistItemModel } from '@/models/playlists.model';

const mediaStore = useMediaStore();
const { mediaList } = storeToRefs(mediaStore);
const playlistsStore = usePlaylistsStore();
const { playlistModalData, scalingOptions, repeatOptions, playlistEditMediaData, mediaToEditIds } =
  storeToRefs(playlistsStore);

const emit = defineEmits<{
  remove: [item: PlaylistItemModel];
}>();

const props = defineProps<{
  item: PlaylistItemModel;
}>();

const editableItem = computed<PlaylistItemModel | undefined>(() => {
  const item = playlistModalData.value.media.find(
    (item: PlaylistItemModel) => item.id === props.item.id
  );

  const editableItemData = playlistEditMediaData.value.find((editItem) => editItem.id === item?.id);

  if (
    item?.duration !== editableItemData?.duration ||
    item?.repeat !== editableItemData?.repeat ||
    item?.scaling !== editableItemData?.scaling ||
    item?.order !== editableItemData?.order
  ) {
    const newMediaEditItem = mediaToEditIds.value.findIndex((id) => id === item?.id);

    if (newMediaEditItem === -1 && editableItemData) {
      setNewMediaEditItem(item?.id);
    }
  }
  return item;
});

const setNewMediaEditItem = (id: number | undefined) => {
  if (id) {
    mediaToEditIds.value.push(id);
  }
};

const mediaItem = computed<Media | undefined>(() => {
  return mediaList.value.find((media: Media) => media.id === props.item.media);
});

const isVideo = (media: Media | undefined) => {
  return media?.file_type.toLowerCase() === 'video';
};

const isImage = (media: Media | undefined) => {
  return media?.file_type.toLowerCase() === 'image';
};

const showRepeat = computed(() => editableItem.value && isVideo(mediaItem.value));
const showDuration = computed(() => editableItem.value && isImage(mediaItem.value));

const removeItem = () => {
  emit('remove', props.item);
};

const updateDuration = (value: number) => {
  if (editableItem.value) {
    editableItem.value.duration = value;
  }
};
</script>
