<template>
  <Dialog
    data-cy="rfid-tag-edit-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="field flex flex-col field-edit">
      <label for="rfidTag" class="mb-2 field-edit-label"
        >{{ $t('rfidTag.id') }}<span class="required">*</span></label
      >
      <InputText
        data-cy="rfid-tag-input"
        id="rfidTag"
        type="text"
        aria-describedby="rfidTag-help"
        class="p-inputtext-sm"
        v-model="tag.rfid_tag"
        :placeholder="$t('rfidTag.id')"
        @keyup="validateTag"
      />
      <div class="input-errors" v-for="(error, index) of v$.rfid_tag.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>
    <div class="field flex flex-col field-edit">
      <label for="userName" class="mb-2 field-edit-label">{{ $t('name') }}</label>
      <InputText
        data-cy="rfid-tag-user-name-input"
        id="userName"
        type="text"
        aria-describedby="userName-help"
        class="p-inputtext-sm"
        v-model="tag.username"
        :placeholder="$t('name')"
        @keyup="validateUserName"
      />
      <div class="input-errors" v-for="(error, index) of v$.username.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>
    <CustomRadioSelect
      :data="stateData"
      :title="t('status')"
      :selectedState="rfidTagEditionData.state"
      class="field-edit"
      @onRadioChange="setState"
    />
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        v-if="!rfidTagIsEditing"
        data-cy="create-rfid-tag-button"
        :label="createLabel"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        :disabled="formIsNotValid"
        @click="createRfidTag"
        :loading="loading"
      />
      <Button
        v-else
        data-cy="edit-rfid-tag-button"
        :label="editLabel"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        :disabled="editFormIsNotValid"
        @click="editRfidTag"
        :loading="loading"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="rfid-tag-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="tags"
  />
</template>

<script lang="ts">
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useRfidTagsStore } from '@/stores/admin/rfidTags/rfidTags.store';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, maxLength } from '@vuelidate/validators';
import { asciiCharacters } from '@/utils/validationRules';
import Toast from 'primevue/toast';
import CustomRadioSelect from '../common/CustomRadioSelect.vue';
import { useToast } from 'primevue/usetoast';
import {
  CREATE,
  EDIT,
  VERTICAL,
  MAX_LENGTH,
  DEFAULT_TOAST_LIFE_MILLISECONDS,
} from '@/utils/constants';

export default {
  name: 'RfidTagEditModal',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Button,
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    InputText,
    Toast,
    CustomRadioSelect,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const store = useRfidTagsStore();
    const isVisible = ref(false);
    const loading = ref(false);
    const { rfidTagModalIsOpen, rfidTagIsEditing, rfidTagEditionData } = storeToRefs(store);
    const tag = ref({
      id: 0,
      rfid_tag: '',
      username: '',
      state: true,
    });
    const stateData = ref([
      {
        id: 1,
        title: t('enabled'),
        description: t('rfidTag.enabledDescription'),
        state: true,
      },
      {
        id: 2,
        title: t('disabled'),
        description: t('rfidTag.disabledDescription'),
        state: false,
      },
    ]);

    const modalTitle = computed(() => {
      const title = rfidTagIsEditing.value
        ? t('edit', { itemName: t('rfidTag.title') })
        : t('create', { itemName: t('rfidTag.title') });
      return title;
    });

    const createLabel = computed(() => {
      return v$.value.$error ? t('error') : t('create');
    });

    const editLabel = computed(() => {
      return v$.value.$error ? t('error') : t('apply');
    });

    const closeModal = () => {
      rfidTagModalIsOpen.value = false;
      loading.value = false;

      resetForm();

      if (rfidTagIsEditing.value) {
        rfidTagIsEditing.value = false;
      }
    };

    const setState = (selectedState: boolean) => {
      tag.value.state = selectedState;
      v$.value.state.$touch();
    };

    const showSuccess = (actionName: string) => {
      toast.add({
        severity: 'success',
        summary: t('toast.success', {
          name: t('rfidTag.title'),
          action: actionName === CREATE ? t('toast.created') : t('toast.edited'),
        }),
        life: DEFAULT_TOAST_LIFE_MILLISECONDS,
        group: 'tags',
      });
    };

    const showError = (actionName: string) => {
      toast.add({
        severity: 'error',
        summary: t('toast.error', {
          action: actionName === CREATE ? t('toast.creation') : t('toast.edition'),
          name: t('rfidTag.title'),
        }),
        group: 'tags',
      });
    };

    const createRfidTag = async () => {
      await v$.value.$validate();

      if (v$.value.$error) return;

      const data = {
        rfid_tag: tag.value.rfid_tag,
        username: tag.value.username,
        state: tag.value.state,
      };

      loading.value = true;

      await store
        .createNewRfidTag(data)
        .then((response: any) => {
          loading.value = false;
          if (response.status === 201) {
            toast.removeGroup('tags');
            showSuccess(CREATE);
            closeModal();
          }
        })
        .catch((error) => {
          loading.value = false;
          toast.removeGroup('tags');
          showError(CREATE);
          throw new Error(error);
        });
    };

    const editRfidTag = async () => {
      await v$.value.$validate();

      if (v$.value.$error) return;

      loading.value = true;

      await store
        .updateRfidTag([tag.value])
        .then((response: any) => {
          loading.value = false;
          if (response.status === 200) {
            toast.removeGroup('tags');
            showSuccess(EDIT);
            closeModal();
          }
        })
        .catch((error) => {
          loading.value = false;
          toast.removeGroup('tags');
          showError(EDIT);
          throw new Error(error);
        });
    };

    watch(
      () => rfidTagModalIsOpen.value,
      (value) => {
        if (value) {
          isVisible.value = true;
        } else {
          isVisible.value = false;
        }
      }
    );

    watch(
      () => store.rfidTagEditionData,
      (value) => {
        if (value.rfid_tag !== '') {
          tag.value.id = store.rfidTagEditionData.id;
          tag.value.rfid_tag = store.rfidTagEditionData.rfid_tag;
          tag.value.username = store.rfidTagEditionData.username;
          tag.value.state = store.rfidTagEditionData.state;
        }
      }
    );

    const validateTag = async () => {
      await v$.value.rfid_tag.$validate();
    };

    const validateUserName = async () => {
      await v$.value.username.$validate();
    };

    const resetForm = () => {
      tag.value.rfid_tag = '';
      tag.value.username = '';
      tag.value.state = true;
      v$.value.$reset();
      store.resetRfidTagEditionData();
    };

    const formIsNotValid = computed(() => {
      return v$.value.$error || tag.value.rfid_tag === '' || tag.value.username === '';
    });

    const editFormIsNotValid = computed(() => {
      return !v$.value.$anyDirty ||
        (v$.value.rfid_tag.$anyDirty && v$.value.rfid_tag.$error) ||
        (v$.value.username.$anyDirty && v$.value.username.$error)
        ? true
        : false;
    });

    const rules = computed(() => {
      return {
        rfid_tag: {
          required: helpers.withMessage(
            t('rules.required', { field: t('id'), item: t('rfidTag.title') }),
            required
          ),
          asciiCharacters: helpers.withMessage(
            t('rules.asciiCharacters', { field: t('id') }),
            asciiCharacters
          ),
          maxLength: maxLength(255),
        },
        username: {
          required: helpers.withMessage(
            t('rules.required', { field: t('name'), item: t('rfidTag.title') }),
            required
          ),
          asciiCharacters: helpers.withMessage(
            t('rules.asciiCharacters', { field: t('name') }),
            asciiCharacters
          ),
          maxLength: maxLength(MAX_LENGTH),
        },
        state: {
          required: helpers.withMessage(t('rules.required', { field: t('status') }), required),
        },
      };
    });

    const v$ = useVuelidate(rules, tag.value);

    return {
      closeModal,
      tag,
      createRfidTag,
      editRfidTag,
      resetForm,
      rfidTagModalIsOpen,
      rfidTagEditionData,
      rfidTagIsEditing,
      modalTitle,
      t,
      v$,
      formIsNotValid,
      createLabel,
      editLabel,
      validateTag,
      validateUserName,
      isVisible,
      showSuccess,
      showError,
      editFormIsNotValid,
      stateData,
      setState,
      VERTICAL,
      loading,
    };
  },
};
</script>
<style lang="scss" scoped></style>
