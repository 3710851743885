<template>
  <div data-cy="list">
    <span data-cy="list-title" v-if="title" class="title block my-3">{{ title }}</span>
    <div class="wrapper">
      <div class="flex flex-wrap list">
        <span
          data-cy="list-item"
          class="w-full item my-1"
          v-for="(item, index) in data"
          :key="index"
          >ID: {{ item }}</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';

export default {
  name: 'List',
  props: {
    data: {
      type: Array as PropType<number[]>,
      required: true,
    },
    title: {
      type: String,
    },
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  max-height: 100px;
  overflow: hidden;
  border: 1px solid $error-toast;
  border-radius: 5px;
  padding: 0 0 0 10px;
  background-color: rgba(255, 255, 255, 0.5);
}
.list {
  max-height: 100px;
  overflow-y: auto;
  padding: 5px 0 5px;
}
.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $error-toast;
  text-align: center;
}
.item {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $error-toast;
}
</style>
