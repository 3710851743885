<template>
  <div class="flex flex-col">
    <div class="my-3 text-right" v-for="(value, key) in chargeParkDetails" :key="key">
      <span
        v-if="value"
        data-cy="site-contact-field-value"
        class="py-[9px] px-4 w-1/2 text-right font-medium text-[var(--gray-700)]"
        >{{ value }}</span
      >
      <span v-if="!value" class="empty py-2 px-4 font-medium">--</span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import type { ChargePark } from '@/models/chargeParks.model';

const props = defineProps<{
  chargePark: ChargePark;
}>();

const chargeParkDetails = computed(() => {
  return {
    contact_name: props.chargePark.contact_name,
    contact_email: props.chargePark.contact_email,
    contact_phone: props.chargePark.contact_phone,
  };
});
</script>
<style scoped lang="scss"></style>
