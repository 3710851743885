<template>
  <div>
    <PanelWithEdit
      :title="$t('contact.title')"
      :loading="isLoading"
      :isEditing="isEditing"
      :isNotValid="editFormIsNotValid"
      @onEdit="isEditing = true"
      @onClose="closeEditing"
      @onApply="editSite"
    >
      <div class="flex justify-between my-2">
        <div class="flex flex-col">
          <div v-for="(value, key) in chargeParkDetails" :key="key">
            <span data-cy="site-contact-field-name" class="block font-medium py-3">{{
              $t(`chargePark.chargeParkDetails.${key}`)
            }}</span>
          </div>
        </div>
        <div v-if="isEditing" class="flex flex-col w-1/2">
          <div class="my-1">
            <InputText
              data-cy="panel-item-input"
              type="text"
              v-model="chargeParkDetails.contact_name"
              class="p-column-filter p-2 w-full"
              :placeholder="$t('name')"
              @keyup="validateInput('contact_name')"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.contact_name.$errors"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small>
            </div>
          </div>
          <div class="my-1">
            <InputText
              data-cy="panel-item-input"
              type="text"
              v-model="chargeParkDetails.contact_email"
              class="p-column-filter p-2 w-full"
              :placeholder="$t('email')"
              @keyup="validateInput('contact_email')"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.contact_email.$errors"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small>
            </div>
          </div>
          <div class="my-1">
            <InputText
              data-cy="panel-item-input"
              type="text"
              v-model="chargeParkDetails.contact_phone"
              class="p-column-filter p-2 w-full"
              :placeholder="$t('contact.phoneNumber')"
              @keyup="validateInput('contact_phone')"
            />
            <div
              class="input-errors"
              v-for="(error, index) of v$.contact_phone.$errors"
              :key="index"
            >
              <small class="p-error">{{ error.$message }}</small>
            </div>
          </div>
        </div>
        <ChargeParkContactPreview v-if="!isEditing" :chargePark="chargeParkEditionData" />
      </div>
    </PanelWithEdit>
    <Toast
      data-cy="charge-park-info-toast"
      position="top-center"
      errorIcon="pi pi-info-circle"
      group="chargeParkContact"
    />
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { chargeParkContactRules } from '@/utils/chargeParkDetailsRules';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import ChargeParkContactPreview from './ChargeParkContactPreview.vue';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

const { t } = useI18n();
const toast = useToast();
const chargeParksStore = useChargeParksStore();
const { chargeParkEditionData } = storeToRefs(chargeParksStore);
const systemsStore = useHardwareSystemsStore();
const chargeParkDetails = ref({
  contact_name: chargeParkEditionData.value.contact_name,
  contact_email: chargeParkEditionData.value.contact_email,
  contact_phone: chargeParkEditionData.value.contact_phone,
});

const isLoading = ref(false);
const isEditing = ref(false);

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('chargePark.title'),
      action: t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'chargeParkContact',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: t('toast.edition'),
      name: t('chargePark.title'),
    }),
    group: 'chargeParkContact',
  });
};

const editFormIsNotValid = computed(() => {
  return (
    !v$.value.$anyDirty ||
    (v$.value.contact_name.$anyDirty && v$.value.contact_name.$error) ||
    (v$.value.contact_email.$anyDirty && v$.value.contact_email.$error) ||
    (v$.value.contact_phone.$anyDirty && v$.value.contact_phone.$error)
  );
});

const resetForm = () => {
  chargeParkDetails.value.contact_name = chargeParkEditionData.value.contact_name;
  chargeParkDetails.value.contact_email = chargeParkEditionData.value.contact_email;
  chargeParkDetails.value.contact_phone = chargeParkEditionData.value.contact_phone;
  v$.value.contact_name.$reset();
  v$.value.contact_email.$reset();
  v$.value.contact_phone.$reset();
  v$.value.$reset();
};

const closeEditing = () => {
  isEditing.value = false;
  resetForm();
};

const editSite = () => {
  chargeParksStore
    .editChargeParkDetails(chargeParkEditionData.value.id, chargeParkDetails.value)
    .then((response: any) => {
      chargeParksStore.fetchChargePark(chargeParkEditionData.value.id).then((response) => {
        const { contact_name, contact_email, contact_phone } = response.data;
        chargeParkDetails.value.contact_name = contact_name;
        chargeParkDetails.value.contact_email = contact_email;
        chargeParkDetails.value.contact_phone = contact_phone;
      });
      if (response.status === 200) {
        systemsStore.fetchSystemsByChargeParkId(chargeParkEditionData.value.id);
        toast.removeGroup('chargeParkContact');
        showSuccess();
        isEditing.value = false;
      }
    })
    .catch((error) => {
      toast.removeGroup('chargeParkContact');
      showError();
      throw new Error(error);
    });
};

const validateInput = async (item: string) => {
  await v$.value[item].$validate();
};

const rules = computed(() => chargeParkContactRules);

const v$ = useVuelidate(rules, chargeParkDetails.value);
</script>
<style scoped lang="scss"></style>
