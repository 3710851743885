import { defineStore } from 'pinia';
import {
  createTariff,
  deleteTariffRequest,
  fetchCurrency,
  fetchSingleTariffDetails,
  fetchTariffs,
  updateTariff,
} from '@/stores/admin/tariffs/tariffs.api';

import type {
  Rate,
  SpecialRate,
  Tariff,
  TariffDetails,
  TariffEditData,
} from '@/models/tariffs.model';
import { isDefaultRate, isSpecialRate, type Currencies } from '@/models/tariffs.model';
import type { IdName } from '@/models/common.model';
import { isNumber, isDate } from 'lodash';
import { useChargeParksStore } from '../chargeParks/chargeParks.store';
import dayjs from 'dayjs';

interface TariffsState {
  tariffs: Tariff[];
  tariffCreateModalIsOpen: boolean;
  tariffDeleteModalIsOpen: boolean;
  tariffCreateSpecialRateIsOpen: boolean;
  tariffSpecialRateIsEditing: boolean;
  tariffModalData: TariffEditData;
  tariffEditId: number | null;
  tariffDeleteId: number | null;
  tariffDetails: TariffDetails;
  currencyTypes: Currencies[];
  rateData: Rate | SpecialRate;
  rateDeleteData: IdName;
  rateDeleteModalIsOpen: boolean;
  rateEditId: number | null;
  defaultRateData: Rate;
}

export const useTariffsStore = defineStore('tariffs', {
  state: (): TariffsState => ({
    tariffs: [],
    tariffCreateModalIsOpen: false,
    tariffDeleteModalIsOpen: false,
    tariffCreateSpecialRateIsOpen: false,
    tariffSpecialRateIsEditing: false,
    tariffModalData: {
      name: '',
      currency: 0,
      currency_symbol: '',
      currency_symbol_position: '',
      owner_organisation_name: '',
      writable: true,
      rates: [],
      charge_parks: [],
    },
    tariffEditId: null,
    tariffDeleteId: null,
    tariffDetails: {
      name: '',
      charge_parks: [],
      modified_at: '',
      currency: 0,
      currency_symbol_position: '',
      owner_organisation_name: '',
      writable: true,
      rates: [],
    },
    rateData: {
      type: 1,
      name: '',
      session_fee_cent: 0,
      session_fee_tax_percent: 0,
      price_per_kwh_tenth_cent: 0,
      price_per_kwh_tax_percent: 0,
      idle_fee_tenth_cent_per_min: 0,
      idle_fee_tax_percent: 0,
      idle_fee_grace_period_min: 0,
      charging_duration_fee_tenth_cent: 0,
      charging_duration_fee_tax_percent: 0,
      fixed_tax_session_cent: 0,
      fixed_tax_parking_cent: 0,
      pre_authorization_amount_cent: 0,
      days: [],
      start_time: '',
      end_time: '',
      start_date: '',
      end_date: '',
    },
    rateDeleteModalIsOpen: false,
    rateDeleteData: {
      id: 0,
      name: '',
    },
    rateEditId: null,
    currencyTypes: [],
    defaultRateData: {
      type: 1,
      name: '',
      days: [],
      start_time: '',
      end_time: '',
      start_date: '',
      end_date: '',
      session_fee_cent: 0,
      session_fee_tax_percent: 0,
      price_per_kwh_tenth_cent: 0,
      price_per_kwh_tax_percent: 0,
      idle_fee_tenth_cent_per_min: 0,
      idle_fee_tax_percent: 0,
      idle_fee_grace_period_min: 0,
      charging_duration_fee_tenth_cent: 0,
      charging_duration_fee_tax_percent: 0,
      fixed_tax_session_cent: 0,
      fixed_tax_parking_cent: 0,
      pre_authorization_amount_cent: 0,
    },
  }),

  getters: {
    isEditMode(state): boolean {
      return state.tariffEditId !== null;
    },

    normalizedTariffData(state) {
      const { name, charge_parks, currency_symbol_position } = state.tariffModalData;
      const currencyId =
        state.currencyTypes.find((currency) => currency.name === state.tariffModalData.currency)
          ?.id || 1;

      const normalizedRates = state.tariffModalData.rates.map((rate) => {
        let normalizedRate = rate;

        if (isDefaultRate(rate)) {
          rate = this.defaultRateData;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { type, name, days, start_time, end_time, start_date, end_date, ...rest } = rate;
          normalizedRate = { type: 1, ...rest } as Rate;
          return normalizedRate;
        }

        if (isSpecialRate(rate)) {
          const chargeParkStore = useChargeParksStore();
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { type, days, start_time, end_time, start_date, end_date, ...rest } =
            rate as SpecialRate;

          const dayIds = chargeParkStore.daysOfWeek
            .filter((day) => days.includes(day.name.toUpperCase() as never))
            .map((item) => item.id);

          const startTime =
            start_time === '' ? null : start_time?.length === 5 ? start_time + ':00' : start_time;
          const endTime =
            end_time === '' ? null : end_time?.length === 5 ? end_time + ':00' : end_time;

          normalizedRate = {
            type: 2,
            days: dayIds,
            start_time: startTime && isDate(startTime) ? dayjs(startTime).format('HH:mm:ss') : startTime,
            end_time: endTime && isDate(endTime) ? dayjs(endTime).format('HH:mm:ss') : endTime,
            start_date: start_date === '' ? null : start_date,
            end_date: end_date === '' ? null : end_date,
            ...rest,
          };
          return normalizedRate;
        }
        return normalizedRate;
      });

      return {
        name: name,
        charge_parks: charge_parks,
        currency: isNumber(state.tariffModalData.currency)
          ? state.tariffModalData.currency
          : currencyId,
        currency_symbol_position: currency_symbol_position,
        rates: normalizedRates,
      };
    },
  },

  actions: {
    async fetchTariffList() {
      try {
        const res = await fetchTariffs();
        this.tariffs = res.data;

        return res;
      } catch (error: unknown) {
        return Promise.reject(error);
      }
    },

    async fetchTariffDetails(id: number) {
      try {
        const res = await fetchSingleTariffDetails(id);
        this.tariffDetails = res.data;

        return res;
      } catch (error: unknown) {
        return Promise.reject(error);
      }
    },

    async deleteTariff() {
      try {
        if (!this.tariffDeleteId) throw new Error('No tariff id provided');

        await deleteTariffRequest(this.tariffDeleteId);
        this.tariffDeleteId = null;
        this.tariffDeleteModalIsOpen = false;
      } catch (error: unknown) {
        return Promise.reject(error);
      }
    },

    async createNewTariff() {
      try {
        return await createTariff(this.normalizedTariffData);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async editTariff() {
      try {
        if (this.tariffEditId === null || !this.normalizedTariffData) {
          return Promise.reject('No tariff id to edit');
        } else {
          return await updateTariff(this.tariffEditId, this.normalizedTariffData);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async fetchCurrencyList() {
      try {
        const res = await fetchCurrency();
        this.currencyTypes = res.data;

        return res;
      } catch (error: unknown) {
        return Promise.reject(error);
      }
    },

    resetTariffModalData() {
      this.tariffModalData = {
        name: '',
        currency: 0,
        currency_symbol: '',
        currency_symbol_position: '',
        owner_organisation_name: '',
        writable: true,
        rates: [],
        charge_parks: [],
      };
    },

    resetTariffDetails() {
      this.tariffDetails = {
        name: '',
        charge_parks: [],
        modified_at: '',
        currency: 0,
        currency_symbol: '',
        currency_symbol_position: '',
        owner_organisation_name: '',
        writable: true,
        rates: [],
      };
    },

    resetRateDate() {
      this.rateData = {
        type: 1,
        name: '',
        session_fee_cent: 0,
        session_fee_tax_percent: 0,
        price_per_kwh_tenth_cent: 0,
        price_per_kwh_tax_percent: 0,
        idle_fee_tenth_cent_per_min: 0,
        idle_fee_tax_percent: 0,
        idle_fee_grace_period_min: 0,
        charging_duration_fee_tenth_cent: 0,
        charging_duration_fee_tax_percent: 0,
        fixed_tax_session_cent: 0,
        fixed_tax_parking_cent: 0,
        pre_authorization_amount_cent: 0,
        days: [],
        start_time: '',
        end_time: '',
        start_date: '',
        end_date: '',
      };
    },

    resetDefaultRate() {
      this.defaultRateData = {
        type: 1,
        name: '',
        days: [],
        start_time: '',
        end_time: '',
        start_date: '',
        end_date: '',
        session_fee_cent: 0,
        session_fee_tax_percent: 0,
        price_per_kwh_tenth_cent: 0,
        price_per_kwh_tax_percent: 0,
        idle_fee_tenth_cent_per_min: 0,
        charging_duration_fee_tenth_cent: 0,
        charging_duration_fee_tax_percent: 0,
        idle_fee_tax_percent: 0,
        idle_fee_grace_period_min: 0,
        fixed_tax_session_cent: 0,
        fixed_tax_parking_cent: 0,
        pre_authorization_amount_cent: 0,
      };
    },
  },
});
