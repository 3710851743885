import type { Router } from 'vue-router';
import {
  signInRequest,
  msalInstance,
  signUpRequest,
  acquireTokenSignInRequest,
  logout,
} from './config';
import { ROUTES } from '@/utils/routeNames';
import type { AccountInfo } from '@azure/msal-browser';
import axios from 'axios';

async function retrieveAndAddTokenToAxios(account: AccountInfo): Promise<void> {
  try {
    const accessToken = await msalInstance.acquireTokenSilent({
      ...acquireTokenSignInRequest,
      account,
    });
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken.accessToken}`;
    return;
  } catch (e) {
    console.error('Silent Token Error (SignIn Authority): ', e);
    await logout();
  }
}

export function registerAuthGuard(router: Router) {
  router.beforeEach(async (to) => {
    if (!to.meta.requiresAuth) return true;

    await msalInstance.initialize();

    try {
      await msalInstance.handleRedirectPromise();
    } catch (e) {
      console.log('Error handling redirect promise', e);
      await msalInstance.loginRedirect(signInRequest);
    }

    if (msalInstance.getAllAccounts().length > 0) {
      await retrieveAndAddTokenToAxios(msalInstance.getAllAccounts()[0]);
      return true;
    }

    if (to.redirectedFrom?.path === `/${ROUTES.SIGN_UP}`) {
      localStorage.clear();
      await msalInstance.loginRedirect(signUpRequest);
    }

    await msalInstance.loginRedirect(signInRequest);
    return false;
  });
}
