<template>
  <Dialog
    v-model:visible="isPlaylistModalOpen"
    data-cy="playlist-create-edit-modal"
    modal
    class="playlist-create-edit-modal bg-gray-100"
    :breakpoints="{ '960px': '90vw' }"
    :style="{ width: '1275px' }"
    @hide="closeModal"
  >
    <template #header>
      <div class="relative w-full text-center">
        <Button
          v-if="!isFirst"
          :label="$t('back')"
          icon="pi pi-angle-left"
          class="!absolute p-button-outlined !bg-white p-button-plain p-button-sm mr-2 left-0 top-1/2 transform -translate-y-1/2"
          @click="goToPrevious"
        />

        <p class="font-semibold">
          {{ modalTitle }}
        </p>
      </div>
    </template>

    <ModalStepper :active-step="current" v-bind="{ steps }" />

    <div class="border-t">
      <component :is="playlistStepsComponents[current]" v-bind="{ v$ }" />
    </div>

    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        data-cy="playlist-create-edit-cancel-button"
        @click="closeModal"
      />
      <Button
        v-if="!isLast"
        :disabled="!isStepValid"
        :label="t('next')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        data-cy="playlist-create-edit-next-button"
        @click="goToNext"
      />
      <Button
        v-else
        :disabled="!isStepValid"
        :label="t('apply')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        data-cy="playlist-create-edit-submit-button"
        :loading="isLoading"
        @click="submitForm"
      />
    </template>
  </Dialog>

  <Toast
    data-cy="playlist-create-edit-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="playlists"
    class="playlists-toast"
  />
</template>

<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useStepper } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { CREATE, DEFAULT_TOAST_LIFE_MILLISECONDS, EDIT } from '@/utils/constants';
import { PlaylistStep } from '@/models/playlists.model';
import { usePlaylistsStore } from '@/stores/admin/playlists/playlists.store';
import { useMediaStore } from '@/stores/admin/media/media.store';
import { playlistRules } from '@/utils/playlistModalRules';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ModalStepper from '@/components/common/steppers/ModalStepper.vue';
import PlaylistModalPlaylistStep from '@/components/playlists/steps/PlaylistModalPlaylistStep.vue';
import PlaylistModalSitesStep from '@/components/playlists/steps/PlaylistModalSitesStep.vue';

import type { Media } from '@/models/media.model';

const { t } = useI18n();
const toast = useToast();
const playlistsStore = usePlaylistsStore();
const {
  isPlaylistModalOpen,
  playlistModalData,
  isEditMode,
  playlistId,
  mediaDeleteIds,
  mediaToEditIds,
} = storeToRefs(playlistsStore);
const mediaStore = useMediaStore();
const { mediaList } = storeToRefs(mediaStore);
const isLoading = ref(false);

const v$ = useVuelidate(playlistRules, playlistModalData);

const modalTitle = computed(() => {
  return isEditMode.value
    ? t('edit', { itemName: t('playlist') })
    : t('create', { itemName: t('playlist') });
});

const closeModal = () => {
  isPlaylistModalOpen.value = false;
  resetForm();

  if (isEditMode.value) {
    playlistId.value = null;
  }
};

const playlistStepsComponents: Record<PlaylistStep, unknown> = {
  [PlaylistStep.Playlist]: PlaylistModalPlaylistStep,
  [PlaylistStep.Sites]: PlaylistModalSitesStep,
};

const modalPath = [PlaylistStep.Playlist, PlaylistStep.Sites];

const { steps, current, goTo, goToNext, goToPrevious, isFirst, isLast } = useStepper(modalPath);

const resetForm = () => {
  playlistsStore.resetPlaylistModalData();
  isEditMode.value = false;
  v$.value.$reset();
  goTo(steps.value[0]);

  if (mediaDeleteIds.value.length > 0) {
    mediaDeleteIds.value = [];
  }

  if (mediaToEditIds.value.length > 0) {
    mediaToEditIds.value = [];
  }
};

const showSuccess = (actionName: string) => {
  toast.removeGroup('playlists');
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('playlist'),
      action: actionName === CREATE ? t('toast.created') : t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'playlists',
  });
};

const showError = (actionName: string) => {
  toast.removeGroup('playlists');
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: actionName === CREATE ? t('toast.creation') : t('toast.edition'),
      name: t('playlist'),
    }),
    group: 'playlists',
  });
};

const isStepValid = computed(() => {
  const stepValidationRules: Record<PlaylistStep, boolean> = {
    [PlaylistStep.Playlist]:
      v$.value.name.$dirty &&
      !v$.value.name.$invalid &&
      v$.value.media.$dirty &&
      !v$.value.media.$invalid &&
      durationIsValid.value,
    [PlaylistStep.Sites]: v$.value.charge_parks.$dirty && !v$.value.charge_parks.$invalid,
  };

  return stepValidationRules[current.value];
});

const durationIsValid = computed(() => {
  return playlistModalData.value.media.every((item) => {
    const mediaType = mediaList.value.find((media: Media) => media.id === item.media)?.file_type;

    if (mediaType === 'Image') {
      return item.duration !== null ? true : false;
    } else {
      return true;
    }
  });
});

async function submitForm() {
  const action = isEditMode.value ? EDIT : CREATE;
  try {
    isLoading.value = true;

    if (isEditMode.value) {
      await playlistsStore.editPlaylist();
    } else {
      await playlistsStore.createPlaylist();
    }
    isLoading.value = false;
    showSuccess(action);
    closeModal();
    resetForm();
    playlistsStore.resetPlaylistModalData();
  } catch (err: unknown) {
    isLoading.value = false;
    showError(action);
    throw new Error(err as string);
  } finally {
    await playlistsStore.fetchAllPlaylists();
  }
}

watch(
  () => isEditMode.value,
  (isActive) => {
    !isActive ? v$.value.$reset() : v$.value.$touch();
  }
);
</script>

<style lang="scss">
.playlist-create-edit-modal {
  transition: width 0.2s ease;
  max-width: 100%;
}
</style>
