<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="contacts-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}</Message
  >
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="contacts-table"
    :value="contacts"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="10"
    v-model:filters="filters"
    filterDisplay="menu"
    dataKey="id"
    class="p-datatable-sm mb-5 mt-6 border-rounded overflow-hidden"
    v-model:selection="selectedTarget"
    :metaKeySelection="false"
    :globalFilterFields="['name', 'type', 'email', 'phone_number']"
  >
    <template #header>
      <div class="flex justify-between items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            :placeholder="
              $t('searchBy', {
                name: `${$t('name')}, ${$t('type')}, ${$t('email')}, ${$t('contact.phoneNumber')}`,
              })
            "
            class="p-inputtext-sm"
          />
        </span>
        <Button
          v-if="activeRoles"
          data-cy="open-create-contact-modal"
          :label="$t('contact.addContact')"
          icon="pi pi-plus"
          class="p-button-sm p-button-primary"
          @click="openCreateContactModal"
        />
      </div>
    </template>
    <template #empty>
      <span data-cy="contact-table-empty" class="w-full block text-center">{{
        $t('contact.empty')
      }}</span>
    </template>
    <Column
      class="no-top-border-row"
      field="name"
      :header="$t('name')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 12rem"
    >
      <template #body="{ data }">
        <span data-cy="contact-name">{{ data.name }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="type"
      :header="$t('type')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 5rem"
    >
      <template #body="{ data }">
        <span data-cy="contact-type">{{ data.type === 'SMS' ? $t('sms') : $t('email') }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('type') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="address"
      :header="$t('address')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 15rem"
    >
      <template #body="{ data }">
        <span v-if="data.type === 'EMAIL' && data.email" data-cy="contact-address">{{
          data.email
        }}</span>
        <span v-if="data.type === 'SMS' && data.phone_number" data-cy="contact-address">{{
          data.phone_number
        }}</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('address') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="verified"
      :header="$t('contact.verified')"
      :sortable="true"
      :showFilterMatchModes="false"
      style="min-width: 5rem"
    >
      <template #body="{ data }">
        <Tag
          data-cy="contact-verified"
          class="mr-2"
          :severity="`${data.verified ? 'success' : 'info'}`"
          :value="`${data.verified ? 'True' : 'Pending'}`"
          rounded
        ></Tag>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="verifiedStates"
          :placeholder="$t('select', { name: $t('contact.verified') })"
          class="p-column-filter mb-2"
          :showClear="true"
        >
        </Dropdown>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="enabled"
      :header="$t('status')"
      :sortable="true"
      :showFilterMatchModes="false"
      style="min-width: 6rem"
    >
      <template #body="{ data }">
        <Tag
          data-cy="contact-enabled"
          class="mr-2"
          :severity="`${data.enabled ? 'success' : 'info'}`"
          :value="`${data.enabled ? 'Enabled' : 'Disabled'}`"
          rounded
        ></Tag>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="statuses"
          :placeholder="$t('select', { name: $t('status') })"
          class="p-column-filter mb-2"
          :showClear="true"
        >
        </Dropdown>
      </template>
    </Column>
    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }">
        <div class="flex">
          <Button
            data-cy="open-contact-verify-modal"
            :label="$t('contact.verify')"
            icon="pi pi-check-square"
            iconPos="left"
            :disabled="data.verified"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openVerifyModal(data)"
          />
          <!-- remove disabled state for Edit if sms contact type is connected -->
          <Button
            v-if="activeRoles"
            data-cy="open-edit-contact-modal"
            :label="$t('edit')"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            :disabled="data.type === 'SMS'"
            @click="openEditContactModal(data)"
          />
          <Button
            v-if="activeRoles"
            data-cy="open-delete-contact-modal"
            :label="$t('delete')"
            icon="pi pi-trash"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openDeleteContactModal(data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <ContactEditModal />
  <ContactVerifyModal />
  <ContactDeleteModal />
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useContactsStore } from '@/stores/admin/contacts/contacts.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api';
import { ACTIVE_MONITORING_ADMIN_ROLE, ADMINISTRATOR_ROLE } from '@/utils/constants';

import type { ContactEditSendData, ContactVerifyData } from '@/models/contacts.model';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import ContactEditModal from './ContactEditModal.vue';
import ContactVerifyModal from './ContactVerifyModal.vue';
import ContactDeleteModal from './ContactDeleteModal.vue';

const contactsStore = useContactsStore();
const {
  contacts,
  contactsModalIsOpen,
  contactEditionData,
  contactIsEditing,
  contactVerifyModalIsOpen,
  contactVerificationData,
  contactDeleteModalIsOpen,
  contactDeleteData,
} = storeToRefs(contactsStore);
const userStore = useUsersStore();
const loading = ref(true);
const notAuthorized = ref(false);
const selectedTarget = ref();
const verifiedStates = ref(['True', 'Pending']);
const statuses = ref(['Enabled', 'Disabled']);
const IS_ENABLED = ref('ENABLED');
const IS_VERIFIED = ref('VERIFIED');

const activeRoles = computed(
  () =>
    userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
    userStore.me.roles.map((role) => role.id).includes(ACTIVE_MONITORING_ADMIN_ROLE)
);

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  type: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  address: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
  },
  verified: { value: null, matchMode: IS_VERIFIED.value },
  enabled: { value: null, matchMode: IS_ENABLED.value },
});

const matchModes = ref([
  { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
  { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
  { label: 'Contains', value: FilterMatchMode.CONTAINS },
]);

FilterService.register(IS_VERIFIED.value, (value, filter): boolean => {
  const convertedValue = value ? 'True' : 'Pending';

  if (filter === undefined || filter === null) {
    return true;
  }

  return convertedValue === filter;
});

FilterService.register(IS_ENABLED.value, (value, filter): boolean => {
  const convertedValue = value ? 'Enabled' : 'Disabled';

  if (filter === undefined || filter === null) {
    return true;
  }

  return convertedValue === filter;
});

const openEditContactModal = (data: ContactEditSendData) => {
  contactEditionData.value = data;
  contactsModalIsOpen.value = true;
  contactIsEditing.value = true;
};

const openDeleteContactModal = (id: number) => {
  contactDeleteData.value.id = id;
  contactDeleteModalIsOpen.value = true;
};

const openVerifyModal = (data: ContactVerifyData) => {
  contactVerificationData.value = data;
  contactVerifyModalIsOpen.value = true;
};

const openCreateContactModal = () => {
  contactsModalIsOpen.value = true;
};

onMounted(
  async () =>
    await contactsStore
      .fetchContactsList()
      .catch((error) => {
        if (error.response.status === 403) {
          notAuthorized.value = true;
        } else {
          throw new Error('Contacts failed to be fetched');
        }
      })
      .finally(() => (loading.value = false))
);
</script>

<style lang="scss" scoped>
.page-title {
  color: $text-medium;
  font-weight: 600;
  font-size: 20px;
}
.page-subtitle {
  color: $text-light;
  font-weight: 400;
  font-size: 14px;
}
</style>
@/models/alerts.model
