<template>
  <div class="flex px-12 border-b text-sm py-1">
    <div class="w-full info-single">
      <ReadOnlyItem
        :name="t('frequency')"
        :value="selectedFrequency ? selectedFrequency.name : '--'"
      />
      <ReadOnlyItem :name="t('report.firstReport')">
        <date-time-display :overrideTimezone="'UTC'" :date="reportModalData.next_report_at" bold />
      </ReadOnlyItem>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import { useReportsStore } from '@/stores/admin/reports/reports.store';

import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import ReadOnlyItem from '@/components/common/readOnly/ReadOnlyItem.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const reportsStore = useReportsStore();
const { reportModalData, selectedFrequency } = storeToRefs(reportsStore);
</script>
