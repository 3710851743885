<template>
  <div class="modal-stepper flex justify-center">
    <div
      v-for="(step, idx) in steps"
      :key="idx"
      class="flex items-center py-3 transition-colors"
      :class="[isActive(step) ? 'text-green-primary' : 'text-gray-400']"
    >
      <div class="mx-2 flex items-center">
        <span
          class="flex items-center justify-center text-xs w-4 h-4 rounded-[3px] text-white transition-colors"
          :class="[isActive(step) ? 'bg-green-primary' : 'bg-gray-400']"
        >
          {{ idx + 1 }}
        </span>
        <span class="text-sm ml-2">{{ t(`dialogSteps.${step}`) }}</span>
      </div>
      <div v-if="idx !== steps.length - 1" class="w-6 h-[1px] bg-[#D9D9D9]"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  steps: string[] | readonly string[];
  activeStep: string | readonly string[];
  isEditMode?: boolean;
}>();

function isActive(step: string) {
  return step === props.activeStep;
}
</script>

<style lang="scss" scoped>
.modal-stepper {
  margin: 0 auto;
}
</style>
