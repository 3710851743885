<template>
  <div class="dpa-container" data-cy="dpa-dialogue">
    <img src="/static/auth/logo.png" alt="Nidec Logo" class="nidec-logo" />
    <form @submit.prevent="acceptDpa" data-cy="accept-dpa-form" class="accept-dpa-card">
      <div class="header">
        <h1>Complete Your Registration</h1>
        <p>
          To use the Nidec By Your Side platform you need to agree to agree to our Data Privacy
          Agreement (DPA)
        </p>
      </div>
      <div>
        <input
          type="checkbox"
          id="dpaAccepted"
          name="dpaAccepted"
          v-model="dpaAccepted"
          data-cy="dpa-boolean-input"
          required
        />
        <label for="dpaAccepted"
          >I have read and accept the <a href="/static/auth/dpa.html" target="_blank">DPA</a></label
        >
      </div>
      <div class="button-group">
        <button type="button" class="deny" @click="rejectDpa" data-cy="dpa-deny-button">
          Deny
        </button>
        <button type="submit" class="accept" :disabled="!dpaAccepted" data-cy="dpa-submit-button">
          Accept
        </button>
      </div>
    </form>
    <div v-if="showDenyConfirmation" class="reject-card" data-cy="dpa-deny-confirmation-dialogue">
      <div class="left">
        <exclamation-triangle-icon class="warning-icon" />
      </div>
      <div class="right">
        <h2>Are you sure?</h2>
        <p>
          By declining the DPA your account will be deleted. Your administrator will need to invite
          you again.
        </p>
        <div class="button-group">
          <button
            type="button"
            class="confirm-reject"
            @click="confirmRejectDpa"
            data-cy="dpa-deny-confirm-button"
          >
            <trash-icon class="button-icon" />
            Yes, Delete My Account
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUsersStore } from '@/stores/admin/users/users.store';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { logout } from '@/services/auth/config';

const usersStore = useUsersStore();
const router = useRouter();

const isLoading = ref(true);
const showDenyConfirmation = ref(false);

const dpaAccepted = ref(false);

const acceptDpa = async () => {
  isLoading.value = true;
  //.on error do nothing till the backend is ready
  await usersStore.sendDpaPreference(true).catch(console.error);
  await router.push('/');
  isLoading.value = false;
};

const rejectDpa = async () => {
  showDenyConfirmation.value = true;
};

const confirmRejectDpa = async () => {
  await usersStore.sendDpaPreference(false).catch(console.error);
  await logout();
};
</script>

<style scoped lang="scss">
.dpa-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: min(40rem, 90%);
  margin: 0 auto;
}

.accept-dpa-card {
  background-color: var(--gray-0);
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
  width: 100%;
  padding: 1.5rem;
}

.header {
  margin-bottom: 1rem;
}

h1 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  color: var(--gray-600);
}

p {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: var(--gray-300);
}

a {
  text-decoration: underline;
}

label {
  margin-left: 0.5rem;
}

.button-group {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}

button {
  padding: 0.5rem 1rem;
  border-radius: var(--rounded-md);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed !important;
    background-color: var(--gray-200) !important;
  }
}

button.accept {
  background-color: var(--green-primary);
  color: var(--gray-0);

  &:hover {
    background-color: var(--green-primary-dark);
    cursor: pointer;
  }
}

button.deny {
  color: var(--gray-400);
  border: 1px solid var(--gray-200);

  &:hover {
    background-color: var(--gray-100);
    cursor: pointer;
  }
}

button.confirm-reject {
  background-color: var(--red-primary);
  color: var(--gray-0);

  &:hover {
    background-color: var(--red-primary-dark);
    cursor: pointer;
  }
}

div.reject-card {
  display: flex;
  width: 100%;
  border: 1px solid var(--gray-200);
  border-radius: var(--rounded-md);
  background-color: var(--gray-0);
}

div.left {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-right: 1px solid var(--gray-200);
}

div.right {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

h2 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  color: var(--gray-600);
}

.warning-icon {
  color: var(--red-primary);
  height: 1.5rem;
}

.button-icon {
  height: 1rem;
  color: var(--gray-0);
}

.nidec-logo {
  width: 80px;
}
</style>
