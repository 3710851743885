<template>
  <page-header :title="t('contacts')" :description="t('contact.description.main')" />
  <div class="container container-grey">
    <ContactsTable />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import PageHeader from '@/components/common/layout/PageHeader.vue';
import ContactsTable from '@/components/contacts/ContactsTable.vue';

const { t } = useI18n();
</script>
