import type { IdName } from './common.model';

export enum DataExportStep {
  Configuration = 'configuration',
  Assets = 'assets',
  Preview = 'preview',
}

export interface DataExport {
  id: number;
  name: string;
  systems: IdName[];
  created_at: string | Date | null;
  datatypes: number[];
  start_datetime: string | null;
  end_datetime: string | null;
  status: string;
}

export interface DataExportEditData {
  name: string;
  systems: number[];
  datatypes: number[];
  start_datetime: string | null;
  end_datetime: string | null;
}

export interface DataExportCreationData {
  name: string;
  systems: number[];
}

export interface DataTypes {
  id: number;
  name: string;
  description: string;
}

export enum DataExportStatus {
  Processing = 'Processing',
  Error = 'Error',
  Complete = 'Complete',
}
