<template>
  <div class="flex justify-between items-center">
    <label for="test" class="text-sm font-medium text-gray-500">
      {{ $t('frequency') }}
    </label>
    <div class="flex flex-col items-end">
      <Dropdown
        data-cy="report-schedule-picker-frequency"
        v-model="reportModalData.frequency"
        class="timezone-picker"
        optionLabel="name"
        optionValue="id"
        scrollHeight="240px"
        :options="reportsStore.frequencies"
        :placeholder="$t('select')"
        @blur="validateFrequency"
      />
    </div>
  </div>
  <div class="flex justify-between items-center">
    <label v-if="!isEditMode" for="test" class="text-sm font-medium text-gray-500">
      {{ $t('report.firstReport') }}
    </label>
    <label v-else for="test" class="text-sm font-medium text-gray-500">
      {{ $t('report.nextReport') }}
    </label>
    <div class="flex flex-col items-end">
      <DateTimeInput
        id="first-report-time"
        data-cy="report-schedule-first-report-time"
        class="w-[215px] calendar-input-sm"
        :placeholder="$t('report.firstReport')"
        v-model="reportModalData.next_report_at"
        :min-date-time="minFirstReportAt"
        :displayTimezoneOverride="'UTC'"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useReportsStore } from '@/stores/admin/reports/reports.store';
import dayjs from 'dayjs';

import Dropdown from 'primevue/dropdown';
import DateTimeInput from '@/components/common/time/DateTimeInput.vue';

import type { Validation } from '@vuelidate/core';
import type { reportInstantTypeRules, reportPeriodicTypeRules } from '@/utils/reportModalRules';

const reportsStore = useReportsStore();
const { reportModalData, isEditMode } = storeToRefs(reportsStore);
const loading = ref(false);

const props = defineProps<{
  v$: Validation<typeof reportInstantTypeRules | typeof reportPeriodicTypeRules>;
}>();

function getDefaultNextReport() {
  return dayjs().add(1, 'd').startOf('day').toISOString();
}

// earliest time is tomorrow - User Story 3043
const minFirstReportAt = ref(dayjs().tz('UTC').add(1, 'd').startOf('day').toISOString());

const validateFrequency = () => props.v$.frequency.$touch();

onMounted(async () => {
  if (!reportModalData.value.next_report_at) {
    reportModalData.value.next_report_at = getDefaultNextReport();
  }

  if (!reportsStore.frequencies?.length) {
    loading.value = true;
    await reportsStore.fetchFrequencies();
    loading.value = false;
  }
});
</script>
