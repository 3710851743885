import axios from 'axios';
import type { AxiosPromise, AxiosResponse } from 'axios';
import type {
  ChargeParkEdit,
  ChargeParkDetailsEdition,
  ChargePark,
} from '@/models/chargeParks.model';

export function fetchChargeParks(): Promise<AxiosResponse<ChargePark[]>> {
  return axios.get<ChargePark[]>(`/api/charge-parks/`, { headers: { uid: 1 } }).catch((error) => {
    return Promise.reject(error);
  });
}

export function fetchSingleChargePark(id: number): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/charge-parks/${id}/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function fetchChargeParksForOrganisation(id: number): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/charge-parks/?owner_organisation_id=${id}`, {
      headers: {
        uid: 1,
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function createChargePark(data: ChargeParkEdit): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/charge-parks/`, data, {
      headers: {
        uid: 1,
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function editChargePark(id: number, data: ChargeParkEdit): Promise<AxiosResponse> {
  return axios
    .put<AxiosPromise>(`/api/charge-parks/${id}/`, data, {
      headers: {
        uid: 1,
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function editSingleChargePark(
  id: number,
  data: ChargeParkDetailsEdition
): Promise<AxiosResponse> {
  return axios
    .patch<AxiosPromise>(`/api/charge-parks/${id}/`, data, {
      headers: {
        uid: 1,
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function fetchDays(): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/day-of-week/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function fetchAccessTypes(): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/charge-park-access-types/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function fetchPaymentMethods(): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/charge-park-payment-methods/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function fetchStationTypes(): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/charge-park-station-types/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}
