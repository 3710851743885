<template>
  <div class="flex px-12 border-b text-sm pt-2 pb-1">
    <div class="w-full info-single">
      <ReadOnlyTitle :title="$t('chargeParks')" />
      <div v-for="(chargePark, index) in selectedChargeParks" :key="index">
        <ReadOnlyItem
          :name="chargePark?.name"
          :value="buildValue(chargePark)"
          :alert="!getNumberOfCompatibleSystems(chargePark)"
          connector
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import ReadOnlyItem from '@/components/common/readOnly/ReadOnlyItem.vue';
import ReadOnlyTitle from '@/components/common/readOnly/ReadOnlyTitle.vue';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import type { ChargePark } from '@/models/chargeParks.model';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useReportsStore } from '@/stores/admin/reports/reports.store';

const reportsStore = useReportsStore();
const { selectedTemplate } = storeToRefs(reportsStore);
const chargeParksStore = useChargeParksStore();
const { chargeParks } = storeToRefs(chargeParksStore);
const { t } = useI18n();

const selectedChargeParks = computed(() => {
  if (!chargeParks.value) return [];

  return chargeParks.value.filter((chargePark: ChargePark) =>
    reportsStore.reportModalData.charge_parks.includes(chargePark.id)
  );
});

const compatibleSystems = computed(() => selectedTemplate.value?.supportedProductTypes);

const getNumberOfCompatibleSystems = (chargePark: ChargePark) => {
  if (!compatibleSystems.value) return 0;

  return compatibleSystems.value.reduce((acc, productType) => {
    const numberOfSystems = chargePark?.number_of_systems_by_product_type?.[productType] ?? 0;

    return acc + numberOfSystems;
  }, 0);
};

const buildValue = (chargePark: ChargePark) => {
  const numberOfCompatibleSystems = getNumberOfCompatibleSystems(chargePark);

  return `${numberOfCompatibleSystems} ${t('hardwareSystem.compatibleSystems')}`;
};
</script>
