<template>
  <div class="p-4">
    <div class="w-full flex space-between items-baseline mb-2">
      <h2 class="flex-1 text-gray-700 font-medium">{{ $t('playlists.mediaBrowser') }}</h2>
      <CheckboxMultiFilter
        :items="filterOptions"
        :title="$t('type')"
        :labelWidth="65"
        :selected="selectedTypes"
        @update:selected="setSelectedTypes"
      />
    </div>

    <div>
      <span class="p-input-icon-left !w-full">
        <i class="pi pi-search" />
        <InputText v-model="mediaSearch" :placeholder="$t('search')" class="p-inputtext-sm" />
      </span>
    </div>

    <div class="bg-white mt-2 flex flex-col divide-y rounded-md border border-gray-200 h-[285px]">
      <div v-if="loading" class="w-full h-full flex justify-center items-center">
        <i class="pi pi-spin pi-spinner text-xl" />
      </div>
      <VirtualScroller v-else :items="filteredMediaList" :itemSize="64">
        <template v-slot:item="{ item }">
          <MediaItem
            class="media-item [&:not(:first-child)]:border-t"
            v-bind="{ item }"
            @add="addMedia(item)"
          />
        </template>
      </VirtualScroller>
    </div>
  </div>
</template>

<script setup lang="ts">
import InputText from 'primevue/inputtext';
import VirtualScroller from 'primevue/virtualscroller';
import { useMediaStore } from '@/stores/admin/media/media.store';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

import MediaItem from '@/components/playlists/picker/MediaItem.vue';
import CheckboxMultiFilter from '@/components/common/CheckboxMultiFilter.vue';
import { MediaType } from '@/models/media.model';

import type { Media } from '@/models/media.model';

const mediaStore = useMediaStore();
const { mediaList } = storeToRefs(mediaStore);

const emit = defineEmits<{
  addMedia: [item: Media];
}>();

const mediaSearch = ref('');
const selectedTypes = ref<string[]>([]);
const loading = ref(false);

const setSelectedTypes = (data: string[]) => {
  selectedTypes.value = data;
};

const filterOptions = [
  { id: MediaType.Image as string, name: 'Image' },
  { id: MediaType.Video as string, name: 'Video' },
];


const filteredMediaList = computed(() => { 
  if (!mediaSearch.value && !selectedTypes.value.length) {
    return mediaList.value;
  }

  return mediaList.value.filter((media) => {

    if (
      mediaSearch.value &&
      !media.file_name.toLowerCase().includes(mediaSearch.value.toLowerCase())
    ) {
      return false;
    }

    return !(selectedTypes.value.length && !selectedTypes.value.includes(media.file_type));
  });
});

const addMedia = (media: Media) => {
  emit('addMedia', media);
};

onMounted(async () => {
  if(!mediaStore.processedMediaStatusID) mediaStore.fetchMediaStatus().then()
  if (!mediaList.value.length) {
    loading.value = true;
    await mediaStore.fetchMediaList();
    loading.value = false;
  }
});
</script>
