<template>
  <TariffReadOnly />
  <div class="border-b"></div>
  <div class="px-12">
    <div class="field flex flex-col items-center pt-4 pb-6">
      <div class="flex flex-col w-full mb-3">
        <label id="tariffNameLabel" for="tariffName" class="title font-medium text-gray-600">
          {{ $t('tariff.configureDefaultRate') }}<span class="required">*</span>
        </label>
        <span class="text-sm text-gray-400">{{ $t('tariff.configureDefaultRateInfo') }}</span>
      </div>
      <TariffRateInputs :rate="defaultRateData" :type="1" @updateRateInputs="updateDefaultRate" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';
import { isDefaultRate } from '@/models/tariffs.model';
import { TWO_DIGITS, THREE_DIGITS } from '@/utils/constants';
import { normalizeData } from '@/utils/rates';

import TariffRateInputs from '../rate/TariffRateInputs.vue';
import TariffReadOnly from '@/components/tariffs/rate/TariffReadOnly.vue';

import type { tariffRules } from '@/utils/tariffModalRules';
import type { Validation } from '@vuelidate/core';
import type { Rate } from '@/models/tariffs.model';

const tariffsStore = useTariffsStore();
const { tariffModalData, defaultRateData } = storeToRefs(tariffsStore);

defineProps<{
  v$: Validation<typeof tariffRules>;
}>();

const updateDefaultRate = (data: Rate) => {
  (defaultRateData.value.session_fee_cent = normalizeData(data.session_fee_cent, TWO_DIGITS)),
    (defaultRateData.value.session_fee_tax_percent = normalizeData(
      data.session_fee_tax_percent,
      TWO_DIGITS
    )),
    (defaultRateData.value.price_per_kwh_tenth_cent = normalizeData(
      data.price_per_kwh_tenth_cent,
      THREE_DIGITS
    )),
    (defaultRateData.value.price_per_kwh_tax_percent = normalizeData(
      data.price_per_kwh_tax_percent,
      TWO_DIGITS
    )),
    (defaultRateData.value.idle_fee_tenth_cent_per_min = normalizeData(
      data.idle_fee_tenth_cent_per_min,
      THREE_DIGITS
    )),
    (defaultRateData.value.idle_fee_tax_percent = normalizeData(
      data.idle_fee_tax_percent,
      TWO_DIGITS
    )),
    (defaultRateData.value.idle_fee_grace_period_min = data.idle_fee_grace_period_min),
    (defaultRateData.value.charging_duration_fee_tenth_cent = normalizeData(
      data.charging_duration_fee_tenth_cent,
      THREE_DIGITS
    )),
    (defaultRateData.value.charging_duration_fee_tax_percent = normalizeData(
      data.charging_duration_fee_tax_percent,
      TWO_DIGITS
    )),
    (defaultRateData.value.fixed_tax_session_cent = normalizeData(
      data.fixed_tax_session_cent,
      TWO_DIGITS
    )),
    (defaultRateData.value.fixed_tax_parking_cent = normalizeData(
      data.fixed_tax_parking_cent,
      TWO_DIGITS
    )),
    (defaultRateData.value.pre_authorization_amount_cent = normalizeData(
      data.pre_authorization_amount_cent,
      TWO_DIGITS
    ));
};

onMounted(() => {
  const defaultRate = tariffModalData.value.rates.find(isDefaultRate);

  if (!defaultRate) {
    tariffModalData.value.rates.push({
      type: 1,
      session_fee_cent: 0,
      session_fee_tax_percent: 0,
      price_per_kwh_tenth_cent: 0,
      price_per_kwh_tax_percent: 0,
      idle_fee_tenth_cent_per_min: 0,
      idle_fee_tax_percent: 0,
      idle_fee_grace_period_min: 0,
      charging_duration_fee_tenth_cent: 0,
      charging_duration_fee_tax_percent: 0,
      fixed_tax_session_cent: 0,
      fixed_tax_parking_cent: 0,
      pre_authorization_amount_cent: 0,
    } as Rate);
  }
});

onUnmounted(() => tariffsStore.resetRateDate());
</script>

<style scoped lang="scss"></style>
