<template>
  <div>
    <PanelWithEdit :editBtn="false">
      <template #customHeader>
        <div class="flex justify-between w-full items-center">
          <span class="p-panel-title">{{ rate.name }}</span>
          <div class="flex">
            <Button
              data-cy="edit-special-tariff"
              icon="pi pi-pencil"
              class="p-button-sm p-button-outlined p-button-plain p-button-sm mr-2"
              :disabled="tariffCreateSpecialRateIsOpen"
              @click="openEditSpecialRate"
            />
            <Button
              data-cy="delete-special-tariff"
              icon="pi pi-trash"
              class="p-button-sm p-button-outlined p-button-plain p-button-sm"
              @click="deleteSpecialRate"
            />
          </div>
        </div>
      </template>
      <div class="flex justify-between">
        <TariffRateDateAndTime :rate="rate" :name="false" class="w-1/2 mt-2" />
        <span class="border-r m-4"></span>
        <TariffRate
          :rate="rate"
          :currencySymbol="props.currencySymbol"
          :currencySymbolPosition="props.currencySymbolPosition"
          class="w-1/2 mt-2"
        />
      </div>
    </PanelWithEdit>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import TariffRate from '@/components/tariffs/rate/TariffRate.vue';
import TariffRateDateAndTime from '@/components/tariffs/rate/TariffRateDateAndTime.vue';
import Button from 'primevue/button';

import type { SpecialRate } from '@/models/tariffs.model';

const props = defineProps<{
  rate: SpecialRate;
  rateIndex: number;
  currencySymbol: string | undefined;
  currencySymbolPosition: string;
}>();

const tariffStore = useTariffsStore();
const {
  rateDeleteData,
  rateDeleteModalIsOpen,
  rateEditId,
  tariffCreateSpecialRateIsOpen,
  tariffSpecialRateIsEditing,
} = storeToRefs(tariffStore);

const openEditSpecialRate = () => {
  rateEditId.value = props.rateIndex;
  tariffCreateSpecialRateIsOpen.value = true;
  tariffSpecialRateIsEditing.value = true;
};

const deleteSpecialRate = () => {
  rateDeleteData.value = {
    id: props.rateIndex,
    name: props.rate.name,
  };
  rateDeleteModalIsOpen.value = true;
};
</script>
<style scoped lang="scss">
:deep(.p-panel-header) {
  background-color: #fff;
}

:deep(.p-panel) {
  &.no-buttons {
    .p-panel-header {
      padding: 10px 16px;
    }
  }
}

:deep(.p-button) {
  &.p-button-sm {
    padding: 6px 14px;
  }
  &.p-button-icon-only {
    width: auto;
  }
}
</style>
