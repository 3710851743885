<template>
  <TariffReadOnly />
  <div class="border-b"></div>
  <div class="flex justify-between items-center px-12 pt-2">
    <div class="flex flex-col w-5/6 my-3">
      <label id="tariffNameLabel" for="tariffName" class="title font-medium text-gray-600">
        {{ $t('tariff.specialRates') }}
      </label>
      <span class="text-sm text-gray-400">{{ $t('tariff.specialRatesCreateDescription') }}</span>
    </div>
    <Button
      data-cy="add-special-rate-button"
      class="p-button-primary p-button-sm ml-6 whitespace-nowrap"
      icon="pi pi-plus"
      :label="$t('tariff.addSpecialRate')"
      :disabled="tariffCreateSpecialRateIsOpen"
      @click="openSpecialRateCreateEditView"
    />
  </div>
  <div class="px-12">
    <TariffSpecialRateCreateEdit
      v-if="tariffCreateSpecialRateIsOpen && (rateEditId === undefined || rateEditId === null)"
      class="mb-4"
    />
    <div v-for="(rate, index) in specialRates" :key="index">
      <TariffSpecialRateCreateEdit
        v-if="tariffCreateSpecialRateIsOpen && rateEditId === index"
        class="mb-4"
        :rate="(rate as SpecialRate)"
        :currencySymbol="tariffModalData.currency_symbol"
      />
      <TariffSpecialRateEditPreview
        v-if="
          !tariffCreateSpecialRateIsOpen || (tariffCreateSpecialRateIsOpen && rateEditId !== index)
        "
        data-cy="special-rate-preview-card"
        :rate="(rate as SpecialRate)"
        :rateIndex="index"
        :currencySymbol="tariffModalData.currency_symbol"
        :currencySymbolPosition="tariffModalData.currency_symbol_position"
        class="my-4"
      />
    </div>
  </div>
  <TariffSpecialRateDeleteModalVue />
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';
import { isSpecialRate } from '@/models/tariffs.model';

import TariffSpecialRateEditPreview from '../rate/TariffSpecialRateEditPreview.vue';
import TariffSpecialRateCreateEdit from '../rate/TariffSpecialRateCreateEdit.vue';
import TariffSpecialRateDeleteModalVue from '../rate/TariffSpecialRateDeleteModal.vue';
import Button from 'primevue/button';
import TariffReadOnly from '@/components/tariffs/rate/TariffReadOnly.vue';

import type { tariffRules } from '@/utils/tariffModalRules';
import type { Validation } from '@vuelidate/core';
import type { SpecialRate } from '@/models/tariffs.model';

const tariffsStore = useTariffsStore();
const { tariffModalData, tariffCreateSpecialRateIsOpen, rateEditId } = storeToRefs(tariffsStore);

defineProps<{
  v$: Validation<typeof tariffRules>;
}>();

const openSpecialRateCreateEditView = () => {
  tariffCreateSpecialRateIsOpen.value = true;
};

const specialRates = computed(() => {
  return tariffModalData.value.rates.filter(isSpecialRate);
});
</script>

<style scoped lang="scss"></style>
