export const iconColors = {
  warning: '#f2994a',
  info: '#287bff',
  success: '#009B4A',
  error: '#ce0000',
};

export const infoIconText = [
  'info',
  'settings change',
  'command executed',
  'request',
  'installing',
  'downloading',
  'scheduled',
  'idle',
  'debug',
  'running',
  'json event',
  'outage',
  'receipt',
];

export const errorIconText = ['error', 'failed'];

export const successIconText = ['success', 'ok'];

export const getIcon = (event: string) => {
  if (successIconText.some((word) => event.toLowerCase().includes(word))) {
    return 'pi pi-check-circle';
  } else {
    return 'pi pi-exclamation-triangle';
  } 
};

export const getIconColor = (event: string) => {
  if (event.toLowerCase().includes('warning')) {
    return iconColors.warning;
  }
  if (infoIconText.some((word) => event.toLowerCase().includes(word))) {
    return iconColors.info;
  }
  if (successIconText.some((word) => event.toLowerCase().includes(word))) {
    return iconColors.success;
  }
  if (errorIconText.some((word) => event.toLowerCase().includes(word))) {
    return iconColors.error;
  }
};
