<template>
  <div class="flex justify-between border-bottom bg-white">
    <div class="flex items-center ml-4">
      <img src="/src/assets/icons/logo.svg" class="logo" alt="" />
    </div>

    <div class="ml-auto m-1">
      <button
        data-cy="user-menu-button"
        aria-haspopup="true"
        aria-controls="user_menu"
        class="flex px-1 py-1 items-center h-full rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 hover:bg-gray-50 border-white border hover:border-gray-200"
        @click="toggleMenu"
      >
        <div
          class="rounded-full bg-gray-100 border border-gray-200 w-8 h-8 mr-2 flex items-center justify-center"
        >
          <i class="pi pi-user text-gray-400" />
        </div>
        <div class="flex flex-col items-start mr-1">
          <span
            v-if="me.name"
            class="text-sm text-gray-700 font-semibold"
            data-cy="header-user-name"
          >
            {{ me.name }}
          </span>
          <span
            v-if="me.email"
            class="text-xs text-gray-400 font-medium"
            data-cy="header-user-email"
          >
            {{ me.email }}
          </span>
        </div>
      </button>
      <Menu
        ref="menu"
        id="user_menu"
        :model="items"
        :popup="true"
        class="mt-4"
        @show="removeDefaultSelection"
      >
        <template #item="{ item }">
          <div
            class="py-3 px-4 font-semibold cursor-pointer"
            :class="{ 'border-b': isNotLastItem(item) }"
          >
            <router-link
              v-if="item.route"
              :to="item.route"
              class="flex items-center text-sm text-gray-600 !no-underline"
              :data-cy="item?.dataCy"
            >
              <i :class="[item.icon, 'mr-2 text-xs']" />
              {{ item.label }}
            </router-link>
            <span v-else class="text-sm text-gray-600" :data-cy="item?.dataCy">
              <i :class="[item.icon, 'mr-2 text-xs']" />
              {{ item.label }}
            </span>
          </div>
        </template>
      </Menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import Menu from 'primevue/menu';
import { useWhitelistsStore } from '@/stores/admin/whitelists/whitelists.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { logout } from '@/services/auth/config';

import type { MenuItem } from 'primevue/menuitem';

const route = useRoute();
const whitelistsStore = useWhitelistsStore();
const userStore = useUsersStore();
const { me } = storeToRefs(userStore);

const { t } = useI18n();

const menu = ref();

const items = ref<MenuItem[]>([
  {
    label: t('settings'),
    icon: 'pi pi-cog',
    route: { name: 'UserPreferences' },
  },
  {
    label: t('logout'),
    icon: 'pi pi-external-link',
    dataCy: 'logout-button',
    command: () => logout(),
  },
]);

const toggleMenu = (event: any) => menu.value.toggle(event);

const isNotLastItem = (item: MenuItem) => {
  const index = items.value.indexOf(item);
  return index !== items.value.length - 1;
};

const setPageHeaderDetails = () => {
  const id = Number(route.params.id);
  whitelistsStore.setWhitelistDetailsView(id);
};

const removeDefaultSelection = () => {
  menu.value.focusedOptionIndex = -1; // https://github.com/primefaces/primevue/issues/3210
};

onMounted(async () => {
  if (route.params.id) setPageHeaderDetails();
  await userStore.init();
});
</script>
