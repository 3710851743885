<template>
  <Dialog
    data-cy="tariff-delete-modal"
    :header="`${modalTitle} - ${tariffDetails.name}`"
    v-model:visible="tariffDeleteModalIsOpen"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <div class="field flex flex-col field-edit">
      <Message severity="error" icon="pi pi-exclamation-triangle" :closable="false">
        {{ $t('deleteWarning') }}
      </Message>
      <div v-if="tariffDetails.charge_parks.length">
        <p data-cy="tariff-delete-modal-warning" class="delete-info my-2">
          {{ $t('tariff.deleteInfoWithSites') }}
        </p>
        <ul v-for="site in tariffDetails.charge_parks" :key="site.id">
          <li class="list-item">
            <span data-cy="tariff-delete-modal-site-affected" class="delete-info">{{
              site.name
            }}</span>
          </li>
        </ul>
      </div>
      <span data-cy="tariff-delete-modal-warning" v-else>{{
        $t('tariff.deleteInfoWithoutSites')
      }}</span>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        data-cy="tariff-delete-button"
        class="p-button-sm p-button-footer p-button-error mx-auto mb-2 block mt-5"
        :label="t('delete')"
        :loading="loading"
        @click="deleteTariff"
      />
    </template>
  </Dialog>
  <Toast
    data-cy="tariff-delete-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="delete-tariff"
  >
    <template v-if="isFailed" #message="slotProps">
      <div class="flex flex-col w-full pt-1">
        <div class="w-full flex">
          <span class="p-toast-message-icon pi pi-info-circle"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
          </div>
        </div>
      </div>
    </template>
  </Toast>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';
import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';
import { TARIFFS } from '@/utils/routeNames';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Message from 'primevue/message';
import Toast from 'primevue/toast';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';

const { t } = useI18n();
const toast = useToast();
const router = useRouter();
const store = useTariffsStore();
const { tariffDeleteModalIsOpen, tariffDetails } = storeToRefs(store);

const isFailed = ref(false);
const loading = ref(false);

const modalTitle = computed(() => t('delete', { itemName: t('tariff.title') }));

const successSummary = computed(() => {
  return t('toast.success', {
    name: t('tariff.title'),
    action: t('toast.deleted'),
  });
});

const errorSummary = computed(() => {
  return t('toast.error', {
    action: t('toast.removal'),
    name: t('tariff.title'),
  });
});

const showSuccess = () => {
  toast.add({
    severity: 'success',
    summary: successSummary.value,
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'delete-tariff',
  });
};

const showError = () => {
  toast.add({
    severity: 'error',
    summary: errorSummary.value,
    group: 'delete-tariff',
  });
};

const closeModal = () => {
  tariffDeleteModalIsOpen.value = false;
  //store.resetTariffDetails();
  store.tariffDeleteId = null;
  loading.value = false;
  toast.removeGroup('delete-tariff');
};

const deleteTariff = async () => {
  loading.value = true;
  toast.removeGroup('delete-tariff');

  try {
    await store.deleteTariff();
    toast.removeGroup('delete-tariff');
    showSuccess();
    closeModal();
    router.push({ path: `/${TARIFFS}` });
  } catch (error: any) {
    loading.value = false;
    toast.removeGroup('delete-tariff');
    if (error.response && error.response.status === 400 && error.response.data.failed_items) {
      isFailed.value = true;
      showError();
      closeModal();
    } else {
      showError();
    }
    throw new Error(error);
  } finally {
    loading.value = false;
  }
};
</script>
<style scoped lang="scss">
.list-item {
  list-style: disc;
  margin-left: 22px;
}
</style>
