<template>
  <div>
    <PanelWithEdit :editBtn="false">
      <template #customHeader>
        <div class="flex flex-wrap">
          <span class="p-panel-title w-full">{{ $t('tariff.specialRate') }}</span>
          <span class="p-panel-subtitle w-full pt-1">{{
            $t('tariff.specialRateDescription')
          }}</span>
        </div>
      </template>
      <div v-if="specialRates.length === 0" class="w-full p-2 text-center">
        <span class="text-sm text-gray-400">{{ $t('tariff.noSpecialRates') }}</span>
      </div>
      <div v-else v-for="(rate, index) in specialRates" :key="index">
        <div v-if="index !== 0" class="border-t my-2"></div>
        <TariffRateDateAndTime :rate="rate" />
        <TariffRate
          :rate="rate"
          :currencySymbol="props.tariff.currency_symbol"
          :currencySymbolPosition="props.tariff.currency_symbol_position"
        />
      </div>
    </PanelWithEdit>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import TariffRate from '@/components/tariffs/rate/TariffRate.vue';
import TariffRateDateAndTime from '@/components/tariffs/rate/TariffRateDateAndTime.vue';

import type { Tariff } from '@/models/tariffs.model';
import { isSpecialRate } from '@/models/tariffs.model';

const props = defineProps<{
  tariff: Tariff;
}>();

const specialRates = computed(() => {
  return props.tariff.rates.filter(isSpecialRate);
});
</script>
<style scoped lang="scss"></style>
