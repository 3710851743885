<template>
  <TariffReadOnly showSpecialRates />
  <div class="border-b"></div>
  <ChargeParkPicker
    assets-column
    tarriff-column
    class="pt-4 tariff-sites"
    owner-label="organisation"
    select-mode="multiple"
    :value="tariffModalData.charge_parks"
    :show-location="false"
    @on-charge-park-select="setSelectedChargePark"
    @on-charge-park-unselect="unselectChargePark"
    @on-charge-park-select-all="selectAllChargeParks"
    @on-charge-park-unselect-all="unselectAllChargeParks"
  >
    <template #header>
      <p class="block title font-medium field-edit-label">
        {{ $t('report.site.title') }}<span class="required">*</span>
      </p>
    </template>
    <template #subheader>
      <p class="block text-grey text-sm">{{ $t('tariff.sitesInfo') }}</p>
    </template>
  </ChargeParkPicker>
  <div class="px-12 mb-6">
    <warning-bar
      v-if="chargeParksWithTariff.length > 0"
      title="Tariff Conflict"
      :message="
        t('warnings.sites.resourceConflict', {
          sites: chargeParksWithTariff.join(', '),
          resource: 'tariff',
          count: chargeParksWithTariff.length,
        })
      "
    />
  </div>
</template>
<script setup lang="ts">
import { computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';

import ChargeParkPicker from '@/components/chargeParks/ChargeParkPicker.vue';
import WarningBar from '@/components/common/bar/WarningBar.vue';
import TariffReadOnly from '@/components/tariffs/rate/TariffReadOnly.vue';

import type { tariffRules } from '@/utils/tariffModalRules';
import type { Validation } from '@vuelidate/core';
import type { ChargePark } from '@/models/chargeParks.model';
import { useI18n } from 'vue-i18n';

const tariffsStore = useTariffsStore();
const { tariffModalData, tariffEditId } = storeToRefs(tariffsStore);
const chargeParksStore = useChargeParksStore();
const { chargeParks } = storeToRefs(chargeParksStore);
const { t } = useI18n();

const props = defineProps<{
  v$: Validation<typeof tariffRules>;
}>();

const chargeParksWithTariff = computed(() => {
  return chargeParks.value
    .filter((chargePark) => chargePark.tariff)
    .filter(
      (item) =>
        tariffModalData.value.charge_parks.includes(item.id) &&
        item.tariff?.id !== tariffEditId.value
    )
    .map((chargePark) => chargePark.name);
});

const setSelectedChargePark = (chargePark: ChargePark) => {
  tariffModalData.value.charge_parks.push(chargePark.id);
};

const unselectChargePark = (chargePark: ChargePark) => {
  tariffModalData.value.charge_parks = tariffModalData.value.charge_parks.filter(
    (id: number) => id !== chargePark.id
  );
};

const selectAllChargeParks = (chargeParks: { id: number }[]) => {
  tariffModalData.value.charge_parks = chargeParks.map((chargePark) => chargePark.id);
};

const unselectAllChargeParks = () => {
  tariffModalData.value.charge_parks = [];
};

watch(
  () => tariffModalData.value.charge_parks,
  () => {
    props.v$.charge_parks.$touch();
  },
  { deep: true }
);
</script>

<style scoped lang="scss"></style>
