<template>
  <page-header
    :title="(tariffDetails.name as string)"
    :subtitle="t('tariff.title')"
    show-back-button
  >
    <template v-slot:buttons>
      <custom-button
        data-cy="open-edit-tariff-view"
        :disabled="!tariffDetails.writable"
        :disabled-tooltip="
          t('permissions.info.parentOrgOwnsResource.editNotAllowed', {
            resource: t('resources.tariff', { count: 1 }),
          })
        "
        @click="openTariffEditModal()"
      >
        <pencil-icon class="w-5 h-5" />
        <span>{{ t('edit') }}</span>
      </custom-button>
      <custom-button
        data-cy="open-delete-tariff-view"
        :disabled="!tariffDetails.writable"
        :disabled-tooltip="
          t('permissions.info.parentOrgOwnsResource.deleteNotAllowed', {
            resource: t('resources.tariff', { count: 1 }),
          })
        "
        @click="openTariffDeleteModal()"
      >
        <trash-icon class="w-5 h-5" />
        <span>{{ t('delete') }}</span>
      </custom-button>
    </template>
  </page-header>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <div v-if="!loading" class="container container-grey">
    <div class="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4">
      <div>
        <TariffInfoPreview :tariff="tariffDetails" class="mb-4" />
        <TariffSitesPreview :tariff="tariffDetails" />
      </div>
      <div>
        <TariffDefaultRatePreview :tariff="tariffDetails" class="mb-4" />
        <TariffSpecialRatePreview :tariff="tariffDetails" class="" />
      </div>
    </div>
  </div>
  <TariffCreateEditModal />
  <TariffDeleteModal />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { onMounted, ref, onUnmounted } from 'vue';
import { useTariffsStore } from '@/stores/admin/tariffs/tariffs.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { useRoute } from 'vue-router';

import PageHeader from '@/components/common/layout/PageHeader.vue';
import TariffInfoPreview from '@/components/tariffs/details/TariffInfoPreview.vue';
import TariffSitesPreview from '@/components/tariffs/details/TariffSitesPreview.vue';
import TariffDefaultRatePreview from '@/components/tariffs/details/TariffDefaultRatePreview.vue';
import TariffSpecialRatePreview from '@/components/tariffs/details/TariffSpecialRatePreview.vue';
import TariffDeleteModal from '@/components/tariffs/TariffDeleteModal.vue';
import TariffCreateEditModal from '@/components/tariffs/TariffCreateEditModal.vue';

import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
import CustomButton from '@/components/common/button/CustomButton.vue';

import type { Rate } from '@/models/tariffs.model';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const userStore = useUsersStore();
const tariffsStore = useTariffsStore();
const chargeParksStore = useChargeParksStore();
const {
  tariffDetails,
  tariffCreateModalIsOpen,
  tariffDeleteModalIsOpen,
  tariffModalData,
  tariffEditId,
  tariffDeleteId,
  defaultRateData,
} = storeToRefs(tariffsStore);
const { t } = useI18n<[]>();
const tariffId = ref(Number(route.params.id));
const loading = ref(true);

const openTariffEditModal = () => {
  tariffEditId.value = Number(route.params.id);
  const convertedSites = tariffDetails.value.charge_parks.map((site) => site.id);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { charge_parks, ...rest } = tariffDetails.value;

  tariffModalData.value.charge_parks = convertedSites;
  tariffModalData.value = { charge_parks: convertedSites, ...rest };

  tariffModalData.value.rates.map((rate) => {
    if (rate.type === 'Default' || rate.type === 1) {
      defaultRateData.value = {
        type: 1,
        session_fee_cent: rate.session_fee_cent,
        session_fee_tax_percent: rate.session_fee_tax_percent,
        price_per_kwh_tenth_cent: rate.price_per_kwh_tenth_cent,
        price_per_kwh_tax_percent: rate.price_per_kwh_tax_percent,
        idle_fee_tenth_cent_per_min: rate.idle_fee_tenth_cent_per_min,
        idle_fee_tax_percent: rate.idle_fee_tax_percent,
        idle_fee_grace_period_min: rate.idle_fee_grace_period_min,
        charging_duration_fee_tenth_cent: rate.charging_duration_fee_tenth_cent,
        charging_duration_fee_tax_percent: rate.charging_duration_fee_tax_percent,
        fixed_tax_session_cent: rate.fixed_tax_session_cent,
        fixed_tax_parking_cent: rate.fixed_tax_parking_cent,
        pre_authorization_amount_cent: rate.pre_authorization_amount_cent,
      } as Rate;
    }
  });

  tariffCreateModalIsOpen.value = true;
};

const openTariffDeleteModal = () => {
  tariffDeleteModalIsOpen.value = true;
  tariffDeleteId.value = tariffId.value;
};

onMounted(async () => {
  await tariffsStore.fetchTariffDetails(tariffId.value).then(() => (loading.value = false));
  if (chargeParksStore.daysOfWeek.length === 0) {
    await chargeParksStore.fetchDaysList();
  }
});
onUnmounted(async () => {
  tariffsStore.resetTariffDetails();
  await userStore.init();
});
</script>
