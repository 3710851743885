<template>
  <Button
    data-cy="open-hardware-system-action"
    :data-cy-system-serial="props.system.nidec_id"
    :label="$t('actions')"
    @click="actionMenu.toggle($event)"
    icon="pi pi-chevron-down"
    iconPos="right"
    aria-haspopup="true"
    aria-controls="actions_menu"
    class="p-button-outlined p-button-plain p-button-sm mr-3 actions-menu-button"
  />
  <Menu
    data-cy="batch-actions-menu"
    :id="'actions_menu_' + props.system.id"
    ref="actionMenu"
    :model="props.actionMenuItems"
    :popup="true"
    class="mt-1"
  />
</template>

<script lang="ts" setup>
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { MenuItem } from 'primevue/menuitem';
import Menu from 'primevue/menu';
import Button from 'primevue/button';
import { ref } from 'vue';

const actionMenu = ref();

const props = defineProps<{
  system: HardwareSystem;
  actionMenuItems?: MenuItem[];
}>();
</script>
