import axios, { isAxiosError } from 'axios';

import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { ProductType } from '@/models/productTypes.model';
import type { Dashboard } from '@/models/controlPanel/dashboard';
import * as productTypePropertiesDummy from '@/data/productTypeProperties.dummy.json';
import type { DataResponse, DataRequest } from './controlPanel.types';
import { directPowerBrandingTemplate } from '@/stores/admin/branding/ui.dummydata';

export async function fetchHistoricalTelemetryData(req: DataRequest): Promise<DataResponse> {
  return axios
    .post('/api/data/telemetry/', req)
    .then((res) => res.data)
    .catch((e) => {
      console.log('Error fetching telemetry data: ', e);
      return Promise.reject('Error fetching telemetry data');
    });
}

export async function fetchHardwareSystem(id: HardwareSystem['id']): Promise<HardwareSystem> {
  return axios.get(`/api/systems/${id}/`).then((r) => r.data);
}

export async function fetchProductType(id: ProductType['id']): Promise<ProductType> {
  return axios.get(`/api/product-types/${id}/`).then((r) => {
    const data = r.data;
    data.image_url ??= '/images/DirectPower360.png';
    data.properties ??= productTypePropertiesDummy;
    data.properties.branding_template ??= directPowerBrandingTemplate;
    return data;
  });
}

export async function fetchDashboard(
  id: Dashboard['id'],
  systemId: HardwareSystem['id']
): Promise<Dashboard> {
  return await axios.get(`/api/dashboards/${id}/?system_id=${systemId}`).then((r) => r.data);
}

export async function applyPatch(
  id: HardwareSystem['id'],
  patch: { path: string; value: string | number | boolean }[]
): Promise<void> {
  return axios.patch(`/api/systems/${id}/settings/`, patch).then((r) => r.data);
}

export type ExecutionRequestResult = {
  existingCommand?: string;
  systemOffline?: boolean;
};

export async function executeCommand(
  id: HardwareSystem['id'],
  command: string,
  force: boolean
): Promise<void> {
  return axios
    .post(`/api/systems/${id}/commands/`, { path: command, force })
    .then((r) => r.data)
    .catch((e) => {
      console.log('Error executing command: ', e);
      if (isAxiosError(e) && e.response?.status === 409)
        return Promise.reject(e.response?.data as ExecutionRequestResult);
      else return Promise.reject(e);
    });
}
