<template>
  <div>
    <page-header :title="t('branding.title')" />
    <div class="p-4">
      <loading-widget v-if="isLoading" />
      <branding-list-table
        v-else
        :brandings="brandings"
        :charge-parks="chargeParks"
        @details="navigateToDetails"
        @create="createModalOpen = true"
      />
    </div>
  </div>
  <branding-create-edit-modal
    :open="createModalOpen"
    mode="CREATE"
    @cancelled="createModalOpen = false"
    @created="navigateToDetails"
  />
</template>

<script setup lang="ts">
import PageHeader from '@/components/common/layout/PageHeader.vue';
import { onMounted, ref } from 'vue';
import { fetchBrandings } from '@/stores/admin/branding/ui.api';
import { useRouter } from 'vue-router';
import { BRANDING } from '@/utils/routeNames';

import LoadingWidget from '@/components/dashboard/widget/LoadingWidget.vue';
import BrandingCreateEditModal from '@/components/branding/modal/BrandingCreateEditModal.vue';
import BrandingListTable from '@/components/branding/table/BrandingListTable.vue';
import type { FullBrandingConfiguration } from '@/stores/admin/branding/branding.types';
import { fetchChargeParks } from '@/stores/admin/chargeParks/chargeParks.api';
import type { ChargePark } from '@/models/chargeParks.model';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();
const isLoading = ref(true);

const brandings = ref<FullBrandingConfiguration[]>([]);
const chargeParks = ref<ChargePark[]>([]);

const createModalOpen = ref(false);

function navigateToDetails(id: FullBrandingConfiguration['id']) {
  router.push(`/${BRANDING}/${id}`);
}

onMounted(async () => {
  try {
    brandings.value = await fetchBrandings();
    chargeParks.value = (await fetchChargeParks())?.data ?? [];
  } catch (e) {
    console.log(e);
  } finally {
    isLoading.value = false;
  }
});
</script>

<style scoped lang="scss">
.branding-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  margin: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background-color: var(--gray-0);
}

.branding-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-200);
  }
}
</style>
