<template>
  <GeneralSummary
    show-name
    :name="alertModalData.name"
    :template="selectedTemplate"
    :createdBy="alertModalData.created_by_user_name"
  />
  <AlertParametersSummary />
  <AlertSitesSummary />
  <AlertContactsSummary />
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';

import GeneralSummary from '@/components/alertsAndReportsShared/summary/GeneralSummary.vue';
import AlertParametersSummary from '@/components/alertsAndReportsShared/summary/AlertParametersSummary.vue';
import AlertContactsSummary from '@/components/alertsAndReportsShared/summary/AlertContactsSummary.vue';
import AlertSitesSummary from '@/components/alertsAndReportsShared/summary/AlertSitesSummary.vue';
import { useAlertsStore } from '@/stores/admin/alerts/alerts.store';

const alertsStore = useAlertsStore();
const { alertModalData, selectedTemplate } = storeToRefs(alertsStore);
</script>
