<template>
  <div class="flex px-12 border-b text-sm py-1">
    <div class="w-full info-single">
      <ReadOnlyItem v-if="showName && name" :name="$t('name')" :value="name" />
      <ReadOnlyItem
        v-if="template?.name"
        :name="$t('template')"
        :value="template.name"
        :tooltip="templateDescription"
      />
      <ReadOnlyItem
        v-if="showAuthor && createdBy"
        :name="$t('user.createdBy')"
        :value="createdBy"
      />
      <ReadOnlyItem
        v-if="type"
        :name="$t('report.reportType.title')"
        :value="prettify(`${type}`)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { prettify } from '@/utils/textFormatting';

import ReadOnlyItem from '@/components/common/readOnly/ReadOnlyItem.vue';

import type { AlertTemplate } from '@/models/alerts.model';
import type { ReportTemplate } from '@/models/reports.model';

const props = withDefaults(
  defineProps<{
    showName?: boolean;
    showAuthor: boolean;
    name?: string | null;
    template?: AlertTemplate | ReportTemplate;
    createdBy?: string;
    type?: string | null;
  }>(),
  {
    showName: false,
    showAuthor: true,
    name: null,
    type: null,
  }
);

const templateDescription = computed(() => {
  return props.template?.description;
});
</script>
