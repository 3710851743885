<template>
  <router-link
    :to="props.path"
    :class="['name', 'block', { active: isActive }]"
    @click="setPageTitle(props.name)"
    activeClass="active"
    >{{ props.name }}</router-link
  >
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useGeneralStore } from '@/stores/general/general.store';
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const props = defineProps<{
  name: string;
  path: string;
}>();

const isActive = computed(() => route.path.startsWith(props.path));
const { title } = storeToRefs(useGeneralStore());

const setPageTitle = (name: string) => (title.value = name);

const route = useRoute();
</script>

<style lang="scss" scoped>
.name {
  color: var(--gray-600);
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 4px 0;
  white-space: nowrap;
  position: relative;
  transition: all 0.3s;

  &:before {
    content: '';
    position: absolute;
    left: -28px;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: var(--green-primary);
    border-radius: 0 13px 13px 0;
    transition: all 0.3s;
  }

  &:hover,
  &:focus {
    color: var(--green-primary);

    &:before {
      left: -24px;
      transition: all 0.3s;
    }
  }
  &.active {
    color: var(--green-primary);

    &:before {
      left: -24px;
    }
  }
}
</style>
