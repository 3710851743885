import type { SpecialRate, WeekdayNumber, WeekdayString } from '@/models/tariffs.model';
import {
  THREE_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER,
  TWO_DIGITS,
  TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER,
} from '@/utils/constants';

function datesOverlap(
  start1: string | Date | null,
  end1: string | Date | null,
  start2: string | Date | null,
  end2: string | Date | null
): boolean {
  const startDate1 = start1 !== null ? new Date(start1) : null;
  const startDate2 = start2 !== null ? new Date(start2) : null;
  const endDate1 = end1 !== null ? new Date(end1) : null;
  const endDate2 = end2 !== null ? new Date(end2) : null;

  return (
    (startDate1 === null || endDate2 === null || startDate1 <= endDate2) &&
    (endDate1 === null || startDate2 === null || endDate1 >= startDate2)
  );
}

function isNumericWeekdayArray(days: WeekdayNumber[] | WeekdayString[]): days is WeekdayNumber[] {
  return typeof days[0] === 'number';
}

const jsWeekdayOrder: WeekdayString[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

function toJavascriptWeekdays(days: WeekdayString[] | WeekdayNumber[]): number[] {
  if (isNumericWeekdayArray(days)) {
    return days;
  } else {
    return days.map((day) =>
      jsWeekdayOrder.findIndex((weekday) => day.toLowerCase().includes(weekday.toLowerCase()))
    );
  }
}

function weekDaysOverlap(
  rate1Start: string | Date,
  rate1End: string | Date,
  rate1WeekdayFilter: WeekdayString[] | WeekdayNumber[],
  rate2start: string | Date,
  rate2end: string | Date,
  rate2WeekdayFilter: WeekdayString[] | WeekdayNumber[]
) {
  const laterStartDate = new Date(rate1Start > rate2start ? rate1Start : rate2start);
  const earliestEndDate = new Date(rate1End < rate2end ? rate1End : rate2end);

  const normalizedRate1Filter: number[] = toJavascriptWeekdays(rate1WeekdayFilter);
  const normalizedRate2Filter: number[] = toJavascriptWeekdays(rate2WeekdayFilter);

  // Iterate through one week starting from the later start date and finish if the earliest end date is reached
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(laterStartDate);
    currentDate.setDate(currentDate.getDate() + i);

    if (currentDate > earliestEndDate) {
      break;
    }

    const weekday = currentDate.getDay();

    if (normalizedRate1Filter.includes(weekday) && normalizedRate2Filter.includes(weekday)) {
      return true;
    }
  }

  return false;
}

// Get the time in seconds
function timeStringToSecondOffset(time: string | any): number {
  if (time instanceof Date) {
    time = time.getHours() + ':' + time.getMinutes() + ':' + time.getSeconds();
  }

  const [hours, minutes, seconds] = time.split(':').map((x: string) => parseInt(x, 10));
  return hours * 3600 + minutes * 60 + (seconds | 0);
}

function timesOverlap(startTime1: string, endTime1: string, startTime2: string, endTime2: string) {
  const start1Seconds = timeStringToSecondOffset(startTime1);
  const end1Seconds = timeStringToSecondOffset(endTime1);
  const start2Seconds = timeStringToSecondOffset(startTime2);
  const end2Seconds = timeStringToSecondOffset(endTime2);

  return start1Seconds < end2Seconds && end1Seconds > start2Seconds;
}

function checkConflictsBetweenRates(
  rate1: SpecialRateWithAllValues,
  rate2: SpecialRateWithAllValues
) {
  const datesAreOverlapping = datesOverlap(
    rate1.start_date,
    rate1.end_date,
    rate2.start_date,
    rate2.end_date
  );

  if (datesAreOverlapping) {
    const timesAreOverlapping = timesOverlap(
      rate1.start_time,
      rate1.end_time,
      rate2.start_time,
      rate2.end_time
    );

    if (timesAreOverlapping) {
      return weekDaysOverlap(
        rate1.start_date,
        rate1.end_date ?? new Date('9999-12-31'),
        rate1.days,
        rate2.start_date,
        rate2.end_date ?? new Date('9999-12-31'),
        rate2.days
      );
    }
  }
}

type SpecialRateWithAllValues = SpecialRate & {
  start_time: string;
  end_time: string;
  start_date: Date;
};

function replaceNullsWithBiggestRange(rate: SpecialRate): SpecialRateWithAllValues {
  const startDate = rate.start_date ? new Date(rate.start_date) : new Date('1970-01-01');

  return {
    ...rate,
    start_time: rate.start_time || '00:00:00',
    end_time: rate.end_time || '23:59:59',
    start_date: startDate,
    end_date: rate.end_date ? new Date(rate.end_date) : null,
  };
}

export function checkConflicts(rates: SpecialRate[]): [string, string][] {
  const conflicts: [string, string][] = [];

  for (let i = 0; i < rates.length; i++) {
    for (let j = i + 1; j < rates.length; j++) {
      const rate1 = replaceNullsWithBiggestRange(rates[i]);
      const rate2 = replaceNullsWithBiggestRange(rates[j]);

      if (checkConflictsBetweenRates(rate1, rate2)) {
        conflicts.push([rate1.name, rate2.name]);
      }
    }
  }

  return conflicts;
}

export const normalizeData = (value: number, decimalPlaces: number) => {
  const normalizedValue =
    decimalPlaces === TWO_DIGITS
      ? value * TWO_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER
      : value * THREE_DIGITS_RATE_VALUE_DEVIDER_AND_MULTIPLIER;
  return Number(normalizedValue.toFixed(decimalPlaces));
};
