<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    class="m-5"
    data-cy="updates-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}
  </Message>
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="system-update-table"
    :value="events"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="7"
    class="m-5"
  >
    <template #empty>
      <span data-cy="system-update-empty" class="w-full block text-center">{{
        $t('hardwareSystem.updateEmpty')
      }}</span>
    </template>
    <template #header>
      <div class="flex justify-between items-center">
        <div>
          <span class="text-xl block">{{ $t('update.firmware') }}</span>
          <div class="version-wrapper mt-3">
            <span class="version-title">{{ $t('hardwareSystem.version') }}</span>
            <span v-if="system.version" class="version-text p-2">{{ system.version }}</span>
            <span v-else class="version-text py-2 px-4">--</span>
          </div>
        </div>
        <Button
          v-if="activeRoles"
          data-cy="open-update-charger-modal"
          :label="$t('hardwareSystem.updateSystem')"
          icon="pi pi-cog"
          class="p-button-sm p-button-primary p-button-sm m-5"
          @click="openUpdateChargerModal()"
        />
      </div>
      <div class="flex justify-between items-center mt-6">
        <span class="text-xl">{{ $t('update.firmwareHistory') }}</span>
      </div>
    </template>
    <Column
      class="no-top-border-row"
      field="created_at"
      :header="$t('time')"
      style="min-width: 10rem"
    >
      <template #body="{ data }: { data: History }">
        <span data-cy="system-history-time" class="time">
          <date-time-display :date="data.created_at" />
        </span>
      </template>
    </Column>
    <Column class="no-top-border-row" :header="$t('history.title')" style="min-width: 14rem">
      <template #body="{ data }: { data: History }">
        <i :class="getIcon(data.event)" :style="{ color: getIconColor(data.event) }"></i>
        <span data-cy="system-update-event" class="pl-2">{{ data.event }}</span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="details"
      :header="$t('details')"
      style="min-width: 30rem"
    >
      <template #body="{ data }: { data: History }">
        <div class="flex justify-between">
          <div class="flex flex-wrap">
            <div
              v-for="(item, index) in data.details"
              :key="index"
              class="mb-2 mr-6 whitespace-nowrap"
            >
              <Badge data-cy="system-update-detail-tag" :value="item.tag"></Badge>
              <span
                v-if="item.tag.toLowerCase().includes('date')"
                data-cy="system-update-detail-content"
                class="pl-2"
                >{{ dayjs(item.content).format('L HH:mm') }}</span
              >
              <span
                v-else-if="item.content === null"
                data-cy="event-history-detail-content-empty"
                class="empty pl-2"
                >--</span
              >
              <span v-else data-cy="system-update-detail-content" class="pl-2">{{
                item.content
              }}</span>
            </div>
          </div>
          <Button
            v-if="data.type === 'System'"
            data-cy="history-details-button"
            :label="$t('details')"
            class="p-button-text ml-2 external-link-button"
            icon="pi pi-external-link"
            iconPos="right"
          />
          <Button
            v-if="data.type === 'Alert'"
            data-cy="history-details-button"
            :label="$t('details')"
            class="p-button-text ml-2 external-link-button"
            icon="pi pi-external-link"
            iconPos="right"
          />
          <Button
            v-if="data.type === 'Ticket'"
            data-cy="history-details-button"
            :label="$t('history.ticketLabel')"
            class="p-button-text ml-2 external-link-button"
            icon="pi pi-external-link"
            iconPos="right"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <HardwareSystemUpdateChargerModal :system="system" />
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Message from 'primevue/message';
import Badge from 'primevue/badge';
import HardwareSystemUpdateChargerModal from '@/components/hardwareSystems/HardwareSystemUpdateModal.vue';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';

import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';

import { getIcon, getIconColor } from '@/utils/eventHistoryIcons';
import { useUsersStore } from '@/stores/admin/users/users.store';
import {
  ADMINISTRATOR_ROLE,
  ASSET_OPERATOR_ROLE,
  DEFAULT_EVENT_HISTORY_PAGE_SIZE,
} from '@/utils/constants';

import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { History } from '@/models/history.model';

const props = defineProps<{
  system: HardwareSystem;
}>();

const systemStore = useHardwareSystemsStore();
const { events, systemUpdateModalIsOpen } = storeToRefs(systemStore);
const loading = ref(false);
const notAuthorized = ref(false);
const userStore = useUsersStore();
const activeRoles = computed(() =>
  userStore.hasAtLeastOneRoleOf(ADMINISTRATOR_ROLE, ASSET_OPERATOR_ROLE)
);

const openUpdateChargerModal = () => {
  systemUpdateModalIsOpen.value = true;
};

onMounted(
  async () =>
    await systemStore
      .fetchEvents(props.system.id, {
        type: [3],
        pageNumber: 1,
        pageSize: DEFAULT_EVENT_HISTORY_PAGE_SIZE,
      })
      .catch((error) => {
        if (error.response.status === 403) notAuthorized.value = true;
        else throw new Error('Firmware updates failed to be fetched');
      })
      .finally(() => (loading.value = false))
);
</script>

<style lang="scss" scoped>
.time {
  font-size: 14px;
  line-height: 17px;
}

:deep(.p-badge) {
  background-color: $grey-medium-light;
  color: #000;
  border-radius: 3px;
}

:deep(.p-dropdown .p-dropdown-label.p-placeholder) {
  padding: 7px 10px;
}

:deep(.p-dropdown.p-dropdown-clearable .p-dropdown-label) {
  padding: 7px 10px;
}

:deep(.p-dropdown .p-dropdown-label) {
  padding: 7px 10px;
}

.p-datatable {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
}

:deep(.p-datatable-header) {
  padding: 30px 20px 20px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

:deep(.p-fluid .p-calendar .p-inputtext) {
  padding: 7px 10px;
}

.version {
  &-wrapper {
    border-radius: 6px;
    border: 1px solid $grey-medium-light;
  }

  &-title {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $text-medium;
    padding: 8px;
  }

  &-text {
    display: inline-block;
    background-color: $primary;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: 1px solid $primary;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
</style>
