<template>
  <PageHeader :title="t('tariffs')" :description="t('tariff.description')" />
  <div class="container container-grey">
    <TariffsTable />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import PageHeader from '@/components/common/layout/PageHeader.vue';
import TariffsTable from '@/components/tariffs/TariffsTable.vue';

const { t } = useI18n();
</script>
