import { defineStore } from 'pinia';
import type { User, UserEdit, UserEditData, UserMe, UserRole } from '@/models/users.model';
import { UserStatus } from '@/models/users.model';
import {
  createUser,
  deleteUser,
  editUser,
  fetchOrganisationUsers,
  fetchRoles,
  fetchSingleUser,
  fetchUsers,
  postDpaPreference,
} from '@/stores/admin/users/users.api';
import { useOrganisationsStore } from '@/stores/admin/organizations/organisations.store';
import {
  ADMINISTRATOR_ROLE,
  Role,
  WHITELIST_ADMIN_ROLE,
  WHITELIST_VIEWER_ROLE,
} from '@/utils/constants';
import { TimeSystem, UnitSystem } from '@/models/datatypes.model';

interface UsersState {
  users: User[];
  me: UserMe;
  userModalIsOpen: Boolean;
  userEditionData: UserEditData;
  userIsEditing: Boolean;
  userOrganisation: {
    id: number;
    name: string;
    roles: UserRole[];
  };
  userDeleteModalIsOpen: boolean;
  userDeleteData: {
    id: number;
    name: string;
    email: string;
    organisation_id: number;
  };
  userDataIsLoaded: boolean;
  roles: UserRole[];
}

export const useUsersStore = defineStore('users', {
  state: (): UsersState => ({
    users: [],
    me: {
      id: 0,
      name: '',
      email: '',
      active_features: [],
      roles: [],
      organisation_id: 0,
      status: UserStatus.ACTIVE,
      units: UnitSystem.METRIC,
      local_timezone: 'Europe/Berlin',
      time_format: TimeSystem.TIME_24H,
    },
    userModalIsOpen: false,
    userIsEditing: false,
    userEditionData: {
      id: 0,
      name: '',
      email: '',
      organisation_id: 0,
      roles: [],
    },
    userOrganisation: {
      id: 0,
      name: '',
      roles: [],
    },
    userDeleteModalIsOpen: false,
    userDeleteData: {
      id: 0,
      name: '',
      email: '',
      organisation_id: 0,
    },
    userDataIsLoaded: false,
    roles: [],
  }),
  getters: {
    userNeedsToAcceptDpa(): boolean {
      return this.me.status === UserStatus.INVITED;
    },
  },
  actions: {
    async fetchUsersList() {
      return await fetchUsers()
        .then((response) => {
          this.users = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async fetchOrganisationUsersList(organisationId: number) {
      return await fetchOrganisationUsers(organisationId)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async createNewUser(data: UserEdit) {
      return await createUser(data)
        .then((response) => {
          const organisationsStore = useOrganisationsStore();
          this.fetchUsersList().then(() => {
            organisationsStore.fetchUsersForSingleOrganisation(data.organisation_id);
          });
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async updateUser(data: UserEdit) {
      return await editUser(this.userEditionData.id, data)
        .then((response) => {
          const organisationsStore = useOrganisationsStore();
          this.fetchUsersList().then(() => {
            organisationsStore.organisations.forEach((organisation) => {
              organisationsStore.fetchUsersForSingleOrganisation(organisation.id);
            });
          });
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async removeUser() {
      const organisationId = this.userDeleteData.organisation_id;

      return await deleteUser(this.userDeleteData.id)
        .then((response) => {
          const organisationsStore = useOrganisationsStore();
          organisationsStore.fetchUsersForSingleOrganisation(organisationId);
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async fetchUserData(force?: boolean) {
      if (this.userDataIsLoaded && !force) return;
      return await fetchSingleUser()
        .then((response) => {
          this.me = response.data;
          this.userDataIsLoaded = true;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    async fetchUserRoles() {
      return await fetchRoles()
        .then((response) => {
          this.roles = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    setUserEditionData(data: UserEditData) {
      this.userEditionData = data;
    },
    resetUserEditionData() {
      this.userEditionData = {
        id: 0,
        name: '',
        email: '',
        organisation_id: 0,
        roles: [],
      };
    },
    resetUserDeleteData() {
      this.userDeleteData = {
        id: 0,
        name: '',
        email: '',
        organisation_id: 0,
      };
    },
    async init() {
      if (this.userDataIsLoaded) return;
      await this.fetchUserData();
    },
    featureIsActive(featureIds: number[]) {
      const activeFeatures = this.me.active_features.map((item) => item.id);
      return featureIds.every((role) => activeFeatures.includes(role));
    },
    hasAtLeastOneRoleOf(...roleNames: number[]) {
      const activeRoles = this.me.roles.map((item) => item.id);
      
      return (
        activeRoles.includes(ADMINISTRATOR_ROLE) ||
        roleNames.some((role) => {
          if (role === WHITELIST_VIEWER_ROLE) {
            return activeRoles.includes(role) || activeRoles.includes(WHITELIST_ADMIN_ROLE);
          }
          return activeRoles.includes(role);
        }));
    },
    async sendDpaPreference(accepted: boolean) {
      await postDpaPreference(accepted);
      await this.fetchUserData(true);
    },
  },
});
