import i18n from '@/i18n';

export interface MaxRate {
  name: string;
  value: number;
}

export const maxRate: MaxRate[] = [
  {
    name: `1 ${i18n.global.t('alert.perDay')}`,
    value: 1,
  },
  {
    name: `10 ${i18n.global.t('alert.perDay')}`,
    value: 10,
  },
  {
    name: `100 ${i18n.global.t('alert.perDay')}`,
    value: 100,
  },
];
