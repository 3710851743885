<template>
  <div class="logheader-container">
    <div class="timerange-select">
      <div class="label">
        <p>Range</p>
      </div>

      <DateTimeInput v-model="dates" show-icon selection-mode="range" />

      <button @click="apply" class="apply-button" :disabled="!different || !valid">
        <exclamation-triangle-icon class="h-5 w-5 inline-block" v-if="!valid" />
        <span v-if="!valid">(Max 7 Days)</span>
        <span v-else>Apply</span>
      </button>
    </div>

    <div class="viewmode-select">
      <label for="select" class="label"> View Mode </label>
      <select
        id="select"
        :value="props.viewMode"
        @change="emit('update:view-mode', ($event.target as HTMLSelectElement).value as ViewMode)"
      >
        <option value="SPLIT">Split</option>
        <option value="FILES">Files Only</option>
        <option value="SYSLOG">Syslog Only</option>
      </select>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid';

import DateTimeInput from '@/components/common/time/DateTimeInput.vue';

type ViewMode = 'SPLIT' | 'FILES' | 'SYSLOG';
const props = defineProps<{
  start: string;
  end: string;
  viewMode: ViewMode;
}>();

const emit = defineEmits<{
  (event: 'update:view-mode', value: ViewMode): void;
  (event: 'update:range', start: string, end: string): void;
}>();

const ONE_DAY_MILLISECONDS = 1000 * 60 * 60 * 24;
const MAX_DIFFERENCE_BETWEEN_DATES_MILLISECONDS = ONE_DAY_MILLISECONDS * 7;

const dates = ref<[string, string]>([props.start, props.end]);

function dayChanged(originalDate: Date | null, newDate: Date | null) {
  if (originalDate === null || newDate === null) return true;
  return (
    originalDate.getFullYear() !== newDate.getFullYear() ||
    originalDate.getMonth() !== newDate.getMonth() ||
    originalDate.getDate() !== newDate.getDate()
  );
}

const different = computed(() => {
  return dates.value[0] !== props.start || dates.value[1] !== props.end;
});

const valid = computed(() => {
  const val1 = new Date(dates.value[0]);
  const val2 = new Date(dates.value[1]);
  return (
    val1 !== null &&
    val2 !== null &&
    val1.getTime() < val2.getTime() &&
    val2.getTime() - val1.getTime() <= MAX_DIFFERENCE_BETWEEN_DATES_MILLISECONDS
  );
});

function apply() {
  if (!valid.value || dates.value[0] === null || dates.value[1] === null) return;
  emit('update:range', dates.value[0], dates.value[1]);
}
</script>

<style scoped lang="scss">
.logheader-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 1rem;
}

.timerange-select {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 0;
  height: 2.5rem;
  min-width: 32rem;

  > * {
    &:first-child {
      border-left-width: 1px;
      border-radius: var(--rounded-md) 0 0 var(--rounded-md);
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    &:last-child {
      border-right-width: 1px;
      border-radius: 0 var(--rounded-md) var(--rounded-md) 0;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--gray-50);
  border: 1px solid var(--gray-200);
  color: var(--gray-500);
}

.p-calendar {
  background-color: var(--gray-0);
  color: var(--gray-500);
  border: 1px solid var(--gray-200);
  border-left: none;
  outline: none;

  &:not(:last-child) {
    border-right: none;
  }

  &:focus,
  &:hover {
    border-color: var(--gray-200);
  }
}

:deep(.p-inputtext) {
  border-radius: 0;
}

.viewmode-select {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 0;
  height: 2.5rem;

  label {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: var(--gray-50);
    border: 1px solid var(--gray-200);
    color: var(--gray-500);
    border-radius: var(--rounded-md) 0 0 var(--rounded-md);
  }

  select {
    background-color: var(--gray-0);
    color: var(--gray-500);
    border: 1px solid var(--gray-200);
    border-left: none;
    outline: none;
    border-radius: 0 var(--rounded-md) var(--rounded-md) 0;
    padding: 0 1rem;

    &:focus,
    &:hover {
      border-color: var(--gray-200);
    }
  }
}

.apply-button {
  background-color: var(--green-primary);
  color: var(--gray-0);
  padding: 0 0.75rem;

  &:disabled {
    background-color: var(--gray-200);
    color: var(--gray-500);
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background-color: var(--green-primary-dark) !important;
  }
}
</style>
