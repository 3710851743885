<template>
  <div class="flex justify-between system-location-preview">
    <div data-cy="system-location-latitude" class="w-1/2">
      <span class="block font-medium py-3">{{ $t('chargePark.latitude') }}</span>
      <span
        v-if="props.hardwareSystem.latitude"
        class="py-[9px] text-right font-medium text-[var(--gray-700)]"
        >{{ props.hardwareSystem.latitude }}</span
      >
      <span v-if="!props.hardwareSystem.latitude" class="empty py-2 px-4 font-medium">--</span>
    </div>
    <div data-cy="system-location-longitude" class="w-1/2">
      <span class="block font-medium py-3">{{ $t('chargePark.longitude') }}</span>
      <span
        v-if="props.hardwareSystem.longitude"
        class="py-[9px] text-right font-medium text-[var(--gray-700)]"
        >{{ props.hardwareSystem.longitude }}</span
      >
      <span v-if="!props.hardwareSystem.longitude" class="empty py-2 px-4 font-medium">--</span>
    </div>
  </div>
  <div class="w-full border-t border-t-[var--gray-200] my-4"></div>
  <div data-cy="system-location-address" class="flex justify-between">
    <span class="block font-medium py-3">
      {{ $t('address') }}
      <span class="required font-medium">*</span>
    </span>
    <span v-if="props.hardwareSystem.address" class="py-[9px] w-1/2 font-medium show-new-lines">
      {{ props.hardwareSystem.address }}
    </span>
    <span v-if="!props.hardwareSystem.address" class="empty py-2 font-medium">--</span>
  </div>
  <div data-cy="system-location-country" class="flex justify-between">
    <span class="block font-medium py-3">
      {{ $t('country.title') }}
      <span class="required font-medium">*</span>
    </span>
    <Country
      v-if="props.hardwareSystem.country_code"
      :country-code="props.hardwareSystem.country_code"
      class="w-1/2"
    />
    <span v-if="!props.hardwareSystem.country_code" class="empty py-2 font-medium">--</span>
  </div>
</template>
<script setup lang="ts">
import Country from '@/components/common/countries/Country.vue';

import type { HardwareSystem } from '@/models/hardwareSystems.model';

const props = defineProps<{
  hardwareSystem: HardwareSystem;
}>();
</script>
