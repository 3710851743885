<template>
  <div class="spinner-container">
    <div class="spinner-class" :class="[`color-${color}`]" />
    <div v-if="withText">
      <span>Loading...</span>
    </div>
  </div>
</template>

<script setup lang="ts">
type Color = 'light' | 'dark';

const props = defineProps<{
  color?: Color;
  opacity?: number;
  withText?: boolean;
}>();

const color: Color = props.color ?? 'dark';
const opacity = props.opacity ?? 0.8;
const withText = props.withText ?? false;
</script>

<style scoped lang="scss">
.spinner-container {
  opacity: v-bind(opacity);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.spinner-class {
  border-radius: 50%;
  width: 1.125rem;
  height: 1.125rem;
  animation: spin 2s linear infinite;
  border-width: 0.125rem;
  border-style: solid;

  &.color-light {
    border-color: var(--gray-100);
    border-top-color: transparent;
  }

  &.color-dark {
    border-color: var(--gray-700);
    border-top-color: transparent;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
