import { required, helpers, maxLength } from '@vuelidate/validators';
import { asciiCharacters } from '@/utils/validationRules';
import { MAX_LENGTH } from '@/utils/constants';
import i18n from '@/i18n';

export const infoRules = {
  name: {
    asciiCharacters: helpers.withMessage(
      i18n.global.t('rules.asciiCharacters', {
        field: i18n.global.t('name'),
      }),
      asciiCharacters
    ),
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('name'),
        item: i18n.global.t('user'),
      }),
      required
    ),
    maxLength: maxLength(MAX_LENGTH),
  },
};

export const preferencesRules = {
  timezone: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('timezone'),
      }),
      required
    ),
  },
  unit: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('timezone'),
      }),
      required
    ),
  },
  timeFormat: {
    required: helpers.withMessage(
      i18n.global.t('rules.required', {
        field: i18n.global.t('timezone'),
      }),
      required
    ),
  },
};
