import type { Card, Column, Panel, Row, SettingWidget } from '@/models/controlPanel/dashboard';
import { type ProductTypeSetting, settingIsNumber } from '@/models/productTypes.model';
import { pathMatcherWithWildcards } from '@/logic/controlPanel/productType';

function groupByPenultimatePathElement(settingsPaths: string[]): Record<string, string[]> {
  return settingsPaths.reduce((acc, path) => {
    const parts = path.split('.');
    const key = parts.slice(0, -1).join('.');
    const settings = acc[key] || [];
    settings.push(path);
    acc[key] = settings;
    return acc;
  }, {} as Record<string, string[]>);
}

function toParts(path: string): string[] {
  return path.split('.').filter(Boolean);
}

function isInProductProperties(path: string, productTypeSettings: ProductTypeSetting[]): boolean {
  return productTypeSettings.some((setting) => pathMatcherWithWildcards(path, setting.path));
}

function filterValidSettings(
  settingsPaths: string[],
  productTypeSettings: ProductTypeSetting[]
): string[] {
  return settingsPaths.filter((path) => {
    const parts = toParts(path);
    return parts.length >= 3 && isInProductProperties(path, productTypeSettings);
  });
}

function groupToPanels(settingPaths: string[]): Record<string, string[]> {
  return settingPaths.reduce((acc, path) => {
    const parts = toParts(path);
    const panelId = parts[1];
    const panelSettings = acc[panelId] || [];
    panelSettings.push(path);
    acc[panelId] = panelSettings;
    return acc;
  }, {} as Record<string, string[]>);
}

function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function buildCardTitle(cardIdPath: string): string {
  const parts = toParts(cardIdPath);

  if (parts.length < 3) {
    return 'General';
  }

  return parts.slice(2).map(capitalize).join(' ');
}

function toWidget(rawPath: string, productSettings: ProductTypeSetting[]): SettingWidget | null {
  const setting = productSettings.find((setting) =>
    pathMatcherWithWildcards(rawPath, setting.path)
  );

  if (!setting) {
    console.log('Setting not found for path:', rawPath);
    return null;
  }

  const widget: SettingWidget = {
    type: 'setting',
    path: rawPath,
    description: setting.description,
    writable: setting.writable,
  };

  if (settingIsNumber(setting)) {
    widget.min = setting.minValue;
    widget.max = setting.maxValue;
  }

  return widget;
}

function isNotNull<T>(widget: T | null): widget is T {
  return widget !== null;
}

function buildPanel(
  panelId: string,
  settingPaths: string[],
  productSettings: ProductTypeSetting[],
  cols: number = 4
): Panel {
  const cardSettings = groupByPenultimatePathElement(settingPaths);

  const cards: Card[] = Object.entries(cardSettings).map(([cardPath, settingPaths]) => ({
    id: cardPath,
    title: buildCardTitle(cardPath),
    widgets: settingPaths
      .map((settingPath) => toWidget(settingPath, productSettings))
      .filter(isNotNull)
      .sort((a, b) => a.path.localeCompare(b.path)),
  }));

  cards
    .sort((a, b) => (a.title && b.title ? a.title.localeCompare(b.title) : 0))
    .sort((a) => (a.title?.toLowerCase().includes('general') ? -1 : 1));

  return {
    id: panelId,
    label: capitalize(panelId),
    rows: arrangeCardsInGrid(cards, cols),
  };
}

function arrangeCardsInGrid(cards: Card[], cols: number = 4): Row[] {
  const rows: Row[] = [];
  const cardWidth = 12 / cols;

  for (let i = 0; i < cards.length; i += cols) {
    const columns: Column[] = [];
    for (let j = 0; j < cols; j++) {
      const card = cards[i + j];
      if (!card) break;
      columns.push({ width: cardWidth, cards: [card] });
    }
    rows.push({ columns });
  }

  return rows;
}

export function buildSettingsPanels(
  lastDataSettingPaths: string[],
  productTypeSettings: ProductTypeSetting[]
): Panel[] {
  const validDataSettings = filterValidSettings(lastDataSettingPaths, productTypeSettings);
  const settingsGroupedByPanelId = groupToPanels(validDataSettings);
  const panels = Object.entries(settingsGroupedByPanelId).map(([panelId, settings]) =>
    buildPanel(panelId, settings, productTypeSettings, 3)
  );
  return panels.sort((a, b) => a.label.localeCompare(b.label));
}
