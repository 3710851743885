export function isRecord(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

function depthOfObject(obj: unknown): number {
  if (typeof obj !== 'object' || obj === null) return 0;
  return 1 + Math.max(...Object.values(obj).map(depthOfObject), 0);
}

function cleanKey(key: string): string {
  return key.replace('.:', ':');
}

function flattenRecursive(
  prefix: string,
  node: Record<string, unknown>,
  flattened: Record<string, unknown>,
  retainDepth = 1
): void {
  if (depthOfObject(node) <= retainDepth) {
    flattened[cleanKey(prefix)] = node;
    return;
  }

  Object.entries(node).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null && !Array.isArray(value))
      flattenRecursive(
        `${prefix}.${key}`,
        value as Record<string, unknown>,
        flattened,
        retainDepth
      );
    else flattened[cleanKey(`${prefix}.${key}`)] = value;
  });
}

export function flatten<T extends string | number | null | boolean | undefined | []>(
  val: T,
  remainingDepth: number
): T;

export function flatten<T extends Record<string, unknown>>(
  val: T,
  remainingDepth: number
): Record<string, unknown>;

export function flatten(obj: unknown, depthToRetain = 1) {
  if (!isRecord(obj)) return obj;
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (isRecord(value)) flattenRecursive(key, value, acc, depthToRetain);
    else acc[key] = value;
    return acc;
  }, {} as Record<string, unknown>);
}
