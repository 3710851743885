import axios from 'axios';
import type { AxiosPromise, AxiosResponse } from 'axios';
import type { ContactEditData } from '@/models/contacts.model';

export function fetchContacts(): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/contacts/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function createContact(data: ContactEditData): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/contacts/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function updateContact(id: number, data: ContactEditData): Promise<AxiosResponse> {
  return axios
    .patch<AxiosPromise>(`/api/contacts/${id}/`, data)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function verifyContactCode(id: number, verificationCode: string): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/contacts/${id}/verify/`, { code: verificationCode })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function sendContactVerificationCode(id: number): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(`/api/contacts/${id}/resend/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function deleteContact(id: number): Promise<AxiosResponse> {
  return axios
    .delete<AxiosPromise>(`/api/contacts/${id}/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}
