import type {
  ProductTypeChannel,
  ProductTypeEnumChannel,
  ProductTypeNumberChannel,
} from '@/models/productTypes.model';

export function isNumberTelemetryChannel(
  channel: ProductTypeChannel
): channel is ProductTypeNumberChannel {
  return ['float', 'double', 'int8', 'uint8', 'int16', 'uint16', 'int32', 'uint32'].includes(
    channel.datatype
  );
}

export function isEnumTelemetryChannel(
  channel: ProductTypeChannel
): channel is ProductTypeEnumChannel {
  return channel.datatype.startsWith('enum');
}

export function getTelemetryEnumValue(
  options: ProductTypeEnumChannel['enumValues'],
  value: number
): string {
  return options.find((option) => option.value === value)?.label || value.toString();
}
