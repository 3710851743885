import { createApp } from 'vue';
import { createPinia } from 'pinia';
import i18n from './i18n';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/mdc-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';

import App from './App.vue';
import router from './router';
import * as Sentry from '@sentry/vue';

import './assets/styles/main.scss';
import { registerAuthGuard } from '@/services/auth/guard';
import { registerDpaGuard, registerFeatureAndRoleGuard } from '@/router/guards';
import { msalInstance } from '@/services/auth/config';
import axios from 'axios';
import dayjs from 'dayjs';

import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

import 'dayjs/locale/en';

const app = createApp(App);
export const pinia = createPinia();

// https://docs.sentry.io/platforms/javascript/guides/vue/
if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    sendDefaultPii: false,
    environment: import.meta.env.VITE_ENVIRONMENT,
    tracesSampleRate: import.meta.env.VITE_ENVIRONMENT.includes('prod') ? 0.1 : 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/.*\.ssb-group\.com\/api/,
      /^https:\/\/.*\.nidec-industrial\.com\/api/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    attachProps: true,
    logErrors: true,
    trackComponents: false,
  });
}

registerAuthGuard(router);
registerDpaGuard(router);
registerFeatureAndRoleGuard(router);

app.use(router);
app.use(i18n);
app.use(PrimeVue);
app.use(ToastService);
app.use(pinia);
app.directive('tooltip', Tooltip);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

axios.defaults.baseURL = import.meta.env.VITE_BASE_API_URL;
axios.defaults.headers.common['uid'] = 1;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) window.location.reload();
    return Promise.reject(error);
  }
);

router.isReady().then(() => app.mount('#app'));
